export const indexHeaders = [
	"Name",
	"Date uploaded",
	"Court",
	"Document",
	"Status",
];

export const suspectHeaders = [
	"Names",
	"Father names",
	"Mother names",
	"Date of Birth",
	"Documents",
];

export const userHeaders = [
	"Names",
	"Email",
	"Phone",
	"National ID",
	"Role",
	"Action",
];
