import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TableContainer, TablePagination, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles1 = makeStyles((theme) => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

function TablePaginationActions(props) {
	const classes = useStyles1();
	const theme = useTheme();
	const { count, page, rowsPerPage, onChangePage } = props;

	const handleFirstPageButtonClick = (event) => {
		onChangePage(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onChangePage(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<div className={classes.root}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <BiLastPage /> : <BiFirstPage />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? (
					<MdKeyboardArrowRight />
				) : (
					<MdKeyboardArrowLeft />
				)}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? (
					<MdKeyboardArrowLeft />
				) : (
					<MdKeyboardArrowRight />
				)}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <BiFirstPage /> : <BiLastPage />}
			</IconButton>
		</div>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onChangePage: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

export default function DataTable(props) {
	const { data, page: pg, rowsPerPage: limit, count, headers, path, onChangePage } = props;
	const [page, setPage] = React.useState(pg || 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(limit || 25);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		onChangePage(newPage, rowsPerPage)
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		onChangePage(0, parseInt(event.target.value, 10))
	};

	if (data === undefined || data === null || !data.length)
		return <div className="col items-center">No data found</div>;
	else if (headers === undefined || headers === null)
		return <div>No table headers provided</div>;
	else {
		return (
			<TableContainer>
				<table className="w-full text-left border-collapse">
					<thead>
						<tr className="bg-gray-100 text-gray-500">
							{headers.map((header) => (
								<th key={header} className="font-normal p-4">
									{header}
								</th>
							))}
						</tr>
					</thead>
					<tbody className="text-left border-none">
						{data.map((row) => (
							<tr key={row.id} className="text-left hover:bg-gray-100">
								{Object.values(row)
									.slice(1)
									.map((item) => (
										<td key={item} className="border-b p-4">
											<Link
												to={`/${path}/${row.id}`}
												className={`${
													item === "fully-indexed"
														? `bg-green-100 px-2 text-green-600 rounded-sm`
														: item === "linked"
														? `bg-blue-100 px-2 text-blue-600 rounded-sm`
														: item === "not-indexed"
														? `bg-red-100 px-2 text-red-600 rounded-sm`
														: ``
												}`}
											>
												{item}
											</Link>
										</td>
									))}
							</tr>
						))}
					</tbody>
					<tfoot className="bg-gray-100">
						<tr>
							<TablePagination
								rowsPerPageOptions={[25, 50, 75, 100]}
								count={count}
								rowsPerPage={rowsPerPage}
								page={page}
								SelectProps={{
									inputProps: { "aria-label": "rows per page" },
									native: true,
								}}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
								ActionsComponent={TablePaginationActions}
							/>
						</tr>
					</tfoot>
				</table>
			</TableContainer>
		);
	}
}
