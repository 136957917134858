import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route } from "react-router-dom";
import NotFound from "./components/NotFound";
import { ProtectedRoute } from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import history from "./Utils/history";
import Dashboard from "./Views/Dashboard";
import Login from "./Views/Login";
import SetPassword from "./Views/SetPassword";

export class App extends Component {
	render() {
		return (
			
			<Router history={history}>
				<Switch>
					<PublicRoute exact path="/" component={Login} />
					<PublicRoute path="/login" component={Login} />
					<PublicRoute path="/set-password/:token" component={SetPassword} />
					<ProtectedRoute path="/dashboard" component={Dashboard} />
					<Route component={NotFound} />
				</Switch>
			</Router>
		);
	}
}

export default connect()(App);
