import {
  GET_DOCUMENT_TYPES,
  GET_DOCUMENT_TYPES_FAILURE,
  DOCUMENTS_BY_TYPE,
  DOCUMENTS_BY_TYPE_ERROR,
  SPECIFIC_DOCUMENT,
  SPECIFIC_DOCUMENT_ERROR,
  GET_CRIMES_CATEGORIES,
  GET_CRIMES_CATEGORIES_ERROR,
  LINK_DOCUMENT,
  LINK_DOCUMENT_ERROR,
  GET_CRIMES,
  GET_CRIMES_ERROR,
  GET_SUSPECTS,
  GET_SUSPECTS_ERROR,
  SUSPECTS_BY_NAMES,
  SUSPECTS_BY_NAMES_ERROR,
  GET_SPECIFIC_SUSPECT,
  GET_SPECIFIC_SUSPECT_ERROR,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_ERROR,
  UPLOAD_LINK_DOCUMENTS,
  UPLOAD_LINK_DOCUMENTS_ERROR,
  CREATE_SUSPECT,
  CREATE_SUSPECT_ERROR,
  UPLOAD_PROGRESS,
  GET_TOTAL_CASES,
  GET_COMPLETED_CASES,
  GET_PENDING_CASES,
  GET_TOTAL_DOCUMENTS_BY_PROVINCE,
  GET_TOTAL_DOCUMENTS_BY_DISTRICT,
  GET_DOCUMENTS_BY_PROVINCE,
  GET_LINKED_DOCUMENTS_BY_PROVINCE,
  GET_DOCUMENTS_BY_DISTRICT,
  GET_LINKED_DOCUMENTS_BY_DISTRICT,
  GET_DOCUMENTS_BY_SECTOR,
  GET_LINKED_DOCUMENTS_BY_SECTOR,
  GET_DOCUMENTS_BY_GENERAL_ASSEMBLY,
  GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY,
  GET_DOCUMENTS_BY_COURT,
  GET_LINKED_DOCUMENTS_BY_COURT,
  GET_STATS_ERROR,
  COUNT_TOTAL_PAGES,
  COUNT_TOTAL_PAGES_BY_STATUS,
  COUNT_TOTAL_PAGE_UNLINKED,
  COUNT_TOTAL_PAGE_LINKED,
  COUNT_TOTAL_PAGES_ERROR,
} from "../actions/types";

const initialState = {
  documentTypesData: null,
  documentTypesError: null,
  documentsByTypeData: null,
  documentsByTypeError: null,
  specificDocumentData: null,
  specificDocumentError: null,
  crimeCategoriesData: null,
  crimeCategoriesError: null,
  crimesData: null,
  crimesError: null,
  linkDocumentData: null,
  linkDocumentError: null,
  suspectsData: null,
  suspectData: null,
  totalCases: null,
  completedCases: null,
  pendingCases: null,
  totalDocumentsByProvince: null,
  totalDocumentsByDistrict: null,
  provinceDocuments: null,
  provinceLinkedDocuments: null,
  districtDocuments: null,
  districtLinkedDocuments: null,
  sectorDocuments: null,
  sectorLinkedDocuments: null,
  generalAssemblyDocuments: null,
  generalAssemblyLinkedDocuments: null,
  courtOfCellDocuments: null,
  courtOfCellLinkedDocuments: null,
  suspectsError: null,
  suspectsByNamesData: null,
  suspectsByNamesError: null,
  specificSuspectData: null,
  specificSuspectError: null,
  uploadDocumentsData: null,
  uploadDocumentsError: null,
  uploadLinkDocumentsData: null,
  uploadLinkDocumentsError: null,
  suspectError: null,
  statsError: null,
  uploadProgress: 0,
  totalPages:null,
  totalLinkedPages:null,
  totalUnlinkedPages:null,
  totalPageError:null,
};

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypesData: payload,
        documentTypesError: null,
        specificDocumentData: null,
        specificDocumentError: null,
      };
    case GET_DOCUMENT_TYPES_FAILURE:
      return {
        ...state,
        documentTypesData: null,
        documentTypesError: payload,
        specificDocumentData: null,
        specificDocumentError: null,
      };
    case DOCUMENTS_BY_TYPE:
      return {
        ...state,
        documentsByTypeData: payload,
        documentsByTypeError: null,
        specificDocumentData: null,
        specificDocumentError: null,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case DOCUMENTS_BY_TYPE_ERROR:
      return {
        ...state,
        documentsByTypeData: null,
        documentsByTypeError: payload,
        specificDocumentData: null,
        specificDocumentError: null,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case SPECIFIC_DOCUMENT:
      return {
        ...state,
        documentsByTypeError: null,
        specificDocumentData: payload,
        specificDocumentError: null,
        linkDocumentData: null,
        linkDocumentError: null,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case SPECIFIC_DOCUMENT_ERROR:
      return {
        ...state,
        specificDocumentData: null,
        specificDocumentError: payload,
        linkDocumentData: null,
        linkDocumentError: null,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case GET_CRIMES_CATEGORIES:
      return {
        ...state,
        crimeCategoriesData: payload,
        crimeCategoriesError: null,
        crimesData: null,
        crimesError: null,
      };
    case GET_CRIMES_CATEGORIES_ERROR:
      return {
        ...state,
        crimeCategoriesData: null,
        crimeCategoriesError: payload,
        crimesData: null,
        crimesError: null,
      };
    case GET_CRIMES:
      return {
        ...state,
        crimesData: payload,
        crimesError: null,
      };
    case GET_CRIMES_ERROR:
      return {
        ...state,
        crimesData: null,
        crimesError: payload,
      };
    case LINK_DOCUMENT:
      return {
        ...state,
        linkDocumentData: payload,
        linkDocumentError: null,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case LINK_DOCUMENT_ERROR:
      return {
        ...state,
        linkDocumentData: null,
        linkDocumentError: payload,
        suspectsByNamesData: null,
        suspectsByNamesError: null,
      };
    case GET_SUSPECTS:
      return {
        ...state,
        suspectsData: payload,
        suspectsError: null,
      };
    case GET_SUSPECTS_ERROR:
      return {
        ...state,
        suspectsData: null,
        suspectsError: payload,
      };
    case SUSPECTS_BY_NAMES:
      return {
        ...state,
        suspectsByNamesData: payload,
        suspectsByNamesError: null,
      };
    case SUSPECTS_BY_NAMES_ERROR:
      return {
        ...state,
        suspectsByNamesData: null,
        suspectsByNamesError: payload,
      };
    case GET_SPECIFIC_SUSPECT:
      return {
        ...state,
        specificSuspectData: payload,
        specificSuspectError: null,
      };
    case GET_SPECIFIC_SUSPECT_ERROR:
      return {
        ...state,
        specificSuspectData: null,
        specificSuspectError: payload,
      };
    case UPLOAD_DOCUMENTS:
      return {
        ...state,
        uploadDocumentsData: payload,
        uploadDocumentsError: null,
        uploadProgress: 0,
      };
    case UPLOAD_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadDocumentsData: null,
        uploadDocumentsError: payload,
        uploadProgress: 0,
      };
    case UPLOAD_LINK_DOCUMENTS:
      return {
        ...state,
        uploadLinkDocumentsData: payload,
        uploadLinkDocumentsError: null,
        uploadProgress: 0,
      };
    case UPLOAD_LINK_DOCUMENTS_ERROR:
      return {
        ...state,
        uploadLinkDocumentsData: null,
        uploadLinkDocumentsError: payload,
        uploadProgress: 0,
      };
    case CREATE_SUSPECT:
      return {
        ...state,
        suspectData: payload,
        suspectError: null,
      };
    case CREATE_SUSPECT_ERROR:
      return {
        ...state,
        suspectData: null,
        suspectError: payload,
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: payload,
      };
    case GET_TOTAL_CASES:
      return {
        ...state,
        totalCases: payload,
      };
    case GET_COMPLETED_CASES:
      return {
        ...state,
        completedCases: payload,
      };
    case GET_PENDING_CASES:
      return {
        ...state,
        pendingCases: payload,
      };
    case GET_TOTAL_DOCUMENTS_BY_PROVINCE:
      return {
        ...state,
        totalDocumentsByProvince: payload,
      };
    case GET_TOTAL_DOCUMENTS_BY_DISTRICT:
      return {
        ...state,
        totalDocumentsByDistrict: payload,
      };
    case GET_DOCUMENTS_BY_PROVINCE:
      return {
        ...state,
        provinceDocuments: payload,
      };
    case GET_LINKED_DOCUMENTS_BY_PROVINCE:
      return {
        ...state,
        provinceLinkedDocuments: payload,
      };
    case GET_DOCUMENTS_BY_DISTRICT:
      return {
        ...state,
        districtDocuments: payload,
      };
    case GET_LINKED_DOCUMENTS_BY_DISTRICT:
      return {
        ...state,
        districtLinkedDocuments: payload,
      };
    case GET_DOCUMENTS_BY_SECTOR:
      return {
        ...state,
        sectorDocuments: payload,
      };
    case GET_LINKED_DOCUMENTS_BY_SECTOR:
      return {
        ...state,
        sectorLinkedDocuments: payload,
      };
    case GET_DOCUMENTS_BY_GENERAL_ASSEMBLY:
      return {
        ...state,
        generalAssemblyDocuments: payload,
      };
    case GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY:
      return {
        ...state,
        generalAssemblyLinkedDocuments: payload,
      };
    case GET_DOCUMENTS_BY_COURT:
      return {
        ...state,
        courtOfCellDocuments: payload,
      };
    case GET_LINKED_DOCUMENTS_BY_COURT:
      return {
        ...state,
        courtOfCellLinkedDocuments: payload,
      };
    case GET_STATS_ERROR:
      return {
        ...state,
        statsError: payload,
      };
    case COUNT_TOTAL_PAGES:
      return {
       ...state,
       totalPages:payload,
       
      };
      case COUNT_TOTAL_PAGES_BY_STATUS:
      return{
   ... state,
   totalPagesByStatus:payload,
      };
      case COUNT_TOTAL_PAGE_LINKED:
        return{
          ... state,
          totalLinkedPages:payload,
        };
    case COUNT_TOTAL_PAGE_UNLINKED:
      return{
        ... state,
        totalUnlinkedPages:payload,
      }
    case COUNT_TOTAL_PAGES_ERROR:
      return{
        ...state,
        totalPageError:payload,
      }
    default:
      return {
        ...state,
      };
  }
};

export default users;
