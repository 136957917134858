import jwtDecode from "jwt-decode";

export const setToken = (token) => {
	return localStorage.setItem("userToken", token);
};

export const getToken = () => {
	return localStorage.getItem("userToken");
};

export const decodeToken = () => {
	const token = getToken();
	if (token !== undefined || token !== null) {
		return jwtDecode(token);
	}
	return false;
};

export const removeToken = () => {
	return localStorage.removeItem("userToken");
};

export const isLoggedIn = () => {
	const token = getToken();
	if (token) {
		const decodedToken = decodeToken();
		if (decodedToken) {
			return true;
		}
		return false;
	}
	return false;
};
