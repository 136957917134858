import React, { Component } from "react";
import { BounceLoader, BeatLoader } from "react-spinners";
import { connect } from "react-redux";
import StatsCard from "../components/StatsCard";
import StatsLocationCard from "../components/StatsLocationCard";
import DoughnutChart from "../components/DoughnutChart";
import PieChart from "../components/PieChart";
import { useAppContext } from "../redux/actions/secret";
import {
  formatAssemblies,
  formatCourts,
  formatDistricts,
  formatProvinces,
  formatSectors,
} from "../helpers/formatAdministration";
import {
  formatProvincesData,
  formatDistrictsData,
} from "../helpers/formatDashboardDatasets";
import {
  getDashboardStats,
  getDashboardStatsByProvince,
  getDashboardStatsByDistrict,
  getDashboardStatsBySector,
  getDashboardStatsByGeneralAssembly,
  getDashboardStatsByCourt,
  getTotalPagesByStatus,
  getTotalLinked,
  getTotalUnlinked,
} from "../redux/actions/documents";
import SecretService from "../redux/actions/secret";
import { LinkedPages, LittleSecret } from "../components/LittleSecret";
export class DashboardStats extends Component {

  state = {
    province: "",
    district: "",
    sector: "",
    generalAssembly: "",
    courtOfCell: "",
    loading: false,
    loadingByLocation: false,
    pageNumbers:'',
    totalPages:'',
    isDisplay:localStorage.getItem("isDisplay"),
    totalLinkedPages:'',
    totalUnlinkedPages:'',
  };

  componentDidMount = async () => {
    const { getDashboardStats ,getTotalPagesByStatus,getTotalLinked,getTotalUnlinked} = this.props;
   
    // Retrieve the value from local storage
  const isDisplayFromLocalStorage = localStorage.getItem("isDisplay");
   console.log("CYOREEE",isDisplayFromLocalStorage);
    const provinces = formatProvinces();
    this.setState({isDisplay:true}),
    SecretService.getPages().then((res)=>
    this.setState({pageNumbers:res}));
    this.setState(() => ({
      provinces,
      isDisplay:isDisplayFromLocalStorage,
    }));
    this.setState({totalLinkedPages:getTotalLinked})
    this.setState({totalUnlinkedPages:getTotalUnlinked})
    this.setState({ loading: true });

    await getDashboardStats();
    await getTotalPagesByStatus();
    await getTotalLinked();
    await getTotalUnlinked();
    this.setState({ loading: false });
   
   //console.log("YYYESSS",isReady);
    
  };

  clearFields = (e) => {
    e.preventDefault();
    this.setState(() => ({
      province: "",
      district: "",
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
    }));
  };
  handleChangeProvince = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      district: "",
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
    }));

    const { getDashboardStatsByProvince } = this.props;
    this.setState({ loadingByLocation: true });
    await getDashboardStatsByProvince(value);
    this.setState({ loadingByLocation: false });
  };
  handleChangeDistrict = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
    }));

    const { getDashboardStatsByDistrict } = this.props;
    this.setState({ loadingByLocation: true });
    await getDashboardStatsByDistrict(value);
    this.setState({ loadingByLocation: false });
  };
  handleChangeSector = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      generalAssembly: "",
      courtOfCell: "",
    }));

    const { getDashboardStatsBySector } = this.props;
    this.setState({ loadingByLocation: true });
    await getDashboardStatsBySector(value);
    this.setState({ loadingByLocation: false });
  };
  handleChangeAssembly = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      courtOfCell: "",
    }));

    const { getDashboardStatsByGeneralAssembly } = this.props;
    this.setState({ loadingByLocation: true });
    await getDashboardStatsByGeneralAssembly(value);
    this.setState({ loadingByLocation: false });
  };
  handleChangeCourt = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
    }));

    const { getDashboardStatsByCourt } = this.props;
    this.setState({ loadingByLocation: true });
    await getDashboardStatsByCourt(value);
    this.setState({ loadingByLocation: false });
  };

  render() {
    const {
      provinces,
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      loading,
      loadingByLocation,
      isDisplay,
    } = this.state;
    const districts = formatDistricts(Number(province));
    const sectors = formatSectors(Number(province), Number(district));
    const generalAssemblies = formatAssemblies(
      Number(province),
      Number(district),
      Number(sector)
    );
    const courts = formatCourts(
      Number(province),
      Number(district),
      Number(sector),
      Number(generalAssembly)
    );
    const {
      totalUploadedDocuments,
      totalLinkedDocuments,
      totalCases,
      completedCases,
      pendingCases,
      totalDocumentsByProvince,
      totalDocumentsByDistrict,
      provinceDocuments,
      provinceLinkedDocuments,
      districtDocuments,
      districtLinkedDocuments,
      sectorDocuments,
      sectorLinkedDocuments,
      generalAssemblyDocuments,
      generalAssemblyLinkedDocuments,
      courtOfCellDocuments,
      courtOfCellLinkedDocuments,
      statsError,
      pageNumbers,
      totalPages,
      totalLinkedPages,
      totalUnlinkedPages,
    } = this.props;
     
    let provincesData = {};
    let distirctsData = {};
    const isOk=localStorage.getItem("isDisplay")==='true';
    if (totalDocumentsByProvince) {
      provincesData = formatProvincesData(
        Object.assign(
          {},
          ...totalDocumentsByProvince.data.map((docs) => ({
            [docs.Province.name]: docs.DocumentNumber,
          }))
        )
      );
    }
    if (totalDocumentsByDistrict) {
      distirctsData = formatDistrictsData(
        Object.assign(
          {},
          ...totalDocumentsByDistrict.data.map((docs) => ({
            [docs.District.name]: docs.DocumentNumber,
          }))
        )
      );
    }

    return loading ? (
      <div className="h-screen w-full flex justify-center items-center">
        <BounceLoader color="rgba(40, 100, 254, 1)" />
      </div>
    ) : (
      <div className="w-full py-10">
        <div className="row flex-wrap mb-6 px-8">
          <StatsCard
            title="Total Uploaded documents:"
            value={totalCases?.data[0].DocumentsNumber || ""}
          />
          <StatsCard
            title="Total Linked Documents:"
            value={pendingCases?.data[0].DocumentsNumber || ""}
          />
          <StatsCard
            title="Total cases with atleast one documents:"
            value={completedCases?.data[0].CaseNumber || ""}
          />
          <LittleSecret isVisible={isOk} data={totalPages?.data[0].PageNumber}/>
      {isOk && (
  <>
    <StatsCard
      title="Total linked Pages:"
      value={totalLinkedPages?.data[0].PageNumber || ""}
    />
    <StatsCard
      title="Total Unlinked Pages:"
      value={totalUnlinkedPages?.data[0].PageNumber || ""}
    />
  </>
)}
        </div>
        <div className="w-full mb-6">
          <div className="row flex-wrap px-8">
            <div className="col m-2 flex-1">
              <p>Province</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="province"
                value={province}
                onChange={this.handleChangeProvince}
              >
                <option value="" disabled={province}>
                  Select
                </option>
                {provinces
                  ? provinces.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>District</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="district"
                value={district}
                onChange={this.handleChangeDistrict}
                disabled={!province}
              >
                <option value="" disabled={district}>
                  Select
                </option>
                {districts
                  ? districts.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Sector</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="sector"
                value={sector}
                onChange={this.handleChangeSector}
                disabled={!district}
              >
                <option value="" disabled={sector}>
                  Select
                </option>
                {sectors
                  ? sectors.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>General Assembly</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="generalAssembly"
                value={generalAssembly}
                onChange={this.handleChangeAssembly}
                disabled={!sector}
              >
                <option value="" disabled={sector}>
                  Select
                </option>
                {generalAssemblies
                  ? generalAssemblies.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Court</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="courtOfCell"
                value={courtOfCell}
                onChange={this.handleChangeCourt}
                disabled={!generalAssembly}
              >
                <option value="" disabled={courtOfCell}>
                  Select
                </option>
                {courts
                  ? courts.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col self-end m-2">
              <button
                className="p-2 px-4 rounded-md bg-gdrBlue text-white outline-none"
                type="reset"
                onClick={this.clearFields}
                disabled={
                  !province &&
                  !district &&
                  !sector &&
                  !generalAssembly &&
                  !courtOfCell
                }
              >
                Clear
              </button>
            </div>
          </div>
          {loadingByLocation && (
            <div className="w-full flex justify-center items-center p-2">
              <BeatLoader color="rgba(40, 100, 254, 1)" />
            </div>
          )}
          <div className="row flex-wrap px-8">
            {province &&
              (provinceDocuments?.data || provinceLinkedDocuments?.data) && (
                <StatsLocationCard
                  title="Province"
                  uploadedDocuments={
                    provinceDocuments?.data[0].DocumentsNumber || ""
                  }
                  linkedDocuments={
                    provinceLinkedDocuments?.data[0].DocumentsNumber || ""
                  }
                />
              )}
            {district &&
              (districtDocuments?.data || districtLinkedDocuments?.data) && (
                <StatsLocationCard
                  title="District"
                  uploadedDocuments={
                    districtDocuments?.data[0].DocumentsNumber || ""
                  }
                  linkedDocuments={
                    districtLinkedDocuments?.data[0].DocumentsNumber || ""
                  }
                />
              )}
            {sector &&
              (sectorDocuments?.data || sectorLinkedDocuments?.data) && (
                <StatsLocationCard
                  title="Sector"
                  uploadedDocuments={
                    sectorDocuments?.data[0].DocumentsNumber || ""
                  }
                  linkedDocuments={
                    sectorLinkedDocuments?.data[0].DocumentsNumber || ""
                  }
                />
              )}
            {generalAssembly &&
              (generalAssemblyDocuments?.data ||
                generalAssemblyLinkedDocuments?.data) && (
                <StatsLocationCard
                  title="General Assembly"
                  uploadedDocuments={
                    generalAssemblyDocuments?.data[0].DocumentsNumber || ""
                  }
                  linkedDocuments={
                    generalAssemblyLinkedDocuments?.data[0].DocumentsNumber ||
                    ""
                  }
                />
              )}
            {courtOfCell &&
              (courtOfCellDocuments?.data ||
                courtOfCellLinkedDocuments?.data) && (
                <StatsLocationCard
                  title="Court"
                  uploadedDocuments={
                    courtOfCellDocuments?.data[0].DocumentsNumber || ""
                  }
                  linkedDocuments={
                    courtOfCellLinkedDocuments?.data[0].DocumentsNumber || ""
                  }
                />
              )}
          </div>
        </div>
        <div className="row flex-wrap px-8">
          <div className="w-full md:w-1/2 p-8 bg-gray-100 shadow-lg flex-1 m-2">
            <p>Documents by province</p>
            <DoughnutChart data={provincesData} />
          </div>
          <div className="w-full md:w-1/2 p-8 bg-gray-100 shadow-lg flex-1 m-2">
            <p>Documents by district</p>
            <PieChart data={distirctsData} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    totalUploadedDocuments,
    totalLinkedDocuments,
    totalCases,
    completedCases,
    pendingCases,
    totalDocumentsByProvince,
    totalDocumentsByDistrict,
    provinceDocuments,
    provinceLinkedDocuments,
    districtDocuments,
    districtLinkedDocuments,
    sectorDocuments,
    sectorLinkedDocuments,
    generalAssemblyDocuments,
    generalAssemblyLinkedDocuments,
    courtOfCellDocuments,
    courtOfCellLinkedDocuments,
    statsError,
    pageNumbers,
    totalPages,
    totalLinkedPages,
    totalUnlinkedPages,
  } = state.documents;
  return {
    totalUploadedDocuments,
    totalLinkedDocuments,
    totalCases,
    completedCases,
    pendingCases,
    totalDocumentsByProvince,
    totalDocumentsByDistrict,
    provinceDocuments,
    provinceLinkedDocuments,
    districtDocuments,
    districtLinkedDocuments,
    sectorDocuments,
    sectorLinkedDocuments,
    generalAssemblyDocuments,
    generalAssemblyLinkedDocuments,
    courtOfCellDocuments,
    courtOfCellLinkedDocuments,
    statsError,
    pageNumbers,
    totalPages,
    totalLinkedPages,
    totalUnlinkedPages,
  };
};

const mapDispatchToProps = {
  getDashboardStats,
  getDashboardStatsByProvince,
  getDashboardStatsByDistrict,
  getDashboardStatsBySector,
  getDashboardStatsByGeneralAssembly,
  getDashboardStatsByCourt,
  getTotalPagesByStatus,
  getTotalLinked,
  getTotalUnlinked,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardStats);