
import React from 'react';
import StatsCard from './StatsCard';

export const LittleSecret=(props)=>{
  if (props.isVisible) {
  return(
    <StatsCard
    title="Total Number of Uploaded Pages:"
    value={props.data}
  />
  )
  }else{
    return null
  }
}


export const UnlinkedPages=(props)=>{
  return(
    <StatsCard
    title="Total Number of Unlinked Pages:"
    value={props.data}
  />
  )
}

export const LinkedPages=(props)=>{
  return(
    <StatsCard
    title="Total Number of Linked Pages:"
    value={props.data}
  />
  )
}