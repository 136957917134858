import React, { Component } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { LinearProgress } from '@material-ui/core'
import { CustomDropzone } from "../components/CustomDropzone";
// import { getFiles } from "../helpers/fileUpload";
import {
	formatAssemblies,
	formatCourts,
	formatDistricts,
	formatProvinces,
	formatSectors,
} from "../helpers/formatAdministration";
import { saveSearchParams } from "../helpers/searchHelpers";
import { getDocumentTypes, uploadDocuments } from "../redux/actions/documents";

export class BulkUpload extends Component {
	state = {
		documentName: "",
	};
	componentDidMount = async () => {
		const provinces = formatProvinces();
		this.setState(() => ({
			provinces,
		}));
		const { getDocumentTypes } = this.props;
		await getDocumentTypes();
	};
	handleChangeProvince = async (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			district: "",
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
			documentType: "",
		}));
	};
	handleChangeDistrict = async (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
			documentType: "",
		}));
	};
	handleChangeSector = async (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			generalAssembly: "",
			courtOfCell: "",
			documentType: "",
		}));
	};
	handleChangeAssembly = async (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			courtOfCell: "",
			documentType: "",
		}));
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			searchResults: [],
		}));
	};
	handleFileChange = (e) => {
		e.preventDefault();
		const { name, files } = e.target;
		this.setState(() => ({
			[name]: files,
		}));
	};
	handleUpload = async (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		const { uploadDocuments, uploadDocumentsData, history } = this.props;
		const {
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			documentType,
			file,
		} = this.state;
		const formData = new FormData();
		const data = {
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			documentType,
		};

		Object.values(file).forEach((item) => {
			formData.append("file", item);
		});
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				formData.append(key, data[key]);
			}
		}
		await uploadDocuments(formData);
		if (uploadDocumentsData) {
			const searchParams = `?district=${district}&sector=${sector}&generalAssembly=${generalAssembly}&courtOfCell=${courtOfCell}&documentType=${documentType}&documentStatus=not-indexed`;
			saveSearchParams(searchParams);
			history.push("/dashboard/files");
		}
		this.setState({ loading: false });
	};
	// static getDerivedStateFromProps = (state, props) => {
	// 	const { uploadDocumentsData, history } = props;
	// 	if (uploadDocumentsData) {
	// 		history.push("/dashboard/files");
	// 	}
	// 	return state;
	// };
	render() {
		const {
			provinces,
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			documentType,
			file,
			loading,
		} = this.state;
		const districts = formatDistricts(Number(province));
		const sectors = formatSectors(Number(province), Number(district));
		const generalAssemblies = formatAssemblies(
			Number(province),
			Number(district),
			Number(sector),
		);
		const courts = formatCourts(
			Number(province),
			Number(district),
			Number(sector),
			Number(generalAssembly),
		);
		const { documentTypesData, uploadProgress } = this.props;
		return (
			<div>
				<div className="">
					<div>
						<div className="px-10 pt-10 text-xl">Specify the structure</div>
						<form action="" onSubmit={this.handleUpload}>
							<div className="row px-10 py-4 flex-wrap">
								<div className="col m-2 flex-1">
									<p>Province</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="province"
										value={province}
										onChange={this.handleChangeProvince}
									>
										<option value="" disabled={province}>
											Select
										</option>
										{provinces
											? provinces.map(({ id, name }) => (
													<option key={id} value={id}>
														{name}
													</option>
											  ))
											: ""}
									</select>
								</div>
								<div className="col m-2 flex-1">
									<p>District</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="district"
										value={district}
										onChange={this.handleChangeDistrict}
										disabled={!province}
									>
										<option value="" disabled={district}>
											Select
										</option>
										{districts
											? districts.map(({ id, name }) => (
													<option key={id} value={id}>
														{name}
													</option>
											  ))
											: ""}
									</select>
								</div>
								<div className="col m-2 flex-1">
									<p>Sector</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="sector"
										value={sector}
										onChange={this.handleChangeSector}
										disabled={!district}
									>
										<option value="" disabled={sector}>
											Select
										</option>
										{sectors
											? sectors.map(({ id, name }) => (
													<option key={id} value={id}>
														{name}
													</option>
											  ))
											: ""}
									</select>
								</div>
								<div className="col m-2 flex-1">
									<p>General Assembly</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="generalAssembly"
										value={generalAssembly}
										onChange={this.handleChangeAssembly}
										disabled={!sector}
									>
										<option value="" disabled={sector}>
											Select
										</option>
										{generalAssemblies
											? generalAssemblies.map(({ id, name }) => (
													<option key={id} value={id}>
														{name}
													</option>
											  ))
											: ""}
									</select>
								</div>
								<div className="col m-2 flex-1">
									<p>Court</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="courtOfCell"
										value={courtOfCell}
										onChange={this.handleChange}
										disabled={!generalAssembly}
									>
										<option value="" disabled={courtOfCell}>
											Select
										</option>
										{courts
											? courts.map(({ id, name }) => (
													<option key={id} value={id}>
														{name}
													</option>
											  ))
											: ""}
									</select>
								</div>
								<div className="col m-2 flex-1">
									<p>Document type</p>
									<select
										className="w-full border rounded-md bg-white p-2"
										name="documentType"
										value={documentType}
										disabled={!courtOfCell}
										onChange={this.handleChange}
										required
									>
										<option value="" disabled={documentType}>
											Select
										</option>
										{documentTypesData &&
											documentTypesData.data.map(({ id, name }) => (
												<option key={id} value={id}>
													{name}
												</option>
											))}
									</select>
								</div>
							</div>
							<div className="px-10">
								<CustomDropzone onFileChange={this.handleFileChange} />
							</div>
							<div className="px-10 py-4">
								<div className="row m-2 gap-4">
									<button
										className="p-2 px-6 rounded-md bg-gdrBlue text-white w-max"
										type="submit"
										disabled={
											!province ||
											!districts ||
											!sector ||
											!generalAssembly ||
											!courtOfCell ||
											!documentType ||
											!file ||
											loading
										}
									>
										{loading ? (
											<BeatLoader color="#FFF" />
										) : (
											"Upload document(s)"
										)}
									</button>
								</div>
								{loading ? <div className="w-full row items-center">
								  <LinearProgress className="w-11/12" variant="determinate" value={uploadProgress} />
									<p className="text-md flex ml-auto">{uploadProgress} %</p>
								</div> : null}
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		documentTypesData,
		documentTypesError,
		uploadDocumentsData,
		uploadDocumentsError,
		uploadProgress,
	} = state.documents;
	return {
		documentTypesData,
		documentTypesError,
		uploadDocumentsData,
		uploadDocumentsError,
		uploadProgress,
	};
};

export default connect(mapStateToProps, { getDocumentTypes, uploadDocuments })(
	BulkUpload,
);
