import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export const CustomDropzone = ({ onFileChange }) => {
	const onDrop = useCallback((acceptedFiles) => {
		const files = [];

		acceptedFiles.forEach((file) => {
			const reader = new FileReader();
			files.push(file);

			reader.onabort = () => console.log("file reading was aborted");
			reader.onerror = () => console.log("file reading has failed");
			reader.onload = () => {
				// Do whatever you want with the file contents
				// const binaryStr = reader.result;
				// console.log("binaryStr", binaryStr);
			};
			reader.readAsArrayBuffer(file);
		});
	}, []);
	const { getRootProps } = useDropzone({ onDrop });

	return (
		<div
			{...getRootProps()}
			className="flex flex-col items-center justify-center h-72 border-2 border-dashed p-2 rounded-lg bg-gray-100 text-gray-500 text-lg"
		>
			<input
				// {...getInputProps()}
				onDrop={onFileChange}
				type="file"
				accept=".pdf"
				name="file"
				multiple
				onChange={onFileChange}
			/>
			{/* {isDragActive ? (
				<div className="flex items-center">Drop the files here...</div>
			) : (
				<div className="flex items-center">
					Click to select
					<span className="mx-4 text-primaryLight text-3xl">
						<TiCloudStorage />
					</span>
				</div>
			)} */}
		</div>
	);
};
