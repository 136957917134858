import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import search from "../assets/images/search.svg";
import DataTable from "../components/DataTable";
import { indexHeaders } from "../Utils/tableHeaders";
import {
  formatAssemblies,
  formatCourts,
  formatDistricts,
  formatProvinces,
  formatSectors,
} from "../helpers/formatAdministration";
import {
  getDocumentTypes,
  getDocumentsByType,
} from "../redux/actions/documents";
import { BounceLoader } from "react-spinners";
import { saveSearchParams, getSearchParams } from "../helpers/searchHelpers";
import getParams from "../Utils/getParams";

export class DashboardIndexes extends Component {
  state = {
    province: "",
    district: "",
    sector: "",
    generalAssembly: "",
    courtOfCell: "",
    documentType: "",
    documentStatus: "",
    page: 0,
    limit: 25,
    loading: false,
  };

  componentDidMount = async () => {
    const provinces = formatProvinces();
    this.setState(() => ({
      provinces,
    }));
    const { getDocumentTypes, getDocumentsByType } = this.props;
    await getDocumentTypes();

    const searchParams = getSearchParams();
    if (searchParams) {
      const parsedParams = JSON.parse(searchParams)
      this.setState({
        province: parsedParams.province || "",
        district: parsedParams.district || "",
        sector: parsedParams.sector || "",
        generalAssembly: parsedParams.generalAssembly || "",
        courtOfCell: parsedParams.courtOfCell || "",
        documentType: parsedParams.documentType || "",
        documentStatus: parsedParams.documentStatus || "",
        page: parsedParams.page || 0,
        limit: parsedParams.limit || 25,
      });
      
      const {province, ...rest} = parsedParams;
      const params = getParams({...rest, page: rest.page + 1})
      await getDocumentsByType(params);
    }
  };
  async componentDidUpdate(prevProps, prevState) {
    const {
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      documentType,
      documentStatus,
      page,
      limit,
    } = this.state;
    const { getDocumentsByType } = this.props;

    if (
      (prevState.province !== province &&
        prevState.district !== district &&
        prevState.sector !== sector &&
        prevState.generalAssembly !== generalAssembly &&
        prevState.courtOfCell !== courtOfCell) ||
      prevState.documentType !== documentType ||
      prevState.documentStatus !== documentStatus ||
      prevState.page !== page ||
      prevState.limit !== limit
    ) {
      // Search
      this.setState({ loading: true });

      saveSearchParams(JSON.stringify({
        province,
        district,
        sector,
        generalAssembly,
        courtOfCell,
        documentType,
        documentStatus,
        page,
        limit
      }));
      
      const options = {
        district,
        sector,
        generalAssembly,
        courtOfCell,
        documentType,
        documentStatus,
        page: page + 1,
        limit,
      };

      const params = getParams(options);
      await getDocumentsByType(params);
      this.setState({ loading: false });
    }
  }

  handleChangeProvince = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      district: "",
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleChangeDistrict = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleChangeSector = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      generalAssembly: "",
      courtOfCell: "",
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleChangeAssembly = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      courtOfCell: "",
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleChangeCourt = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  clearSearch = (e) => {
    e.preventDefault();
    this.setState(() => ({
      province: "",
      district: "",
      sector: "",
      generalAssembly: "",
      courtOfCell: "",
      documentType: "",
      documentStatus: "",
      page: 0,
      limit: 25,
      searchResults: [],
    }));
  };
  handleSearch = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    await this.setState(() => ({
      [name]: value,
    }));
    this.setState({ loading: true });
    const { getDocumentsByType } = this.props;
    const {
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      documentType,
      documentStatus,
      page,
      limit,
    } = this.state;
    const options = {
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      documentType,
      documentStatus,
      // page: page + 1,
      page: 5,
      limit,
    };
    let params = "";
    Object.keys(options).forEach((key) => {
      if (options[`${key}`] !== "") {
        params += `${key}=${options[`${key}`]}&`;
      }
    });
    let searchParams = params.split("&").slice(1, 7).join("&");
    if (searchParams !== "") {
      searchParams = `?${searchParams}`;
    }
    
    await getDocumentsByType(searchParams);
    this.setState({ loading: false });
  };
  handleChangePage = async (page, limit) => {
    this.setState({
      page,
      limit,
    });
  };

  render() {
    const {
      provinces,
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      documentType,
      documentStatus,
      loading,
      page,
      limit,
    } = this.state;
    console.log(
      province,
      district,
      sector,
      generalAssembly,
      courtOfCell,
      documentType,
      documentStatus,
      page,
      limit,);
    let searchResults = [];
    const districts = formatDistricts(Number(province));
    const sectors = formatSectors(Number(province), Number(district));
    const generalAssemblies = formatAssemblies(
      Number(province),
      Number(district),
      Number(sector)
    );
    const courts = formatCourts(
      Number(province),
      Number(district),
      Number(sector),
      Number(generalAssembly)
    );
    const { documentTypesData, documentsByTypeData } = this.props;
    if (documentsByTypeData !== null) {
      let searchData = documentsByTypeData.data.documents;
      searchResults = searchData.map(
        ({
          id,
          documentName,
          documentStatus,
          createdAt,
          DocumentType,
          GeneralAssembly,
          CourtOfCell,
        }) => ({
          id,
          name: documentName,
          dateUploaded: moment(createdAt).format("MMMM DD, YYYY"),
          location: `${GeneralAssembly.name}, ${CourtOfCell.name}`,
          documents: DocumentType.name,
          status: documentStatus,
        })
      );
    }
    return (
      <div className="">
        <div className="px-10 pt-10 text-3xl">Search document</div>
        <form action="" onSubmit={this.handleSearch}>
          <div className="row px-10 py-4 flex-wrap">
            <div className="col m-2 flex-1">
              <p>Province</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="province"
                value={province}
                onChange={this.handleChangeProvince}
              >
                <option value="" disabled={province}>
                  Select
                </option>
                {provinces
                  ? provinces.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>District</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="district"
                value={district}
                onChange={this.handleChangeDistrict}
                disabled={!province}
              >
                <option value="" disabled={district}>
                  Select
                </option>
                {districts
                  ? districts.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Sector</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="sector"
                value={sector}
                onChange={this.handleChangeSector}
                disabled={!district}
              >
                <option value="" disabled={sector}>
                  Select
                </option>
                {sectors
                  ? sectors.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>General Assembly</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="generalAssembly"
                value={generalAssembly}
                onChange={this.handleChangeAssembly}
                disabled={!sector}
              >
                <option value="" disabled={sector}>
                  Select
                </option>
                {generalAssemblies
                  ? generalAssemblies.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Court</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="courtOfCell"
                value={courtOfCell}
                onChange={this.handleChangeCourt}
                disabled={!generalAssembly}
              >
                <option value="" disabled={courtOfCell}>
                  Select
                </option>
                {courts
                  ? courts.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))
                  : ""}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Document type</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="documentType"
                value={documentType}
                disabled={!courtOfCell}
                onChange={this.handleChange}
              >
                <option value="" disabled={documentType}>
                  Select
                </option>
                {documentTypesData &&
                  documentTypesData.data.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p>Status</p>
              <select
                className="w-full border rounded-md bg-white p-2"
                name="documentStatus"
                value={documentStatus}
                disabled={!documentType}
                onChange={this.handleChange}
              >
                <option value="">All</option>
                <option value="not-assigned">Not Indexed</option>
                <option value="linked">Linked to accused</option>
                <option value="fully-assigned">Fully Indexed</option>
              </select>
            </div>
            <div className="col m-2 flex-1">
              <p className="text-white">.</p>
              <button
                className="w-max p-2 px-4 bg-gdrBlue text-white rounded-md"
                disabled={!district || !sector || !documentType}
                onClick={this.clearSearch}
              >
                Clear search
              </button>
            </div>
          </div>
        </form>

        <hr />
        <div>
          {loading ? (
            <div className="col h-screen w-full justify-center items-center">
              <BounceLoader color="rgba(40, 100, 254, 1)" />
            </div>
          ) : (
            <div>
              {searchResults.length ? (
                <div className="p-10">
                  <p className="text-gdrBlue text-xl">Search results</p>
                  <div className="my-4">
                    <DataTable
                      headers={indexHeaders}
                      data={searchResults}
                      count={documentsByTypeData?.data.totalResults}
                      rowsPerPage={limit}
                      page={page}
                      path={"dashboard/files"}
                      onChangePage={this.handleChangePage}
                    />
                  </div>
                </div>
              ) : (
                <div className="col w-full items-center justify-center p-10">
                  {!province ||
                  !district ||
                  !sector ||
                  !courtOfCell ||
                  !documentType ||
                  !documentStatus ? (
                    <div>
                      <img src={search} alt="" />
                      <p className="text-2xl p-4">Search for a document</p>
                    </div>
                  ) : (
                    <div>
                      <img src={search} alt="" />
                      <p className="text-2xl p-4">No documents found</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    documentTypesData,
    documentTypesError,
    documentsByTypeData,
    documentsByTypeError,
  } = state.documents;
  return {
    documentTypesData,
    documentTypesError,
    documentsByTypeData,
    documentsByTypeError,
  };
};

export default connect(mapStateToProps, {
  getDocumentTypes,
  getDocumentsByType,
})(DashboardIndexes);
