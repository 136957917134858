import React, { Component } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { isValidPhoneNumber } from "libphonenumber-js";
import { createUser, getRoles } from "../redux/actions/users";
import { Required } from "../components/Required";

export class AddUser extends Component {
  state = {
    names: "",
    email: "",
    phone: "",
    nationalId: "",
    roleId: "",
    password: "",
    loading: false,
    error: null,
  };
  componentDidMount = async () => {
    const { getRoles } = this.props;
    await getRoles();
  };
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
    }));
  };
  clearFields = (e) => {
    e.preventDefault();
    this.setState(() => ({
      names: "",
      email: "",
      phone: "",
      nationalId: "",
      roleId: "",
      password: "",
      error: "",
    }));
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: '' });
    try {
      const { names, email, phone, nationalId, roleId, password } = this.state;
      const { createUser } = this.props;

      if (
        !email.match(
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        )
      ) {
        this.setState({
          error: "Email must be a valid email",
          loading: false,
        });

        return;
      }

      if (!isValidPhoneNumber(phone, "RW")) {
        this.setState({
          error: "Phone must be a valid phone number(250.........)",
          loading: false,
        });

        return;
      }

      if (nationalId.length !== 16) {
        this.setState({
          error: "National ID must be 16 digits",
          loading: false,
        });

        return;
      }

      if (!password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)) {
        this.setState({
          error: "Password must be at least 6 characters long and must include at least a number and a capital letter",
          loading: false,
        });

        return;
      }

      const data = {
        names,
        email,
        phone: `+25${phone}`,
        nationalId,
        roleId,
        password
      };
      await createUser(data);
      this.clearFields();
      this.setState({ loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  render() {
    const { names, email, phone, nationalId, roleId, password, loading, error } =
      this.state;
    const { rolesData, userError } = this.props;

    return (
      <div className="p-10 bg-gray-100 h-full">
        <div className="col p-8">
          <form onSubmit={this.handleSubmit}>
            <div className="row space-x-6 my-6">
              <div className="flex-1 col">
                <label htmlFor="names">
                  Names
                  <Required />
                </label>
                <input
                  type="text"
                  name="names"
                  id="names"
                  value={names}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                  required
                />
              </div>
              <div className="flex-1 col">
                <label htmlFor="nationalId">
                  National ID
                  <Required />
                </label>
                <input
                  type="text"
                  name="nationalId"
                  id="nationalId"
                  value={nationalId}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                  required
                />
              </div>
            </div>

            <div className="row space-x-6 my-6">
              <div className="flex-1 col">
                <label htmlFor="email">
                  Email
                  <Required />
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                  required
                />
              </div>
              <div className="flex-1 col">
                <label htmlFor="phone">
                  Phone eg:(250782398942)
                  <Required />
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                  required
                />
              </div>
            </div>
            <div className="row space-x-6 my-6">
              <div className="flex-1 col">
                <label htmlFor="password">
                  Password
                  <Required />
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  value={password}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                  required
                />
              </div>
              <div className="flex-1 col">
                <label htmlFor="roleId">
                  Role
                  <Required />
                </label>
                <select
                  name="roleId"
                  id="roleId"
                  value={roleId}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  disabled={!rolesData}
                  required
                >
                  <option value="" disabled={roleId}>
                    Select
                  </option>
                  {rolesData?.data.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {error && <div className="text-red-500 my-4">{error}</div>}
            {userError?.data && (
              <div className="text-red-500 my-4">{userError.data.error}</div>
            )}

            <div className="row space-x-4">
              <button
                className="p-2 px-6 rounded-md bg-gray-200 outline-none"
                type="reset"
                onClick={this.clearFields}
              >
                Cancel
              </button>
              <button
                className="p-2 px-6 rounded-md bg-gdrBlue text-white outline-none"
                type="submit"
                disabled={!names || !email || !phone || !nationalId || !roleId || !password}
              >
                {loading ? <BeatLoader color="#FFF" /> : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userData, userError, rolesData, rolesError } = state.users;
  return {
    userData,
    userError,
    rolesData,
    rolesError,
  };
};

const mapDispatchToProps = {
  createUser,
  getRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
