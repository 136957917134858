import React, { Component } from "react";
import { connect } from "react-redux";
import IndexedResults from "./IndexedResults";
import { getSuspectsByNames } from "../redux/actions/documents";
import { withRouter } from "react-router";
import { linkDocument, getSpecificDocument } from "../redux/actions/documents";
import { BeatLoader } from "react-spinners";
import getParams from "../Utils/getParams";

export class Indexed extends Component {
	state = {
		names: "",
		fatherNames: "",
		motherNames: "",
		results: [],
		suspectId: "",
		loading: false,
		accused: null,
		page: 1,
		limit: 10,
	};
	clearFields = (e) => {
		e.preventDefault();
		this.setState(() => ({
			names: "",
			fatherNames: "",
			motherNames: "",
			accused: null,
			page: 1,
			limit: 10,
		}));
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleChangePage = async (page = 1, limit = 10) => {
		this.setState({
			page,
			limit,
			loading: true,
			accused: null
		});
		const { names, fatherNames, motherNames } = this.state;
		const { getSuspectsByNames } = this.props;
		const options = {
			names,
			fatherNames,
			motherNames,
			page,
			limit,
		};
		const params = getParams(options);

		await getSuspectsByNames(params);
		this.setState({ loading: false });
	}
	handleSearch = async (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
			accused: null,
			page: 1,
			limit: 10,
		});
		const { getSuspectsByNames } = this.props;
		const { names, fatherNames, motherNames } = this.state;
		const options = {
			names,
			fatherNames,
			motherNames,
			page: 1,
			limit: 10,
		};
		const params = getParams(options);

		await getSuspectsByNames(params);
		this.setState({ loading: false });
	};
	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		const { accused } = this.state;
		const { id, linkDocument, suspectsByNamesData } = this.props;
		const suspect = suspectsByNamesData.data.suspects.find(
			(item) => item.id === accused,
		);
		const {
			id: suspectId,
			names,
			DOB,
			fatherNames,
			motherNames,
			profession,
			gender,
			crimes,
			description,
		} = suspect;
		const data = {
			suspectId,
			names,
			dob: DOB,
			fatherNames,
			motherNames,
			profession,
			gender,
			crimes: crimes.map(({ id }) => id),
			description,
		};
		await linkDocument(id, data);
		this.setState({ loading: false });
	};
	static getDerivedStateFromProps = (state, props) => {
		const { linkDocumentData, getSpecificDocument, id } = props;
		if (linkDocumentData) {
			getSpecificDocument(id);
		}
		return state;
	};

	render() {
		const {
			names,
			fatherNames,
			motherNames,
			loading,
			accused,
			results,
			page,
			limit,
		} = this.state;
		const { suspectsByNamesData } = this.props;
		let searchResults = results;
		if (suspectsByNamesData && suspectsByNamesData.data.suspects.length) {
			searchResults = suspectsByNamesData.data.suspects.map(
				({
					id,
					DOB,
					names,
					fatherNames,
					motherNames,
					crimes,
					description,
					gender,
					state
				}) => ({
					id,
					DOB,
					names,
					fatherNames,
					motherNames,
					crimes,
					description,
					gender,
					state
				}),
			);
		}
		return (
			<div>
				<div className="col p-8">
					<p className="text-2xl">Search accused</p>
					<form onSubmit={this.handleSearch}>
						<div className="col gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">Accused full names</label>
								<div className="row items-center border rounded-lg p-3">
									<input
										type="text"
										name="names"
										value={names}
										onChange={this.handleChange}
										className="w-full focus:outline-none"
										placeholder="Fill in this field"
										required
									/>
								</div>
							</div>
						<div className="flex-1 col mt-2">
							<label htmlFor="">Father names</label>
							<div className="row items-center border rounded-lg p-3">
								<input
									type="text"
									name="fatherNames"
									value={fatherNames}
									onChange={this.handleChange}
									className="w-full focus:outline-none"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						<div className="flex-1 col mt-2">
							<label htmlFor="">Mother names</label>
							<div className="row items-center border rounded-lg p-3">
								<input
									type="text"
									name="motherNames"
									value={motherNames}
									onChange={this.handleChange}
									className="w-full focus:outline-none"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						<div className="flex-1 mt-2 row items-center justify-end space-x-4">
							<div className="col">
								<button
									className="p-3 px-6 rounded-md bg-gray-200 outline-none"
									type="reset"
									onClick={this.clearFields}
								>
									Clear
								</button>
							</div>
							<div className="col">
							<button
								className="p-3 px-6 rounded-md bg-gdrBlue text-white outline-none"
								type="submit"
								onClick={this.handleSearch}
								disabled={!names && !fatherNames && !motherNames}
							>
								{loading ? (
									<BeatLoader color="#FFF" />
								) : (
									'Search'
								)}
							</button>
						</div>
						</div>
						</div>

						<div className="col">
							{suspectsByNamesData ? (
								suspectsByNamesData.data.suspects.length ? (
									<IndexedResults
										indexedData={searchResults}
										count={suspectsByNamesData?.data.totalResults}
								        rowsPerPage={limit}
								        page={page}
										onChange={this.handleChange}
										onChangePage={this.handleChangePage}
										loading={loading}
									/>
								) : (
									<div className="text-red-400">
										<p>No matching results were found</p>
									</div>
								)
							) : (
								""
							)}
						</div>

						<div className="row space-x-4 my-4">
							<button
								className="p-2 px-6 rounded-md bg-gray-200 outline-none"
								type="reset"
								onClick={this.clearFields}
							>
								Cancel
							</button>
							{accused && <button
								className="p-2 px-6 rounded-md bg-gdrBlue text-white outline-none"
								type="submit"
								disabled={!accused}
								onClick={this.handleSubmit}
							>
								{loading ? (
									<BeatLoader color="#FFF" />
								) : (
									"Link document to accused"
								)}
							</button>}
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		suspectsByNamesData,
		suspectsByNamesError,
		linkDocumentData,
	} = state.documents;
	return {
		suspectsByNamesData,
		suspectsByNamesError,
		linkDocumentData,
	};
};

export default connect(mapStateToProps, {
	getSuspectsByNames,
	linkDocument,
	getSpecificDocument,
})(withRouter(Indexed));
