import React, { Component } from "react";
import { NavLink,useHistory,Link} from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { connect } from "react-redux";
import Table from "../components/Table";
import { getUsers, changeStatus } from "../redux/actions/users";
import { userHeaders } from "../Utils/tableHeaders";
import { decodeToken } from "../helpers/tokenHelper";


export class UsersList extends Component {
  state = {
    loading: false,
  };
  componentDidMount = async () => {
    const { getUsers } = this.props;

    this.setState({ loading: true });

    await getUsers();

    this.setState({ loading: false });
  };
  toggleStatus = async (userId, status) => {
    this.setState({ loading: true });

    const { changeStatus } = this.props;
    await changeStatus(userId, status === "active" ? "inactive" : "active");
    // window.location.reload();

    this.setState({ loading: false });
  };
  goChangePassword=async(id)=>{
    const history = useHistory();
  history.push(`/change-password/${id}`);
  }

  render() {
    const { loading } = this.state;
    const { usersData } = this.props;
    const authUser = decodeToken();

    const users = usersData?.data
      ? usersData.data.map((user) => ({
          id: user.id,
          names: user.names,
          email: user.email,
          phone: user.phone,
          nationalId: user.nationalId,
          role: user.role?.name,
          status: (
            <button
              type="button"
              onClick={() => this.toggleStatus(user.id, user.status)}
              className={`${
                user.status === "active" ? "bg-red-500" : "bg-green-500"
              } px-2 py-1 rounded-sm text-white outline-none`}
              disabled={authUser?.userId === user.id}
            >
              {user.status === "active" ? "Deactivate" : "Activate"}
            </button>
          ),
          changePassword:(
            <a href="#">
            <Link to={`/dashboard/change-password/${user.id}`}>Change Password</Link>
            </a>
          ),
        }))
      : [];

    return (
      <div>
        <div className="p-10 flex items-center">
          <div className="text-3xl">Users</div>
          <div className="ml-auto">
            <NavLink
              to="/dashboard/users/add"
              className="p-3 px-6 rounded-md bg-gdrBlue text-white outline-none"
            >
              Add User
            </NavLink>
          </div>
        </div>
        <hr />

        {loading ? (
          <div className="col h-screen w-full justify-center items-center">
            <BounceLoader color="rgba(40, 100, 254, 1)" />
          </div>
        ) : (
          <div className="col p-10">
            {users.length ? (
              <div className="">
                <p className="text-gdrBlue text-xl">Users list</p>
                <div className="my-4">
                  <Table
                    data={users}
                    headers={userHeaders}
                    path={"dashboard/users"}
                    noPagination
                  />
                </div>
              </div>
            ) : (
              <div className="col w-full items-center justify-center">
                <p className="text-red-400">No users were found</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { usersData, usersError } = state.users;
  return {
    usersData,
    usersError,
  };
};

export default connect(mapStateToProps, { getUsers, changeStatus })(UsersList);
