import React, { Component,useEffect } from "react";
import { useState } from "react";
//import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
import { LittleSecret } from "../components/LittleSecret";
import StatsCard from "../components/StatsCard";
import SecretService from "../redux/actions/secret";
import {
 
 getTotalLinked,
 getTotalUnlinked,
  getTotalPagesByStatus,
} from "../redux/actions/documents";
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';


export class SetSecret extends Component{
    constructor(props){
        super(props)
        this.state={
            pageNumbers:'',
            pageData:[] ,
            totalPages:'',
            isDisplay: localStorage.getItem("isDisplay") === "true",
            totalLinkedPages:'',
            totalUnlinkedPages:'',
            timestamp: Date.now(), // Add a timestamp state variable
        }
        
        
    }
  
//  state={
//   pageNumbers:"",
//   loading: false,
//     error: null,
//   pageNumbers:'',
//    pageData:[] ,
//    totalPages:'',
//    isDisplay: true,
  
//  };
 
 componentDidMount=async(e)=>{

  const { getTotalPagesByStatus,getTotalLinked,getTotalUnlinked} = this.props;
  
   this.setState({isDisplay:false});
   this.setState({ loading: true });
   this.setState({totalPages:getTotalPagesByStatus})
   this.setState({totalLinkedPages:getTotalLinked})
   this.setState({totalUnlinkedPages:getTotalUnlinked})
    // Start the interval to update the timestamp every second
    this.refreshInterval = setInterval(() => {
      window.location.reload();
    }, 500000);

   await getTotalPagesByStatus();
   await getTotalLinked();
  await getTotalUnlinked();
   this.setState({ loading: false });
 
 }
 componentWillUnmount() {
  // Clear the interval when the component unmounts to avoid memory leaks
  clearInterval(this.interval);
}

 handleChange = (e) => {

    e.preventDefault();
    const { value} = e.target;
    this.setState(() => ({
     pageNumbers: value,
     pageData:{
        id:1,
        pageNumber:value,

     },
    
     isDisplay: e.target.checked,
    
    }),
    () => {
      // Save isDisplay to localStorage
      localStorage.setItem("isDisplay", this.state.isDisplay);
    }
    );
    const{ isDisplay}=this.state
    console.log('THIS IS',isDisplay);
    // SecretService.updatePages(this.state.pageData.id,this.state.pageData).then(
    //     (res)=>{
    //         console.log("AKILI:"+this.state.pageData.id,this.state.pageData.pageNumber)
    //         return res
    //     }
       
    // )
   
  };
 handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ loading: true, error: '' });
   const{pageNumbers}=this.state;
   const data={
    pageNumbers
   }
  
  
 }
 render() {
    const{pageNumbers,isDisplay}=this.state;
   const{totalPages,totalLinkedPages,totalUnlinkedPages}=this.props
   return(
   
<div className="p-10 bg-gray-100 h-full">
        <div className="col p-8">
        <LittleSecret isVisible={true} data={totalPages?.data[0].PageNumber}  timestamp={this.state.timestamp}/>
     
     
        {true && (
  <>
    <StatsCard
      title="Total linked Pages:"
      value={totalLinkedPages?.data[0].PageNumber || ""}
      timestamp={this.state.timestamp}
    />
    <StatsCard
      title="Total Unlinked Pages:"
      value={totalUnlinkedPages?.data[0].PageNumber || ""}
      timestamp={this.state.timestamp}
    />
  </>
)}
        <FormControl component="fieldset" variant="standard">
    <FormLabel component="legend">Assign responsibility</FormLabel>
    <FormControlLabel
          control={
            <Switch checked={isDisplay} onChange={this.handleChange} name="gilad" />
          }
          label="Turn on page label"
        />
    </FormControl>
        <form onSubmit={this.handleSubmit}>
        <div className="row space-x-6 my-6">
              <div className="flex-1 col">
              
                <label htmlFor="names">
                  Enter page number:
                 
                </label>
                <input
                  type="number"
                  name="pages"
                  id="pages"
                  value={pageNumbers}
                  onChange={this.handleChange}
                  className="border rounded-lg p-3"
                  placeholder="Fill in this field"
                 
                />
                
              </div>
      
              </div>
        </form>
       
         </div>
        </div>
   );
 }
}
const mapStateToProps = (state) => {
    const { 
      totalPages,
      totalLinkedPages,
      totalUnlinkedPages
    } = state.documents;
    return {
      totalPages,
      totalLinkedPages,
      totalUnlinkedPages,
    };
  };

  const mapDispatchToProps = {
    getTotalPagesByStatus,
    getTotalLinked,
    getTotalUnlinked,
  };
 
  export default connect(mapStateToProps,mapDispatchToProps)(SetSecret);
