import axios from "axios";
import { request } from "http";
import { UPDATE_SECRET,LOAD_SECRET_SUCCESS,LOAD_SECRET_ERROR,UPDATE_SECRET_SUCCESS,UPDATE_SECRET_ERROR, LOAD_SECRET } from "./types";
import { getTotalPagesByStatus } from "./documents";
  
const baseUrl="http://localhost:3008/";
const originUrl=window.location.origin;
const theUrl=originUrl.split(':')[1]
const api = axios.create({
            baseURL:theUrl+":"+process.env.REACT_APP_SECRET_PORT,
            
 })


 class SecretService{
   async getPages (){
    
        try {
           const json= await api.get("pagenumbers")
           console.log("TESTIFY MONEY",json.data[0].pageNumber);
           return json.data[0].pageNumber;
        } catch (error) {
          return 0;
        }
       }

    async updatePages(id,data){
        const originUrl=window.location.origin; 
        try {
        const json=await api.patch(`pagenumbers/${id}`,data,{
            headers: {
                'Content-Type': 'application/json',
            }
        })

            console.log("HEHEHEY:",json.data)
            return json.data;
           
        } catch (error) {
            console.log("DORE ISHYANO:"+error,data)
            return error;
        }
    }
       
    async getPageNumbers(){
       return getTotalPagesByStatus();
    }
}

export default new SecretService();
/*export const updatePage = (id, data) => async (dispatch) => {
    dispatch({
        type:UPDATE_SECRET_SUCCESS
     })
	try {
		const json= await axios.get("data.json")
	} catch (error) {
		dispatch(authType(UPDATE_SUSPECT_ERROR, error.response));
	}
};*/