export const formatProvincesData = (data) => {
  const formattedData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "Documents by province",
        data: Object.values(data),
        backgroundColor: [
          "rgba(40, 100, 254, 1)",
          "rgba(59, 76, 86, 1)",
          "rgba(55, 97, 111, 1)",
          "rgba(101, 138, 154, 1)",
          "rgba(109, 151, 129, 1)",
        ],
        borderWidth: 0.5,
      },
    ],
  };
  return formattedData;
};

export const formatDistrictsData = (data) => {
  const formattedData = {
    labels: Object.keys(data),
    datasets: [
      {
        label: "Documents by district",
        data: Object.values(data),
        backgroundColor: [
          "rgba(26, 188, 156,1.0)",
          "rgba(26, 188, 156,0.5)",
          "rgba(46, 204, 113,1.0)",
          "rgba(46, 204, 113,0.5)",
          "rgba(52, 152, 219,1.0)",
          "rgba(52, 152, 219,0.5)",
          "rgba(52, 73, 94,1.0)",
          "rgba(52, 73, 94,0.5)",
          "rgba(22, 160, 133,1.0)",
          "rgba(22, 160, 133,0.5)",
          "rgba(39, 174, 96,1.0)",
          "rgba(39, 174, 96,0.5)",
          "rgba(41, 128, 185,1.0)",
          "rgba(41, 128, 185,0.5)",
          "rgba(44, 62, 80,1.0)",
          "rgba(44, 62, 80,0.5)",
          "rgba(241, 196, 15,1.0)",
          "rgba(241, 196, 15,0.5)",
          "rgba(230, 126, 34,1.0)",
          "rgba(230, 126, 34,0.5)",
          "rgba(231, 76, 60,1.0)",
          "rgba(231, 76, 60,0.5)",
          "rgba(149, 165, 166,1.0)",
          "rgba(149, 165, 166,0.5)",
          "rgba(243, 156, 18,1.0)",
          "rgba(243, 156, 18,0.5)",
          "rgba(211, 84, 0,1.0)",
          "rgba(211, 84, 0,0.5)",
          "rgba(192, 57, 43,1.0)",
          "rgba(192, 57, 43,0.5)",
        ],
        borderWidth: 0.5,
      },
    ],
  };

  return formattedData;
};
