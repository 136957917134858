import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

export default function PDFRenderer(props) {
	const [numPages, setNumPages] = useState(null);

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const { pdf } = props;

	return (
		<Document
			file={pdf}
			options={{ workerSrc: "/pdf.worker.js" }}
			onLoadSuccess={onDocumentLoadSuccess}>
			{Array.from(new Array(numPages), (el, index) => (
				<Page key={`page_${index + 1}`} pageNumber={index + 1} />
			))}
		</Document>
	);
}
