import axios from "axios";
import { toast } from "react-toastify";
import { removeToken } from "../helpers/tokenHelper";
import history from "./history";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With",
    Accept: "application/json",
  },
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      toast.error("Expired session, please try to login again");
      removeToken();
      history.push("/login");
    }

    return Promise.reject(error);
  }
);

export default instance;

