import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

const PieChart = ({ data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef?.current.getContext("2d");
    if (!ctx) {
      return;
    }

    const chartInstance = new Chart(ctx, {
      type: "pie",
      data,
    });

    return () => {
      chartInstance.destroy();
    };
  }, [data]);

  return <canvas ref={chartRef} />;
};

export default PieChart;
