import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';



const UserCard=({names,email,phone,id})=> {
    return (
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Names:
          </Typography>
          <Typography variant="h5" component="div">
            {names}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary">
            Email:
          </Typography>
          <Typography variant="h5" component="div">
            {email}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small"></Button>
        </CardActions>
      </Card>
    );
  }
  export default UserCard;
  