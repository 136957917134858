import React, { Component } from "react";

export class NotFound extends Component {
	render() {
		return (
			<div className="flex w-full h-screen justify-center items-center">
				Not Found
			</div>
		);
	}
}

export default NotFound;
