import React, { Component } from "react";
import { AiOutlineLeft, AiOutlineLink } from "react-icons/ai";
import { connect } from "react-redux";
import moment from "moment";
import { BounceLoader } from "react-spinners";
import NotIndexed from "../components/NotIndexed";
import {
	IoDocumentTextSharp,
	IoCalendarOutline,
	IoDocumentText,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import Indexed from "../components/Indexed";
import { getSpecificDocument } from "../redux/actions/documents";
import PDFRenderer from "../components/PDFRenderer";

export class IndexDetails extends Component {
	state = {
		file: "",
	};
	componentDidMount = async () => {
		const { id } = this.props.match.params;
		this.setState({ loading: true });
		const { getSpecificDocument } = this.props;
		await getSpecificDocument(id);
		this.setState({ loading: false });
	};
	render() {
		const { specificDocumentData } = this.props;
		if (specificDocumentData) {
			const {
				id,
				documentName,
				documentStatus,
				createdAt,
				DocumentType,
				GeneralAssembly,
				CourtOfCell,
				file,
				Suspect,
			} = specificDocumentData.data;
			return (
				<div>
					<div className="p-10 bg-gray-100">
						<Link
							to="/dashboard/files"
							className="row items-center text-gdrBlue text-xl"
						>
							<AiOutlineLeft /> &nbsp;
							<p>Back to search results</p>
						</Link>
						<div>
							<div className="pt-8">{documentName}</div>
							<div className="p-4 my-4 border rounded-xl row bg-white">
								<div className="flex-1 row items-center">
									<IoDocumentText className="text-gdrBlue text-2xl" /> &nbsp;{" "}
									{documentName}
								</div>
								<div className="flex-1">
									{moment(createdAt).format("MMMM DD, YYYY")}
								</div>
								<div className="flex-1">{`${GeneralAssembly.name}, ${CourtOfCell.name}`}</div>
								<div className="flex-1">{DocumentType.name}</div>
								<div className="flex-1">
									<span
										className={`${
											documentStatus === "fully-indexed"
												? `bg-green-100 px-2 text-green-600 rounded-sm`
												: documentStatus === "linked"
												? `bg-blue-100 px-2 text-blue-600 rounded-sm`
												: documentStatus === "not-indexed"
												? `bg-red-100 px-2 text-red-600 rounded-sm`
												: ``
										}`}
									>
										#{documentStatus}
									</span>
								</div>
							</div>
							<div className="grid md:grid-cols-2 border rounded-xl bg-white">
								<div className="border-r col">
									{DocumentType.name === "Charge sheet" &&
									documentStatus === "not-indexed" ? (
										<NotIndexed id={id} />
									) : documentStatus === "linked" ? (
										<div>
											<div className="p-8 rounded-md text-xl">
												<p className="text-gdrBlue text-xl">
													This {DocumentType.name} is linked to:
												</p>
												<div className="row items-center my-4 text-xl">
													Names:&nbsp;
													<Link
														to={`/dashboard/suspects/${Suspect.id}`}
														className="text-gdrBlue row items-center"
													>
														<AiOutlineLink />{Suspect.names}
													</Link>
												</div>
												<div className="my-4">
													Father: {Suspect.fatherNames}
												</div>
												<div className="my-4">
													Mother: {Suspect.motherNames}
												</div>
												<div className="my-4">
													Profession: {Suspect.profession}
												</div>
												<div className="row items-start my-4">
													<div className="">Crime(s): </div>
													<div className="col">
														{Suspect.crimes
															? Suspect.crimes.map(({ id, name }) => (
																	<div key={id} className="px-2">
																		<li>{name}</li>
																	</div>
															  ))
															: ""}
													</div>
												</div>
												<div className="my-4">
													Sentence description: {Suspect.description}
												</div>
												<div className="my-4">
													Gender: {Suspect.gender}
												</div>
												<div className="my-4 text-gdrBlue row items-center">
													<IoCalendarOutline /> &nbsp; &nbsp; Birth Date:{" "}
													{Suspect.DOB}
												</div>
												<div className="my-4">
													Status: &nbsp;
													<span className="bg-green-100 px-2 text-green-600 rounded-sm">
														{Suspect.state} documents out of 33
													</span>
												</div>
											</div>
										</div>
									) : (
										<Indexed id={id} />
									)}
								</div>
								<div className="col">
									<div className="border-b row items-center p-4 py-6 font-semibold">
										<IoDocumentTextSharp /> &nbsp; {DocumentType.name}
									</div>
									<div className="bg-gray-100 p-4 h-full w-full overflow-y-scroll">
										{/* <iframe
											title="title"
											src={`${this.state.file}`}
											className="h-full w-full"
										></iframe> */}
										{/* <img
											src={`${this.state.file}`}
											alt=""
											className="w-full h-full"
										/> */}
										{/* <Document file={this.state} /> */}
										<p onContextMenu={(e) => e.preventDefault()}>
										<PDFRenderer
                                          
											pdf={`${process.env.REACT_APP_BASE}${file}`}    
										/>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="col h-screen w-full justify-center items-center">
					<BounceLoader color="rgba(40, 100, 254, 1)" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const {
		specificDocumentData,
		specificDocumentError,
	} = state.documents;
	return {
		specificDocumentData,
		specificDocumentError,
	};
};

export default connect(mapStateToProps, {
	getSpecificDocument,
})(IndexDetails);
