import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { connect } from "react-redux";
import DataTable from "../components/DataTable";
import {
	formatAssemblies,
	formatCourts,
	formatDistricts,
	formatProvinces,
	formatSectors,
} from "../helpers/formatAdministration";
import { getSuspectsByName, getSuspects } from "../redux/actions/documents";
import { suspectHeaders } from "../Utils/tableHeaders";
import getParams from "../Utils/getParams";
import search from "../assets/images/search.svg";

export class SuspectsList extends Component {
	state = {
		province: "",
		district: "",
		sector: "",
		generalAssembly: "",
		courtOfCell: "",
		names: "",
		fatherNames: "",
		motherNames: "",
		page: 1,
		limit: 25,
		loading: false,
	};
	componentDidMount = async () => {
		const provinces = formatProvinces();
		this.setState(() => ({
			provinces,
		}));
	};
	async componentDidUpdate(prevProps, prevState) {
		const {
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			names,
			fatherNames,
			motherNames,
			page,
			limit
		} = this.state;
		const { getSuspects } = this.props;

		if ((prevState.province !== province && province) ||
			(prevState.district !== district && district) ||
			(prevState.sector !== sector && sector) ||
			(prevState.generalAssembly !== generalAssembly && generalAssembly) ||
			(prevState.courtOfCell !== courtOfCell && courtOfCell) ||
			prevState.page !== page ||
			prevState.limit !== limit) {
				// Search
		    this.setState({ loading: true });
				const options = {
					province,
					district,
					sector,
					generalAssembly,
					courtOfCell,
					names,
					fatherNames,
					motherNames,
					page: page + 1,
					limit,
				};
			const params = getParams(options);

			await getSuspects(params);
			this.setState({ loading: false });
		}
	}
	clearFields = (e) => {
		e.preventDefault();
		this.setState(() => ({
			province: "",
			district: "",
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
			accusedName: "",
			page: 0,
			limit: 25,
		}));
	};
	handleChangeProvince =  (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			district: "",
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
			page: 0,
			limit: 25,
			searchResults: [],
		}));
	};
	handleChangeDistrict =  (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
			page: 0,
			limit: 25,
			searchResults: [],
		}));
	};
	handleChangeSector =  (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			generalAssembly: "",
			courtOfCell: "",
			page: 0,
			limit: 25,
			searchResults: [],
		}));
	};
	handleChangeAssembly =  (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			courtOfCell: "",
			page: 0,
			limit: 25,
			searchResults: [],
		}));
	};
	handleChangeCourt =  (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			page: 0,
			limit: 25,
			searchResults: [],
		}));
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleSearch = async (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
			page: 0,
			limit: 25,
		});
		const {
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			names,
			fatherNames,
			motherNames,
		} = this.state;
		const { getSuspects } = this.props;
		const options = {
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			names,
			fatherNames,
			motherNames,
			page: 1,
			limit: 25,
		};
		const params = getParams(options);

		await getSuspects(params);

		this.setState({ loading: false });
	};
	handleChangePage = async (page, limit) => {
		this.setState({
			page,
			limit,
		});
	}

	render() {
		const {
			provinces,
			province,
			district,
			sector,
			generalAssembly,
			courtOfCell,
			names,
			fatherNames,
			motherNames,
			page,
			limit,
			loading,
		} = this.state;
		const districts = formatDistricts(Number(province));
		const sectors = formatSectors(Number(province), Number(district));
		const generalAssemblies = formatAssemblies(
			Number(province),
			Number(district),
			Number(sector),
		);
		const courts = formatCourts(
			Number(province),
			Number(district),
			Number(sector),
			Number(generalAssembly),
		);
		let searchResults;
		const { suspectsData } = this.props;

		if (suspectsData?.data) {
			searchResults = suspectsData.data.suspects.map(
				({ id, DOB, names, fatherNames, motherNames, state }) => ({
					id,
					names,
					fatherNames,
					motherNames,
					DOB,
					state: (
						<span className="bg-green-100 px-2 text-green-600 rounded-sm">
							{state} documents out of 33
						</span>
					),
				}),
			);
		}

		return (
			<div>
				<div className="px-10 pt-10 flex items-center">
				    <div className="text-3xl">Search accused by names</div>
					<div className="ml-auto">
					    <NavLink
					        to="/dashboard/suspects/add"
					    	className="p-3 px-6 rounded-md bg-gdrBlue text-white outline-none">
					    	Add Suspect
					    </NavLink>
					</div>
				</div>
				<form onSubmit={this.handleSearch} className="px-10">
					<div className="row flex-wrap gap-6 mt-6">
					<div className="col m-2 flex-1">
							<p>Province</p>
							<select
								className="w-full border rounded-md bg-white p-2"
								name="province"
								value={province}
								onChange={this.handleChangeProvince}
							>
								<option value="" disabled={province}>
									Select
								</option>
								{provinces
									? provinces.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
									  ))
									: ""}
							</select>
						</div>
						<div className="col m-2 flex-1">
							<p>District</p>
							<select
								className="w-full border rounded-md bg-white p-2"
								name="district"
								value={district}
								onChange={this.handleChangeDistrict}
								disabled={!province}
							>
								<option value="" disabled={district}>
									Select
								</option>
								{districts
									? districts.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
									  ))
									: ""}
							</select>
						</div>
						<div className="col m-2 flex-1">
							<p>Sector</p>
							<select
								className="w-full border rounded-md bg-white p-2"
								name="sector"
								value={sector}
								onChange={this.handleChangeSector}
								disabled={!district}
							>
								<option value="" disabled={sector}>
									Select
								</option>
								{sectors
									? sectors.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
									  ))
									: ""}
							</select>
						</div>
						<div className="col m-2 flex-1">
							<p>General Assembly</p>
							<select
								className="w-full border rounded-md bg-white p-2"
								name="generalAssembly"
								value={generalAssembly}
								onChange={this.handleChangeAssembly}
								disabled={!sector}
							>
								<option value="" disabled={sector}>
									Select
								</option>
								{generalAssemblies
									? generalAssemblies.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
									  ))
									: ""}
							</select>
						</div>
						<div className="col m-2 flex-1">
							<p>Court</p>
							<select
								className="w-full border rounded-md bg-white p-2"
								name="courtOfCell"
								value={courtOfCell}
								onChange={this.handleChangeCourt}
								disabled={!generalAssembly}
							>
								<option value="" disabled={courtOfCell}>
									Select
								</option>
								{courts
									? courts.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
									  ))
									: ""}
							</select>
						</div>

						<div className="col self-end my-2">
							<button
								className="p-2 px-4 rounded-md bg-gdrBlue text-white outline-none"
								type="reset"
								onClick={this.clearFields}
								disabled={!province && !district && !sector && !generalAssembly && !courtOfCell}
							>
								Clear
							</button>
						</div>
						</div>
					<div className="row gap-6 mb-6">
						<div className="flex-1 col m-2">
							<label htmlFor="">Accused names</label>
							<div className="row items-center border rounded-lg p-3">
								<input
									type="text"
									name="names"
									value={names}
									onChange={this.handleChange}
									className="w-full focus:outline-none"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						<div className="flex-1 col m-2">
							<label htmlFor="">Father names</label>
							<div className="row items-center border rounded-lg p-3">
								<input
									type="text"
									name="fatherNames"
									value={fatherNames}
									onChange={this.handleChange}
									className="w-full focus:outline-none"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						<div className="flex-1 col m-2">
							<label htmlFor="">Mother names</label>
							<div className="row items-center border rounded-lg p-3">
								<input
									type="text"
									name="motherNames"
									value={motherNames}
									onChange={this.handleChange}
									className="w-full focus:outline-none"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						<div className="col my-2 self-end">
							<button
								className="p-3 px-6 rounded-md bg-gdrBlue text-white outline-none"
								type="submit"
								onClick={this.handleSearch}
								disabled={!names && !fatherNames && !motherNames}
							>
								Search
							</button>
						</div>
					</div>
				</form>
				<hr />

				{loading?<div className="col h-screen w-full justify-center items-center">
							<BounceLoader color="rgba(40, 100, 254, 1)" />
						</div>:<div className="col p-10">
					{searchResults ? searchResults.length ? (
							<div className="">
								<p className="text-gdrBlue text-xl">Search results</p>
								<div className="my-4">
									<DataTable
										data={searchResults}
										count={suspectsData?.data.totalResults}
								        rowsPerPage={limit}
								        page={page}
										headers={suspectHeaders}
										path={"dashboard/suspects"}
										onChangePage={this.handleChangePage}
									/>
								</div>
							</div>
						) : (
							<div className="col w-full items-center justify-center">
							<div className="text-red-400">
								<p>No matching results were found</p>
							</div>
							</div>
						): <div className="col w-full items-center justify-center">
						<div>
						<img src={search} alt="" />
						<p className="text-2xl p-4">Search for an accused</p>
						</div>
					</div>}
				</div>}
				<div>
					{/* {loading ? (
						<div className="col h-screen w-full justify-center items-center">
							<BounceLoader color="rgba(40, 100, 254, 1)" />
						</div>
					) : (
						<div>
							{searchResults.length ? (
								<div className="p-10">
									<p className="text-gdrBlue text-xl">Search results</p>
									<div className="my-4">
										<DataTable
											headers={indexHeaders}
											data={searchResults}
											path={"dashboard/files"}
										/>
									</div>
								</div>
							) : (
								<div className="col w-full items-center justify-center p-10">
									{!province ||
									!district ||
									!sector ||
									!court ||
									!documentType ||
									!documentStatus ? (
										<div>
											<img src={search} alt="" />
											<p className="text-2xl p-4">Search for a document</p>
										</div>
									) : (
										<div>
											<img src={search} alt="" />
											<p className="text-2xl p-4">No documents found</p>
										</div>
									)}
								</div>
							)}
						</div>
					)} */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { suspectsData, suspectsError } = state.documents;
	return {
		suspectsData,
		suspectsError,
	};
};

export default connect(mapStateToProps, { getSuspectsByName, getSuspects })(SuspectsList);
