import React from "react";
import { TableContainer } from "@material-ui/core";

export default function Table(props) {
  const { data, headers, path } = props;

  if (data === undefined || data === null || !data.length)
    return <div className="col items-center">No data found</div>;
  else if (headers === undefined || headers === null)
    return <div>No table headers provided</div>;
  else {
    return (
      <TableContainer>
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="bg-gray-100 text-gray-500">
              {headers.map((header) => (
                <th key={header} className="font-normal p-4">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-left border-none">
            {data.map((row) => (
              <tr key={row.id} className="text-left hover:bg-gray-100">
                {Object.values(row)
                  .slice(1)
                  .map((item) => (
                    <td key={item} className="border-b p-4">
                      {item}
                    </td>
                  ))}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    );
  }
}
