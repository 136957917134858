import React, { Component } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { decodeToken, isLoggedIn, removeToken } from "../helpers/tokenHelper";

export class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...rest } = this.props;
    const isAuthenticated = isLoggedIn();

    return (
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  }
}
const mapStateToProps = (state) => {
  const { isLoggedIn } = state.users;
  return {
    isLoggedIn,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
