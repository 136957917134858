import { Component } from "react";
import { connect } from "react-redux";
import UserCard from "../components/UserCard";
import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { 
    getOneUser,
    resetPassword
 } from "../redux/actions/users";
 
export class ChangePassword extends Component{
    state = {
      
		names: "",
		email: "",
		phone: "",
		nationalId: "",
		roleId: "",
		status: "",
        password:"",
        confirmPassword:"",
		loading: false,
		editable: false,
        error:"",
        userId:"",
	};
   
    componentDidMount = async () => {
		const { id } = this.props.match.params;
		const {getOneUser} = this.props;
		this.setState({ loading: true });
		await getOneUser(id);
		this.setValues();
		this.setState({ loading: false });
	};

    setValues = () => {
		const { userData } = this.props;
		if (userData !== null) {
			this.setState(() => ({
				names:userData.data.names,
				 email: userData.data.email,
				 phone: userData.data.phone.split(" ").join(""),
				 nationalId: userData.data.nationalId,
				 roleId: userData.data.roleId,
				 status: userData.data.status,
                 userId: userData.data.id,  
			}));
		}
	};
  
    handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
    clearFields = (e) => {
        e.preventDefault();
        this.setState(() => ({
          names: "",
          email: "",
          phone: "",
          nationalId: "",
          roleId: "",
          password: "",
          error: "",
          confirmPassword:""
        }));
      };
    handleSubmitChange =async (e)=>{
        e.preventDefault();
        this.setState({ loading: true, error: '' });
        try {
           const {password,confirmPassword,userId}=this.state; 
           const {resetPassword,setPasswordError}=this.props;
           
           if(password===confirmPassword){
            await resetPassword(userId,password);
			this.setState({ loading: false });
            this.clearFields();
          
           }
        
           this.setState({
            error: "Password must be identical",
            loading: false,
          });

		
		  
        } catch (error) {
            console.log("This is because of:"+error);
           
          }

    }
    render(){
   
    
        const {
          
			names,
			email,
			phone,
			nationalId,
			roleId,
			status,
			loading,
			editable,
            password,
            confirmPassword,
            userId,
            error
		} = this.state;
	    const{setPasswordError} =this.props;
        return(
            <div>
                <div className="col p-8 items-center">
					<div className="p-8 rounded-md shadow-lg">
						<p className="text-center italic font-light text-gdrBlue">
							CHANGING PASSWORD FOR
						</p>
                        <UserCard
                         names={names}
                         email={email}
                         phone={phone}
                         id={status}
                        />
                          <form action="" onSubmit={this.handleSubmitChange}>
                            <div className="text-red-500 my-4">{error}</div>
					{setPasswordError &&  <div className="text-red-500 my-4">Password must include a number,6 character and a Capital letter at least</div>}
                          <div>
									<input
										type="password"
										name="password"
										value={password}
										onChange={this.handleChange}
										placeholder="Password"
										className="p-4 border w-full border-black rounded-md my-2 text-lg"
										required
									/>
								</div>
                                <div>
									<input
										type="password"
										name="confirmPassword"
										value={confirmPassword}
										onChange={this.handleChange}
										placeholder="Confirm password"
										className="p-4 border w-full border-black rounded-md my-2 text-lg"
										required
									/>
								</div>
                                <div>
									<button
										className="bg-gdrBlue text-white text-lg w-full p-4 rounded-md my-2"
										type="submit"
										
									>
										Change Password
									</button>
								</div>
                          </form>
        
                     </div>  
                </div>
            </div>
        );
    }

}
const mapStateToProps = (state) => {
	const {
		userData,
		userError,
		resetPasswordData,
		setPasswordError,
	} = state.users;
	
	return {
		userData,
		userError,
		resetPasswordData,
		setPasswordError,
	
		
	};
};

export default connect(mapStateToProps, {
	getOneUser,
	resetPassword,
	
})(ChangePassword);
