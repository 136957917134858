import React from "react";

const StatsLocationCard = ({ title, uploadedDocuments, linkedDocuments }) => {
  return (
    <div className="w-full md:w-1/5 p-2">
    <div className="w-full h-full bg-gray-50 p-4 px-6 shadow-lg">
      <p>{title}</p>
      <div className="pl-4 pt-4 space-y-2">
        <div>
          <p>Uploaded Documents:</p>
          <p className="text-xl text-gdrBlue">{uploadedDocuments}</p>
        </div>
        <div>
          <p>Linked Documents:</p>
          <p className="text-xl text-gdrBlue">{linkedDocuments}</p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default StatsLocationCard;
