import React, { Component } from "react";
import { connect } from "react-redux";
import { BeatLoader } from "react-spinners";
import { setPassword } from "../redux/actions/users";

export class SetPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    loading: false,
    error: false,
  };
  componentDidUpdate() {
    const { setPasswordData, history } = this.props;

    if (setPasswordData?.message) {
      history.push("/login");
    }
  }
  handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    this.setState(() => ({
      [name]: value,
    }));
  };
  handleCreate = async (e) => {
    e.preventDefault();
    this.setState({ error: false, loading: true });

    const { password, confirmPassword } = this.state;
    if (!password.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/)) {
      this.setState({
        error:
          "Password must be at least 6 characters long and must include at least a number and a capital letter",
        loading: false,
      });
      return;
    }
    if (password !== confirmPassword) {
      this.setState({
        error: "Passwords don't match",
        loading: false,
      });
      return;
    }

    const { setPassword, match } = this.props;
    const { token } = match.params;

    await setPassword(password, token);

    this.setState({ loading: false });
  };
  render() {
    const { password, confirmPassword, loading, error } = this.state;
    const { setPasswordError } = this.props;

    return (
      <div>
        <div className="col h-screen w-screen justify-center items-center bg-lightGray relative">
          <div className="login-form absolute max-w-lg z-20">
            <form action="" onSubmit={this.handleCreate}>
              <p className="my-8 text-center text-4xl font-semibold text-darkGray">
                GACACA CASE FILING
                {/* <span className="font-semibold">Filing</span> */}
              </p>
              <div className="bg-white p-16 rounded-lg shadow-lg">
                <p className="text-3xl">Create Password</p>
                <p className="my-4">
                  Create password to login to your account to enjoy all the
                  services of the GCF System
                </p>
                <div>
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    placeholder="Password"
                    className="p-4 border w-full border-black rounded-md my-2 text-lg"
                    required
                  />
                </div>
                <div>
                  <input
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.handleChange}
                    placeholder="Confirm Password"
                    className="p-4 border w-full border-black rounded-md my-2 text-lg"
                    required
                  />
                </div>
                {error && <div className="my-2 text-red-500">{error}</div>}
                {setPasswordError?.data && (
                  <div className="my-2 text-red-500">
                    {setPasswordError.data.error}
                  </div>
                )}
                <div>
                  <button
                    className="bg-gdrBlue text-white text-lg w-full p-4 rounded-md my-2"
                    type="submit"
                    disabled={!password || !confirmPassword}
                  >
                    {loading ? <BeatLoader color="#FFF" /> : "Create"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { setPasswordData, setPasswordError } = state.users;
  return { setPasswordData, setPasswordError };
};

export default connect(mapStateToProps, { setPassword })(SetPassword);
