import React, { Component } from "react";
import { connect } from "react-redux";
// import gdrBlob1 from "../assets/images/gdrBlob1.svg";
// import gdrBlob2 from "../assets/images/gdrBlob2.svg";
import { BeatLoader } from "react-spinners";
// import { Link } from "react-router-dom";
import { login } from "../redux/actions/users";

export class Login extends Component {
	state = {
		email: "",
		password: "",
		loading: false,
		error: false,
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleLogin = async (e) => {
		e.preventDefault();
		this.setState({ error: false, loading: true });
		const { email, password } = this.state;
		const data = { email, password };
		const { login } = this.props;
		const result = await login(data);
		if (!result) {
			this.setState(() => ({
				error: true,
			}));
		}
		this.setState({ loading: false });
	};
	render() {
		const { email, password, loading, error } = this.state;
		return (
			<div>
				<div className="col h-screen w-screen justify-center items-center bg-lightGray relative">
					{/* <div className="h-full w-full overflow-hidden relative">
						<div
							className="absolute"
							style={{
								height: "80rem",
								width: "80rem",
								top: "-35rem",
								left: "-20rem",
							}}
						>
							<img src={gdrBlob1} alt="" />
						</div>
						<div
							className="absolute"
							style={{
								height: "80rem",
								width: "80rem",
								bottom: "-35rem",
								right: "-23rem",
							}}
						>
							<img src={gdrBlob2} alt="" />
						</div>
					</div> */}

					<div className="login-form absolute max-w-lg z-20">
						<form action="" onSubmit={this.handleLogin}>
							<p className="my-8 text-center text-4xl font-semibold text-darkGray">
								GACACA CASE FILING
								{/* <span className="font-semibold">Filing</span> */}
							</p>
							<div className="bg-white p-16 rounded-lg shadow-lg">
								<p className="text-3xl">Login</p>
								<p className="my-4">
									Login to your account to enjoy all the services of the GCF
									System
								</p>
								<div>
									<input
										type="email"
										name="email"
										value={email}
										onChange={this.handleChange}
										placeholder="Email Address"
										className="p-4 border w-full border-black rounded-md my-2 text-lg"
										required
									/>
								</div>
								<div>
									<input
										type="password"
										name="password"
										value={password}
										onChange={this.handleChange}
										placeholder="Password"
										className="p-4 border w-full border-black rounded-md my-2 text-lg"
										required
									/>
								</div>
								{error && (
									<div className="my-2 text-red-500">
										Incorrect email or password
									</div>
								)}
								<div>
									<button
										className="bg-gdrBlue text-white text-lg w-full p-4 rounded-md my-2"
										type="submit"
										disabled={!email || !password}
									>
										{loading ? <BeatLoader color="#FFF" /> : "Login"}
									</button>
								</div>
								{/* <div className="mt-8">
									<p>
										Don't have an account?{" "}
										<span>
											<Link to="#">Sign Up</Link>
										</span>
									</p>
								</div> */}
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { authData, authError } = state.users;
	return { authData, authError };
};

export default connect(mapStateToProps, { login })(Login);
