export const EXAMPLE = "EXAMPLE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";
export const GET_DOCUMENT_TYPES = "GET_DOCUMENT_TYPES";
export const GET_DOCUMENT_TYPES_FAILURE = "GET_DOCUMENT_TYPES_FAILURE";
export const DOCUMENTS_BY_TYPE = "DOCUMENTS_BY_TYPE";
export const DOCUMENTS_BY_TYPE_ERROR = "DOCUMENTS_BY_TYPE_ERROR";
export const SPECIFIC_DOCUMENT = "SPECIFIC_DOCUMENT";
export const SPECIFIC_DOCUMENT_ERROR = "SPECIFIC_DOCUMENT_ERROR";
export const GET_CRIMES = "GET_CRIMES";
export const GET_CRIMES_ERROR = "GET_CRIMES_ERROR";
export const GET_CRIMES_CATEGORIES = "GET_CRIMES_CATEGORIES";
export const GET_CRIMES_CATEGORIES_ERROR = "GET_CRIMES_CATEGORIES_ERROR";
export const LINK_DOCUMENT = "LINK_DOCUMENT";
export const LINK_DOCUMENT_ERROR = "LINK_DOCUMENT_ERROR";
export const GET_SUSPECTS = "GET_SUSPECTS";
export const GET_SUSPECTS_ERROR = "GET_SUSPECTS_ERROR";
export const SUSPECTS_BY_NAMES = "SUSPECTS_BY_NAMES";
export const SUSPECTS_BY_NAMES_ERROR = "SUSPECTS_BY_NAMES_ERROR";
export const GET_SPECIFIC_SUSPECT = "GET_SPECIFIC_SUSPECT";
export const GET_SPECIFIC_SUSPECT_ERROR = "GET_SPECIFIC_SUSPECT_ERROR";
export const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
export const UPLOAD_DOCUMENTS_ERROR = "UPLOAD_DOCUMENTS_ERROR";
export const UPLOAD_LINK_DOCUMENTS = "UPLOAD_LINK_DOCUMENTS";
export const UPLOAD_LINK_DOCUMENTS_ERROR = "UPLOAD_LINK_DOCUMENTS_ERROR";
export const CREATE_SUSPECT = "CREATE_SUSPECT";
export const CREATE_SUSPECT_ERROR = "CREATE_SUSPECT_ERROR";
export const UPLOAD_PROGRESS = "UPLOAD_PROGRESS";
export const CREATE_USER = "CREATE_USER";
export const CREATE_USER_ERROR = "CREATE_USER_ERROR";
export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_ERROR = "GET_ROLES_ERROR";
export const SET_PASSWORD = "SET_PASSWORD";
export const RESET_PASSWORD="RESET_PASSWORD";
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR";
export const GET_USERS = "GET_USERS";
export const GET_USER ="GET_USER";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const CHANGE_USER_STATUS_ERROR = "CHANGE_USER_STATUS_ERROR";
export const GET_TOTAL_CASES = "GET_TOTAL_CASES";
export const GET_COMPLETED_CASES = "GET_TCOMPLETED_CASES";
export const GET_PENDING_CASES = "GET_PENDING_CASES";
export const GET_TOTAL_DOCUMENTS_BY_PROVINCE = "GET_TOTAL_DOCUMENTS_BY_PROVINCE";
export const GET_TOTAL_DOCUMENTS_BY_DISTRICT = "GET_TOTAL_DOCUMENTS_BY_DISTRICT";
export const GET_DOCUMENTS_BY_PROVINCE = "GET_DOCUMENTS_BY_PROVINCE";
export const GET_LINKED_DOCUMENTS_BY_PROVINCE = "GET_LINKED_DOCUMENTS_BY_PROVINCE";
export const GET_DOCUMENTS_BY_DISTRICT = "GET_DOCUMENTS_BY_DISTRICT";
export const GET_LINKED_DOCUMENTS_BY_DISTRICT = "GET_LINKED_DOCUMENTS_BY_DISTRICT";
export const GET_DOCUMENTS_BY_SECTOR = "GET_DOCUMENTS_BY_SECTOR";
export const GET_LINKED_DOCUMENTS_BY_SECTOR = "GET_LINKED_DOCUMENTS_BY_SECTOR";
export const GET_DOCUMENTS_BY_GENERAL_ASSEMBLY = "GET_DOCUMENTS_BY_GENERAL_ASSEMBLY";
export const GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY = "GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY";
export const GET_DOCUMENTS_BY_COURT = "GET_DOCUMENTS_BY_COURT";
export const GET_LINKED_DOCUMENTS_BY_COURT = "GET_LINKED_DOCUMENTS_BY_COURT";
export const GET_STATS_ERROR = "GET_STATS_ERROR";
export const COUNT_TOTAL_UPLOADED_DOCUMENTS="COUNT_TOTAL_UPLOADED_DOCUMENTS";
export const COUNT_TOTAL_LINKED_DOCUMENTS="COUNT_TOTAL_LINKED_DOCUMENTS";
export const LOAD_SECRET="LOAD_SECRET";
export const LOAD_SECRET_SUCCESS="LOAD_SECRET_SUCCESS";
export const LOAD_SECRET_ERROR="LOAD_SECRETERROR";
export const UPDATE_SECRET="UPDATE_SECRET";
export const UPDATE_SECRET_SUCCESS="UPDATE_SECRET_SUCCESS";
export const UPDATE_SECRET_ERROR="UPDATE_SECRET_ERROR";
export const COUNT_TOTAL_PAGES_BY_STATUS="COUNT_TOTAL_PAGES_BY_STATUS";
export const COUNT_TOTAL_PAGE_LINKED="COUNT_TOTAL_PAGE_LINKED";
export const COUNT_TOTAL_PAGE_UNLINKED="COUNT_TOTAL_PAGE_UNLINKED";
export const  COUNT_TOTAL_PAGES="COUNT_TOTAL_PAGES";
export const COUNT_TOTAL_PAGES_ERROR="OUNT_TOTAL_PAGES_ERROR";

