export const administrationStructure = [
	{
		id: 4,
		name: "East",
		createdAt: "2021-05-03T07:57:21.181Z",
		updatedAt: "2021-05-03T07:57:21.181Z",
		districts: [
			{
				id: 407,
				name: "Bugesera",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40701,
						name: "Gashora",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070105,
								name: "Ramiro",
								sector_id: 40701,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070104,
								name: "Mwendo",
								sector_id: 40701,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070103,
								name: "Kagomasi",
								sector_id: 40701,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070102,
								name: "Kabuye",
								sector_id: 40701,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070101,
								name: "Biryogo",
								sector_id: 40701,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1,
								name: "Gashora",
								sector_id: 40701,
								courtOfCells: [
									{
										id: 1,
										name: "AKAGAKO",
										generalAssembly_id: 1,
									},
									{
										id: 2,
										name: "BIDUDU",
										generalAssembly_id: 1,
									},
									{
										id: 3,
										name: "BIRYOGO",
										generalAssembly_id: 1,
									},
									{
										id: 4,
										name: "DIHIRO",
										generalAssembly_id: 1,
									},
									{
										id: 5,
										name: "GIHANAMA",
										generalAssembly_id: 1,
									},
									{
										id: 6,
										name: "KAGOMASI",
										generalAssembly_id: 1,
									},
									{
										id: 7,
										name: "KANYONYOMBA",
										generalAssembly_id: 1,
									},
									{
										id: 8,
										name: "KURUGANDA",
										generalAssembly_id: 1,
									},
									{
										id: 9,
										name: "KARUTETE",
										generalAssembly_id: 1,
									},
									{
										id: 10,
										name: "KIRUHURA",
										generalAssembly_id: 1,
									},
									{
										id: 11,
										name: "KIVUGIZA",
										generalAssembly_id: 1,
									},
									{
										id: 12,
										name: "RUGUNGA",
										generalAssembly_id: 1,
									},
									{
										id: 13,
										name: "RUNZENZE",
										generalAssembly_id: 1,
									},
									{
										id: 14,
										name: "RUSHUBI",
										generalAssembly_id: 1,
									},
									{
										id: 15,
										name: "RWERU",
										generalAssembly_id: 1,
									},
									{
										id: 8976,
										name: "Gashora Court of Appeal ",
										generalAssembly_id: 1,
									},
									{
										id: 10509,
										name: "Gashora Gacaca court of sector ",
										generalAssembly_id: 1,
									},
								],
							},
							{
								id: 2,
								name: "Mwendo",
								sector_id: 40701,
								courtOfCells: [
									{
										id: 16,
										name: "BIDUDU",
										generalAssembly_id: 2,
									},
									{
										id: 17,
										name: "GAHARWA",
										generalAssembly_id: 2,
									},
									{
										id: 18,
										name: "GISENYI",
										generalAssembly_id: 2,
									},
									{
										id: 19,
										name: "KABUYE",
										generalAssembly_id: 2,
									},
									{
										id: 20,
										name: "KARIZINGE",
										generalAssembly_id: 2,
									},
									{
										id: 21,
										name: "KAYOVU",
										generalAssembly_id: 2,
									},
									{
										id: 22,
										name: "MIGINA",
										generalAssembly_id: 2,
									},
									{
										id: 23,
										name: "RUHANGA",
										generalAssembly_id: 2,
									},
									{
										id: 24,
										name: "RUHANURA",
										generalAssembly_id: 2,
									},
									{
										id: 25,
										name: "RWAGASIGA",
										generalAssembly_id: 2,
									},
									{
										id: 26,
										name: "RWETETO",
										generalAssembly_id: 2,
									},
									{
										id: 8977,
										name: "Mwendo Court of Appeal ",
										generalAssembly_id: 2,
									},
									{
										id: 10510,
										name: "Mwendo Gacaca court of sector ",
										generalAssembly_id: 2,
									},
								],
							},
						],
					},
					{
						id: 40702,
						name: "Juru",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070205,
								name: "Rwinume",
								sector_id: 40702,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070204,
								name: "Musovu",
								sector_id: 40702,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070203,
								name: "Mugorore",
								sector_id: 40702,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070202,
								name: "Kabukuba",
								sector_id: 40702,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070201,
								name: "Juru",
								sector_id: 40702,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 3,
								name: "Juru",
								sector_id: 40702,
								courtOfCells: [
									{
										id: 27,
										name: "NZABAKIZA",
										generalAssembly_id: 3,
									},
									{
										id: 28,
										name: "BISAGARA",
										generalAssembly_id: 3,
									},
									{
										id: 29,
										name: "GATORA",
										generalAssembly_id: 3,
									},
									{
										id: 30,
										name: "KAMATONGO",
										generalAssembly_id: 3,
									},
									{
										id: 31,
										name: "KIJOJWE",
										generalAssembly_id: 3,
									},
									{
										id: 32,
										name: "MAJANJA",
										generalAssembly_id: 3,
									},
									{
										id: 33,
										name: "NYAMIGENDE",
										generalAssembly_id: 3,
									},
									{
										id: 34,
										name: "RUGARAMA",
										generalAssembly_id: 3,
									},
									{
										id: 35,
										name: "TWABAGARAMA",
										generalAssembly_id: 3,
									},
									{
										id: 8978,
										name: "Juru Court of Appeal ",
										generalAssembly_id: 3,
									},
									{
										id: 10511,
										name: "Juru Gacaca court of sector ",
										generalAssembly_id: 3,
									},
								],
							},
							{
								id: 4,
								name: "Musovu",
								sector_id: 40702,
								courtOfCells: [
									{
										id: 36,
										name: "BITEGA",
										generalAssembly_id: 4,
									},
									{
										id: 37,
										name: "CYABASONGA",
										generalAssembly_id: 4,
									},
									{
										id: 38,
										name: "CYABATWA",
										generalAssembly_id: 4,
									},
									{
										id: 39,
										name: "KABUKUBA",
										generalAssembly_id: 4,
									},
									{
										id: 40,
										name: "KINGAJU",
										generalAssembly_id: 4,
									},
									{
										id: 41,
										name: "MBUYE",
										generalAssembly_id: 4,
									},
									{
										id: 42,
										name: "NYARUHURU",
										generalAssembly_id: 4,
									},
									{
										id: 43,
										name: "RUSHUBI",
										generalAssembly_id: 4,
									},
									{
										id: 8979,
										name: "Musovu Court of Appeal ",
										generalAssembly_id: 4,
									},
									{
										id: 10512,
										name: "Musovu Gacaca court of sector ",
										generalAssembly_id: 4,
									},
								],
							},
							{
								id: 5,
								name: "Rwinume",
								sector_id: 40702,
								courtOfCells: [
									{
										id: 44,
										name: "GIKURAZO",
										generalAssembly_id: 5,
									},
									{
										id: 45,
										name: "GISORORO",
										generalAssembly_id: 5,
									},
									{
										id: 46,
										name: "KIGWENGERI",
										generalAssembly_id: 5,
									},
									{
										id: 47,
										name: "KINIHIRA",
										generalAssembly_id: 5,
									},
									{
										id: 48,
										name: "KIRABO",
										generalAssembly_id: 5,
									},
									{
										id: 49,
										name: "KATARARA",
										generalAssembly_id: 5,
									},
									{
										id: 50,
										name: "MUGORORE",
										generalAssembly_id: 5,
									},
									{
										id: 51,
										name: "RWIMPYISI",
										generalAssembly_id: 5,
									},
									{
										id: 52,
										name: "SHONDEKA",
										generalAssembly_id: 5,
									},
									{
										id: 53,
										name: "TABARARI",
										generalAssembly_id: 5,
									},
									{
										id: 54,
										name: "UWIMPUNGA",
										generalAssembly_id: 5,
									},
									{
										id: 8980,
										name: "Rwinume Court of Appeal ",
										generalAssembly_id: 5,
									},
									{
										id: 10513,
										name: "Rwinume Gacaca court of sector ",
										generalAssembly_id: 5,
									},
								],
							},
						],
					},
					{
						id: 40703,
						name: "Kamabuye",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070305,
								name: "Tunda",
								sector_id: 40703,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070304,
								name: "Nyakayaga",
								sector_id: 40703,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070303,
								name: "Kampeka",
								sector_id: 40703,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070302,
								name: "Burenge",
								sector_id: 40703,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070301,
								name: "Biharagu",
								sector_id: 40703,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 6,
								name: "Burenge ",
								sector_id: 40703,
								courtOfCells: [
									{
										id: 55,
										name: "BIHARAGU",
										generalAssembly_id: 6,
									},
									{
										id: 56,
										name: "KAGENGE",
										generalAssembly_id: 6,
									},
									{
										id: 57,
										name: "KANYONYERA",
										generalAssembly_id: 6,
									},
									{
										id: 58,
										name: "MURAMBO",
										generalAssembly_id: 6,
									},
									{
										id: 59,
										name: "MUYIGI",
										generalAssembly_id: 6,
									},
									{
										id: 60,
										name: "NYARURAMA",
										generalAssembly_id: 6,
									},
									{
										id: 61,
										name: "REBERO",
										generalAssembly_id: 6,
									},
									{
										id: 62,
										name: "SENGA",
										generalAssembly_id: 6,
									},
									{
										id: 8981,
										name: "Burenge  Court of Appeal ",
										generalAssembly_id: 6,
									},
									{
										id: 10514,
										name: "Burenge  Gacaca court of sector ",
										generalAssembly_id: 6,
									},
								],
							},
							{
								id: 7,
								name: "Nyakayaga",
								sector_id: 40703,
								courtOfCells: [
									{
										id: 63,
										name: "KAMABUYE",
										generalAssembly_id: 7,
									},
									{
										id: 64,
										name: "KAMPEKA",
										generalAssembly_id: 7,
									},
									{
										id: 65,
										name: "MASANGANO",
										generalAssembly_id: 7,
									},
									{
										id: 66,
										name: "MUBUBA",
										generalAssembly_id: 7,
									},
									{
										id: 67,
										name: "MURAMBI-FATINKANDA",
										generalAssembly_id: 7,
									},
									{
										id: 68,
										name: "NDAMA",
										generalAssembly_id: 7,
									},
									{
										id: 69,
										name: "NTUNGAMO-MABUYE",
										generalAssembly_id: 7,
									},
									{
										id: 70,
										name: "NYAKAYAGA-TWIMBOGO",
										generalAssembly_id: 7,
									},
									{
										id: 71,
										name: "CYOGAMUYAGA-RUBIRIZI",
										generalAssembly_id: 7,
									},
									{
										id: 72,
										name: "RUSIBYA-TUNDA",
										generalAssembly_id: 7,
									},
									{
										id: 73,
										name: "TWURUZIRAMIRE",
										generalAssembly_id: 7,
									},
									{
										id: 74,
										name: "UWIBIRARO",
										generalAssembly_id: 7,
									},
									{
										id: 75,
										name: "UWUMUSAVE",
										generalAssembly_id: 7,
									},
									{
										id: 8982,
										name: "Nyakayaga Court of Appeal ",
										generalAssembly_id: 7,
									},
									{
										id: 10515,
										name: "Nyakayaga Gacaca court of sector ",
										generalAssembly_id: 7,
									},
								],
							},
						],
					},
					{
						id: 40704,
						name: "Mareba",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070405,
								name: "Rugarama",
								sector_id: 40704,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070404,
								name: "Rango",
								sector_id: 40704,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070403,
								name: "Nyamigina",
								sector_id: 40704,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070402,
								name: "Gakomeye",
								sector_id: 40704,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070401,
								name: "Bushenyi",
								sector_id: 40704,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 8,
								name: "Gakomeye",
								sector_id: 40704,
								courtOfCells: [
									{
										id: 76,
										name: "GATARE",
										generalAssembly_id: 8,
									},
									{
										id: 77,
										name: "GATINZA",
										generalAssembly_id: 8,
									},
									{
										id: 78,
										name: "GITWA",
										generalAssembly_id: 8,
									},
									{
										id: 79,
										name: "KABERE I",
										generalAssembly_id: 8,
									},
									{
										id: 80,
										name: "KABERE II",
										generalAssembly_id: 8,
									},
									{
										id: 81,
										name: "KAMUNANA",
										generalAssembly_id: 8,
									},
									{
										id: 82,
										name: "KARWANA",
										generalAssembly_id: 8,
									},
									{
										id: 83,
										name: "MATINZA",
										generalAssembly_id: 8,
									},
									{
										id: 84,
										name: "NYAMIGINA",
										generalAssembly_id: 8,
									},
									{
										id: 85,
										name: "RANGO",
										generalAssembly_id: 8,
									},
									{
										id: 86,
										name: "RUSAGARA",
										generalAssembly_id: 8,
									},
									{
										id: 87,
										name: "RUYENZI I",
										generalAssembly_id: 8,
									},
									{
										id: 88,
										name: "RUYENZI II",
										generalAssembly_id: 8,
									},
									{
										id: 89,
										name: "RWABIKWANO",
										generalAssembly_id: 8,
									},
									{
										id: 8983,
										name: "Gakomeye Court of Appeal ",
										generalAssembly_id: 8,
									},
									{
										id: 10516,
										name: "Gakomeye Gacaca court of sector ",
										generalAssembly_id: 8,
									},
								],
							},
							{
								id: 9,
								name: "Mareba",
								sector_id: 40704,
								courtOfCells: [
									{
										id: 90,
										name: "BUKUMBA",
										generalAssembly_id: 9,
									},
									{
										id: 91,
										name: "BUSHENYI",
										generalAssembly_id: 9,
									},
									{
										id: 92,
										name: "GATARE",
										generalAssembly_id: 9,
									},
									{
										id: 93,
										name: "KAGESE",
										generalAssembly_id: 9,
									},
									{
										id: 94,
										name: "KAYONZA",
										generalAssembly_id: 9,
									},
									{
										id: 95,
										name: "MAREBA",
										generalAssembly_id: 9,
									},
									{
										id: 96,
										name: "RUDUHA",
										generalAssembly_id: 9,
									},
									{
										id: 97,
										name: "RUGARAMA",
										generalAssembly_id: 9,
									},
									{
										id: 98,
										name: "RUKOYOYO",
										generalAssembly_id: 9,
									},
									{
										id: 99,
										name: "RUSUSA",
										generalAssembly_id: 9,
									},
									{
										id: 100,
										name: "RUTAKA",
										generalAssembly_id: 9,
									},
									{
										id: 101,
										name: "KAVUMU",
										generalAssembly_id: 9,
									},
									{
										id: 8984,
										name: "Mareba Court of Appeal ",
										generalAssembly_id: 9,
									},
									{
										id: 10517,
										name: "Mareba Gacaca court of sector ",
										generalAssembly_id: 9,
									},
								],
							},
						],
					},
					{
						id: 40705,
						name: "Mayange",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070505,
								name: "Mbyo",
								sector_id: 40705,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070504,
								name: "Kibirizi",
								sector_id: 40705,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070503,
								name: "Kibenga",
								sector_id: 40705,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070502,
								name: "Kagenge",
								sector_id: 40705,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070501,
								name: "Gakamba",
								sector_id: 40705,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 10,
								name: "Mayange",
								sector_id: 40705,
								courtOfCells: [
									{
										id: 102,
										name: "GAHWIJI",
										generalAssembly_id: 10,
									},
									{
										id: 103,
										name: "GAKINDO",
										generalAssembly_id: 10,
									},
									{
										id: 104,
										name: "GISENYI",
										generalAssembly_id: 10,
									},
									{
										id: 105,
										name: "KAGENGE",
										generalAssembly_id: 10,
									},
									{
										id: 106,
										name: "KINDONYI",
										generalAssembly_id: 10,
									},
									{
										id: 107,
										name: "MURAMBI",
										generalAssembly_id: 10,
									},
									{
										id: 108,
										name: "RUGAZI",
										generalAssembly_id: 10,
									},
									{
										id: 109,
										name: "RUHORABERA",
										generalAssembly_id: 10,
									},
									{
										id: 110,
										name: "RUKINDO",
										generalAssembly_id: 10,
									},
									{
										id: 111,
										name: "RUKORA",
										generalAssembly_id: 10,
									},
									{
										id: 112,
										name: "RWAKARAMIRA",
										generalAssembly_id: 10,
									},
									{
										id: 113,
										name: "RWARUSAKU",
										generalAssembly_id: 10,
									},
									{
										id: 114,
										name: "KAMUGENZI",
										generalAssembly_id: 10,
									},
									{
										id: 8985,
										name: "Mayange Court of Appeal ",
										generalAssembly_id: 10,
									},
									{
										id: 10518,
										name: "Mayange Gacaca court of sector ",
										generalAssembly_id: 10,
									},
								],
							},
							{
								id: 11,
								name: "Mbyo",
								sector_id: 40705,
								courtOfCells: [
									{
										id: 115,
										name: "CYARUHIRIRA",
										generalAssembly_id: 11,
									},
									{
										id: 116,
										name: "GITERA",
										generalAssembly_id: 11,
									},
									{
										id: 117,
										name: "KABYO",
										generalAssembly_id: 11,
									},
									{
										id: 118,
										name: "KIBILIZI",
										generalAssembly_id: 11,
									},
									{
										id: 119,
										name: "RUGARAMA",
										generalAssembly_id: 11,
									},
									{
										id: 120,
										name: "RWIMIKONI",
										generalAssembly_id: 11,
									},
									{
										id: 8986,
										name: "Mbyo Court of Appeal ",
										generalAssembly_id: 11,
									},
									{
										id: 10519,
										name: "Mbyo Gacaca court of sector ",
										generalAssembly_id: 11,
									},
								],
							},
						],
					},
					{
						id: 40706,
						name: "Musenyi",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070604,
								name: "Rulindo",
								sector_id: 40706,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070603,
								name: "Nyagihunika",
								sector_id: 40706,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070602,
								name: "Musenyi",
								sector_id: 40706,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070601,
								name: "Gicaca",
								sector_id: 40706,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 12,
								name: "Gicaca",
								sector_id: 40706,
								courtOfCells: [
									{
										id: 121,
										name: "CYARUBAZI",
										generalAssembly_id: 12,
									},
									{
										id: 122,
										name: "KAGUSA",
										generalAssembly_id: 12,
									},
									{
										id: 123,
										name: "KAVUMU",
										generalAssembly_id: 12,
									},
									{
										id: 124,
										name: "KIDUDU",
										generalAssembly_id: 12,
									},
									{
										id: 125,
										name: "MIGINA",
										generalAssembly_id: 12,
									},
									{
										id: 126,
										name: "NGARAMA",
										generalAssembly_id: 12,
									},
									{
										id: 127,
										name: "REMERA",
										generalAssembly_id: 12,
									},
									{
										id: 128,
										name: "RUSAGARA",
										generalAssembly_id: 12,
									},
									{
										id: 8987,
										name: "Gicaca Court of Appeal ",
										generalAssembly_id: 12,
									},
									{
										id: 10520,
										name: "Gicaca Gacaca court of sector ",
										generalAssembly_id: 12,
									},
								],
							},
							{
								id: 13,
								name: "Musenyi",
								sector_id: 40706,
								courtOfCells: [
									{
										id: 129,
										name: "BIDUDU",
										generalAssembly_id: 13,
									},
									{
										id: 130,
										name: "BISHINGE-GAKOMEYE",
										generalAssembly_id: 13,
									},
									{
										id: 131,
										name: "BIZENGA",
										generalAssembly_id: 13,
									},
									{
										id: 132,
										name: "CYERU",
										generalAssembly_id: 13,
									},
									{
										id: 133,
										name: "GAKURAZO",
										generalAssembly_id: 13,
									},
									{
										id: 134,
										name: "KIGARAMA",
										generalAssembly_id: 13,
									},
									{
										id: 135,
										name: "KIJURI",
										generalAssembly_id: 13,
									},
									{
										id: 136,
										name: "KIRINGA",
										generalAssembly_id: 13,
									},
									{
										id: 137,
										name: "MUHANGA",
										generalAssembly_id: 13,
									},
									{
										id: 138,
										name: "NUNGA",
										generalAssembly_id: 13,
									},
									{
										id: 139,
										name: "NYAGASAGARA",
										generalAssembly_id: 13,
									},
									{
										id: 140,
										name: "RUGANDO",
										generalAssembly_id: 13,
									},
									{
										id: 141,
										name: "RUGEYO",
										generalAssembly_id: 13,
									},
									{
										id: 8988,
										name: "Musenyi Court of Appeal ",
										generalAssembly_id: 13,
									},
									{
										id: 10521,
										name: "Musenyi Gacaca court of sector ",
										generalAssembly_id: 13,
									},
								],
							},
							{
								id: 14,
								name: "Nyagihunika",
								sector_id: 40706,
								courtOfCells: [
									{
										id: 142,
										name: "GACACA",
										generalAssembly_id: 14,
									},
									{
										id: 143,
										name: "GATOKI",
										generalAssembly_id: 14,
									},
									{
										id: 144,
										name: "RWUMUBOGO-GITAGATA",
										generalAssembly_id: 14,
									},
									{
										id: 145,
										name: "KIGUSA",
										generalAssembly_id: 14,
									},
									{
										id: 146,
										name: "KIRUHURA",
										generalAssembly_id: 14,
									},
									{
										id: 147,
										name: "MBONWA",
										generalAssembly_id: 14,
									},
									{
										id: 148,
										name: "NYAKAJURI",
										generalAssembly_id: 14,
									},
									{
										id: 149,
										name: "RUSHUBI",
										generalAssembly_id: 14,
									},
									{
										id: 150,
										name: "RWANKERI",
										generalAssembly_id: 14,
									},
									{
										id: 8989,
										name: "Nyagihunika Court of Appeal ",
										generalAssembly_id: 14,
									},
									{
										id: 10522,
										name: "Nyagihunika Gacaca court of sector ",
										generalAssembly_id: 14,
									},
								],
							},
							{
								id: 15,
								name: "Rulindo",
								sector_id: 40706,
								courtOfCells: [
									{
										id: 151,
										name: "KABUYE",
										generalAssembly_id: 15,
									},
									{
										id: 152,
										name: "KAGUNGA",
										generalAssembly_id: 15,
									},
									{
										id: 153,
										name: "KINDONYI",
										generalAssembly_id: 15,
									},
									{
										id: 154,
										name: "KINYOVI",
										generalAssembly_id: 15,
									},
									{
										id: 155,
										name: "MUGOMERO",
										generalAssembly_id: 15,
									},
									{
										id: 156,
										name: "RURAMA",
										generalAssembly_id: 15,
									},
									{
										id: 8990,
										name: "Rulindo Court of Appeal ",
										generalAssembly_id: 15,
									},
									{
										id: 10523,
										name: "Rulindo Gacaca court of sector ",
										generalAssembly_id: 15,
									},
								],
							},
						],
					},
					{
						id: 40707,
						name: "Mwogo",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070704,
								name: "Rurenge",
								sector_id: 40707,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070703,
								name: "Rugunga",
								sector_id: 40707,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070702,
								name: "Kagasa",
								sector_id: 40707,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070701,
								name: "Bitaba",
								sector_id: 40707,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 16,
								name: "Mwogo",
								sector_id: 40707,
								courtOfCells: [
									{
										id: 157,
										name: "BITABA",
										generalAssembly_id: 16,
									},
									{
										id: 158,
										name: "GATOKI",
										generalAssembly_id: 16,
									},
									{
										id: 159,
										name: "GATWE",
										generalAssembly_id: 16,
									},
									{
										id: 160,
										name: "GISENYI",
										generalAssembly_id: 16,
									},
									{
										id: 161,
										name: "GITARAGA",
										generalAssembly_id: 16,
									},
									{
										id: 162,
										name: "KAGERERO",
										generalAssembly_id: 16,
									},
									{
										id: 163,
										name: "KARUTABANA",
										generalAssembly_id: 16,
									},
									{
										id: 164,
										name: "KAZIRAMIRE",
										generalAssembly_id: 16,
									},
									{
										id: 165,
										name: "MISATSI",
										generalAssembly_id: 16,
									},
									{
										id: 166,
										name: "NGANDO",
										generalAssembly_id: 16,
									},
									{
										id: 167,
										name: "NYAMABUYE",
										generalAssembly_id: 16,
									},
									{
										id: 168,
										name: "RUBUMBA",
										generalAssembly_id: 16,
									},
									{
										id: 169,
										name: "RUGAZI",
										generalAssembly_id: 16,
									},
									{
										id: 170,
										name: "RUGUNGA",
										generalAssembly_id: 16,
									},
									{
										id: 171,
										name: "RUKIRA",
										generalAssembly_id: 16,
									},
									{
										id: 172,
										name: "RUKORE",
										generalAssembly_id: 16,
									},
									{
										id: 173,
										name: "RUKORONKO",
										generalAssembly_id: 16,
									},
									{
										id: 174,
										name: "RURENGE",
										generalAssembly_id: 16,
									},
									{
										id: 175,
										name: "RWABASHENYI",
										generalAssembly_id: 16,
									},
									{
										id: 176,
										name: "RWINTENDERI",
										generalAssembly_id: 16,
									},
									{
										id: 8991,
										name: "Mwogo Court of Appeal ",
										generalAssembly_id: 16,
									},
									{
										id: 10524,
										name: "Mwogo Gacaca court of sector ",
										generalAssembly_id: 16,
									},
								],
							},
						],
					},
					{
						id: 40708,
						name: "Ngeruka",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070805,
								name: "Rutonde",
								sector_id: 40708,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070804,
								name: "Nyakayenzi",
								sector_id: 40708,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070803,
								name: "Ngeruka",
								sector_id: 40708,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070802,
								name: "Murama",
								sector_id: 40708,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070801,
								name: "Gihembe",
								sector_id: 40708,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 17,
								name: "Gakamba",
								sector_id: 40708,
								courtOfCells: [
									{
										id: 177,
										name: "BUHARA",
										generalAssembly_id: 17,
									},
									{
										id: 178,
										name: "GIHEMBE",
										generalAssembly_id: 17,
									},
									{
										id: 179,
										name: "KAGASA",
										generalAssembly_id: 17,
									},
									{
										id: 180,
										name: "KIMIDUHA",
										generalAssembly_id: 17,
									},
									{
										id: 181,
										name: "KIRASANIRO",
										generalAssembly_id: 17,
									},
									{
										id: 182,
										name: "NYAKAYENZI",
										generalAssembly_id: 17,
									},
									{
										id: 183,
										name: "RUZINGE",
										generalAssembly_id: 17,
									},
									{
										id: 184,
										name: "SHITWE",
										generalAssembly_id: 17,
									},
									{
										id: 8992,
										name: "Gakamba Court of Appeal ",
										generalAssembly_id: 17,
									},
									{
										id: 10525,
										name: "Gakamba Gacaca court of sector ",
										generalAssembly_id: 17,
									},
								],
							},
							{
								id: 18,
								name: "Rutonde",
								sector_id: 40708,
								courtOfCells: [
									{
										id: 185,
										name: "BISHENYI",
										generalAssembly_id: 18,
									},
									{
										id: 186,
										name: "GAKURAZO",
										generalAssembly_id: 18,
									},
									{
										id: 187,
										name: "KAMUGERA",
										generalAssembly_id: 18,
									},
									{
										id: 188,
										name: "KANKURIYINGOMA",
										generalAssembly_id: 18,
									},
									{
										id: 189,
										name: "KIGARAMA",
										generalAssembly_id: 18,
									},
									{
										id: 190,
										name: "MURAMA",
										generalAssembly_id: 18,
									},
									{
										id: 191,
										name: "RUBIRIZI",
										generalAssembly_id: 18,
									},
									{
										id: 192,
										name: "RUGAZI",
										generalAssembly_id: 18,
									},
									{
										id: 193,
										name: "RUSAMAZA",
										generalAssembly_id: 18,
									},
									{
										id: 194,
										name: "RUTONDE",
										generalAssembly_id: 18,
									},
									{
										id: 195,
										name: "RWABISHESHE",
										generalAssembly_id: 18,
									},
									{
										id: 196,
										name: "SHAMI",
										generalAssembly_id: 18,
									},
									{
										id: 8993,
										name: "Rutonde Court of Appeal ",
										generalAssembly_id: 18,
									},
									{
										id: 10526,
										name: "Rutonde Gacaca court of sector ",
										generalAssembly_id: 18,
									},
								],
							},
							{
								id: 19,
								name: "Kavumu",
								sector_id: 40708,
								courtOfCells: [
									{
										id: 197,
										name: "BINYONZWE-KAMAJERI",
										generalAssembly_id: 19,
									},
									{
										id: 198,
										name: "KARUGONDO",
										generalAssembly_id: 19,
									},
									{
										id: 199,
										name: "KABUYE",
										generalAssembly_id: 19,
									},
									{
										id: 200,
										name: "KAMASONGA",
										generalAssembly_id: 19,
									},
									{
										id: 201,
										name: "KAVUMU",
										generalAssembly_id: 19,
									},
									{
										id: 202,
										name: "KIBAYA",
										generalAssembly_id: 19,
									},
									{
										id: 203,
										name: "KIBUNGO",
										generalAssembly_id: 19,
									},
									{
										id: 204,
										name: "KIVUGIZA",
										generalAssembly_id: 19,
									},
									{
										id: 205,
										name: "MURAMBI",
										generalAssembly_id: 19,
									},
									{
										id: 206,
										name: "MUYANGE",
										generalAssembly_id: 19,
									},
									{
										id: 207,
										name: "NGERUKA",
										generalAssembly_id: 19,
									},
									{
										id: 8994,
										name: "Kavumu Court of Appeal ",
										generalAssembly_id: 19,
									},
									{
										id: 10527,
										name: "Kavumu Gacaca court of sector ",
										generalAssembly_id: 19,
									},
								],
							},
						],
					},
					{
						id: 40709,
						name: "Ntarama",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4070903,
								name: "Kibungo",
								sector_id: 40709,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070902,
								name: "Kanzenze",
								sector_id: 40709,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4070901,
								name: "Cyugaro",
								sector_id: 40709,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 20,
								name: "Kanzenze",
								sector_id: 40709,
								courtOfCells: [
									{
										id: 208,
										name: "BIDUDU-CYERU",
										generalAssembly_id: 20,
									},
									{
										id: 209,
										name: "GASAGARA-NYAMABUYE",
										generalAssembly_id: 20,
									},
									{
										id: 210,
										name: "KABEZA-RUSARABUGE",
										generalAssembly_id: 20,
									},
									{
										id: 211,
										name: "KARUMUNA-KINYANA",
										generalAssembly_id: 20,
									},
									{
										id: 212,
										name: "KURUGENGE",
										generalAssembly_id: 20,
									},
									{
										id: 213,
										name: "RWANGARA-KABAHA",
										generalAssembly_id: 20,
									},
									{
										id: 8995,
										name: "Kanzenze Court of Appeal ",
										generalAssembly_id: 20,
									},
									{
										id: 10528,
										name: "Kanzenze Gacaca court of sector ",
										generalAssembly_id: 20,
									},
								],
							},
							{
								id: 21,
								name: "Kibungo",
								sector_id: 40709,
								courtOfCells: [
									{
										id: 214,
										name: "KAGOMA",
										generalAssembly_id: 21,
									},
									{
										id: 215,
										name: "KIGANWA",
										generalAssembly_id: 21,
									},
									{
										id: 216,
										name: "NGANWA",
										generalAssembly_id: 21,
									},
									{
										id: 217,
										name: "NYARUNAZI-RUSEKERA",
										generalAssembly_id: 21,
									},
									{
										id: 218,
										name: "RUHENGERI",
										generalAssembly_id: 21,
									},
									{
										id: 8996,
										name: "Kibungo Court of Appeal ",
										generalAssembly_id: 21,
									},
									{
										id: 10529,
										name: "Kibungo Gacaca court of sector ",
										generalAssembly_id: 21,
									},
								],
							},
							{
								id: 22,
								name: "Ntarama",
								sector_id: 40709,
								courtOfCells: [
									{
										id: 219,
										name: "BYIMANA-RWANKERI",
										generalAssembly_id: 22,
									},
									{
										id: 220,
										name: "GATORO",
										generalAssembly_id: 22,
									},
									{
										id: 221,
										name: "KIDUDU",
										generalAssembly_id: 22,
									},
									{
										id: 222,
										name: "KINGABO",
										generalAssembly_id: 22,
									},
									{
										id: 223,
										name: "RUBOMBORANA-RUGARAMA",
										generalAssembly_id: 22,
									},
									{
										id: 224,
										name: "RUGUNGA",
										generalAssembly_id: 22,
									},
									{
										id: 8997,
										name: "Ntarama Court of Appeal ",
										generalAssembly_id: 22,
									},
									{
										id: 10530,
										name: "Ntarama Gacaca court of sector ",
										generalAssembly_id: 22,
									},
								],
							},
						],
					},
					{
						id: 40710,
						name: "Nyamata",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071005,
								name: "Nyamata y' Umujyi",
								sector_id: 40710,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071004,
								name: "Murama",
								sector_id: 40710,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071003,
								name: "Maranyundo",
								sector_id: 40710,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071002,
								name: "Kayumba",
								sector_id: 40710,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071001,
								name: "Kanazi",
								sector_id: 40710,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 23,
								name: "Kanazi",
								sector_id: 40710,
								courtOfCells: [
									{
										id: 225,
										name: "BIHARI",
										generalAssembly_id: 23,
									},
									{
										id: 226,
										name: "CYERU-KINYAGA",
										generalAssembly_id: 23,
									},
									{
										id: 227,
										name: "GITOVU",
										generalAssembly_id: 23,
									},
									{
										id: 228,
										name: "GITWE-MUSAGARA",
										generalAssembly_id: 23,
									},
									{
										id: 229,
										name: "KAGIRAZINA",
										generalAssembly_id: 23,
									},
									{
										id: 230,
										name: "KAGUGU-NYAMATA II-RUGANDO",
										generalAssembly_id: 23,
									},
									{
										id: 231,
										name: "NYAMATA I",
										generalAssembly_id: 23,
									},
									{
										id: 232,
										name: "NYARUGATI",
										generalAssembly_id: 23,
									},
									{
										id: 233,
										name: "SUMBURE-RUSENYI",
										generalAssembly_id: 23,
									},
									{
										id: 8998,
										name: "Kanazi Court of Appeal ",
										generalAssembly_id: 23,
									},
									{
										id: 10531,
										name: "Kanazi Gacaca court of sector ",
										generalAssembly_id: 23,
									},
								],
							},
							{
								id: 24,
								name: "Maranyundo",
								sector_id: 40710,
								courtOfCells: [
									{
										id: 234,
										name: "GASENGA",
										generalAssembly_id: 24,
									},
									{
										id: 235,
										name: "GATARE I",
										generalAssembly_id: 24,
									},
									{
										id: 236,
										name: "GATARE II",
										generalAssembly_id: 24,
									},
									{
										id: 237,
										name: "MUYANGE",
										generalAssembly_id: 24,
									},
									{
										id: 238,
										name: "RUSAGARA I",
										generalAssembly_id: 24,
									},
									{
										id: 239,
										name: "RUSAGARA II",
										generalAssembly_id: 24,
									},
									{
										id: 240,
										name: "RUSAGARA III",
										generalAssembly_id: 24,
									},
									{
										id: 241,
										name: "RUSAGARA IV",
										generalAssembly_id: 24,
									},
									{
										id: 242,
										name: "RUSAGARA V",
										generalAssembly_id: 24,
									},
									{
										id: 8999,
										name: "Maranyundo Court of Appeal ",
										generalAssembly_id: 24,
									},
									{
										id: 10532,
										name: "Maranyundo Gacaca court of sector ",
										generalAssembly_id: 24,
									},
								],
							},
							{
								id: 25,
								name: "Murama",
								sector_id: 40710,
								courtOfCells: [
									{
										id: 243,
										name: "BISHWESHWE",
										generalAssembly_id: 25,
									},
									{
										id: 244,
										name: "GATARAGA",
										generalAssembly_id: 25,
									},
									{
										id: 245,
										name: "KIVUGIZA-RUTUKURA",
										generalAssembly_id: 25,
									},
									{
										id: 246,
										name: "KIYOGOMA",
										generalAssembly_id: 25,
									},
									{
										id: 247,
										name: "MWESA",
										generalAssembly_id: 25,
									},
									{
										id: 248,
										name: "RUCUCU",
										generalAssembly_id: 25,
									},
									{
										id: 249,
										name: "RWABARE-RUTOBOTOBO",
										generalAssembly_id: 25,
									},
									{
										id: 250,
										name: "RWAMACUMU",
										generalAssembly_id: 25,
									},
									{
										id: 9000,
										name: "Murama Court of Appeal ",
										generalAssembly_id: 25,
									},
									{
										id: 10533,
										name: "Murama Gacaca court of sector ",
										generalAssembly_id: 25,
									},
								],
							},
							{
								id: 26,
								name: "Muyenzi",
								sector_id: 40710,
								courtOfCells: [
									{
										id: 251,
										name: "GACUCU",
										generalAssembly_id: 26,
									},
									{
										id: 252,
										name: "GACYAMO",
										generalAssembly_id: 26,
									},
									{
										id: 253,
										name: "GAHINGA",
										generalAssembly_id: 26,
									},
									{
										id: 254,
										name: "GAKAMBA",
										generalAssembly_id: 26,
									},
									{
										id: 255,
										name: "GISENYI",
										generalAssembly_id: 26,
									},
									{
										id: 256,
										name: "KAMUGENZI",
										generalAssembly_id: 26,
									},
									{
										id: 257,
										name: "KARAMBO",
										generalAssembly_id: 26,
									},
									{
										id: 258,
										name: "KAVUMU",
										generalAssembly_id: 26,
									},
									{
										id: 259,
										name: "RWAKIBIRIZI",
										generalAssembly_id: 26,
									},
									{
										id: 9001,
										name: "Muyenzi Court of Appeal ",
										generalAssembly_id: 26,
									},
									{
										id: 10534,
										name: "Muyenzi Gacaca court of sector ",
										generalAssembly_id: 26,
									},
								],
							},
							{
								id: 27,
								name: "Kayumba",
								sector_id: 40710,
								courtOfCells: [
									{
										id: 260,
										name: "KAYENZI-CYERU",
										generalAssembly_id: 27,
									},
									{
										id: 261,
										name: "GATARE",
										generalAssembly_id: 27,
									},
									{
										id: 262,
										name: "KARAMBI-MURAMBI-RUKIRI-KAYONZA",
										generalAssembly_id: 27,
									},
									{
										id: 263,
										name: "KINYINYA-NYIRAMATUNTU-NYAKWIBEREKA",
										generalAssembly_id: 27,
									},
									{
										id: 264,
										name: "NYABIVUMU",
										generalAssembly_id: 27,
									},
									{
										id: 265,
										name: "RUGARAMA",
										generalAssembly_id: 27,
									},
									{
										id: 266,
										name: "RWANZA",
										generalAssembly_id: 27,
									},
									{
										id: 9002,
										name: "Kayumba Court of Appeal ",
										generalAssembly_id: 27,
									},
									{
										id: 10535,
										name: "Kayumba Gacaca court of sector ",
										generalAssembly_id: 27,
									},
								],
							},
						],
					},
					{
						id: 40711,
						name: "Nyarugenge",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071105,
								name: "Rugando",
								sector_id: 40711,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071104,
								name: "Ngenda",
								sector_id: 40711,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071103,
								name: "Murambi",
								sector_id: 40711,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071102,
								name: "Kabuye",
								sector_id: 40711,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071101,
								name: "Gihinga",
								sector_id: 40711,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 28,
								name: "Nyarugenge",
								sector_id: 40711,
								courtOfCells: [
									{
										id: 267,
										name: "CYERU",
										generalAssembly_id: 28,
									},
									{
										id: 268,
										name: "GAKO",
										generalAssembly_id: 28,
									},
									{
										id: 269,
										name: "GIHINGA",
										generalAssembly_id: 28,
									},
									{
										id: 270,
										name: "KABAKEMBA",
										generalAssembly_id: 28,
									},
									{
										id: 271,
										name: "KABUYE",
										generalAssembly_id: 28,
									},
									{
										id: 272,
										name: "KAMABARE",
										generalAssembly_id: 28,
									},
									{
										id: 273,
										name: "KIDIMBIZI",
										generalAssembly_id: 28,
									},
									{
										id: 274,
										name: "KIGARAMA",
										generalAssembly_id: 28,
									},
									{
										id: 275,
										name: "MURAMBI",
										generalAssembly_id: 28,
									},
									{
										id: 276,
										name: "NGENDA",
										generalAssembly_id: 28,
									},
									{
										id: 277,
										name: "NYAKABUYE",
										generalAssembly_id: 28,
									},
									{
										id: 278,
										name: "NYARUGENGE",
										generalAssembly_id: 28,
									},
									{
										id: 279,
										name: "NYARUSAMBU",
										generalAssembly_id: 28,
									},
									{
										id: 280,
										name: "RUGANDO",
										generalAssembly_id: 28,
									},
									{
										id: 281,
										name: "RUNZENZE",
										generalAssembly_id: 28,
									},
									{
										id: 282,
										name: "RUSHOREZO",
										generalAssembly_id: 28,
									},
									{
										id: 9003,
										name: "Nyarugenge Court of Appeal ",
										generalAssembly_id: 28,
									},
									{
										id: 10536,
										name: "Nyarugenge Gacaca court of sector ",
										generalAssembly_id: 28,
									},
								],
							},
						],
					},
					{
						id: 40712,
						name: "Rilima",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071205,
								name: "Nyabagendwa",
								sector_id: 40712,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071204,
								name: "Ntarama",
								sector_id: 40712,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071203,
								name: "Kimaranzara",
								sector_id: 40712,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071202,
								name: "Karera",
								sector_id: 40712,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071201,
								name: "Kabeza",
								sector_id: 40712,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 29,
								name: "Karera",
								sector_id: 40712,
								courtOfCells: [
									{
										id: 283,
										name: "BIDENGE-NYAMISAGARA",
										generalAssembly_id: 29,
									},
									{
										id: 284,
										name: "BIRARO",
										generalAssembly_id: 29,
									},
									{
										id: 285,
										name: "GAKURAZO",
										generalAssembly_id: 29,
									},
									{
										id: 286,
										name: "GATARE",
										generalAssembly_id: 29,
									},
									{
										id: 287,
										name: "KAMASHYA",
										generalAssembly_id: 29,
									},
									{
										id: 288,
										name: "KARAMBI",
										generalAssembly_id: 29,
									},
									{
										id: 289,
										name: "KARAMBO",
										generalAssembly_id: 29,
									},
									{
										id: 290,
										name: "KARIRISI",
										generalAssembly_id: 29,
									},
									{
										id: 291,
										name: "KAVUMU",
										generalAssembly_id: 29,
									},
									{
										id: 292,
										name: "MUTARAMA",
										generalAssembly_id: 29,
									},
									{
										id: 293,
										name: "RWAVUNINGOMA-RUKOKI",
										generalAssembly_id: 29,
									},
									{
										id: 294,
										name: "RURAMBO",
										generalAssembly_id: 29,
									},
									{
										id: 295,
										name: "RWANKOMATI",
										generalAssembly_id: 29,
									},
									{
										id: 296,
										name: "RWIMIRAMA",
										generalAssembly_id: 29,
									},
									{
										id: 9004,
										name: "Karera Court of Appeal ",
										generalAssembly_id: 29,
									},
									{
										id: 10537,
										name: "Karera Gacaca court of sector ",
										generalAssembly_id: 29,
									},
								],
							},
							{
								id: 30,
								name: "Rilima",
								sector_id: 40712,
								courtOfCells: [
									{
										id: 297,
										name: "AKABEZA",
										generalAssembly_id: 30,
									},
									{
										id: 298,
										name: "AKINTWARI",
										generalAssembly_id: 30,
									},
									{
										id: 299,
										name: "CYOMA",
										generalAssembly_id: 30,
									},
									{
										id: 300,
										name: "GAKO",
										generalAssembly_id: 30,
									},
									{
										id: 301,
										name: "GASARWE",
										generalAssembly_id: 30,
									},
									{
										id: 302,
										name: "GASEKE",
										generalAssembly_id: 30,
									},
									{
										id: 303,
										name: "GICACA",
										generalAssembly_id: 30,
									},
									{
										id: 304,
										name: "GIHUSHI",
										generalAssembly_id: 30,
									},
									{
										id: 305,
										name: "GITEGA",
										generalAssembly_id: 30,
									},
									{
										id: 306,
										name: "KABAHAYA",
										generalAssembly_id: 30,
									},
									{
										id: 307,
										name: "KAMABUYE",
										generalAssembly_id: 30,
									},
									{
										id: 308,
										name: "RUTARE-KIDOGO",
										generalAssembly_id: 30,
									},
									{
										id: 309,
										name: "KIMARANZARA",
										generalAssembly_id: 30,
									},
									{
										id: 310,
										name: "KIVUMU",
										generalAssembly_id: 30,
									},
									{
										id: 311,
										name: "MUBUGA",
										generalAssembly_id: 30,
									},
									{
										id: 312,
										name: "MUKOMA",
										generalAssembly_id: 30,
									},
									{
										id: 313,
										name: "NTARAMA",
										generalAssembly_id: 30,
									},
									{
										id: 314,
										name: "NYABAGENDWA",
										generalAssembly_id: 30,
									},
									{
										id: 315,
										name: "NYAMIZI",
										generalAssembly_id: 30,
									},
									{
										id: 316,
										name: "RWIBIKARA",
										generalAssembly_id: 30,
									},
									{
										id: 317,
										name: "SARUDUHA",
										generalAssembly_id: 30,
									},
									{
										id: 318,
										name: "MATABA",
										generalAssembly_id: 30,
									},
									{
										id: 9005,
										name: "Rilima Court of Appeal ",
										generalAssembly_id: 30,
									},
									{
										id: 10538,
										name: "Rilima Gacaca court of sector ",
										generalAssembly_id: 30,
									},
								],
							},
						],
					},
					{
						id: 40713,
						name: "Ruhuha",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071305,
								name: "Ruhuha",
								sector_id: 40713,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071304,
								name: "Kindama",
								sector_id: 40713,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071303,
								name: "Gikundamvura",
								sector_id: 40713,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071302,
								name: "Gatanga",
								sector_id: 40713,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071301,
								name: "Bihari",
								sector_id: 40713,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 31,
								name: "Kindama",
								sector_id: 40713,
								courtOfCells: [
									{
										id: 319,
										name: "GATARE",
										generalAssembly_id: 31,
									},
									{
										id: 320,
										name: "GIKUNDAMVURA",
										generalAssembly_id: 31,
									},
									{
										id: 321,
										name: "KAGASERA",
										generalAssembly_id: 31,
									},
									{
										id: 322,
										name: "KAMWERU",
										generalAssembly_id: 31,
									},
									{
										id: 323,
										name: "KANOMBE",
										generalAssembly_id: 31,
									},
									{
										id: 324,
										name: "KAZABAGARURA",
										generalAssembly_id: 31,
									},
									{
										id: 325,
										name: "KINDAMA",
										generalAssembly_id: 31,
									},
									{
										id: 326,
										name: "RUKURAZO",
										generalAssembly_id: 31,
									},
									{
										id: 327,
										name: "RURAMBA",
										generalAssembly_id: 31,
									},
									{
										id: 328,
										name: "SARUDUHA",
										generalAssembly_id: 31,
									},
									{
										id: 9006,
										name: "Kindama Court of Appeal ",
										generalAssembly_id: 31,
									},
									{
										id: 10539,
										name: "Kindama Gacaca court of sector ",
										generalAssembly_id: 31,
									},
								],
							},
							{
								id: 32,
								name: "Ruhuha",
								sector_id: 40713,
								courtOfCells: [
									{
										id: 329,
										name: "BIHARI",
										generalAssembly_id: 32,
									},
									{
										id: 330,
										name: "BUTERERI",
										generalAssembly_id: 32,
									},
									{
										id: 331,
										name: "KAYIGI",
										generalAssembly_id: 32,
									},
									{
										id: 332,
										name: "KIMIKAMBA",
										generalAssembly_id: 32,
									},
									{
										id: 333,
										name: "MASENGA I",
										generalAssembly_id: 32,
									},
									{
										id: 334,
										name: "MASENGA II",
										generalAssembly_id: 32,
									},
									{
										id: 335,
										name: "NYABARANGA",
										generalAssembly_id: 32,
									},
									{
										id: 336,
										name: "NYAKAGARAMA",
										generalAssembly_id: 32,
									},
									{
										id: 337,
										name: "RUGARAMA",
										generalAssembly_id: 32,
									},
									{
										id: 338,
										name: "RUHUHA",
										generalAssembly_id: 32,
									},
									{
										id: 339,
										name: "RWANIKA",
										generalAssembly_id: 32,
									},
									{
										id: 9007,
										name: "Ruhuha Court of Appeal ",
										generalAssembly_id: 32,
									},
									{
										id: 10540,
										name: "Ruhuha Gacaca court of sector ",
										generalAssembly_id: 32,
									},
								],
							},
						],
					},
					{
						id: 40714,
						name: "Rweru",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071406,
								name: "Sharita",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071405,
								name: "Nkanga",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071404,
								name: "Nemba",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071403,
								name: "Mazane",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071402,
								name: "Kintambwe",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071401,
								name: "Batima",
								sector_id: 40714,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 33,
								name: "Nkanga",
								sector_id: 40714,
								courtOfCells: [
									{
										id: 340,
										name: "AGASHORO",
										generalAssembly_id: 33,
									},
									{
										id: 341,
										name: "GASORORO",
										generalAssembly_id: 33,
									},
									{
										id: 342,
										name: "GIKOMA",
										generalAssembly_id: 33,
									},
									{
										id: 343,
										name: "KIGINA",
										generalAssembly_id: 33,
									},
									{
										id: 344,
										name: "KIVUSHA",
										generalAssembly_id: 33,
									},
									{
										id: 345,
										name: "RUZO",
										generalAssembly_id: 33,
									},
									{
										id: 346,
										name: "RWIMINAZI",
										generalAssembly_id: 33,
									},
									{
										id: 347,
										name: "SHARITA",
										generalAssembly_id: 33,
									},
									{
										id: 9008,
										name: "Nkanga Court of Appeal ",
										generalAssembly_id: 33,
									},
									{
										id: 10541,
										name: "Nkanga Gacaca court of sector ",
										generalAssembly_id: 33,
									},
								],
							},
							{
								id: 34,
								name: "Rweru",
								sector_id: 40714,
								courtOfCells: [
									{
										id: 348,
										name: "GASENYI",
										generalAssembly_id: 34,
									},
									{
										id: 349,
										name: "KINTAMBWE",
										generalAssembly_id: 34,
									},
									{
										id: 350,
										name: "MABURANE",
										generalAssembly_id: 34,
									},
									{
										id: 351,
										name: "MAZANE",
										generalAssembly_id: 34,
									},
									{
										id: 352,
										name: "MUGINA",
										generalAssembly_id: 34,
									},
									{
										id: 353,
										name: "NYIRAGISEKE",
										generalAssembly_id: 34,
									},
									{
										id: 9009,
										name: "Rweru Court of Appeal ",
										generalAssembly_id: 34,
									},
									{
										id: 10542,
										name: "Rweru Gacaca court of sector ",
										generalAssembly_id: 34,
									},
								],
							},
						],
					},
					{
						id: 40715,
						name: "Shyara",
						district_id: 407,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4071505,
								name: "Rutare",
								sector_id: 40715,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071504,
								name: "Rebero",
								sector_id: 40715,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071503,
								name: "Nziranziza",
								sector_id: 40715,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071502,
								name: "Kamabuye",
								sector_id: 40715,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4071501,
								name: "Kabagugu",
								sector_id: 40715,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 35,
								name: "Nziranziza",
								sector_id: 40715,
								courtOfCells: [
									{
										id: 354,
										name: "GAHOSHA",
										generalAssembly_id: 35,
									},
									{
										id: 355,
										name: "GAKONI",
										generalAssembly_id: 35,
									},
									{
										id: 356,
										name: "GATEKO",
										generalAssembly_id: 35,
									},
									{
										id: 357,
										name: "KAGARAMA",
										generalAssembly_id: 35,
									},
									{
										id: 358,
										name: "NYABAGUMA",
										generalAssembly_id: 35,
									},
									{
										id: 359,
										name: "NYAMIRAMA",
										generalAssembly_id: 35,
									},
									{
										id: 360,
										name: "NZIRANZIZA",
										generalAssembly_id: 35,
									},
									{
										id: 361,
										name: "RUBWIRWA",
										generalAssembly_id: 35,
									},
									{
										id: 362,
										name: "RULI",
										generalAssembly_id: 35,
									},
									{
										id: 363,
										name: "RUTEBE",
										generalAssembly_id: 35,
									},
									{
										id: 364,
										name: "KINTEKO",
										generalAssembly_id: 35,
									},
									{
										id: 9010,
										name: "Nziranziza Court of Appeal ",
										generalAssembly_id: 35,
									},
									{
										id: 10543,
										name: "Nziranziza Gacaca court of sector ",
										generalAssembly_id: 35,
									},
								],
							},
							{
								id: 36,
								name: "Shyara ",
								sector_id: 40715,
								courtOfCells: [
									{
										id: 365,
										name: "KABAGUGU",
										generalAssembly_id: 36,
									},
									{
										id: 366,
										name: "KAMWERU",
										generalAssembly_id: 36,
									},
									{
										id: 367,
										name: "KINTEKO",
										generalAssembly_id: 36,
									},
									{
										id: 368,
										name: "RUHANGA",
										generalAssembly_id: 36,
									},
									{
										id: 369,
										name: "RUTARE",
										generalAssembly_id: 36,
									},
									{
										id: 370,
										name: "RWAMANYONI",
										generalAssembly_id: 36,
									},
									{
										id: 371,
										name: "SHYARA",
										generalAssembly_id: 36,
									},
									{
										id: 372,
										name: "GAHOSHA",
										generalAssembly_id: 36,
									},
									{
										id: 9011,
										name: "Shyara  Court of Appeal ",
										generalAssembly_id: 36,
									},
									{
										id: 10544,
										name: "Shyara  Gacaca court of sector ",
										generalAssembly_id: 36,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 403,
				name: "Gatsibo",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40301,
						name: "Gasange",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030104,
								name: "Viro",
								sector_id: 40301,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030103,
								name: "Teme",
								sector_id: 40301,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030102,
								name: "Kimana",
								sector_id: 40301,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030101,
								name: "Kigabiro",
								sector_id: 40301,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 206,
								name: "Bibare ",
								sector_id: 40301,
								courtOfCells: [
									{
										id: 1372,
										name: "BIBARE",
										generalAssembly_id: 206,
									},
									{
										id: 1373,
										name: "BINIGA",
										generalAssembly_id: 206,
									},
									{
										id: 1374,
										name: "BUTINSA",
										generalAssembly_id: 206,
									},
									{
										id: 1375,
										name: "CYARUGIRA",
										generalAssembly_id: 206,
									},
									{
										id: 1376,
										name: "GAKOROKOMBE",
										generalAssembly_id: 206,
									},
									{
										id: 1377,
										name: "RUTOMA",
										generalAssembly_id: 206,
									},
									{
										id: 9181,
										name: "Bibare  Court of Appeal ",
										generalAssembly_id: 206,
									},
									{
										id: 10714,
										name: "Bibare  Gacaca court of sector ",
										generalAssembly_id: 206,
									},
								],
							},
							{
								id: 207,
								name: "Kome",
								sector_id: 40301,
								courtOfCells: [
									{
										id: 1378,
										name: "GIHETA",
										generalAssembly_id: 207,
									},
									{
										id: 1379,
										name: "KIMANA",
										generalAssembly_id: 207,
									},
									{
										id: 1380,
										name: "KINUNGA",
										generalAssembly_id: 207,
									},
									{
										id: 1381,
										name: "MAYA",
										generalAssembly_id: 207,
									},
									{
										id: 1382,
										name: "RUGARAMA",
										generalAssembly_id: 207,
									},
									{
										id: 1383,
										name: "TEME",
										generalAssembly_id: 207,
									},
									{
										id: 9182,
										name: "Kome Court of Appeal ",
										generalAssembly_id: 207,
									},
									{
										id: 10715,
										name: "Kome Gacaca court of sector ",
										generalAssembly_id: 207,
									},
								],
							},
							{
								id: 208,
								name: "Kigabiro",
								sector_id: 40301,
								courtOfCells: [
									{
										id: 1384,
										name: "BUBURANKWI",
										generalAssembly_id: 208,
									},
									{
										id: 1385,
										name: "KABUYE",
										generalAssembly_id: 208,
									},
									{
										id: 1386,
										name: "KIGABIRO",
										generalAssembly_id: 208,
									},
									{
										id: 1387,
										name: "MUNINI",
										generalAssembly_id: 208,
									},
									{
										id: 1388,
										name: "RUGARAMA",
										generalAssembly_id: 208,
									},
									{
										id: 9183,
										name: "Kigabiro Court of Appeal ",
										generalAssembly_id: 208,
									},
									{
										id: 10716,
										name: "Kigabiro Gacaca court of sector ",
										generalAssembly_id: 208,
									},
								],
							},
						],
					},
					{
						id: 40302,
						name: "Gatsibo",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030205,
								name: "Nyagahanga",
								sector_id: 40302,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030204,
								name: "Nyabicwamba",
								sector_id: 40302,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030203,
								name: "Mugera",
								sector_id: 40302,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030202,
								name: "Manishya",
								sector_id: 40302,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030201,
								name: "Gatsibo",
								sector_id: 40302,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 209,
								name: "Gatsibo",
								sector_id: 40302,
								courtOfCells: [
									{
										id: 1389,
										name: "GATSIBO",
										generalAssembly_id: 209,
									},
									{
										id: 1390,
										name: "MUKWIZA",
										generalAssembly_id: 209,
									},
									{
										id: 1391,
										name: "NYARUKONI",
										generalAssembly_id: 209,
									},
									{
										id: 1392,
										name: "RUHENE",
										generalAssembly_id: 209,
									},
									{
										id: 1393,
										name: "RWIMBOGO",
										generalAssembly_id: 209,
									},
									{
										id: 9184,
										name: "Gatsibo Court of Appeal ",
										generalAssembly_id: 209,
									},
									{
										id: 10717,
										name: "Gatsibo Gacaca court of sector ",
										generalAssembly_id: 209,
									},
								],
							},
							{
								id: 210,
								name: "Manishya",
								sector_id: 40302,
								courtOfCells: [
									{
										id: 1394,
										name: "KIBISHO",
										generalAssembly_id: 210,
									},
									{
										id: 1395,
										name: "MANISHYA",
										generalAssembly_id: 210,
									},
									{
										id: 1396,
										name: "MWENYA",
										generalAssembly_id: 210,
									},
									{
										id: 1397,
										name: "NYARUKONI",
										generalAssembly_id: 210,
									},
									{
										id: 9185,
										name: "Manishya Court of Appeal ",
										generalAssembly_id: 210,
									},
									{
										id: 10718,
										name: "Manishya Gacaca court of sector ",
										generalAssembly_id: 210,
									},
								],
							},
							{
								id: 211,
								name: "Nyabiheke",
								sector_id: 40302,
								courtOfCells: [
									{
										id: 1398,
										name: "NYABICWAMBA",
										generalAssembly_id: 211,
									},
									{
										id: 1399,
										name: "NYABIHEKE",
										generalAssembly_id: 211,
									},
									{
										id: 1400,
										name: "NYAMUDUHA-RWIMBOGO",
										generalAssembly_id: 211,
									},
									{
										id: 1401,
										name: "RWISIRABO",
										generalAssembly_id: 211,
									},
									{
										id: 9186,
										name: "Nyabiheke Court of Appeal ",
										generalAssembly_id: 211,
									},
									{
										id: 10719,
										name: "Nyabiheke Gacaca court of sector ",
										generalAssembly_id: 211,
									},
								],
							},
							{
								id: 212,
								name: "Mugera",
								sector_id: 40302,
								courtOfCells: [
									{
										id: 1402,
										name: "KARAMBO",
										generalAssembly_id: 212,
									},
									{
										id: 1403,
										name: "KAVUMU",
										generalAssembly_id: 212,
									},
									{
										id: 1404,
										name: "KIRARITSI",
										generalAssembly_id: 212,
									},
									{
										id: 1405,
										name: "NYARUKONI",
										generalAssembly_id: 212,
									},
									{
										id: 9187,
										name: "Mugera Court of Appeal ",
										generalAssembly_id: 212,
									},
									{
										id: 10720,
										name: "Mugera Gacaca court of sector ",
										generalAssembly_id: 212,
									},
								],
							},
							{
								id: 213,
								name: "Karehe",
								sector_id: 40302,
								courtOfCells: [
									{
										id: 1406,
										name: "CYANYANDAGO",
										generalAssembly_id: 213,
									},
									{
										id: 1407,
										name: "NYAGAHANGA",
										generalAssembly_id: 213,
									},
									{
										id: 1408,
										name: "NYAKANONI",
										generalAssembly_id: 213,
									},
									{
										id: 1409,
										name: "NYARUKA",
										generalAssembly_id: 213,
									},
									{
										id: 1410,
										name: "RUSENGE",
										generalAssembly_id: 213,
									},
									{
										id: 1411,
										name: "RWOBE",
										generalAssembly_id: 213,
									},
									{
										id: 9188,
										name: "Karehe Court of Appeal ",
										generalAssembly_id: 213,
									},
									{
										id: 10721,
										name: "Karehe Gacaca court of sector ",
										generalAssembly_id: 213,
									},
								],
							},
						],
					},
					{
						id: 40303,
						name: "Gitoki",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030306,
								name: "Rubira",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030305,
								name: "Nyamirama",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030304,
								name: "Mpondwa",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030303,
								name: "Karubungo",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030302,
								name: "Cyabusheshe",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030301,
								name: "Bukomane",
								sector_id: 40303,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 214,
								name: "Gitoki",
								sector_id: 40303,
								courtOfCells: [
									{
										id: 1412,
										name: "BUKIRA",
										generalAssembly_id: 214,
									},
									{
										id: 1413,
										name: "GAHABO",
										generalAssembly_id: 214,
									},
									{
										id: 1414,
										name: "GITOKI",
										generalAssembly_id: 214,
									},
									{
										id: 1415,
										name: "MPONDWA",
										generalAssembly_id: 214,
									},
									{
										id: 1416,
										name: "NYAMIRAMA",
										generalAssembly_id: 214,
									},
									{
										id: 1417,
										name: "NYAMYIJIMA",
										generalAssembly_id: 214,
									},
									{
										id: 9189,
										name: "Gitoki Court of Appeal ",
										generalAssembly_id: 214,
									},
									{
										id: 10722,
										name: "Gitoki Gacaca court of sector ",
										generalAssembly_id: 214,
									},
								],
							},
							{
								id: 215,
								name: "Mukarange",
								sector_id: 40303,
								courtOfCells: [
									{
										id: 1418,
										name: "KAGUGU",
										generalAssembly_id: 215,
									},
									{
										id: 1419,
										name: "SATA-KANTERI",
										generalAssembly_id: 215,
									},
									{
										id: 1420,
										name: "MUKARANGE",
										generalAssembly_id: 215,
									},
									{
										id: 1421,
										name: "NYAKAGOTA",
										generalAssembly_id: 215,
									},
									{
										id: 1422,
										name: "NYAMENGO",
										generalAssembly_id: 215,
									},
									{
										id: 9190,
										name: "Mukarange Court of Appeal ",
										generalAssembly_id: 215,
									},
									{
										id: 10723,
										name: "Mukarange Gacaca court of sector ",
										generalAssembly_id: 215,
									},
								],
							},
							{
								id: 216,
								name: "Nyakayaga",
								sector_id: 40303,
								courtOfCells: [
									{
										id: 1423,
										name: "GISAKA",
										generalAssembly_id: 216,
									},
									{
										id: 1424,
										name: "NYAKAYAGA",
										generalAssembly_id: 216,
									},
									{
										id: 1425,
										name: "NYARUNAZI",
										generalAssembly_id: 216,
									},
									{
										id: 1426,
										name: "RUSHASHI",
										generalAssembly_id: 216,
									},
									{
										id: 1427,
										name: "MUNINI",
										generalAssembly_id: 216,
									},
									{
										id: 1428,
										name: "CYABUSHESHE",
										generalAssembly_id: 216,
									},
									{
										id: 9191,
										name: "Nyakayaga Court of Appeal ",
										generalAssembly_id: 216,
									},
									{
										id: 10724,
										name: "Nyakayaga Gacaca court of sector ",
										generalAssembly_id: 216,
									},
								],
							},
						],
					},
					{
						id: 40304,
						name: "Kabarore",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030406,
								name: "Simbwa",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030405,
								name: "Nyabikiri",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030404,
								name: "Marimba",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030403,
								name: "Karenge",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030402,
								name: "Kabeza",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030401,
								name: "Kabarore",
								sector_id: 40304,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 217,
								name: "Nyarubuye",
								sector_id: 40304,
								courtOfCells: [
									{
										id: 1429,
										name: "KABEZA",
										generalAssembly_id: 217,
									},
									{
										id: 1430,
										name: "KARENGE",
										generalAssembly_id: 217,
									},
									{
										id: 1431,
										name: "MUTARAMA",
										generalAssembly_id: 217,
									},
									{
										id: 1432,
										name: "NGARAMA",
										generalAssembly_id: 217,
									},
									{
										id: 1433,
										name: "NYABIKIRI",
										generalAssembly_id: 217,
									},
									{
										id: 1434,
										name: "NYARUBUYE",
										generalAssembly_id: 217,
									},
									{
										id: 9192,
										name: "Nyarubuye Court of Appeal ",
										generalAssembly_id: 217,
									},
									{
										id: 10725,
										name: "Nyarubuye Gacaca court of sector ",
										generalAssembly_id: 217,
									},
								],
							},
							{
								id: 218,
								name: "Kabarore",
								sector_id: 40304,
								courtOfCells: [
									{
										id: 1435,
										name: "BIHINGA",
										generalAssembly_id: 218,
									},
									{
										id: 1436,
										name: "GATOKI",
										generalAssembly_id: 218,
									},
									{
										id: 1437,
										name: "KABARORE",
										generalAssembly_id: 218,
									},
									{
										id: 1438,
										name: "RYANJERU",
										generalAssembly_id: 218,
									},
									{
										id: 1439,
										name: "KABINGO",
										generalAssembly_id: 218,
									},
									{
										id: 1440,
										name: "RUHUHA",
										generalAssembly_id: 218,
									},
									{
										id: 1441,
										name: "MISHENYI",
										generalAssembly_id: 218,
									},
									{
										id: 1442,
										name: "SIMBWA",
										generalAssembly_id: 218,
									},
									{
										id: 9193,
										name: "Kabarore Court of Appeal ",
										generalAssembly_id: 218,
									},
									{
										id: 10726,
										name: "Kabarore Gacaca court of sector ",
										generalAssembly_id: 218,
									},
								],
							},
							{
								id: 219,
								name: "Kibondo",
								sector_id: 40304,
								courtOfCells: [
									{
										id: 1443,
										name: "KABARE-KANTERI",
										generalAssembly_id: 219,
									},
									{
										id: 1444,
										name: "KIBONDO I-KIBONDO II",
										generalAssembly_id: 219,
									},
									{
										id: 1445,
										name: "MARIMBA-NYARWANYA",
										generalAssembly_id: 219,
									},
									{
										id: 1446,
										name: "REBERO",
										generalAssembly_id: 219,
									},
									{
										id: 1447,
										name: "RUTENDERI",
										generalAssembly_id: 219,
									},
									{
										id: 1448,
										name: "RWIMBOGO",
										generalAssembly_id: 219,
									},
									{
										id: 9194,
										name: "Kibondo Court of Appeal ",
										generalAssembly_id: 219,
									},
									{
										id: 10727,
										name: "Kibondo Gacaca court of sector ",
										generalAssembly_id: 219,
									},
								],
							},
						],
					},
					{
						id: 40305,
						name: "Kageyo",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030504,
								name: "Nyagisozi",
								sector_id: 40305,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030503,
								name: "Kintu",
								sector_id: 40305,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030502,
								name: "Gituza",
								sector_id: 40305,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030501,
								name: "Busetsa",
								sector_id: 40305,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 220,
								name: "Gitebwe ",
								sector_id: 40305,
								courtOfCells: [
									{
										id: 1449,
										name: "BUSETSA",
										generalAssembly_id: 220,
									},
									{
										id: 1450,
										name: "GITEBWE",
										generalAssembly_id: 220,
									},
									{
										id: 1451,
										name: "KANINGA",
										generalAssembly_id: 220,
									},
									{
										id: 1452,
										name: "RURAMA",
										generalAssembly_id: 220,
									},
									{
										id: 1453,
										name: "TSIMA",
										generalAssembly_id: 220,
									},
									{
										id: 9195,
										name: "Gitebwe  Court of Appeal ",
										generalAssembly_id: 220,
									},
									{
										id: 10728,
										name: "Gitebwe  Gacaca court of sector ",
										generalAssembly_id: 220,
									},
								],
							},
							{
								id: 221,
								name: "Gituza",
								sector_id: 40305,
								courtOfCells: [
									{
										id: 1454,
										name: "BUGARAMA",
										generalAssembly_id: 221,
									},
									{
										id: 1455,
										name: "GASIZA",
										generalAssembly_id: 221,
									},
									{
										id: 1456,
										name: "GITUZA",
										generalAssembly_id: 221,
									},
									{
										id: 1457,
										name: "KASHANGO",
										generalAssembly_id: 221,
									},
									{
										id: 1458,
										name: "KINTU",
										generalAssembly_id: 221,
									},
									{
										id: 1459,
										name: "KIRARA",
										generalAssembly_id: 221,
									},
									{
										id: 1460,
										name: "MPAMA",
										generalAssembly_id: 221,
									},
									{
										id: 1461,
										name: "RUTOMA",
										generalAssembly_id: 221,
									},
									{
										id: 9196,
										name: "Gituza Court of Appeal ",
										generalAssembly_id: 221,
									},
									{
										id: 10729,
										name: "Gituza Gacaca court of sector ",
										generalAssembly_id: 221,
									},
								],
							},
							{
								id: 222,
								name: "Nyagisozi",
								sector_id: 40305,
								courtOfCells: [
									{
										id: 1462,
										name: "KAGEYO",
										generalAssembly_id: 222,
									},
									{
										id: 1463,
										name: "KINYANA",
										generalAssembly_id: 222,
									},
									{
										id: 1464,
										name: "NYABUKOBERO",
										generalAssembly_id: 222,
									},
									{
										id: 1465,
										name: "NYAGISOZI",
										generalAssembly_id: 222,
									},
									{
										id: 1466,
										name: "NYAKABUNGO",
										generalAssembly_id: 222,
									},
									{
										id: 1467,
										name: "RYABUSHOGORO",
										generalAssembly_id: 222,
									},
									{
										id: 9197,
										name: "Nyagisozi Court of Appeal ",
										generalAssembly_id: 222,
									},
									{
										id: 10730,
										name: "Nyagisozi Gacaca court of sector ",
										generalAssembly_id: 222,
									},
								],
							},
						],
					},
					{
						id: 40306,
						name: "Kiramuruzi",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030604,
								name: "Nyabisindu",
								sector_id: 40306,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030603,
								name: "Gakoni",
								sector_id: 40306,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030602,
								name: "Gakenke",
								sector_id: 40306,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030601,
								name: "Akabuga",
								sector_id: 40306,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 223,
								name: "Gakenke",
								sector_id: 40306,
								courtOfCells: [
									{
										id: 1468,
										name: "AKAMASINE",
										generalAssembly_id: 223,
									},
									{
										id: 1469,
										name: "AKURUSIZI",
										generalAssembly_id: 223,
									},
									{
										id: 1470,
										name: "GATUGUNDA",
										generalAssembly_id: 223,
									},
									{
										id: 1471,
										name: "NYAKAGARAMA",
										generalAssembly_id: 223,
									},
									{
										id: 1472,
										name: "UMUREHE",
										generalAssembly_id: 223,
									},
									{
										id: 9198,
										name: "Gakenke Court of Appeal ",
										generalAssembly_id: 223,
									},
									{
										id: 10731,
										name: "Gakenke Gacaca court of sector ",
										generalAssembly_id: 223,
									},
								],
							},
							{
								id: 224,
								name: "Gakoni",
								sector_id: 40306,
								courtOfCells: [
									{
										id: 1473,
										name: "KUMANA",
										generalAssembly_id: 224,
									},
									{
										id: 1474,
										name: "KUMUNINI",
										generalAssembly_id: 224,
									},
									{
										id: 1475,
										name: "KUMWIGA",
										generalAssembly_id: 224,
									},
									{
										id: 1476,
										name: "RWAJEMBE",
										generalAssembly_id: 224,
									},
									{
										id: 9199,
										name: "Gakoni Court of Appeal ",
										generalAssembly_id: 224,
									},
									{
										id: 10732,
										name: "Gakoni Gacaca court of sector ",
										generalAssembly_id: 224,
									},
								],
							},
							{
								id: 225,
								name: "Kiramuruzi",
								sector_id: 40306,
								courtOfCells: [
									{
										id: 1477,
										name: "AKABUGA",
										generalAssembly_id: 225,
									},
									{
										id: 1478,
										name: "AKAGARAMA",
										generalAssembly_id: 225,
									},
									{
										id: 1479,
										name: "AKARAMBO",
										generalAssembly_id: 225,
									},
									{
										id: 1480,
										name: "BUSINDE",
										generalAssembly_id: 225,
									},
									{
										id: 1481,
										name: "NDUBA",
										generalAssembly_id: 225,
									},
									{
										id: 9200,
										name: "Kiramuruzi Court of Appeal ",
										generalAssembly_id: 225,
									},
									{
										id: 10733,
										name: "Kiramuruzi Gacaca court of sector ",
										generalAssembly_id: 225,
									},
								],
							},
							{
								id: 226,
								name: "Nyabisindu",
								sector_id: 40306,
								courtOfCells: [
									{
										id: 1482,
										name: "GAHOKO",
										generalAssembly_id: 226,
									},
									{
										id: 1483,
										name: "GITUNGINKA",
										generalAssembly_id: 226,
									},
									{
										id: 1484,
										name: "KARABA",
										generalAssembly_id: 226,
									},
									{
										id: 1485,
										name: "NTETE",
										generalAssembly_id: 226,
									},
									{
										id: 1486,
										name: "NYABISINDU",
										generalAssembly_id: 226,
									},
									{
										id: 1487,
										name: "NYARUSAMBU",
										generalAssembly_id: 226,
									},
									{
										id: 9201,
										name: "Nyabisindu Court of Appeal ",
										generalAssembly_id: 226,
									},
									{
										id: 10734,
										name: "Nyabisindu Gacaca court of sector ",
										generalAssembly_id: 226,
									},
								],
							},
						],
					},
					{
						id: 40307,
						name: "Kiziguro",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030704,
								name: "Rubona",
								sector_id: 40307,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030703,
								name: "Ndatemwa",
								sector_id: 40307,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030702,
								name: "Mbogo",
								sector_id: 40307,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030701,
								name: "Agakomeye",
								sector_id: 40307,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 227,
								name: "Kiziguro",
								sector_id: 40307,
								courtOfCells: [
									{
										id: 1488,
										name: "AGAKOMEYE",
										generalAssembly_id: 227,
									},
									{
										id: 1489,
										name: "AKINGONDO",
										generalAssembly_id: 227,
									},
									{
										id: 1490,
										name: "MUNANIRA",
										generalAssembly_id: 227,
									},
									{
										id: 1491,
										name: "MURINGA",
										generalAssembly_id: 227,
									},
									{
										id: 9202,
										name: "Kiziguro Court of Appeal ",
										generalAssembly_id: 227,
									},
									{
										id: 10735,
										name: "Kiziguro Gacaca court of sector ",
										generalAssembly_id: 227,
									},
								],
							},
							{
								id: 228,
								name: "Mbogo",
								sector_id: 40307,
								courtOfCells: [
									{
										id: 1492,
										name: "AKABUYE",
										generalAssembly_id: 228,
									},
									{
										id: 1493,
										name: "AKAVUMU",
										generalAssembly_id: 228,
									},
									{
										id: 1494,
										name: "MBOGO",
										generalAssembly_id: 228,
									},
									{
										id: 1495,
										name: "NYAKABUNGO",
										generalAssembly_id: 228,
									},
									{
										id: 9203,
										name: "Mbogo Court of Appeal ",
										generalAssembly_id: 228,
									},
									{
										id: 10736,
										name: "Mbogo Gacaca court of sector ",
										generalAssembly_id: 228,
									},
								],
							},
							{
								id: 229,
								name: "Rubona",
								sector_id: 40307,
								courtOfCells: [
									{
										id: 1496,
										name: "AGATARE",
										generalAssembly_id: 229,
									},
									{
										id: 1497,
										name: "BWIZA",
										generalAssembly_id: 229,
									},
									{
										id: 1498,
										name: "KIGOROBA",
										generalAssembly_id: 229,
									},
									{
										id: 1499,
										name: "KINIMBA",
										generalAssembly_id: 229,
									},
									{
										id: 1500,
										name: "NYAGASAMBU",
										generalAssembly_id: 229,
									},
									{
										id: 1501,
										name: "TUBINDI",
										generalAssembly_id: 229,
									},
									{
										id: 9204,
										name: "Rubona Court of Appeal ",
										generalAssembly_id: 229,
									},
									{
										id: 10737,
										name: "Rubona Gacaca court of sector ",
										generalAssembly_id: 229,
									},
								],
							},
							{
								id: 230,
								name: "Ndatemwa ",
								sector_id: 40307,
								courtOfCells: [
									{
										id: 1502,
										name: "BIDUDU",
										generalAssembly_id: 230,
									},
									{
										id: 1503,
										name: "AKARAMBO",
										generalAssembly_id: 230,
									},
									{
										id: 1504,
										name: "KIGARAMA",
										generalAssembly_id: 230,
									},
									{
										id: 1505,
										name: "KINUNGA",
										generalAssembly_id: 230,
									},
									{
										id: 1506,
										name: "MUREHE",
										generalAssembly_id: 230,
									},
									{
										id: 1507,
										name: "NYAGASHENYI",
										generalAssembly_id: 230,
									},
									{
										id: 1508,
										name: "RUKUNGU",
										generalAssembly_id: 230,
									},
									{
										id: 9205,
										name: "Ndatemwa  Court of Appeal ",
										generalAssembly_id: 230,
									},
									{
										id: 10738,
										name: "Ndatemwa  Gacaca court of sector ",
										generalAssembly_id: 230,
									},
								],
							},
						],
					},
					{
						id: 40308,
						name: "Muhura",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030805,
								name: "Taba",
								sector_id: 40308,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030804,
								name: "Rumuli",
								sector_id: 40308,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030803,
								name: "Mamfu",
								sector_id: 40308,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030802,
								name: "Gakorokombe",
								sector_id: 40308,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030801,
								name: "Bibare",
								sector_id: 40308,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 231,
								name: "Gahara",
								sector_id: 40308,
								courtOfCells: [
									{
										id: 1509,
										name: "BYAHI",
										generalAssembly_id: 231,
									},
									{
										id: 1510,
										name: "GAHARA-I",
										generalAssembly_id: 231,
									},
									{
										id: 1511,
										name: "GAHARA-II",
										generalAssembly_id: 231,
									},
									{
										id: 1512,
										name: "RUGARAMA",
										generalAssembly_id: 231,
									},
									{
										id: 1513,
										name: "KAGOGO",
										generalAssembly_id: 231,
									},
									{
										id: 1514,
										name: "VIRO",
										generalAssembly_id: 231,
									},
									{
										id: 9206,
										name: "Gahara Court of Appeal ",
										generalAssembly_id: 231,
									},
									{
										id: 10739,
										name: "Gahara Gacaca court of sector ",
										generalAssembly_id: 231,
									},
								],
							},
							{
								id: 232,
								name: "Mamfu",
								sector_id: 40308,
								courtOfCells: [
									{
										id: 1515,
										name: "AGATAGARA",
										generalAssembly_id: 232,
									},
									{
										id: 1516,
										name: "AKAMATAMU",
										generalAssembly_id: 232,
									},
									{
										id: 1517,
										name: "KAZIGA",
										generalAssembly_id: 232,
									},
									{
										id: 1518,
										name: "RUHINGA",
										generalAssembly_id: 232,
									},
									{
										id: 1519,
										name: "UMUNANIRA",
										generalAssembly_id: 232,
									},
									{
										id: 9207,
										name: "Mamfu Court of Appeal ",
										generalAssembly_id: 232,
									},
									{
										id: 10740,
										name: "Mamfu Gacaca court of sector ",
										generalAssembly_id: 232,
									},
								],
							},
							{
								id: 233,
								name: "Rumuli",
								sector_id: 40308,
								courtOfCells: [
									{
										id: 1520,
										name: "JUGA",
										generalAssembly_id: 233,
									},
									{
										id: 1521,
										name: "KARAMA",
										generalAssembly_id: 233,
									},
									{
										id: 1522,
										name: "NYANGE-I",
										generalAssembly_id: 233,
									},
									{
										id: 1523,
										name: "NYANGE-II",
										generalAssembly_id: 233,
									},
									{
										id: 1524,
										name: "RWASAMA-I",
										generalAssembly_id: 233,
									},
									{
										id: 1525,
										name: "RWASAMA-II",
										generalAssembly_id: 233,
									},
									{
										id: 1526,
										name: "RWEZA-I",
										generalAssembly_id: 233,
									},
									{
										id: 1527,
										name: "RWEZA-II",
										generalAssembly_id: 233,
									},
									{
										id: 9208,
										name: "Rumuli Court of Appeal ",
										generalAssembly_id: 233,
									},
									{
										id: 10741,
										name: "Rumuli Gacaca court of sector ",
										generalAssembly_id: 233,
									},
								],
							},
							{
								id: 234,
								name: "Muhura",
								sector_id: 40308,
								courtOfCells: [
									{
										id: 1528,
										name: "KARENGE",
										generalAssembly_id: 234,
									},
									{
										id: 1529,
										name: "MWAMBARO",
										generalAssembly_id: 234,
									},
									{
										id: 1530,
										name: "NSHORO",
										generalAssembly_id: 234,
									},
									{
										id: 1531,
										name: "NYAGASAMBU",
										generalAssembly_id: 234,
									},
									{
										id: 1532,
										name: "RUGARAMA",
										generalAssembly_id: 234,
									},
									{
										id: 1533,
										name: "RUHENDA",
										generalAssembly_id: 234,
									},
									{
										id: 1534,
										name: "TABA",
										generalAssembly_id: 234,
									},
									{
										id: 9209,
										name: "Muhura Court of Appeal ",
										generalAssembly_id: 234,
									},
									{
										id: 10742,
										name: "Muhura Gacaca court of sector ",
										generalAssembly_id: 234,
									},
								],
							},
						],
					},
					{
						id: 40309,
						name: "Murambi",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4030904,
								name: "Rwimitereri",
								sector_id: 40309,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030903,
								name: "Rwankuba",
								sector_id: 40309,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030902,
								name: "Nyamiyaga",
								sector_id: 40309,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4030901,
								name: "Murambi",
								sector_id: 40309,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 235,
								name: "Murambi",
								sector_id: 40309,
								courtOfCells: [
									{
										id: 1535,
										name: "GASHARU",
										generalAssembly_id: 235,
									},
									{
										id: 1536,
										name: "AGATAGARA",
										generalAssembly_id: 235,
									},
									{
										id: 1537,
										name: "AKAMASHYA",
										generalAssembly_id: 235,
									},
									{
										id: 1538,
										name: "KIMIRONKO",
										generalAssembly_id: 235,
									},
									{
										id: 1539,
										name: "KINIGA",
										generalAssembly_id: 235,
									},
									{
										id: 1540,
										name: "MATABA",
										generalAssembly_id: 235,
									},
									{
										id: 1541,
										name: "RYAMPUNGA",
										generalAssembly_id: 235,
									},
									{
										id: 9210,
										name: "Murambi Court of Appeal ",
										generalAssembly_id: 235,
									},
									{
										id: 10743,
										name: "Murambi Gacaca court of sector ",
										generalAssembly_id: 235,
									},
								],
							},
							{
								id: 236,
								name: "Rwankuba ",
								sector_id: 40309,
								courtOfCells: [
									{
										id: 1542,
										name: "AKARAMBO",
										generalAssembly_id: 236,
									},
									{
										id: 1543,
										name: "UMWIGA",
										generalAssembly_id: 236,
									},
									{
										id: 1544,
										name: "IMPANZI",
										generalAssembly_id: 236,
									},
									{
										id: 1545,
										name: "NYAGASAMBU",
										generalAssembly_id: 236,
									},
									{
										id: 9211,
										name: "Rwankuba  Court of Appeal ",
										generalAssembly_id: 236,
									},
									{
										id: 10744,
										name: "Rwankuba  Gacaca court of sector ",
										generalAssembly_id: 236,
									},
								],
							},
							{
								id: 237,
								name: "Rwimitereri",
								sector_id: 40309,
								courtOfCells: [
									{
										id: 1546,
										name: "BUSHENYI",
										generalAssembly_id: 237,
									},
									{
										id: 1547,
										name: "BWERANYANGE",
										generalAssembly_id: 237,
									},
									{
										id: 1548,
										name: "BWEYA",
										generalAssembly_id: 237,
									},
									{
										id: 1549,
										name: "KABEZA",
										generalAssembly_id: 237,
									},
									{
										id: 1550,
										name: "KAGENGE",
										generalAssembly_id: 237,
									},
									{
										id: 1551,
										name: "KIGOTE",
										generalAssembly_id: 237,
									},
									{
										id: 1552,
										name: "KINIGA",
										generalAssembly_id: 237,
									},
									{
										id: 1553,
										name: "KINUNGA",
										generalAssembly_id: 237,
									},
									{
										id: 1554,
										name: "NYAKABANDA",
										generalAssembly_id: 237,
									},
									{
										id: 1555,
										name: "RUNYINYA",
										generalAssembly_id: 237,
									},
									{
										id: 1556,
										name: "RYANYAGAPFUMU",
										generalAssembly_id: 237,
									},
									{
										id: 9212,
										name: "Rwimitereri Court of Appeal ",
										generalAssembly_id: 237,
									},
									{
										id: 10745,
										name: "Rwimitereri Gacaca court of sector ",
										generalAssembly_id: 237,
									},
								],
							},
						],
					},
					{
						id: 40310,
						name: "Ngarama",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4031005,
								name: "Nyarubungo",
								sector_id: 40310,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031004,
								name: "Ngarama",
								sector_id: 40310,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031003,
								name: "Kigasha",
								sector_id: 40310,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031002,
								name: "Karambi",
								sector_id: 40310,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031001,
								name: "Bugamba",
								sector_id: 40310,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 238,
								name: "Ngarama ",
								sector_id: 40310,
								courtOfCells: [
									{
										id: 1557,
										name: "BUGAMBA",
										generalAssembly_id: 238,
									},
									{
										id: 1558,
										name: "KIMBUGU",
										generalAssembly_id: 238,
									},
									{
										id: 1559,
										name: "NGARAMA-A",
										generalAssembly_id: 238,
									},
									{
										id: 1560,
										name: "NGARAMA-B",
										generalAssembly_id: 238,
									},
									{
										id: 1561,
										name: "NYARUBUNGO",
										generalAssembly_id: 238,
									},
									{
										id: 1562,
										name: "RUGARAMA",
										generalAssembly_id: 238,
									},
									{
										id: 1563,
										name: "RWAGAKARA",
										generalAssembly_id: 238,
									},
									{
										id: 9213,
										name: "Ngarama  Court of Appeal ",
										generalAssembly_id: 238,
									},
									{
										id: 10746,
										name: "Ngarama  Gacaca court of sector ",
										generalAssembly_id: 238,
									},
								],
							},
							{
								id: 239,
								name: "Kigasha",
								sector_id: 40310,
								courtOfCells: [
									{
										id: 1564,
										name: "BUSHYANGUHE",
										generalAssembly_id: 239,
									},
									{
										id: 1565,
										name: "KANYINYA",
										generalAssembly_id: 239,
									},
									{
										id: 1566,
										name: "KIGASHA",
										generalAssembly_id: 239,
									},
									{
										id: 1567,
										name: "NYARURAMBI",
										generalAssembly_id: 239,
									},
									{
										id: 9214,
										name: "Kigasha Court of Appeal ",
										generalAssembly_id: 239,
									},
									{
										id: 10747,
										name: "Kigasha Gacaca court of sector ",
										generalAssembly_id: 239,
									},
								],
							},
						],
					},
					{
						id: 40311,
						name: "Nyagihanga",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4031106,
								name: "Nyamirama",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031105,
								name: "Nyagitabire",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031104,
								name: "Murambi",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031103,
								name: "Mayange",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031102,
								name: "Kibare",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031101,
								name: "Gitinda",
								sector_id: 40311,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 240,
								name: "Gitinda ",
								sector_id: 40311,
								courtOfCells: [
									{
										id: 1568,
										name: "GITINDA",
										generalAssembly_id: 240,
									},
									{
										id: 1569,
										name: "MAYANGE",
										generalAssembly_id: 240,
									},
									{
										id: 1570,
										name: "MPAGARE",
										generalAssembly_id: 240,
									},
									{
										id: 1571,
										name: "MURAMBI",
										generalAssembly_id: 240,
									},
									{
										id: 1572,
										name: "NEKE",
										generalAssembly_id: 240,
									},
									{
										id: 1573,
										name: "NYABUKINGI",
										generalAssembly_id: 240,
									},
									{
										id: 1574,
										name: "RUSHENYI",
										generalAssembly_id: 240,
									},
									{
										id: 9215,
										name: "Gitinda  Court of Appeal ",
										generalAssembly_id: 240,
									},
									{
										id: 10748,
										name: "Gitinda  Gacaca court of sector ",
										generalAssembly_id: 240,
									},
								],
							},
							{
								id: 241,
								name: "Karama ",
								sector_id: 40311,
								courtOfCells: [
									{
										id: 1575,
										name: "KARAMA",
										generalAssembly_id: 241,
									},
									{
										id: 1576,
										name: "MPASHANI",
										generalAssembly_id: 241,
									},
									{
										id: 1577,
										name: "NYAGITABIRE",
										generalAssembly_id: 241,
									},
									{
										id: 1578,
										name: "NYAMENGO",
										generalAssembly_id: 241,
									},
									{
										id: 9216,
										name: "Karama  Court of Appeal ",
										generalAssembly_id: 241,
									},
									{
										id: 10749,
										name: "Karama  Gacaca court of sector ",
										generalAssembly_id: 241,
									},
								],
							},
							{
								id: 242,
								name: "Buhanga ",
								sector_id: 40311,
								courtOfCells: [
									{
										id: 1579,
										name: "BUTEGERA",
										generalAssembly_id: 242,
									},
									{
										id: 1580,
										name: "GISHIKIRI",
										generalAssembly_id: 242,
									},
									{
										id: 1581,
										name: "KAGERA",
										generalAssembly_id: 242,
									},
									{
										id: 1582,
										name: "NYAMIRAMA",
										generalAssembly_id: 242,
									},
									{
										id: 9217,
										name: "Buhanga  Court of Appeal ",
										generalAssembly_id: 242,
									},
									{
										id: 10750,
										name: "Buhanga  Gacaca court of sector ",
										generalAssembly_id: 242,
									},
								],
							},
							{
								id: 243,
								name: "Nyagihanga ",
								sector_id: 40311,
								courtOfCells: [
									{
										id: 1583,
										name: "KIBARE",
										generalAssembly_id: 243,
									},
									{
										id: 1584,
										name: "MURAMBI",
										generalAssembly_id: 243,
									},
									{
										id: 1585,
										name: "NYAGIHANGA",
										generalAssembly_id: 243,
									},
									{
										id: 9218,
										name: "Nyagihanga  Court of Appeal ",
										generalAssembly_id: 243,
									},
									{
										id: 10751,
										name: "Nyagihanga  Gacaca court of sector ",
										generalAssembly_id: 243,
									},
								],
							},
						],
					},
					{
						id: 40312,
						name: "Remera",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4031206,
								name: "Rwarenga",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031205,
								name: "Rurenge",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031204,
								name: "Nyagakombe",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031203,
								name: "Kigabiro",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031202,
								name: "Butiruka",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031201,
								name: "Bushobora",
								sector_id: 40312,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 244,
								name: "Bugarura",
								sector_id: 40312,
								courtOfCells: [
									{
										id: 1586,
										name: "AKABARE",
										generalAssembly_id: 244,
									},
									{
										id: 1587,
										name: "BUSHOBORA",
										generalAssembly_id: 244,
									},
									{
										id: 1588,
										name: "KIMIRONKO",
										generalAssembly_id: 244,
									},
									{
										id: 1589,
										name: "NKORONKO",
										generalAssembly_id: 244,
									},
									{
										id: 1590,
										name: "NYARUHINGA",
										generalAssembly_id: 244,
									},
									{
										id: 1591,
										name: "RUGOMBE",
										generalAssembly_id: 244,
									},
									{
										id: 1592,
										name: "RWARENGA",
										generalAssembly_id: 244,
									},
									{
										id: 1593,
										name: "RWAMBUGA",
										generalAssembly_id: 244,
									},
									{
										id: 1594,
										name: "URUGUNGA",
										generalAssembly_id: 244,
									},
									{
										id: 9219,
										name: "Bugarura Court of Appeal ",
										generalAssembly_id: 244,
									},
									{
										id: 10752,
										name: "Bugarura Gacaca court of sector ",
										generalAssembly_id: 244,
									},
								],
							},
							{
								id: 245,
								name: "Humure ",
								sector_id: 40312,
								courtOfCells: [
									{
										id: 1595,
										name: "BIYANGA",
										generalAssembly_id: 245,
									},
									{
										id: 1596,
										name: "KIGABIRO",
										generalAssembly_id: 245,
									},
									{
										id: 1597,
										name: "KIRWA",
										generalAssembly_id: 245,
									},
									{
										id: 1598,
										name: "MPONDWA",
										generalAssembly_id: 245,
									},
									{
										id: 1599,
										name: "RWAMUSARO",
										generalAssembly_id: 245,
									},
									{
										id: 1600,
										name: "RYARUTSINZI",
										generalAssembly_id: 245,
									},
									{
										id: 9220,
										name: "Humure  Court of Appeal ",
										generalAssembly_id: 245,
									},
									{
										id: 10753,
										name: "Humure  Gacaca court of sector ",
										generalAssembly_id: 245,
									},
								],
							},
							{
								id: 246,
								name: "Remera",
								sector_id: 40312,
								courtOfCells: [
									{
										id: 1601,
										name: "AGAKIRI",
										generalAssembly_id: 246,
									},
									{
										id: 1602,
										name: "AKANYAGA",
										generalAssembly_id: 246,
									},
									{
										id: 1603,
										name: "BUTINZA",
										generalAssembly_id: 246,
									},
									{
										id: 1604,
										name: "BUTIRUKA",
										generalAssembly_id: 246,
									},
									{
										id: 1605,
										name: "BUTUNDA",
										generalAssembly_id: 246,
									},
									{
										id: 1606,
										name: "CYARUTABIRA",
										generalAssembly_id: 246,
									},
									{
										id: 1607,
										name: "NYAGAKOMBE",
										generalAssembly_id: 246,
									},
									{
										id: 1608,
										name: "NYAGASOZI",
										generalAssembly_id: 246,
									},
									{
										id: 1609,
										name: "NYAKAGARAMA",
										generalAssembly_id: 246,
									},
									{
										id: 1610,
										name: "RUBARE",
										generalAssembly_id: 246,
									},
									{
										id: 1611,
										name: "RURENGE",
										generalAssembly_id: 246,
									},
									{
										id: 1612,
										name: "RUTAGARA",
										generalAssembly_id: 246,
									},
									{
										id: 9221,
										name: "Remera Court of Appeal ",
										generalAssembly_id: 246,
									},
									{
										id: 10754,
										name: "Remera Gacaca court of sector ",
										generalAssembly_id: 246,
									},
								],
							},
						],
					},
					{
						id: 40313,
						name: "Rugarama",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4031306,
								name: "Remera",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031305,
								name: "Matunguru",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031304,
								name: "Matare",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031303,
								name: "Kanyangese",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031302,
								name: "Gihuta",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031301,
								name: "Bugarama",
								sector_id: 40313,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 247,
								name: "Gikoma",
								sector_id: 40313,
								courtOfCells: [
									{
										id: 1613,
										name: "MIKO",
										generalAssembly_id: 247,
									},
									{
										id: 1614,
										name: "REMERA",
										generalAssembly_id: 247,
									},
									{
										id: 1615,
										name: "RUBIHA",
										generalAssembly_id: 247,
									},
									{
										id: 1616,
										name: "RUGARAMA",
										generalAssembly_id: 247,
									},
									{
										id: 1617,
										name: "UWINGONDO",
										generalAssembly_id: 247,
									},
									{
										id: 9222,
										name: "Gikoma Court of Appeal ",
										generalAssembly_id: 247,
									},
									{
										id: 10755,
										name: "Gikoma Gacaca court of sector ",
										generalAssembly_id: 247,
									},
								],
							},
							{
								id: 248,
								name: "Rugarama",
								sector_id: 40313,
								courtOfCells: [
									{
										id: 1618,
										name: "MATUNGURU-II",
										generalAssembly_id: 248,
									},
									{
										id: 1619,
										name: "KANYANGESE",
										generalAssembly_id: 248,
									},
									{
										id: 1620,
										name: "RUGARAMA",
										generalAssembly_id: 248,
									},
									{
										id: 1621,
										name: "NYARUBARE",
										generalAssembly_id: 248,
									},
									{
										id: 1622,
										name: "MATUNGURU-I",
										generalAssembly_id: 248,
									},
									{
										id: 1623,
										name: "MATARE",
										generalAssembly_id: 248,
									},
									{
										id: 1624,
										name: "KAYENZI",
										generalAssembly_id: 248,
									},
									{
										id: 1625,
										name: "GITSIMBA",
										generalAssembly_id: 248,
									},
									{
										id: 1626,
										name: "GIHUTA",
										generalAssembly_id: 248,
									},
									{
										id: 1627,
										name: "NYAGAHANGA",
										generalAssembly_id: 248,
									},
									{
										id: 9223,
										name: "Rugarama Court of Appeal ",
										generalAssembly_id: 248,
									},
									{
										id: 10756,
										name: "Rugarama Gacaca court of sector ",
										generalAssembly_id: 248,
									},
								],
							},
						],
					},
					{
						id: 40314,
						name: "Rwimbogo",
						district_id: 403,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4031404,
								name: "Rwikiniro",
								sector_id: 40314,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031403,
								name: "Nyamatete",
								sector_id: 40314,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031402,
								name: "Munini",
								sector_id: 40314,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4031401,
								name: "Kiburara",
								sector_id: 40314,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 249,
								name: "Rwimbogo ",
								sector_id: 40314,
								courtOfCells: [
									{
										id: 1628,
										name: "KIYOVU",
										generalAssembly_id: 249,
									},
									{
										id: 1629,
										name: "NDAMA",
										generalAssembly_id: 249,
									},
									{
										id: 1630,
										name: "NGARAMA",
										generalAssembly_id: 249,
									},
									{
										id: 1631,
										name: "NYAMATETE",
										generalAssembly_id: 249,
									},
									{
										id: 1632,
										name: "RWIMBOGO",
										generalAssembly_id: 249,
									},
									{
										id: 1633,
										name: "RWIKINIRO",
										generalAssembly_id: 249,
									},
									{
										id: 9224,
										name: "Rwimbogo  Court of Appeal ",
										generalAssembly_id: 249,
									},
									{
										id: 10757,
										name: "Rwimbogo  Gacaca court of sector ",
										generalAssembly_id: 249,
									},
								],
							},
							{
								id: 250,
								name: "Kiburara",
								sector_id: 40314,
								courtOfCells: [
									{
										id: 1634,
										name: "KIBURARA-I",
										generalAssembly_id: 250,
									},
									{
										id: 1635,
										name: "KIBURARA-II",
										generalAssembly_id: 250,
									},
									{
										id: 1636,
										name: "NYACYONGA",
										generalAssembly_id: 250,
									},
									{
										id: 9225,
										name: "Kiburara Court of Appeal ",
										generalAssembly_id: 250,
									},
									{
										id: 10758,
										name: "Kiburara Gacaca court of sector ",
										generalAssembly_id: 250,
									},
								],
							},
							{
								id: 251,
								name: "Munini ",
								sector_id: 40314,
								courtOfCells: [
									{
										id: 1637,
										name: "KABEZA",
										generalAssembly_id: 251,
									},
									{
										id: 1638,
										name: "MUNINI",
										generalAssembly_id: 251,
									},
									{
										id: 9226,
										name: "Munini  Court of Appeal ",
										generalAssembly_id: 251,
									},
									{
										id: 10759,
										name: "Munini  Gacaca court of sector ",
										generalAssembly_id: 251,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 404,
				name: "Kayonza",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40401,
						name: "Gahini",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040104,
								name: "Urugarama",
								sector_id: 40401,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040103,
								name: "Kiyenzi",
								sector_id: 40401,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040102,
								name: "Kahi",
								sector_id: 40401,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040101,
								name: "Juru",
								sector_id: 40401,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 586,
								name: "Gahini",
								sector_id: 40401,
								courtOfCells: [
									{
										id: 3211,
										name: "AKABEZA",
										generalAssembly_id: 586,
									},
									{
										id: 3212,
										name: "BUYANJA",
										generalAssembly_id: 586,
									},
									{
										id: 3213,
										name: "IBIZA",
										generalAssembly_id: 586,
									},
									{
										id: 3214,
										name: "MICANZIGO",
										generalAssembly_id: 586,
									},
									{
										id: 3215,
										name: "RWINKUBA",
										generalAssembly_id: 586,
									},
									{
										id: 3216,
										name: "UMWIGA",
										generalAssembly_id: 586,
									},
									{
										id: 3217,
										name: "RUGARAMA",
										generalAssembly_id: 586,
									},
									{
										id: 9561,
										name: "Gahini Court of Appeal ",
										generalAssembly_id: 586,
									},
									{
										id: 11094,
										name: "Gahini Gacaca court of sector ",
										generalAssembly_id: 586,
									},
								],
							},
							{
								id: 587,
								name: "Kiyenzi",
								sector_id: 40401,
								courtOfCells: [
									{
										id: 3218,
										name: "KABUYE",
										generalAssembly_id: 587,
									},
									{
										id: 3219,
										name: "KINYINYA",
										generalAssembly_id: 587,
									},
									{
										id: 3220,
										name: "KIYENZI",
										generalAssembly_id: 587,
									},
									{
										id: 3221,
										name: "NYAGAHANDAGAZA",
										generalAssembly_id: 587,
									},
									{
										id: 9562,
										name: "Kiyenzi Court of Appeal ",
										generalAssembly_id: 587,
									},
									{
										id: 11095,
										name: "Kiyenzi Gacaca court of sector ",
										generalAssembly_id: 587,
									},
								],
							},
							{
								id: 588,
								name: "Nyakabungo",
								sector_id: 40401,
								courtOfCells: [
									{
										id: 3222,
										name: "JURU I",
										generalAssembly_id: 588,
									},
									{
										id: 3223,
										name: "JURU II",
										generalAssembly_id: 588,
									},
									{
										id: 3224,
										name: "KAMUDONGO",
										generalAssembly_id: 588,
									},
									{
										id: 3225,
										name: "NYAKABUNGO",
										generalAssembly_id: 588,
									},
									{
										id: 3226,
										name: "NYAMIYAGA",
										generalAssembly_id: 588,
									},
									{
										id: 3227,
										name: "RUBARIRO",
										generalAssembly_id: 588,
									},
									{
										id: 3228,
										name: "MWOGO",
										generalAssembly_id: 588,
									},
									{
										id: 9563,
										name: "Nyakabungo Court of Appeal ",
										generalAssembly_id: 588,
									},
									{
										id: 11096,
										name: "Nyakabungo Gacaca court of sector ",
										generalAssembly_id: 588,
									},
								],
							},
							{
								id: 589,
								name: "Nyamiyaga ",
								sector_id: 40401,
								courtOfCells: [
									{
										id: 3229,
										name: "JURU-TSIMA",
										generalAssembly_id: 589,
									},
									{
										id: 3230,
										name: "NYAMIYAGA",
										generalAssembly_id: 589,
									},
									{
										id: 9564,
										name: "Nyamiyaga  Court of Appeal ",
										generalAssembly_id: 589,
									},
									{
										id: 11097,
										name: "Nyamiyaga  Gacaca court of sector ",
										generalAssembly_id: 589,
									},
								],
							},
						],
					},
					{
						id: 40402,
						name: "Kabare",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040205,
								name: "Rubumba",
								sector_id: 40402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040204,
								name: "Rubimba",
								sector_id: 40402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040203,
								name: "Kirehe",
								sector_id: 40402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040202,
								name: "Gitara",
								sector_id: 40402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040201,
								name: "Cyarubare",
								sector_id: 40402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 590,
								name: "Kabare II",
								sector_id: 40402,
								courtOfCells: [
									{
										id: 3231,
										name: "BARA",
										generalAssembly_id: 590,
									},
									{
										id: 3232,
										name: "CYARUBARE",
										generalAssembly_id: 590,
									},
									{
										id: 3233,
										name: "GISHANDA",
										generalAssembly_id: 590,
									},
									{
										id: 3234,
										name: "GITARA",
										generalAssembly_id: 590,
									},
									{
										id: 3235,
										name: "KIREHE",
										generalAssembly_id: 590,
									},
									{
										id: 3236,
										name: "MATAHIRO",
										generalAssembly_id: 590,
									},
									{
										id: 3237,
										name: "NYARUSANGE",
										generalAssembly_id: 590,
									},
									{
										id: 3238,
										name: "RUBIMBA",
										generalAssembly_id: 590,
									},
									{
										id: 3239,
										name: "RUBUMBA",
										generalAssembly_id: 590,
									},
									{
										id: 3240,
										name: "RUGUNGA",
										generalAssembly_id: 590,
									},
									{
										id: 3241,
										name: "RUSHENYI",
										generalAssembly_id: 590,
									},
									{
										id: 9565,
										name: "Kabare II Court of Appeal ",
										generalAssembly_id: 590,
									},
									{
										id: 11098,
										name: "Kabare II Gacaca court of sector ",
										generalAssembly_id: 590,
									},
								],
							},
						],
					},
					{
						id: 40403,
						name: "Kabarondo",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040304,
								name: "Rusera",
								sector_id: 40403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040303,
								name: "Kabura",
								sector_id: 40403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040302,
								name: "Cyinzovu",
								sector_id: 40403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040301,
								name: "Cyabajwa",
								sector_id: 40403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 591,
								name: "Cyinzovu",
								sector_id: 40403,
								courtOfCells: [
									{
										id: 3242,
										name: "CYINZOVU",
										generalAssembly_id: 591,
									},
									{
										id: 3243,
										name: "NYABISENGA",
										generalAssembly_id: 591,
									},
									{
										id: 3244,
										name: "NYAKABUNGO",
										generalAssembly_id: 591,
									},
									{
										id: 3245,
										name: "RUGAZI II",
										generalAssembly_id: 591,
									},
									{
										id: 3246,
										name: "RURENGE",
										generalAssembly_id: 591,
									},
									{
										id: 9566,
										name: "Cyinzovu Court of Appeal ",
										generalAssembly_id: 591,
									},
									{
										id: 11099,
										name: "Cyinzovu Gacaca court of sector ",
										generalAssembly_id: 591,
									},
								],
							},
							{
								id: 592,
								name: "Kabarondo",
								sector_id: 40403,
								courtOfCells: [
									{
										id: 3247,
										name: "BITOMA",
										generalAssembly_id: 592,
									},
									{
										id: 3248,
										name: "BUSINDU",
										generalAssembly_id: 592,
									},
									{
										id: 3249,
										name: "CYABAJWA",
										generalAssembly_id: 592,
									},
									{
										id: 3250,
										name: "KABARONDO",
										generalAssembly_id: 592,
									},
									{
										id: 3251,
										name: "RUGAZI I",
										generalAssembly_id: 592,
									},
									{
										id: 9567,
										name: "Kabarondo Court of Appeal ",
										generalAssembly_id: 592,
									},
									{
										id: 11100,
										name: "Kabarondo Gacaca court of sector ",
										generalAssembly_id: 592,
									},
								],
							},
							{
								id: 593,
								name: "Rubira",
								sector_id: 40403,
								courtOfCells: [
									{
										id: 3252,
										name: "AGASHARU",
										generalAssembly_id: 593,
									},
									{
										id: 3253,
										name: "AKAGARAMA",
										generalAssembly_id: 593,
									},
									{
										id: 3254,
										name: "NYABIKENKE I",
										generalAssembly_id: 593,
									},
									{
										id: 3255,
										name: "NYABIKENKE II",
										generalAssembly_id: 593,
									},
									{
										id: 3256,
										name: "RUBIRA",
										generalAssembly_id: 593,
									},
									{
										id: 9568,
										name: "Rubira Court of Appeal ",
										generalAssembly_id: 593,
									},
									{
										id: 11101,
										name: "Rubira Gacaca court of sector ",
										generalAssembly_id: 593,
									},
								],
							},
							{
								id: 594,
								name: "Rundu",
								sector_id: 40403,
								courtOfCells: [
									{
										id: 3257,
										name: "GASHONYI",
										generalAssembly_id: 594,
									},
									{
										id: 3258,
										name: "GISORO",
										generalAssembly_id: 594,
									},
									{
										id: 3259,
										name: "KABUYE",
										generalAssembly_id: 594,
									},
									{
										id: 3260,
										name: "MURAMBI",
										generalAssembly_id: 594,
									},
									{
										id: 3261,
										name: "RYAGAHORO",
										generalAssembly_id: 594,
									},
									{
										id: 9569,
										name: "Rundu Court of Appeal ",
										generalAssembly_id: 594,
									},
									{
										id: 11102,
										name: "Rundu Gacaca court of sector ",
										generalAssembly_id: 594,
									},
								],
							},
							{
								id: 595,
								name: "Rusera",
								sector_id: 40403,
								courtOfCells: [
									{
										id: 3262,
										name: "NKUBA",
										generalAssembly_id: 595,
									},
									{
										id: 3263,
										name: "RUGWAGWA",
										generalAssembly_id: 595,
									},
									{
										id: 3264,
										name: "RURAMA",
										generalAssembly_id: 595,
									},
									{
										id: 3265,
										name: "RUSERA",
										generalAssembly_id: 595,
									},
									{
										id: 3266,
										name: "BUTOBAGIRE",
										generalAssembly_id: 595,
									},
									{
										id: 9570,
										name: "Rusera Court of Appeal ",
										generalAssembly_id: 595,
									},
									{
										id: 11103,
										name: "Rusera Gacaca court of sector ",
										generalAssembly_id: 595,
									},
								],
							},
						],
					},
					{
						id: 40404,
						name: "Mukarange",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040405,
								name: "Rugendabari",
								sector_id: 40404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040404,
								name: "Nyagatovu",
								sector_id: 40404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040403,
								name: "Mburabuturo",
								sector_id: 40404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040402,
								name: "Kayonza",
								sector_id: 40404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040401,
								name: "Bwiza",
								sector_id: 40404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 596,
								name: "Mburabuturo",
								sector_id: 40404,
								courtOfCells: [
									{
										id: 3267,
										name: "BWINGEYO",
										generalAssembly_id: 596,
									},
									{
										id: 3268,
										name: "GIHINA",
										generalAssembly_id: 596,
									},
									{
										id: 3269,
										name: "KINUNGA",
										generalAssembly_id: 596,
									},
									{
										id: 3270,
										name: "MBURABUTURO",
										generalAssembly_id: 596,
									},
									{
										id: 9571,
										name: "Mburabuturo Court of Appeal ",
										generalAssembly_id: 596,
									},
									{
										id: 11104,
										name: "Mburabuturo Gacaca court of sector ",
										generalAssembly_id: 596,
									},
								],
							},
							{
								id: 597,
								name: "Mukarange",
								sector_id: 40404,
								courtOfCells: [
									{
										id: 3271,
										name: "BWIZA",
										generalAssembly_id: 597,
									},
									{
										id: 3272,
										name: "GASOGORORO",
										generalAssembly_id: 597,
									},
									{
										id: 3273,
										name: "KABUYE",
										generalAssembly_id: 597,
									},
									{
										id: 3274,
										name: "KINYEMERA",
										generalAssembly_id: 597,
									},
									{
										id: 3275,
										name: "KAYONZA CENTRE",
										generalAssembly_id: 597,
									},
									{
										id: 3276,
										name: "KIYANGE",
										generalAssembly_id: 597,
									},
									{
										id: 3277,
										name: "NURAZI",
										generalAssembly_id: 597,
									},
									{
										id: 3278,
										name: "NYABUBARE",
										generalAssembly_id: 597,
									},
									{
										id: 9572,
										name: "Mukarange Court of Appeal ",
										generalAssembly_id: 597,
									},
									{
										id: 11105,
										name: "Mukarange Gacaca court of sector ",
										generalAssembly_id: 597,
									},
								],
							},
							{
								id: 598,
								name: "Rutare",
								sector_id: 40404,
								courtOfCells: [
									{
										id: 3279,
										name: "GIKUMBA",
										generalAssembly_id: 598,
									},
									{
										id: 3280,
										name: "NYAMASHYA",
										generalAssembly_id: 598,
									},
									{
										id: 3281,
										name: "KARAMBO",
										generalAssembly_id: 598,
									},
									{
										id: 3282,
										name: "RUGENDABARI",
										generalAssembly_id: 598,
									},
									{
										id: 3283,
										name: "RUTARE",
										generalAssembly_id: 598,
									},
									{
										id: 9573,
										name: "Rutare Court of Appeal ",
										generalAssembly_id: 598,
									},
									{
										id: 11106,
										name: "Rutare Gacaca court of sector ",
										generalAssembly_id: 598,
									},
								],
							},
							{
								id: 599,
								name: "Nyagatovu",
								sector_id: 40404,
								courtOfCells: [
									{
										id: 3284,
										name: "BUHONDI",
										generalAssembly_id: 599,
									},
									{
										id: 3285,
										name: "BYANGE",
										generalAssembly_id: 599,
									},
									{
										id: 3286,
										name: "CYERU",
										generalAssembly_id: 599,
									},
									{
										id: 3287,
										name: "GATAGARA",
										generalAssembly_id: 599,
									},
									{
										id: 3288,
										name: "KAZIRABWAYI",
										generalAssembly_id: 599,
									},
									{
										id: 3289,
										name: "NYAGATOVU",
										generalAssembly_id: 599,
									},
									{
										id: 3290,
										name: "RAGWE",
										generalAssembly_id: 599,
									},
									{
										id: 9574,
										name: "Nyagatovu Court of Appeal ",
										generalAssembly_id: 599,
									},
									{
										id: 11107,
										name: "Nyagatovu Gacaca court of sector ",
										generalAssembly_id: 599,
									},
								],
							},
						],
					},
					{
						id: 40405,
						name: "Murama",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040505,
								name: "Rusave",
								sector_id: 40405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040504,
								name: "Nyakanazi",
								sector_id: 40405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040503,
								name: "Murama",
								sector_id: 40405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040502,
								name: "Muko",
								sector_id: 40405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040501,
								name: "Bunyentongo",
								sector_id: 40405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 600,
								name: "Bisenga",
								sector_id: 40405,
								courtOfCells: [
									{
										id: 3291,
										name: "MUKO",
										generalAssembly_id: 600,
									},
									{
										id: 3292,
										name: "MUTUMBA",
										generalAssembly_id: 600,
									},
									{
										id: 3293,
										name: "NGOMA",
										generalAssembly_id: 600,
									},
									{
										id: 3294,
										name: "NYAKANAZI",
										generalAssembly_id: 600,
									},
									{
										id: 3295,
										name: "RURENGE",
										generalAssembly_id: 600,
									},
									{
										id: 9575,
										name: "Bisenga Court of Appeal ",
										generalAssembly_id: 600,
									},
									{
										id: 11108,
										name: "Bisenga Gacaca court of sector ",
										generalAssembly_id: 600,
									},
								],
							},
							{
								id: 601,
								name: "Murama",
								sector_id: 40405,
								courtOfCells: [
									{
										id: 3296,
										name: "BUBINDI",
										generalAssembly_id: 601,
									},
									{
										id: 3297,
										name: "MURAMA",
										generalAssembly_id: 601,
									},
									{
										id: 3298,
										name: "RUSARO I",
										generalAssembly_id: 601,
									},
									{
										id: 3299,
										name: "RUSARO II",
										generalAssembly_id: 601,
									},
									{
										id: 3300,
										name: "RWABUGENGERI",
										generalAssembly_id: 601,
									},
									{
										id: 9576,
										name: "Murama Court of Appeal ",
										generalAssembly_id: 601,
									},
									{
										id: 11109,
										name: "Murama Gacaca court of sector ",
										generalAssembly_id: 601,
									},
								],
							},
							{
								id: 602,
								name: "Shyanda",
								sector_id: 40405,
								courtOfCells: [
									{
										id: 3301,
										name: "BUNYETONGO",
										generalAssembly_id: 602,
									},
									{
										id: 3302,
										name: "GAHENGERI",
										generalAssembly_id: 602,
									},
									{
										id: 3303,
										name: "GISUNZU",
										generalAssembly_id: 602,
									},
									{
										id: 3304,
										name: "NYAKAGEZI",
										generalAssembly_id: 602,
									},
									{
										id: 3305,
										name: "RUSAVE",
										generalAssembly_id: 602,
									},
									{
										id: 9577,
										name: "Shyanda Court of Appeal ",
										generalAssembly_id: 602,
									},
									{
										id: 11110,
										name: "Shyanda Gacaca court of sector ",
										generalAssembly_id: 602,
									},
								],
							},
						],
					},
					{
						id: 40406,
						name: "Murundi",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040604,
								name: "Ryamanyoni",
								sector_id: 40406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040603,
								name: "Murundi",
								sector_id: 40406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040602,
								name: "Karambi",
								sector_id: 40406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040601,
								name: "Buhabwa",
								sector_id: 40406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 603,
								name: "Buhabwa ",
								sector_id: 40406,
								courtOfCells: [
									{
										id: 3306,
										name: "BUHABWA",
										generalAssembly_id: 603,
									},
									{
										id: 3307,
										name: "CYAMBURARA",
										generalAssembly_id: 603,
									},
									{
										id: 3308,
										name: "GAKOMA",
										generalAssembly_id: 603,
									},
									{
										id: 3309,
										name: "MIYAGA",
										generalAssembly_id: 603,
									},
									{
										id: 9578,
										name: "Buhabwa  Court of Appeal ",
										generalAssembly_id: 603,
									},
									{
										id: 11111,
										name: "Buhabwa  Gacaca court of sector ",
										generalAssembly_id: 603,
									},
								],
							},
							{
								id: 604,
								name: "Murundi",
								sector_id: 40406,
								courtOfCells: [
									{
										id: 3310,
										name: "KARAMBI",
										generalAssembly_id: 604,
									},
									{
										id: 3311,
										name: "KAYONGO",
										generalAssembly_id: 604,
									},
									{
										id: 3312,
										name: "KIBARI",
										generalAssembly_id: 604,
									},
									{
										id: 3313,
										name: "KINYANA-MURUNDI",
										generalAssembly_id: 604,
									},
									{
										id: 3314,
										name: "RWEZA",
										generalAssembly_id: 604,
									},
									{
										id: 3315,
										name: "RWINSHEKE",
										generalAssembly_id: 604,
									},
									{
										id: 3316,
										name: "MURUNDI",
										generalAssembly_id: 604,
									},
									{
										id: 9579,
										name: "Murundi Court of Appeal ",
										generalAssembly_id: 604,
									},
									{
										id: 11112,
										name: "Murundi Gacaca court of sector ",
										generalAssembly_id: 604,
									},
								],
							},
							{
								id: 605,
								name: "Ryamanyoni",
								sector_id: 40406,
								courtOfCells: [
									{
										id: 3317,
										name: "GACACA",
										generalAssembly_id: 605,
									},
									{
										id: 3318,
										name: "RWAKABANDA",
										generalAssembly_id: 605,
									},
									{
										id: 3319,
										name: "RYAKIREZI",
										generalAssembly_id: 605,
									},
									{
										id: 3320,
										name: "RYAMANYONI",
										generalAssembly_id: 605,
									},
									{
										id: 9580,
										name: "Ryamanyoni Court of Appeal ",
										generalAssembly_id: 605,
									},
									{
										id: 11113,
										name: "Ryamanyoni Gacaca court of sector ",
										generalAssembly_id: 605,
									},
								],
							},
						],
					},
					{
						id: 40407,
						name: "Mwiri",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040704,
								name: "Nyawera",
								sector_id: 40407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040703,
								name: "Nyamugari",
								sector_id: 40407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040702,
								name: "Migera",
								sector_id: 40407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040701,
								name: "Kageyo",
								sector_id: 40407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 606,
								name: "Kageyo ",
								sector_id: 40407,
								courtOfCells: [
									{
										id: 3321,
										name: "NDAGO-RWISIRABO",
										generalAssembly_id: 606,
									},
									{
										id: 3322,
										name: "SEBASENGO",
										generalAssembly_id: 606,
									},
									{
										id: 9581,
										name: "Kageyo  Court of Appeal ",
										generalAssembly_id: 606,
									},
									{
										id: 11114,
										name: "Kageyo  Gacaca court of sector ",
										generalAssembly_id: 606,
									},
								],
							},
							{
								id: 607,
								name: "Mwiri",
								sector_id: 40407,
								courtOfCells: [
									{
										id: 3323,
										name: "CYANYABUGAHE",
										generalAssembly_id: 607,
									},
									{
										id: 3324,
										name: "MWIRI",
										generalAssembly_id: 607,
									},
									{
										id: 3325,
										name: "NYAKABUNGO",
										generalAssembly_id: 607,
									},
									{
										id: 9582,
										name: "Mwiri Court of Appeal ",
										generalAssembly_id: 607,
									},
									{
										id: 11115,
										name: "Mwiri Gacaca court of sector ",
										generalAssembly_id: 607,
									},
								],
							},
							{
								id: 608,
								name: "Nyawera ",
								sector_id: 40407,
								courtOfCells: [
									{
										id: 3326,
										name: "MUHOZI",
										generalAssembly_id: 608,
									},
									{
										id: 3327,
										name: "MUREHE",
										generalAssembly_id: 608,
									},
									{
										id: 3328,
										name: "MURORI",
										generalAssembly_id: 608,
									},
									{
										id: 3329,
										name: "MWIRI",
										generalAssembly_id: 608,
									},
									{
										id: 9583,
										name: "Nyawera  Court of Appeal ",
										generalAssembly_id: 608,
									},
									{
										id: 11116,
										name: "Nyawera  Gacaca court of sector ",
										generalAssembly_id: 608,
									},
								],
							},
							{
								id: 609,
								name: "Rwazana",
								sector_id: 40407,
								courtOfCells: [
									{
										id: 3330,
										name: "NYAMUGALI",
										generalAssembly_id: 609,
									},
									{
										id: 3331,
										name: "RWAZANA",
										generalAssembly_id: 609,
									},
									{
										id: 3332,
										name: "KIGARAMA",
										generalAssembly_id: 609,
									},
									{
										id: 9584,
										name: "Rwazana Court of Appeal ",
										generalAssembly_id: 609,
									},
									{
										id: 11117,
										name: "Rwazana Gacaca court of sector ",
										generalAssembly_id: 609,
									},
								],
							},
						],
					},
					{
						id: 40408,
						name: "Ndego",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040804,
								name: "Kiyovu",
								sector_id: 40408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040803,
								name: "Karambi",
								sector_id: 40408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040802,
								name: "Isangano",
								sector_id: 40408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040801,
								name: "Byimana",
								sector_id: 40408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 610,
								name: "Ndego",
								sector_id: 40408,
								courtOfCells: [
									{
										id: 3333,
										name: "BYIMANA",
										generalAssembly_id: 610,
									},
									{
										id: 3334,
										name: "GASABO",
										generalAssembly_id: 610,
									},
									{
										id: 3335,
										name: "GASENYI",
										generalAssembly_id: 610,
									},
									{
										id: 3336,
										name: "ISANGANO",
										generalAssembly_id: 610,
									},
									{
										id: 3337,
										name: "KARAMBI",
										generalAssembly_id: 610,
									},
									{
										id: 3338,
										name: "KARAMBO",
										generalAssembly_id: 610,
									},
									{
										id: 3339,
										name: "KIYOVU",
										generalAssembly_id: 610,
									},
									{
										id: 3340,
										name: "NYAKABINGO",
										generalAssembly_id: 610,
									},
									{
										id: 3341,
										name: "NYAMATA",
										generalAssembly_id: 610,
									},
									{
										id: 3342,
										name: "NYAMUGARI",
										generalAssembly_id: 610,
									},
									{
										id: 9585,
										name: "Ndego Court of Appeal ",
										generalAssembly_id: 610,
									},
									{
										id: 11118,
										name: "Ndego Gacaca court of sector ",
										generalAssembly_id: 610,
									},
								],
							},
						],
					},
					{
						id: 40409,
						name: "Nyamirama",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4040904,
								name: "Shyogo",
								sector_id: 40409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040903,
								name: "Rurambi",
								sector_id: 40409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040902,
								name: "Musumba",
								sector_id: 40409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4040901,
								name: "Gikaya",
								sector_id: 40409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 611,
								name: "Gasogi ",
								sector_id: 40409,
								courtOfCells: [
									{
										id: 3343,
										name: "GASOGI",
										generalAssembly_id: 611,
									},
									{
										id: 3344,
										name: "GASURA",
										generalAssembly_id: 611,
									},
									{
										id: 3345,
										name: "KABUYE",
										generalAssembly_id: 611,
									},
									{
										id: 3346,
										name: "RURAMBI",
										generalAssembly_id: 611,
									},
									{
										id: 3347,
										name: "KINKORONKO",
										generalAssembly_id: 611,
									},
									{
										id: 9586,
										name: "Gasogi  Court of Appeal ",
										generalAssembly_id: 611,
									},
									{
										id: 11119,
										name: "Gasogi  Gacaca court of sector ",
										generalAssembly_id: 611,
									},
								],
							},
							{
								id: 612,
								name: "Musumba",
								sector_id: 40409,
								courtOfCells: [
									{
										id: 3348,
										name: "KANANGO",
										generalAssembly_id: 612,
									},
									{
										id: 3349,
										name: "MUSUMBA",
										generalAssembly_id: 612,
									},
									{
										id: 3350,
										name: "NYAGASAMBU",
										generalAssembly_id: 612,
									},
									{
										id: 3351,
										name: "RUSERA",
										generalAssembly_id: 612,
									},
									{
										id: 9587,
										name: "Musumba Court of Appeal ",
										generalAssembly_id: 612,
									},
									{
										id: 11120,
										name: "Musumba Gacaca court of sector ",
										generalAssembly_id: 612,
									},
								],
							},
							{
								id: 613,
								name: "Nyamirama",
								sector_id: 40409,
								courtOfCells: [
									{
										id: 3352,
										name: "KABUYE",
										generalAssembly_id: 613,
									},
									{
										id: 3353,
										name: "KABUYA",
										generalAssembly_id: 613,
									},
									{
										id: 3354,
										name: "NTINTYI",
										generalAssembly_id: 613,
									},
									{
										id: 3355,
										name: "RURAMBI",
										generalAssembly_id: 613,
									},
									{
										id: 3356,
										name: "RUVUMU",
										generalAssembly_id: 613,
									},
									{
										id: 9588,
										name: "Nyamirama Court of Appeal ",
										generalAssembly_id: 613,
									},
									{
										id: 11121,
										name: "Nyamirama Gacaca court of sector ",
										generalAssembly_id: 613,
									},
								],
							},
							{
								id: 614,
								name: "Shyogo",
								sector_id: 40409,
								courtOfCells: [
									{
										id: 3357,
										name: "GASHARU",
										generalAssembly_id: 614,
									},
									{
										id: 3358,
										name: "GATOKI",
										generalAssembly_id: 614,
									},
									{
										id: 3359,
										name: "NYAKAGARAMA",
										generalAssembly_id: 614,
									},
									{
										id: 3360,
										name: "RUGAGI",
										generalAssembly_id: 614,
									},
									{
										id: 3361,
										name: "RWINYANA",
										generalAssembly_id: 614,
									},
									{
										id: 9589,
										name: "Shyogo Court of Appeal ",
										generalAssembly_id: 614,
									},
									{
										id: 11122,
										name: "Shyogo Gacaca court of sector ",
										generalAssembly_id: 614,
									},
								],
							},
						],
					},
					{
						id: 40410,
						name: "Rukara",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4041003,
								name: "Rwimishinya",
								sector_id: 40410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4041002,
								name: "Rukara",
								sector_id: 40410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4041001,
								name: "Kawangire",
								sector_id: 40410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 615,
								name: "Kawangire",
								sector_id: 40410,
								courtOfCells: [
									{
										id: 3362,
										name: "BUTIMBA",
										generalAssembly_id: 615,
									},
									{
										id: 3363,
										name: "GAKENYERI",
										generalAssembly_id: 615,
									},
									{
										id: 3364,
										name: "KARAMA",
										generalAssembly_id: 615,
									},
									{
										id: 3365,
										name: "KIDOGO",
										generalAssembly_id: 615,
									},
									{
										id: 3366,
										name: "KINUNGA",
										generalAssembly_id: 615,
									},
									{
										id: 3367,
										name: "GITEGA",
										generalAssembly_id: 615,
									},
									{
										id: 9590,
										name: "Kawangire Court of Appeal ",
										generalAssembly_id: 615,
									},
									{
										id: 11123,
										name: "Kawangire Gacaca court of sector ",
										generalAssembly_id: 615,
									},
								],
							},
							{
								id: 616,
								name: "Rukara",
								sector_id: 40410,
								courtOfCells: [
									{
										id: 3368,
										name: "BUTIMBA",
										generalAssembly_id: 616,
									},
									{
										id: 3369,
										name: "BUYONZA",
										generalAssembly_id: 616,
									},
									{
										id: 3370,
										name: "KARAMBO",
										generalAssembly_id: 616,
									},
									{
										id: 3371,
										name: "KINUNGA",
										generalAssembly_id: 616,
									},
									{
										id: 3372,
										name: "MITUNGO",
										generalAssembly_id: 616,
									},
									{
										id: 3373,
										name: "MIMURI",
										generalAssembly_id: 616,
									},
									{
										id: 3374,
										name: "MUZIZI",
										generalAssembly_id: 616,
									},
									{
										id: 9591,
										name: "Rukara Court of Appeal ",
										generalAssembly_id: 616,
									},
									{
										id: 11124,
										name: "Rukara Gacaca court of sector ",
										generalAssembly_id: 616,
									},
								],
							},
							{
								id: 617,
								name: "Rwimishinya",
								sector_id: 40410,
								courtOfCells: [
									{
										id: 3375,
										name: "AKABARE",
										generalAssembly_id: 617,
									},
									{
										id: 3376,
										name: "KARAGALI",
										generalAssembly_id: 617,
									},
									{
										id: 3377,
										name: "KINUNGA",
										generalAssembly_id: 617,
									},
									{
										id: 3378,
										name: "MURAMBI",
										generalAssembly_id: 617,
									},
									{
										id: 3379,
										name: "NYABIGEGA",
										generalAssembly_id: 617,
									},
									{
										id: 3380,
										name: "NYARUNAZI",
										generalAssembly_id: 617,
									},
									{
										id: 3381,
										name: "NYARUTUNGA",
										generalAssembly_id: 617,
									},
									{
										id: 3382,
										name: "KIGWENE-RWIBYIYONI",
										generalAssembly_id: 617,
									},
									{
										id: 9592,
										name: "Rwimishinya Court of Appeal ",
										generalAssembly_id: 617,
									},
									{
										id: 11125,
										name: "Rwimishinya Gacaca court of sector ",
										generalAssembly_id: 617,
									},
								],
							},
						],
					},
					{
						id: 40411,
						name: "Ruramira",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4041104,
								name: "Umubuga",
								sector_id: 40411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4041103,
								name: "Ruyonza",
								sector_id: 40411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4041102,
								name: "Nkamba",
								sector_id: 40411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4041101,
								name: "Bugambira",
								sector_id: 40411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 618,
								name: "Nkamba",
								sector_id: 40411,
								courtOfCells: [
									{
										id: 3383,
										name: "AGATARE",
										generalAssembly_id: 618,
									},
									{
										id: 3384,
										name: "GITWA",
										generalAssembly_id: 618,
									},
									{
										id: 3385,
										name: "CYEMU",
										generalAssembly_id: 618,
									},
									{
										id: 3386,
										name: "MABUGA",
										generalAssembly_id: 618,
									},
									{
										id: 3387,
										name: "RYAMAKAZA",
										generalAssembly_id: 618,
									},
									{
										id: 9593,
										name: "Nkamba Court of Appeal ",
										generalAssembly_id: 618,
									},
									{
										id: 11126,
										name: "Nkamba Gacaca court of sector ",
										generalAssembly_id: 618,
									},
								],
							},
							{
								id: 619,
								name: "Rukira",
								sector_id: 40411,
								courtOfCells: [
									{
										id: 3388,
										name: "AGASHARU",
										generalAssembly_id: 619,
									},
									{
										id: 3389,
										name: "AGATARE",
										generalAssembly_id: 619,
									},
									{
										id: 3390,
										name: "AMASHYA",
										generalAssembly_id: 619,
									},
									{
										id: 3391,
										name: "BUHORO",
										generalAssembly_id: 619,
									},
									{
										id: 3392,
										name: "MURAMBI",
										generalAssembly_id: 619,
									},
									{
										id: 9594,
										name: "Rukira Court of Appeal ",
										generalAssembly_id: 619,
									},
									{
										id: 11127,
										name: "Rukira Gacaca court of sector ",
										generalAssembly_id: 619,
									},
								],
							},
							{
								id: 620,
								name: "Ruramira",
								sector_id: 40411,
								courtOfCells: [
									{
										id: 3393,
										name: "AKARUGINA",
										generalAssembly_id: 620,
									},
									{
										id: 3394,
										name: "AMATABA",
										generalAssembly_id: 620,
									},
									{
										id: 3395,
										name: "AMAZINGA",
										generalAssembly_id: 620,
									},
									{
										id: 3396,
										name: "NTAREMBA",
										generalAssembly_id: 620,
									},
									{
										id: 3397,
										name: "UMUBUGA",
										generalAssembly_id: 620,
									},
									{
										id: 9595,
										name: "Ruramira Court of Appeal ",
										generalAssembly_id: 620,
									},
									{
										id: 11128,
										name: "Ruramira Gacaca court of sector ",
										generalAssembly_id: 620,
									},
								],
							},
							{
								id: 621,
								name: "Ruyonza",
								sector_id: 40411,
								courtOfCells: [
									{
										id: 3398,
										name: "TABA",
										generalAssembly_id: 621,
									},
									{
										id: 3399,
										name: "GISENGA",
										generalAssembly_id: 621,
									},
									{
										id: 3400,
										name: "GITWA",
										generalAssembly_id: 621,
									},
									{
										id: 3401,
										name: "RUKOMA",
										generalAssembly_id: 621,
									},
									{
										id: 3402,
										name: "MURENGE",
										generalAssembly_id: 621,
									},
									{
										id: 9596,
										name: "Ruyonza Court of Appeal ",
										generalAssembly_id: 621,
									},
									{
										id: 11129,
										name: "Ruyonza Gacaca court of sector ",
										generalAssembly_id: 621,
									},
								],
							},
						],
					},
					{
						id: 40412,
						name: "Rwinkwavu",
						district_id: 404,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4041204,
								name: "Nkondo ",
								sector_id: 40412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4041203,
								name: "Mukoyoyo",
								sector_id: 40412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4041202,
								name: "Mbarara",
								sector_id: 40412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4041201,
								name: "Gihinga",
								sector_id: 40412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 622,
								name: "Cyabajwa",
								sector_id: 40412,
								courtOfCells: [
									{
										id: 3403,
										name: "CYABAJWA I",
										generalAssembly_id: 622,
									},
									{
										id: 3404,
										name: "GIHINGA",
										generalAssembly_id: 622,
									},
									{
										id: 3405,
										name: "RUHAMBO",
										generalAssembly_id: 622,
									},
									{
										id: 3406,
										name: "MBARARA",
										generalAssembly_id: 622,
									},
									{
										id: 3407,
										name: "MIGERA",
										generalAssembly_id: 622,
									},
									{
										id: 9597,
										name: "Cyabajwa Court of Appeal ",
										generalAssembly_id: 622,
									},
									{
										id: 11130,
										name: "Cyabajwa Gacaca court of sector ",
										generalAssembly_id: 622,
									},
								],
							},
							{
								id: 623,
								name: "Gishanda ",
								sector_id: 40412,
								courtOfCells: [
									{
										id: 3408,
										name: "GISHANDA",
										generalAssembly_id: 623,
									},
									{
										id: 3409,
										name: "MUKOYOYO",
										generalAssembly_id: 623,
									},
									{
										id: 9598,
										name: "Gishanda  Court of Appeal ",
										generalAssembly_id: 623,
									},
									{
										id: 11131,
										name: "Gishanda  Gacaca court of sector ",
										generalAssembly_id: 623,
									},
								],
							},
							{
								id: 624,
								name: "Rwinkwavu",
								sector_id: 40412,
								courtOfCells: [
									{
										id: 3410,
										name: "CYABAJWA II",
										generalAssembly_id: 624,
									},
									{
										id: 3411,
										name: "NKONDO I",
										generalAssembly_id: 624,
									},
									{
										id: 3412,
										name: "NKONDO II",
										generalAssembly_id: 624,
									},
									{
										id: 9599,
										name: "Rwinkwavu Court of Appeal ",
										generalAssembly_id: 624,
									},
									{
										id: 11132,
										name: "Rwinkwavu Gacaca court of sector ",
										generalAssembly_id: 624,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 405,
				name: "Kirehe",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40501,
						name: "Gahara",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050106,
								name: "Rubimba",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050105,
								name: "Nyakagezi",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050104,
								name: "Nyagasenyi",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050103,
								name: "Murehe",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050102,
								name: "Muhamba",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050101,
								name: "Butezi",
								sector_id: 40501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 642,
								name: "Gahara",
								sector_id: 40501,
								courtOfCells: [
									{
										id: 3498,
										name: "BUTANGA",
										generalAssembly_id: 642,
									},
									{
										id: 3499,
										name: "KAGARAMA",
										generalAssembly_id: 642,
									},
									{
										id: 3500,
										name: "MUGOGO",
										generalAssembly_id: 642,
									},
									{
										id: 3501,
										name: "MUHAMBA",
										generalAssembly_id: 642,
									},
									{
										id: 3502,
										name: "MURANGARA",
										generalAssembly_id: 642,
									},
									{
										id: 3503,
										name: "MUREHE",
										generalAssembly_id: 642,
									},
									{
										id: 3504,
										name: "NYAKAGEZI",
										generalAssembly_id: 642,
									},
									{
										id: 3505,
										name: "RUBIMBA",
										generalAssembly_id: 642,
									},
									{
										id: 3506,
										name: "TARAYI",
										generalAssembly_id: 642,
									},
									{
										id: 9617,
										name: "Gahara Court of Appeal ",
										generalAssembly_id: 642,
									},
									{
										id: 11150,
										name: "Gahara Gacaca court of sector ",
										generalAssembly_id: 642,
									},
								],
							},
							{
								id: 643,
								name: "Gashongora",
								sector_id: 40501,
								courtOfCells: [
									{
										id: 3507,
										name: "BUTEZI",
										generalAssembly_id: 643,
									},
									{
										id: 3508,
										name: "KABAGERA",
										generalAssembly_id: 643,
									},
									{
										id: 3509,
										name: "NYAGASENYI",
										generalAssembly_id: 643,
									},
									{
										id: 3510,
										name: "NYAKABUNGO",
										generalAssembly_id: 643,
									},
									{
										id: 3511,
										name: "RWIMONDO",
										generalAssembly_id: 643,
									},
									{
										id: 9618,
										name: "Gashongora Court of Appeal ",
										generalAssembly_id: 643,
									},
									{
										id: 11151,
										name: "Gashongora Gacaca court of sector ",
										generalAssembly_id: 643,
									},
								],
							},
						],
					},
					{
						id: 40502,
						name: "Gatore",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050206,
								name: "Rwantonde",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050205,
								name: "Rwabutazi",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050204,
								name: "Nyamiryango",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050203,
								name: "Muganza",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050202,
								name: "Cyunuzi",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050201,
								name: "Curazo",
								sector_id: 40502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 644,
								name: "Gatore",
								sector_id: 40502,
								courtOfCells: [
									{
										id: 3512,
										name: "CYIHA",
										generalAssembly_id: 644,
									},
									{
										id: 3513,
										name: "CYUNUZI",
										generalAssembly_id: 644,
									},
									{
										id: 3514,
										name: "KAMOMO",
										generalAssembly_id: 644,
									},
									{
										id: 3515,
										name: "MUGANZA",
										generalAssembly_id: 644,
									},
									{
										id: 3516,
										name: "MUMEYA",
										generalAssembly_id: 644,
									},
									{
										id: 3517,
										name: "NYAKABARE",
										generalAssembly_id: 644,
									},
									{
										id: 3518,
										name: "NYAMIRYANGO",
										generalAssembly_id: 644,
									},
									{
										id: 3519,
										name: "RUBONA",
										generalAssembly_id: 644,
									},
									{
										id: 3520,
										name: "RUGARI",
										generalAssembly_id: 644,
									},
									{
										id: 3521,
										name: "RUGINA",
										generalAssembly_id: 644,
									},
									{
										id: 3522,
										name: "RURENGE I",
										generalAssembly_id: 644,
									},
									{
										id: 3523,
										name: "RURENGE II",
										generalAssembly_id: 644,
									},
									{
										id: 3524,
										name: "RWABUTAZI",
										generalAssembly_id: 644,
									},
									{
										id: 9619,
										name: "Gatore Court of Appeal ",
										generalAssembly_id: 644,
									},
									{
										id: 11152,
										name: "Gatore Gacaca court of sector ",
										generalAssembly_id: 644,
									},
								],
							},
						],
					},
					{
						id: 40503,
						name: "Kigarama",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050305,
								name: "Nyankurazo",
								sector_id: 40503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050304,
								name: "Nyakerera",
								sector_id: 40503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050303,
								name: "Kiremera",
								sector_id: 40503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050302,
								name: "Kigarama",
								sector_id: 40503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050301,
								name: "Cyanya",
								sector_id: 40503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 645,
								name: "Kigarama",
								sector_id: 40503,
								courtOfCells: [
									{
										id: 3525,
										name: "CYANYA",
										generalAssembly_id: 645,
									},
									{
										id: 3526,
										name: "GISHENYI",
										generalAssembly_id: 645,
									},
									{
										id: 3527,
										name: "HUMURE",
										generalAssembly_id: 645,
									},
									{
										id: 3528,
										name: "KABARE",
										generalAssembly_id: 645,
									},
									{
										id: 3529,
										name: "KIGARAMA",
										generalAssembly_id: 645,
									},
									{
										id: 3530,
										name: "KIMESHO I",
										generalAssembly_id: 645,
									},
									{
										id: 3531,
										name: "KIMESHO II",
										generalAssembly_id: 645,
									},
									{
										id: 3532,
										name: "KIREMERA I",
										generalAssembly_id: 645,
									},
									{
										id: 3533,
										name: "KIREMERA II",
										generalAssembly_id: 645,
									},
									{
										id: 3534,
										name: "KIYANZI",
										generalAssembly_id: 645,
									},
									{
										id: 3535,
										name: "NYAKERERA",
										generalAssembly_id: 645,
									},
									{
										id: 3536,
										name: "NYAMIYAGA",
										generalAssembly_id: 645,
									},
									{
										id: 3537,
										name: "NYANKURAZO I",
										generalAssembly_id: 645,
									},
									{
										id: 3538,
										name: "NYANKURAZO II",
										generalAssembly_id: 645,
									},
									{
										id: 9620,
										name: "Kigarama Court of Appeal ",
										generalAssembly_id: 645,
									},
									{
										id: 11153,
										name: "Kigarama Gacaca court of sector ",
										generalAssembly_id: 645,
									},
								],
							},
						],
					},
					{
						id: 40504,
						name: "Kigina",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050404,
								name: "Rwanteru",
								sector_id: 40504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050403,
								name: "Ruhanga",
								sector_id: 40504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050402,
								name: "Rugarama",
								sector_id: 40504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050401,
								name: "Gatarama",
								sector_id: 40504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 646,
								name: "Kigina",
								sector_id: 40504,
								courtOfCells: [
									{
										id: 3539,
										name: "BUGARURA",
										generalAssembly_id: 646,
									},
									{
										id: 3540,
										name: "GASARASI",
										generalAssembly_id: 646,
									},
									{
										id: 3541,
										name: "GATARAMA I",
										generalAssembly_id: 646,
									},
									{
										id: 3542,
										name: "GATARAMA II",
										generalAssembly_id: 646,
									},
									{
										id: 3543,
										name: "KAVUZO",
										generalAssembly_id: 646,
									},
									{
										id: 3544,
										name: "MAYIZI",
										generalAssembly_id: 646,
									},
									{
										id: 3545,
										name: "MUGISENYI",
										generalAssembly_id: 646,
									},
									{
										id: 3546,
										name: "NYAKARAMBI",
										generalAssembly_id: 646,
									},
									{
										id: 3547,
										name: "NYAKIBANDE",
										generalAssembly_id: 646,
									},
									{
										id: 3548,
										name: "RUGANDO",
										generalAssembly_id: 646,
									},
									{
										id: 3549,
										name: "RUGARAMA I",
										generalAssembly_id: 646,
									},
									{
										id: 3550,
										name: "RUGARAMA II",
										generalAssembly_id: 646,
									},
									{
										id: 3551,
										name: "RUHANGA",
										generalAssembly_id: 646,
									},
									{
										id: 3552,
										name: "RWANTERU",
										generalAssembly_id: 646,
									},
									{
										id: 9621,
										name: "Kigina Court of Appeal ",
										generalAssembly_id: 646,
									},
									{
										id: 11154,
										name: "Kigina Gacaca court of sector ",
										generalAssembly_id: 646,
									},
								],
							},
						],
					},
					{
						id: 40505,
						name: "Kirehe",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050505,
								name: "Rwesero",
								sector_id: 40505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050504,
								name: "Nyabikokora",
								sector_id: 40505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050503,
								name: "Nyabigega",
								sector_id: 40505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050502,
								name: "Kirehe",
								sector_id: 40505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050501,
								name: "Gahama",
								sector_id: 40505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 647,
								name: "Kirehe",
								sector_id: 40505,
								courtOfCells: [
									{
										id: 3553,
										name: "BUGARURA",
										generalAssembly_id: 647,
									},
									{
										id: 3554,
										name: "GACUMU",
										generalAssembly_id: 647,
									},
									{
										id: 3555,
										name: "KADUHA",
										generalAssembly_id: 647,
									},
									{
										id: 3556,
										name: "KAZIBA",
										generalAssembly_id: 647,
									},
									{
										id: 3557,
										name: "KIREHE",
										generalAssembly_id: 647,
									},
									{
										id: 3558,
										name: "MUBUGA",
										generalAssembly_id: 647,
									},
									{
										id: 3559,
										name: "NYABIKOKORA",
										generalAssembly_id: 647,
									},
									{
										id: 3560,
										name: "RURENGE",
										generalAssembly_id: 647,
									},
									{
										id: 3561,
										name: "RUTABAGU",
										generalAssembly_id: 647,
									},
									{
										id: 9622,
										name: "Kirehe Court of Appeal ",
										generalAssembly_id: 647,
									},
									{
										id: 11155,
										name: "Kirehe Gacaca court of sector ",
										generalAssembly_id: 647,
									},
								],
							},
						],
					},
					{
						id: 40506,
						name: "Mahama",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050604,
								name: "Munini",
								sector_id: 40506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050603,
								name: "Saruhembe",
								sector_id: 40506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050602,
								name: "Mwoga",
								sector_id: 40506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050601,
								name: "Kamombo",
								sector_id: 40506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 648,
								name: "Gisenyi ",
								sector_id: 40506,
								courtOfCells: [
									{
										id: 3562,
										name: "GISENYI",
										generalAssembly_id: 648,
									},
									{
										id: 3563,
										name: "KAMOMBO",
										generalAssembly_id: 648,
									},
									{
										id: 3564,
										name: "KIGONGI",
										generalAssembly_id: 648,
									},
									{
										id: 3565,
										name: "KIGUFI",
										generalAssembly_id: 648,
									},
									{
										id: 3566,
										name: "NYAMIYUMBU",
										generalAssembly_id: 648,
									},
									{
										id: 3567,
										name: "RUSHONGA I",
										generalAssembly_id: 648,
									},
									{
										id: 3568,
										name: "SARUHEMBE",
										generalAssembly_id: 648,
									},
									{
										id: 9623,
										name: "Gisenyi  Court of Appeal ",
										generalAssembly_id: 648,
									},
									{
										id: 11156,
										name: "Gisenyi  Gacaca court of sector ",
										generalAssembly_id: 648,
									},
								],
							},
							{
								id: 649,
								name: "Mahama",
								sector_id: 40506,
								courtOfCells: [
									{
										id: 3569,
										name: "KWISHA",
										generalAssembly_id: 649,
									},
									{
										id: 3570,
										name: "MUNINI",
										generalAssembly_id: 649,
									},
									{
										id: 3571,
										name: "MWOGA",
										generalAssembly_id: 649,
									},
									{
										id: 3572,
										name: "REMANYUNDO",
										generalAssembly_id: 649,
									},
									{
										id: 9624,
										name: "Mahama Court of Appeal ",
										generalAssembly_id: 649,
									},
									{
										id: 11157,
										name: "Mahama Gacaca court of sector ",
										generalAssembly_id: 649,
									},
								],
							},
						],
					},
					{
						id: 40507,
						name: "Mpanga",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050707,
								name: "Rubaya",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050706,
								name: "Nyakabungo",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050705,
								name: "Nasho",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050704,
								name: "Mushongi",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050703,
								name: "Mpanga",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050702,
								name: "Kankobwa",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050701,
								name: "Bwiyorere",
								sector_id: 40507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 650,
								name: "Kankobwa",
								sector_id: 40507,
								courtOfCells: [
									{
										id: 3573,
										name: "KAHI",
										generalAssembly_id: 650,
									},
									{
										id: 3574,
										name: "MURUNDI",
										generalAssembly_id: 650,
									},
									{
										id: 3575,
										name: "NYAKABANDE",
										generalAssembly_id: 650,
									},
									{
										id: 3576,
										name: "RUGARAMA III",
										generalAssembly_id: 650,
									},
									{
										id: 3577,
										name: "RUHAMA",
										generalAssembly_id: 650,
									},
									{
										id: 3578,
										name: "RUTUMBWE",
										generalAssembly_id: 650,
									},
									{
										id: 9625,
										name: "Kankobwa Court of Appeal ",
										generalAssembly_id: 650,
									},
									{
										id: 11158,
										name: "Kankobwa Gacaca court of sector ",
										generalAssembly_id: 650,
									},
								],
							},
							{
								id: 651,
								name: "Mpanga",
								sector_id: 40507,
								courtOfCells: [
									{
										id: 3579,
										name: "KABUYE",
										generalAssembly_id: 651,
									},
									{
										id: 3580,
										name: "MISHENYI",
										generalAssembly_id: 651,
									},
									{
										id: 3581,
										name: "MPANGA",
										generalAssembly_id: 651,
									},
									{
										id: 3582,
										name: "MUSHONGI",
										generalAssembly_id: 651,
									},
									{
										id: 3583,
										name: "NGUGU",
										generalAssembly_id: 651,
									},
									{
										id: 3584,
										name: "NYAGATOVU",
										generalAssembly_id: 651,
									},
									{
										id: 3585,
										name: "RURAMBI",
										generalAssembly_id: 651,
									},
									{
										id: 9626,
										name: "Mpanga Court of Appeal ",
										generalAssembly_id: 651,
									},
									{
										id: 11159,
										name: "Mpanga Gacaca court of sector ",
										generalAssembly_id: 651,
									},
								],
							},
							{
								id: 652,
								name: "Nyabubare",
								sector_id: 40507,
								courtOfCells: [
									{
										id: 3586,
										name: "BUSASAMANA",
										generalAssembly_id: 652,
									},
									{
										id: 3587,
										name: "IBANDA",
										generalAssembly_id: 652,
									},
									{
										id: 3588,
										name: "MURAMBI",
										generalAssembly_id: 652,
									},
									{
										id: 3589,
										name: "NYABUBARE",
										generalAssembly_id: 652,
									},
									{
										id: 3590,
										name: "NYAWERA",
										generalAssembly_id: 652,
									},
									{
										id: 3591,
										name: "RUBAYA",
										generalAssembly_id: 652,
									},
									{
										id: 3592,
										name: "RUSHONGA II",
										generalAssembly_id: 652,
									},
									{
										id: 9627,
										name: "Nyabubare Court of Appeal ",
										generalAssembly_id: 652,
									},
									{
										id: 11160,
										name: "Nyabubare Gacaca court of sector ",
										generalAssembly_id: 652,
									},
								],
							},
						],
					},
					{
						id: 40508,
						name: "Musaza",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050805,
								name: "Nganda ",
								sector_id: 40508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050804,
								name: "Musaza",
								sector_id: 40508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050803,
								name: "Mubuga",
								sector_id: 40508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050802,
								name: "Kabuga",
								sector_id: 40508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050801,
								name: "Gasarabwayi",
								sector_id: 40508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 653,
								name: "Musaza",
								sector_id: 40508,
								courtOfCells: [
									{
										id: 3593,
										name: "GACUBA",
										generalAssembly_id: 653,
									},
									{
										id: 3594,
										name: "GASARABWAYI",
										generalAssembly_id: 653,
									},
									{
										id: 3595,
										name: "GATWE",
										generalAssembly_id: 653,
									},
									{
										id: 3596,
										name: "GIKENKE",
										generalAssembly_id: 653,
									},
									{
										id: 3597,
										name: "KABUGIRI",
										generalAssembly_id: 653,
									},
									{
										id: 3598,
										name: "KAGERA",
										generalAssembly_id: 653,
									},
									{
										id: 3599,
										name: "KAYANZA",
										generalAssembly_id: 653,
									},
									{
										id: 3600,
										name: "MURAMBI",
										generalAssembly_id: 653,
									},
									{
										id: 3601,
										name: "MUSAZA",
										generalAssembly_id: 653,
									},
									{
										id: 3602,
										name: "NUYAKA",
										generalAssembly_id: 653,
									},
									{
										id: 3603,
										name: "NGANDA",
										generalAssembly_id: 653,
									},
									{
										id: 3604,
										name: "NKWANDI",
										generalAssembly_id: 653,
									},
									{
										id: 3605,
										name: "NYAGAHAMA",
										generalAssembly_id: 653,
									},
									{
										id: 3606,
										name: "RUGARAMA",
										generalAssembly_id: 653,
									},
									{
										id: 3607,
										name: "RUKUMBA",
										generalAssembly_id: 653,
									},
									{
										id: 3608,
										name: "RUSEKE",
										generalAssembly_id: 653,
									},
									{
										id: 9628,
										name: "Musaza Court of Appeal ",
										generalAssembly_id: 653,
									},
									{
										id: 11161,
										name: "Musaza Gacaca court of sector ",
										generalAssembly_id: 653,
									},
								],
							},
						],
					},
					{
						id: 40509,
						name: "Mushikiri",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4050905,
								name: "Rwayikona",
								sector_id: 40509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050904,
								name: "Rwanyamuhanga",
								sector_id: 40509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050903,
								name: "Rugarama",
								sector_id: 40509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050902,
								name: "Cyamigurwa",
								sector_id: 40509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4050901,
								name: "Bisagara",
								sector_id: 40509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 654,
								name: "Mubago",
								sector_id: 40509,
								courtOfCells: [
									{
										id: 3609,
										name: "CYERU",
										generalAssembly_id: 654,
									},
									{
										id: 3610,
										name: "KARENGE",
										generalAssembly_id: 654,
									},
									{
										id: 3611,
										name: "NTERERE",
										generalAssembly_id: 654,
									},
									{
										id: 3612,
										name: "NTUNGAMO",
										generalAssembly_id: 654,
									},
									{
										id: 3613,
										name: "NYAGATEME",
										generalAssembly_id: 654,
									},
									{
										id: 3614,
										name: "RUBIMBA",
										generalAssembly_id: 654,
									},
									{
										id: 3615,
										name: "RUSENYI",
										generalAssembly_id: 654,
									},
									{
										id: 3616,
										name: "RUTOMA",
										generalAssembly_id: 654,
									},
									{
										id: 9629,
										name: "Mubago Court of Appeal ",
										generalAssembly_id: 654,
									},
									{
										id: 11162,
										name: "Mubago Gacaca court of sector ",
										generalAssembly_id: 654,
									},
								],
							},
							{
								id: 655,
								name: "Mushikiri",
								sector_id: 40509,
								courtOfCells: [
									{
										id: 3617,
										name: "BISAGARA",
										generalAssembly_id: 655,
									},
									{
										id: 3618,
										name: "BUTEZI",
										generalAssembly_id: 655,
									},
									{
										id: 3619,
										name: "GATONGO",
										generalAssembly_id: 655,
									},
									{
										id: 3620,
										name: "RWAMUHIGI",
										generalAssembly_id: 655,
									},
									{
										id: 3621,
										name: "RWAYIKONA",
										generalAssembly_id: 655,
									},
									{
										id: 9630,
										name: "Mushikiri Court of Appeal ",
										generalAssembly_id: 655,
									},
									{
										id: 11163,
										name: "Mushikiri Gacaca court of sector ",
										generalAssembly_id: 655,
									},
								],
							},
							{
								id: 656,
								name: "Rugarama",
								sector_id: 40509,
								courtOfCells: [
									{
										id: 3622,
										name: "KANYAMI",
										generalAssembly_id: 656,
									},
									{
										id: 3623,
										name: "KARAMBI",
										generalAssembly_id: 656,
									},
									{
										id: 3624,
										name: "MUREHE",
										generalAssembly_id: 656,
									},
									{
										id: 3625,
										name: "TOMI",
										generalAssembly_id: 656,
									},
									{
										id: 9631,
										name: "Rugarama Court of Appeal ",
										generalAssembly_id: 656,
									},
									{
										id: 11164,
										name: "Rugarama Gacaca court of sector ",
										generalAssembly_id: 656,
									},
								],
							},
						],
					},
					{
						id: 40510,
						name: "Nasho",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4051005,
								name: "Rugoma",
								sector_id: 40510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051004,
								name: "Rubirizi",
								sector_id: 40510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051003,
								name: "Ntaruka",
								sector_id: 40510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051002,
								name: "Kagese",
								sector_id: 40510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051001,
								name: "Cyambwe",
								sector_id: 40510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 657,
								name: "Kagese",
								sector_id: 40510,
								courtOfCells: [
									{
										id: 3626,
										name: "GATUNGURU",
										generalAssembly_id: 657,
									},
									{
										id: 3627,
										name: "KAGESE",
										generalAssembly_id: 657,
									},
									{
										id: 3628,
										name: "MITSINDO",
										generalAssembly_id: 657,
									},
									{
										id: 3629,
										name: "MUREHE",
										generalAssembly_id: 657,
									},
									{
										id: 3630,
										name: "NYABIMURI",
										generalAssembly_id: 657,
									},
									{
										id: 9632,
										name: "Kagese Court of Appeal ",
										generalAssembly_id: 657,
									},
									{
										id: 11165,
										name: "Kagese Gacaca court of sector ",
										generalAssembly_id: 657,
									},
								],
							},
							{
								id: 658,
								name: "Gashiru",
								sector_id: 40510,
								courtOfCells: [
									{
										id: 3631,
										name: "BUGARAMA",
										generalAssembly_id: 658,
									},
									{
										id: 3632,
										name: "RUBIRIZI",
										generalAssembly_id: 658,
									},
									{
										id: 3633,
										name: "RUGOMA",
										generalAssembly_id: 658,
									},
									{
										id: 9633,
										name: "Gashiru Court of Appeal ",
										generalAssembly_id: 658,
									},
									{
										id: 11166,
										name: "Gashiru Gacaca court of sector ",
										generalAssembly_id: 658,
									},
								],
							},
							{
								id: 659,
								name: "Ntaruka ",
								sector_id: 40510,
								courtOfCells: [
									{
										id: 3634,
										name: "CYAMBWE",
										generalAssembly_id: 659,
									},
									{
										id: 3635,
										name: "KIRUHURA",
										generalAssembly_id: 659,
									},
									{
										id: 3636,
										name: "NYAMIKONI",
										generalAssembly_id: 659,
									},
									{
										id: 3637,
										name: "RUSEKE",
										generalAssembly_id: 659,
									},
									{
										id: 9634,
										name: "Ntaruka  Court of Appeal ",
										generalAssembly_id: 659,
									},
									{
										id: 11167,
										name: "Ntaruka  Gacaca court of sector ",
										generalAssembly_id: 659,
									},
								],
							},
						],
					},
					{
						id: 40511,
						name: "Nyamugari",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4051105,
								name: "Nyamugari",
								sector_id: 40511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051104,
								name: "Kiyanzi",
								sector_id: 40511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051103,
								name: "Kazizi",
								sector_id: 40511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051102,
								name: "Kagasa",
								sector_id: 40511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051101,
								name: "Bukora",
								sector_id: 40511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 660,
								name: "Nyamugali ",
								sector_id: 40511,
								courtOfCells: [
									{
										id: 3638,
										name: "BUKORA I",
										generalAssembly_id: 660,
									},
									{
										id: 3639,
										name: "BUKORA II",
										generalAssembly_id: 660,
									},
									{
										id: 3640,
										name: "GASHANGA",
										generalAssembly_id: 660,
									},
									{
										id: 3641,
										name: "KAGASA",
										generalAssembly_id: 660,
									},
									{
										id: 3642,
										name: "KAMEYA",
										generalAssembly_id: 660,
									},
									{
										id: 3643,
										name: "KAMUGARURA",
										generalAssembly_id: 660,
									},
									{
										id: 3644,
										name: "KANSHONGWE",
										generalAssembly_id: 660,
									},
									{
										id: 3645,
										name: "KAZIZI",
										generalAssembly_id: 660,
									},
									{
										id: 3646,
										name: "KIGONGI",
										generalAssembly_id: 660,
									},
									{
										id: 3647,
										name: "KIYANZI",
										generalAssembly_id: 660,
									},
									{
										id: 3648,
										name: "MITAKO",
										generalAssembly_id: 660,
									},
									{
										id: 3649,
										name: "NYAMUGALI",
										generalAssembly_id: 660,
									},
									{
										id: 3650,
										name: "NYARWAMURA",
										generalAssembly_id: 660,
									},
									{
										id: 3651,
										name: "RAMA I",
										generalAssembly_id: 660,
									},
									{
										id: 3652,
										name: "RAMA II",
										generalAssembly_id: 660,
									},
									{
										id: 3653,
										name: "RUSOZI",
										generalAssembly_id: 660,
									},
									{
										id: 9635,
										name: "Nyamugali  Court of Appeal ",
										generalAssembly_id: 660,
									},
									{
										id: 11168,
										name: "Nyamugali  Gacaca court of sector ",
										generalAssembly_id: 660,
									},
								],
							},
						],
					},
					{
						id: 40512,
						name: "Nyarubuye",
						district_id: 405,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4051203,
								name: "Nyarutunga",
								sector_id: 40512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051202,
								name: "Nyabitare",
								sector_id: 40512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4051201,
								name: "Mareba",
								sector_id: 40512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 661,
								name: "Nyabitare",
								sector_id: 40512,
								courtOfCells: [
									{
										id: 3654,
										name: "KAZIZI",
										generalAssembly_id: 661,
									},
									{
										id: 3655,
										name: "MPANGUHE",
										generalAssembly_id: 661,
									},
									{
										id: 3656,
										name: "NYABAYAMA",
										generalAssembly_id: 661,
									},
									{
										id: 3657,
										name: "NYABITARE",
										generalAssembly_id: 661,
									},
									{
										id: 3658,
										name: "NYAMATEKE",
										generalAssembly_id: 661,
									},
									{
										id: 3659,
										name: "NYAMISAGARA",
										generalAssembly_id: 661,
									},
									{
										id: 3660,
										name: "RUGARAMA",
										generalAssembly_id: 661,
									},
									{
										id: 9636,
										name: "Nyabitare Court of Appeal ",
										generalAssembly_id: 661,
									},
									{
										id: 11169,
										name: "Nyabitare Gacaca court of sector ",
										generalAssembly_id: 661,
									},
								],
							},
							{
								id: 662,
								name: "Nyarubuye",
								sector_id: 40512,
								courtOfCells: [
									{
										id: 3661,
										name: "RUGENGE",
										generalAssembly_id: 662,
									},
									{
										id: 3662,
										name: "MAREBA",
										generalAssembly_id: 662,
									},
									{
										id: 3663,
										name: "NYARUTUNGA",
										generalAssembly_id: 662,
									},
									{
										id: 3664,
										name: "RUBARE",
										generalAssembly_id: 662,
									},
									{
										id: 3665,
										name: "KAGABIRO",
										generalAssembly_id: 662,
									},
									{
										id: 3666,
										name: "RUGARAMA",
										generalAssembly_id: 662,
									},
									{
										id: 3667,
										name: "NYARUBUYE",
										generalAssembly_id: 662,
									},
									{
										id: 3668,
										name: "NKAKWA",
										generalAssembly_id: 662,
									},
									{
										id: 9637,
										name: "Nyarubuye Court of Appeal ",
										generalAssembly_id: 662,
									},
									{
										id: 11170,
										name: "Nyarubuye Gacaca court of sector ",
										generalAssembly_id: 662,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 406,
				name: "Ngoma",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40601,
						name: "Gashanda",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060104,
								name: "Mutsindo",
								sector_id: 40601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060103,
								name: "Munege",
								sector_id: 40601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060102,
								name: "Giseri",
								sector_id: 40601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060101,
								name: "Cyerwa",
								sector_id: 40601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 764,
								name: "Gashanda",
								sector_id: 40601,
								courtOfCells: [
									{
										id: 4314,
										name: "CYERWA",
										generalAssembly_id: 764,
									},
									{
										id: 4315,
										name: "NYAKABANDE",
										generalAssembly_id: 764,
									},
									{
										id: 4316,
										name: "RUBAMBANTARE",
										generalAssembly_id: 764,
									},
									{
										id: 4317,
										name: "RWAMBOHERO",
										generalAssembly_id: 764,
									},
									{
										id: 4318,
										name: "RWANYAMIGONO",
										generalAssembly_id: 764,
									},
									{
										id: 9739,
										name: "Gashanda Court of Appeal ",
										generalAssembly_id: 764,
									},
									{
										id: 11272,
										name: "Gashanda Gacaca court of sector ",
										generalAssembly_id: 764,
									},
								],
							},
							{
								id: 765,
								name: "Ruyema",
								sector_id: 40601,
								courtOfCells: [
									{
										id: 4319,
										name: "BITARE",
										generalAssembly_id: 765,
									},
									{
										id: 4320,
										name: "GAKO",
										generalAssembly_id: 765,
									},
									{
										id: 4321,
										name: "MUNEGE",
										generalAssembly_id: 765,
									},
									{
										id: 4322,
										name: "MIZIBIRI",
										generalAssembly_id: 765,
									},
									{
										id: 4323,
										name: "RUBUMBA",
										generalAssembly_id: 765,
									},
									{
										id: 4324,
										name: "RUYEMA-I",
										generalAssembly_id: 765,
									},
									{
										id: 4325,
										name: "RUYEMA-II",
										generalAssembly_id: 765,
									},
									{
										id: 9740,
										name: "Ruyema Court of Appeal ",
										generalAssembly_id: 765,
									},
									{
										id: 11273,
										name: "Ruyema Gacaca court of sector ",
										generalAssembly_id: 765,
									},
								],
							},
							{
								id: 766,
								name: "Kansana",
								sector_id: 40601,
								courtOfCells: [
									{
										id: 4326,
										name: "CYANAMA",
										generalAssembly_id: 766,
									},
									{
										id: 4327,
										name: "GISELI",
										generalAssembly_id: 766,
									},
									{
										id: 4328,
										name: "GISENYI",
										generalAssembly_id: 766,
									},
									{
										id: 4329,
										name: "KANYINYA",
										generalAssembly_id: 766,
									},
									{
										id: 4330,
										name: "KIRUNDO",
										generalAssembly_id: 766,
									},
									{
										id: 4331,
										name: "MUTSINDO",
										generalAssembly_id: 766,
									},
									{
										id: 9741,
										name: "Kansana Court of Appeal ",
										generalAssembly_id: 766,
									},
									{
										id: 11274,
										name: "Kansana Gacaca court of sector ",
										generalAssembly_id: 766,
									},
								],
							},
						],
					},
					{
						id: 40602,
						name: "Jarama",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060205,
								name: "Kigoma",
								sector_id: 40602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060204,
								name: "Kibimba",
								sector_id: 40602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060203,
								name: "Karenge",
								sector_id: 40602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060202,
								name: "Jarama",
								sector_id: 40602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060201,
								name: "Ihanika",
								sector_id: 40602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 767,
								name: "Mbuye ",
								sector_id: 40602,
								courtOfCells: [
									{
										id: 4332,
										name: "KABANDE",
										generalAssembly_id: 767,
									},
									{
										id: 4333,
										name: "KIBIMBA",
										generalAssembly_id: 767,
									},
									{
										id: 4334,
										name: "KIGOMA",
										generalAssembly_id: 767,
									},
									{
										id: 4335,
										name: "MUBAHA",
										generalAssembly_id: 767,
									},
									{
										id: 4336,
										name: "MURAMA",
										generalAssembly_id: 767,
									},
									{
										id: 9742,
										name: "Mbuye  Court of Appeal ",
										generalAssembly_id: 767,
									},
									{
										id: 11275,
										name: "Mbuye  Gacaca court of sector ",
										generalAssembly_id: 767,
									},
								],
							},
							{
								id: 768,
								name: "Murwa",
								sector_id: 40602,
								courtOfCells: [
									{
										id: 4337,
										name: "GISOKO",
										generalAssembly_id: 768,
									},
									{
										id: 4338,
										name: "IHANIKA",
										generalAssembly_id: 768,
									},
									{
										id: 4339,
										name: "IRARIRE",
										generalAssembly_id: 768,
									},
									{
										id: 4340,
										name: "JARAMA",
										generalAssembly_id: 768,
									},
									{
										id: 4341,
										name: "KARENGE",
										generalAssembly_id: 768,
									},
									{
										id: 4342,
										name: "KIBOHA",
										generalAssembly_id: 768,
									},
									{
										id: 4343,
										name: "KIRYAMA",
										generalAssembly_id: 768,
									},
									{
										id: 4344,
										name: "NYAMUGALI",
										generalAssembly_id: 768,
									},
									{
										id: 9743,
										name: "Murwa Court of Appeal ",
										generalAssembly_id: 768,
									},
									{
										id: 11276,
										name: "Murwa Gacaca court of sector ",
										generalAssembly_id: 768,
									},
								],
							},
						],
					},
					{
						id: 40603,
						name: "Karembo",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060303,
								name: "Nyamirambo",
								sector_id: 40603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060302,
								name: "Karaba",
								sector_id: 40603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060301,
								name: "Akaziba",
								sector_id: 40603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 769,
								name: "Karembo",
								sector_id: 40603,
								courtOfCells: [
									{
										id: 4345,
										name: "AKAZIBA",
										generalAssembly_id: 769,
									},
									{
										id: 4346,
										name: "KARABA",
										generalAssembly_id: 769,
									},
									{
										id: 4347,
										name: "NYAGASOZI",
										generalAssembly_id: 769,
									},
									{
										id: 4348,
										name: "NYIMBYI",
										generalAssembly_id: 769,
									},
									{
										id: 4349,
										name: "RUBUMBA",
										generalAssembly_id: 769,
									},
									{
										id: 4350,
										name: "RUKIZI",
										generalAssembly_id: 769,
									},
									{
										id: 4351,
										name: "RWAMISHIBA",
										generalAssembly_id: 769,
									},
									{
										id: 4352,
										name: "RWAMUHIMBURA",
										generalAssembly_id: 769,
									},
									{
										id: 9744,
										name: "Karembo Court of Appeal ",
										generalAssembly_id: 769,
									},
									{
										id: 11277,
										name: "Karembo Gacaca court of sector ",
										generalAssembly_id: 769,
									},
								],
							},
							{
								id: 770,
								name: "Kabilizi",
								sector_id: 40603,
								courtOfCells: [
									{
										id: 4353,
										name: "GASHEKASHEKE-I",
										generalAssembly_id: 770,
									},
									{
										id: 4354,
										name: "GASHEKASHEKE-II",
										generalAssembly_id: 770,
									},
									{
										id: 4355,
										name: "GITARAGA-I",
										generalAssembly_id: 770,
									},
									{
										id: 4356,
										name: "GITARAGA-II",
										generalAssembly_id: 770,
									},
									{
										id: 4357,
										name: "KABONOBONO",
										generalAssembly_id: 770,
									},
									{
										id: 4358,
										name: "KARIBU",
										generalAssembly_id: 770,
									},
									{
										id: 4359,
										name: "KIVUGANGOMA-I",
										generalAssembly_id: 770,
									},
									{
										id: 4360,
										name: "KIVUGANGOMA-II",
										generalAssembly_id: 770,
									},
									{
										id: 4361,
										name: "MURAMBI",
										generalAssembly_id: 770,
									},
									{
										id: 4362,
										name: "RWAKAYANGO",
										generalAssembly_id: 770,
									},
									{
										id: 9745,
										name: "Kabilizi Court of Appeal ",
										generalAssembly_id: 770,
									},
									{
										id: 11278,
										name: "Kabilizi Gacaca court of sector ",
										generalAssembly_id: 770,
									},
								],
							},
						],
					},
					{
						id: 40604,
						name: "Kazo",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060405,
								name: "Umukamba",
								sector_id: 40604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060404,
								name: "Kinyonzo",
								sector_id: 40604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060403,
								name: "Karama",
								sector_id: 40604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060402,
								name: "Gahurire",
								sector_id: 40604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060401,
								name: "Birenga",
								sector_id: 40604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 771,
								name: "Gahurire",
								sector_id: 40604,
								courtOfCells: [
									{
										id: 4363,
										name: "MPANDO",
										generalAssembly_id: 771,
									},
									{
										id: 4364,
										name: "RANGO",
										generalAssembly_id: 771,
									},
									{
										id: 4365,
										name: "RUGENGE",
										generalAssembly_id: 771,
									},
									{
										id: 4366,
										name: "TAMBIRO",
										generalAssembly_id: 771,
									},
									{
										id: 9746,
										name: "Gahurire Court of Appeal ",
										generalAssembly_id: 771,
									},
									{
										id: 11279,
										name: "Gahurire Gacaca court of sector ",
										generalAssembly_id: 771,
									},
								],
							},
							{
								id: 772,
								name: "Birenga",
								sector_id: 40604,
								courtOfCells: [
									{
										id: 4367,
										name: "BARASA",
										generalAssembly_id: 772,
									},
									{
										id: 4368,
										name: "KARENGE",
										generalAssembly_id: 772,
									},
									{
										id: 4369,
										name: "KAZO",
										generalAssembly_id: 772,
									},
									{
										id: 4370,
										name: "RUSEBEYI",
										generalAssembly_id: 772,
									},
									{
										id: 4371,
										name: "RWANKOGOTO",
										generalAssembly_id: 772,
									},
									{
										id: 9747,
										name: "Birenga Court of Appeal ",
										generalAssembly_id: 772,
									},
									{
										id: 11280,
										name: "Birenga Gacaca court of sector ",
										generalAssembly_id: 772,
									},
								],
							},
							{
								id: 773,
								name: "Fukwe",
								sector_id: 40604,
								courtOfCells: [
									{
										id: 4372,
										name: "AKABAYA",
										generalAssembly_id: 773,
									},
									{
										id: 4373,
										name: "KABIMBA",
										generalAssembly_id: 773,
									},
									{
										id: 4374,
										name: "KAGUSA",
										generalAssembly_id: 773,
									},
									{
										id: 4375,
										name: "KARENGE",
										generalAssembly_id: 773,
									},
									{
										id: 4376,
										name: "RUGAZI",
										generalAssembly_id: 773,
									},
									{
										id: 4377,
										name: "UMUKAMBA",
										generalAssembly_id: 773,
									},
									{
										id: 9748,
										name: "Fukwe Court of Appeal ",
										generalAssembly_id: 773,
									},
									{
										id: 11281,
										name: "Fukwe Gacaca court of sector ",
										generalAssembly_id: 773,
									},
								],
							},
							{
								id: 774,
								name: "Kibimba",
								sector_id: 40604,
								courtOfCells: [
									{
										id: 4378,
										name: "KINYONZO",
										generalAssembly_id: 774,
									},
									{
										id: 4379,
										name: "RUGARAMA",
										generalAssembly_id: 774,
									},
									{
										id: 4380,
										name: "TUNDUTI",
										generalAssembly_id: 774,
									},
									{
										id: 9749,
										name: "Kibimba Court of Appeal ",
										generalAssembly_id: 774,
									},
									{
										id: 11282,
										name: "Kibimba Gacaca court of sector ",
										generalAssembly_id: 774,
									},
								],
							},
						],
					},
					{
						id: 40605,
						name: "Kibungo",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060505,
								name: "Mahango",
								sector_id: 40605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060504,
								name: "Karenge",
								sector_id: 40605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060503,
								name: "Gatonde",
								sector_id: 40605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060502,
								name: "Gahima",
								sector_id: 40605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060501,
								name: "Cyasemakamba",
								sector_id: 40605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 775,
								name: "Kibungo",
								sector_id: 40605,
								courtOfCells: [
									{
										id: 4381,
										name: "CYASEMAKAMBA",
										generalAssembly_id: 775,
									},
									{
										id: 4382,
										name: "KARENGE",
										generalAssembly_id: 775,
									},
									{
										id: 4383,
										name: "MUSAMVU",
										generalAssembly_id: 775,
									},
									{
										id: 4384,
										name: "NGOMA",
										generalAssembly_id: 775,
									},
									{
										id: 9750,
										name: "Kibungo Court of Appeal ",
										generalAssembly_id: 775,
									},
									{
										id: 11283,
										name: "Kibungo Gacaca court of sector ",
										generalAssembly_id: 775,
									},
								],
							},
							{
								id: 776,
								name: "Ndamira",
								sector_id: 40605,
								courtOfCells: [
									{
										id: 4385,
										name: "GATORO",
										generalAssembly_id: 776,
									},
									{
										id: 4386,
										name: "KABIMBA",
										generalAssembly_id: 776,
									},
									{
										id: 4387,
										name: "KIZIGURO",
										generalAssembly_id: 776,
									},
									{
										id: 4388,
										name: "MAHANGO",
										generalAssembly_id: 776,
									},
									{
										id: 4389,
										name: "RUHINGA",
										generalAssembly_id: 776,
									},
									{
										id: 9751,
										name: "Ndamira Court of Appeal ",
										generalAssembly_id: 776,
									},
									{
										id: 11284,
										name: "Ndamira Gacaca court of sector ",
										generalAssembly_id: 776,
									},
								],
							},
							{
								id: 777,
								name: "Rubona",
								sector_id: 40605,
								courtOfCells: [
									{
										id: 4390,
										name: "GASORO",
										generalAssembly_id: 777,
									},
									{
										id: 4391,
										name: "GATONDE",
										generalAssembly_id: 777,
									},
									{
										id: 4392,
										name: "GITEME",
										generalAssembly_id: 777,
									},
									{
										id: 4393,
										name: "MURIZA",
										generalAssembly_id: 777,
									},
									{
										id: 4394,
										name: "NYAGATOVU",
										generalAssembly_id: 777,
									},
									{
										id: 4395,
										name: "NYAMIGINA",
										generalAssembly_id: 777,
									},
									{
										id: 9752,
										name: "Rubona Court of Appeal ",
										generalAssembly_id: 777,
									},
									{
										id: 11285,
										name: "Rubona Gacaca court of sector ",
										generalAssembly_id: 777,
									},
								],
							},
						],
					},
					{
						id: 40606,
						name: "Mugesera",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060605,
								name: "Nyange",
								sector_id: 40606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060604,
								name: "Nyamugari",
								sector_id: 40606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060603,
								name: "Ntanga",
								sector_id: 40606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060602,
								name: "Mugatare",
								sector_id: 40606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060601,
								name: "Akabungo",
								sector_id: 40606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 778,
								name: " Kagashi",
								sector_id: 40606,
								courtOfCells: [
									{
										id: 4396,
										name: "BUTAKA",
										generalAssembly_id: 778,
									},
									{
										id: 4397,
										name: "CYINYO",
										generalAssembly_id: 778,
									},
									{
										id: 4398,
										name: "KAZANYI",
										generalAssembly_id: 778,
									},
									{
										id: 4399,
										name: "RUBIRIZI",
										generalAssembly_id: 778,
									},
									{
										id: 4400,
										name: "RUGARAMA",
										generalAssembly_id: 778,
									},
									{
										id: 4401,
										name: "RWINKUBA",
										generalAssembly_id: 778,
									},
									{
										id: 9753,
										name: " Kagashi Court of Appeal ",
										generalAssembly_id: 778,
									},
									{
										id: 11286,
										name: " Kagashi Gacaca court of sector ",
										generalAssembly_id: 778,
									},
								],
							},
							{
								id: 779,
								name: "Gatare",
								sector_id: 40606,
								courtOfCells: [
									{
										id: 4402,
										name: "AKABUNGO",
										generalAssembly_id: 779,
									},
									{
										id: 4403,
										name: "NYAKIBANDE",
										generalAssembly_id: 779,
									},
									{
										id: 4404,
										name: "NYANDAMA",
										generalAssembly_id: 779,
									},
									{
										id: 4405,
										name: "RUDASUMA",
										generalAssembly_id: 779,
									},
									{
										id: 4406,
										name: "RUSOZI",
										generalAssembly_id: 779,
									},
									{
										id: 9754,
										name: "Gatare Court of Appeal ",
										generalAssembly_id: 779,
									},
									{
										id: 11287,
										name: "Gatare Gacaca court of sector ",
										generalAssembly_id: 779,
									},
								],
							},
							{
								id: 780,
								name: "Kibare",
								sector_id: 40606,
								courtOfCells: [
									{
										id: 4407,
										name: "AGACEKERI",
										generalAssembly_id: 780,
									},
									{
										id: 4408,
										name: "GISENYI",
										generalAssembly_id: 780,
									},
									{
										id: 4409,
										name: "GISHANDARO",
										generalAssembly_id: 780,
									},
									{
										id: 4410,
										name: "KIMANAMA",
										generalAssembly_id: 780,
									},
									{
										id: 4411,
										name: "MUGATARE",
										generalAssembly_id: 780,
									},
									{
										id: 4412,
										name: "NYAGASAVE",
										generalAssembly_id: 780,
									},
									{
										id: 4413,
										name: "NYAMABUYE",
										generalAssembly_id: 780,
									},
									{
										id: 4414,
										name: "NYAMINAGA",
										generalAssembly_id: 780,
									},
									{
										id: 4415,
										name: "NYAMUGALI",
										generalAssembly_id: 780,
									},
									{
										id: 4416,
										name: "RUHIRA",
										generalAssembly_id: 780,
									},
									{
										id: 9755,
										name: "Kibare Court of Appeal ",
										generalAssembly_id: 780,
									},
									{
										id: 11288,
										name: "Kibare Gacaca court of sector ",
										generalAssembly_id: 780,
									},
								],
							},
							{
								id: 781,
								name: "Nyange",
								sector_id: 40606,
								courtOfCells: [
									{
										id: 4417,
										name: "IGOMEZO",
										generalAssembly_id: 781,
									},
									{
										id: 4418,
										name: "INUNGA",
										generalAssembly_id: 781,
									},
									{
										id: 4419,
										name: "NTARAMA",
										generalAssembly_id: 781,
									},
									{
										id: 4420,
										name: "RUGAZI",
										generalAssembly_id: 781,
									},
									{
										id: 4421,
										name: "RWAMUSWA",
										generalAssembly_id: 781,
									},
									{
										id: 9756,
										name: "Nyange Court of Appeal ",
										generalAssembly_id: 781,
									},
									{
										id: 11289,
										name: "Nyange Gacaca court of sector ",
										generalAssembly_id: 781,
									},
								],
							},
						],
					},
					{
						id: 40607,
						name: "Murama",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060705,
								name: "Sakara",
								sector_id: 40607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060704,
								name: "Rurenge",
								sector_id: 40607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060703,
								name: "Mvumba",
								sector_id: 40607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060702,
								name: "Kigabiro",
								sector_id: 40607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060701,
								name: "Gitaraga",
								sector_id: 40607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 782,
								name: "Sakara",
								sector_id: 40607,
								courtOfCells: [
									{
										id: 4422,
										name: "GAHAMA",
										generalAssembly_id: 782,
									},
									{
										id: 4423,
										name: "KABAHUSHYI",
										generalAssembly_id: 782,
									},
									{
										id: 4424,
										name: "KIYAGARA",
										generalAssembly_id: 782,
									},
									{
										id: 4425,
										name: "KUKARAMBI",
										generalAssembly_id: 782,
									},
									{
										id: 4426,
										name: "KUKARENGE",
										generalAssembly_id: 782,
									},
									{
										id: 4427,
										name: "MVUMBA",
										generalAssembly_id: 782,
									},
									{
										id: 4428,
										name: "NYAGATABA",
										generalAssembly_id: 782,
									},
									{
										id: 4429,
										name: "NYARWANYA",
										generalAssembly_id: 782,
									},
									{
										id: 9757,
										name: "Sakara Court of Appeal ",
										generalAssembly_id: 782,
									},
									{
										id: 11290,
										name: "Sakara Gacaca court of sector ",
										generalAssembly_id: 782,
									},
								],
							},
							{
								id: 783,
								name: "Murama",
								sector_id: 40607,
								courtOfCells: [
									{
										id: 4430,
										name: "KIGABIRO",
										generalAssembly_id: 783,
									},
									{
										id: 4431,
										name: "MUTARA",
										generalAssembly_id: 783,
									},
									{
										id: 4432,
										name: "NYAGASOZI",
										generalAssembly_id: 783,
									},
									{
										id: 4433,
										name: "NYAKABANGA",
										generalAssembly_id: 783,
									},
									{
										id: 4434,
										name: "RUKIZI",
										generalAssembly_id: 783,
									},
									{
										id: 4435,
										name: "TONERO",
										generalAssembly_id: 783,
									},
									{
										id: 9758,
										name: "Murama Court of Appeal ",
										generalAssembly_id: 783,
									},
									{
										id: 11291,
										name: "Murama Gacaca court of sector ",
										generalAssembly_id: 783,
									},
								],
							},
							{
								id: 784,
								name: "Rurenge",
								sector_id: 40607,
								courtOfCells: [
									{
										id: 4436,
										name: "KIZENGA",
										generalAssembly_id: 784,
									},
									{
										id: 4437,
										name: "NTARA",
										generalAssembly_id: 784,
									},
									{
										id: 4438,
										name: "NYAKAZINGA",
										generalAssembly_id: 784,
									},
									{
										id: 4439,
										name: "RUGOMBE",
										generalAssembly_id: 784,
									},
									{
										id: 4440,
										name: "RUVUZI-I",
										generalAssembly_id: 784,
									},
									{
										id: 4441,
										name: "RUVUZI-II",
										generalAssembly_id: 784,
									},
									{
										id: 4442,
										name: "RUZINGA-I",
										generalAssembly_id: 784,
									},
									{
										id: 4443,
										name: "RUZINGA-II",
										generalAssembly_id: 784,
									},
									{
										id: 9759,
										name: "Rurenge Court of Appeal ",
										generalAssembly_id: 784,
									},
									{
										id: 11292,
										name: "Rurenge Gacaca court of sector ",
										generalAssembly_id: 784,
									},
								],
							},
						],
					},
					{
						id: 40608,
						name: "Mutenderi",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060805,
								name: "Nyagasozi",
								sector_id: 40608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060804,
								name: "Muzingira",
								sector_id: 40608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060803,
								name: "Mutenderi",
								sector_id: 40608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060802,
								name: "Kibare",
								sector_id: 40608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060801,
								name: "Karwema",
								sector_id: 40608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 785,
								name: "Bare",
								sector_id: 40608,
								courtOfCells: [
									{
										id: 4444,
										name: "KAGURUKA",
										generalAssembly_id: 785,
									},
									{
										id: 4445,
										name: "KARENGE",
										generalAssembly_id: 785,
									},
									{
										id: 4446,
										name: "MUTENDERI",
										generalAssembly_id: 785,
									},
									{
										id: 4447,
										name: "MUZINGIRA",
										generalAssembly_id: 785,
									},
									{
										id: 4448,
										name: "RURENGE",
										generalAssembly_id: 785,
									},
									{
										id: 9760,
										name: "Bare Court of Appeal ",
										generalAssembly_id: 785,
									},
									{
										id: 11293,
										name: "Bare Gacaca court of sector ",
										generalAssembly_id: 785,
									},
								],
							},
							{
								id: 786,
								name: "Matongo",
								sector_id: 40608,
								courtOfCells: [
									{
										id: 4449,
										name: "KARWEMA",
										generalAssembly_id: 786,
									},
									{
										id: 4450,
										name: "KIBARE",
										generalAssembly_id: 786,
									},
									{
										id: 4451,
										name: "MUKONA",
										generalAssembly_id: 786,
									},
									{
										id: 4452,
										name: "NYAGASAVE",
										generalAssembly_id: 786,
									},
									{
										id: 9761,
										name: "Matongo Court of Appeal ",
										generalAssembly_id: 786,
									},
									{
										id: 11294,
										name: "Matongo Gacaca court of sector ",
										generalAssembly_id: 786,
									},
								],
							},
							{
								id: 787,
								name: "Kibara",
								sector_id: 40608,
								courtOfCells: [
									{
										id: 4453,
										name: "NYAGASOZI",
										generalAssembly_id: 787,
									},
									{
										id: 4454,
										name: "NYAMIRINDI",
										generalAssembly_id: 787,
									},
									{
										id: 4455,
										name: "NYAMUGARI",
										generalAssembly_id: 787,
									},
									{
										id: 9762,
										name: "Kibara Court of Appeal ",
										generalAssembly_id: 787,
									},
									{
										id: 11295,
										name: "Kibara Gacaca court of sector ",
										generalAssembly_id: 787,
									},
								],
							},
						],
					},
					{
						id: 40609,
						name: "Remera",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4060904,
								name: "Nyamagana",
								sector_id: 40609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060903,
								name: "Ndekwe",
								sector_id: 40609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060902,
								name: "Kinunga",
								sector_id: 40609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4060901,
								name: "Bugera",
								sector_id: 40609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 788,
								name: "Kabare I",
								sector_id: 40609,
								courtOfCells: [
									{
										id: 4456,
										name: "KABUYE",
										generalAssembly_id: 788,
									},
									{
										id: 4457,
										name: "KINANIRA",
										generalAssembly_id: 788,
									},
									{
										id: 4458,
										name: "KINUNGA",
										generalAssembly_id: 788,
									},
									{
										id: 4459,
										name: "NYAMAGANA",
										generalAssembly_id: 788,
									},
									{
										id: 4460,
										name: "NYAMUGARI",
										generalAssembly_id: 788,
									},
									{
										id: 4461,
										name: "RUHAMA",
										generalAssembly_id: 788,
									},
									{
										id: 9763,
										name: "Kabare I Court of Appeal ",
										generalAssembly_id: 788,
									},
									{
										id: 11296,
										name: "Kabare I Gacaca court of sector ",
										generalAssembly_id: 788,
									},
								],
							},
							{
								id: 789,
								name: "Gasetsa",
								sector_id: 40609,
								courtOfCells: [
									{
										id: 4462,
										name: "GIKOMERO",
										generalAssembly_id: 789,
									},
									{
										id: 4463,
										name: "KIYOVU",
										generalAssembly_id: 789,
									},
									{
										id: 4464,
										name: "NDEKWE",
										generalAssembly_id: 789,
									},
									{
										id: 4465,
										name: "NKENKE",
										generalAssembly_id: 789,
									},
									{
										id: 4466,
										name: "RUKORE",
										generalAssembly_id: 789,
									},
									{
										id: 4467,
										name: "RUTARE",
										generalAssembly_id: 789,
									},
									{
										id: 9764,
										name: "Gasetsa Court of Appeal ",
										generalAssembly_id: 789,
									},
									{
										id: 11297,
										name: "Gasetsa Gacaca court of sector ",
										generalAssembly_id: 789,
									},
								],
							},
							{
								id: 790,
								name: "Remera",
								sector_id: 40609,
								courtOfCells: [
									{
										id: 4468,
										name: "BUGERA",
										generalAssembly_id: 790,
									},
									{
										id: 4469,
										name: "GATARE",
										generalAssembly_id: 790,
									},
									{
										id: 4470,
										name: "KAMVUMBA",
										generalAssembly_id: 790,
									},
									{
										id: 4471,
										name: "KANEKE",
										generalAssembly_id: 790,
									},
									{
										id: 9765,
										name: "Remera Court of Appeal ",
										generalAssembly_id: 790,
									},
									{
										id: 11298,
										name: "Remera Gacaca court of sector ",
										generalAssembly_id: 790,
									},
								],
							},
						],
					},
					{
						id: 40610,
						name: "Rukira",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4061004,
								name: "Nyinya",
								sector_id: 40610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061003,
								name: "Nyaruvumu",
								sector_id: 40610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061002,
								name: "Kibatsi",
								sector_id: 40610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061001,
								name: "Buliba",
								sector_id: 40610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 791,
								name: "Kibaya",
								sector_id: 40610,
								courtOfCells: [
									{
										id: 4472,
										name: "GAHAMA",
										generalAssembly_id: 791,
									},
									{
										id: 4473,
										name: "KARENGE",
										generalAssembly_id: 791,
									},
									{
										id: 4474,
										name: "NYAGAHANDAGAZI",
										generalAssembly_id: 791,
									},
									{
										id: 4475,
										name: "NYAGAKIZI",
										generalAssembly_id: 791,
									},
									{
										id: 4476,
										name: "NYAGATOVU",
										generalAssembly_id: 791,
									},
									{
										id: 9766,
										name: "Kibaya Court of Appeal ",
										generalAssembly_id: 791,
									},
									{
										id: 11299,
										name: "Kibaya Gacaca court of sector ",
										generalAssembly_id: 791,
									},
								],
							},
							{
								id: 792,
								name: "Gituku",
								sector_id: 40610,
								courtOfCells: [
									{
										id: 4477,
										name: "GAFUNZO",
										generalAssembly_id: 792,
									},
									{
										id: 4478,
										name: "RUGORIGONDI",
										generalAssembly_id: 792,
									},
									{
										id: 4479,
										name: "RWAMUYAGA",
										generalAssembly_id: 792,
									},
									{
										id: 4480,
										name: "RWIMPONGO",
										generalAssembly_id: 792,
									},
									{
										id: 9767,
										name: "Gituku Court of Appeal ",
										generalAssembly_id: 792,
									},
									{
										id: 11300,
										name: "Gituku Gacaca court of sector ",
										generalAssembly_id: 792,
									},
								],
							},
							{
								id: 793,
								name: "Gitwe",
								sector_id: 40610,
								courtOfCells: [
									{
										id: 4481,
										name: "BWERANKA",
										generalAssembly_id: 793,
									},
									{
										id: 4482,
										name: "CYABAYAGARA",
										generalAssembly_id: 793,
									},
									{
										id: 4483,
										name: "KABIMBA",
										generalAssembly_id: 793,
									},
									{
										id: 4484,
										name: "RUHAMA",
										generalAssembly_id: 793,
									},
									{
										id: 4485,
										name: "RUSENYI",
										generalAssembly_id: 793,
									},
									{
										id: 4486,
										name: "RWAGAKOBE",
										generalAssembly_id: 793,
									},
									{
										id: 4487,
										name: "RWAMUKOBWA",
										generalAssembly_id: 793,
									},
									{
										id: 9768,
										name: "Gitwe Court of Appeal ",
										generalAssembly_id: 793,
									},
									{
										id: 11301,
										name: "Gitwe Gacaca court of sector ",
										generalAssembly_id: 793,
									},
								],
							},
							{
								id: 794,
								name: "Rurama",
								sector_id: 40610,
								courtOfCells: [
									{
										id: 4488,
										name: "GATSI",
										generalAssembly_id: 794,
									},
									{
										id: 4489,
										name: "HUMURE",
										generalAssembly_id: 794,
									},
									{
										id: 4490,
										name: "KIBIZI",
										generalAssembly_id: 794,
									},
									{
										id: 4491,
										name: "NYAKABANDE",
										generalAssembly_id: 794,
									},
									{
										id: 4492,
										name: "RUNYINYA",
										generalAssembly_id: 794,
									},
									{
										id: 4493,
										name: "TONERO",
										generalAssembly_id: 794,
									},
									{
										id: 9769,
										name: "Rurama Court of Appeal ",
										generalAssembly_id: 794,
									},
									{
										id: 11302,
										name: "Rurama Gacaca court of sector ",
										generalAssembly_id: 794,
									},
								],
							},
						],
					},
					{
						id: 40611,
						name: "Rukumberi",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4061105,
								name: "Rwintashya",
								sector_id: 40611,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061104,
								name: "Rubona",
								sector_id: 40611,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061103,
								name: "Rubago",
								sector_id: 40611,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061102,
								name: "Ntovi",
								sector_id: 40611,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061101,
								name: "Gituza",
								sector_id: 40611,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 795,
								name: "Gituza",
								sector_id: 40611,
								courtOfCells: [
									{
										id: 4494,
										name: "GITESANYI",
										generalAssembly_id: 795,
									},
									{
										id: 4495,
										name: "GITUZA",
										generalAssembly_id: 795,
									},
									{
										id: 4496,
										name: "MASWA",
										generalAssembly_id: 795,
									},
									{
										id: 4497,
										name: "MFUNE",
										generalAssembly_id: 795,
									},
									{
										id: 4498,
										name: "RUBONA",
										generalAssembly_id: 795,
									},
									{
										id: 4499,
										name: "RUYENZI-I",
										generalAssembly_id: 795,
									},
									{
										id: 9770,
										name: "Gituza Court of Appeal ",
										generalAssembly_id: 795,
									},
									{
										id: 11303,
										name: "Gituza Gacaca court of sector ",
										generalAssembly_id: 795,
									},
								],
							},
							{
								id: 796,
								name: "Rubago",
								sector_id: 40611,
								courtOfCells: [
									{
										id: 4500,
										name: "AKABUNGO",
										generalAssembly_id: 796,
									},
									{
										id: 4501,
										name: "KAVUMVE",
										generalAssembly_id: 796,
									},
									{
										id: 4502,
										name: "NYAGAKIZI",
										generalAssembly_id: 796,
									},
									{
										id: 4503,
										name: "NYAGITABIRE",
										generalAssembly_id: 796,
									},
									{
										id: 4504,
										name: "RUBAGO",
										generalAssembly_id: 796,
									},
									{
										id: 4505,
										name: "RUGENDA-I",
										generalAssembly_id: 796,
									},
									{
										id: 4506,
										name: "RUGENDA-II",
										generalAssembly_id: 796,
									},
									{
										id: 9771,
										name: "Rubago Court of Appeal ",
										generalAssembly_id: 796,
									},
									{
										id: 11304,
										name: "Rubago Gacaca court of sector ",
										generalAssembly_id: 796,
									},
								],
							},
							{
								id: 797,
								name: "Rukumbeli",
								sector_id: 40611,
								courtOfCells: [
									{
										id: 4507,
										name: "IYANTENDE",
										generalAssembly_id: 797,
									},
									{
										id: 4508,
										name: "MUGWATO",
										generalAssembly_id: 797,
									},
									{
										id: 4509,
										name: "NTOVI",
										generalAssembly_id: 797,
									},
									{
										id: 4510,
										name: "RUKUMBELI",
										generalAssembly_id: 797,
									},
									{
										id: 4511,
										name: "RWAMBABI",
										generalAssembly_id: 797,
									},
									{
										id: 9772,
										name: "Rukumbeli Court of Appeal ",
										generalAssembly_id: 797,
									},
									{
										id: 11305,
										name: "Rukumbeli Gacaca court of sector ",
										generalAssembly_id: 797,
									},
								],
							},
							{
								id: 798,
								name: "Sholi",
								sector_id: 40611,
								courtOfCells: [
									{
										id: 4512,
										name: "BARE",
										generalAssembly_id: 798,
									},
									{
										id: 4513,
										name: "KAROKORA",
										generalAssembly_id: 798,
									},
									{
										id: 4514,
										name: "KIGESE",
										generalAssembly_id: 798,
									},
									{
										id: 4515,
										name: "RWIMPONGO",
										generalAssembly_id: 798,
									},
									{
										id: 4516,
										name: "SHYEMBE",
										generalAssembly_id: 798,
									},
									{
										id: 9773,
										name: "Sholi Court of Appeal ",
										generalAssembly_id: 798,
									},
									{
										id: 11306,
										name: "Sholi Gacaca court of sector ",
										generalAssembly_id: 798,
									},
								],
							},
						],
					},
					{
						id: 40612,
						name: "Rurenge",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4061206,
								name: "Rwikubo",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061205,
								name: "Rujambara",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061204,
								name: "Rugese",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061203,
								name: "Musya",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061202,
								name: "Muhurire",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061201,
								name: "Akagarama",
								sector_id: 40612,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 799,
								name: "Kaberangwe",
								sector_id: 40612,
								courtOfCells: [
									{
										id: 4517,
										name: "BUGARAMA",
										generalAssembly_id: 799,
									},
									{
										id: 4518,
										name: "GASAVE",
										generalAssembly_id: 799,
									},
									{
										id: 4519,
										name: "KABASHUMBA",
										generalAssembly_id: 799,
									},
									{
										id: 4520,
										name: "RUGESE",
										generalAssembly_id: 799,
									},
									{
										id: 4521,
										name: "RWAROMBA",
										generalAssembly_id: 799,
									},
									{
										id: 4522,
										name: "RWIKUBO",
										generalAssembly_id: 799,
									},
									{
										id: 9774,
										name: "Kaberangwe Court of Appeal ",
										generalAssembly_id: 799,
									},
									{
										id: 11307,
										name: "Kaberangwe Gacaca court of sector ",
										generalAssembly_id: 799,
									},
								],
							},
							{
								id: 800,
								name: "Rurenge",
								sector_id: 40612,
								courtOfCells: [
									{
										id: 4523,
										name: "GITARAGA",
										generalAssembly_id: 800,
									},
									{
										id: 4524,
										name: "GITOBE",
										generalAssembly_id: 800,
									},
									{
										id: 4525,
										name: "MASHYOZA",
										generalAssembly_id: 800,
									},
									{
										id: 4526,
										name: "MUHURIRE",
										generalAssembly_id: 800,
									},
									{
										id: 4527,
										name: "NYAMATA",
										generalAssembly_id: 800,
									},
									{
										id: 4528,
										name: "RUJAMBARA",
										generalAssembly_id: 800,
									},
									{
										id: 4529,
										name: "SATA",
										generalAssembly_id: 800,
									},
									{
										id: 9775,
										name: "Rurenge Court of Appeal ",
										generalAssembly_id: 800,
									},
									{
										id: 11308,
										name: "Rurenge Gacaca court of sector ",
										generalAssembly_id: 800,
									},
								],
							},
							{
								id: 801,
								name: "Vumwe",
								sector_id: 40612,
								courtOfCells: [
									{
										id: 4530,
										name: "AKAGARAMA",
										generalAssembly_id: 801,
									},
									{
										id: 4531,
										name: "KAMUGUNDU",
										generalAssembly_id: 801,
									},
									{
										id: 4532,
										name: "KUWIMANA",
										generalAssembly_id: 801,
									},
									{
										id: 4533,
										name: "MUSYA",
										generalAssembly_id: 801,
									},
									{
										id: 4534,
										name: "RUCUCU",
										generalAssembly_id: 801,
									},
									{
										id: 4535,
										name: "RWASABURO",
										generalAssembly_id: 801,
									},
									{
										id: 9776,
										name: "Vumwe Court of Appeal ",
										generalAssembly_id: 801,
									},
									{
										id: 11309,
										name: "Vumwe Gacaca court of sector ",
										generalAssembly_id: 801,
									},
								],
							},
						],
					},
					{
						id: 40613,
						name: "Sake",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4061304,
								name: "Rukoma",
								sector_id: 40613,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061303,
								name: "Nkanga",
								sector_id: 40613,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061302,
								name: "Kibonde",
								sector_id: 40613,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061301,
								name: "Gafunzo",
								sector_id: 40613,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 802,
								name: "Mabuga",
								sector_id: 40613,
								courtOfCells: [
									{
										id: 4536,
										name: "AKABAYA-RURENGE",
										generalAssembly_id: 802,
									},
									{
										id: 4537,
										name: "AKABIRA",
										generalAssembly_id: 802,
									},
									{
										id: 4538,
										name: "GAFUNZO",
										generalAssembly_id: 802,
									},
									{
										id: 4539,
										name: "GASAVE",
										generalAssembly_id: 802,
									},
									{
										id: 4540,
										name: "GASHUBI",
										generalAssembly_id: 802,
									},
									{
										id: 4541,
										name: "MUYANGE-I",
										generalAssembly_id: 802,
									},
									{
										id: 4542,
										name: "MUYANGE-II",
										generalAssembly_id: 802,
									},
									{
										id: 4543,
										name: "NYABUHORO",
										generalAssembly_id: 802,
									},
									{
										id: 4544,
										name: "NYARUHANGWA",
										generalAssembly_id: 802,
									},
									{
										id: 9777,
										name: "Mabuga Court of Appeal ",
										generalAssembly_id: 802,
									},
									{
										id: 11310,
										name: "Mabuga Gacaca court of sector ",
										generalAssembly_id: 802,
									},
								],
							},
							{
								id: 803,
								name: "Ngoma",
								sector_id: 40613,
								courtOfCells: [
									{
										id: 4545,
										name: "BUKOKOZA",
										generalAssembly_id: 803,
									},
									{
										id: 4546,
										name: "GISERA",
										generalAssembly_id: 803,
									},
									{
										id: 4547,
										name: "MIZIBIRI",
										generalAssembly_id: 803,
									},
									{
										id: 4548,
										name: "NGOMA",
										generalAssembly_id: 803,
									},
									{
										id: 4549,
										name: "NKANGA",
										generalAssembly_id: 803,
									},
									{
										id: 4550,
										name: "NYAKABUNGO",
										generalAssembly_id: 803,
									},
									{
										id: 9778,
										name: "Ngoma Court of Appeal ",
										generalAssembly_id: 803,
									},
									{
										id: 11311,
										name: "Ngoma Gacaca court of sector ",
										generalAssembly_id: 803,
									},
								],
							},
							{
								id: 804,
								name: "Nshili",
								sector_id: 40613,
								courtOfCells: [
									{
										id: 4551,
										name: "KABARE",
										generalAssembly_id: 804,
									},
									{
										id: 4552,
										name: "KAGOMORA",
										generalAssembly_id: 804,
									},
									{
										id: 4553,
										name: "KARENGE",
										generalAssembly_id: 804,
									},
									{
										id: 4554,
										name: "KIBONDE",
										generalAssembly_id: 804,
									},
									{
										id: 4555,
										name: "NTUNGAMO",
										generalAssembly_id: 804,
									},
									{
										id: 4556,
										name: "NYAGASANI",
										generalAssembly_id: 804,
									},
									{
										id: 4557,
										name: "NYARUSANGE",
										generalAssembly_id: 804,
									},
									{
										id: 4558,
										name: "RURENGE",
										generalAssembly_id: 804,
									},
									{
										id: 4559,
										name: "RUSAVE",
										generalAssembly_id: 804,
									},
									{
										id: 9779,
										name: "Nshili Court of Appeal ",
										generalAssembly_id: 804,
									},
									{
										id: 11312,
										name: "Nshili Gacaca court of sector ",
										generalAssembly_id: 804,
									},
								],
							},
						],
					},
					{
						id: 40614,
						name: "Zaza",
						district_id: 406,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4061404,
								name: "Ruhinga",
								sector_id: 40614,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061403,
								name: "Ruhembe",
								sector_id: 40614,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061402,
								name: "Nyagatugunda",
								sector_id: 40614,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 4061401,
								name: "Nyagasozi",
								sector_id: 40614,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 805,
								name: "Kirambo",
								sector_id: 40614,
								courtOfCells: [
									{
										id: 4560,
										name: "KARENGE",
										generalAssembly_id: 805,
									},
									{
										id: 4561,
										name: "NYAGACEKERI",
										generalAssembly_id: 805,
									},
									{
										id: 4562,
										name: "NYARUTEJA",
										generalAssembly_id: 805,
									},
									{
										id: 4563,
										name: "RUGARAMA",
										generalAssembly_id: 805,
									},
									{
										id: 4564,
										name: "RUHINGA",
										generalAssembly_id: 805,
									},
									{
										id: 9780,
										name: "Kirambo Court of Appeal ",
										generalAssembly_id: 805,
									},
									{
										id: 11313,
										name: "Kirambo Gacaca court of sector ",
										generalAssembly_id: 805,
									},
								],
							},
							{
								id: 806,
								name: "Kukabuye",
								sector_id: 40614,
								courtOfCells: [
									{
										id: 4565,
										name: "KIZENGA",
										generalAssembly_id: 806,
									},
									{
										id: 4566,
										name: "KUMUYANGE",
										generalAssembly_id: 806,
									},
									{
										id: 4567,
										name: "NYAGAHINGA",
										generalAssembly_id: 806,
									},
									{
										id: 4568,
										name: "RUBATI",
										generalAssembly_id: 806,
									},
									{
										id: 4569,
										name: "RUGARAMA",
										generalAssembly_id: 806,
									},
									{
										id: 4570,
										name: "RWAMUBUGU",
										generalAssembly_id: 806,
									},
									{
										id: 4571,
										name: "RWIMBIRWA",
										generalAssembly_id: 806,
									},
									{
										id: 9781,
										name: "Kukabuye Court of Appeal ",
										generalAssembly_id: 806,
									},
									{
										id: 11314,
										name: "Kukabuye Gacaca court of sector ",
										generalAssembly_id: 806,
									},
								],
							},
							{
								id: 807,
								name: "Sangaza ",
								sector_id: 40614,
								courtOfCells: [
									{
										id: 4572,
										name: "KAGARAMA",
										generalAssembly_id: 807,
									},
									{
										id: 4573,
										name: "NYAKARIBA",
										generalAssembly_id: 807,
									},
									{
										id: 4574,
										name: "RUHINGA",
										generalAssembly_id: 807,
									},
									{
										id: 4575,
										name: "RUHORORO",
										generalAssembly_id: 807,
									},
									{
										id: 4576,
										name: "RURENGE",
										generalAssembly_id: 807,
									},
									{
										id: 9782,
										name: "Sangaza  Court of Appeal ",
										generalAssembly_id: 807,
									},
									{
										id: 11315,
										name: "Sangaza  Gacaca court of sector ",
										generalAssembly_id: 807,
									},
								],
							},
							{
								id: 808,
								name: "Zaza",
								sector_id: 40614,
								courtOfCells: [
									{
										id: 4577,
										name: "CYERWA",
										generalAssembly_id: 808,
									},
									{
										id: 4578,
										name: "CYIRIRA",
										generalAssembly_id: 808,
									},
									{
										id: 4579,
										name: "MAKOMA",
										generalAssembly_id: 808,
									},
									{
										id: 4580,
										name: "NYABIKU",
										generalAssembly_id: 808,
									},
									{
										id: 4581,
										name: "NYAGATUGUNDA",
										generalAssembly_id: 808,
									},
									{
										id: 4582,
										name: "NYAKIBANDA",
										generalAssembly_id: 808,
									},
									{
										id: 4583,
										name: "RUHEMBE",
										generalAssembly_id: 808,
									},
									{
										id: 4584,
										name: "RUSENYI",
										generalAssembly_id: 808,
									},
									{
										id: 4585,
										name: "RWIBUMBA",
										generalAssembly_id: 808,
									},
									{
										id: 9783,
										name: "Zaza Court of Appeal ",
										generalAssembly_id: 808,
									},
									{
										id: 11316,
										name: "Zaza Gacaca court of sector ",
										generalAssembly_id: 808,
									},
								],
							},
							{
								id: 809,
								name: "Shywa",
								sector_id: 40614,
								courtOfCells: [
									{
										id: 4586,
										name: "GIHOSHI",
										generalAssembly_id: 809,
									},
									{
										id: 4587,
										name: "KUMUYANGE",
										generalAssembly_id: 809,
									},
									{
										id: 4588,
										name: "NYAGASOZI",
										generalAssembly_id: 809,
									},
									{
										id: 4589,
										name: "RWAMUHIGI",
										generalAssembly_id: 809,
									},
									{
										id: 4590,
										name: "RWANCURO",
										generalAssembly_id: 809,
									},
									{
										id: 9784,
										name: "Shywa Court of Appeal ",
										generalAssembly_id: 809,
									},
									{
										id: 11317,
										name: "Shywa Gacaca court of sector ",
										generalAssembly_id: 809,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 402,
				name: "Nyagatare",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40201,
						name: "GATUNDA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4020107,
								name: "Rwensheke",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020106,
								name: "Nyarurema",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020105,
								name: "Nyangara",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020104,
								name: "Nyamirembe",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020103,
								name: "Nyamikamba",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020102,
								name: "Kabeza",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020101,
								name: "Cyagaju",
								sector_id: 40201,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 905,
								name: "Gatunda",
								sector_id: 40201,
								courtOfCells: [
									{
										id: 5185,
										name: "BUGUMA",
										generalAssembly_id: 905,
									},
									{
										id: 5186,
										name: "CYAGAJU",
										generalAssembly_id: 905,
									},
									{
										id: 5187,
										name: "MUHAMBO",
										generalAssembly_id: 905,
									},
									{
										id: 5188,
										name: "NYANGARA",
										generalAssembly_id: 905,
									},
									{
										id: 5189,
										name: "RWENSHEKE",
										generalAssembly_id: 905,
									},
									{
										id: 5190,
										name: "SHABANA",
										generalAssembly_id: 905,
									},
									{
										id: 9880,
										name: "Gatunda Court of Appeal ",
										generalAssembly_id: 905,
									},
									{
										id: 11413,
										name: "Gatunda Gacaca court of sector ",
										generalAssembly_id: 905,
									},
								],
							},
							{
								id: 906,
								name: "Rwebare",
								sector_id: 40201,
								courtOfCells: [
									{
										id: 5191,
										name: "KARAMBI",
										generalAssembly_id: 906,
									},
									{
										id: 5192,
										name: "MUYENZI",
										generalAssembly_id: 906,
									},
									{
										id: 5193,
										name: "NYAMIKAMBA",
										generalAssembly_id: 906,
									},
									{
										id: 5194,
										name: "NYAMIREMBE",
										generalAssembly_id: 906,
									},
									{
										id: 5195,
										name: "RWEBARE",
										generalAssembly_id: 906,
									},
									{
										id: 9881,
										name: "Rwebare Court of Appeal ",
										generalAssembly_id: 906,
									},
									{
										id: 11414,
										name: "Rwebare Gacaca court of sector ",
										generalAssembly_id: 906,
									},
								],
							},
						],
					},
					{
						id: 40202,
						name: "KARAMA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4020207,
								name: "Nyakiga",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020206,
								name: "Ndego",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020205,
								name: "Kabuga",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020204,
								name: "Gikundamvura",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020203,
								name: "Gikagati",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020202,
								name: "Cyenkwanzi",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020201,
								name: "Bushara",
								sector_id: 40202,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 907,
								name: "Bushara",
								sector_id: 40202,
								courtOfCells: [
									{
										id: 5196,
										name: "BUSHARA",
										generalAssembly_id: 907,
									},
									{
										id: 5197,
										name: "GIKUNDAMVURA",
										generalAssembly_id: 907,
									},
									{
										id: 5198,
										name: "KABUGA",
										generalAssembly_id: 907,
									},
									{
										id: 5199,
										name: "NYAGASHARARA",
										generalAssembly_id: 907,
									},
									{
										id: 5200,
										name: "NYAMIRAMA",
										generalAssembly_id: 907,
									},
									{
										id: 9882,
										name: "Bushara Court of Appeal ",
										generalAssembly_id: 907,
									},
									{
										id: 11415,
										name: "Bushara Gacaca court of sector ",
										generalAssembly_id: 907,
									},
								],
							},
							{
								id: 908,
								name: "Karama ",
								sector_id: 40202,
								courtOfCells: [
									{
										id: 5201,
										name: "CYENKWAZI",
										generalAssembly_id: 908,
									},
									{
										id: 5202,
										name: "GIKAGATI",
										generalAssembly_id: 908,
									},
									{
										id: 5203,
										name: "KAGONGI",
										generalAssembly_id: 908,
									},
									{
										id: 5204,
										name: "KANYAMI",
										generalAssembly_id: 908,
									},
									{
										id: 5205,
										name: "NDEGO",
										generalAssembly_id: 908,
									},
									{
										id: 5206,
										name: "NYAKIGA",
										generalAssembly_id: 908,
									},
									{
										id: 9883,
										name: "Karama  Court of Appeal ",
										generalAssembly_id: 908,
									},
									{
										id: 11416,
										name: "Karama  Gacaca court of sector ",
										generalAssembly_id: 908,
									},
								],
							},
						],
					},
					{
						id: 40203,
						name: "KARANGAZI",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020311,
								name: "Rwisirabo",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020310,
								name: "Rwenyemera",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020309,
								name: "Rubagabaga",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020308,
								name: "Nyamirama",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020307,
								name: "Nyagashanga",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020306,
								name: "Ndama",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020305,
								name: "Musenyi",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020304,
								name: "Mbare",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020303,
								name: "Kizirakome",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020302,
								name: "Karama",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020301,
								name: "Kamate",
								sector_id: 40203,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 909,
								name: "Kamate ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5207,
										name: "BUHONGORO I",
										generalAssembly_id: 909,
									},
									{
										id: 5208,
										name: "BUHONGORO II",
										generalAssembly_id: 909,
									},
									{
										id: 5209,
										name: "KAMATE",
										generalAssembly_id: 909,
									},
									{
										id: 5210,
										name: "KIGAZI",
										generalAssembly_id: 909,
									},
									{
										id: 9884,
										name: "Kamate  Court of Appeal ",
										generalAssembly_id: 909,
									},
									{
										id: 11417,
										name: "Kamate  Gacaca court of sector ",
										generalAssembly_id: 909,
									},
								],
							},
							{
								id: 910,
								name: "Karangazi ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5211,
										name: "RWISIRABO I",
										generalAssembly_id: 910,
									},
									{
										id: 5212,
										name: "RWISIRABO II",
										generalAssembly_id: 910,
									},
									{
										id: 5213,
										name: "RWISIRABO III",
										generalAssembly_id: 910,
									},
									{
										id: 5214,
										name: "RWAYAGARA I",
										generalAssembly_id: 910,
									},
									{
										id: 5215,
										name: "RWAYAGARA II",
										generalAssembly_id: 910,
									},
									{
										id: 5216,
										name: "RUBONA",
										generalAssembly_id: 910,
									},
									{
										id: 5217,
										name: "GAKOMA",
										generalAssembly_id: 910,
									},
									{
										id: 9885,
										name: "Karangazi  Court of Appeal ",
										generalAssembly_id: 910,
									},
									{
										id: 11418,
										name: "Karangazi  Gacaca court of sector ",
										generalAssembly_id: 910,
									},
								],
							},
							{
								id: 911,
								name: "Kizirakome ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5218,
										name: "KIZIRAKOME I",
										generalAssembly_id: 911,
									},
									{
										id: 5219,
										name: "KIZIRAKOME II",
										generalAssembly_id: 911,
									},
									{
										id: 5220,
										name: "KAHI I",
										generalAssembly_id: 911,
									},
									{
										id: 5221,
										name: "KAHI II",
										generalAssembly_id: 911,
									},
									{
										id: 5222,
										name: "KAGEYO I",
										generalAssembly_id: 911,
									},
									{
										id: 5223,
										name: "KAGEYO II",
										generalAssembly_id: 911,
									},
									{
										id: 9886,
										name: "Kizirakome  Court of Appeal ",
										generalAssembly_id: 911,
									},
									{
										id: 11419,
										name: "Kizirakome  Gacaca court of sector ",
										generalAssembly_id: 911,
									},
								],
							},
							{
								id: 912,
								name: "Mbare ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5224,
										name: "KABIRIZI",
										generalAssembly_id: 912,
									},
									{
										id: 5225,
										name: "KAJUMO",
										generalAssembly_id: 912,
									},
									{
										id: 5226,
										name: "MBARE I",
										generalAssembly_id: 912,
									},
									{
										id: 5227,
										name: "MBARE II",
										generalAssembly_id: 912,
									},
									{
										id: 9887,
										name: "Mbare  Court of Appeal ",
										generalAssembly_id: 912,
									},
									{
										id: 11420,
										name: "Mbare  Gacaca court of sector ",
										generalAssembly_id: 912,
									},
								],
							},
							{
								id: 913,
								name: "Musenyi ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5228,
										name: "IRUNDIRO",
										generalAssembly_id: 913,
									},
									{
										id: 5229,
										name: "MAKOMO",
										generalAssembly_id: 913,
									},
									{
										id: 5230,
										name: "MUSENYI I",
										generalAssembly_id: 913,
									},
									{
										id: 5231,
										name: "MUSENYI II",
										generalAssembly_id: 913,
									},
									{
										id: 5232,
										name: "RUZIRANYENZI",
										generalAssembly_id: 913,
									},
									{
										id: 5233,
										name: "KARAMA",
										generalAssembly_id: 913,
									},
									{
										id: 9888,
										name: "Musenyi  Court of Appeal ",
										generalAssembly_id: 913,
									},
									{
										id: 11421,
										name: "Musenyi  Gacaca court of sector ",
										generalAssembly_id: 913,
									},
								],
							},
							{
								id: 914,
								name: "Musenyi ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5234,
										name: "RUBAGABAGA",
										generalAssembly_id: 914,
									},
									{
										id: 5235,
										name: "NKUNA",
										generalAssembly_id: 914,
									},
									{
										id: 9889,
										name: "Musenyi  Court of Appeal ",
										generalAssembly_id: 914,
									},
									{
										id: 11422,
										name: "Musenyi  Gacaca court of sector ",
										generalAssembly_id: 914,
									},
								],
							},
							{
								id: 915,
								name: "Ndama ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5236,
										name: "NDAMA",
										generalAssembly_id: 915,
									},
									{
										id: 5237,
										name: "AKANYERI",
										generalAssembly_id: 915,
									},
									{
										id: 5238,
										name: "AKAYANGE",
										generalAssembly_id: 915,
									},
									{
										id: 5239,
										name: "KAGUGU I",
										generalAssembly_id: 915,
									},
									{
										id: 5240,
										name: "KAGUGU II",
										generalAssembly_id: 915,
									},
									{
										id: 9890,
										name: "Ndama  Court of Appeal ",
										generalAssembly_id: 915,
									},
									{
										id: 11423,
										name: "Ndama  Gacaca court of sector ",
										generalAssembly_id: 915,
									},
								],
							},
							{
								id: 916,
								name: "Nyagashanga",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5241,
										name: "NKOMA",
										generalAssembly_id: 916,
									},
									{
										id: 5242,
										name: "BWERA",
										generalAssembly_id: 916,
									},
									{
										id: 5243,
										name: "KABARE",
										generalAssembly_id: 916,
									},
									{
										id: 5244,
										name: "AKAYANGE",
										generalAssembly_id: 916,
									},
									{
										id: 5245,
										name: "NYAMIRAMA",
										generalAssembly_id: 916,
									},
									{
										id: 9891,
										name: "Nyagashanga Court of Appeal ",
										generalAssembly_id: 916,
									},
									{
										id: 11424,
										name: "Nyagashanga Gacaca court of sector ",
										generalAssembly_id: 916,
									},
								],
							},
							{
								id: 917,
								name: "Rwenyemera ",
								sector_id: 40203,
								courtOfCells: [
									{
										id: 5246,
										name: "RWIMIRAMA",
										generalAssembly_id: 917,
									},
									{
										id: 5247,
										name: "BWERA",
										generalAssembly_id: 917,
									},
									{
										id: 5248,
										name: "RWENYEMERA I",
										generalAssembly_id: 917,
									},
									{
										id: 5249,
										name: "RWENYEMERA II",
										generalAssembly_id: 917,
									},
									{
										id: 5250,
										name: "KAYISHUNIKA",
										generalAssembly_id: 917,
									},
									{
										id: 9892,
										name: "Rwenyemera  Court of Appeal ",
										generalAssembly_id: 917,
									},
									{
										id: 11425,
										name: "Rwenyemera  Gacaca court of sector ",
										generalAssembly_id: 917,
									},
								],
							},
						],
					},
					{
						id: 40204,
						name: "KATABAGEMU",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020409,
								name: "Rutoma",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020408,
								name: "Rugazi",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020407,
								name: "Rubira",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020406,
								name: "Nyakigando",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020405,
								name: "Kigarama",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020404,
								name: "Katabagemu",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020403,
								name: "Kanyeganyege",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020402,
								name: "Kaduha",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020401,
								name: "Bayigaburire",
								sector_id: 40204,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 918,
								name: "Katabagemu",
								sector_id: 40204,
								courtOfCells: [
									{
										id: 5251,
										name: "BAYIGABURIRE A",
										generalAssembly_id: 918,
									},
									{
										id: 5252,
										name: "BAYIGABURIRE A",
										generalAssembly_id: 918,
									},
									{
										id: 5253,
										name: "BAYIGABURIRE A",
										generalAssembly_id: 918,
									},
									{
										id: 5254,
										name: "KATABAGEMU A",
										generalAssembly_id: 918,
									},
									{
										id: 5255,
										name: "KATABAGEMU A",
										generalAssembly_id: 918,
									},
									{
										id: 5256,
										name: "KATABAGEMU A",
										generalAssembly_id: 918,
									},
									{
										id: 5257,
										name: "KIGARAMA",
										generalAssembly_id: 918,
									},
									{
										id: 5258,
										name: "RUTOMA",
										generalAssembly_id: 918,
									},
									{
										id: 9893,
										name: "Katabagemu Court of Appeal ",
										generalAssembly_id: 918,
									},
									{
										id: 11426,
										name: "Katabagemu Gacaca court of sector ",
										generalAssembly_id: 918,
									},
								],
							},
							{
								id: 919,
								name: "Nyakigando ",
								sector_id: 40204,
								courtOfCells: [
									{
										id: 5259,
										name: "NYABIYONZA",
										generalAssembly_id: 919,
									},
									{
										id: 5260,
										name: "NTOMA",
										generalAssembly_id: 919,
									},
									{
										id: 5261,
										name: "BUYUGI",
										generalAssembly_id: 919,
									},
									{
										id: 5262,
										name: "KANYEGANYEGE",
										generalAssembly_id: 919,
									},
									{
										id: 5263,
										name: "RUBIRA I",
										generalAssembly_id: 919,
									},
									{
										id: 5264,
										name: "RUBIRA II",
										generalAssembly_id: 919,
									},
									{
										id: 5265,
										name: "RUGAZI I -RUGAZI II",
										generalAssembly_id: 919,
									},
									{
										id: 5266,
										name: "KABAYA",
										generalAssembly_id: 919,
									},
									{
										id: 5267,
										name: "KANYINYA",
										generalAssembly_id: 919,
									},
									{
										id: 5268,
										name: "KADUHA",
										generalAssembly_id: 919,
									},
									{
										id: 5269,
										name: "NYAKIGANDO I",
										generalAssembly_id: 919,
									},
									{
										id: 5270,
										name: "NYAKIGANDO II",
										generalAssembly_id: 919,
									},
									{
										id: 5271,
										name: "RYARUGANZU",
										generalAssembly_id: 919,
									},
									{
										id: 5272,
										name: "RUTOMA",
										generalAssembly_id: 919,
									},
									{
										id: 9894,
										name: "Nyakigando  Court of Appeal ",
										generalAssembly_id: 919,
									},
									{
										id: 11427,
										name: "Nyakigando  Gacaca court of sector ",
										generalAssembly_id: 919,
									},
								],
							},
						],
					},
					{
						id: 40205,
						name: "KIYOMBE",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020506,
								name: "Tovu",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020505,
								name: "Karujumba",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020504,
								name: "Karambo",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020503,
								name: "Kabungo",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020502,
								name: "Gitenga",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020501,
								name: "Gataba",
								sector_id: 40205,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 920,
								name: "Gatsilima",
								sector_id: 40205,
								courtOfCells: [
									{
										id: 5273,
										name: "CYERERO",
										generalAssembly_id: 920,
									},
									{
										id: 5274,
										name: "GITENGA",
										generalAssembly_id: 920,
									},
									{
										id: 5275,
										name: "NYABUBARE",
										generalAssembly_id: 920,
									},
									{
										id: 5276,
										name: "RUGOBE",
										generalAssembly_id: 920,
									},
									{
										id: 5277,
										name: "TOVU",
										generalAssembly_id: 920,
									},
									{
										id: 9895,
										name: "Gatsilima Court of Appeal ",
										generalAssembly_id: 920,
									},
									{
										id: 11428,
										name: "Gatsilima Gacaca court of sector ",
										generalAssembly_id: 920,
									},
								],
							},
							{
								id: 921,
								name: "Cyondo",
								sector_id: 40205,
								courtOfCells: [
									{
										id: 5278,
										name: "BWISHYA",
										generalAssembly_id: 921,
									},
									{
										id: 5279,
										name: "GASHANJE",
										generalAssembly_id: 921,
									},
									{
										id: 5280,
										name: "KABUNGO",
										generalAssembly_id: 921,
									},
									{
										id: 5281,
										name: "RUSHEBEYA",
										generalAssembly_id: 921,
									},
									{
										id: 5282,
										name: "RUTOMA",
										generalAssembly_id: 921,
									},
									{
										id: 9896,
										name: "Cyondo Court of Appeal ",
										generalAssembly_id: 921,
									},
									{
										id: 11429,
										name: "Cyondo Gacaca court of sector ",
										generalAssembly_id: 921,
									},
								],
							},
							{
								id: 922,
								name: "Kabare ",
								sector_id: 40205,
								courtOfCells: [
									{
										id: 5283,
										name: "GISHORO",
										generalAssembly_id: 922,
									},
									{
										id: 5284,
										name: "KAGOROGORO",
										generalAssembly_id: 922,
									},
									{
										id: 5285,
										name: "KENZIZI",
										generalAssembly_id: 922,
									},
									{
										id: 5286,
										name: "KARUJUMBA",
										generalAssembly_id: 922,
									},
									{
										id: 5287,
										name: "NYANGE",
										generalAssembly_id: 922,
									},
									{
										id: 5288,
										name: "RUKONGORO",
										generalAssembly_id: 922,
									},
									{
										id: 9897,
										name: "Kabare  Court of Appeal ",
										generalAssembly_id: 922,
									},
									{
										id: 11430,
										name: "Kabare  Gacaca court of sector ",
										generalAssembly_id: 922,
									},
								],
							},
							{
								id: 923,
								name: "Kiyombe ",
								sector_id: 40205,
								courtOfCells: [
									{
										id: 5289,
										name: "GATABA",
										generalAssembly_id: 923,
									},
									{
										id: 5290,
										name: "KANYABUGARA",
										generalAssembly_id: 923,
									},
									{
										id: 5291,
										name: "KANYANTANGA",
										generalAssembly_id: 923,
									},
									{
										id: 5292,
										name: "MURAMBI",
										generalAssembly_id: 923,
									},
									{
										id: 5293,
										name: "RUSONI",
										generalAssembly_id: 923,
									},
									{
										id: 5294,
										name: "RUSOROZA",
										generalAssembly_id: 923,
									},
									{
										id: 9898,
										name: "Kiyombe  Court of Appeal ",
										generalAssembly_id: 923,
									},
									{
										id: 11431,
										name: "Kiyombe  Gacaca court of sector ",
										generalAssembly_id: 923,
									},
								],
							},
							{
								id: 924,
								name: "Nkana ",
								sector_id: 40205,
								courtOfCells: [
									{
										id: 5295,
										name: "KABIRA",
										generalAssembly_id: 924,
									},
									{
										id: 5296,
										name: "KARAMBO",
										generalAssembly_id: 924,
									},
									{
										id: 5297,
										name: "MUHINDA",
										generalAssembly_id: 924,
									},
									{
										id: 5298,
										name: "RUBARE",
										generalAssembly_id: 924,
									},
									{
										id: 5299,
										name: "RUGENDO",
										generalAssembly_id: 924,
									},
									{
										id: 9899,
										name: "Nkana  Court of Appeal ",
										generalAssembly_id: 924,
									},
									{
										id: 11432,
										name: "Nkana  Gacaca court of sector ",
										generalAssembly_id: 924,
									},
								],
							},
						],
					},
					{
						id: 40206,
						name: "MATIMBA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020608,
								name: "Rwentanga",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020607,
								name: "Nyabwishongwezi",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020606,
								name: "Matimba",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020605,
								name: "Kanyonza",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020604,
								name: "Kagitumba",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020603,
								name: "Cyembogo",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020602,
								name: "Byimana",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020601,
								name: "Bwera",
								sector_id: 40206,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 925,
								name: "Kagitumba",
								sector_id: 40206,
								courtOfCells: [
									{
										id: 5300,
										name: "AKAGERA",
										generalAssembly_id: 925,
									},
									{
										id: 5301,
										name: "CYEMBOGO I",
										generalAssembly_id: 925,
									},
									{
										id: 5302,
										name: "CYEMBOGO II",
										generalAssembly_id: 925,
									},
									{
										id: 5303,
										name: "CYEMBOGO III",
										generalAssembly_id: 925,
									},
									{
										id: 5304,
										name: "GISHARA",
										generalAssembly_id: 925,
									},
									{
										id: 5305,
										name: "KAGITUMBA I",
										generalAssembly_id: 925,
									},
									{
										id: 5306,
										name: "KAGITUMBA II",
										generalAssembly_id: 925,
									},
									{
										id: 5307,
										name: "KANYONZA",
										generalAssembly_id: 925,
									},
									{
										id: 5308,
										name: "MUSENYI",
										generalAssembly_id: 925,
									},
									{
										id: 5309,
										name: "MUVUMBA",
										generalAssembly_id: 925,
									},
									{
										id: 9900,
										name: "Kagitumba Court of Appeal ",
										generalAssembly_id: 925,
									},
									{
										id: 11433,
										name: "Kagitumba Gacaca court of sector ",
										generalAssembly_id: 925,
									},
								],
							},
							{
								id: 926,
								name: "Matimba",
								sector_id: 40206,
								courtOfCells: [
									{
										id: 5310,
										name: "NTOMA AWERA",
										generalAssembly_id: 926,
									},
									{
										id: 5311,
										name: "GAKORE",
										generalAssembly_id: 926,
									},
									{
										id: 5312,
										name: "MATIMBA",
										generalAssembly_id: 926,
									},
									{
										id: 5313,
										name: "RUGAGA",
										generalAssembly_id: 926,
									},
									{
										id: 9901,
										name: "Matimba Court of Appeal ",
										generalAssembly_id: 926,
									},
									{
										id: 11434,
										name: "Matimba Gacaca court of sector ",
										generalAssembly_id: 926,
									},
								],
							},
							{
								id: 927,
								name: "Nyabwishongwezi",
								sector_id: 40206,
								courtOfCells: [
									{
										id: 5314,
										name: "BYIMANA",
										generalAssembly_id: 927,
									},
									{
										id: 5315,
										name: "KABUGA",
										generalAssembly_id: 927,
									},
									{
										id: 5316,
										name: "NYABWISHONGWEZI",
										generalAssembly_id: 927,
									},
									{
										id: 9902,
										name: "Nyabwishongwezi Court of Appeal ",
										generalAssembly_id: 927,
									},
									{
										id: 11435,
										name: "Nyabwishongwezi Gacaca court of sector ",
										generalAssembly_id: 927,
									},
								],
							},
							{
								id: 928,
								name: "Rwentanga ",
								sector_id: 40206,
								courtOfCells: [
									{
										id: 5317,
										name: "GAKOMA",
										generalAssembly_id: 928,
									},
									{
										id: 5318,
										name: "KAGEZI",
										generalAssembly_id: 928,
									},
									{
										id: 5319,
										name: "MITAYAYO I",
										generalAssembly_id: 928,
									},
									{
										id: 5320,
										name: "MITAYAYO II",
										generalAssembly_id: 928,
									},
									{
										id: 5321,
										name: "RWENTANGA",
										generalAssembly_id: 928,
									},
									{
										id: 9903,
										name: "Rwentanga  Court of Appeal ",
										generalAssembly_id: 928,
									},
									{
										id: 11436,
										name: "Rwentanga  Gacaca court of sector ",
										generalAssembly_id: 928,
									},
								],
							},
						],
					},
					{
						id: 40207,
						name: "MIMURI",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020705,
								name: "Rugari",
								sector_id: 40207,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020704,
								name: "Mimuri",
								sector_id: 40207,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020703,
								name: "Mahoro",
								sector_id: 40207,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020702,
								name: "Gakoma",
								sector_id: 40207,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020701,
								name: "Bibare",
								sector_id: 40207,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 929,
								name: "Gakoma ",
								sector_id: 40207,
								courtOfCells: [
									{
										id: 5322,
										name: "CYABWANA",
										generalAssembly_id: 929,
									},
									{
										id: 5323,
										name: "MAHWA",
										generalAssembly_id: 929,
									},
									{
										id: 5324,
										name: "NYAGAHANDAGAZA",
										generalAssembly_id: 929,
									},
									{
										id: 5325,
										name: "NYARWINA",
										generalAssembly_id: 929,
									},
									{
										id: 9904,
										name: "Gakoma  Court of Appeal ",
										generalAssembly_id: 929,
									},
									{
										id: 11437,
										name: "Gakoma  Gacaca court of sector ",
										generalAssembly_id: 929,
									},
								],
							},
							{
								id: 930,
								name: "Mimuri",
								sector_id: 40207,
								courtOfCells: [
									{
										id: 5326,
										name: "BAYIGABURIRE A",
										generalAssembly_id: 930,
									},
									{
										id: 5327,
										name: "BIBARE",
										generalAssembly_id: 930,
									},
									{
										id: 5328,
										name: "MIMURI",
										generalAssembly_id: 930,
									},
									{
										id: 5329,
										name: "NTEKO",
										generalAssembly_id: 930,
									},
									{
										id: 5330,
										name: "RUGARI",
										generalAssembly_id: 930,
									},
									{
										id: 9905,
										name: "Mimuri Court of Appeal ",
										generalAssembly_id: 930,
									},
									{
										id: 11438,
										name: "Mimuri Gacaca court of sector ",
										generalAssembly_id: 930,
									},
								],
							},
						],
					},
					{
						id: 40208,
						name: "MUKAMA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020806,
								name: "Rugarama",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020805,
								name: "Kagina",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020804,
								name: "Gishororo",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020803,
								name: "Gihengeri",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020802,
								name: "Gatete",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020801,
								name: "Bufunda",
								sector_id: 40208,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 931,
								name: "Mukama",
								sector_id: 40208,
								courtOfCells: [
									{
										id: 5331,
										name: "BUFUNDA",
										generalAssembly_id: 931,
									},
									{
										id: 5332,
										name: "BUKIRE",
										generalAssembly_id: 931,
									},
									{
										id: 5333,
										name: "GISHORORO",
										generalAssembly_id: 931,
									},
									{
										id: 5334,
										name: "KAGINA",
										generalAssembly_id: 931,
									},
									{
										id: 5335,
										name: "NYAGATARE",
										generalAssembly_id: 931,
									},
									{
										id: 5336,
										name: "RUGARAMA",
										generalAssembly_id: 931,
									},
									{
										id: 9906,
										name: "Mukama Court of Appeal ",
										generalAssembly_id: 931,
									},
									{
										id: 11439,
										name: "Mukama Gacaca court of sector ",
										generalAssembly_id: 931,
									},
								],
							},
							{
								id: 932,
								name: "Kabongoya ",
								sector_id: 40208,
								courtOfCells: [
									{
										id: 5337,
										name: "GATETE",
										generalAssembly_id: 932,
									},
									{
										id: 5338,
										name: "GIHENGERI",
										generalAssembly_id: 932,
									},
									{
										id: 5339,
										name: "KABONGOYA",
										generalAssembly_id: 932,
									},
									{
										id: 9907,
										name: "Kabongoya  Court of Appeal ",
										generalAssembly_id: 932,
									},
									{
										id: 11440,
										name: "Kabongoya  Gacaca court of sector ",
										generalAssembly_id: 932,
									},
								],
							},
						],
					},
					{
						id: 40209,
						name: "MUSHERI",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4020908,
								name: "Rugarama Ii",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020907,
								name: "Rugarama I",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020906,
								name: "Nyamiyonga",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020905,
								name: "Nyagatabire",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020904,
								name: "Ntoma",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020903,
								name: "Musheri",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020902,
								name: "Kijojo",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4020901,
								name: "Kibirizi",
								sector_id: 40209,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 933,
								name: "Kijojo ",
								sector_id: 40209,
								courtOfCells: [
									{
										id: 5340,
										name: "KAGWEGWE",
										generalAssembly_id: 933,
									},
									{
										id: 5341,
										name: "KIJOJO",
										generalAssembly_id: 933,
									},
									{
										id: 9908,
										name: "Kijojo  Court of Appeal ",
										generalAssembly_id: 933,
									},
									{
										id: 11441,
										name: "Kijojo  Gacaca court of sector ",
										generalAssembly_id: 933,
									},
								],
							},
							{
								id: 934,
								name: "Musheli ",
								sector_id: 40209,
								courtOfCells: [
									{
										id: 5342,
										name: "GAKIRI",
										generalAssembly_id: 934,
									},
									{
										id: 5343,
										name: "HUMURE",
										generalAssembly_id: 934,
									},
									{
										id: 5344,
										name: "KARAMA",
										generalAssembly_id: 934,
									},
									{
										id: 5345,
										name: "KARUCA",
										generalAssembly_id: 934,
									},
									{
										id: 5346,
										name: "KIBIRIZI-NYAMENGE",
										generalAssembly_id: 934,
									},
									{
										id: 5347,
										name: "MURISANGA",
										generalAssembly_id: 934,
									},
									{
										id: 5348,
										name: "MUSHELI",
										generalAssembly_id: 934,
									},
									{
										id: 5349,
										name: "NTOMA I",
										generalAssembly_id: 934,
									},
									{
										id: 5350,
										name: "NTOMA II",
										generalAssembly_id: 934,
									},
									{
										id: 5351,
										name: "NYAMISANGE",
										generalAssembly_id: 934,
									},
									{
										id: 5352,
										name: "RUGARAMA",
										generalAssembly_id: 934,
									},
									{
										id: 5353,
										name: "UMUNINI",
										generalAssembly_id: 934,
									},
									{
										id: 5354,
										name: "KARAMBI",
										generalAssembly_id: 934,
									},
									{
										id: 9909,
										name: "Musheli  Court of Appeal ",
										generalAssembly_id: 934,
									},
									{
										id: 11442,
										name: "Musheli  Gacaca court of sector ",
										generalAssembly_id: 934,
									},
								],
							},
							{
								id: 935,
								name: "Nyamiyonga ",
								sector_id: 40209,
								courtOfCells: [
									{
										id: 5355,
										name: "CYENOMBE",
										generalAssembly_id: 935,
									},
									{
										id: 5356,
										name: "GIKUNYU",
										generalAssembly_id: 935,
									},
									{
										id: 5357,
										name: "NYAGATABIRE",
										generalAssembly_id: 935,
									},
									{
										id: 5358,
										name: "NYAMIYONGA II",
										generalAssembly_id: 935,
									},
									{
										id: 5359,
										name: "NYAMIYONGA I",
										generalAssembly_id: 935,
									},
									{
										id: 9910,
										name: "Nyamiyonga  Court of Appeal ",
										generalAssembly_id: 935,
									},
									{
										id: 11443,
										name: "Nyamiyonga  Gacaca court of sector ",
										generalAssembly_id: 935,
									},
								],
							},
						],
					},
					{
						id: 40210,
						name: "NYAGATARE",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4021009,
								name: "Ryabega",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021008,
								name: "Rutaraka",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021007,
								name: "Nyagatare",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021006,
								name: "Nsheke",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021005,
								name: "Kamagiri",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021004,
								name: "Gakirage",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021003,
								name: "Cyabayaga",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021002,
								name: "Bushoga",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021001,
								name: "Barija",
								sector_id: 40210,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 936,
								name: "Barija ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5360,
										name: "BARIJA",
										generalAssembly_id: 936,
									},
									{
										id: 5361,
										name: "BURUMBA",
										generalAssembly_id: 936,
									},
									{
										id: 5362,
										name: "KINIHIRA",
										generalAssembly_id: 936,
									},
									{
										id: 9911,
										name: "Barija  Court of Appeal ",
										generalAssembly_id: 936,
									},
									{
										id: 11444,
										name: "Barija  Gacaca court of sector ",
										generalAssembly_id: 936,
									},
								],
							},
							{
								id: 937,
								name: "Nsheke ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5363,
										name: "KABARE I",
										generalAssembly_id: 937,
									},
									{
										id: 5364,
										name: "NSHEKE-NYEGEZA",
										generalAssembly_id: 937,
									},
									{
										id: 9912,
										name: "Nsheke  Court of Appeal ",
										generalAssembly_id: 937,
									},
									{
										id: 11445,
										name: "Nsheke  Gacaca court of sector ",
										generalAssembly_id: 937,
									},
								],
							},
							{
								id: 938,
								name: "Nyagatare ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5365,
										name: "MIRAMA",
										generalAssembly_id: 938,
									},
									{
										id: 5366,
										name: "NYAGATARE I",
										generalAssembly_id: 938,
									},
									{
										id: 5367,
										name: "NYAGATARE II",
										generalAssembly_id: 938,
									},
									{
										id: 9913,
										name: "Nyagatare  Court of Appeal ",
										generalAssembly_id: 938,
									},
									{
										id: 11446,
										name: "Nyagatare  Gacaca court of sector ",
										generalAssembly_id: 938,
									},
								],
							},
							{
								id: 939,
								name: "Rutaraka ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5368,
										name: "RUTARAKA",
										generalAssembly_id: 939,
									},
									{
										id: 5369,
										name: "NKONJI",
										generalAssembly_id: 939,
									},
									{
										id: 5370,
										name: "GIHOROBWA",
										generalAssembly_id: 939,
									},
									{
										id: 9914,
										name: "Rutaraka  Court of Appeal ",
										generalAssembly_id: 939,
									},
									{
										id: 11447,
										name: "Rutaraka  Gacaca court of sector ",
										generalAssembly_id: 939,
									},
								],
							},
							{
								id: 940,
								name: "Ryabega ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5371,
										name: "RYABEGA-MUGALI",
										generalAssembly_id: 940,
									},
									{
										id: 9915,
										name: "Ryabega  Court of Appeal ",
										generalAssembly_id: 940,
									},
									{
										id: 11448,
										name: "Ryabega  Gacaca court of sector ",
										generalAssembly_id: 940,
									},
								],
							},
							{
								id: 941,
								name: "Nkerenke ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5372,
										name: "KAMAGIRI",
										generalAssembly_id: 941,
									},
									{
										id: 5373,
										name: "KARUNGI",
										generalAssembly_id: 941,
									},
									{
										id: 5374,
										name: "NKERENKE",
										generalAssembly_id: 941,
									},
									{
										id: 9916,
										name: "Nkerenke  Court of Appeal ",
										generalAssembly_id: 941,
									},
									{
										id: 11449,
										name: "Nkerenke  Gacaca court of sector ",
										generalAssembly_id: 941,
									},
								],
							},
							{
								id: 942,
								name: "Rugendo ",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5375,
										name: "MARONGERO I",
										generalAssembly_id: 942,
									},
									{
										id: 5376,
										name: "MARONGERO II",
										generalAssembly_id: 942,
									},
									{
										id: 5377,
										name: "RUGENDO I",
										generalAssembly_id: 942,
									},
									{
										id: 5378,
										name: "RUGENDO II",
										generalAssembly_id: 942,
									},
									{
										id: 5379,
										name: "RYABEGA",
										generalAssembly_id: 942,
									},
									{
										id: 9917,
										name: "Rugendo  Court of Appeal ",
										generalAssembly_id: 942,
									},
									{
										id: 11450,
										name: "Rugendo  Gacaca court of sector ",
										generalAssembly_id: 942,
									},
								],
							},
							{
								id: 943,
								name: "Gakirage",
								sector_id: 40210,
								courtOfCells: [
									{
										id: 5380,
										name: "BIHINGA",
										generalAssembly_id: 943,
									},
									{
										id: 5381,
										name: "BUSHOGA",
										generalAssembly_id: 943,
									},
									{
										id: 5382,
										name: "CYABAHANGA AYONYO",
										generalAssembly_id: 943,
									},
									{
										id: 5383,
										name: "CYABAYAGA A",
										generalAssembly_id: 943,
									},
									{
										id: 5384,
										name: "CYABAYAGA A",
										generalAssembly_id: 943,
									},
									{
										id: 5385,
										name: "KIBOGA",
										generalAssembly_id: 943,
									},
									{
										id: 5386,
										name: "NKONGI I-NKONGI II-GAKIRAGE",
										generalAssembly_id: 943,
									},
									{
										id: 5387,
										name: "CYONYO",
										generalAssembly_id: 943,
									},
									{
										id: 5388,
										name: "NYAKABUYE",
										generalAssembly_id: 943,
									},
									{
										id: 5389,
										name: "RYINKUYU",
										generalAssembly_id: 943,
									},
									{
										id: 9918,
										name: "Gakirage Court of Appeal ",
										generalAssembly_id: 943,
									},
									{
										id: 11451,
										name: "Gakirage Gacaca court of sector ",
										generalAssembly_id: 943,
									},
								],
							},
						],
					},
					{
						id: 40211,
						name: "RUKOMO",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4021105,
								name: "Rurenge",
								sector_id: 40211,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021104,
								name: "Rukomo Ii",
								sector_id: 40211,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021103,
								name: "Nyakagarama",
								sector_id: 40211,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021102,
								name: "Gashenyi",
								sector_id: 40211,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021101,
								name: "Gahurura",
								sector_id: 40211,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 944,
								name: "Gashenyi",
								sector_id: 40211,
								courtOfCells: [
									{
										id: 5390,
										name: "BUKEGETE",
										generalAssembly_id: 944,
									},
									{
										id: 5391,
										name: "GASHENYI",
										generalAssembly_id: 944,
									},
									{
										id: 5392,
										name: "GASHURA",
										generalAssembly_id: 944,
									},
									{
										id: 5393,
										name: "NYAKAGARAMA",
										generalAssembly_id: 944,
									},
									{
										id: 5394,
										name: "RURENGE",
										generalAssembly_id: 944,
									},
									{
										id: 9919,
										name: "Gashenyi Court of Appeal ",
										generalAssembly_id: 944,
									},
									{
										id: 11452,
										name: "Gashenyi Gacaca court of sector ",
										generalAssembly_id: 944,
									},
								],
							},
							{
								id: 945,
								name: "Rukomo ",
								sector_id: 40211,
								courtOfCells: [
									{
										id: 5395,
										name: "BUKAMBA",
										generalAssembly_id: 945,
									},
									{
										id: 5396,
										name: "GAHURURA",
										generalAssembly_id: 945,
									},
									{
										id: 5397,
										name: "MUTURIRWA",
										generalAssembly_id: 945,
									},
									{
										id: 5398,
										name: "RUKOMO I",
										generalAssembly_id: 945,
									},
									{
										id: 5399,
										name: "RUKOMO II",
										generalAssembly_id: 945,
									},
									{
										id: 5400,
										name: "RUYONZA",
										generalAssembly_id: 945,
									},
									{
										id: 9920,
										name: "Rukomo  Court of Appeal ",
										generalAssembly_id: 945,
									},
									{
										id: 11453,
										name: "Rukomo  Gacaca court of sector ",
										generalAssembly_id: 945,
									},
								],
							},
						],
					},
					{
						id: 40212,
						name: "RWEMPASHA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4021210,
								name: "Ryeru",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021209,
								name: "Rwempasha",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021208,
								name: "Rutare",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021207,
								name: "Rukorota",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021206,
								name: "Rugarama",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021205,
								name: "Mishenyi",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021204,
								name: "Kazaza",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021203,
								name: "Kabare",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021202,
								name: "Gasinga",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021201,
								name: "Cyenjonjo",
								sector_id: 40212,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 946,
								name: "Bweya ",
								sector_id: 40212,
								courtOfCells: [
									{
										id: 5401,
										name: "BWEYA",
										generalAssembly_id: 946,
									},
									{
										id: 5402,
										name: "GICWAMBA",
										generalAssembly_id: 946,
									},
									{
										id: 5403,
										name: "MISHENYI",
										generalAssembly_id: 946,
									},
									{
										id: 5404,
										name: "RUHIRIMA",
										generalAssembly_id: 946,
									},
									{
										id: 5405,
										name: "RYERU",
										generalAssembly_id: 946,
									},
									{
										id: 9921,
										name: "Bweya  Court of Appeal ",
										generalAssembly_id: 946,
									},
									{
										id: 11454,
										name: "Bweya  Gacaca court of sector ",
										generalAssembly_id: 946,
									},
								],
							},
							{
								id: 947,
								name: "Cyenjojo ",
								sector_id: 40212,
								courtOfCells: [
									{
										id: 5406,
										name: "CYENJOJO",
										generalAssembly_id: 947,
									},
									{
										id: 5407,
										name: "CYENJOJO II",
										generalAssembly_id: 947,
									},
									{
										id: 5408,
										name: "MASHAKA",
										generalAssembly_id: 947,
									},
									{
										id: 5409,
										name: "NSHULI",
										generalAssembly_id: 947,
									},
									{
										id: 5410,
										name: "RUTARE I",
										generalAssembly_id: 947,
									},
									{
										id: 5411,
										name: "RUTARE II",
										generalAssembly_id: 947,
									},
									{
										id: 9922,
										name: "Cyenjojo  Court of Appeal ",
										generalAssembly_id: 947,
									},
									{
										id: 11455,
										name: "Cyenjojo  Gacaca court of sector ",
										generalAssembly_id: 947,
									},
								],
							},
							{
								id: 948,
								name: "Kabare ",
								sector_id: 40212,
								courtOfCells: [
									{
										id: 5412,
										name: "GITURO",
										generalAssembly_id: 948,
									},
									{
										id: 5413,
										name: "KABARE",
										generalAssembly_id: 948,
									},
									{
										id: 5414,
										name: "KABARE II",
										generalAssembly_id: 948,
									},
									{
										id: 5415,
										name: "GASINGA",
										generalAssembly_id: 948,
									},
									{
										id: 5416,
										name: "RWEBISHOROGOTO",
										generalAssembly_id: 948,
									},
									{
										id: 5417,
										name: "KIJOJO",
										generalAssembly_id: 948,
									},
									{
										id: 9923,
										name: "Kabare  Court of Appeal ",
										generalAssembly_id: 948,
									},
									{
										id: 11456,
										name: "Kabare  Gacaca court of sector ",
										generalAssembly_id: 948,
									},
								],
							},
							{
								id: 949,
								name: "Kazaza ",
								sector_id: 40212,
								courtOfCells: [
									{
										id: 5418,
										name: "BUBARE",
										generalAssembly_id: 949,
									},
									{
										id: 5419,
										name: "GAKINDO",
										generalAssembly_id: 949,
									},
									{
										id: 5420,
										name: "KAZAZA",
										generalAssembly_id: 949,
									},
									{
										id: 5421,
										name: "RUGARAMA",
										generalAssembly_id: 949,
									},
									{
										id: 5422,
										name: "RUGARAMA II",
										generalAssembly_id: 949,
									},
									{
										id: 5423,
										name: "GAHINDO I",
										generalAssembly_id: 949,
									},
									{
										id: 5424,
										name: "GAHINDO II",
										generalAssembly_id: 949,
									},
									{
										id: 9924,
										name: "Kazaza  Court of Appeal ",
										generalAssembly_id: 949,
									},
									{
										id: 11457,
										name: "Kazaza  Gacaca court of sector ",
										generalAssembly_id: 949,
									},
								],
							},
							{
								id: 950,
								name: "Rwempasha ",
								sector_id: 40212,
								courtOfCells: [
									{
										id: 5425,
										name: "BUKONJI",
										generalAssembly_id: 950,
									},
									{
										id: 5426,
										name: "RUKOROTA",
										generalAssembly_id: 950,
									},
									{
										id: 5427,
										name: "RUKOROTA II",
										generalAssembly_id: 950,
									},
									{
										id: 5428,
										name: "RWAHI",
										generalAssembly_id: 950,
									},
									{
										id: 5429,
										name: "RWEMPASHA",
										generalAssembly_id: 950,
									},
									{
										id: 5430,
										name: "UWINKIKO",
										generalAssembly_id: 950,
									},
									{
										id: 5431,
										name: "NYARUBARE",
										generalAssembly_id: 950,
									},
									{
										id: 9925,
										name: "Rwempasha  Court of Appeal ",
										generalAssembly_id: 950,
									},
									{
										id: 11458,
										name: "Rwempasha  Gacaca court of sector ",
										generalAssembly_id: 950,
									},
								],
							},
						],
					},
					{
						id: 40213,
						name: "RWIMIYAGA",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4021308,
								name: "Rwimiyaga",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021307,
								name: "Rutungu",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021306,
								name: "Nyendo",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021305,
								name: "Nyarupfubire",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021304,
								name: "Ntoma",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021303,
								name: "Kirebe",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021302,
								name: "Kabeza",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021301,
								name: "Gacundezi",
								sector_id: 40213,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 951,
								name: "Bwera ",
								sector_id: 40213,
								courtOfCells: [
									{
										id: 5432,
										name: "KAGERA",
										generalAssembly_id: 951,
									},
									{
										id: 5433,
										name: "RUTUNGU",
										generalAssembly_id: 951,
									},
									{
										id: 9926,
										name: "Bwera  Court of Appeal ",
										generalAssembly_id: 951,
									},
									{
										id: 11459,
										name: "Bwera  Gacaca court of sector ",
										generalAssembly_id: 951,
									},
								],
							},
							{
								id: 952,
								name: "Gacundezi ",
								sector_id: 40213,
								courtOfCells: [
									{
										id: 5434,
										name: "GACUNDEZI",
										generalAssembly_id: 952,
									},
									{
										id: 5435,
										name: "GACUNDEZI II",
										generalAssembly_id: 952,
									},
									{
										id: 5436,
										name: "KABEZA",
										generalAssembly_id: 952,
									},
									{
										id: 5437,
										name: "RUKUNDO",
										generalAssembly_id: 952,
									},
									{
										id: 9927,
										name: "Gacundezi  Court of Appeal ",
										generalAssembly_id: 952,
									},
									{
										id: 11460,
										name: "Gacundezi  Gacaca court of sector ",
										generalAssembly_id: 952,
									},
								],
							},
							{
								id: 953,
								name: "Nyarupfubire ",
								sector_id: 40213,
								courtOfCells: [
									{
										id: 5438,
										name: "KAMAGIRI",
										generalAssembly_id: 953,
									},
									{
										id: 5439,
										name: "NYAKAGANDO",
										generalAssembly_id: 953,
									},
									{
										id: 5440,
										name: "NYARUPFUBIRE",
										generalAssembly_id: 953,
									},
									{
										id: 5441,
										name: "RWIMIYAGA",
										generalAssembly_id: 953,
									},
									{
										id: 9928,
										name: "Nyarupfubire  Court of Appeal ",
										generalAssembly_id: 953,
									},
									{
										id: 11461,
										name: "Nyarupfubire  Gacaca court of sector ",
										generalAssembly_id: 953,
									},
								],
							},
							{
								id: 954,
								name: "Kirebe ",
								sector_id: 40213,
								courtOfCells: [
									{
										id: 5442,
										name: "GATEBE-KIREBE-RUKINDO",
										generalAssembly_id: 954,
									},
									{
										id: 9929,
										name: "Kirebe  Court of Appeal ",
										generalAssembly_id: 954,
									},
									{
										id: 11462,
										name: "Kirebe  Gacaca court of sector ",
										generalAssembly_id: 954,
									},
								],
							},
							{
								id: 955,
								name: "Rwimiyaga",
								sector_id: 40213,
								courtOfCells: [
									{
										id: 5443,
										name: "GASHWENU",
										generalAssembly_id: 955,
									},
									{
										id: 5444,
										name: "KANYEGANYEGE",
										generalAssembly_id: 955,
									},
									{
										id: 5445,
										name: "NTOMA",
										generalAssembly_id: 955,
									},
									{
										id: 5446,
										name: "NYENDO",
										generalAssembly_id: 955,
									},
									{
										id: 5447,
										name: "RWIMIYAGA I",
										generalAssembly_id: 955,
									},
									{
										id: 5448,
										name: "RWIMIYAGA II",
										generalAssembly_id: 955,
									},
									{
										id: 5449,
										name: "RWIMIYAGA III",
										generalAssembly_id: 955,
									},
									{
										id: 9930,
										name: "Rwimiyaga Court of Appeal ",
										generalAssembly_id: 955,
									},
									{
										id: 11463,
										name: "Rwimiyaga Gacaca court of sector ",
										generalAssembly_id: 955,
									},
								],
							},
						],
					},
					{
						id: 40214,
						name: "TABAGWE",
						district_id: 402,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 4021407,
								name: "Tabagwe",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021406,
								name: "Shonga",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021405,
								name: "Nyagatoma",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021404,
								name: "Nyabitekeri",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021403,
								name: "Nkoma",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021402,
								name: "Gitengure",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4021401,
								name: "Gishuro",
								sector_id: 40214,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 956,
								name: "Shonga ",
								sector_id: 40214,
								courtOfCells: [
									{
										id: 5450,
										name: "GATURA",
										generalAssembly_id: 956,
									},
									{
										id: 5451,
										name: "GIKOBA",
										generalAssembly_id: 956,
									},
									{
										id: 5452,
										name: "KABOROGOTA",
										generalAssembly_id: 956,
									},
									{
										id: 5453,
										name: "MUTOJO",
										generalAssembly_id: 956,
									},
									{
										id: 5454,
										name: "NKOMA",
										generalAssembly_id: 956,
									},
									{
										id: 5455,
										name: "NYAKIGANDO",
										generalAssembly_id: 956,
									},
									{
										id: 5456,
										name: "RUNYINYA",
										generalAssembly_id: 956,
									},
									{
										id: 9931,
										name: "Shonga  Court of Appeal ",
										generalAssembly_id: 956,
									},
									{
										id: 11464,
										name: "Shonga  Gacaca court of sector ",
										generalAssembly_id: 956,
									},
								],
							},
							{
								id: 957,
								name: "Tabagwe",
								sector_id: 40214,
								courtOfCells: [
									{
										id: 5457,
										name: "GASHESHE",
										generalAssembly_id: 957,
									},
									{
										id: 5458,
										name: "GISHURO",
										generalAssembly_id: 957,
									},
									{
										id: 5459,
										name: "RUBIRIZI-KANGOMA",
										generalAssembly_id: 957,
									},
									{
										id: 5460,
										name: "KAYIGIRO",
										generalAssembly_id: 957,
									},
									{
										id: 5461,
										name: "KIYUMBU-MIRAMBI",
										generalAssembly_id: 957,
									},
									{
										id: 5462,
										name: "NYABITEKERI",
										generalAssembly_id: 957,
									},
									{
										id: 5463,
										name: "NYAGASIGATI-NSHURI",
										generalAssembly_id: 957,
									},
									{
										id: 5464,
										name: "TABAGWE",
										generalAssembly_id: 957,
									},
									{
										id: 9932,
										name: "Tabagwe Court of Appeal ",
										generalAssembly_id: 957,
									},
									{
										id: 11465,
										name: "Tabagwe Gacaca court of sector ",
										generalAssembly_id: 957,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 401,
				name: "Rwamagana",
				province_id: 4,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 40101,
						name: "Fumbwe",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010106,
								name: "Sasabirago",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010105,
								name: "Nyarubuye",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010104,
								name: "Nyamirama",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010103,
								name: "Nyakagunga",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010102,
								name: "Nyagasambu",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010101,
								name: "Mununu",
								sector_id: 40101,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1493,
								name: "Fumbwe",
								sector_id: 40101,
								courtOfCells: [
									{
										id: 8670,
										name: "AGATARE",
										generalAssembly_id: 1493,
									},
									{
										id: 8671,
										name: "BIGARAMA",
										generalAssembly_id: 1493,
									},
									{
										id: 8672,
										name: "BIREMBO",
										generalAssembly_id: 1493,
									},
									{
										id: 8673,
										name: "BYIMANA",
										generalAssembly_id: 1493,
									},
									{
										id: 8674,
										name: "CYARUTABANA",
										generalAssembly_id: 1493,
									},
									{
										id: 8675,
										name: "KIBAZA-RUGARAMA",
										generalAssembly_id: 1493,
									},
									{
										id: 8676,
										name: "KARAMBO",
										generalAssembly_id: 1493,
									},
									{
										id: 8677,
										name: "KIREHE",
										generalAssembly_id: 1493,
									},
									{
										id: 8678,
										name: "MAKWANDI",
										generalAssembly_id: 1493,
									},
									{
										id: 8679,
										name: "MUNINI",
										generalAssembly_id: 1493,
									},
									{
										id: 8680,
										name: "NTUNGAMO",
										generalAssembly_id: 1493,
									},
									{
										id: 8681,
										name: "NYAKAGUNGA",
										generalAssembly_id: 1493,
									},
									{
										id: 8682,
										name: "NYAMIRAMA",
										generalAssembly_id: 1493,
									},
									{
										id: 8683,
										name: "SASABIRAGO",
										generalAssembly_id: 1493,
									},
									{
										id: 10467,
										name: "Fumbwe Court of Appeal ",
										generalAssembly_id: 1493,
									},
									{
										id: 12000,
										name: "Fumbwe Gacaca court of sector ",
										generalAssembly_id: 1493,
									},
								],
							},
							{
								id: 1494,
								name: "Mununu",
								sector_id: 40101,
								courtOfCells: [
									{
										id: 8684,
										name: "GITWE",
										generalAssembly_id: 1494,
									},
									{
										id: 8685,
										name: "JANJAGIRO",
										generalAssembly_id: 1494,
									},
									{
										id: 8686,
										name: "KABEZA",
										generalAssembly_id: 1494,
									},
									{
										id: 8687,
										name: "KABIRIZI",
										generalAssembly_id: 1494,
									},
									{
										id: 8688,
										name: "CYINGARA",
										generalAssembly_id: 1494,
									},
									{
										id: 8689,
										name: "KIVUGIZA",
										generalAssembly_id: 1494,
									},
									{
										id: 8690,
										name: "MATABA-RUGARAMA",
										generalAssembly_id: 1494,
									},
									{
										id: 8691,
										name: "MUNUNU",
										generalAssembly_id: 1494,
									},
									{
										id: 8692,
										name: "MURAMBI",
										generalAssembly_id: 1494,
									},
									{
										id: 8693,
										name: "NDINDA",
										generalAssembly_id: 1494,
									},
									{
										id: 8694,
										name: "NYAGASAMBU",
										generalAssembly_id: 1494,
									},
									{
										id: 8695,
										name: "NYARUBUYE",
										generalAssembly_id: 1494,
									},
									{
										id: 8696,
										name: "NYIRABITERE",
										generalAssembly_id: 1494,
									},
									{
										id: 8697,
										name: "RUGENGE",
										generalAssembly_id: 1494,
									},
									{
										id: 10468,
										name: "Mununu Court of Appeal ",
										generalAssembly_id: 1494,
									},
									{
										id: 12001,
										name: "Mununu Gacaca court of sector ",
										generalAssembly_id: 1494,
									},
								],
							},
						],
					},
					{
						id: 40102,
						name: "Gahengeri",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010208,
								name: "Rweri",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010207,
								name: "Runyinya",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010206,
								name: "Rugarama",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010205,
								name: "Mutamwa",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010204,
								name: "Kibare",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010203,
								name: "Kanyangese",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010202,
								name: "Kagezi",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010201,
								name: "Gihumuza",
								sector_id: 40102,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1495,
								name: "Gahengeri",
								sector_id: 40102,
								courtOfCells: [
									{
										id: 8698,
										name: "GIHUMUZA",
										generalAssembly_id: 1495,
									},
									{
										id: 8699,
										name: "KABIGONDO",
										generalAssembly_id: 1495,
									},
									{
										id: 8700,
										name: "KARUTIMBO",
										generalAssembly_id: 1495,
									},
									{
										id: 8701,
										name: "KIBARE",
										generalAssembly_id: 1495,
									},
									{
										id: 8702,
										name: "KINYOVI",
										generalAssembly_id: 1495,
									},
									{
										id: 8703,
										name: "RWAMASHYONGOSHYO",
										generalAssembly_id: 1495,
									},
									{
										id: 8704,
										name: "RWARUGAJU",
										generalAssembly_id: 1495,
									},
									{
										id: 8705,
										name: "RWERI",
										generalAssembly_id: 1495,
									},
									{
										id: 8706,
										name: "SAMATARE",
										generalAssembly_id: 1495,
									},
									{
										id: 10469,
										name: "Gahengeri Court of Appeal ",
										generalAssembly_id: 1495,
									},
									{
										id: 12002,
										name: "Gahengeri Gacaca court of sector ",
										generalAssembly_id: 1495,
									},
								],
							},
							{
								id: 1496,
								name: "Runyinya",
								sector_id: 40102,
								courtOfCells: [
									{
										id: 8707,
										name: "BYIMANA-NYARUCYAMO",
										generalAssembly_id: 1496,
									},
									{
										id: 8708,
										name: "CYERU",
										generalAssembly_id: 1496,
									},
									{
										id: 8709,
										name: "KIYOVU-GACUNSHU",
										generalAssembly_id: 1496,
									},
									{
										id: 8710,
										name: "RUGARAMA I-RUGARAMA II",
										generalAssembly_id: 1496,
									},
									{
										id: 8711,
										name: "RUYUMBA",
										generalAssembly_id: 1496,
									},
									{
										id: 8712,
										name: "RYAMUZUKA",
										generalAssembly_id: 1496,
									},
									{
										id: 10470,
										name: "Runyinya Court of Appeal ",
										generalAssembly_id: 1496,
									},
									{
										id: 12003,
										name: "Runyinya Gacaca court of sector ",
										generalAssembly_id: 1496,
									},
								],
							},
							{
								id: 1497,
								name: "Rwamashyongoshyo",
								sector_id: 40102,
								courtOfCells: [
									{
										id: 8713,
										name: "KAMUGASA",
										generalAssembly_id: 1497,
									},
									{
										id: 8714,
										name: "KAMURINDI",
										generalAssembly_id: 1497,
									},
									{
										id: 8715,
										name: "KANYANGESE",
										generalAssembly_id: 1497,
									},
									{
										id: 8716,
										name: "MUTAMWA",
										generalAssembly_id: 1497,
									},
									{
										id: 8717,
										name: "NYABAGAZA",
										generalAssembly_id: 1497,
									},
									{
										id: 8718,
										name: "NYIRABUJARI-RUGARAMA",
										generalAssembly_id: 1497,
									},
									{
										id: 8719,
										name: "RUBONOBONO",
										generalAssembly_id: 1497,
									},
									{
										id: 8720,
										name: "RUGAGI",
										generalAssembly_id: 1497,
									},
									{
										id: 8721,
										name: "RUHITA",
										generalAssembly_id: 1497,
									},
									{
										id: 8722,
										name: "RWISHYO",
										generalAssembly_id: 1497,
									},
									{
										id: 8723,
										name: "RWAMASHYONGOSHYO",
										generalAssembly_id: 1497,
									},
									{
										id: 10471,
										name: "Rwamashyongoshyo Court of Appeal ",
										generalAssembly_id: 1497,
									},
									{
										id: 12004,
										name: "Rwamashyongoshyo Gacaca court of sector ",
										generalAssembly_id: 1497,
									},
								],
							},
						],
					},
					{
						id: 40103,
						name: "Gishali",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010307,
								name: "Ruhunda",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010306,
								name: "Ruhimbi",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010305,
								name: "Kavumu",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010304,
								name: "Gati",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010303,
								name: "Cyinyana",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010302,
								name: "Bwinsanga",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010301,
								name: "Binunga",
								sector_id: 40103,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1498,
								name: "Gati",
								sector_id: 40103,
								courtOfCells: [
									{
										id: 8724,
										name: "AGATARE",
										generalAssembly_id: 1498,
									},
									{
										id: 8725,
										name: "AKABUGA",
										generalAssembly_id: 1498,
									},
									{
										id: 8726,
										name: "INGEYO",
										generalAssembly_id: 1498,
									},
									{
										id: 8727,
										name: "KIBONDE",
										generalAssembly_id: 1498,
									},
									{
										id: 8728,
										name: "NYAMABUYE",
										generalAssembly_id: 1498,
									},
									{
										id: 8729,
										name: "UMUNANIRA",
										generalAssembly_id: 1498,
									},
									{
										id: 8730,
										name: "URUHUHA",
										generalAssembly_id: 1498,
									},
									{
										id: 8731,
										name: "URUYENZI",
										generalAssembly_id: 1498,
									},
									{
										id: 10472,
										name: "Gati Court of Appeal ",
										generalAssembly_id: 1498,
									},
									{
										id: 12005,
										name: "Gati Gacaca court of sector ",
										generalAssembly_id: 1498,
									},
								],
							},
							{
								id: 1499,
								name: "Gishari",
								sector_id: 40103,
								courtOfCells: [
									{
										id: 8732,
										name: "AKANOGO",
										generalAssembly_id: 1499,
									},
									{
										id: 8733,
										name: "BWINSANGA",
										generalAssembly_id: 1499,
									},
									{
										id: 8734,
										name: "CYIRI",
										generalAssembly_id: 1499,
									},
									{
										id: 8735,
										name: "MUGUSHA",
										generalAssembly_id: 1499,
									},
									{
										id: 8736,
										name: "RWAGAHAYA",
										generalAssembly_id: 1499,
									},
									{
										id: 8737,
										name: "SHABURONDO",
										generalAssembly_id: 1499,
									},
									{
										id: 10473,
										name: "Gishari Court of Appeal ",
										generalAssembly_id: 1499,
									},
									{
										id: 12006,
										name: "Gishari Gacaca court of sector ",
										generalAssembly_id: 1499,
									},
								],
							},
							{
								id: 1500,
								name: "Nyarugari",
								sector_id: 40103,
								courtOfCells: [
									{
										id: 8738,
										name: "ABAKINA",
										generalAssembly_id: 1500,
									},
									{
										id: 8739,
										name: "CYINYANA",
										generalAssembly_id: 1500,
									},
									{
										id: 8740,
										name: "NYAGACYAMO",
										generalAssembly_id: 1500,
									},
									{
										id: 8741,
										name: "NYAKAGARAMA",
										generalAssembly_id: 1500,
									},
									{
										id: 8742,
										name: "MUNINI",
										generalAssembly_id: 1500,
									},
									{
										id: 10474,
										name: "Nyarugari Court of Appeal ",
										generalAssembly_id: 1500,
									},
									{
										id: 12007,
										name: "Nyarugari Gacaca court of sector ",
										generalAssembly_id: 1500,
									},
								],
							},
							{
								id: 1501,
								name: "Ruhunda",
								sector_id: 40103,
								courtOfCells: [
									{
										id: 8743,
										name: "BINUNGA",
										generalAssembly_id: 1501,
									},
									{
										id: 8744,
										name: "MPUNGWE",
										generalAssembly_id: 1501,
									},
									{
										id: 8745,
										name: "NYAGAHINGA",
										generalAssembly_id: 1501,
									},
									{
										id: 8746,
										name: "NYAGAKOMBE",
										generalAssembly_id: 1501,
									},
									{
										id: 8747,
										name: "RURINDIMURA",
										generalAssembly_id: 1501,
									},
									{
										id: 10475,
										name: "Ruhunda Court of Appeal ",
										generalAssembly_id: 1501,
									},
									{
										id: 12008,
										name: "Ruhunda Gacaca court of sector ",
										generalAssembly_id: 1501,
									},
								],
							},
						],
					},
					{
						id: 40104,
						name: "Karenge",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010407,
								name: "Nyamatete",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010406,
								name: "Nyabubare",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010405,
								name: "Karenge",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010404,
								name: "Kangamba",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010403,
								name: "Kabasore",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010402,
								name: "Byimana",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010401,
								name: "Bicaca",
								sector_id: 40104,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1502,
								name: "Karenge ",
								sector_id: 40104,
								courtOfCells: [
									{
										id: 8748,
										name: "KABASORE",
										generalAssembly_id: 1502,
									},
									{
										id: 8749,
										name: "KANYANGESE",
										generalAssembly_id: 1502,
									},
									{
										id: 8750,
										name: "KARENGE",
										generalAssembly_id: 1502,
									},
									{
										id: 8751,
										name: "MIGAMBA",
										generalAssembly_id: 1502,
									},
									{
										id: 8752,
										name: "NYABUBARE",
										generalAssembly_id: 1502,
									},
									{
										id: 8753,
										name: "NYAGATOVU",
										generalAssembly_id: 1502,
									},
									{
										id: 8754,
										name: "RUVOMO",
										generalAssembly_id: 1502,
									},
									{
										id: 10476,
										name: "Karenge  Court of Appeal ",
										generalAssembly_id: 1502,
									},
									{
										id: 12009,
										name: "Karenge  Gacaca court of sector ",
										generalAssembly_id: 1502,
									},
								],
							},
							{
								id: 1503,
								name: "Nyamatete",
								sector_id: 40104,
								courtOfCells: [
									{
										id: 8755,
										name: "BICACA",
										generalAssembly_id: 1503,
									},
									{
										id: 8756,
										name: "CYARUGAJU",
										generalAssembly_id: 1503,
									},
									{
										id: 8757,
										name: "NDARAGE",
										generalAssembly_id: 1503,
									},
									{
										id: 8758,
										name: "NYABUGONDO",
										generalAssembly_id: 1503,
									},
									{
										id: 8759,
										name: "NYAGASENYI",
										generalAssembly_id: 1503,
									},
									{
										id: 8760,
										name: "NYAKABUYE",
										generalAssembly_id: 1503,
									},
									{
										id: 8761,
										name: "NYAMATETE",
										generalAssembly_id: 1503,
									},
									{
										id: 10477,
										name: "Nyamatete Court of Appeal ",
										generalAssembly_id: 1503,
									},
									{
										id: 12010,
										name: "Nyamatete Gacaca court of sector ",
										generalAssembly_id: 1503,
									},
								],
							},
							{
								id: 1504,
								name: "Rukori",
								sector_id: 40104,
								courtOfCells: [
									{
										id: 8762,
										name: "BYIMANA",
										generalAssembly_id: 1504,
									},
									{
										id: 8763,
										name: "KANGAMBA",
										generalAssembly_id: 1504,
									},
									{
										id: 8764,
										name: "KIMARAMBASA",
										generalAssembly_id: 1504,
									},
									{
										id: 8765,
										name: "KIYAYA",
										generalAssembly_id: 1504,
									},
									{
										id: 8766,
										name: "NKONGI",
										generalAssembly_id: 1504,
									},
									{
										id: 8767,
										name: "RUKANKAMA",
										generalAssembly_id: 1504,
									},
									{
										id: 8768,
										name: "RUKORI",
										generalAssembly_id: 1504,
									},
									{
										id: 8769,
										name: "RWINKA",
										generalAssembly_id: 1504,
									},
									{
										id: 10478,
										name: "Rukori Court of Appeal ",
										generalAssembly_id: 1504,
									},
									{
										id: 12011,
										name: "Rukori Gacaca court of sector ",
										generalAssembly_id: 1504,
									},
								],
							},
						],
					},
					{
						id: 40105,
						name: "Kigabiro",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010505,
								name: "Sovu",
								sector_id: 40105,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010504,
								name: "Sibagire",
								sector_id: 40105,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010503,
								name: "Nyagasenyi",
								sector_id: 40105,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010502,
								name: "Cyanya",
								sector_id: 40105,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010501,
								name: "Bwiza",
								sector_id: 40105,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1505,
								name: "Kigabiro",
								sector_id: 40105,
								courtOfCells: [
									{
										id: 8770,
										name: "CYANYA",
										generalAssembly_id: 1505,
									},
									{
										id: 8771,
										name: "KABUGA",
										generalAssembly_id: 1505,
									},
									{
										id: 8772,
										name: "KARUHAYI",
										generalAssembly_id: 1505,
									},
									{
										id: 8773,
										name: "KARUTIMBO",
										generalAssembly_id: 1505,
									},
									{
										id: 10479,
										name: "Kigabiro Court of Appeal ",
										generalAssembly_id: 1505,
									},
									{
										id: 12012,
										name: "Kigabiro Gacaca court of sector ",
										generalAssembly_id: 1505,
									},
								],
							},
							{
								id: 1506,
								name: "Rutonde",
								sector_id: 40105,
								courtOfCells: [
									{
										id: 8774,
										name: "BWIZA",
										generalAssembly_id: 1506,
									},
									{
										id: 8775,
										name: "GITEGA",
										generalAssembly_id: 1506,
									},
									{
										id: 8776,
										name: "KIGARAMA",
										generalAssembly_id: 1506,
									},
									{
										id: 8777,
										name: "MUNINI",
										generalAssembly_id: 1506,
									},
									{
										id: 8778,
										name: "MUNINI-II",
										generalAssembly_id: 1506,
									},
									{
										id: 10480,
										name: "Rutonde Court of Appeal ",
										generalAssembly_id: 1506,
									},
									{
										id: 12013,
										name: "Rutonde Gacaca court of sector ",
										generalAssembly_id: 1506,
									},
								],
							},
							{
								id: 1507,
								name: "Rwikubo",
								sector_id: 40105,
								courtOfCells: [
									{
										id: 8779,
										name: "BACYORO",
										generalAssembly_id: 1507,
									},
									{
										id: 8780,
										name: "BANYAMURIRO",
										generalAssembly_id: 1507,
									},
									{
										id: 8781,
										name: "KABUYE",
										generalAssembly_id: 1507,
									},
									{
										id: 8782,
										name: "MIYANGE",
										generalAssembly_id: 1507,
									},
									{
										id: 8783,
										name: "NYAGASENYI",
										generalAssembly_id: 1507,
									},
									{
										id: 8784,
										name: "NYIRAKADONGO",
										generalAssembly_id: 1507,
									},
									{
										id: 10481,
										name: "Rwikubo Court of Appeal ",
										generalAssembly_id: 1507,
									},
									{
										id: 12014,
										name: "Rwikubo Gacaca court of sector ",
										generalAssembly_id: 1507,
									},
								],
							},
							{
								id: 1508,
								name: "Sovu",
								sector_id: 40105,
								courtOfCells: [
									{
										id: 8785,
										name: "GASHARU",
										generalAssembly_id: 1508,
									},
									{
										id: 8786,
										name: "GATARE",
										generalAssembly_id: 1508,
									},
									{
										id: 8787,
										name: "KABUGA",
										generalAssembly_id: 1508,
									},
									{
										id: 8788,
										name: "NYABISHUNZI",
										generalAssembly_id: 1508,
									},
									{
										id: 8789,
										name: "RUGOBAGOBA",
										generalAssembly_id: 1508,
									},
									{
										id: 8790,
										name: "RUSHANGARA",
										generalAssembly_id: 1508,
									},
									{
										id: 10482,
										name: "Sovu Court of Appeal ",
										generalAssembly_id: 1508,
									},
									{
										id: 12015,
										name: "Sovu Gacaca court of sector ",
										generalAssembly_id: 1508,
									},
								],
							},
						],
					},
					{
						id: 40106,
						name: "Muhazi",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010609,
								name: "Nyarusange",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010608,
								name: "Ntebe",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010607,
								name: "Nsinda",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010606,
								name: "Murambi",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010605,
								name: "Kitazigurwa",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010604,
								name: "Karitutu",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010603,
								name: "Karambi",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010602,
								name: "Kabare",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010601,
								name: "Byeza",
								sector_id: 40106,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1509,
								name: "Nyarusange",
								sector_id: 40106,
								courtOfCells: [
									{
										id: 8791,
										name: "GAHONDO",
										generalAssembly_id: 1509,
									},
									{
										id: 8792,
										name: "KANYWIRIRI",
										generalAssembly_id: 1509,
									},
									{
										id: 8793,
										name: "KARAMBO",
										generalAssembly_id: 1509,
									},
									{
										id: 8794,
										name: "KIDOGO",
										generalAssembly_id: 1509,
									},
									{
										id: 8795,
										name: "MPINGA",
										generalAssembly_id: 1509,
									},
									{
										id: 10483,
										name: "Nyarusange Court of Appeal ",
										generalAssembly_id: 1509,
									},
									{
										id: 12016,
										name: "Nyarusange Gacaca court of sector ",
										generalAssembly_id: 1509,
									},
								],
							},
							{
								id: 1510,
								name: "Kabare",
								sector_id: 40106,
								courtOfCells: [
									{
										id: 8796,
										name: "BYEZA",
										generalAssembly_id: 1510,
									},
									{
										id: 8797,
										name: "GATOBOTOBO",
										generalAssembly_id: 1510,
									},
									{
										id: 8798,
										name: "KIGOGO",
										generalAssembly_id: 1510,
									},
									{
										id: 8799,
										name: "NYARUKOMBE",
										generalAssembly_id: 1510,
									},
									{
										id: 8800,
										name: "RUKOMA",
										generalAssembly_id: 1510,
									},
									{
										id: 10484,
										name: "Kabare Court of Appeal ",
										generalAssembly_id: 1510,
									},
									{
										id: 12017,
										name: "Kabare Gacaca court of sector ",
										generalAssembly_id: 1510,
									},
								],
							},
							{
								id: 1511,
								name: "Kitazigurwa",
								sector_id: 40106,
								courtOfCells: [
									{
										id: 8801,
										name: "BUHANYA",
										generalAssembly_id: 1511,
									},
									{
										id: 8802,
										name: "GASHARU",
										generalAssembly_id: 1511,
									},
									{
										id: 8803,
										name: "KARWIRU",
										generalAssembly_id: 1511,
									},
									{
										id: 8804,
										name: "MWUMA",
										generalAssembly_id: 1511,
									},
									{
										id: 8805,
										name: "NTEBE",
										generalAssembly_id: 1511,
									},
									{
										id: 10485,
										name: "Kitazigurwa Court of Appeal ",
										generalAssembly_id: 1511,
									},
									{
										id: 12018,
										name: "Kitazigurwa Gacaca court of sector ",
										generalAssembly_id: 1511,
									},
								],
							},
							{
								id: 1512,
								name: "Murambi",
								sector_id: 40106,
								courtOfCells: [
									{
										id: 8806,
										name: "BIREMBO",
										generalAssembly_id: 1512,
									},
									{
										id: 8807,
										name: "GASHARU",
										generalAssembly_id: 1512,
									},
									{
										id: 8808,
										name: "KARAMBI",
										generalAssembly_id: 1512,
									},
									{
										id: 8809,
										name: "KAYENZI",
										generalAssembly_id: 1512,
									},
									{
										id: 8810,
										name: "RAGWE",
										generalAssembly_id: 1512,
									},
									{
										id: 8811,
										name: "YABARANDA",
										generalAssembly_id: 1512,
									},
									{
										id: 10486,
										name: "Murambi Court of Appeal ",
										generalAssembly_id: 1512,
									},
									{
										id: 12019,
										name: "Murambi Gacaca court of sector ",
										generalAssembly_id: 1512,
									},
								],
							},
							{
								id: 1513,
								name: "Nsinda",
								sector_id: 40106,
								courtOfCells: [
									{
										id: 8812,
										name: "AGASHARU",
										generalAssembly_id: 1513,
									},
									{
										id: 8813,
										name: "AGATARE",
										generalAssembly_id: 1513,
									},
									{
										id: 8814,
										name: "KARITUTU",
										generalAssembly_id: 1513,
									},
									{
										id: 8815,
										name: "KIBARE",
										generalAssembly_id: 1513,
									},
									{
										id: 8816,
										name: "RUBIRIZI",
										generalAssembly_id: 1513,
									},
									{
										id: 10487,
										name: "Nsinda Court of Appeal ",
										generalAssembly_id: 1513,
									},
									{
										id: 12020,
										name: "Nsinda Gacaca court of sector ",
										generalAssembly_id: 1513,
									},
								],
							},
						],
					},
					{
						id: 40107,
						name: "Munyaga",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010704,
								name: "Zinga",
								sector_id: 40107,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010703,
								name: "Rweru",
								sector_id: 40107,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010702,
								name: "Nkungu",
								sector_id: 40107,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010701,
								name: "Kaduha",
								sector_id: 40107,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1514,
								name: "Kaduha",
								sector_id: 40107,
								courtOfCells: [
									{
										id: 8817,
										name: "GISHIKE",
										generalAssembly_id: 1514,
									},
									{
										id: 8818,
										name: "KABABERO",
										generalAssembly_id: 1514,
									},
									{
										id: 8819,
										name: "KABARE",
										generalAssembly_id: 1514,
									},
									{
										id: 8820,
										name: "KAMAMANA",
										generalAssembly_id: 1514,
									},
									{
										id: 8821,
										name: "KANGABO",
										generalAssembly_id: 1514,
									},
									{
										id: 8822,
										name: "RWIMBOGO",
										generalAssembly_id: 1514,
									},
									{
										id: 10488,
										name: "Kaduha Court of Appeal ",
										generalAssembly_id: 1514,
									},
									{
										id: 12021,
										name: "Kaduha Gacaca court of sector ",
										generalAssembly_id: 1514,
									},
								],
							},
							{
								id: 1515,
								name: "Nkungu",
								sector_id: 40107,
								courtOfCells: [
									{
										id: 8823,
										name: "KABUYE",
										generalAssembly_id: 1515,
									},
									{
										id: 8824,
										name: "MATABA",
										generalAssembly_id: 1515,
									},
									{
										id: 8825,
										name: "NYAGAKOMBE",
										generalAssembly_id: 1515,
									},
									{
										id: 8826,
										name: "RUDASHYA",
										generalAssembly_id: 1515,
									},
									{
										id: 8827,
										name: "RUSHANGARA",
										generalAssembly_id: 1515,
									},
									{
										id: 10489,
										name: "Nkungu Court of Appeal ",
										generalAssembly_id: 1515,
									},
									{
										id: 12022,
										name: "Nkungu Gacaca court of sector ",
										generalAssembly_id: 1515,
									},
								],
							},
							{
								id: 1516,
								name: "Rweru",
								sector_id: 40107,
								courtOfCells: [
									{
										id: 8828,
										name: "BIRAYI",
										generalAssembly_id: 1516,
									},
									{
										id: 8829,
										name: "CYINGANZWA",
										generalAssembly_id: 1516,
									},
									{
										id: 8830,
										name: "GATARE",
										generalAssembly_id: 1516,
									},
									{
										id: 8831,
										name: "KABAZEYI",
										generalAssembly_id: 1516,
									},
									{
										id: 8832,
										name: "KABINGO",
										generalAssembly_id: 1516,
									},
									{
										id: 8833,
										name: "KANYEGERA",
										generalAssembly_id: 1516,
									},
									{
										id: 8834,
										name: "MUBUGA",
										generalAssembly_id: 1516,
									},
									{
										id: 8835,
										name: "RWISANGE",
										generalAssembly_id: 1516,
									},
									{
										id: 8836,
										name: "ZINGA",
										generalAssembly_id: 1516,
									},
									{
										id: 10490,
										name: "Rweru Court of Appeal ",
										generalAssembly_id: 1516,
									},
									{
										id: 12023,
										name: "Rweru Gacaca court of sector ",
										generalAssembly_id: 1516,
									},
								],
							},
						],
					},
					{
						id: 40108,
						name: "Munyiginya",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010806,
								name: "Nyarubuye",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010805,
								name: "Nkomangwa",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010804,
								name: "Cyimbazi",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010803,
								name: "Cyarukamba",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010802,
								name: "Bwana",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010801,
								name: "Binunga",
								sector_id: 40108,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1517,
								name: "Cyimbazi",
								sector_id: 40108,
								courtOfCells: [
									{
										id: 8837,
										name: "CYIMBAZI-AGATARE",
										generalAssembly_id: 1517,
									},
									{
										id: 8838,
										name: "AKABUYE",
										generalAssembly_id: 1517,
									},
									{
										id: 8839,
										name: "NDAGO-CYARUKAMBA",
										generalAssembly_id: 1517,
									},
									{
										id: 8840,
										name: "NTUNGA",
										generalAssembly_id: 1517,
									},
									{
										id: 8841,
										name: "NYAGAKOMBE",
										generalAssembly_id: 1517,
									},
									{
										id: 8842,
										name: "RWEZA",
										generalAssembly_id: 1517,
									},
									{
										id: 10491,
										name: "Cyimbazi Court of Appeal ",
										generalAssembly_id: 1517,
									},
									{
										id: 12024,
										name: "Cyimbazi Gacaca court of sector ",
										generalAssembly_id: 1517,
									},
								],
							},
							{
								id: 1518,
								name: "Munyiginya",
								sector_id: 40108,
								courtOfCells: [
									{
										id: 8843,
										name: "AKABATASI",
										generalAssembly_id: 1518,
									},
									{
										id: 8844,
										name: "BINUNGA",
										generalAssembly_id: 1518,
									},
									{
										id: 8845,
										name: "BYARUGINA",
										generalAssembly_id: 1518,
									},
									{
										id: 8846,
										name: "IVIRO",
										generalAssembly_id: 1518,
									},
									{
										id: 8847,
										name: "KACYUMA",
										generalAssembly_id: 1518,
									},
									{
										id: 10492,
										name: "Munyiginya Court of Appeal ",
										generalAssembly_id: 1518,
									},
									{
										id: 12025,
										name: "Munyiginya Gacaca court of sector ",
										generalAssembly_id: 1518,
									},
								],
							},
							{
								id: 1519,
								name: "Nkomangwa",
								sector_id: 40108,
								courtOfCells: [
									{
										id: 8848,
										name: "BAKANNYI",
										generalAssembly_id: 1519,
									},
									{
										id: 8849,
										name: "KABUYE",
										generalAssembly_id: 1519,
									},
									{
										id: 8850,
										name: "KARUBISHA",
										generalAssembly_id: 1519,
									},
									{
										id: 8851,
										name: "NYAGAHANGA",
										generalAssembly_id: 1519,
									},
									{
										id: 8852,
										name: "RYAMIRENGE",
										generalAssembly_id: 1519,
									},
									{
										id: 10493,
										name: "Nkomangwa Court of Appeal ",
										generalAssembly_id: 1519,
									},
									{
										id: 12026,
										name: "Nkomangwa Gacaca court of sector ",
										generalAssembly_id: 1519,
									},
								],
							},
							{
								id: 1520,
								name: "Nyarubuye ",
								sector_id: 40108,
								courtOfCells: [
									{
										id: 8853,
										name: "BABASHA",
										generalAssembly_id: 1520,
									},
									{
										id: 8854,
										name: "BUYANJA",
										generalAssembly_id: 1520,
									},
									{
										id: 8855,
										name: "KIMARA",
										generalAssembly_id: 1520,
									},
									{
										id: 8856,
										name: "KIREBE",
										generalAssembly_id: 1520,
									},
									{
										id: 8857,
										name: "MAZINGA",
										generalAssembly_id: 1520,
									},
									{
										id: 10494,
										name: "Nyarubuye  Court of Appeal ",
										generalAssembly_id: 1520,
									},
									{
										id: 12027,
										name: "Nyarubuye  Gacaca court of sector ",
										generalAssembly_id: 1520,
									},
								],
							},
						],
					},
					{
						id: 40109,
						name: "Musha",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4010906,
								name: "Nyakabanda",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010905,
								name: "Nyabisindu",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010904,
								name: "Musha",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010903,
								name: "Kagarama",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010902,
								name: "Budahanda",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4010901,
								name: "Akabare",
								sector_id: 40109,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1521,
								name: "Duha",
								sector_id: 40109,
								courtOfCells: [
									{
										id: 8858,
										name: "AGASHUHE",
										generalAssembly_id: 1521,
									},
									{
										id: 8859,
										name: "BUDAHIGWA",
										generalAssembly_id: 1521,
									},
									{
										id: 8860,
										name: "BUSANZA",
										generalAssembly_id: 1521,
									},
									{
										id: 8861,
										name: "BWIZA",
										generalAssembly_id: 1521,
									},
									{
										id: 8862,
										name: "GASHIKIRI",
										generalAssembly_id: 1521,
									},
									{
										id: 8863,
										name: "UMUNINI",
										generalAssembly_id: 1521,
									},
									{
										id: 8864,
										name: "NYABISINDU",
										generalAssembly_id: 1521,
									},
									{
										id: 8865,
										name: "RUGABANO",
										generalAssembly_id: 1521,
									},
									{
										id: 8866,
										name: "RUGARAMA",
										generalAssembly_id: 1521,
									},
									{
										id: 8867,
										name: "RUJUMBURA",
										generalAssembly_id: 1521,
									},
									{
										id: 8868,
										name: "RWAMIVU",
										generalAssembly_id: 1521,
									},
									{
										id: 10495,
										name: "Duha Court of Appeal ",
										generalAssembly_id: 1521,
									},
									{
										id: 12028,
										name: "Duha Gacaca court of sector ",
										generalAssembly_id: 1521,
									},
								],
							},
							{
								id: 1522,
								name: "Musha",
								sector_id: 40109,
								courtOfCells: [
									{
										id: 8869,
										name: "BINUNGA",
										generalAssembly_id: 1522,
									},
									{
										id: 8870,
										name: "KABARE",
										generalAssembly_id: 1522,
									},
									{
										id: 8871,
										name: "KAGARAMA",
										generalAssembly_id: 1522,
									},
									{
										id: 8872,
										name: "KIRUHURA",
										generalAssembly_id: 1522,
									},
									{
										id: 8873,
										name: "MBIRIZI",
										generalAssembly_id: 1522,
									},
									{
										id: 8874,
										name: "MUHOGOTO",
										generalAssembly_id: 1522,
									},
									{
										id: 8875,
										name: "MUSHA-GATIKA",
										generalAssembly_id: 1522,
									},
									{
										id: 8876,
										name: "NYAMIGANO-NYAGACYAMO",
										generalAssembly_id: 1522,
									},
									{
										id: 8877,
										name: "RUKOMBE",
										generalAssembly_id: 1522,
									},
									{
										id: 8878,
										name: "RWANKUBA",
										generalAssembly_id: 1522,
									},
									{
										id: 10496,
										name: "Musha Court of Appeal ",
										generalAssembly_id: 1522,
									},
									{
										id: 12029,
										name: "Musha Gacaca court of sector ",
										generalAssembly_id: 1522,
									},
								],
							},
							{
								id: 1523,
								name: "Rutoma",
								sector_id: 40109,
								courtOfCells: [
									{
										id: 8879,
										name: "BITSIBO-NYAKIRIBA",
										generalAssembly_id: 1523,
									},
									{
										id: 8880,
										name: "BUDAHANDA",
										generalAssembly_id: 1523,
									},
									{
										id: 8881,
										name: "GAHAMA",
										generalAssembly_id: 1523,
									},
									{
										id: 8882,
										name: "KARAMBO",
										generalAssembly_id: 1523,
									},
									{
										id: 8883,
										name: "KAYANGE",
										generalAssembly_id: 1523,
									},
									{
										id: 8884,
										name: "MATABA",
										generalAssembly_id: 1523,
									},
									{
										id: 8885,
										name: "MATYAZO",
										generalAssembly_id: 1523,
									},
									{
										id: 8886,
										name: "NYAKABANDA",
										generalAssembly_id: 1523,
									},
									{
										id: 8887,
										name: "NYANTOKI",
										generalAssembly_id: 1523,
									},
									{
										id: 8888,
										name: "RUGARAMA",
										generalAssembly_id: 1523,
									},
									{
										id: 8889,
										name: "RUGWIZA",
										generalAssembly_id: 1523,
									},
									{
										id: 8890,
										name: "RUHITA",
										generalAssembly_id: 1523,
									},
									{
										id: 10497,
										name: "Rutoma Court of Appeal ",
										generalAssembly_id: 1523,
									},
									{
										id: 12030,
										name: "Rutoma Gacaca court of sector ",
										generalAssembly_id: 1523,
									},
								],
							},
						],
					},
					{
						id: 40110,
						name: "Muyumbu",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4011005,
								name: "Nyarukombe",
								sector_id: 40110,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011004,
								name: "Ntebe",
								sector_id: 40110,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011003,
								name: "Murehe",
								sector_id: 40110,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011002,
								name: "Bujyujyu",
								sector_id: 40110,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011001,
								name: "Akinyambo",
								sector_id: 40110,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1524,
								name: "Murehe",
								sector_id: 40110,
								courtOfCells: [
									{
										id: 8891,
										name: "BUJYUJYU",
										generalAssembly_id: 1524,
									},
									{
										id: 8892,
										name: "GATAMBA",
										generalAssembly_id: 1524,
									},
									{
										id: 8893,
										name: "GISHAKA",
										generalAssembly_id: 1524,
									},
									{
										id: 8894,
										name: "KARAMA",
										generalAssembly_id: 1524,
									},
									{
										id: 8895,
										name: "MUREHE",
										generalAssembly_id: 1524,
									},
									{
										id: 8896,
										name: "NYANZA",
										generalAssembly_id: 1524,
									},
									{
										id: 8897,
										name: "RWEZA",
										generalAssembly_id: 1524,
									},
									{
										id: 8898,
										name: "SAMURAMBA",
										generalAssembly_id: 1524,
									},
									{
										id: 10498,
										name: "Murehe Court of Appeal ",
										generalAssembly_id: 1524,
									},
									{
										id: 12031,
										name: "Murehe Gacaca court of sector ",
										generalAssembly_id: 1524,
									},
								],
							},
							{
								id: 1525,
								name: "Muyumbu",
								sector_id: 40110,
								courtOfCells: [
									{
										id: 8899,
										name: "AKINYAMBO",
										generalAssembly_id: 1525,
									},
									{
										id: 8900,
										name: "GAKOMEYE",
										generalAssembly_id: 1525,
									},
									{
										id: 8901,
										name: "GATUZA",
										generalAssembly_id: 1525,
									},
									{
										id: 8902,
										name: "GITARAGA",
										generalAssembly_id: 1525,
									},
									{
										id: 8903,
										name: "GITUZA",
										generalAssembly_id: 1525,
									},
									{
										id: 8904,
										name: "KABAGABO",
										generalAssembly_id: 1525,
									},
									{
										id: 8905,
										name: "KAMPIGIKA",
										generalAssembly_id: 1525,
									},
									{
										id: 8906,
										name: "NTEBE",
										generalAssembly_id: 1525,
									},
									{
										id: 8907,
										name: "NYARUKOMBE",
										generalAssembly_id: 1525,
									},
									{
										id: 8908,
										name: "RUGARAMA",
										generalAssembly_id: 1525,
									},
									{
										id: 8909,
										name: "RYABAHESHWA",
										generalAssembly_id: 1525,
									},
									{
										id: 10499,
										name: "Muyumbu Court of Appeal ",
										generalAssembly_id: 1525,
									},
									{
										id: 12032,
										name: "Muyumbu Gacaca court of sector ",
										generalAssembly_id: 1525,
									},
								],
							},
						],
					},
					{
						id: 40111,
						name: "Mwulire",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4011104,
								name: "Ntunga",
								sector_id: 40111,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011103,
								name: "Mwulire",
								sector_id: 40111,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011102,
								name: "Bushenyi",
								sector_id: 40111,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011101,
								name: "Bicumbi",
								sector_id: 40111,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1526,
								name: "Mwulire",
								sector_id: 40111,
								courtOfCells: [
									{
										id: 8910,
										name: "BUSHENYI",
										generalAssembly_id: 1526,
									},
									{
										id: 8911,
										name: "BYANGE",
										generalAssembly_id: 1526,
									},
									{
										id: 8912,
										name: "CYOMA",
										generalAssembly_id: 1526,
									},
									{
										id: 8913,
										name: "GISANZA",
										generalAssembly_id: 1526,
									},
									{
										id: 8914,
										name: "KABUYA",
										generalAssembly_id: 1526,
									},
									{
										id: 8915,
										name: "KIGABIRO",
										generalAssembly_id: 1526,
									},
									{
										id: 8916,
										name: "MASANDI",
										generalAssembly_id: 1526,
									},
									{
										id: 8917,
										name: "MUNINI",
										generalAssembly_id: 1526,
									},
									{
										id: 8918,
										name: "RUBONA",
										generalAssembly_id: 1526,
									},
									{
										id: 8919,
										name: "BWISANZA",
										generalAssembly_id: 1526,
									},
									{
										id: 10500,
										name: "Mwulire Court of Appeal ",
										generalAssembly_id: 1526,
									},
									{
										id: 12033,
										name: "Mwulire Gacaca court of sector ",
										generalAssembly_id: 1526,
									},
								],
							},
							{
								id: 1527,
								name: "Bicumbi",
								sector_id: 40111,
								courtOfCells: [
									{
										id: 8920,
										name: "KABACUZI",
										generalAssembly_id: 1527,
									},
									{
										id: 8921,
										name: "KARAMA",
										generalAssembly_id: 1527,
									},
									{
										id: 8922,
										name: "MANENE",
										generalAssembly_id: 1527,
									},
									{
										id: 8923,
										name: "NTUNGA",
										generalAssembly_id: 1527,
									},
									{
										id: 8924,
										name: "NYAGIHANGA",
										generalAssembly_id: 1527,
									},
									{
										id: 8925,
										name: "RWARUGAJU",
										generalAssembly_id: 1527,
									},
									{
										id: 10501,
										name: "Bicumbi Court of Appeal ",
										generalAssembly_id: 1527,
									},
									{
										id: 12034,
										name: "Bicumbi Gacaca court of sector ",
										generalAssembly_id: 1527,
									},
								],
							},
						],
					},
					{
						id: 40112,
						name: "Nyakaliro",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4011205,
								name: "Rwimbogo",
								sector_id: 40112,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011204,
								name: "Munini",
								sector_id: 40112,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011203,
								name: "Gishore",
								sector_id: 40112,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011202,
								name: "Gatare",
								sector_id: 40112,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011201,
								name: "Bihembe",
								sector_id: 40112,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1528,
								name: "Bihembe",
								sector_id: 40112,
								courtOfCells: [
									{
										id: 8926,
										name: "BIHEMBE",
										generalAssembly_id: 1528,
									},
									{
										id: 8927,
										name: "CYARUHINDA",
										generalAssembly_id: 1528,
									},
									{
										id: 8928,
										name: "KABERE",
										generalAssembly_id: 1528,
									},
									{
										id: 8929,
										name: "KAMASHAZA",
										generalAssembly_id: 1528,
									},
									{
										id: 8930,
										name: "MUBUMBWE",
										generalAssembly_id: 1528,
									},
									{
										id: 8931,
										name: "RUSHESHE",
										generalAssembly_id: 1528,
									},
									{
										id: 8932,
										name: "RWIMBOGO",
										generalAssembly_id: 1528,
									},
									{
										id: 10502,
										name: "Bihembe Court of Appeal ",
										generalAssembly_id: 1528,
									},
									{
										id: 12035,
										name: "Bihembe Gacaca court of sector ",
										generalAssembly_id: 1528,
									},
								],
							},
							{
								id: 1529,
								name: "Nyakariro",
								sector_id: 40112,
								courtOfCells: [
									{
										id: 8933,
										name: "GATARE",
										generalAssembly_id: 1529,
									},
									{
										id: 8934,
										name: "GISHORE",
										generalAssembly_id: 1529,
									},
									{
										id: 8935,
										name: "KIGINA",
										generalAssembly_id: 1529,
									},
									{
										id: 8936,
										name: "MUNINI",
										generalAssembly_id: 1529,
									},
									{
										id: 8937,
										name: "NYAKAGARAMA",
										generalAssembly_id: 1529,
									},
									{
										id: 8938,
										name: "NYAKALIRO",
										generalAssembly_id: 1529,
									},
									{
										id: 8939,
										name: "NYARUTOVU",
										generalAssembly_id: 1529,
									},
									{
										id: 8940,
										name: "NYIRABUHENE",
										generalAssembly_id: 1529,
									},
									{
										id: 8941,
										name: "RUHANIKA",
										generalAssembly_id: 1529,
									},
									{
										id: 8942,
										name: "SAMUDUHA",
										generalAssembly_id: 1529,
									},
									{
										id: 10503,
										name: "Nyakariro Court of Appeal ",
										generalAssembly_id: 1529,
									},
									{
										id: 12036,
										name: "Nyakariro Gacaca court of sector ",
										generalAssembly_id: 1529,
									},
								],
							},
						],
					},
					{
						id: 40113,
						name: "Nzige",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4011304,
								name: "Rugarama",
								sector_id: 40113,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011303,
								name: "Murama",
								sector_id: 40113,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011302,
								name: "Kigarama",
								sector_id: 40113,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011301,
								name: "Akanzu",
								sector_id: 40113,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1530,
								name: "Murama",
								sector_id: 40113,
								courtOfCells: [
									{
										id: 8943,
										name: "KAYIBANDA",
										generalAssembly_id: 1530,
									},
									{
										id: 8944,
										name: "KIBABARA",
										generalAssembly_id: 1530,
									},
									{
										id: 8945,
										name: "NYARUGENGE",
										generalAssembly_id: 1530,
									},
									{
										id: 8946,
										name: "NYARUSANGE",
										generalAssembly_id: 1530,
									},
									{
										id: 8947,
										name: "NYIRANZABONA",
										generalAssembly_id: 1530,
									},
									{
										id: 8948,
										name: "RUGARAMA",
										generalAssembly_id: 1530,
									},
									{
										id: 8949,
										name: "RWAGATSAMA",
										generalAssembly_id: 1530,
									},
									{
										id: 10504,
										name: "Murama Court of Appeal ",
										generalAssembly_id: 1530,
									},
									{
										id: 12037,
										name: "Murama Gacaca court of sector ",
										generalAssembly_id: 1530,
									},
								],
							},
							{
								id: 1531,
								name: "Nzige",
								sector_id: 40113,
								courtOfCells: [
									{
										id: 8950,
										name: "CYERWA",
										generalAssembly_id: 1531,
									},
									{
										id: 8951,
										name: "GITAMU",
										generalAssembly_id: 1531,
									},
									{
										id: 8952,
										name: "KIGARAMA",
										generalAssembly_id: 1531,
									},
									{
										id: 8953,
										name: "KIJIJI",
										generalAssembly_id: 1531,
									},
									{
										id: 8954,
										name: "MUBUGA",
										generalAssembly_id: 1531,
									},
									{
										id: 8955,
										name: "NZIGE",
										generalAssembly_id: 1531,
									},
									{
										id: 10505,
										name: "Nzige Court of Appeal ",
										generalAssembly_id: 1531,
									},
									{
										id: 12038,
										name: "Nzige Gacaca court of sector ",
										generalAssembly_id: 1531,
									},
								],
							},
						],
					},
					{
						id: 40114,
						name: "Rubona",
						district_id: 401,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 4011406,
								name: "Nawe",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011405,
								name: "Mabare",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011404,
								name: "Karambi",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011403,
								name: "Kabuye",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011402,
								name: "Kabatasi",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 4011401,
								name: "Byinza",
								sector_id: 40114,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1532,
								name: "Mabare",
								sector_id: 40114,
								courtOfCells: [
									{
										id: 8956,
										name: "GASHARU",
										generalAssembly_id: 1532,
									},
									{
										id: 8957,
										name: "KABUYE",
										generalAssembly_id: 1532,
									},
									{
										id: 8958,
										name: "RUBIRIZI",
										generalAssembly_id: 1532,
									},
									{
										id: 8959,
										name: "RUSANZA",
										generalAssembly_id: 1532,
									},
									{
										id: 10506,
										name: "Mabare Court of Appeal ",
										generalAssembly_id: 1532,
									},
									{
										id: 12039,
										name: "Mabare Gacaca court of sector ",
										generalAssembly_id: 1532,
									},
								],
							},
							{
								id: 1533,
								name: "Nawe",
								sector_id: 40114,
								courtOfCells: [
									{
										id: 8960,
										name: "BYOBO",
										generalAssembly_id: 1533,
									},
									{
										id: 8961,
										name: "GASEKE",
										generalAssembly_id: 1533,
									},
									{
										id: 8962,
										name: "KABUYE",
										generalAssembly_id: 1533,
									},
									{
										id: 8963,
										name: "KARAMBI",
										generalAssembly_id: 1533,
									},
									{
										id: 8964,
										name: "RUBUMBA",
										generalAssembly_id: 1533,
									},
									{
										id: 8965,
										name: "RUDASHYA",
										generalAssembly_id: 1533,
									},
									{
										id: 8966,
										name: "RUGARAMA",
										generalAssembly_id: 1533,
									},
									{
										id: 10507,
										name: "Nawe Court of Appeal ",
										generalAssembly_id: 1533,
									},
									{
										id: 12040,
										name: "Nawe Gacaca court of sector ",
										generalAssembly_id: 1533,
									},
								],
							},
							{
								id: 1534,
								name: "Rubona",
								sector_id: 40114,
								courtOfCells: [
									{
										id: 8967,
										name: "BIDUDU",
										generalAssembly_id: 1534,
									},
									{
										id: 8968,
										name: "GITWA",
										generalAssembly_id: 1534,
									},
									{
										id: 8969,
										name: "KABATASI",
										generalAssembly_id: 1534,
									},
									{
										id: 8970,
										name: "KABAYANGE",
										generalAssembly_id: 1534,
									},
									{
										id: 8971,
										name: "KABUYE",
										generalAssembly_id: 1534,
									},
									{
										id: 8972,
										name: "MIDAHANDWA",
										generalAssembly_id: 1534,
									},
									{
										id: 8973,
										name: "MITARI",
										generalAssembly_id: 1534,
									},
									{
										id: 8974,
										name: "MUNINI",
										generalAssembly_id: 1534,
									},
									{
										id: 8975,
										name: "RUSENYI",
										generalAssembly_id: 1534,
									},
									{
										id: 10508,
										name: "Rubona Court of Appeal ",
										generalAssembly_id: 1534,
									},
									{
										id: 12041,
										name: "Rubona Gacaca court of sector ",
										generalAssembly_id: 1534,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		id: 5,
		name: "North",
		createdAt: "2021-05-03T07:57:21.181Z",
		updatedAt: "2021-05-03T07:57:21.181Z",
		districts: [
			{
				id: 504,
				name: "Burera",
				province_id: 5,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 50401,
						name: "Bungwe",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040104,
								name: "Tumba",
								sector_id: 50401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040103,
								name: "Mudugari",
								sector_id: 50401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040102,
								name: "Bushenya",
								sector_id: 50401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040101,
								name: "Bungwe",
								sector_id: 50401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 37,
								name: "Bungwe ",
								sector_id: 50401,
								courtOfCells: [
									{
										id: 373,
										name: "BUNGWE",
										generalAssembly_id: 37,
									},
									{
										id: 374,
										name: "GATENGA",
										generalAssembly_id: 37,
									},
									{
										id: 375,
										name: "KINIHIRA",
										generalAssembly_id: 37,
									},
									{
										id: 376,
										name: "MURAMBO",
										generalAssembly_id: 37,
									},
									{
										id: 377,
										name: "NYABYONDO",
										generalAssembly_id: 37,
									},
									{
										id: 378,
										name: "NYARUKORE",
										generalAssembly_id: 37,
									},
									{
										id: 379,
										name: "RWERU",
										generalAssembly_id: 37,
									},
									{
										id: 9012,
										name: "Bungwe  Court of Appeal ",
										generalAssembly_id: 37,
									},
									{
										id: 10545,
										name: "Bungwe  Gacaca court of sector ",
										generalAssembly_id: 37,
									},
								],
							},
							{
								id: 38,
								name: "Bushenya ",
								sector_id: 50401,
								courtOfCells: [
									{
										id: 380,
										name: "BUHINGA",
										generalAssembly_id: 38,
									},
									{
										id: 381,
										name: "BUSHENYA",
										generalAssembly_id: 38,
									},
									{
										id: 382,
										name: "GIFUMBA",
										generalAssembly_id: 38,
									},
									{
										id: 383,
										name: "MBUGA",
										generalAssembly_id: 38,
									},
									{
										id: 384,
										name: "RYAMAYAYA",
										generalAssembly_id: 38,
									},
									{
										id: 9013,
										name: "Bushenya  Court of Appeal ",
										generalAssembly_id: 38,
									},
									{
										id: 10546,
										name: "Bushenya  Gacaca court of sector ",
										generalAssembly_id: 38,
									},
								],
							},
							{
								id: 39,
								name: "Mudugari ",
								sector_id: 50401,
								courtOfCells: [
									{
										id: 385,
										name: "BUNIGO",
										generalAssembly_id: 39,
									},
									{
										id: 386,
										name: "KIVUMO",
										generalAssembly_id: 39,
									},
									{
										id: 387,
										name: "MUDUGARI",
										generalAssembly_id: 39,
									},
									{
										id: 388,
										name: "NSANGABUZI",
										generalAssembly_id: 39,
									},
									{
										id: 389,
										name: "NYAKENA",
										generalAssembly_id: 39,
									},
									{
										id: 9014,
										name: "Mudugari  Court of Appeal ",
										generalAssembly_id: 39,
									},
									{
										id: 10547,
										name: "Mudugari  Gacaca court of sector ",
										generalAssembly_id: 39,
									},
								],
							},
							{
								id: 40,
								name: "Tumba ",
								sector_id: 50401,
								courtOfCells: [
									{
										id: 390,
										name: "KARWEMA",
										generalAssembly_id: 40,
									},
									{
										id: 391,
										name: "MUBUGA",
										generalAssembly_id: 40,
									},
									{
										id: 392,
										name: "MUBURA",
										generalAssembly_id: 40,
									},
									{
										id: 393,
										name: "TUMBA",
										generalAssembly_id: 40,
									},
									{
										id: 9015,
										name: "Tumba  Court of Appeal ",
										generalAssembly_id: 40,
									},
									{
										id: 10548,
										name: "Tumba  Gacaca court of sector ",
										generalAssembly_id: 40,
									},
								],
							},
						],
					},
					{
						id: 50402,
						name: "Butaro",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040205,
								name: "Rusumo",
								sector_id: 50402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040204,
								name: "Nyamicucu",
								sector_id: 50402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040203,
								name: "Muhotora",
								sector_id: 50402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040202,
								name: "Mubuga",
								sector_id: 50402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040201,
								name: "Gatsibo",
								sector_id: 50402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 41,
								name: "Bukaragata ",
								sector_id: 50402,
								courtOfCells: [
									{
										id: 394,
										name: "BUKARAGATA",
										generalAssembly_id: 41,
									},
									{
										id: 395,
										name: "KARAMBI",
										generalAssembly_id: 41,
									},
									{
										id: 396,
										name: "KIBANDE",
										generalAssembly_id: 41,
									},
									{
										id: 397,
										name: "MUHOTORA",
										generalAssembly_id: 41,
									},
									{
										id: 398,
										name: "MURAMBI",
										generalAssembly_id: 41,
									},
									{
										id: 9016,
										name: "Bukaragata  Court of Appeal ",
										generalAssembly_id: 41,
									},
									{
										id: 10549,
										name: "Bukaragata  Gacaca court of sector ",
										generalAssembly_id: 41,
									},
								],
							},
							{
								id: 42,
								name: "Kayange ",
								sector_id: 50402,
								courtOfCells: [
									{
										id: 399,
										name: "BUTARO",
										generalAssembly_id: 42,
									},
									{
										id: 400,
										name: "BUYANGA",
										generalAssembly_id: 42,
									},
									{
										id: 401,
										name: "CYASENGE",
										generalAssembly_id: 42,
									},
									{
										id: 402,
										name: "GASEBEYA",
										generalAssembly_id: 42,
									},
									{
										id: 403,
										name: "KABINGO",
										generalAssembly_id: 42,
									},
									{
										id: 404,
										name: "KADEHERO",
										generalAssembly_id: 42,
									},
									{
										id: 405,
										name: "RUNABA",
										generalAssembly_id: 42,
									},
									{
										id: 9017,
										name: "Kayange  Court of Appeal ",
										generalAssembly_id: 42,
									},
									{
										id: 10550,
										name: "Kayange  Gacaca court of sector ",
										generalAssembly_id: 42,
									},
								],
							},
							{
								id: 43,
								name: "Musama ",
								sector_id: 50402,
								courtOfCells: [
									{
										id: 406,
										name: "GAHIRA",
										generalAssembly_id: 43,
									},
									{
										id: 407,
										name: "KABYAZA",
										generalAssembly_id: 43,
									},
									{
										id: 408,
										name: "KAGOMA",
										generalAssembly_id: 43,
									},
									{
										id: 409,
										name: "MUSAMA",
										generalAssembly_id: 43,
									},
									{
										id: 9018,
										name: "Musama  Court of Appeal ",
										generalAssembly_id: 43,
									},
									{
										id: 10551,
										name: "Musama  Gacaca court of sector ",
										generalAssembly_id: 43,
									},
								],
							},
							{
								id: 44,
								name: "Kindoyi ",
								sector_id: 50402,
								courtOfCells: [
									{
										id: 410,
										name: "GASIZA",
										generalAssembly_id: 44,
									},
									{
										id: 411,
										name: "GATSIBO",
										generalAssembly_id: 44,
									},
									{
										id: 412,
										name: "KINDOYI",
										generalAssembly_id: 44,
									},
									{
										id: 413,
										name: "KIRINGA",
										generalAssembly_id: 44,
									},
									{
										id: 414,
										name: "NYAMIYAGA",
										generalAssembly_id: 44,
									},
									{
										id: 9019,
										name: "Kindoyi  Court of Appeal ",
										generalAssembly_id: 44,
									},
									{
										id: 10552,
										name: "Kindoyi  Gacaca court of sector ",
										generalAssembly_id: 44,
									},
								],
							},
						],
					},
					{
						id: 50403,
						name: "Cyanika",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040306,
								name: "Nyagahinga",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040305,
								name: "Kamanyana",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040304,
								name: "Kagitega",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040303,
								name: "Kabyiniro",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040302,
								name: "Gisovu",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040301,
								name: "Gasiza",
								sector_id: 50403,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 45,
								name: "Burambi",
								sector_id: 50403,
								courtOfCells: [
									{
										id: 415,
										name: "CYERU",
										generalAssembly_id: 45,
									},
									{
										id: 416,
										name: "KABAYA",
										generalAssembly_id: 45,
									},
									{
										id: 417,
										name: "KAMUPFUMFU",
										generalAssembly_id: 45,
									},
									{
										id: 418,
										name: "MAREMBO",
										generalAssembly_id: 45,
									},
									{
										id: 419,
										name: "NINDO",
										generalAssembly_id: 45,
									},
									{
										id: 420,
										name: "NYAGAHINGA",
										generalAssembly_id: 45,
									},
									{
										id: 421,
										name: "NYAKABAYA",
										generalAssembly_id: 45,
									},
									{
										id: 422,
										name: "ZINDIRO",
										generalAssembly_id: 45,
									},
									{
										id: 9020,
										name: "Burambi Court of Appeal ",
										generalAssembly_id: 45,
									},
									{
										id: 10553,
										name: "Burambi Gacaca court of sector ",
										generalAssembly_id: 45,
									},
								],
							},
							{
								id: 46,
								name: "Butete ",
								sector_id: 50403,
								courtOfCells: [
									{
										id: 423,
										name: "GISOVU",
										generalAssembly_id: 46,
									},
									{
										id: 424,
										name: "KAMEGERI",
										generalAssembly_id: 46,
									},
									{
										id: 425,
										name: "KARENGO",
										generalAssembly_id: 46,
									},
									{
										id: 426,
										name: "RUHIMBI",
										generalAssembly_id: 46,
									},
									{
										id: 427,
										name: "RUTANGO",
										generalAssembly_id: 46,
									},
									{
										id: 9021,
										name: "Butete  Court of Appeal ",
										generalAssembly_id: 46,
									},
									{
										id: 10554,
										name: "Butete  Gacaca court of sector ",
										generalAssembly_id: 46,
									},
								],
							},
							{
								id: 47,
								name: "Cyanika ",
								sector_id: 50403,
								courtOfCells: [
									{
										id: 428,
										name: "KAMANYANA",
										generalAssembly_id: 47,
									},
									{
										id: 429,
										name: "KANYANDARO",
										generalAssembly_id: 47,
									},
									{
										id: 430,
										name: "NYAKIMANGA",
										generalAssembly_id: 47,
									},
									{
										id: 431,
										name: "NYAMIYAGA",
										generalAssembly_id: 47,
									},
									{
										id: 432,
										name: "RUKORO",
										generalAssembly_id: 47,
									},
									{
										id: 9022,
										name: "Cyanika  Court of Appeal ",
										generalAssembly_id: 47,
									},
									{
										id: 10555,
										name: "Cyanika  Gacaca court of sector ",
										generalAssembly_id: 47,
									},
								],
							},
							{
								id: 48,
								name: "Gitaraga ",
								sector_id: 50403,
								courtOfCells: [
									{
										id: 433,
										name: "GASIZA",
										generalAssembly_id: 48,
									},
									{
										id: 434,
										name: "GIHOKO",
										generalAssembly_id: 48,
									},
									{
										id: 435,
										name: "KAMANGA",
										generalAssembly_id: 48,
									},
									{
										id: 436,
										name: "KAZIGURO",
										generalAssembly_id: 48,
									},
									{
										id: 437,
										name: "MUGARAMA",
										generalAssembly_id: 48,
									},
									{
										id: 438,
										name: "NTARAMA",
										generalAssembly_id: 48,
									},
									{
										id: 439,
										name: "NYAGISENYI",
										generalAssembly_id: 48,
									},
									{
										id: 9023,
										name: "Gitaraga  Court of Appeal ",
										generalAssembly_id: 48,
									},
									{
										id: 10556,
										name: "Gitaraga  Gacaca court of sector ",
										generalAssembly_id: 48,
									},
								],
							},
							{
								id: 49,
								name: "Kidaho ",
								sector_id: 50403,
								courtOfCells: [
									{
										id: 440,
										name: "KAGERERO",
										generalAssembly_id: 49,
									},
									{
										id: 441,
										name: "KAGITEGA",
										generalAssembly_id: 49,
									},
									{
										id: 442,
										name: "KIDAHO",
										generalAssembly_id: 49,
									},
									{
										id: 443,
										name: "NKIRIZA",
										generalAssembly_id: 49,
									},
									{
										id: 444,
										name: "NTARAMA",
										generalAssembly_id: 49,
									},
									{
										id: 9024,
										name: "Kidaho  Court of Appeal ",
										generalAssembly_id: 49,
									},
									{
										id: 10557,
										name: "Kidaho  Gacaca court of sector ",
										generalAssembly_id: 49,
									},
								],
							},
						],
					},
					{
						id: 50404,
						name: "Cyeru",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040403,
								name: "Ruyange",
								sector_id: 50404,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040402,
								name: "Ndongozi",
								sector_id: 50404,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040401,
								name: "Butare",
								sector_id: 50404,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 50,
								name: "Kabona",
								sector_id: 50404,
								courtOfCells: [
									{
										id: 445,
										name: "GIKORE",
										generalAssembly_id: 50,
									},
									{
										id: 446,
										name: "KABONA",
										generalAssembly_id: 50,
									},
									{
										id: 447,
										name: "BUCYABA",
										generalAssembly_id: 50,
									},
									{
										id: 448,
										name: "MUSEBEYA",
										generalAssembly_id: 50,
									},
									{
										id: 9025,
										name: "Kabona Court of Appeal ",
										generalAssembly_id: 50,
									},
									{
										id: 10558,
										name: "Kabona Gacaca court of sector ",
										generalAssembly_id: 50,
									},
								],
							},
							{
								id: 51,
								name: "Ndago",
								sector_id: 50404,
								courtOfCells: [
									{
										id: 449,
										name: "GITOVU",
										generalAssembly_id: 51,
									},
									{
										id: 450,
										name: "MURONGOZI",
										generalAssembly_id: 51,
									},
									{
										id: 451,
										name: "NDAGO",
										generalAssembly_id: 51,
									},
									{
										id: 452,
										name: "NYARUNGU",
										generalAssembly_id: 51,
									},
									{
										id: 9026,
										name: "Ndago Court of Appeal ",
										generalAssembly_id: 51,
									},
									{
										id: 10559,
										name: "Ndago Gacaca court of sector ",
										generalAssembly_id: 51,
									},
								],
							},
							{
								id: 52,
								name: "Ruhanga",
								sector_id: 50404,
								courtOfCells: [
									{
										id: 453,
										name: "RUHANGA",
										generalAssembly_id: 52,
									},
									{
										id: 454,
										name: "RUHURURA",
										generalAssembly_id: 52,
									},
									{
										id: 9027,
										name: "Ruhanga Court of Appeal ",
										generalAssembly_id: 52,
									},
									{
										id: 10560,
										name: "Ruhanga Gacaca court of sector ",
										generalAssembly_id: 52,
									},
								],
							},
						],
					},
					{
						id: 50405,
						name: "Gahunga",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040505,
								name: "Rwasa",
								sector_id: 50405,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040504,
								name: "Nyangwe",
								sector_id: 50405,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040503,
								name: "Kidakama",
								sector_id: 50405,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040502,
								name: "Gisizi",
								sector_id: 50405,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040501,
								name: "Buramba",
								sector_id: 50405,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 53,
								name: "Gahunga",
								sector_id: 50405,
								courtOfCells: [
									{
										id: 455,
										name: "GASAGARA",
										generalAssembly_id: 53,
									},
									{
										id: 456,
										name: "KABINDI",
										generalAssembly_id: 53,
									},
									{
										id: 457,
										name: "KAMATANDA",
										generalAssembly_id: 53,
									},
									{
										id: 458,
										name: "KIDAKAMA",
										generalAssembly_id: 53,
									},
									{
										id: 459,
										name: "RUSENYI",
										generalAssembly_id: 53,
									},
									{
										id: 9028,
										name: "Gahunga Court of Appeal ",
										generalAssembly_id: 53,
									},
									{
										id: 10561,
										name: "Gahunga Gacaca court of sector ",
										generalAssembly_id: 53,
									},
								],
							},
							{
								id: 54,
								name: "Gatete ",
								sector_id: 50405,
								courtOfCells: [
									{
										id: 460,
										name: "KANABA",
										generalAssembly_id: 54,
									},
									{
										id: 461,
										name: "KIGOTE",
										generalAssembly_id: 54,
									},
									{
										id: 462,
										name: "NYAGASOZI",
										generalAssembly_id: 54,
									},
									{
										id: 463,
										name: "RURI",
										generalAssembly_id: 54,
									},
									{
										id: 464,
										name: "GISIZI",
										generalAssembly_id: 54,
									},
									{
										id: 9029,
										name: "Gatete  Court of Appeal ",
										generalAssembly_id: 54,
									},
									{
										id: 10562,
										name: "Gatete  Gacaca court of sector ",
										generalAssembly_id: 54,
									},
								],
							},
							{
								id: 55,
								name: "Musanzu ",
								sector_id: 50405,
								courtOfCells: [
									{
										id: 465,
										name: "BURAMBA",
										generalAssembly_id: 55,
									},
									{
										id: 466,
										name: "KAGOMA",
										generalAssembly_id: 55,
									},
									{
										id: 467,
										name: "MURAMBI",
										generalAssembly_id: 55,
									},
									{
										id: 468,
										name: "MUSANZU",
										generalAssembly_id: 55,
									},
									{
										id: 469,
										name: "NYANGWE",
										generalAssembly_id: 55,
									},
									{
										id: 9030,
										name: "Musanzu  Court of Appeal ",
										generalAssembly_id: 55,
									},
									{
										id: 10563,
										name: "Musanzu  Gacaca court of sector ",
										generalAssembly_id: 55,
									},
								],
							},
						],
					},
					{
						id: 50406,
						name: "Gatebe",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040604,
								name: "Rwasa",
								sector_id: 50406,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040603,
								name: "Rwambogo",
								sector_id: 50406,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040602,
								name: "Musenda",
								sector_id: 50406,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040601,
								name: "Gabiro",
								sector_id: 50406,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 56,
								name: "Gatebe ",
								sector_id: 50406,
								courtOfCells: [
									{
										id: 470,
										name: "GATEBE",
										generalAssembly_id: 56,
									},
									{
										id: 471,
										name: "GINGA",
										generalAssembly_id: 56,
									},
									{
										id: 472,
										name: "KABUGA",
										generalAssembly_id: 56,
									},
									{
										id: 473,
										name: "KAGANO",
										generalAssembly_id: 56,
									},
									{
										id: 474,
										name: "NYAKABUNGO",
										generalAssembly_id: 56,
									},
									{
										id: 475,
										name: "RUGARAMA",
										generalAssembly_id: 56,
									},
									{
										id: 476,
										name: "RYARUYUMBU",
										generalAssembly_id: 56,
									},
									{
										id: 9031,
										name: "Gatebe  Court of Appeal ",
										generalAssembly_id: 56,
									},
									{
										id: 10564,
										name: "Gatebe  Gacaca court of sector ",
										generalAssembly_id: 56,
									},
								],
							},
							{
								id: 57,
								name: "Musenda",
								sector_id: 50406,
								courtOfCells: [
									{
										id: 477,
										name: "GATEBE",
										generalAssembly_id: 57,
									},
									{
										id: 478,
										name: "KARYANGO",
										generalAssembly_id: 57,
									},
									{
										id: 479,
										name: "MUSENDA",
										generalAssembly_id: 57,
									},
									{
										id: 480,
										name: "NYIRAMIKORE",
										generalAssembly_id: 57,
									},
									{
										id: 481,
										name: "RUFUHA",
										generalAssembly_id: 57,
									},
									{
										id: 482,
										name: "RUKOMA",
										generalAssembly_id: 57,
									},
									{
										id: 483,
										name: "RUTETE",
										generalAssembly_id: 57,
									},
									{
										id: 484,
										name: "RUYANGE",
										generalAssembly_id: 57,
									},
									{
										id: 485,
										name: "RWAMBOGO",
										generalAssembly_id: 57,
									},
									{
										id: 9032,
										name: "Musenda Court of Appeal ",
										generalAssembly_id: 57,
									},
									{
										id: 10565,
										name: "Musenda Gacaca court of sector ",
										generalAssembly_id: 57,
									},
								],
							},
							{
								id: 58,
								name: "Rwasa",
								sector_id: 50406,
								courtOfCells: [
									{
										id: 486,
										name: "GIKURO",
										generalAssembly_id: 58,
									},
									{
										id: 487,
										name: "KIYOGERA",
										generalAssembly_id: 58,
									},
									{
										id: 488,
										name: "RUTOVU I",
										generalAssembly_id: 58,
									},
									{
										id: 489,
										name: "RUTOVU II",
										generalAssembly_id: 58,
									},
									{
										id: 490,
										name: "RWASA I",
										generalAssembly_id: 58,
									},
									{
										id: 491,
										name: "RWASA II",
										generalAssembly_id: 58,
									},
									{
										id: 9033,
										name: "Rwasa Court of Appeal ",
										generalAssembly_id: 58,
									},
									{
										id: 10566,
										name: "Rwasa Gacaca court of sector ",
										generalAssembly_id: 58,
									},
								],
							},
						],
					},
					{
						id: 50407,
						name: "Gitovu",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040703,
								name: "Runoga",
								sector_id: 50407,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040702,
								name: "Musasa",
								sector_id: 50407,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040701,
								name: "Mariba",
								sector_id: 50407,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 59,
								name: "Kiboga",
								sector_id: 50407,
								courtOfCells: [
									{
										id: 492,
										name: "KIBOGA",
										generalAssembly_id: 59,
									},
									{
										id: 493,
										name: "MUBUGA",
										generalAssembly_id: 59,
									},
									{
										id: 494,
										name: "MUSEKERA",
										generalAssembly_id: 59,
									},
									{
										id: 9034,
										name: "Kiboga Court of Appeal ",
										generalAssembly_id: 59,
									},
									{
										id: 10567,
										name: "Kiboga Gacaca court of sector ",
										generalAssembly_id: 59,
									},
								],
							},
							{
								id: 60,
								name: "Ruhombo ",
								sector_id: 50407,
								courtOfCells: [
									{
										id: 495,
										name: "BUGOBORI",
										generalAssembly_id: 60,
									},
									{
										id: 496,
										name: "MUTUNGU",
										generalAssembly_id: 60,
									},
									{
										id: 497,
										name: "RUHOMBO",
										generalAssembly_id: 60,
									},
									{
										id: 9035,
										name: "Ruhombo  Court of Appeal ",
										generalAssembly_id: 60,
									},
									{
										id: 10568,
										name: "Ruhombo  Gacaca court of sector ",
										generalAssembly_id: 60,
									},
								],
							},
							{
								id: 61,
								name: "Ruyange",
								sector_id: 50407,
								courtOfCells: [
									{
										id: 498,
										name: "KABERANO",
										generalAssembly_id: 61,
									},
									{
										id: 499,
										name: "MURAMBO",
										generalAssembly_id: 61,
									},
									{
										id: 500,
										name: "RUGARI",
										generalAssembly_id: 61,
									},
									{
										id: 9036,
										name: "Ruyange Court of Appeal ",
										generalAssembly_id: 61,
									},
									{
										id: 10569,
										name: "Ruyange Gacaca court of sector ",
										generalAssembly_id: 61,
									},
								],
							},
						],
					},
					{
						id: 50408,
						name: "Kagogo",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040804,
								name: "Nyamabuye",
								sector_id: 50408,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040803,
								name: "Kiringa",
								sector_id: 50408,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040802,
								name: "Kayenzi",
								sector_id: 50408,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040801,
								name: "Kabaya",
								sector_id: 50408,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 62,
								name: "Butenga ",
								sector_id: 50408,
								courtOfCells: [
									{
										id: 501,
										name: "KABUGA",
										generalAssembly_id: 62,
									},
									{
										id: 502,
										name: "KAYENZI",
										generalAssembly_id: 62,
									},
									{
										id: 503,
										name: "KIZIBA",
										generalAssembly_id: 62,
									},
									{
										id: 504,
										name: "NYAGASOZI",
										generalAssembly_id: 62,
									},
									{
										id: 505,
										name: "RUBONA",
										generalAssembly_id: 62,
									},
									{
										id: 9037,
										name: "Butenga  Court of Appeal ",
										generalAssembly_id: 62,
									},
									{
										id: 10570,
										name: "Butenga  Gacaca court of sector ",
										generalAssembly_id: 62,
									},
								],
							},
							{
								id: 63,
								name: "Gitare ",
								sector_id: 50408,
								courtOfCells: [
									{
										id: 506,
										name: "GIHETA",
										generalAssembly_id: 63,
									},
									{
										id: 507,
										name: "GIHONGA",
										generalAssembly_id: 63,
									},
									{
										id: 508,
										name: "KIBAYA",
										generalAssembly_id: 63,
									},
									{
										id: 509,
										name: "KIVUMU",
										generalAssembly_id: 63,
									},
									{
										id: 510,
										name: "NYAMABUYE",
										generalAssembly_id: 63,
									},
									{
										id: 511,
										name: "RUSAGARA",
										generalAssembly_id: 63,
									},
									{
										id: 9038,
										name: "Gitare  Court of Appeal ",
										generalAssembly_id: 63,
									},
									{
										id: 10571,
										name: "Gitare  Gacaca court of sector ",
										generalAssembly_id: 63,
									},
								],
							},
							{
								id: 64,
								name: "Kagogo ",
								sector_id: 50408,
								courtOfCells: [
									{
										id: 512,
										name: "GAHINGA",
										generalAssembly_id: 64,
									},
									{
										id: 513,
										name: "KABINDI",
										generalAssembly_id: 64,
									},
									{
										id: 514,
										name: "KIRINGA",
										generalAssembly_id: 64,
									},
									{
										id: 515,
										name: "MUGERA",
										generalAssembly_id: 64,
									},
									{
										id: 516,
										name: "RWAHOMBO",
										generalAssembly_id: 64,
									},
									{
										id: 517,
										name: "SOZI",
										generalAssembly_id: 64,
									},
									{
										id: 518,
										name: "KINYAMUKUBI",
										generalAssembly_id: 64,
									},
									{
										id: 9039,
										name: "Kagogo  Court of Appeal ",
										generalAssembly_id: 64,
									},
									{
										id: 10572,
										name: "Kagogo  Gacaca court of sector ",
										generalAssembly_id: 64,
									},
								],
							},
							{
								id: 65,
								name: "Rugendabari ",
								sector_id: 50408,
								courtOfCells: [
									{
										id: 519,
										name: "BIHANGA",
										generalAssembly_id: 65,
									},
									{
										id: 520,
										name: "BUTARE",
										generalAssembly_id: 65,
									},
									{
										id: 521,
										name: "KABAYA",
										generalAssembly_id: 65,
									},
									{
										id: 522,
										name: "KANABA",
										generalAssembly_id: 65,
									},
									{
										id: 9040,
										name: "Rugendabari  Court of Appeal ",
										generalAssembly_id: 65,
									},
									{
										id: 10573,
										name: "Rugendabari  Gacaca court of sector ",
										generalAssembly_id: 65,
									},
								],
							},
						],
					},
					{
						id: 50409,
						name: "Kinoni",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5040904,
								name: "Ntaruka",
								sector_id: 50409,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040903,
								name: "Nkumba",
								sector_id: 50409,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040902,
								name: "Nkenke",
								sector_id: 50409,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5040901,
								name: "Gafuka",
								sector_id: 50409,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 66,
								name: "Kabaya",
								sector_id: 50409,
								courtOfCells: [
									{
										id: 523,
										name: "KABAYA",
										generalAssembly_id: 66,
									},
									{
										id: 524,
										name: "MURINZI",
										generalAssembly_id: 66,
									},
									{
										id: 525,
										name: "NYARWAMBU",
										generalAssembly_id: 66,
									},
									{
										id: 526,
										name: "RWASA",
										generalAssembly_id: 66,
									},
									{
										id: 9041,
										name: "Kabaya Court of Appeal ",
										generalAssembly_id: 66,
									},
									{
										id: 10574,
										name: "Kabaya Gacaca court of sector ",
										generalAssembly_id: 66,
									},
								],
							},
							{
								id: 67,
								name: "Kinoni ",
								sector_id: 50409,
								courtOfCells: [
									{
										id: 527,
										name: "GAFUKA",
										generalAssembly_id: 67,
									},
									{
										id: 528,
										name: "KINONI",
										generalAssembly_id: 67,
									},
									{
										id: 529,
										name: "NTWANA",
										generalAssembly_id: 67,
									},
									{
										id: 530,
										name: "NYAGAFUNZO",
										generalAssembly_id: 67,
									},
									{
										id: 9042,
										name: "Kinoni  Court of Appeal ",
										generalAssembly_id: 67,
									},
									{
										id: 10575,
										name: "Kinoni  Gacaca court of sector ",
										generalAssembly_id: 67,
									},
								],
							},
							{
								id: 68,
								name: "Mwiko ",
								sector_id: 50409,
								courtOfCells: [
									{
										id: 531,
										name: "BIRWA",
										generalAssembly_id: 68,
									},
									{
										id: 532,
										name: "MWENDO",
										generalAssembly_id: 68,
									},
									{
										id: 533,
										name: "NKENKE",
										generalAssembly_id: 68,
									},
									{
										id: 534,
										name: "NYABIGUGU",
										generalAssembly_id: 68,
									},
									{
										id: 9043,
										name: "Mwiko  Court of Appeal ",
										generalAssembly_id: 68,
									},
									{
										id: 10576,
										name: "Mwiko  Gacaca court of sector ",
										generalAssembly_id: 68,
									},
								],
							},
							{
								id: 69,
								name: "Nyanga ",
								sector_id: 50409,
								courtOfCells: [
									{
										id: 535,
										name: "GIKORO",
										generalAssembly_id: 69,
									},
									{
										id: 536,
										name: "GIKURO",
										generalAssembly_id: 69,
									},
									{
										id: 537,
										name: "KAMANYANA",
										generalAssembly_id: 69,
									},
									{
										id: 538,
										name: "NYANGA",
										generalAssembly_id: 69,
									},
									{
										id: 9044,
										name: "Nyanga  Court of Appeal ",
										generalAssembly_id: 69,
									},
									{
										id: 10577,
										name: "Nyanga  Gacaca court of sector ",
										generalAssembly_id: 69,
									},
								],
							},
							{
								id: 70,
								name: "Ruhondo ",
								sector_id: 50409,
								courtOfCells: [
									{
										id: 539,
										name: "CYANYA",
										generalAssembly_id: 70,
									},
									{
										id: 540,
										name: "KABAGUMA",
										generalAssembly_id: 70,
									},
									{
										id: 541,
										name: "MUBUGA",
										generalAssembly_id: 70,
									},
									{
										id: 542,
										name: "MUTABO",
										generalAssembly_id: 70,
									},
									{
										id: 543,
										name: "KARAMBO",
										generalAssembly_id: 70,
									},
									{
										id: 9045,
										name: "Ruhondo  Court of Appeal ",
										generalAssembly_id: 70,
									},
									{
										id: 10578,
										name: "Ruhondo  Gacaca court of sector ",
										generalAssembly_id: 70,
									},
								],
							},
						],
					},
					{
						id: 50410,
						name: "Kinyababa",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041004,
								name: "Rutovu",
								sector_id: 50410,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041003,
								name: "Musasa",
								sector_id: 50410,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041002,
								name: "Kaganda",
								sector_id: 50410,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041001,
								name: "Bugamba",
								sector_id: 50410,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 71,
								name: "Ruliba ",
								sector_id: 50410,
								courtOfCells: [
									{
										id: 544,
										name: "GAHONDO",
										generalAssembly_id: 71,
									},
									{
										id: 545,
										name: "KAGANDA",
										generalAssembly_id: 71,
									},
									{
										id: 546,
										name: "KIRARO",
										generalAssembly_id: 71,
									},
									{
										id: 547,
										name: "MUBIMBI",
										generalAssembly_id: 71,
									},
									{
										id: 9046,
										name: "Ruliba  Court of Appeal ",
										generalAssembly_id: 71,
									},
									{
										id: 10579,
										name: "Ruliba  Gacaca court of sector ",
										generalAssembly_id: 71,
									},
								],
							},
							{
								id: 72,
								name: "Kinyababa ",
								sector_id: 50410,
								courtOfCells: [
									{
										id: 548,
										name: "BUGAMBA",
										generalAssembly_id: 72,
									},
									{
										id: 549,
										name: "CYUFE",
										generalAssembly_id: 72,
									},
									{
										id: 550,
										name: "GITOMA",
										generalAssembly_id: 72,
									},
									{
										id: 551,
										name: "JAGI",
										generalAssembly_id: 72,
									},
									{
										id: 552,
										name: "KABINGO",
										generalAssembly_id: 72,
									},
									{
										id: 553,
										name: "KABYAZA",
										generalAssembly_id: 72,
									},
									{
										id: 554,
										name: "MAHURA",
										generalAssembly_id: 72,
									},
									{
										id: 555,
										name: "RWAMATABARO",
										generalAssembly_id: 72,
									},
									{
										id: 9047,
										name: "Kinyababa  Court of Appeal ",
										generalAssembly_id: 72,
									},
									{
										id: 10580,
										name: "Kinyababa  Gacaca court of sector ",
										generalAssembly_id: 72,
									},
								],
							},
							{
								id: 73,
								name: "Rutovu ",
								sector_id: 50410,
								courtOfCells: [
									{
										id: 556,
										name: "BUKOKA",
										generalAssembly_id: 73,
									},
									{
										id: 557,
										name: "GISIRIRI",
										generalAssembly_id: 73,
									},
									{
										id: 558,
										name: "KAGURIRO",
										generalAssembly_id: 73,
									},
									{
										id: 559,
										name: "KAVUMU",
										generalAssembly_id: 73,
									},
									{
										id: 560,
										name: "MUSAGA",
										generalAssembly_id: 73,
									},
									{
										id: 561,
										name: "NYABIZI",
										generalAssembly_id: 73,
									},
									{
										id: 562,
										name: "RUBAYU",
										generalAssembly_id: 73,
									},
									{
										id: 563,
										name: "SHAGA",
										generalAssembly_id: 73,
									},
									{
										id: 9048,
										name: "Rutovu  Court of Appeal ",
										generalAssembly_id: 73,
									},
									{
										id: 10581,
										name: "Rutovu  Gacaca court of sector ",
										generalAssembly_id: 73,
									},
								],
							},
						],
					},
					{
						id: 50411,
						name: "Kivuye",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041104,
								name: "Nyirataba",
								sector_id: 50411,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041103,
								name: "Murwa",
								sector_id: 50411,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041102,
								name: "Gashanje",
								sector_id: 50411,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041101,
								name: "Bukwashuri",
								sector_id: 50411,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 74,
								name: "Buhita ",
								sector_id: 50411,
								courtOfCells: [
									{
										id: 564,
										name: "KIRINGA",
										generalAssembly_id: 74,
									},
									{
										id: 565,
										name: "RUSASA",
										generalAssembly_id: 74,
									},
									{
										id: 566,
										name: "RUSHAKI",
										generalAssembly_id: 74,
									},
									{
										id: 567,
										name: "RUYUMBA",
										generalAssembly_id: 74,
									},
									{
										id: 9049,
										name: "Buhita  Court of Appeal ",
										generalAssembly_id: 74,
									},
									{
										id: 10582,
										name: "Buhita  Gacaca court of sector ",
										generalAssembly_id: 74,
									},
								],
							},
							{
								id: 75,
								name: "Butandi ",
								sector_id: 50411,
								courtOfCells: [
									{
										id: 568,
										name: "BURANGO",
										generalAssembly_id: 75,
									},
									{
										id: 569,
										name: "GASHANJE",
										generalAssembly_id: 75,
									},
									{
										id: 570,
										name: "NYAKABUNGO",
										generalAssembly_id: 75,
									},
									{
										id: 571,
										name: "RUGARAMBIRO",
										generalAssembly_id: 75,
									},
									{
										id: 9050,
										name: "Butandi  Court of Appeal ",
										generalAssembly_id: 75,
									},
									{
										id: 10583,
										name: "Butandi  Gacaca court of sector ",
										generalAssembly_id: 75,
									},
								],
							},
							{
								id: 76,
								name: "Kivuye ",
								sector_id: 50411,
								courtOfCells: [
									{
										id: 572,
										name: "BUKUMBI",
										generalAssembly_id: 76,
									},
									{
										id: 573,
										name: "KIVUMO",
										generalAssembly_id: 76,
									},
									{
										id: 574,
										name: "KIVUYE",
										generalAssembly_id: 76,
									},
									{
										id: 575,
										name: "MUSHUNGA",
										generalAssembly_id: 76,
									},
									{
										id: 9051,
										name: "Kivuye  Court of Appeal ",
										generalAssembly_id: 76,
									},
									{
										id: 10584,
										name: "Kivuye  Gacaca court of sector ",
										generalAssembly_id: 76,
									},
								],
							},
							{
								id: 77,
								name: "Shanja ",
								sector_id: 50411,
								courtOfCells: [
									{
										id: 576,
										name: "BUHITA",
										generalAssembly_id: 77,
									},
									{
										id: 577,
										name: "BUKWASHURI",
										generalAssembly_id: 77,
									},
									{
										id: 578,
										name: "BWINDI",
										generalAssembly_id: 77,
									},
									{
										id: 579,
										name: "GITOVU",
										generalAssembly_id: 77,
									},
									{
										id: 580,
										name: "KIVUYE I",
										generalAssembly_id: 77,
									},
									{
										id: 581,
										name: "KIVUYE II",
										generalAssembly_id: 77,
									},
									{
										id: 582,
										name: "MURAMBO",
										generalAssembly_id: 77,
									},
									{
										id: 9052,
										name: "Shanja  Court of Appeal ",
										generalAssembly_id: 77,
									},
									{
										id: 10585,
										name: "Shanja  Gacaca court of sector ",
										generalAssembly_id: 77,
									},
								],
							},
						],
					},
					{
						id: 50412,
						name: "Nemba",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041204,
								name: "Rushara",
								sector_id: 50412,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041203,
								name: "Rubona",
								sector_id: 50412,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041202,
								name: "Nyamugari",
								sector_id: 50412,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041201,
								name: "Kivumu",
								sector_id: 50412,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 78,
								name: "Kivumu",
								sector_id: 50412,
								courtOfCells: [
									{
										id: 583,
										name: "BUSOGO",
										generalAssembly_id: 78,
									},
									{
										id: 584,
										name: "GISURO",
										generalAssembly_id: 78,
									},
									{
										id: 585,
										name: "MUHONDO",
										generalAssembly_id: 78,
									},
									{
										id: 586,
										name: "RUTEMBA",
										generalAssembly_id: 78,
									},
									{
										id: 587,
										name: "SONGORERO",
										generalAssembly_id: 78,
									},
									{
										id: 9053,
										name: "Kivumu Court of Appeal ",
										generalAssembly_id: 78,
									},
									{
										id: 10586,
										name: "Kivumu Gacaca court of sector ",
										generalAssembly_id: 78,
									},
								],
							},
							{
								id: 79,
								name: "Nemba",
								sector_id: 50412,
								courtOfCells: [
									{
										id: 588,
										name: "AKABUGORE",
										generalAssembly_id: 79,
									},
									{
										id: 589,
										name: "GIKORO",
										generalAssembly_id: 79,
									},
									{
										id: 590,
										name: "MUHAYASUGU",
										generalAssembly_id: 79,
									},
									{
										id: 591,
										name: "NYAGAHONDO",
										generalAssembly_id: 79,
									},
									{
										id: 592,
										name: "NYAKAYOGERA",
										generalAssembly_id: 79,
									},
									{
										id: 9054,
										name: "Nemba Court of Appeal ",
										generalAssembly_id: 79,
									},
									{
										id: 10587,
										name: "Nemba Gacaca court of sector ",
										generalAssembly_id: 79,
									},
								],
							},
							{
								id: 80,
								name: "Rubona ",
								sector_id: 50412,
								courtOfCells: [
									{
										id: 593,
										name: "KADEHERO",
										generalAssembly_id: 80,
									},
									{
										id: 594,
										name: "KAMABARE",
										generalAssembly_id: 80,
									},
									{
										id: 595,
										name: "KANYARU",
										generalAssembly_id: 80,
									},
									{
										id: 596,
										name: "MURANDAMO",
										generalAssembly_id: 80,
									},
									{
										id: 597,
										name: "MUSAVE",
										generalAssembly_id: 80,
									},
									{
										id: 9055,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 80,
									},
									{
										id: 10588,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 80,
									},
								],
							},
							{
								id: 81,
								name: "Rushara ",
								sector_id: 50412,
								courtOfCells: [
									{
										id: 598,
										name: "CYAVE",
										generalAssembly_id: 81,
									},
									{
										id: 599,
										name: "KAGESERA",
										generalAssembly_id: 81,
									},
									{
										id: 600,
										name: "KAJE",
										generalAssembly_id: 81,
									},
									{
										id: 601,
										name: "RUBAYO",
										generalAssembly_id: 81,
									},
									{
										id: 9056,
										name: "Rushara  Court of Appeal ",
										generalAssembly_id: 81,
									},
									{
										id: 10589,
										name: "Rushara  Gacaca court of sector ",
										generalAssembly_id: 81,
									},
								],
							},
						],
					},
					{
						id: 50413,
						name: "Rugarama",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041304,
								name: "Rurembo",
								sector_id: 50413,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041303,
								name: "Karangara",
								sector_id: 50413,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041302,
								name: "Gafumba",
								sector_id: 50413,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041301,
								name: "Cyahi",
								sector_id: 50413,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 82,
								name: "Burera ",
								sector_id: 50413,
								courtOfCells: [
									{
										id: 602,
										name: "BIRWA",
										generalAssembly_id: 82,
									},
									{
										id: 603,
										name: "GASHORE",
										generalAssembly_id: 82,
									},
									{
										id: 604,
										name: "MWERU",
										generalAssembly_id: 82,
									},
									{
										id: 605,
										name: "NYABIGUGU",
										generalAssembly_id: 82,
									},
									{
										id: 606,
										name: "RUREMBO",
										generalAssembly_id: 82,
									},
									{
										id: 9057,
										name: "Burera  Court of Appeal ",
										generalAssembly_id: 82,
									},
									{
										id: 10590,
										name: "Burera  Gacaca court of sector ",
										generalAssembly_id: 82,
									},
								],
							},
							{
								id: 83,
								name: "Maya",
								sector_id: 50413,
								courtOfCells: [
									{
										id: 607,
										name: "BUHINGA",
										generalAssembly_id: 83,
									},
									{
										id: 608,
										name: "KARANGARA",
										generalAssembly_id: 83,
									},
									{
										id: 609,
										name: "MAYA",
										generalAssembly_id: 83,
									},
									{
										id: 610,
										name: "MUSHIRABWOBA",
										generalAssembly_id: 83,
									},
									{
										id: 611,
										name: "RUKORE",
										generalAssembly_id: 83,
									},
									{
										id: 9058,
										name: "Maya Court of Appeal ",
										generalAssembly_id: 83,
									},
									{
										id: 10591,
										name: "Maya Gacaca court of sector ",
										generalAssembly_id: 83,
									},
								],
							},
							{
								id: 84,
								name: "Rugarama ",
								sector_id: 50413,
								courtOfCells: [
									{
										id: 612,
										name: "BAMBIRO",
										generalAssembly_id: 84,
									},
									{
										id: 613,
										name: "BASUMBA",
										generalAssembly_id: 84,
									},
									{
										id: 614,
										name: "GAFUMBA",
										generalAssembly_id: 84,
									},
									{
										id: 615,
										name: "GAHUNGA",
										generalAssembly_id: 84,
									},
									{
										id: 616,
										name: "KANYANGEZI",
										generalAssembly_id: 84,
									},
									{
										id: 9059,
										name: "Rugarama  Court of Appeal ",
										generalAssembly_id: 84,
									},
									{
										id: 10592,
										name: "Rugarama  Gacaca court of sector ",
										generalAssembly_id: 84,
									},
								],
							},
							{
								id: 85,
								name: "Rutamba ",
								sector_id: 50413,
								courtOfCells: [
									{
										id: 617,
										name: "KINYAMUKUBI",
										generalAssembly_id: 85,
									},
									{
										id: 618,
										name: "BUSURA I",
										generalAssembly_id: 85,
									},
									{
										id: 619,
										name: "BUSURA II",
										generalAssembly_id: 85,
									},
									{
										id: 620,
										name: "CYAHI",
										generalAssembly_id: 85,
									},
									{
										id: 621,
										name: "HANIKA",
										generalAssembly_id: 85,
									},
									{
										id: 622,
										name: "NGURI",
										generalAssembly_id: 85,
									},
									{
										id: 623,
										name: "RUBANGA",
										generalAssembly_id: 85,
									},
									{
										id: 9060,
										name: "Rutamba  Court of Appeal ",
										generalAssembly_id: 85,
									},
									{
										id: 10593,
										name: "Rutamba  Gacaca court of sector ",
										generalAssembly_id: 85,
									},
								],
							},
						],
					},
					{
						id: 50414,
						name: "Rugengabari",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041404,
								name: "Rukandabyuma",
								sector_id: 50414,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041403,
								name: "Nyanamo",
								sector_id: 50414,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041402,
								name: "Mucaca",
								sector_id: 50414,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041401,
								name: "Kilibata",
								sector_id: 50414,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 86,
								name: "Kinihira",
								sector_id: 50414,
								courtOfCells: [
									{
										id: 624,
										name: "BUTERERA",
										generalAssembly_id: 86,
									},
									{
										id: 625,
										name: "GAHINGA",
										generalAssembly_id: 86,
									},
									{
										id: 626,
										name: "MUCACA",
										generalAssembly_id: 86,
									},
									{
										id: 9061,
										name: "Kinihira Court of Appeal ",
										generalAssembly_id: 86,
									},
									{
										id: 10594,
										name: "Kinihira Gacaca court of sector ",
										generalAssembly_id: 86,
									},
								],
							},
							{
								id: 87,
								name: "Rugengabali",
								sector_id: 50414,
								courtOfCells: [
									{
										id: 627,
										name: "GATENGA",
										generalAssembly_id: 87,
									},
									{
										id: 628,
										name: "MURUNGU",
										generalAssembly_id: 87,
									},
									{
										id: 629,
										name: "NGOMA",
										generalAssembly_id: 87,
									},
									{
										id: 630,
										name: "REMERA",
										generalAssembly_id: 87,
									},
									{
										id: 631,
										name: "RUGANDU",
										generalAssembly_id: 87,
									},
									{
										id: 9062,
										name: "Rugengabali Court of Appeal ",
										generalAssembly_id: 87,
									},
									{
										id: 10595,
										name: "Rugengabali Gacaca court of sector ",
										generalAssembly_id: 87,
									},
								],
							},
						],
					},
					{
						id: 50415,
						name: "Ruhunde",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041504,
								name: "Rusekera",
								sector_id: 50415,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041503,
								name: "Gitovu",
								sector_id: 50415,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041502,
								name: "Gatare",
								sector_id: 50415,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041501,
								name: "Gaseke",
								sector_id: 50415,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 88,
								name: "Gitovu ",
								sector_id: 50415,
								courtOfCells: [
									{
										id: 632,
										name: "CYASURI",
										generalAssembly_id: 88,
									},
									{
										id: 633,
										name: "GENDA",
										generalAssembly_id: 88,
									},
									{
										id: 634,
										name: "NGOMA",
										generalAssembly_id: 88,
									},
									{
										id: 9063,
										name: "Gitovu  Court of Appeal ",
										generalAssembly_id: 88,
									},
									{
										id: 10596,
										name: "Gitovu  Gacaca court of sector ",
										generalAssembly_id: 88,
									},
								],
							},
							{
								id: 89,
								name: "Ruhunde ",
								sector_id: 50415,
								courtOfCells: [
									{
										id: 635,
										name: "BUKAMYI",
										generalAssembly_id: 89,
									},
									{
										id: 636,
										name: "GATARE",
										generalAssembly_id: 89,
									},
									{
										id: 637,
										name: "KARENDA",
										generalAssembly_id: 89,
									},
									{
										id: 638,
										name: "MATYAZO",
										generalAssembly_id: 89,
									},
									{
										id: 639,
										name: "MURAMBO",
										generalAssembly_id: 89,
									},
									{
										id: 640,
										name: "RUSEKERA",
										generalAssembly_id: 89,
									},
									{
										id: 641,
										name: "RUTORO",
										generalAssembly_id: 89,
									},
									{
										id: 642,
										name: "RYAMUGENI",
										generalAssembly_id: 89,
									},
									{
										id: 9064,
										name: "Ruhunde  Court of Appeal ",
										generalAssembly_id: 89,
									},
									{
										id: 10597,
										name: "Ruhunde  Gacaca court of sector ",
										generalAssembly_id: 89,
									},
								],
							},
							{
								id: 90,
								name: "Gaseke ",
								sector_id: 50415,
								courtOfCells: [
									{
										id: 643,
										name: "GAHE",
										generalAssembly_id: 90,
									},
									{
										id: 644,
										name: "KANYONI",
										generalAssembly_id: 90,
									},
									{
										id: 645,
										name: "MUKAKA",
										generalAssembly_id: 90,
									},
									{
										id: 646,
										name: "MURAMBO",
										generalAssembly_id: 90,
									},
									{
										id: 647,
										name: "RUKWAVU",
										generalAssembly_id: 90,
									},
									{
										id: 9065,
										name: "Gaseke  Court of Appeal ",
										generalAssembly_id: 90,
									},
									{
										id: 10598,
										name: "Gaseke  Gacaca court of sector ",
										generalAssembly_id: 90,
									},
								],
							},
						],
					},
					{
						id: 50416,
						name: "Rusarabuye",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041603,
								name: "Ruhanga",
								sector_id: 50416,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041602,
								name: "Ndago",
								sector_id: 50416,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041601,
								name: "Kabona",
								sector_id: 50416,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 91,
								name: "Butare",
								sector_id: 50416,
								courtOfCells: [
									{
										id: 648,
										name: "BUTARE",
										generalAssembly_id: 91,
									},
									{
										id: 649,
										name: "GISHA",
										generalAssembly_id: 91,
									},
									{
										id: 9066,
										name: "Butare Court of Appeal ",
										generalAssembly_id: 91,
									},
									{
										id: 10599,
										name: "Butare Gacaca court of sector ",
										generalAssembly_id: 91,
									},
								],
							},
							{
								id: 92,
								name: "Kabingo",
								sector_id: 50416,
								courtOfCells: [
									{
										id: 650,
										name: "BYUMBA",
										generalAssembly_id: 92,
									},
									{
										id: 651,
										name: "KINIHIRA",
										generalAssembly_id: 92,
									},
									{
										id: 652,
										name: "MUGOMERO",
										generalAssembly_id: 92,
									},
									{
										id: 9067,
										name: "Kabingo Court of Appeal ",
										generalAssembly_id: 92,
									},
									{
										id: 10600,
										name: "Kabingo Gacaca court of sector ",
										generalAssembly_id: 92,
									},
								],
							},
							{
								id: 93,
								name: "Rusarabuge",
								sector_id: 50416,
								courtOfCells: [
									{
										id: 653,
										name: "KABIRA",
										generalAssembly_id: 93,
									},
									{
										id: 654,
										name: "KABUKOKO",
										generalAssembly_id: 93,
									},
									{
										id: 655,
										name: "NYANAMO",
										generalAssembly_id: 93,
									},
									{
										id: 9068,
										name: "Rusarabuge Court of Appeal ",
										generalAssembly_id: 93,
									},
									{
										id: 10601,
										name: "Rusarabuge Gacaca court of sector ",
										generalAssembly_id: 93,
									},
								],
							},
						],
					},
					{
						id: 50417,
						name: "Rwerere",
						district_id: 504,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5041704,
								name: "Rugari",
								sector_id: 50417,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041703,
								name: "Ruconsho",
								sector_id: 50417,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041702,
								name: "Gashoro",
								sector_id: 50417,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5041701,
								name: "Gacundura",
								sector_id: 50417,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 94,
								name: "Gacundura ",
								sector_id: 50417,
								courtOfCells: [
									{
										id: 656,
										name: "GACUNDURA",
										generalAssembly_id: 94,
									},
									{
										id: 657,
										name: "SARAMBWE",
										generalAssembly_id: 94,
									},
									{
										id: 9069,
										name: "Gacundura  Court of Appeal ",
										generalAssembly_id: 94,
									},
									{
										id: 10602,
										name: "Gacundura  Gacaca court of sector ",
										generalAssembly_id: 94,
									},
								],
							},
							{
								id: 95,
								name: "Rwerere ",
								sector_id: 50417,
								courtOfCells: [
									{
										id: 658,
										name: "BISAGA",
										generalAssembly_id: 95,
									},
									{
										id: 659,
										name: "GAKO",
										generalAssembly_id: 95,
									},
									{
										id: 660,
										name: "GASHORO",
										generalAssembly_id: 95,
									},
									{
										id: 9070,
										name: "Rwerere  Court of Appeal ",
										generalAssembly_id: 95,
									},
									{
										id: 10603,
										name: "Rwerere  Gacaca court of sector ",
										generalAssembly_id: 95,
									},
								],
							},
							{
								id: 96,
								name: "Mushubi ",
								sector_id: 50417,
								courtOfCells: [
									{
										id: 661,
										name: "BUHANGA",
										generalAssembly_id: 96,
									},
									{
										id: 662,
										name: "KIBANDE",
										generalAssembly_id: 96,
									},
									{
										id: 663,
										name: "RUGARI",
										generalAssembly_id: 96,
									},
									{
										id: 664,
										name: "TANGATA",
										generalAssembly_id: 96,
									},
									{
										id: 9071,
										name: "Mushubi  Court of Appeal ",
										generalAssembly_id: 96,
									},
									{
										id: 10604,
										name: "Mushubi  Gacaca court of sector ",
										generalAssembly_id: 96,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 502,
				name: "Gakenke",
				province_id: 5,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 50201,
						name: "Busengo ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020107,
								name: "Ruhanga ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020106,
								name: "Mwumba ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020105,
								name: "Kirabo ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020104,
								name: "Kamina ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020103,
								name: "Byibuhiro ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020102,
								name: "Butereri ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020101,
								name: "Birambo ",
								sector_id: 50201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 97,
								name: "Bwisha",
								sector_id: 50201,
								courtOfCells: [
									{
										id: 665,
										name: "BUNYANGEZI",
										generalAssembly_id: 97,
									},
									{
										id: 666,
										name: "KAJERERI",
										generalAssembly_id: 97,
									},
									{
										id: 667,
										name: "KIRABO",
										generalAssembly_id: 97,
									},
									{
										id: 668,
										name: "MWENDO",
										generalAssembly_id: 97,
									},
									{
										id: 669,
										name: "NGEZI",
										generalAssembly_id: 97,
									},
									{
										id: 670,
										name: "NYARUBUYE",
										generalAssembly_id: 97,
									},
									{
										id: 671,
										name: "RUSEBEYA",
										generalAssembly_id: 97,
									},
									{
										id: 672,
										name: "RWANKUBA",
										generalAssembly_id: 97,
									},
									{
										id: 9072,
										name: "Bwisha Court of Appeal ",
										generalAssembly_id: 97,
									},
									{
										id: 10605,
										name: "Bwisha Gacaca court of sector ",
										generalAssembly_id: 97,
									},
								],
							},
							{
								id: 98,
								name: "Busengo",
								sector_id: 50201,
								courtOfCells: [
									{
										id: 673,
										name: "GASHIRWE",
										generalAssembly_id: 98,
									},
									{
										id: 674,
										name: "KABUGA",
										generalAssembly_id: 98,
									},
									{
										id: 675,
										name: "KAMONYI",
										generalAssembly_id: 98,
									},
									{
										id: 676,
										name: "MWUMBA",
										generalAssembly_id: 98,
									},
									{
										id: 677,
										name: "RUHANGA",
										generalAssembly_id: 98,
									},
									{
										id: 9073,
										name: "Busengo Court of Appeal ",
										generalAssembly_id: 98,
									},
									{
										id: 10606,
										name: "Busengo Gacaca court of sector ",
										generalAssembly_id: 98,
									},
								],
							},
							{
								id: 99,
								name: "Cyibumba",
								sector_id: 50201,
								courtOfCells: [
									{
										id: 678,
										name: "BIRAMBO",
										generalAssembly_id: 99,
									},
									{
										id: 679,
										name: "BURUSHYA",
										generalAssembly_id: 99,
									},
									{
										id: 680,
										name: "GATOKI",
										generalAssembly_id: 99,
									},
									{
										id: 681,
										name: "GITWA",
										generalAssembly_id: 99,
									},
									{
										id: 682,
										name: "KARAMBI",
										generalAssembly_id: 99,
									},
									{
										id: 683,
										name: "NYAGASOZI",
										generalAssembly_id: 99,
									},
									{
										id: 684,
										name: "RUBOZA",
										generalAssembly_id: 99,
									},
									{
										id: 685,
										name: "KIBUYE",
										generalAssembly_id: 99,
									},
									{
										id: 686,
										name: "KIRWA",
										generalAssembly_id: 99,
									},
									{
										id: 9074,
										name: "Cyibumba Court of Appeal ",
										generalAssembly_id: 99,
									},
									{
										id: 10607,
										name: "Cyibumba Gacaca court of sector ",
										generalAssembly_id: 99,
									},
								],
							},
							{
								id: 100,
								name: "Bugaragara ",
								sector_id: 50201,
								courtOfCells: [
									{
										id: 687,
										name: "BUHUGA",
										generalAssembly_id: 100,
									},
									{
										id: 688,
										name: "BUTERERI",
										generalAssembly_id: 100,
									},
									{
										id: 689,
										name: "KIRWA",
										generalAssembly_id: 100,
									},
									{
										id: 690,
										name: "RUBAGA",
										generalAssembly_id: 100,
									},
									{
										id: 691,
										name: "RUGENDABARI",
										generalAssembly_id: 100,
									},
									{
										id: 9075,
										name: "Bugaragara  Court of Appeal ",
										generalAssembly_id: 100,
									},
									{
										id: 10608,
										name: "Bugaragara  Gacaca court of sector ",
										generalAssembly_id: 100,
									},
								],
							},
						],
					},
					{
						id: 50202,
						name: "Coko ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020204,
								name: "Nyanza ",
								sector_id: 50202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020203,
								name: "Nyange ",
								sector_id: 50202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020202,
								name: "Mbirima ",
								sector_id: 50202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020201,
								name: "Kiruku ",
								sector_id: 50202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 101,
								name: "Coko",
								sector_id: 50202,
								courtOfCells: [
									{
										id: 692,
										name: "BARAMBA",
										generalAssembly_id: 101,
									},
									{
										id: 693,
										name: "GITABA",
										generalAssembly_id: 101,
									},
									{
										id: 694,
										name: "KIBINGO",
										generalAssembly_id: 101,
									},
									{
										id: 695,
										name: "NYANZA",
										generalAssembly_id: 101,
									},
									{
										id: 696,
										name: "TUMBA",
										generalAssembly_id: 101,
									},
									{
										id: 9076,
										name: "Coko Court of Appeal ",
										generalAssembly_id: 101,
									},
									{
										id: 10609,
										name: "Coko Gacaca court of sector ",
										generalAssembly_id: 101,
									},
								],
							},
							{
								id: 102,
								name: "Kiruku ",
								sector_id: 50202,
								courtOfCells: [
									{
										id: 697,
										name: "BUHULI",
										generalAssembly_id: 102,
									},
									{
										id: 698,
										name: "BUKAMBA",
										generalAssembly_id: 102,
									},
									{
										id: 699,
										name: "BUSHAGASHI",
										generalAssembly_id: 102,
									},
									{
										id: 700,
										name: "MUCUMAZO",
										generalAssembly_id: 102,
									},
									{
										id: 701,
										name: "NTARABANA",
										generalAssembly_id: 102,
									},
									{
										id: 702,
										name: "NYAMASUKA",
										generalAssembly_id: 102,
									},
									{
										id: 703,
										name: "RUBUGUMA",
										generalAssembly_id: 102,
									},
									{
										id: 9077,
										name: "Kiruku  Court of Appeal ",
										generalAssembly_id: 102,
									},
									{
										id: 10610,
										name: "Kiruku  Gacaca court of sector ",
										generalAssembly_id: 102,
									},
								],
							},
							{
								id: 103,
								name: "Mbirima ",
								sector_id: 50202,
								courtOfCells: [
									{
										id: 704,
										name: "AKANDUGA",
										generalAssembly_id: 103,
									},
									{
										id: 705,
										name: "MBOGO",
										generalAssembly_id: 103,
									},
									{
										id: 706,
										name: "MURAMBI",
										generalAssembly_id: 103,
									},
									{
										id: 707,
										name: "RWAHI",
										generalAssembly_id: 103,
									},
									{
										id: 708,
										name: "SHYUNGA",
										generalAssembly_id: 103,
									},
									{
										id: 9078,
										name: "Mbirima  Court of Appeal ",
										generalAssembly_id: 103,
									},
									{
										id: 10611,
										name: "Mbirima  Gacaca court of sector ",
										generalAssembly_id: 103,
									},
								],
							},
							{
								id: 104,
								name: "Nyange ",
								sector_id: 50202,
								courtOfCells: [
									{
										id: 709,
										name: "BUHARA",
										generalAssembly_id: 104,
									},
									{
										id: 710,
										name: "GASEKE",
										generalAssembly_id: 104,
									},
									{
										id: 711,
										name: "GASHINYA",
										generalAssembly_id: 104,
									},
									{
										id: 712,
										name: "KAROLI",
										generalAssembly_id: 104,
									},
									{
										id: 713,
										name: "MUSASA",
										generalAssembly_id: 104,
									},
									{
										id: 714,
										name: "NTOBWE",
										generalAssembly_id: 104,
									},
									{
										id: 9079,
										name: "Nyange  Court of Appeal ",
										generalAssembly_id: 104,
									},
									{
										id: 10612,
										name: "Nyange  Gacaca court of sector ",
										generalAssembly_id: 104,
									},
								],
							},
						],
					},
					{
						id: 50203,
						name: "Cyabingo ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020305,
								name: "Rukore ",
								sector_id: 50203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020304,
								name: "Mutanda ",
								sector_id: 50203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020303,
								name: "Muramba ",
								sector_id: 50203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020302,
								name: "Muhororo ",
								sector_id: 50203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020301,
								name: "Muhaza ",
								sector_id: 50203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 105,
								name: "Cyabingo ",
								sector_id: 50203,
								courtOfCells: [
									{
										id: 715,
										name: "CYABINGO",
										generalAssembly_id: 105,
									},
									{
										id: 716,
										name: "GISHUBI",
										generalAssembly_id: 105,
									},
									{
										id: 717,
										name: "KAMBARE",
										generalAssembly_id: 105,
									},
									{
										id: 718,
										name: "MUCACA",
										generalAssembly_id: 105,
									},
									{
										id: 719,
										name: "MUTANDA",
										generalAssembly_id: 105,
									},
									{
										id: 9080,
										name: "Cyabingo  Court of Appeal ",
										generalAssembly_id: 105,
									},
									{
										id: 10613,
										name: "Cyabingo  Gacaca court of sector ",
										generalAssembly_id: 105,
									},
								],
							},
							{
								id: 106,
								name: "Muhaza ",
								sector_id: 50203,
								courtOfCells: [
									{
										id: 720,
										name: "BURAZA",
										generalAssembly_id: 106,
									},
									{
										id: 721,
										name: "BUSOGA",
										generalAssembly_id: 106,
									},
									{
										id: 722,
										name: "JOMBA",
										generalAssembly_id: 106,
									},
									{
										id: 723,
										name: "MUHAZA",
										generalAssembly_id: 106,
									},
									{
										id: 724,
										name: "NTARAGA",
										generalAssembly_id: 106,
									},
									{
										id: 9081,
										name: "Muhaza  Court of Appeal ",
										generalAssembly_id: 106,
									},
									{
										id: 10614,
										name: "Muhaza  Gacaca court of sector ",
										generalAssembly_id: 106,
									},
								],
							},
							{
								id: 107,
								name: "Muhororo ",
								sector_id: 50203,
								courtOfCells: [
									{
										id: 725,
										name: "GATOKI",
										generalAssembly_id: 107,
									},
									{
										id: 726,
										name: "KABUNGWE",
										generalAssembly_id: 107,
									},
									{
										id: 727,
										name: "MUHORORO",
										generalAssembly_id: 107,
									},
									{
										id: 728,
										name: "MUSEBEYA",
										generalAssembly_id: 107,
									},
									{
										id: 729,
										name: "TONGOBORO",
										generalAssembly_id: 107,
									},
									{
										id: 9082,
										name: "Muhororo  Court of Appeal ",
										generalAssembly_id: 107,
									},
									{
										id: 10615,
										name: "Muhororo  Gacaca court of sector ",
										generalAssembly_id: 107,
									},
								],
							},
							{
								id: 108,
								name: "Muramba",
								sector_id: 50203,
								courtOfCells: [
									{
										id: 730,
										name: "BUKUBA",
										generalAssembly_id: 108,
									},
									{
										id: 731,
										name: "GAHAMA",
										generalAssembly_id: 108,
									},
									{
										id: 732,
										name: "MUSEBEYA",
										generalAssembly_id: 108,
									},
									{
										id: 733,
										name: "NTEBEYIMANDWA",
										generalAssembly_id: 108,
									},
									{
										id: 734,
										name: "RUGARAGARA",
										generalAssembly_id: 108,
									},
									{
										id: 735,
										name: "RWOBE",
										generalAssembly_id: 108,
									},
									{
										id: 9083,
										name: "Muramba Court of Appeal ",
										generalAssembly_id: 108,
									},
									{
										id: 10616,
										name: "Muramba Gacaca court of sector ",
										generalAssembly_id: 108,
									},
								],
							},
							{
								id: 109,
								name: "Rukore",
								sector_id: 50203,
								courtOfCells: [
									{
										id: 736,
										name: "KIGOTE",
										generalAssembly_id: 109,
									},
									{
										id: 737,
										name: "MURAMBA",
										generalAssembly_id: 109,
									},
									{
										id: 738,
										name: "MUREHE",
										generalAssembly_id: 109,
									},
									{
										id: 739,
										name: "NYABISIKA",
										generalAssembly_id: 109,
									},
									{
										id: 740,
										name: "NYAMUGALI",
										generalAssembly_id: 109,
									},
									{
										id: 741,
										name: "RUKORE",
										generalAssembly_id: 109,
									},
									{
										id: 9084,
										name: "Rukore Court of Appeal ",
										generalAssembly_id: 109,
									},
									{
										id: 10617,
										name: "Rukore Gacaca court of sector ",
										generalAssembly_id: 109,
									},
								],
							},
						],
					},
					{
						id: 50204,
						name: "Gakenke ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020404,
								name: "Rusagara ",
								sector_id: 50204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020403,
								name: "Nganzo ",
								sector_id: 50204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020402,
								name: "Kagoma ",
								sector_id: 50204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020401,
								name: "Buheta ",
								sector_id: 50204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 110,
								name: "Gakenke ",
								sector_id: 50204,
								courtOfCells: [
									{
										id: 742,
										name: "GITENGA",
										generalAssembly_id: 110,
									},
									{
										id: 743,
										name: "MAZINGA",
										generalAssembly_id: 110,
									},
									{
										id: 744,
										name: "NYAMABUYE",
										generalAssembly_id: 110,
									},
									{
										id: 745,
										name: "RUSAGARA",
										generalAssembly_id: 110,
									},
									{
										id: 746,
										name: "SITWE",
										generalAssembly_id: 110,
									},
									{
										id: 9085,
										name: "Gakenke  Court of Appeal ",
										generalAssembly_id: 110,
									},
									{
										id: 10618,
										name: "Gakenke  Gacaca court of sector ",
										generalAssembly_id: 110,
									},
								],
							},
							{
								id: 111,
								name: "Kiriba",
								sector_id: 50204,
								courtOfCells: [
									{
										id: 747,
										name: "GISHYINGURO",
										generalAssembly_id: 111,
									},
									{
										id: 748,
										name: "KAMATARE",
										generalAssembly_id: 111,
									},
									{
										id: 749,
										name: "KAREHE",
										generalAssembly_id: 111,
									},
									{
										id: 750,
										name: "MBOGO",
										generalAssembly_id: 111,
									},
									{
										id: 751,
										name: "MURAMBI",
										generalAssembly_id: 111,
									},
									{
										id: 752,
										name: "NGANZO",
										generalAssembly_id: 111,
									},
									{
										id: 753,
										name: "RUSURI",
										generalAssembly_id: 111,
									},
									{
										id: 9086,
										name: "Kiriba Court of Appeal ",
										generalAssembly_id: 111,
									},
									{
										id: 10619,
										name: "Kiriba Gacaca court of sector ",
										generalAssembly_id: 111,
									},
								],
							},
							{
								id: 112,
								name: "Ruhangari",
								sector_id: 50204,
								courtOfCells: [
									{
										id: 754,
										name: "KAMATARE",
										generalAssembly_id: 112,
									},
									{
										id: 755,
										name: "MURAMA",
										generalAssembly_id: 112,
									},
									{
										id: 756,
										name: "MURAMBI",
										generalAssembly_id: 112,
									},
									{
										id: 757,
										name: "RUSURI",
										generalAssembly_id: 112,
									},
									{
										id: 9087,
										name: "Ruhangari Court of Appeal ",
										generalAssembly_id: 112,
									},
									{
										id: 10620,
										name: "Ruhangari Gacaca court of sector ",
										generalAssembly_id: 112,
									},
								],
							},
							{
								id: 113,
								name: "Buheta",
								sector_id: 50204,
								courtOfCells: [
									{
										id: 758,
										name: "BUSOGI",
										generalAssembly_id: 113,
									},
									{
										id: 759,
										name: "KABAYA",
										generalAssembly_id: 113,
									},
									{
										id: 760,
										name: "KIBINGO",
										generalAssembly_id: 113,
									},
									{
										id: 761,
										name: "MUGOGWE",
										generalAssembly_id: 113,
									},
									{
										id: 762,
										name: "NYABIGOMA",
										generalAssembly_id: 113,
									},
									{
										id: 9088,
										name: "Buheta Court of Appeal ",
										generalAssembly_id: 113,
									},
									{
										id: 10621,
										name: "Buheta Gacaca court of sector ",
										generalAssembly_id: 113,
									},
								],
							},
						],
					},
					{
						id: 50205,
						name: "Gashenyi ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020505,
								name: "Taba ",
								sector_id: 50205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020504,
								name: "Rutenderi ",
								sector_id: 50205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020503,
								name: "Rutabo ",
								sector_id: 50205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020502,
								name: "Rukura ",
								sector_id: 50205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020501,
								name: "Nyacyina ",
								sector_id: 50205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 114,
								name: "Gashenyi",
								sector_id: 50205,
								courtOfCells: [
									{
										id: 763,
										name: "BWIYANDO",
										generalAssembly_id: 114,
									},
									{
										id: 764,
										name: "GIHANGA",
										generalAssembly_id: 114,
									},
									{
										id: 765,
										name: "MURAMBI",
										generalAssembly_id: 114,
									},
									{
										id: 766,
										name: "MWISHA",
										generalAssembly_id: 114,
									},
									{
										id: 767,
										name: "TABA",
										generalAssembly_id: 114,
									},
									{
										id: 9089,
										name: "Gashenyi Court of Appeal ",
										generalAssembly_id: 114,
									},
									{
										id: 10622,
										name: "Gashenyi Gacaca court of sector ",
										generalAssembly_id: 114,
									},
								],
							},
							{
								id: 115,
								name: "Gihinga",
								sector_id: 50205,
								courtOfCells: [
									{
										id: 768,
										name: "BUHIRA",
										generalAssembly_id: 115,
									},
									{
										id: 769,
										name: "KABWIKA",
										generalAssembly_id: 115,
									},
									{
										id: 770,
										name: "MUKIRA",
										generalAssembly_id: 115,
									},
									{
										id: 771,
										name: "NYACYINA",
										generalAssembly_id: 115,
									},
									{
										id: 772,
										name: "RUBUGA",
										generalAssembly_id: 115,
									},
									{
										id: 773,
										name: "RUGENDABARI",
										generalAssembly_id: 115,
									},
									{
										id: 774,
										name: "RUTABO",
										generalAssembly_id: 115,
									},
									{
										id: 9090,
										name: "Gihinga Court of Appeal ",
										generalAssembly_id: 115,
									},
									{
										id: 10623,
										name: "Gihinga Gacaca court of sector ",
										generalAssembly_id: 115,
									},
								],
							},
							{
								id: 116,
								name: "Rukura",
								sector_id: 50205,
								courtOfCells: [
									{
										id: 775,
										name: "GAHIHI",
										generalAssembly_id: 116,
									},
									{
										id: 776,
										name: "KARA",
										generalAssembly_id: 116,
									},
									{
										id: 777,
										name: "MURANDI",
										generalAssembly_id: 116,
									},
									{
										id: 778,
										name: "NYAMATAHA",
										generalAssembly_id: 116,
									},
									{
										id: 9091,
										name: "Rukura Court of Appeal ",
										generalAssembly_id: 116,
									},
									{
										id: 10624,
										name: "Rukura Gacaca court of sector ",
										generalAssembly_id: 116,
									},
								],
							},
							{
								id: 117,
								name: "Rutenderi ",
								sector_id: 50205,
								courtOfCells: [
									{
										id: 779,
										name: "GASEKE",
										generalAssembly_id: 117,
									},
									{
										id: 780,
										name: "GITABA",
										generalAssembly_id: 117,
									},
									{
										id: 781,
										name: "KABERE",
										generalAssembly_id: 117,
									},
									{
										id: 782,
										name: "KABUGOMBA",
										generalAssembly_id: 117,
									},
									{
										id: 783,
										name: "KIBARA",
										generalAssembly_id: 117,
									},
									{
										id: 784,
										name: "MURAMBO",
										generalAssembly_id: 117,
									},
									{
										id: 785,
										name: "RUTENDERI",
										generalAssembly_id: 117,
									},
									{
										id: 9092,
										name: "Rutenderi  Court of Appeal ",
										generalAssembly_id: 117,
									},
									{
										id: 10625,
										name: "Rutenderi  Gacaca court of sector ",
										generalAssembly_id: 117,
									},
								],
							},
						],
					},
					{
						id: 50206,
						name: "Janja ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020604,
								name: "Karukungu ",
								sector_id: 50206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020603,
								name: "Gatwa ",
								sector_id: 50206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020602,
								name: "Gashyamba ",
								sector_id: 50206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020601,
								name: "Gakindo ",
								sector_id: 50206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 118,
								name: "Gakenke",
								sector_id: 50206,
								courtOfCells: [
									{
										id: 786,
										name: "BUKERERA",
										generalAssembly_id: 118,
									},
									{
										id: 787,
										name: "GAKINDO",
										generalAssembly_id: 118,
									},
									{
										id: 788,
										name: "KABUSORO",
										generalAssembly_id: 118,
									},
									{
										id: 789,
										name: "RUBONA",
										generalAssembly_id: 118,
									},
									{
										id: 790,
										name: "RURUMBYA",
										generalAssembly_id: 118,
									},
									{
										id: 9093,
										name: "Gakenke Court of Appeal ",
										generalAssembly_id: 118,
									},
									{
										id: 10626,
										name: "Gakenke Gacaca court of sector ",
										generalAssembly_id: 118,
									},
								],
							},
							{
								id: 119,
								name: "Janja",
								sector_id: 50206,
								courtOfCells: [
									{
										id: 791,
										name: "JANJA",
										generalAssembly_id: 119,
									},
									{
										id: 792,
										name: "MURAMBI",
										generalAssembly_id: 119,
									},
									{
										id: 793,
										name: "MWANZA",
										generalAssembly_id: 119,
									},
									{
										id: 794,
										name: "NYABUSHISHIRI",
										generalAssembly_id: 119,
									},
									{
										id: 795,
										name: "NYAGISOZI",
										generalAssembly_id: 119,
									},
									{
										id: 9094,
										name: "Janja Court of Appeal ",
										generalAssembly_id: 119,
									},
									{
										id: 10627,
										name: "Janja Gacaca court of sector ",
										generalAssembly_id: 119,
									},
								],
							},
							{
								id: 120,
								name: "Kivune",
								sector_id: 50206,
								courtOfCells: [
									{
										id: 796,
										name: "BUREGA",
										generalAssembly_id: 120,
									},
									{
										id: 797,
										name: "GASHYAMBA",
										generalAssembly_id: 120,
									},
									{
										id: 798,
										name: "GATONGO",
										generalAssembly_id: 120,
									},
									{
										id: 799,
										name: "GITOVU",
										generalAssembly_id: 120,
									},
									{
										id: 800,
										name: "RWAMPARI",
										generalAssembly_id: 120,
									},
									{
										id: 9095,
										name: "Kivune Court of Appeal ",
										generalAssembly_id: 120,
									},
									{
										id: 10628,
										name: "Kivune Gacaca court of sector ",
										generalAssembly_id: 120,
									},
								],
							},
							{
								id: 121,
								name: "Mugandu",
								sector_id: 50206,
								courtOfCells: [
									{
										id: 801,
										name: "CYIFUZO",
										generalAssembly_id: 121,
									},
									{
										id: 802,
										name: "KARAMA",
										generalAssembly_id: 121,
									},
									{
										id: 803,
										name: "KARUKUNGU",
										generalAssembly_id: 121,
									},
									{
										id: 804,
										name: "RUTAKE",
										generalAssembly_id: 121,
									},
									{
										id: 9096,
										name: "Mugandu Court of Appeal ",
										generalAssembly_id: 121,
									},
									{
										id: 10629,
										name: "Mugandu Gacaca court of sector ",
										generalAssembly_id: 121,
									},
								],
							},
						],
					},
					{
						id: 50207,
						name: "Kamubuga ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020704,
								name: "Rukore ",
								sector_id: 50207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020703,
								name: "Mbatabata ",
								sector_id: 50207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020702,
								name: "Kidomo ",
								sector_id: 50207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020701,
								name: "Kamubuga ",
								sector_id: 50207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 122,
								name: "Kamubuga",
								sector_id: 50207,
								courtOfCells: [
									{
										id: 805,
										name: "GITWE",
										generalAssembly_id: 122,
									},
									{
										id: 806,
										name: "KAMUBUGA I",
										generalAssembly_id: 122,
									},
									{
										id: 807,
										name: "KAMUBUGA II",
										generalAssembly_id: 122,
									},
									{
										id: 808,
										name: "KANSHENGE",
										generalAssembly_id: 122,
									},
									{
										id: 809,
										name: "RARO",
										generalAssembly_id: 122,
									},
									{
										id: 9097,
										name: "Kamubuga Court of Appeal ",
										generalAssembly_id: 122,
									},
									{
										id: 10630,
										name: "Kamubuga Gacaca court of sector ",
										generalAssembly_id: 122,
									},
								],
							},
							{
								id: 123,
								name: "Karingorera",
								sector_id: 50207,
								courtOfCells: [
									{
										id: 810,
										name: "BUHINDA",
										generalAssembly_id: 123,
									},
									{
										id: 811,
										name: "KARINGORERA",
										generalAssembly_id: 123,
									},
									{
										id: 812,
										name: "MWASHA",
										generalAssembly_id: 123,
									},
									{
										id: 9098,
										name: "Karingorera Court of Appeal ",
										generalAssembly_id: 123,
									},
									{
										id: 10631,
										name: "Karingorera Gacaca court of sector ",
										generalAssembly_id: 123,
									},
								],
							},
							{
								id: 124,
								name: "Kidomo ",
								sector_id: 50207,
								courtOfCells: [
									{
										id: 813,
										name: "BUGOGO",
										generalAssembly_id: 124,
									},
									{
										id: 814,
										name: "NYAGATOVU",
										generalAssembly_id: 124,
									},
									{
										id: 815,
										name: "RUTAGARA",
										generalAssembly_id: 124,
									},
									{
										id: 9099,
										name: "Kidomo  Court of Appeal ",
										generalAssembly_id: 124,
									},
									{
										id: 10632,
										name: "Kidomo  Gacaca court of sector ",
										generalAssembly_id: 124,
									},
								],
							},
							{
								id: 125,
								name: "Rukore",
								sector_id: 50207,
								courtOfCells: [
									{
										id: 816,
										name: "RUNGU",
										generalAssembly_id: 125,
									},
									{
										id: 817,
										name: "RUSASA",
										generalAssembly_id: 125,
									},
									{
										id: 818,
										name: "TABA",
										generalAssembly_id: 125,
									},
									{
										id: 9100,
										name: "Rukore Court of Appeal ",
										generalAssembly_id: 125,
									},
									{
										id: 10633,
										name: "Rukore Gacaca court of sector ",
										generalAssembly_id: 125,
									},
								],
							},
						],
					},
					{
						id: 50208,
						name: "Karambo ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020803,
								name: "Kirebe ",
								sector_id: 50208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020802,
								name: "Karambo ",
								sector_id: 50208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020801,
								name: "Kanyanza ",
								sector_id: 50208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 126,
								name: "Karambo ",
								sector_id: 50208,
								courtOfCells: [
									{
										id: 819,
										name: "BUGABURI",
										generalAssembly_id: 126,
									},
									{
										id: 820,
										name: "BUHINDA",
										generalAssembly_id: 126,
									},
									{
										id: 821,
										name: "BUHUNU",
										generalAssembly_id: 126,
									},
									{
										id: 822,
										name: "KIGARAMA",
										generalAssembly_id: 126,
									},
									{
										id: 823,
										name: "MUHENDU",
										generalAssembly_id: 126,
									},
									{
										id: 824,
										name: "REMERA",
										generalAssembly_id: 126,
									},
									{
										id: 825,
										name: "BUHEKA",
										generalAssembly_id: 126,
									},
									{
										id: 9101,
										name: "Karambo  Court of Appeal ",
										generalAssembly_id: 126,
									},
									{
										id: 10634,
										name: "Karambo  Gacaca court of sector ",
										generalAssembly_id: 126,
									},
								],
							},
							{
								id: 127,
								name: "Kinyoma",
								sector_id: 50208,
								courtOfCells: [
									{
										id: 826,
										name: "BUGARAGARA",
										generalAssembly_id: 127,
									},
									{
										id: 827,
										name: "BUTONDWE",
										generalAssembly_id: 127,
									},
									{
										id: 828,
										name: "GIHINGA",
										generalAssembly_id: 127,
									},
									{
										id: 829,
										name: "KABAGARI",
										generalAssembly_id: 127,
									},
									{
										id: 830,
										name: "KANYANZA",
										generalAssembly_id: 127,
									},
									{
										id: 831,
										name: "KARENGE",
										generalAssembly_id: 127,
									},
									{
										id: 832,
										name: "KIREBE",
										generalAssembly_id: 127,
									},
									{
										id: 833,
										name: "SOHA",
										generalAssembly_id: 127,
									},
									{
										id: 9102,
										name: "Kinyoma Court of Appeal ",
										generalAssembly_id: 127,
									},
									{
										id: 10635,
										name: "Kinyoma Gacaca court of sector ",
										generalAssembly_id: 127,
									},
								],
							},
						],
					},
					{
						id: 50209,
						name: "Kivuruga ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5020905,
								name: "Sereri ",
								sector_id: 50209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020904,
								name: "Ruhinga ",
								sector_id: 50209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020903,
								name: "Rugimbu ",
								sector_id: 50209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020902,
								name: "Gasiza ",
								sector_id: 50209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5020901,
								name: "Cyintare ",
								sector_id: 50209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 128,
								name: "Rugimbu",
								sector_id: 50209,
								courtOfCells: [
									{
										id: 834,
										name: "GASAVE",
										generalAssembly_id: 128,
									},
									{
										id: 835,
										name: "KARUHUNGE",
										generalAssembly_id: 128,
									},
									{
										id: 836,
										name: "MUGARI",
										generalAssembly_id: 128,
									},
									{
										id: 837,
										name: "NTURO",
										generalAssembly_id: 128,
									},
									{
										id: 838,
										name: "NYARUNGO",
										generalAssembly_id: 128,
									},
									{
										id: 839,
										name: "RURAMBO",
										generalAssembly_id: 128,
									},
									{
										id: 840,
										name: "RUTAMBA",
										generalAssembly_id: 128,
									},
									{
										id: 9103,
										name: "Rugimbu Court of Appeal ",
										generalAssembly_id: 128,
									},
									{
										id: 10636,
										name: "Rugimbu Gacaca court of sector ",
										generalAssembly_id: 128,
									},
								],
							},
							{
								id: 129,
								name: "Mugamba",
								sector_id: 50209,
								courtOfCells: [
									{
										id: 841,
										name: "BUHAYO",
										generalAssembly_id: 129,
									},
									{
										id: 842,
										name: "MASORO",
										generalAssembly_id: 129,
									},
									{
										id: 843,
										name: "SERERI",
										generalAssembly_id: 129,
									},
									{
										id: 9104,
										name: "Mugamba Court of Appeal ",
										generalAssembly_id: 129,
									},
									{
										id: 10637,
										name: "Mugamba Gacaca court of sector ",
										generalAssembly_id: 129,
									},
								],
							},
							{
								id: 130,
								name: "Ruhinga ll",
								sector_id: 50209,
								courtOfCells: [
									{
										id: 844,
										name: "CYINTARE",
										generalAssembly_id: 130,
									},
									{
										id: 845,
										name: "KAVUMU",
										generalAssembly_id: 130,
									},
									{
										id: 846,
										name: "KINTARURE",
										generalAssembly_id: 130,
									},
									{
										id: 847,
										name: "MUNYEGE",
										generalAssembly_id: 130,
									},
									{
										id: 848,
										name: "NYARUBUYE",
										generalAssembly_id: 130,
									},
									{
										id: 849,
										name: "RUGESHI",
										generalAssembly_id: 130,
									},
									{
										id: 850,
										name: "RWAKIRARI",
										generalAssembly_id: 130,
									},
									{
										id: 9105,
										name: "Ruhinga ll Court of Appeal ",
										generalAssembly_id: 130,
									},
									{
										id: 10638,
										name: "Ruhinga ll Gacaca court of sector ",
										generalAssembly_id: 130,
									},
								],
							},
							{
								id: 131,
								name: "Bushoka ",
								sector_id: 50209,
								courtOfCells: [
									{
										id: 851,
										name: "BUHUGA",
										generalAssembly_id: 131,
									},
									{
										id: 852,
										name: "BUSHOKA",
										generalAssembly_id: 131,
									},
									{
										id: 853,
										name: "KABUHOMA",
										generalAssembly_id: 131,
									},
									{
										id: 854,
										name: "KAMWUMBA",
										generalAssembly_id: 131,
									},
									{
										id: 855,
										name: "MUSEKERA",
										generalAssembly_id: 131,
									},
									{
										id: 9106,
										name: "Bushoka  Court of Appeal ",
										generalAssembly_id: 131,
									},
									{
										id: 10639,
										name: "Bushoka  Gacaca court of sector ",
										generalAssembly_id: 131,
									},
								],
							},
						],
					},
					{
						id: 50210,
						name: "Mataba ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021003,
								name: "Nyundo ",
								sector_id: 50210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021002,
								name: "Gikombe ",
								sector_id: 50210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021001,
								name: "Buyange ",
								sector_id: 50210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 132,
								name: "Buhunga ",
								sector_id: 50210,
								courtOfCells: [
									{
										id: 856,
										name: "GASIGA",
										generalAssembly_id: 132,
									},
									{
										id: 857,
										name: "GATARE",
										generalAssembly_id: 132,
									},
									{
										id: 858,
										name: "KARUGANDA",
										generalAssembly_id: 132,
									},
									{
										id: 859,
										name: "MUGWIRA",
										generalAssembly_id: 132,
									},
									{
										id: 860,
										name: "MURAMA",
										generalAssembly_id: 132,
									},
									{
										id: 861,
										name: "NYARUBUYE",
										generalAssembly_id: 132,
									},
									{
										id: 9107,
										name: "Buhunga  Court of Appeal ",
										generalAssembly_id: 132,
									},
									{
										id: 10640,
										name: "Buhunga  Gacaca court of sector ",
										generalAssembly_id: 132,
									},
								],
							},
							{
								id: 133,
								name: "Mataba",
								sector_id: 50210,
								courtOfCells: [
									{
										id: 862,
										name: "BUGALI",
										generalAssembly_id: 133,
									},
									{
										id: 863,
										name: "BWANZO",
										generalAssembly_id: 133,
									},
									{
										id: 864,
										name: "GIKOMBE",
										generalAssembly_id: 133,
									},
									{
										id: 865,
										name: "MUHORORO",
										generalAssembly_id: 133,
									},
									{
										id: 866,
										name: "MUNINI",
										generalAssembly_id: 133,
									},
									{
										id: 9108,
										name: "Mataba Court of Appeal ",
										generalAssembly_id: 133,
									},
									{
										id: 10641,
										name: "Mataba Gacaca court of sector ",
										generalAssembly_id: 133,
									},
								],
							},
							{
								id: 134,
								name: "Tandagura ",
								sector_id: 50210,
								courtOfCells: [
									{
										id: 867,
										name: "GIHITA",
										generalAssembly_id: 134,
									},
									{
										id: 868,
										name: "GITABA",
										generalAssembly_id: 134,
									},
									{
										id: 869,
										name: "MATABA",
										generalAssembly_id: 134,
									},
									{
										id: 870,
										name: "MWANZA",
										generalAssembly_id: 134,
									},
									{
										id: 871,
										name: "NKURAZO",
										generalAssembly_id: 134,
									},
									{
										id: 9109,
										name: "Tandagura  Court of Appeal ",
										generalAssembly_id: 134,
									},
									{
										id: 10642,
										name: "Tandagura  Gacaca court of sector ",
										generalAssembly_id: 134,
									},
								],
							},
						],
					},
					{
						id: 50211,
						name: "Minazi ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021104,
								name: "Raba ",
								sector_id: 50211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021103,
								name: "Murambi ",
								sector_id: 50211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021102,
								name: "Munyana ",
								sector_id: 50211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021101,
								name: "Gasiho ",
								sector_id: 50211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 135,
								name: "Gihanga",
								sector_id: 50211,
								courtOfCells: [
									{
										id: 872,
										name: "GAHOMBO",
										generalAssembly_id: 135,
									},
									{
										id: 873,
										name: "GAHUNDA",
										generalAssembly_id: 135,
									},
									{
										id: 874,
										name: "GASANGWA",
										generalAssembly_id: 135,
									},
									{
										id: 875,
										name: "KABALIMA",
										generalAssembly_id: 135,
									},
									{
										id: 876,
										name: "KIGEYO",
										generalAssembly_id: 135,
									},
									{
										id: 877,
										name: "MBOGO",
										generalAssembly_id: 135,
									},
									{
										id: 878,
										name: "NYAKABOGO",
										generalAssembly_id: 135,
									},
									{
										id: 9110,
										name: "Gihanga Court of Appeal ",
										generalAssembly_id: 135,
									},
									{
										id: 10643,
										name: "Gihanga Gacaca court of sector ",
										generalAssembly_id: 135,
									},
								],
							},
							{
								id: 136,
								name: "Minazi",
								sector_id: 50211,
								courtOfCells: [
									{
										id: 879,
										name: "GIHORORO",
										generalAssembly_id: 136,
									},
									{
										id: 880,
										name: "GISOVU",
										generalAssembly_id: 136,
									},
									{
										id: 881,
										name: "GITWA",
										generalAssembly_id: 136,
									},
									{
										id: 882,
										name: "KABUGA",
										generalAssembly_id: 136,
									},
									{
										id: 883,
										name: "KANKA",
										generalAssembly_id: 136,
									},
									{
										id: 884,
										name: "KIVUBA",
										generalAssembly_id: 136,
									},
									{
										id: 885,
										name: "MURAMBI",
										generalAssembly_id: 136,
									},
									{
										id: 886,
										name: "MUSAVE",
										generalAssembly_id: 136,
									},
									{
										id: 887,
										name: "NYABITARE",
										generalAssembly_id: 136,
									},
									{
										id: 888,
										name: "NYARUBUYE",
										generalAssembly_id: 136,
									},
									{
										id: 9111,
										name: "Minazi Court of Appeal ",
										generalAssembly_id: 136,
									},
									{
										id: 10644,
										name: "Minazi Gacaca court of sector ",
										generalAssembly_id: 136,
									},
								],
							},
							{
								id: 137,
								name: "Raba",
								sector_id: 50211,
								courtOfCells: [
									{
										id: 889,
										name: "BUKONDE",
										generalAssembly_id: 137,
									},
									{
										id: 890,
										name: "GAHARO",
										generalAssembly_id: 137,
									},
									{
										id: 891,
										name: "MUNINI",
										generalAssembly_id: 137,
									},
									{
										id: 892,
										name: "MURAMBI",
										generalAssembly_id: 137,
									},
									{
										id: 893,
										name: "MUTARA",
										generalAssembly_id: 137,
									},
									{
										id: 894,
										name: "NDEGAMIRE",
										generalAssembly_id: 137,
									},
									{
										id: 895,
										name: "SARABUYE",
										generalAssembly_id: 137,
									},
									{
										id: 9112,
										name: "Raba Court of Appeal ",
										generalAssembly_id: 137,
									},
									{
										id: 10645,
										name: "Raba Gacaca court of sector ",
										generalAssembly_id: 137,
									},
								],
							},
						],
					},
					{
						id: 50212,
						name: "Mugunga ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021207,
								name: "Rwamambe ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021206,
								name: "Rutenderi ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021205,
								name: "Rutabo ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021204,
								name: "Nkomane ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021203,
								name: "Mutego ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021202,
								name: "Munyana ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021201,
								name: "Gahinga ",
								sector_id: 50212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 138,
								name: "Kabingo ",
								sector_id: 50212,
								courtOfCells: [
									{
										id: 896,
										name: "KAMASANZE",
										generalAssembly_id: 138,
									},
									{
										id: 897,
										name: "KARAMBO",
										generalAssembly_id: 138,
									},
									{
										id: 898,
										name: "KIRARO",
										generalAssembly_id: 138,
									},
									{
										id: 899,
										name: "MUTEGO",
										generalAssembly_id: 138,
									},
									{
										id: 900,
										name: "NGANZO",
										generalAssembly_id: 138,
									},
									{
										id: 901,
										name: "NYUNDO",
										generalAssembly_id: 138,
									},
									{
										id: 902,
										name: "RUBONA",
										generalAssembly_id: 138,
									},
									{
										id: 903,
										name: "RUTARAGA",
										generalAssembly_id: 138,
									},
									{
										id: 904,
										name: "RUTENDERI",
										generalAssembly_id: 138,
									},
									{
										id: 9113,
										name: "Kabingo  Court of Appeal ",
										generalAssembly_id: 138,
									},
									{
										id: 10646,
										name: "Kabingo  Gacaca court of sector ",
										generalAssembly_id: 138,
									},
								],
							},
							{
								id: 139,
								name: "Mugunga",
								sector_id: 50212,
								courtOfCells: [
									{
										id: 905,
										name: "GICUMBO",
										generalAssembly_id: 139,
									},
									{
										id: 906,
										name: "KABUGA",
										generalAssembly_id: 139,
									},
									{
										id: 907,
										name: "KANABA",
										generalAssembly_id: 139,
									},
									{
										id: 908,
										name: "NEMBA",
										generalAssembly_id: 139,
									},
									{
										id: 909,
										name: "NKOMANE",
										generalAssembly_id: 139,
									},
									{
										id: 910,
										name: "RUSEBEYA",
										generalAssembly_id: 139,
									},
									{
										id: 9114,
										name: "Mugunga Court of Appeal ",
										generalAssembly_id: 139,
									},
									{
										id: 10647,
										name: "Mugunga Gacaca court of sector ",
										generalAssembly_id: 139,
									},
								],
							},
							{
								id: 140,
								name: "Munanira",
								sector_id: 50212,
								courtOfCells: [
									{
										id: 911,
										name: "BURENGA",
										generalAssembly_id: 140,
									},
									{
										id: 912,
										name: "GASHUBI",
										generalAssembly_id: 140,
									},
									{
										id: 913,
										name: "KANYINYA",
										generalAssembly_id: 140,
									},
									{
										id: 914,
										name: "MUNYANA",
										generalAssembly_id: 140,
									},
									{
										id: 915,
										name: "MUTANDA",
										generalAssembly_id: 140,
									},
									{
										id: 916,
										name: "RUHUHA",
										generalAssembly_id: 140,
									},
									{
										id: 917,
										name: "RWAMAMBE",
										generalAssembly_id: 140,
									},
									{
										id: 9115,
										name: "Munanira Court of Appeal ",
										generalAssembly_id: 140,
									},
									{
										id: 10648,
										name: "Munanira Gacaca court of sector ",
										generalAssembly_id: 140,
									},
								],
							},
							{
								id: 141,
								name: "Nyakagezi",
								sector_id: 50212,
								courtOfCells: [
									{
										id: 918,
										name: "GAHINGA",
										generalAssembly_id: 141,
									},
									{
										id: 919,
										name: "KARAMBO",
										generalAssembly_id: 141,
									},
									{
										id: 920,
										name: "KAVUMU",
										generalAssembly_id: 141,
									},
									{
										id: 921,
										name: "RUSEBEYA",
										generalAssembly_id: 141,
									},
									{
										id: 922,
										name: "RUTABO",
										generalAssembly_id: 141,
									},
									{
										id: 9116,
										name: "Nyakagezi Court of Appeal ",
										generalAssembly_id: 141,
									},
									{
										id: 10649,
										name: "Nyakagezi Gacaca court of sector ",
										generalAssembly_id: 141,
									},
								],
							},
						],
					},
					{
						id: 50213,
						name: "Muhondo ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021309,
								name: "Rwinkuba ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021308,
								name: "Ruganda ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021307,
								name: "Musenyi ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021306,
								name: "Musagara ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021305,
								name: "Huro ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021304,
								name: "Gihinga ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021303,
								name: "Gasiza ",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021302,
								name: "Bwenda",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021301,
								name: "Busake",
								sector_id: 50213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 142,
								name: "Bwenda",
								sector_id: 50213,
								courtOfCells: [
									{
										id: 923,
										name: "GAHAMA",
										generalAssembly_id: 142,
									},
									{
										id: 924,
										name: "GATARE",
										generalAssembly_id: 142,
									},
									{
										id: 925,
										name: "GITABA",
										generalAssembly_id: 142,
									},
									{
										id: 926,
										name: "KAREHE",
										generalAssembly_id: 142,
									},
									{
										id: 927,
										name: "KAROBAGIRE",
										generalAssembly_id: 142,
									},
									{
										id: 928,
										name: "KIMANAMA",
										generalAssembly_id: 142,
									},
									{
										id: 929,
										name: "NKETSI",
										generalAssembly_id: 142,
									},
									{
										id: 930,
										name: "TABA",
										generalAssembly_id: 142,
									},
									{
										id: 9117,
										name: "Bwenda Court of Appeal ",
										generalAssembly_id: 142,
									},
									{
										id: 10650,
										name: "Bwenda Gacaca court of sector ",
										generalAssembly_id: 142,
									},
								],
							},
							{
								id: 143,
								name: "Huro",
								sector_id: 50213,
								courtOfCells: [
									{
										id: 931,
										name: "BUHINYA",
										generalAssembly_id: 143,
									},
									{
										id: 932,
										name: "CURA",
										generalAssembly_id: 143,
									},
									{
										id: 933,
										name: "GAHINGA",
										generalAssembly_id: 143,
									},
									{
										id: 934,
										name: "GAKUYU",
										generalAssembly_id: 143,
									},
									{
										id: 935,
										name: "GITWA",
										generalAssembly_id: 143,
									},
									{
										id: 936,
										name: "KIBILIZI",
										generalAssembly_id: 143,
									},
									{
										id: 937,
										name: "KIGALI",
										generalAssembly_id: 143,
									},
									{
										id: 938,
										name: "MUSENYI",
										generalAssembly_id: 143,
									},
									{
										id: 939,
										name: "RUBONA",
										generalAssembly_id: 143,
									},
									{
										id: 9118,
										name: "Huro Court of Appeal ",
										generalAssembly_id: 143,
									},
									{
										id: 10651,
										name: "Huro Gacaca court of sector ",
										generalAssembly_id: 143,
									},
								],
							},
							{
								id: 144,
								name: "Muhondo ",
								sector_id: 50213,
								courtOfCells: [
									{
										id: 940,
										name: "BASE",
										generalAssembly_id: 144,
									},
									{
										id: 941,
										name: "GAHABWA",
										generalAssembly_id: 144,
									},
									{
										id: 942,
										name: "GAHINGA",
										generalAssembly_id: 144,
									},
									{
										id: 943,
										name: "GAHONDO",
										generalAssembly_id: 144,
									},
									{
										id: 944,
										name: "GASIZA",
										generalAssembly_id: 144,
									},
									{
										id: 945,
										name: "GIHINGA",
										generalAssembly_id: 144,
									},
									{
										id: 946,
										name: "GIKIKIRA",
										generalAssembly_id: 144,
									},
									{
										id: 947,
										name: "KABEZA",
										generalAssembly_id: 144,
									},
									{
										id: 948,
										name: "KARAMBI",
										generalAssembly_id: 144,
									},
									{
										id: 949,
										name: "MATARE",
										generalAssembly_id: 144,
									},
									{
										id: 9119,
										name: "Muhondo  Court of Appeal ",
										generalAssembly_id: 144,
									},
									{
										id: 10652,
										name: "Muhondo  Gacaca court of sector ",
										generalAssembly_id: 144,
									},
								],
							},
							{
								id: 145,
								name: "Musagara ",
								sector_id: 50213,
								courtOfCells: [
									{
										id: 950,
										name: "AKARA",
										generalAssembly_id: 145,
									},
									{
										id: 951,
										name: "CYENDA",
										generalAssembly_id: 145,
									},
									{
										id: 952,
										name: "CYIMBOGO",
										generalAssembly_id: 145,
									},
									{
										id: 953,
										name: "GITEME",
										generalAssembly_id: 145,
									},
									{
										id: 954,
										name: "KANYANA",
										generalAssembly_id: 145,
									},
									{
										id: 955,
										name: "KAROBAGIRE",
										generalAssembly_id: 145,
									},
									{
										id: 956,
										name: "RUHOROBERO",
										generalAssembly_id: 145,
									},
									{
										id: 9120,
										name: "Musagara  Court of Appeal ",
										generalAssembly_id: 145,
									},
									{
										id: 10653,
										name: "Musagara  Gacaca court of sector ",
										generalAssembly_id: 145,
									},
								],
							},
							{
								id: 146,
								name: "Ruganda ",
								sector_id: 50213,
								courtOfCells: [
									{
										id: 957,
										name: "BUSAKE",
										generalAssembly_id: 146,
									},
									{
										id: 958,
										name: "GIKIKIRA",
										generalAssembly_id: 146,
									},
									{
										id: 959,
										name: "GISOZI",
										generalAssembly_id: 146,
									},
									{
										id: 960,
										name: "KIBIRIZI",
										generalAssembly_id: 146,
									},
									{
										id: 961,
										name: "KINYONZO",
										generalAssembly_id: 146,
									},
									{
										id: 962,
										name: "NYAKABANDA",
										generalAssembly_id: 146,
									},
									{
										id: 963,
										name: "RANZI",
										generalAssembly_id: 146,
									},
									{
										id: 964,
										name: "RUBONA",
										generalAssembly_id: 146,
									},
									{
										id: 965,
										name: "RUGANDA",
										generalAssembly_id: 146,
									},
									{
										id: 9121,
										name: "Ruganda  Court of Appeal ",
										generalAssembly_id: 146,
									},
									{
										id: 10654,
										name: "Ruganda  Gacaca court of sector ",
										generalAssembly_id: 146,
									},
								],
							},
						],
					},
					{
						id: 50214,
						name: "Muyongwe ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021405,
								name: "Va ",
								sector_id: 50214,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021404,
								name: "Nganzo ",
								sector_id: 50214,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021403,
								name: "Karyango ",
								sector_id: 50214,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021402,
								name: "Gisiza ",
								sector_id: 50214,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021401,
								name: "Bumba ",
								sector_id: 50214,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 147,
								name: "Bumba",
								sector_id: 50214,
								courtOfCells: [
									{
										id: 966,
										name: "BUMBA",
										generalAssembly_id: 147,
									},
									{
										id: 967,
										name: "GIKORO",
										generalAssembly_id: 147,
									},
									{
										id: 968,
										name: "GITOVU",
										generalAssembly_id: 147,
									},
									{
										id: 969,
										name: "RANZI",
										generalAssembly_id: 147,
									},
									{
										id: 970,
										name: "VA I",
										generalAssembly_id: 147,
									},
									{
										id: 971,
										name: "VA II",
										generalAssembly_id: 147,
									},
									{
										id: 9122,
										name: "Bumba Court of Appeal ",
										generalAssembly_id: 147,
									},
									{
										id: 10655,
										name: "Bumba Gacaca court of sector ",
										generalAssembly_id: 147,
									},
								],
							},
							{
								id: 148,
								name: "Muyongwe",
								sector_id: 50214,
								courtOfCells: [
									{
										id: 972,
										name: "GIKOMBE",
										generalAssembly_id: 148,
									},
									{
										id: 973,
										name: "GISIZA",
										generalAssembly_id: 148,
									},
									{
										id: 974,
										name: "GITANDA",
										generalAssembly_id: 148,
									},
									{
										id: 975,
										name: "KIBINGO I",
										generalAssembly_id: 148,
									},
									{
										id: 976,
										name: "KIBINGO II",
										generalAssembly_id: 148,
									},
									{
										id: 977,
										name: "KIRIBA",
										generalAssembly_id: 148,
									},
									{
										id: 978,
										name: "KIYEBE",
										generalAssembly_id: 148,
									},
									{
										id: 979,
										name: "MAHAHA",
										generalAssembly_id: 148,
									},
									{
										id: 980,
										name: "MUGERA",
										generalAssembly_id: 148,
									},
									{
										id: 981,
										name: "MURAMBA",
										generalAssembly_id: 148,
									},
									{
										id: 982,
										name: "RUHOKO",
										generalAssembly_id: 148,
									},
									{
										id: 983,
										name: "SANZARI",
										generalAssembly_id: 148,
									},
									{
										id: 9123,
										name: "Muyongwe Court of Appeal ",
										generalAssembly_id: 148,
									},
									{
										id: 10656,
										name: "Muyongwe Gacaca court of sector ",
										generalAssembly_id: 148,
									},
								],
							},
							{
								id: 149,
								name: "Nganzo ",
								sector_id: 50214,
								courtOfCells: [
									{
										id: 984,
										name: "KABINGO",
										generalAssembly_id: 149,
									},
									{
										id: 985,
										name: "MUHORORO",
										generalAssembly_id: 149,
									},
									{
										id: 986,
										name: "NGANZO",
										generalAssembly_id: 149,
									},
									{
										id: 987,
										name: "NGOMA",
										generalAssembly_id: 149,
									},
									{
										id: 988,
										name: "SHIRU",
										generalAssembly_id: 149,
									},
									{
										id: 989,
										name: "VUGANGOMA",
										generalAssembly_id: 149,
									},
									{
										id: 9124,
										name: "Nganzo  Court of Appeal ",
										generalAssembly_id: 149,
									},
									{
										id: 10657,
										name: "Nganzo  Gacaca court of sector ",
										generalAssembly_id: 149,
									},
								],
							},
						],
					},
					{
						id: 50215,
						name: "Muzo ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021505,
								name: "Rwa ",
								sector_id: 50215,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021504,
								name: "Mwiyando ",
								sector_id: 50215,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021503,
								name: "Mubuga ",
								sector_id: 50215,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021502,
								name: "Kiryamo ",
								sector_id: 50215,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021501,
								name: "Kabatezi ",
								sector_id: 50215,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 150,
								name: "Kiriba",
								sector_id: 50215,
								courtOfCells: [
									{
										id: 990,
										name: "GITABI II",
										generalAssembly_id: 150,
									},
									{
										id: 991,
										name: "GITOKE",
										generalAssembly_id: 150,
									},
									{
										id: 992,
										name: "MUGUGULI",
										generalAssembly_id: 150,
									},
									{
										id: 993,
										name: "MWIYANDO",
										generalAssembly_id: 150,
									},
									{
										id: 994,
										name: "NYAGASOZI",
										generalAssembly_id: 150,
									},
									{
										id: 995,
										name: "RUHONDO",
										generalAssembly_id: 150,
									},
									{
										id: 9125,
										name: "Kiriba Court of Appeal ",
										generalAssembly_id: 150,
									},
									{
										id: 10658,
										name: "Kiriba Gacaca court of sector ",
										generalAssembly_id: 150,
									},
								],
							},
							{
								id: 151,
								name: "Kiringa",
								sector_id: 50215,
								courtOfCells: [
									{
										id: 996,
										name: "BUTAMBWE",
										generalAssembly_id: 151,
									},
									{
										id: 997,
										name: "KAVUZA",
										generalAssembly_id: 151,
									},
									{
										id: 998,
										name: "MUBUGA",
										generalAssembly_id: 151,
									},
									{
										id: 999,
										name: "MWIRIKA",
										generalAssembly_id: 151,
									},
									{
										id: 1000,
										name: "MWURIRE",
										generalAssembly_id: 151,
									},
									{
										id: 1001,
										name: "RUBEJA",
										generalAssembly_id: 151,
									},
									{
										id: 9126,
										name: "Kiringa Court of Appeal ",
										generalAssembly_id: 151,
									},
									{
										id: 10659,
										name: "Kiringa Gacaca court of sector ",
										generalAssembly_id: 151,
									},
								],
							},
							{
								id: 152,
								name: "Muzo",
								sector_id: 50215,
								courtOfCells: [
									{
										id: 1002,
										name: "BITABA",
										generalAssembly_id: 152,
									},
									{
										id: 1003,
										name: "GIHORORO",
										generalAssembly_id: 152,
									},
									{
										id: 1004,
										name: "GIKO",
										generalAssembly_id: 152,
									},
									{
										id: 1005,
										name: "GISANZE",
										generalAssembly_id: 152,
									},
									{
										id: 1006,
										name: "RWA",
										generalAssembly_id: 152,
									},
									{
										id: 9127,
										name: "Muzo Court of Appeal ",
										generalAssembly_id: 152,
									},
									{
										id: 10660,
										name: "Muzo Gacaca court of sector ",
										generalAssembly_id: 152,
									},
								],
							},
							{
								id: 153,
								name: "Mwumba",
								sector_id: 50215,
								courtOfCells: [
									{
										id: 1007,
										name: "KIRYAMO",
										generalAssembly_id: 153,
									},
									{
										id: 1008,
										name: "MBIZI",
										generalAssembly_id: 153,
									},
									{
										id: 1009,
										name: "MBOGO",
										generalAssembly_id: 153,
									},
									{
										id: 1010,
										name: "MUGAMBAZI",
										generalAssembly_id: 153,
									},
									{
										id: 1011,
										name: "MWUMBA",
										generalAssembly_id: 153,
									},
									{
										id: 9128,
										name: "Mwumba Court of Appeal ",
										generalAssembly_id: 153,
									},
									{
										id: 10661,
										name: "Mwumba Gacaca court of sector ",
										generalAssembly_id: 153,
									},
								],
							},
							{
								id: 154,
								name: "Rusoro",
								sector_id: 50215,
								courtOfCells: [
									{
										id: 1012,
										name: "CURUGUSI",
										generalAssembly_id: 154,
									},
									{
										id: 1013,
										name: "GITABI I",
										generalAssembly_id: 154,
									},
									{
										id: 1014,
										name: "KABATEZI",
										generalAssembly_id: 154,
									},
									{
										id: 1015,
										name: "KASHESHE",
										generalAssembly_id: 154,
									},
									{
										id: 1016,
										name: "RUNYINYA",
										generalAssembly_id: 154,
									},
									{
										id: 1017,
										name: "RUSORORO",
										generalAssembly_id: 154,
									},
									{
										id: 9129,
										name: "Rusoro Court of Appeal ",
										generalAssembly_id: 154,
									},
									{
										id: 10662,
										name: "Rusoro Gacaca court of sector ",
										generalAssembly_id: 154,
									},
								],
							},
						],
					},
					{
						id: 50216,
						name: "Nemba ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021604,
								name: "Mucaca ",
								sector_id: 50216,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021603,
								name: "Gisozi ",
								sector_id: 50216,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021602,
								name: "Gahinga ",
								sector_id: 50216,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021601,
								name: "Buranga ",
								sector_id: 50216,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 155,
								name: "Gitovu",
								sector_id: 50216,
								courtOfCells: [
									{
										id: 1018,
										name: "GATARE",
										generalAssembly_id: 155,
									},
									{
										id: 1019,
										name: "KABINGO",
										generalAssembly_id: 155,
									},
									{
										id: 1020,
										name: "KAMUVUNYI",
										generalAssembly_id: 155,
									},
									{
										id: 1021,
										name: "KIRUHURA",
										generalAssembly_id: 155,
									},
									{
										id: 1022,
										name: "MUGANWA",
										generalAssembly_id: 155,
									},
									{
										id: 1023,
										name: "MUNYEGE",
										generalAssembly_id: 155,
									},
									{
										id: 9130,
										name: "Gitovu Court of Appeal ",
										generalAssembly_id: 155,
									},
									{
										id: 10663,
										name: "Gitovu Gacaca court of sector ",
										generalAssembly_id: 155,
									},
								],
							},
							{
								id: 156,
								name: "Kajwi",
								sector_id: 50216,
								courtOfCells: [
									{
										id: 1024,
										name: "GASHENYI",
										generalAssembly_id: 156,
									},
									{
										id: 1025,
										name: "GATARE",
										generalAssembly_id: 156,
									},
									{
										id: 1026,
										name: "GISOZI",
										generalAssembly_id: 156,
									},
									{
										id: 1027,
										name: "KABUSHARA",
										generalAssembly_id: 156,
									},
									{
										id: 1028,
										name: "NYABITEKERO",
										generalAssembly_id: 156,
									},
									{
										id: 1029,
										name: "NYARUGESHI",
										generalAssembly_id: 156,
									},
									{
										id: 9131,
										name: "Kajwi Court of Appeal ",
										generalAssembly_id: 156,
									},
									{
										id: 10664,
										name: "Kajwi Gacaca court of sector ",
										generalAssembly_id: 156,
									},
								],
							},
							{
								id: 157,
								name: "Ruhinga I ",
								sector_id: 50216,
								courtOfCells: [
									{
										id: 1030,
										name: "BITARE",
										generalAssembly_id: 157,
									},
									{
										id: 1031,
										name: "BUKURURA",
										generalAssembly_id: 157,
									},
									{
										id: 1032,
										name: "BURANGA",
										generalAssembly_id: 157,
									},
									{
										id: 1033,
										name: "BUREGO",
										generalAssembly_id: 157,
									},
									{
										id: 1034,
										name: "KABAYA",
										generalAssembly_id: 157,
									},
									{
										id: 1035,
										name: "KIRIMBI",
										generalAssembly_id: 157,
									},
									{
										id: 1036,
										name: "MUKAKA",
										generalAssembly_id: 157,
									},
									{
										id: 9132,
										name: "Ruhinga I  Court of Appeal ",
										generalAssembly_id: 157,
									},
									{
										id: 10665,
										name: "Ruhinga I  Gacaca court of sector ",
										generalAssembly_id: 157,
									},
								],
							},
						],
					},
					{
						id: 50217,
						name: "Ruli ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021705,
								name: "Rwesero ",
								sector_id: 50217,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021704,
								name: "Ruli ",
								sector_id: 50217,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021703,
								name: "Jango ",
								sector_id: 50217,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021702,
								name: "Gikingo ",
								sector_id: 50217,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021701,
								name: "Busoro ",
								sector_id: 50217,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 158,
								name: "Gatagara",
								sector_id: 50217,
								courtOfCells: [
									{
										id: 1037,
										name: "GIHURA",
										generalAssembly_id: 158,
									},
									{
										id: 1038,
										name: "GITONDE",
										generalAssembly_id: 158,
									},
									{
										id: 1039,
										name: "KINYONZO",
										generalAssembly_id: 158,
									},
									{
										id: 1040,
										name: "MUBUGA",
										generalAssembly_id: 158,
									},
									{
										id: 1041,
										name: "MUREHE",
										generalAssembly_id: 158,
									},
									{
										id: 9133,
										name: "Gatagara Court of Appeal ",
										generalAssembly_id: 158,
									},
									{
										id: 10666,
										name: "Gatagara Gacaca court of sector ",
										generalAssembly_id: 158,
									},
								],
							},
							{
								id: 159,
								name: "Gihande",
								sector_id: 50217,
								courtOfCells: [
									{
										id: 1042,
										name: "GATARE",
										generalAssembly_id: 159,
									},
									{
										id: 1043,
										name: "GISIZI",
										generalAssembly_id: 159,
									},
									{
										id: 1044,
										name: "MABAGO",
										generalAssembly_id: 159,
									},
									{
										id: 1045,
										name: "NYARUNYINYA",
										generalAssembly_id: 159,
									},
									{
										id: 9134,
										name: "Gihande Court of Appeal ",
										generalAssembly_id: 159,
									},
									{
										id: 10667,
										name: "Gihande Gacaca court of sector ",
										generalAssembly_id: 159,
									},
								],
							},
							{
								id: 160,
								name: "Gikingo",
								sector_id: 50217,
								courtOfCells: [
									{
										id: 1046,
										name: "BUSHOKA",
										generalAssembly_id: 160,
									},
									{
										id: 1047,
										name: "GATWA",
										generalAssembly_id: 160,
									},
									{
										id: 1048,
										name: "KABINGO",
										generalAssembly_id: 160,
									},
									{
										id: 1049,
										name: "KARANGO",
										generalAssembly_id: 160,
									},
									{
										id: 1050,
										name: "NYAMUGALI",
										generalAssembly_id: 160,
									},
									{
										id: 1051,
										name: "RUMASA",
										generalAssembly_id: 160,
									},
									{
										id: 9135,
										name: "Gikingo Court of Appeal ",
										generalAssembly_id: 160,
									},
									{
										id: 10668,
										name: "Gikingo Gacaca court of sector ",
										generalAssembly_id: 160,
									},
								],
							},
							{
								id: 161,
								name: "Nkara",
								sector_id: 50217,
								courtOfCells: [
									{
										id: 1052,
										name: "CONGORI",
										generalAssembly_id: 161,
									},
									{
										id: 1053,
										name: "CYOGANYONI",
										generalAssembly_id: 161,
									},
									{
										id: 1054,
										name: "GITABA",
										generalAssembly_id: 161,
									},
									{
										id: 1055,
										name: "KIBIRIZI",
										generalAssembly_id: 161,
									},
									{
										id: 1056,
										name: "NKOTO",
										generalAssembly_id: 161,
									},
									{
										id: 1057,
										name: "RUGARAGARA",
										generalAssembly_id: 161,
									},
									{
										id: 9136,
										name: "Nkara Court of Appeal ",
										generalAssembly_id: 161,
									},
									{
										id: 10669,
										name: "Nkara Gacaca court of sector ",
										generalAssembly_id: 161,
									},
								],
							},
							{
								id: 162,
								name: "Ruli",
								sector_id: 50217,
								courtOfCells: [
									{
										id: 1058,
										name: "BALIZA",
										generalAssembly_id: 162,
									},
									{
										id: 1059,
										name: "GAHONDO",
										generalAssembly_id: 162,
									},
									{
										id: 1060,
										name: "GATABA",
										generalAssembly_id: 162,
									},
									{
										id: 1061,
										name: "MUGAMBAZI",
										generalAssembly_id: 162,
									},
									{
										id: 1062,
										name: "MUGWATO",
										generalAssembly_id: 162,
									},
									{
										id: 1063,
										name: "NGAYAKE",
										generalAssembly_id: 162,
									},
									{
										id: 1064,
										name: "NYAKARAMBI",
										generalAssembly_id: 162,
									},
									{
										id: 9137,
										name: "Ruli Court of Appeal ",
										generalAssembly_id: 162,
									},
									{
										id: 10670,
										name: "Ruli Gacaca court of sector ",
										generalAssembly_id: 162,
									},
								],
							},
						],
					},
					{
						id: 50218,
						name: "Rusasa ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021806,
								name: "Rurembo ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021805,
								name: "Rumbi ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021804,
								name: "Nyundo ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021803,
								name: "Murambi ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021802,
								name: "Kamonyi ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021801,
								name: "Gataba ",
								sector_id: 50218,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 163,
								name: "Gahanga ",
								sector_id: 50218,
								courtOfCells: [
									{
										id: 1065,
										name: "BUKIZA",
										generalAssembly_id: 163,
									},
									{
										id: 1066,
										name: "BUYORA",
										generalAssembly_id: 163,
									},
									{
										id: 1067,
										name: "BWANAMO",
										generalAssembly_id: 163,
									},
									{
										id: 1068,
										name: "KARUHUNGE",
										generalAssembly_id: 163,
									},
									{
										id: 1069,
										name: "KIREHE",
										generalAssembly_id: 163,
									},
									{
										id: 1070,
										name: "NINDA",
										generalAssembly_id: 163,
									},
									{
										id: 1071,
										name: "NYANGE",
										generalAssembly_id: 163,
									},
									{
										id: 1072,
										name: "BUHARABUYE",
										generalAssembly_id: 163,
									},
									{
										id: 9138,
										name: "Gahanga  Court of Appeal ",
										generalAssembly_id: 163,
									},
									{
										id: 10671,
										name: "Gahanga  Gacaca court of sector ",
										generalAssembly_id: 163,
									},
								],
							},
							{
								id: 164,
								name: "Rusasa ",
								sector_id: 50218,
								courtOfCells: [
									{
										id: 1073,
										name: "BURINDA",
										generalAssembly_id: 164,
									},
									{
										id: 1074,
										name: "GAKINDO",
										generalAssembly_id: 164,
									},
									{
										id: 1075,
										name: "GAPFURA",
										generalAssembly_id: 164,
									},
									{
										id: 1076,
										name: "GITWE",
										generalAssembly_id: 164,
									},
									{
										id: 1077,
										name: "NYAGAHAMA",
										generalAssembly_id: 164,
									},
									{
										id: 1078,
										name: "RURAMBI",
										generalAssembly_id: 164,
									},
									{
										id: 1079,
										name: "RWEMARIKA",
										generalAssembly_id: 164,
									},
									{
										id: 1080,
										name: "KIDOMO",
										generalAssembly_id: 164,
									},
									{
										id: 9139,
										name: "Rusasa  Court of Appeal ",
										generalAssembly_id: 164,
									},
									{
										id: 10672,
										name: "Rusasa  Gacaca court of sector ",
										generalAssembly_id: 164,
									},
								],
							},
							{
								id: 165,
								name: "Gitwa ",
								sector_id: 50218,
								courtOfCells: [
									{
										id: 1081,
										name: "BUMONYO",
										generalAssembly_id: 165,
									},
									{
										id: 1082,
										name: "GAHAMA",
										generalAssembly_id: 165,
									},
									{
										id: 1083,
										name: "GATABA",
										generalAssembly_id: 165,
									},
									{
										id: 1084,
										name: "KEBERO",
										generalAssembly_id: 165,
									},
									{
										id: 1085,
										name: "KIBAYA",
										generalAssembly_id: 165,
									},
									{
										id: 9140,
										name: "Gitwa  Court of Appeal ",
										generalAssembly_id: 165,
									},
									{
										id: 10673,
										name: "Gitwa  Gacaca court of sector ",
										generalAssembly_id: 165,
									},
								],
							},
							{
								id: 166,
								name: "Nyundo ",
								sector_id: 50218,
								courtOfCells: [
									{
										id: 1086,
										name: "BUKINGO",
										generalAssembly_id: 166,
									},
									{
										id: 1087,
										name: "BUMONYO",
										generalAssembly_id: 166,
									},
									{
										id: 1088,
										name: "GISOVU",
										generalAssembly_id: 166,
									},
									{
										id: 1089,
										name: "NYUNDO",
										generalAssembly_id: 166,
									},
									{
										id: 1090,
										name: "TANE",
										generalAssembly_id: 166,
									},
									{
										id: 9141,
										name: "Nyundo  Court of Appeal ",
										generalAssembly_id: 166,
									},
									{
										id: 10674,
										name: "Nyundo  Gacaca court of sector ",
										generalAssembly_id: 166,
									},
								],
							},
							{
								id: 167,
								name: "Rurembo ",
								sector_id: 50218,
								courtOfCells: [
									{
										id: 1091,
										name: "BUSHOKA",
										generalAssembly_id: 167,
									},
									{
										id: 1092,
										name: "MAZINGA",
										generalAssembly_id: 167,
									},
									{
										id: 1093,
										name: "MURORI",
										generalAssembly_id: 167,
									},
									{
										id: 1094,
										name: "NYAKABUNGO",
										generalAssembly_id: 167,
									},
									{
										id: 1095,
										name: "RUGAMBA",
										generalAssembly_id: 167,
									},
									{
										id: 9142,
										name: "Rurembo  Court of Appeal ",
										generalAssembly_id: 167,
									},
									{
										id: 10675,
										name: "Rurembo  Gacaca court of sector ",
										generalAssembly_id: 167,
									},
								],
							},
						],
					},
					{
						id: 50219,
						name: "Rushashi ",
						district_id: 502,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5021908,
								name: "Shyombwe ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021907,
								name: "Rwankuba ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021906,
								name: "Razi ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021905,
								name: "Mbogo ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021904,
								name: "Kageyo ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021903,
								name: "Joma ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021902,
								name: "Busanane ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5021901,
								name: "Burimba ",
								sector_id: 50219,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 168,
								name: "Gatare ",
								sector_id: 50219,
								courtOfCells: [
									{
										id: 1096,
										name: "BUSORO",
										generalAssembly_id: 168,
									},
									{
										id: 1097,
										name: "GITEMA",
										generalAssembly_id: 168,
									},
									{
										id: 1098,
										name: "JURWE",
										generalAssembly_id: 168,
									},
									{
										id: 1099,
										name: "MBOGO",
										generalAssembly_id: 168,
									},
									{
										id: 1100,
										name: "RUYOVU",
										generalAssembly_id: 168,
									},
									{
										id: 9143,
										name: "Gatare  Court of Appeal ",
										generalAssembly_id: 168,
									},
									{
										id: 10676,
										name: "Gatare  Gacaca court of sector ",
										generalAssembly_id: 168,
									},
								],
							},
							{
								id: 169,
								name: "Joma ",
								sector_id: 50219,
								courtOfCells: [
									{
										id: 1101,
										name: "KABUGA",
										generalAssembly_id: 169,
									},
									{
										id: 1102,
										name: "KANJUGU",
										generalAssembly_id: 169,
									},
									{
										id: 1103,
										name: "MULIHI",
										generalAssembly_id: 169,
									},
									{
										id: 1104,
										name: "NGANGE",
										generalAssembly_id: 169,
									},
									{
										id: 1105,
										name: "RAZI",
										generalAssembly_id: 169,
									},
									{
										id: 1106,
										name: "RUTEMBE",
										generalAssembly_id: 169,
									},
									{
										id: 1107,
										name: "RUYEBE",
										generalAssembly_id: 169,
									},
									{
										id: 9144,
										name: "Joma  Court of Appeal ",
										generalAssembly_id: 169,
									},
									{
										id: 10677,
										name: "Joma  Gacaca court of sector ",
										generalAssembly_id: 169,
									},
								],
							},
							{
								id: 170,
								name: "Rushashi",
								sector_id: 50219,
								courtOfCells: [
									{
										id: 1108,
										name: "BUDILIGA",
										generalAssembly_id: 170,
									},
									{
										id: 1109,
										name: "BULIMBA",
										generalAssembly_id: 170,
									},
									{
										id: 1110,
										name: "GASEKE",
										generalAssembly_id: 170,
									},
									{
										id: 1111,
										name: "GITORERWA",
										generalAssembly_id: 170,
									},
									{
										id: 1112,
										name: "KAGEYO",
										generalAssembly_id: 170,
									},
									{
										id: 1113,
										name: "KARAMBO",
										generalAssembly_id: 170,
									},
									{
										id: 1114,
										name: "KARUNGU",
										generalAssembly_id: 170,
									},
									{
										id: 1115,
										name: "KIBINGO",
										generalAssembly_id: 170,
									},
									{
										id: 1116,
										name: "MUBUGA",
										generalAssembly_id: 170,
									},
									{
										id: 1117,
										name: "NKUBANKARI",
										generalAssembly_id: 170,
									},
									{
										id: 1118,
										name: "RWANKUBA",
										generalAssembly_id: 170,
									},
									{
										id: 9145,
										name: "Rushashi Court of Appeal ",
										generalAssembly_id: 170,
									},
									{
										id: 10678,
										name: "Rushashi Gacaca court of sector ",
										generalAssembly_id: 170,
									},
								],
							},
							{
								id: 171,
								name: "Shyombwe ",
								sector_id: 50219,
								courtOfCells: [
									{
										id: 1119,
										name: "CYANGWE",
										generalAssembly_id: 171,
									},
									{
										id: 1120,
										name: "MUSUMBA",
										generalAssembly_id: 171,
									},
									{
										id: 1121,
										name: "RUDASHYA",
										generalAssembly_id: 171,
									},
									{
										id: 1122,
										name: "RUHONDO",
										generalAssembly_id: 171,
									},
									{
										id: 1123,
										name: "SHYANGIRO",
										generalAssembly_id: 171,
									},
									{
										id: 9146,
										name: "Shyombwe  Court of Appeal ",
										generalAssembly_id: 171,
									},
									{
										id: 10679,
										name: "Shyombwe  Gacaca court of sector ",
										generalAssembly_id: 171,
									},
								],
							},
							{
								id: 172,
								name: "Busanane",
								sector_id: 50219,
								courtOfCells: [
									{
										id: 1124,
										name: "CYATA",
										generalAssembly_id: 172,
									},
									{
										id: 1125,
										name: "GITOVU",
										generalAssembly_id: 172,
									},
									{
										id: 1126,
										name: "KABINGO",
										generalAssembly_id: 172,
									},
									{
										id: 1127,
										name: "KANGANZO",
										generalAssembly_id: 172,
									},
									{
										id: 1128,
										name: "KAVUMO",
										generalAssembly_id: 172,
									},
									{
										id: 1129,
										name: "MURAMBI",
										generalAssembly_id: 172,
									},
									{
										id: 9147,
										name: "Busanane Court of Appeal ",
										generalAssembly_id: 172,
									},
									{
										id: 10680,
										name: "Busanane Gacaca court of sector ",
										generalAssembly_id: 172,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 505,
				name: "Gicumbi",
				province_id: 5,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 50501,
						name: "Bukure",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050104,
								name: "Rwesero",
								sector_id: 50501,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050103,
								name: "Kivumu",
								sector_id: 50501,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050102,
								name: "Kigabiro",
								sector_id: 50501,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050101,
								name: "Karenge",
								sector_id: 50501,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 252,
								name: "Bukure",
								sector_id: 50501,
								courtOfCells: [
									{
										id: 1639,
										name: "KABUGA",
										generalAssembly_id: 252,
									},
									{
										id: 1640,
										name: "KAGARAMA",
										generalAssembly_id: 252,
									},
									{
										id: 1641,
										name: "KARENGE",
										generalAssembly_id: 252,
									},
									{
										id: 1642,
										name: "NYARUTOVU",
										generalAssembly_id: 252,
									},
									{
										id: 1643,
										name: "REBERO",
										generalAssembly_id: 252,
									},
									{
										id: 9227,
										name: "Bukure Court of Appeal ",
										generalAssembly_id: 252,
									},
									{
										id: 10760,
										name: "Bukure Gacaca court of sector ",
										generalAssembly_id: 252,
									},
								],
							},
							{
								id: 253,
								name: "Karagari",
								sector_id: 50501,
								courtOfCells: [
									{
										id: 1644,
										name: "GICACA",
										generalAssembly_id: 253,
									},
									{
										id: 1645,
										name: "KANYOGOTE",
										generalAssembly_id: 253,
									},
									{
										id: 1646,
										name: "KARAGARI",
										generalAssembly_id: 253,
									},
									{
										id: 1647,
										name: "KIGABIRO",
										generalAssembly_id: 253,
									},
									{
										id: 1648,
										name: "MUGORORE",
										generalAssembly_id: 253,
									},
									{
										id: 1649,
										name: "RWARENGA",
										generalAssembly_id: 253,
									},
									{
										id: 9228,
										name: "Karagari Court of Appeal ",
										generalAssembly_id: 253,
									},
									{
										id: 10761,
										name: "Karagari Gacaca court of sector ",
										generalAssembly_id: 253,
									},
								],
							},
							{
								id: 254,
								name: "Karushya",
								sector_id: 50501,
								courtOfCells: [
									{
										id: 1650,
										name: "KARUSHYA",
										generalAssembly_id: 254,
									},
									{
										id: 1651,
										name: "KIVUGIZA",
										generalAssembly_id: 254,
									},
									{
										id: 1652,
										name: "KIVUMU",
										generalAssembly_id: 254,
									},
									{
										id: 1653,
										name: "RUYANGE",
										generalAssembly_id: 254,
									},
									{
										id: 9229,
										name: "Karushya Court of Appeal ",
										generalAssembly_id: 254,
									},
									{
										id: 10762,
										name: "Karushya Gacaca court of sector ",
										generalAssembly_id: 254,
									},
								],
							},
						],
					},
					{
						id: 50502,
						name: "Bwisige",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050204,
								name: "Nyabushingitwa",
								sector_id: 50502,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050203,
								name: "Mukono",
								sector_id: 50502,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050202,
								name: "Gihuke",
								sector_id: 50502,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050201,
								name: "Bwisige",
								sector_id: 50502,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 255,
								name: "Mukono ",
								sector_id: 50502,
								courtOfCells: [
									{
										id: 1654,
										name: "MUKONO",
										generalAssembly_id: 255,
									},
									{
										id: 1655,
										name: "NDAYABANA",
										generalAssembly_id: 255,
									},
									{
										id: 1656,
										name: "NYABUSHINGITWA",
										generalAssembly_id: 255,
									},
									{
										id: 1657,
										name: "NYAGATOMA",
										generalAssembly_id: 255,
									},
									{
										id: 1658,
										name: "NYARUMBA",
										generalAssembly_id: 255,
									},
									{
										id: 1659,
										name: "RUGARAMA",
										generalAssembly_id: 255,
									},
									{
										id: 1660,
										name: "RWEBISHEKE",
										generalAssembly_id: 255,
									},
									{
										id: 1661,
										name: "RWONDO",
										generalAssembly_id: 255,
									},
									{
										id: 9230,
										name: "Mukono  Court of Appeal ",
										generalAssembly_id: 255,
									},
									{
										id: 10763,
										name: "Mukono  Gacaca court of sector ",
										generalAssembly_id: 255,
									},
								],
							},
							{
								id: 256,
								name: "Bwisige ",
								sector_id: 50502,
								courtOfCells: [
									{
										id: 1662,
										name: "BWISIGE",
										generalAssembly_id: 256,
									},
									{
										id: 1663,
										name: "KIRAMBO",
										generalAssembly_id: 256,
									},
									{
										id: 1664,
										name: "RWAMUHIRIMA",
										generalAssembly_id: 256,
									},
									{
										id: 9231,
										name: "Bwisige  Court of Appeal ",
										generalAssembly_id: 256,
									},
									{
										id: 10764,
										name: "Bwisige  Gacaca court of sector ",
										generalAssembly_id: 256,
									},
								],
							},
							{
								id: 257,
								name: "Gihuke ",
								sector_id: 50502,
								courtOfCells: [
									{
										id: 1665,
										name: "KUMANA",
										generalAssembly_id: 257,
									},
									{
										id: 1666,
										name: "KUMUNINI",
										generalAssembly_id: 257,
									},
									{
										id: 1667,
										name: "MUREHE",
										generalAssembly_id: 257,
									},
									{
										id: 1668,
										name: "NYAMUGARI",
										generalAssembly_id: 257,
									},
									{
										id: 9232,
										name: "Gihuke  Court of Appeal ",
										generalAssembly_id: 257,
									},
									{
										id: 10765,
										name: "Gihuke  Gacaca court of sector ",
										generalAssembly_id: 257,
									},
								],
							},
						],
					},
					{
						id: 50503,
						name: "Byumba",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050309,
								name: "Nyarutarama",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050308,
								name: "Nyamabuye",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050307,
								name: "Nyakabungo",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050306,
								name: "Ngondore",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050305,
								name: "Murama",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050304,
								name: "Kivugiza",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050303,
								name: "Kibali",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050302,
								name: "Gisuna",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050301,
								name: "Gacurabwenge",
								sector_id: 50503,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 258,
								name: "Buhambe ",
								sector_id: 50503,
								courtOfCells: [
									{
										id: 1669,
										name: "GASIZA",
										generalAssembly_id: 258,
									},
									{
										id: 1670,
										name: "KAMITSINGA",
										generalAssembly_id: 258,
									},
									{
										id: 1671,
										name: "KINIHIRA",
										generalAssembly_id: 258,
									},
									{
										id: 1672,
										name: "NYAMABUYE",
										generalAssembly_id: 258,
									},
									{
										id: 1673,
										name: "RWIMINSI",
										generalAssembly_id: 258,
									},
									{
										id: 9233,
										name: "Buhambe  Court of Appeal ",
										generalAssembly_id: 258,
									},
									{
										id: 10766,
										name: "Buhambe  Gacaca court of sector ",
										generalAssembly_id: 258,
									},
								],
							},
							{
								id: 259,
								name: "Byumba",
								sector_id: 50503,
								courtOfCells: [
									{
										id: 1674,
										name: "GASHARU",
										generalAssembly_id: 259,
									},
									{
										id: 1675,
										name: "MATYAZO",
										generalAssembly_id: 259,
									},
									{
										id: 1676,
										name: "MUKERI",
										generalAssembly_id: 259,
									},
									{
										id: 1677,
										name: "MURIZA",
										generalAssembly_id: 259,
									},
									{
										id: 1678,
										name: "NYANDE",
										generalAssembly_id: 259,
									},
									{
										id: 1679,
										name: "REBERO",
										generalAssembly_id: 259,
									},
									{
										id: 1680,
										name: "RUGANDU",
										generalAssembly_id: 259,
									},
									{
										id: 1681,
										name: "RUYAGA",
										generalAssembly_id: 259,
									},
									{
										id: 1682,
										name: "RWASAMA",
										generalAssembly_id: 259,
									},
									{
										id: 1683,
										name: "RWIRI",
										generalAssembly_id: 259,
									},
									{
										id: 9234,
										name: "Byumba Court of Appeal ",
										generalAssembly_id: 259,
									},
									{
										id: 10767,
										name: "Byumba Gacaca court of sector ",
										generalAssembly_id: 259,
									},
								],
							},
							{
								id: 260,
								name: "Rugarama ",
								sector_id: 50503,
								courtOfCells: [
									{
										id: 1684,
										name: "GAKENKE",
										generalAssembly_id: 260,
									},
									{
										id: 1685,
										name: "GITOVU",
										generalAssembly_id: 260,
									},
									{
										id: 1686,
										name: "NGONDORE",
										generalAssembly_id: 260,
									},
									{
										id: 1687,
										name: "RUBANGA",
										generalAssembly_id: 260,
									},
									{
										id: 1688,
										name: "RUZO",
										generalAssembly_id: 260,
									},
									{
										id: 9235,
										name: "Rugarama  Court of Appeal ",
										generalAssembly_id: 260,
									},
									{
										id: 10768,
										name: "Rugarama  Gacaca court of sector ",
										generalAssembly_id: 260,
									},
								],
							},
							{
								id: 261,
								name: "Ruhenda ",
								sector_id: 50503,
								courtOfCells: [
									{
										id: 1689,
										name: "KIVUGIZA",
										generalAssembly_id: 261,
									},
									{
										id: 1690,
										name: "MIRIKU",
										generalAssembly_id: 261,
									},
									{
										id: 1691,
										name: "MURAMA",
										generalAssembly_id: 261,
									},
									{
										id: 1692,
										name: "NYAKABUNGO",
										generalAssembly_id: 261,
									},
									{
										id: 1693,
										name: "RUHURURA",
										generalAssembly_id: 261,
									},
									{
										id: 1694,
										name: "RUKEREZA",
										generalAssembly_id: 261,
									},
									{
										id: 1695,
										name: "RWAMBONA",
										generalAssembly_id: 261,
									},
									{
										id: 9236,
										name: "Ruhenda  Court of Appeal ",
										generalAssembly_id: 261,
									},
									{
										id: 10769,
										name: "Ruhenda  Gacaca court of sector ",
										generalAssembly_id: 261,
									},
								],
							},
						],
					},
					{
						id: 50504,
						name: "Cyumba",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050406,
								name: "Rwankonjo",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050405,
								name: "Nyaruka",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050404,
								name: "Nyambare",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050403,
								name: "Nyakabungo",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050402,
								name: "Muhambo",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050401,
								name: "Gasunzu",
								sector_id: 50504,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 262,
								name: "Cyumba ",
								sector_id: 50504,
								courtOfCells: [
									{
										id: 1696,
										name: "CENTRE",
										generalAssembly_id: 262,
									},
									{
										id: 1697,
										name: "GISHAMBASHAYO",
										generalAssembly_id: 262,
									},
									{
										id: 1698,
										name: "KIRIBA",
										generalAssembly_id: 262,
									},
									{
										id: 1699,
										name: "MUGERA",
										generalAssembly_id: 262,
									},
									{
										id: 1700,
										name: "RYAMUROMBA",
										generalAssembly_id: 262,
									},
									{
										id: 1701,
										name: "CYUMBA",
										generalAssembly_id: 262,
									},
									{
										id: 9237,
										name: "Cyumba  Court of Appeal ",
										generalAssembly_id: 262,
									},
									{
										id: 10770,
										name: "Cyumba  Gacaca court of sector ",
										generalAssembly_id: 262,
									},
								],
							},
							{
								id: 263,
								name: "Mukono ",
								sector_id: 50504,
								courtOfCells: [
									{
										id: 1702,
										name: "BUSHENYI",
										generalAssembly_id: 263,
									},
									{
										id: 1703,
										name: "GIKOMO",
										generalAssembly_id: 263,
									},
									{
										id: 1704,
										name: "MUKONO",
										generalAssembly_id: 263,
									},
									{
										id: 1705,
										name: "NYAKABUNGO",
										generalAssembly_id: 263,
									},
									{
										id: 1706,
										name: "RUKIZI",
										generalAssembly_id: 263,
									},
									{
										id: 1707,
										name: "RWANKONJO",
										generalAssembly_id: 263,
									},
									{
										id: 9238,
										name: "Mukono  Court of Appeal ",
										generalAssembly_id: 263,
									},
									{
										id: 10771,
										name: "Mukono  Gacaca court of sector ",
										generalAssembly_id: 263,
									},
								],
							},
							{
								id: 264,
								name: "Murore",
								sector_id: 50504,
								courtOfCells: [
									{
										id: 1708,
										name: "BURINDI",
										generalAssembly_id: 264,
									},
									{
										id: 1709,
										name: "MAYA",
										generalAssembly_id: 264,
									},
									{
										id: 1710,
										name: "MURORE",
										generalAssembly_id: 264,
									},
									{
										id: 1711,
										name: "NYAMBARE",
										generalAssembly_id: 264,
									},
									{
										id: 1712,
										name: "RUGERERO",
										generalAssembly_id: 264,
									},
									{
										id: 9239,
										name: "Murore Court of Appeal ",
										generalAssembly_id: 264,
									},
									{
										id: 10772,
										name: "Murore Gacaca court of sector ",
										generalAssembly_id: 264,
									},
								],
							},
						],
					},
					{
						id: 50505,
						name: "Giti",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050503,
								name: "Tanda",
								sector_id: 50505,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050502,
								name: "Murehe",
								sector_id: 50505,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050501,
								name: "Gatobotobo",
								sector_id: 50505,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 265,
								name: "Gatare",
								sector_id: 50505,
								courtOfCells: [
									{
										id: 1713,
										name: "BUSHIRANYOTA",
										generalAssembly_id: 265,
									},
									{
										id: 1714,
										name: "BUTARE",
										generalAssembly_id: 265,
									},
									{
										id: 1715,
										name: "CYAMABANO",
										generalAssembly_id: 265,
									},
									{
										id: 1716,
										name: "GATARE",
										generalAssembly_id: 265,
									},
									{
										id: 1717,
										name: "KABEZA",
										generalAssembly_id: 265,
									},
									{
										id: 9240,
										name: "Gatare Court of Appeal ",
										generalAssembly_id: 265,
									},
									{
										id: 10773,
										name: "Gatare Gacaca court of sector ",
										generalAssembly_id: 265,
									},
								],
							},
							{
								id: 266,
								name: "Kabacuzi ",
								sector_id: 50505,
								courtOfCells: [
									{
										id: 1718,
										name: "KABABITO",
										generalAssembly_id: 266,
									},
									{
										id: 1719,
										name: "KABACUZI",
										generalAssembly_id: 266,
									},
									{
										id: 1720,
										name: "MATYAZO",
										generalAssembly_id: 266,
									},
									{
										id: 1721,
										name: "NYAMIRAMBO",
										generalAssembly_id: 266,
									},
									{
										id: 1722,
										name: "RUGARAMA",
										generalAssembly_id: 266,
									},
									{
										id: 9241,
										name: "Kabacuzi  Court of Appeal ",
										generalAssembly_id: 266,
									},
									{
										id: 10774,
										name: "Kabacuzi  Gacaca court of sector ",
										generalAssembly_id: 266,
									},
								],
							},
							{
								id: 267,
								name: "Ruzizi",
								sector_id: 50505,
								courtOfCells: [
									{
										id: 1723,
										name: "BUNYAMPETA",
										generalAssembly_id: 267,
									},
									{
										id: 1724,
										name: "GASHARU",
										generalAssembly_id: 267,
									},
									{
										id: 1725,
										name: "KIGABIRO",
										generalAssembly_id: 267,
									},
									{
										id: 1726,
										name: "NGANWA",
										generalAssembly_id: 267,
									},
									{
										id: 1727,
										name: "RUZIZI",
										generalAssembly_id: 267,
									},
									{
										id: 1728,
										name: "TANDA",
										generalAssembly_id: 267,
									},
									{
										id: 9242,
										name: "Ruzizi Court of Appeal ",
										generalAssembly_id: 267,
									},
									{
										id: 10775,
										name: "Ruzizi Gacaca court of sector ",
										generalAssembly_id: 267,
									},
								],
							},
						],
					},
					{
						id: 50506,
						name: "Kageyo",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050605,
								name: "Nyamiyaga",
								sector_id: 50506,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050604,
								name: "Muhondo",
								sector_id: 50506,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050603,
								name: "Kabuga",
								sector_id: 50506,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050602,
								name: "Horezo",
								sector_id: 50506,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050601,
								name: "Gihembe",
								sector_id: 50506,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 268,
								name: "Muhondo",
								sector_id: 50506,
								courtOfCells: [
									{
										id: 1729,
										name: "GATIBA",
										generalAssembly_id: 268,
									},
									{
										id: 1730,
										name: "KAMANYUNDO",
										generalAssembly_id: 268,
									},
									{
										id: 1731,
										name: "MURAMBI",
										generalAssembly_id: 268,
									},
									{
										id: 1732,
										name: "MUSETSA",
										generalAssembly_id: 268,
									},
									{
										id: 1733,
										name: "MWANGE",
										generalAssembly_id: 268,
									},
									{
										id: 9243,
										name: "Muhondo Court of Appeal ",
										generalAssembly_id: 268,
									},
									{
										id: 10776,
										name: "Muhondo Gacaca court of sector ",
										generalAssembly_id: 268,
									},
								],
							},
							{
								id: 269,
								name: "Mukarange ",
								sector_id: 50506,
								courtOfCells: [
									{
										id: 1734,
										name: "KABARE",
										generalAssembly_id: 269,
									},
									{
										id: 1735,
										name: "KARIHIRA",
										generalAssembly_id: 269,
									},
									{
										id: 1736,
										name: "MUREHE",
										generalAssembly_id: 269,
									},
									{
										id: 1737,
										name: "NYAGAHURU",
										generalAssembly_id: 269,
									},
									{
										id: 1738,
										name: "NYARUVUMU",
										generalAssembly_id: 269,
									},
									{
										id: 1739,
										name: "RUKOMO",
										generalAssembly_id: 269,
									},
									{
										id: 9244,
										name: "Mukarange  Court of Appeal ",
										generalAssembly_id: 269,
									},
									{
										id: 10777,
										name: "Mukarange  Gacaca court of sector ",
										generalAssembly_id: 269,
									},
								],
							},
							{
								id: 270,
								name: "Gicumbi",
								sector_id: 50506,
								courtOfCells: [
									{
										id: 1740,
										name: "GATOBOTOBO",
										generalAssembly_id: 270,
									},
									{
										id: 1741,
										name: "KIGOMA",
										generalAssembly_id: 270,
									},
									{
										id: 1742,
										name: "MAYA",
										generalAssembly_id: 270,
									},
									{
										id: 1743,
										name: "MUKENKE",
										generalAssembly_id: 270,
									},
									{
										id: 1744,
										name: "NYAKAREKARE",
										generalAssembly_id: 270,
									},
									{
										id: 9245,
										name: "Gicumbi Court of Appeal ",
										generalAssembly_id: 270,
									},
									{
										id: 10778,
										name: "Gicumbi Gacaca court of sector ",
										generalAssembly_id: 270,
									},
								],
							},
						],
					},
					{
						id: 50507,
						name: "Kaniga",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050705,
								name: "Rukurura",
								sector_id: 50507,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050704,
								name: "Nyarwambu",
								sector_id: 50507,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050703,
								name: "Mulindi",
								sector_id: 50507,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050702,
								name: "Gatoma",
								sector_id: 50507,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050701,
								name: "Bugomba",
								sector_id: 50507,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 271,
								name: "Butozo ",
								sector_id: 50507,
								courtOfCells: [
									{
										id: 1745,
										name: "BUGABIRA",
										generalAssembly_id: 271,
									},
									{
										id: 1746,
										name: "BWANIKA",
										generalAssembly_id: 271,
									},
									{
										id: 1747,
										name: "CENTRE MULINDI",
										generalAssembly_id: 271,
									},
									{
										id: 1748,
										name: "KIRWA",
										generalAssembly_id: 271,
									},
									{
										id: 1749,
										name: "MUHONDO",
										generalAssembly_id: 271,
									},
									{
										id: 9246,
										name: "Butozo  Court of Appeal ",
										generalAssembly_id: 271,
									},
									{
										id: 10779,
										name: "Butozo  Gacaca court of sector ",
										generalAssembly_id: 271,
									},
								],
							},
							{
								id: 272,
								name: "Kaniga ",
								sector_id: 50507,
								courtOfCells: [
									{
										id: 1750,
										name: "GITOVU",
										generalAssembly_id: 272,
									},
									{
										id: 1751,
										name: "KABARE",
										generalAssembly_id: 272,
									},
									{
										id: 1752,
										name: "KABUKIZA",
										generalAssembly_id: 272,
									},
									{
										id: 1753,
										name: "KAMUSHURE",
										generalAssembly_id: 272,
									},
									{
										id: 1754,
										name: "KARAMBO",
										generalAssembly_id: 272,
									},
									{
										id: 1755,
										name: "KINNYOGO",
										generalAssembly_id: 272,
									},
									{
										id: 1756,
										name: "MUNINI",
										generalAssembly_id: 272,
									},
									{
										id: 1757,
										name: "NYAGATARE",
										generalAssembly_id: 272,
									},
									{
										id: 1758,
										name: "RUBOROGA",
										generalAssembly_id: 272,
									},
									{
										id: 1759,
										name: "RUHANGIRO",
										generalAssembly_id: 272,
									},
									{
										id: 1760,
										name: "MUYANGE",
										generalAssembly_id: 272,
									},
									{
										id: 9247,
										name: "Kaniga  Court of Appeal ",
										generalAssembly_id: 272,
									},
									{
										id: 10780,
										name: "Kaniga  Gacaca court of sector ",
										generalAssembly_id: 272,
									},
								],
							},
							{
								id: 273,
								name: "Kizinga",
								sector_id: 50507,
								courtOfCells: [
									{
										id: 1761,
										name: "GASHIRU",
										generalAssembly_id: 273,
									},
									{
										id: 1762,
										name: "KAMABARE",
										generalAssembly_id: 273,
									},
									{
										id: 1763,
										name: "NGABIRA",
										generalAssembly_id: 273,
									},
									{
										id: 1764,
										name: "NYAKAGERA",
										generalAssembly_id: 273,
									},
									{
										id: 1765,
										name: "NYAKARA",
										generalAssembly_id: 273,
									},
									{
										id: 1766,
										name: "NYAKIBANDE",
										generalAssembly_id: 273,
									},
									{
										id: 1767,
										name: "RUGARAMA",
										generalAssembly_id: 273,
									},
									{
										id: 9248,
										name: "Kizinga Court of Appeal ",
										generalAssembly_id: 273,
									},
									{
										id: 10781,
										name: "Kizinga Gacaca court of sector ",
										generalAssembly_id: 273,
									},
								],
							},
						],
					},
					{
						id: 50508,
						name: "Manyagiro",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050806,
								name: "Ryaruyumba",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050805,
								name: "Rusekera",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050804,
								name: "Remera",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050803,
								name: "Nyiravugiza",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050802,
								name: "Nyiragifumba",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050801,
								name: "Kabuga",
								sector_id: 50508,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 274,
								name: "Buhinga",
								sector_id: 50508,
								courtOfCells: [
									{
										id: 1768,
										name: "BUGINA",
										generalAssembly_id: 274,
									},
									{
										id: 1769,
										name: "BUHINGA",
										generalAssembly_id: 274,
									},
									{
										id: 1770,
										name: "BUSA",
										generalAssembly_id: 274,
									},
									{
										id: 1771,
										name: "BUSHINGA",
										generalAssembly_id: 274,
									},
									{
										id: 1772,
										name: "GITABA",
										generalAssembly_id: 274,
									},
									{
										id: 1773,
										name: "KABUGA",
										generalAssembly_id: 274,
									},
									{
										id: 1774,
										name: "MAFUREBO",
										generalAssembly_id: 274,
									},
									{
										id: 1775,
										name: "MUGENYIWANGA",
										generalAssembly_id: 274,
									},
									{
										id: 1776,
										name: "RUTOVU",
										generalAssembly_id: 274,
									},
									{
										id: 9249,
										name: "Buhinga Court of Appeal ",
										generalAssembly_id: 274,
									},
									{
										id: 10782,
										name: "Buhinga Gacaca court of sector ",
										generalAssembly_id: 274,
									},
								],
							},
							{
								id: 275,
								name: "Gakubo ",
								sector_id: 50508,
								courtOfCells: [
									{
										id: 1777,
										name: "GAKUBO",
										generalAssembly_id: 275,
									},
									{
										id: 1778,
										name: "GATUNGO",
										generalAssembly_id: 275,
									},
									{
										id: 1779,
										name: "KAGOROGORO",
										generalAssembly_id: 275,
									},
									{
										id: 1780,
										name: "NYAGISOZI",
										generalAssembly_id: 275,
									},
									{
										id: 1781,
										name: "RUSABIRA",
										generalAssembly_id: 275,
									},
									{
										id: 9250,
										name: "Gakubo  Court of Appeal ",
										generalAssembly_id: 275,
									},
									{
										id: 10783,
										name: "Gakubo  Gacaca court of sector ",
										generalAssembly_id: 275,
									},
								],
							},
							{
								id: 276,
								name: "Manyagiro",
								sector_id: 50508,
								courtOfCells: [
									{
										id: 1782,
										name: "KIGARAMA",
										generalAssembly_id: 276,
									},
									{
										id: 1783,
										name: "MUGERA",
										generalAssembly_id: 276,
									},
									{
										id: 1784,
										name: "MUREHE",
										generalAssembly_id: 276,
									},
									{
										id: 1785,
										name: "NYANTARURE",
										generalAssembly_id: 276,
									},
									{
										id: 1786,
										name: "RUBINDI",
										generalAssembly_id: 276,
									},
									{
										id: 1787,
										name: "RUGASA",
										generalAssembly_id: 276,
									},
									{
										id: 9251,
										name: "Manyagiro Court of Appeal ",
										generalAssembly_id: 276,
									},
									{
										id: 10784,
										name: "Manyagiro Gacaca court of sector ",
										generalAssembly_id: 276,
									},
								],
							},
						],
					},
					{
						id: 50509,
						name: "Miyove",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5050903,
								name: "Mubuga",
								sector_id: 50509,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050902,
								name: "Miyove",
								sector_id: 50509,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5050901,
								name: "Gakenke",
								sector_id: 50509,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 277,
								name: "Rumuli ",
								sector_id: 50509,
								courtOfCells: [
									{
										id: 1788,
										name: "GAKO",
										generalAssembly_id: 277,
									},
									{
										id: 1789,
										name: "GASIZA",
										generalAssembly_id: 277,
									},
									{
										id: 1790,
										name: "KARWANIRA",
										generalAssembly_id: 277,
									},
									{
										id: 1791,
										name: "KIRWA",
										generalAssembly_id: 277,
									},
									{
										id: 1792,
										name: "MUSEKE",
										generalAssembly_id: 277,
									},
									{
										id: 1793,
										name: "NYAMIYAGA",
										generalAssembly_id: 277,
									},
									{
										id: 1794,
										name: "NYARURAMBI",
										generalAssembly_id: 277,
									},
									{
										id: 9252,
										name: "Rumuli  Court of Appeal ",
										generalAssembly_id: 277,
									},
									{
										id: 10785,
										name: "Rumuli  Gacaca court of sector ",
										generalAssembly_id: 277,
									},
								],
							},
							{
								id: 278,
								name: "Miyove ",
								sector_id: 50509,
								courtOfCells: [
									{
										id: 1795,
										name: "GISEKURU",
										generalAssembly_id: 278,
									},
									{
										id: 1796,
										name: "KAGASA",
										generalAssembly_id: 278,
									},
									{
										id: 1797,
										name: "MASOGWE",
										generalAssembly_id: 278,
									},
									{
										id: 1798,
										name: "MUKAKA",
										generalAssembly_id: 278,
									},
									{
										id: 1799,
										name: "MPINGA-MUREHE",
										generalAssembly_id: 278,
									},
									{
										id: 1800,
										name: "MUTONGA",
										generalAssembly_id: 278,
									},
									{
										id: 1801,
										name: "RUTOVU",
										generalAssembly_id: 278,
									},
									{
										id: 9253,
										name: "Miyove  Court of Appeal ",
										generalAssembly_id: 278,
									},
									{
										id: 10786,
										name: "Miyove  Gacaca court of sector ",
										generalAssembly_id: 278,
									},
								],
							},
						],
					},
					{
						id: 50510,
						name: "Mukarange",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051006,
								name: "Rusambya",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051005,
								name: "Rugerero",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051004,
								name: "Mutarama",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051003,
								name: "Kiruhura",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051002,
								name: "Gatenga",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051001,
								name: "Cyamuganga",
								sector_id: 50510,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 279,
								name: "Mugina",
								sector_id: 50510,
								courtOfCells: [
									{
										id: 1802,
										name: "KIYORWA",
										generalAssembly_id: 279,
									},
									{
										id: 1803,
										name: "MUGINA",
										generalAssembly_id: 279,
									},
									{
										id: 1804,
										name: "NDARAMA",
										generalAssembly_id: 279,
									},
									{
										id: 1805,
										name: "NYAKABUNGO",
										generalAssembly_id: 279,
									},
									{
										id: 1806,
										name: "NYANGE",
										generalAssembly_id: 279,
									},
									{
										id: 1807,
										name: "RUGARAMA",
										generalAssembly_id: 279,
									},
									{
										id: 9254,
										name: "Mugina Court of Appeal ",
										generalAssembly_id: 279,
									},
									{
										id: 10787,
										name: "Mugina Gacaca court of sector ",
										generalAssembly_id: 279,
									},
								],
							},
							{
								id: 280,
								name: "Mukarange ",
								sector_id: 50510,
								courtOfCells: [
									{
										id: 1808,
										name: "GIKORE",
										generalAssembly_id: 280,
									},
									{
										id: 1809,
										name: "MAFUMIRWA",
										generalAssembly_id: 280,
									},
									{
										id: 1810,
										name: "MUNYEGE",
										generalAssembly_id: 280,
									},
									{
										id: 1811,
										name: "RUGESHI",
										generalAssembly_id: 280,
									},
									{
										id: 1812,
										name: "RUREMBO",
										generalAssembly_id: 280,
									},
									{
										id: 1813,
										name: "RUSHASHA",
										generalAssembly_id: 280,
									},
									{
										id: 1814,
										name: "RUZIKU",
										generalAssembly_id: 280,
									},
									{
										id: 9255,
										name: "Mukarange  Court of Appeal ",
										generalAssembly_id: 280,
									},
									{
										id: 10788,
										name: "Mukarange  Gacaca court of sector ",
										generalAssembly_id: 280,
									},
								],
							},
							{
								id: 281,
								name: "Nyagakizi",
								sector_id: 50510,
								courtOfCells: [
									{
										id: 1815,
										name: "BUREMBO",
										generalAssembly_id: 281,
									},
									{
										id: 1816,
										name: "KABUNGO",
										generalAssembly_id: 281,
									},
									{
										id: 1817,
										name: "KAGANE",
										generalAssembly_id: 281,
									},
									{
										id: 1818,
										name: "KIGARAMA",
										generalAssembly_id: 281,
									},
									{
										id: 1819,
										name: "KIRUHURA",
										generalAssembly_id: 281,
									},
									{
										id: 1820,
										name: "NYAMUTOKO",
										generalAssembly_id: 281,
									},
									{
										id: 1821,
										name: "RUSAMBYA",
										generalAssembly_id: 281,
									},
									{
										id: 9256,
										name: "Nyagakizi Court of Appeal ",
										generalAssembly_id: 281,
									},
									{
										id: 10789,
										name: "Nyagakizi Gacaca court of sector ",
										generalAssembly_id: 281,
									},
								],
							},
						],
					},
					{
						id: 50511,
						name: "Muko",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051105,
								name: "Rebero",
								sector_id: 50511,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051104,
								name: "Ngange",
								sector_id: 50511,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051103,
								name: "Mwendo",
								sector_id: 50511,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051102,
								name: "Kigoma",
								sector_id: 50511,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051101,
								name: "Cyamuhinda",
								sector_id: 50511,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 282,
								name: "Cyamuhinda",
								sector_id: 50511,
								courtOfCells: [
									{
										id: 1822,
										name: "GICUREGENYA",
										generalAssembly_id: 282,
									},
									{
										id: 1823,
										name: "NTONGANYA",
										generalAssembly_id: 282,
									},
									{
										id: 1824,
										name: "RUGARAGARA",
										generalAssembly_id: 282,
									},
									{
										id: 1825,
										name: "RUKAZIRE",
										generalAssembly_id: 282,
									},
									{
										id: 1826,
										name: "RWAMITEMBE",
										generalAssembly_id: 282,
									},
									{
										id: 9257,
										name: "Cyamuhinda Court of Appeal ",
										generalAssembly_id: 282,
									},
									{
										id: 10790,
										name: "Cyamuhinda Gacaca court of sector ",
										generalAssembly_id: 282,
									},
								],
							},
							{
								id: 283,
								name: "Muko",
								sector_id: 50511,
								courtOfCells: [
									{
										id: 1827,
										name: "GASIZI",
										generalAssembly_id: 283,
									},
									{
										id: 1828,
										name: "KARUNDI",
										generalAssembly_id: 283,
									},
									{
										id: 1829,
										name: "KIRARA",
										generalAssembly_id: 283,
									},
									{
										id: 1830,
										name: "MAYOGI",
										generalAssembly_id: 283,
									},
									{
										id: 1831,
										name: "NYAMPUNDU",
										generalAssembly_id: 283,
									},
									{
										id: 1832,
										name: "RYAGASHAKA",
										generalAssembly_id: 283,
									},
									{
										id: 9258,
										name: "Muko Court of Appeal ",
										generalAssembly_id: 283,
									},
									{
										id: 10791,
										name: "Muko Gacaca court of sector ",
										generalAssembly_id: 283,
									},
								],
							},
							{
								id: 284,
								name: "Mwendo ",
								sector_id: 50511,
								courtOfCells: [
									{
										id: 1833,
										name: "GIKUMBA",
										generalAssembly_id: 284,
									},
									{
										id: 1834,
										name: "KABUYE",
										generalAssembly_id: 284,
									},
									{
										id: 1835,
										name: "KAGOGO",
										generalAssembly_id: 284,
									},
									{
										id: 1836,
										name: "KIRENGO",
										generalAssembly_id: 284,
									},
									{
										id: 9259,
										name: "Mwendo  Court of Appeal ",
										generalAssembly_id: 284,
									},
									{
										id: 10792,
										name: "Mwendo  Gacaca court of sector ",
										generalAssembly_id: 284,
									},
								],
							},
							{
								id: 285,
								name: "Ngange ",
								sector_id: 50511,
								courtOfCells: [
									{
										id: 1837,
										name: "GASHARU",
										generalAssembly_id: 285,
									},
									{
										id: 1838,
										name: "KABARE",
										generalAssembly_id: 285,
									},
									{
										id: 1839,
										name: "KIMPONGO",
										generalAssembly_id: 285,
									},
									{
										id: 1840,
										name: "MAYORA",
										generalAssembly_id: 285,
									},
									{
										id: 1841,
										name: "RUDOGO",
										generalAssembly_id: 285,
									},
									{
										id: 9260,
										name: "Ngange  Court of Appeal ",
										generalAssembly_id: 285,
									},
									{
										id: 10793,
										name: "Ngange  Gacaca court of sector ",
										generalAssembly_id: 285,
									},
								],
							},
							{
								id: 286,
								name: "Kigoma ",
								sector_id: 50511,
								courtOfCells: [
									{
										id: 1842,
										name: "CYERERE",
										generalAssembly_id: 286,
									},
									{
										id: 1843,
										name: "GATOBOTOBO",
										generalAssembly_id: 286,
									},
									{
										id: 1844,
										name: "KARAMBI",
										generalAssembly_id: 286,
									},
									{
										id: 1845,
										name: "KARUMULI",
										generalAssembly_id: 286,
									},
									{
										id: 1846,
										name: "RYARWOGA",
										generalAssembly_id: 286,
									},
									{
										id: 9261,
										name: "Kigoma  Court of Appeal ",
										generalAssembly_id: 286,
									},
									{
										id: 10794,
										name: "Kigoma  Gacaca court of sector ",
										generalAssembly_id: 286,
									},
								],
							},
						],
					},
					{
						id: 50512,
						name: "Mutete",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051205,
								name: "Nyarubuye",
								sector_id: 50512,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051204,
								name: "Mutandi",
								sector_id: 50512,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051203,
								name: "Musenyi",
								sector_id: 50512,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051202,
								name: "Kabeza",
								sector_id: 50512,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051201,
								name: "Gaseke",
								sector_id: 50512,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 287,
								name: "Kavumu",
								sector_id: 50512,
								courtOfCells: [
									{
										id: 1847,
										name: "GATARE",
										generalAssembly_id: 287,
									},
									{
										id: 1848,
										name: "GIHANGARA",
										generalAssembly_id: 287,
									},
									{
										id: 1849,
										name: "KARAMA",
										generalAssembly_id: 287,
									},
									{
										id: 1850,
										name: "KINUNGA",
										generalAssembly_id: 287,
									},
									{
										id: 1851,
										name: "MUTANDI",
										generalAssembly_id: 287,
									},
									{
										id: 1852,
										name: "NKENZI",
										generalAssembly_id: 287,
									},
									{
										id: 1853,
										name: "RUGARAGARA",
										generalAssembly_id: 287,
									},
									{
										id: 1854,
										name: "RUGARAMA",
										generalAssembly_id: 287,
									},
									{
										id: 1855,
										name: "RUHONDO",
										generalAssembly_id: 287,
									},
									{
										id: 9262,
										name: "Kavumu Court of Appeal ",
										generalAssembly_id: 287,
									},
									{
										id: 10795,
										name: "Kavumu Gacaca court of sector ",
										generalAssembly_id: 287,
									},
								],
							},
							{
								id: 288,
								name: "Mutete ",
								sector_id: 50512,
								courtOfCells: [
									{
										id: 1856,
										name: "GASHARU",
										generalAssembly_id: 288,
									},
									{
										id: 1857,
										name: "KIMISUGI",
										generalAssembly_id: 288,
									},
									{
										id: 1858,
										name: "MUHORORO",
										generalAssembly_id: 288,
									},
									{
										id: 1859,
										name: "MUTETE-KAVUMU",
										generalAssembly_id: 288,
									},
									{
										id: 1860,
										name: "RUKONDO",
										generalAssembly_id: 288,
									},
									{
										id: 9263,
										name: "Mutete  Court of Appeal ",
										generalAssembly_id: 288,
									},
									{
										id: 10796,
										name: "Mutete  Gacaca court of sector ",
										generalAssembly_id: 288,
									},
								],
							},
							{
								id: 289,
								name: "Zoko",
								sector_id: 50512,
								courtOfCells: [
									{
										id: 1861,
										name: "GIHIRA",
										generalAssembly_id: 289,
									},
									{
										id: 1862,
										name: "KABASEGA",
										generalAssembly_id: 289,
									},
									{
										id: 1863,
										name: "KAGARAMA",
										generalAssembly_id: 289,
									},
									{
										id: 1864,
										name: "MEREZO",
										generalAssembly_id: 289,
									},
									{
										id: 1865,
										name: "NYAGAFUNZO",
										generalAssembly_id: 289,
									},
									{
										id: 1866,
										name: "NYAMABUYE",
										generalAssembly_id: 289,
									},
									{
										id: 1867,
										name: "RUSEBEYA",
										generalAssembly_id: 289,
									},
									{
										id: 9264,
										name: "Zoko Court of Appeal ",
										generalAssembly_id: 289,
									},
									{
										id: 10797,
										name: "Zoko Gacaca court of sector ",
										generalAssembly_id: 289,
									},
								],
							},
						],
					},
					{
						id: 50513,
						name: "Nyamiyaga",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051307,
								name: "Mataba",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051306,
								name: "Kiziba",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051305,
								name: "Karambo",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051304,
								name: "Kabuga",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051303,
								name: "Kabeza",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051302,
								name: "Jamba",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051301,
								name: "Gahumuliza",
								sector_id: 50513,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 290,
								name: "Kagamba ",
								sector_id: 50513,
								courtOfCells: [
									{
										id: 1868,
										name: "BURIMBI",
										generalAssembly_id: 290,
									},
									{
										id: 1869,
										name: "JAMBA",
										generalAssembly_id: 290,
									},
									{
										id: 1870,
										name: "KADUHA",
										generalAssembly_id: 290,
									},
									{
										id: 1871,
										name: "KAGARAMA",
										generalAssembly_id: 290,
									},
									{
										id: 1872,
										name: "KARAMBI",
										generalAssembly_id: 290,
									},
									{
										id: 1873,
										name: "NYARUBUYE",
										generalAssembly_id: 290,
									},
									{
										id: 1874,
										name: "NYARUTARAMA",
										generalAssembly_id: 290,
									},
									{
										id: 1875,
										name: "NYIRAKAGAMBA",
										generalAssembly_id: 290,
									},
									{
										id: 9265,
										name: "Kagamba  Court of Appeal ",
										generalAssembly_id: 290,
									},
									{
										id: 10798,
										name: "Kagamba  Gacaca court of sector ",
										generalAssembly_id: 290,
									},
								],
							},
							{
								id: 291,
								name: "Mugina",
								sector_id: 50513,
								courtOfCells: [
									{
										id: 1876,
										name: "GASEKE",
										generalAssembly_id: 291,
									},
									{
										id: 1877,
										name: "GASHARU",
										generalAssembly_id: 291,
									},
									{
										id: 1878,
										name: "MAYA",
										generalAssembly_id: 291,
									},
									{
										id: 1879,
										name: "MIYANGE",
										generalAssembly_id: 291,
									},
									{
										id: 1880,
										name: "MURAMA",
										generalAssembly_id: 291,
									},
									{
										id: 1881,
										name: "NYARUBUYE",
										generalAssembly_id: 291,
									},
									{
										id: 1882,
										name: "RUHANGO",
										generalAssembly_id: 291,
									},
									{
										id: 1883,
										name: "RUYAGA",
										generalAssembly_id: 291,
									},
									{
										id: 9266,
										name: "Mugina Court of Appeal ",
										generalAssembly_id: 291,
									},
									{
										id: 10799,
										name: "Mugina Gacaca court of sector ",
										generalAssembly_id: 291,
									},
								],
							},
							{
								id: 292,
								name: "Nyamiyaga",
								sector_id: 50513,
								courtOfCells: [
									{
										id: 1884,
										name: "GASAVE",
										generalAssembly_id: 292,
									},
									{
										id: 1885,
										name: "GASHOKORO",
										generalAssembly_id: 292,
									},
									{
										id: 1886,
										name: "KIGARAMA",
										generalAssembly_id: 292,
									},
									{
										id: 1887,
										name: "NYAMIYAGA",
										generalAssembly_id: 292,
									},
									{
										id: 1888,
										name: "RWINGWE",
										generalAssembly_id: 292,
									},
									{
										id: 9267,
										name: "Nyamiyaga Court of Appeal ",
										generalAssembly_id: 292,
									},
									{
										id: 10800,
										name: "Nyamiyaga Gacaca court of sector ",
										generalAssembly_id: 292,
									},
								],
							},
						],
					},
					{
						id: 50514,
						name: "Nyankenke",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051407,
								name: "Yaramba",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051406,
								name: "Rwagihura",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051405,
								name: "Rutete",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051404,
								name: "Rusasa",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051403,
								name: "Kinishya",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051402,
								name: "Kigogo",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051401,
								name: "Butare",
								sector_id: 50514,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 293,
								name: "Yaramba ",
								sector_id: 50514,
								courtOfCells: [
									{
										id: 1889,
										name: "CYANKARANKA",
										generalAssembly_id: 293,
									},
									{
										id: 1890,
										name: "KABUGA",
										generalAssembly_id: 293,
									},
									{
										id: 1891,
										name: "MWENYI",
										generalAssembly_id: 293,
									},
									{
										id: 1892,
										name: "NTURO",
										generalAssembly_id: 293,
									},
									{
										id: 1893,
										name: "NYAGAFUNZO",
										generalAssembly_id: 293,
									},
									{
										id: 1894,
										name: "RWATA",
										generalAssembly_id: 293,
									},
									{
										id: 9268,
										name: "Yaramba  Court of Appeal ",
										generalAssembly_id: 293,
									},
									{
										id: 10801,
										name: "Yaramba  Gacaca court of sector ",
										generalAssembly_id: 293,
									},
								],
							},
							{
								id: 294,
								name: "Nyankenke I ",
								sector_id: 50514,
								courtOfCells: [
									{
										id: 1895,
										name: "GACACA",
										generalAssembly_id: 294,
									},
									{
										id: 1896,
										name: "KABERE",
										generalAssembly_id: 294,
									},
									{
										id: 1897,
										name: "KABINGO",
										generalAssembly_id: 294,
									},
									{
										id: 1898,
										name: "KAGEJI",
										generalAssembly_id: 294,
									},
									{
										id: 1899,
										name: "KAGOGO",
										generalAssembly_id: 294,
									},
									{
										id: 1900,
										name: "MWENDO",
										generalAssembly_id: 294,
									},
									{
										id: 1901,
										name: "RUTETE",
										generalAssembly_id: 294,
									},
									{
										id: 9269,
										name: "Nyankenke I  Court of Appeal ",
										generalAssembly_id: 294,
									},
									{
										id: 10802,
										name: "Nyankenke I  Gacaca court of sector ",
										generalAssembly_id: 294,
									},
								],
							},
							{
								id: 295,
								name: "Nyankenke II ",
								sector_id: 50514,
								courtOfCells: [
									{
										id: 1902,
										name: "BIRUMBA",
										generalAssembly_id: 295,
									},
									{
										id: 1903,
										name: "BUTARE",
										generalAssembly_id: 295,
									},
									{
										id: 1904,
										name: "GASHINGE",
										generalAssembly_id: 295,
									},
									{
										id: 1905,
										name: "GIKOMBE",
										generalAssembly_id: 295,
									},
									{
										id: 1906,
										name: "NTABANGIRA",
										generalAssembly_id: 295,
									},
									{
										id: 1907,
										name: "NYANAMO",
										generalAssembly_id: 295,
									},
									{
										id: 1908,
										name: "REMBERO",
										generalAssembly_id: 295,
									},
									{
										id: 9270,
										name: "Nyankenke II  Court of Appeal ",
										generalAssembly_id: 295,
									},
									{
										id: 10803,
										name: "Nyankenke II  Gacaca court of sector ",
										generalAssembly_id: 295,
									},
								],
							},
						],
					},
					{
						id: 50515,
						name: "Rubaya",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051505,
								name: "Nyamiyaga",
								sector_id: 50515,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051504,
								name: "Muguramo",
								sector_id: 50515,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051503,
								name: "Gishari",
								sector_id: 50515,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051502,
								name: "Gishambashayo",
								sector_id: 50515,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051501,
								name: "Gihanga",
								sector_id: 50515,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 296,
								name: "Gatengerane ",
								sector_id: 50515,
								courtOfCells: [
									{
										id: 1909,
										name: "BWAMBI",
										generalAssembly_id: 296,
									},
									{
										id: 1910,
										name: "GATENGERANE",
										generalAssembly_id: 296,
									},
									{
										id: 1911,
										name: "MUHONDO",
										generalAssembly_id: 296,
									},
									{
										id: 1912,
										name: "RUBAYA (1)",
										generalAssembly_id: 296,
									},
									{
										id: 9271,
										name: "Gatengerane  Court of Appeal ",
										generalAssembly_id: 296,
									},
									{
										id: 10804,
										name: "Gatengerane  Gacaca court of sector ",
										generalAssembly_id: 296,
									},
								],
							},
							{
								id: 297,
								name: "Gishambashayo",
								sector_id: 50515,
								courtOfCells: [
									{
										id: 1913,
										name: "GISHAMBASHAYO",
										generalAssembly_id: 297,
									},
									{
										id: 1914,
										name: "KARAMBO",
										generalAssembly_id: 297,
									},
									{
										id: 1915,
										name: "MARIBA",
										generalAssembly_id: 297,
									},
									{
										id: 1916,
										name: "RUHONWA",
										generalAssembly_id: 297,
									},
									{
										id: 1917,
										name: "BUGERERA",
										generalAssembly_id: 297,
									},
									{
										id: 1918,
										name: "KIRIMBI",
										generalAssembly_id: 297,
									},
									{
										id: 9272,
										name: "Gishambashayo Court of Appeal ",
										generalAssembly_id: 297,
									},
									{
										id: 10805,
										name: "Gishambashayo Gacaca court of sector ",
										generalAssembly_id: 297,
									},
								],
							},
							{
								id: 298,
								name: "Rubaya ",
								sector_id: 50515,
								courtOfCells: [
									{
										id: 1919,
										name: "KAGUGO",
										generalAssembly_id: 298,
									},
									{
										id: 1920,
										name: "MUGOTE",
										generalAssembly_id: 298,
									},
									{
										id: 1921,
										name: "NYAGATOJO",
										generalAssembly_id: 298,
									},
									{
										id: 1922,
										name: "NYAKESHA",
										generalAssembly_id: 298,
									},
									{
										id: 1923,
										name: "RUBAYA (2)",
										generalAssembly_id: 298,
									},
									{
										id: 9273,
										name: "Rubaya  Court of Appeal ",
										generalAssembly_id: 298,
									},
									{
										id: 10806,
										name: "Rubaya  Gacaca court of sector ",
										generalAssembly_id: 298,
									},
								],
							},
						],
					},
					{
						id: 50516,
						name: "Rukomo",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051606,
								name: "Munyinya",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051605,
								name: "Mabare",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051604,
								name: "Kinyami",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051603,
								name: "Gisiza",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051602,
								name: "Cyuru",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051601,
								name: "Cyeya",
								sector_id: 50516,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 299,
								name: "Bisika",
								sector_id: 50516,
								courtOfCells: [
									{
										id: 1924,
										name: "BIRAMBO",
										generalAssembly_id: 299,
									},
									{
										id: 1925,
										name: "BWUHIRA",
										generalAssembly_id: 299,
									},
									{
										id: 1926,
										name: "KAMUSARE",
										generalAssembly_id: 299,
									},
									{
										id: 1927,
										name: "KAYUNGWE",
										generalAssembly_id: 299,
									},
									{
										id: 1928,
										name: "MABARE",
										generalAssembly_id: 299,
									},
									{
										id: 1929,
										name: "RWAMIKO",
										generalAssembly_id: 299,
									},
									{
										id: 9274,
										name: "Bisika Court of Appeal ",
										generalAssembly_id: 299,
									},
									{
										id: 10807,
										name: "Bisika Gacaca court of sector ",
										generalAssembly_id: 299,
									},
								],
							},
							{
								id: 300,
								name: "Cyuru ",
								sector_id: 50516,
								courtOfCells: [
									{
										id: 1930,
										name: "BUKAMBA",
										generalAssembly_id: 300,
									},
									{
										id: 1931,
										name: "CYURU",
										generalAssembly_id: 300,
									},
									{
										id: 1932,
										name: "KARAMBI",
										generalAssembly_id: 300,
									},
									{
										id: 1933,
										name: "MUHAMA",
										generalAssembly_id: 300,
									},
									{
										id: 1934,
										name: "MUNYINYA",
										generalAssembly_id: 300,
									},
									{
										id: 1935,
										name: "NYANKOKOMA",
										generalAssembly_id: 300,
									},
									{
										id: 1936,
										name: "RWAMUSHUMBA",
										generalAssembly_id: 300,
									},
									{
										id: 1937,
										name: "SABIRO",
										generalAssembly_id: 300,
									},
									{
										id: 9275,
										name: "Cyuru  Court of Appeal ",
										generalAssembly_id: 300,
									},
									{
										id: 10808,
										name: "Cyuru  Gacaca court of sector ",
										generalAssembly_id: 300,
									},
								],
							},
							{
								id: 301,
								name: "Kinyami",
								sector_id: 50516,
								courtOfCells: [
									{
										id: 1938,
										name: "GITABA",
										generalAssembly_id: 301,
									},
									{
										id: 1939,
										name: "KAVUMU",
										generalAssembly_id: 301,
									},
									{
										id: 1940,
										name: "KIGASHA",
										generalAssembly_id: 301,
									},
									{
										id: 1941,
										name: "MESHERO",
										generalAssembly_id: 301,
									},
									{
										id: 1942,
										name: "MUBUGA",
										generalAssembly_id: 301,
									},
									{
										id: 1943,
										name: "RUTARABANA",
										generalAssembly_id: 301,
									},
									{
										id: 9276,
										name: "Kinyami Court of Appeal ",
										generalAssembly_id: 301,
									},
									{
										id: 10809,
										name: "Kinyami Gacaca court of sector ",
										generalAssembly_id: 301,
									},
								],
							},
						],
					},
					{
						id: 50517,
						name: "Rushaki",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051703,
								name: "Karurama",
								sector_id: 50517,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051702,
								name: "Kamutora",
								sector_id: 50517,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051701,
								name: "Gitega",
								sector_id: 50517,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 302,
								name: "Bugwe ",
								sector_id: 50517,
								courtOfCells: [
									{
										id: 1944,
										name: "BUGWE",
										generalAssembly_id: 302,
									},
									{
										id: 1945,
										name: "KARAMBI",
										generalAssembly_id: 302,
									},
									{
										id: 1946,
										name: "NYABYAMBU",
										generalAssembly_id: 302,
									},
									{
										id: 1947,
										name: "RUBYIRO",
										generalAssembly_id: 302,
									},
									{
										id: 1948,
										name: "RYARUGANZU",
										generalAssembly_id: 302,
									},
									{
										id: 9277,
										name: "Bugwe  Court of Appeal ",
										generalAssembly_id: 302,
									},
									{
										id: 10810,
										name: "Bugwe  Gacaca court of sector ",
										generalAssembly_id: 302,
									},
								],
							},
							{
								id: 303,
								name: "Muyumbu",
								sector_id: 50517,
								courtOfCells: [
									{
										id: 1949,
										name: "CENTRE RUSHAKI",
										generalAssembly_id: 303,
									},
									{
										id: 1950,
										name: "GATONDE",
										generalAssembly_id: 303,
									},
									{
										id: 1951,
										name: "IZINGA",
										generalAssembly_id: 303,
									},
									{
										id: 1952,
										name: "KARURAMA",
										generalAssembly_id: 303,
									},
									{
										id: 1953,
										name: "MABARE",
										generalAssembly_id: 303,
									},
									{
										id: 1954,
										name: "MBUGA",
										generalAssembly_id: 303,
									},
									{
										id: 1955,
										name: "RWARANDA",
										generalAssembly_id: 303,
									},
									{
										id: 9278,
										name: "Muyumbu Court of Appeal ",
										generalAssembly_id: 303,
									},
									{
										id: 10811,
										name: "Muyumbu Gacaca court of sector ",
										generalAssembly_id: 303,
									},
								],
							},
							{
								id: 304,
								name: "Rushaki",
								sector_id: 50517,
								courtOfCells: [
									{
										id: 1956,
										name: "KARWOGA",
										generalAssembly_id: 304,
									},
									{
										id: 1957,
										name: "NGABIRA",
										generalAssembly_id: 304,
									},
									{
										id: 1958,
										name: "NKAMBA",
										generalAssembly_id: 304,
									},
									{
										id: 1959,
										name: "NYAMYUMBA",
										generalAssembly_id: 304,
									},
									{
										id: 9279,
										name: "Rushaki Court of Appeal ",
										generalAssembly_id: 304,
									},
									{
										id: 10812,
										name: "Rushaki Gacaca court of sector ",
										generalAssembly_id: 304,
									},
								],
							},
						],
					},
					{
						id: 50518,
						name: "Rutare",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051806,
								name: "Nkoto",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051805,
								name: "Munanira",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051804,
								name: "Kigabiro",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051803,
								name: "Gatwaro",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051802,
								name: "Gasharu",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051801,
								name: "Bikumba",
								sector_id: 50518,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 305,
								name: "Kabira",
								sector_id: 50518,
								courtOfCells: [
									{
										id: 1960,
										name: "BUGAMBA",
										generalAssembly_id: 305,
									},
									{
										id: 1961,
										name: "GASHINYA",
										generalAssembly_id: 305,
									},
									{
										id: 1962,
										name: "KABIRA",
										generalAssembly_id: 305,
									},
									{
										id: 1963,
										name: "KAGUNDA",
										generalAssembly_id: 305,
									},
									{
										id: 1964,
										name: "MURAMA",
										generalAssembly_id: 305,
									},
									{
										id: 1965,
										name: "RWIMBOGO",
										generalAssembly_id: 305,
									},
									{
										id: 1966,
										name: "YOGI",
										generalAssembly_id: 305,
									},
									{
										id: 9280,
										name: "Kabira Court of Appeal ",
										generalAssembly_id: 305,
									},
									{
										id: 10813,
										name: "Kabira Gacaca court of sector ",
										generalAssembly_id: 305,
									},
								],
							},
							{
								id: 306,
								name: "Kinjojo",
								sector_id: 50518,
								courtOfCells: [
									{
										id: 1967,
										name: "GAHANGA",
										generalAssembly_id: 306,
									},
									{
										id: 1968,
										name: "KABATIMA",
										generalAssembly_id: 306,
									},
									{
										id: 1969,
										name: "KASERUKERENKE",
										generalAssembly_id: 306,
									},
									{
										id: 1970,
										name: "MATYAZO",
										generalAssembly_id: 306,
									},
									{
										id: 1971,
										name: "NYABISINDU",
										generalAssembly_id: 306,
									},
									{
										id: 1972,
										name: "RUGARAMA",
										generalAssembly_id: 306,
									},
									{
										id: 9281,
										name: "Kinjojo Court of Appeal ",
										generalAssembly_id: 306,
									},
									{
										id: 10814,
										name: "Kinjojo Gacaca court of sector ",
										generalAssembly_id: 306,
									},
								],
							},
							{
								id: 307,
								name: "Rambura",
								sector_id: 50518,
								courtOfCells: [
									{
										id: 1973,
										name: "KAYENZI",
										generalAssembly_id: 307,
									},
									{
										id: 1974,
										name: "KIRWA",
										generalAssembly_id: 307,
									},
									{
										id: 1975,
										name: "MUNINI",
										generalAssembly_id: 307,
									},
									{
										id: 1976,
										name: "RUGARAMA",
										generalAssembly_id: 307,
									},
									{
										id: 1977,
										name: "RUKUNGERI",
										generalAssembly_id: 307,
									},
									{
										id: 1978,
										name: "RUTI",
										generalAssembly_id: 307,
									},
									{
										id: 9282,
										name: "Rambura Court of Appeal ",
										generalAssembly_id: 307,
									},
									{
										id: 10815,
										name: "Rambura Gacaca court of sector ",
										generalAssembly_id: 307,
									},
								],
							},
							{
								id: 308,
								name: "Rurembo ",
								sector_id: 50518,
								courtOfCells: [
									{
										id: 1979,
										name: "BARIZA",
										generalAssembly_id: 308,
									},
									{
										id: 1980,
										name: "BWANGAMWANDA",
										generalAssembly_id: 308,
									},
									{
										id: 1981,
										name: "MUREHE",
										generalAssembly_id: 308,
									},
									{
										id: 1982,
										name: "NYAGATOMA",
										generalAssembly_id: 308,
									},
									{
										id: 1983,
										name: "NYANSENGE",
										generalAssembly_id: 308,
									},
									{
										id: 9283,
										name: "Rurembo  Court of Appeal ",
										generalAssembly_id: 308,
									},
									{
										id: 10816,
										name: "Rurembo  Gacaca court of sector ",
										generalAssembly_id: 308,
									},
								],
							},
						],
					},
					{
						id: 50519,
						name: "Ruvune",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5051906,
								name: "Ruhondo",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051905,
								name: "Rebero",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051904,
								name: "Kabare",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051903,
								name: "Gashirira",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051902,
								name: "Gasambya",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5051901,
								name: "Cyandaro",
								sector_id: 50519,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 309,
								name: "Muti",
								sector_id: 50519,
								courtOfCells: [
									{
										id: 1984,
										name: "CYANYANDAGO",
										generalAssembly_id: 309,
									},
									{
										id: 1985,
										name: "KINIHIRA",
										generalAssembly_id: 309,
									},
									{
										id: 1986,
										name: "MURAMBO",
										generalAssembly_id: 309,
									},
									{
										id: 1987,
										name: "RUKESHA",
										generalAssembly_id: 309,
									},
									{
										id: 9284,
										name: "Muti Court of Appeal ",
										generalAssembly_id: 309,
									},
									{
										id: 10817,
										name: "Muti Gacaca court of sector ",
										generalAssembly_id: 309,
									},
								],
							},
							{
								id: 310,
								name: "Nyarurama ",
								sector_id: 50519,
								courtOfCells: [
									{
										id: 1988,
										name: "KAGASHA",
										generalAssembly_id: 310,
									},
									{
										id: 1989,
										name: "KIRAMBO",
										generalAssembly_id: 310,
									},
									{
										id: 1990,
										name: "NYARURAMA",
										generalAssembly_id: 310,
									},
									{
										id: 9285,
										name: "Nyarurama  Court of Appeal ",
										generalAssembly_id: 310,
									},
									{
										id: 10818,
										name: "Nyarurama  Gacaca court of sector ",
										generalAssembly_id: 310,
									},
								],
							},
							{
								id: 311,
								name: "Ruvune",
								sector_id: 50519,
								courtOfCells: [
									{
										id: 1991,
										name: "GAHENGERI",
										generalAssembly_id: 311,
									},
									{
										id: 1992,
										name: "KAMIMA",
										generalAssembly_id: 311,
									},
									{
										id: 1993,
										name: "NYAGITABIRE",
										generalAssembly_id: 311,
									},
									{
										id: 1994,
										name: "NYAKABUNGO",
										generalAssembly_id: 311,
									},
									{
										id: 1995,
										name: "NYARUVUMU",
										generalAssembly_id: 311,
									},
									{
										id: 1996,
										name: "RUBONA",
										generalAssembly_id: 311,
									},
									{
										id: 1997,
										name: "RUVUNE",
										generalAssembly_id: 311,
									},
									{
										id: 1998,
										name: "RWAMUSHUMBA",
										generalAssembly_id: 311,
									},
									{
										id: 9286,
										name: "Ruvune Court of Appeal ",
										generalAssembly_id: 311,
									},
									{
										id: 10819,
										name: "Ruvune Gacaca court of sector ",
										generalAssembly_id: 311,
									},
								],
							},
							{
								id: 312,
								name: "Bushwagara ",
								sector_id: 50519,
								courtOfCells: [
									{
										id: 1999,
										name: "GASAMBYA",
										generalAssembly_id: 312,
									},
									{
										id: 2000,
										name: "KIDUNDUGURU",
										generalAssembly_id: 312,
									},
									{
										id: 2001,
										name: "KIRARA",
										generalAssembly_id: 312,
									},
									{
										id: 2002,
										name: "NYAMIRAMA",
										generalAssembly_id: 312,
									},
									{
										id: 9287,
										name: "Bushwagara  Court of Appeal ",
										generalAssembly_id: 312,
									},
									{
										id: 10820,
										name: "Bushwagara  Gacaca court of sector ",
										generalAssembly_id: 312,
									},
								],
							},
							{
								id: 313,
								name: "Nyabihu ",
								sector_id: 50519,
								courtOfCells: [
									{
										id: 2003,
										name: "BUYANJA",
										generalAssembly_id: 313,
									},
									{
										id: 2004,
										name: "GATARE",
										generalAssembly_id: 313,
									},
									{
										id: 2005,
										name: "KABARE",
										generalAssembly_id: 313,
									},
									{
										id: 2006,
										name: "MUREHE",
										generalAssembly_id: 313,
									},
									{
										id: 9288,
										name: "Nyabihu  Court of Appeal ",
										generalAssembly_id: 313,
									},
									{
										id: 10821,
										name: "Nyabihu  Gacaca court of sector ",
										generalAssembly_id: 313,
									},
								],
							},
						],
					},
					{
						id: 50520,
						name: "Rwamiko",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5052003,
								name: "Nyagahinga",
								sector_id: 50520,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052002,
								name: "Kigabiro",
								sector_id: 50520,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052001,
								name: "Cyeru",
								sector_id: 50520,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 314,
								name: "Bijunde",
								sector_id: 50520,
								courtOfCells: [
									{
										id: 2007,
										name: "KABIRA",
										generalAssembly_id: 314,
									},
									{
										id: 2008,
										name: "KANIGA",
										generalAssembly_id: 314,
									},
									{
										id: 2009,
										name: "KANYOVE",
										generalAssembly_id: 314,
									},
									{
										id: 2010,
										name: "KIVUMU",
										generalAssembly_id: 314,
									},
									{
										id: 2011,
										name: "MURAMA",
										generalAssembly_id: 314,
									},
									{
										id: 2012,
										name: "MUTAMBIKO",
										generalAssembly_id: 314,
									},
									{
										id: 9289,
										name: "Bijunde Court of Appeal ",
										generalAssembly_id: 314,
									},
									{
										id: 10822,
										name: "Bijunde Gacaca court of sector ",
										generalAssembly_id: 314,
									},
								],
							},
							{
								id: 315,
								name: "Nyanza ",
								sector_id: 50520,
								courtOfCells: [
									{
										id: 2013,
										name: "MURAMA",
										generalAssembly_id: 315,
									},
									{
										id: 2014,
										name: "NTAREMBA",
										generalAssembly_id: 315,
									},
									{
										id: 2015,
										name: "NYAGATUGUNDA",
										generalAssembly_id: 315,
									},
									{
										id: 2016,
										name: "NYANZA",
										generalAssembly_id: 315,
									},
									{
										id: 2017,
										name: "RUBONA",
										generalAssembly_id: 315,
									},
									{
										id: 9290,
										name: "Nyanza  Court of Appeal ",
										generalAssembly_id: 315,
									},
									{
										id: 10823,
										name: "Nyanza  Gacaca court of sector ",
										generalAssembly_id: 315,
									},
								],
							},
							{
								id: 316,
								name: "Rwamiko",
								sector_id: 50520,
								courtOfCells: [
									{
										id: 2018,
										name: "BUGARURA",
										generalAssembly_id: 316,
									},
									{
										id: 2019,
										name: "CYERU",
										generalAssembly_id: 316,
									},
									{
										id: 2020,
										name: "GABIRO",
										generalAssembly_id: 316,
									},
									{
										id: 2021,
										name: "KALIKA",
										generalAssembly_id: 316,
									},
									{
										id: 2022,
										name: "MUKUYU",
										generalAssembly_id: 316,
									},
									{
										id: 2023,
										name: "MURAMBI",
										generalAssembly_id: 316,
									},
									{
										id: 2024,
										name: "NYAGASOZI",
										generalAssembly_id: 316,
									},
									{
										id: 9291,
										name: "Rwamiko Court of Appeal ",
										generalAssembly_id: 316,
									},
									{
										id: 10824,
										name: "Rwamiko Gacaca court of sector ",
										generalAssembly_id: 316,
									},
								],
							},
						],
					},
					{
						id: 50521,
						name: "Shangasha",
						district_id: 505,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5052105,
								name: "Shangasha",
								sector_id: 50521,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052104,
								name: "Nyabubare",
								sector_id: 50521,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052103,
								name: "Nyabishambi",
								sector_id: 50521,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052102,
								name: "Kitazigurwa",
								sector_id: 50521,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5052101,
								name: "Bushara",
								sector_id: 50521,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 317,
								name: "Bushara ",
								sector_id: 50521,
								courtOfCells: [
									{
										id: 2025,
										name: "BUSHARA",
										generalAssembly_id: 317,
									},
									{
										id: 2026,
										name: "GASURA",
										generalAssembly_id: 317,
									},
									{
										id: 2027,
										name: "GATARE",
										generalAssembly_id: 317,
									},
									{
										id: 2028,
										name: "GISIZA",
										generalAssembly_id: 317,
									},
									{
										id: 2029,
										name: "NYABUBARE",
										generalAssembly_id: 317,
									},
									{
										id: 2030,
										name: "NYAMIYAGA",
										generalAssembly_id: 317,
									},
									{
										id: 9292,
										name: "Bushara  Court of Appeal ",
										generalAssembly_id: 317,
									},
									{
										id: 10825,
										name: "Bushara  Gacaca court of sector ",
										generalAssembly_id: 317,
									},
								],
							},
							{
								id: 318,
								name: "Nyabishambi ",
								sector_id: 50521,
								courtOfCells: [
									{
										id: 2031,
										name: "GASIZA",
										generalAssembly_id: 318,
									},
									{
										id: 2032,
										name: "KAGARI",
										generalAssembly_id: 318,
									},
									{
										id: 2033,
										name: "KITAZIGURWA",
										generalAssembly_id: 318,
									},
									{
										id: 2034,
										name: "MUBUGA",
										generalAssembly_id: 318,
									},
									{
										id: 2035,
										name: "MURAMBO",
										generalAssembly_id: 318,
									},
									{
										id: 2036,
										name: "NTOMVU",
										generalAssembly_id: 318,
									},
									{
										id: 2037,
										name: "RUGARAMA",
										generalAssembly_id: 318,
									},
									{
										id: 2038,
										name: "RUKINIRO",
										generalAssembly_id: 318,
									},
									{
										id: 9293,
										name: "Nyabishambi  Court of Appeal ",
										generalAssembly_id: 318,
									},
									{
										id: 10826,
										name: "Nyabishambi  Gacaca court of sector ",
										generalAssembly_id: 318,
									},
								],
							},
							{
								id: 319,
								name: "Shangasha ",
								sector_id: 50521,
								courtOfCells: [
									{
										id: 2039,
										name: "MUNINI",
										generalAssembly_id: 319,
									},
									{
										id: 2040,
										name: "RUNABA",
										generalAssembly_id: 319,
									},
									{
										id: 2041,
										name: "RYAMATEBURA",
										generalAssembly_id: 319,
									},
									{
										id: 2042,
										name: "SHANGASHA",
										generalAssembly_id: 319,
									},
									{
										id: 9294,
										name: "Shangasha  Court of Appeal ",
										generalAssembly_id: 319,
									},
									{
										id: 10827,
										name: "Shangasha  Gacaca court of sector ",
										generalAssembly_id: 319,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 503,
				name: "Musanze",
				province_id: 5,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 50304,
						name: "Gashaki",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030404,
								name: "Muharuro",
								sector_id: 50304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030403,
								name: "Mbwe",
								sector_id: 50304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030402,
								name: "Kivumu",
								sector_id: 50304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030401,
								name: "Kigabiro",
								sector_id: 50304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 716,
								name: "Gashaki",
								sector_id: 50304,
								courtOfCells: [
									{
										id: 4054,
										name: "BUHURA",
										generalAssembly_id: 716,
									},
									{
										id: 4055,
										name: "BUREMBO",
										generalAssembly_id: 716,
									},
									{
										id: 4056,
										name: "GASEBEYA",
										generalAssembly_id: 716,
									},
									{
										id: 4057,
										name: "GITEGA",
										generalAssembly_id: 716,
									},
									{
										id: 4058,
										name: "GITONYA",
										generalAssembly_id: 716,
									},
									{
										id: 9691,
										name: "Gashaki Court of Appeal ",
										generalAssembly_id: 716,
									},
									{
										id: 11224,
										name: "Gashaki Gacaca court of sector ",
										generalAssembly_id: 716,
									},
								],
							},
							{
								id: 717,
								name: "Giheta",
								sector_id: 50304,
								courtOfCells: [
									{
										id: 4059,
										name: "BUTUNDA",
										generalAssembly_id: 717,
									},
									{
										id: 4060,
										name: "CYIRI",
										generalAssembly_id: 717,
									},
									{
										id: 4061,
										name: "GAHAMA",
										generalAssembly_id: 717,
									},
									{
										id: 4062,
										name: "GAKORO",
										generalAssembly_id: 717,
									},
									{
										id: 4063,
										name: "MURORA",
										generalAssembly_id: 717,
									},
									{
										id: 4064,
										name: "SARAZI",
										generalAssembly_id: 717,
									},
									{
										id: 9692,
										name: "Giheta Court of Appeal ",
										generalAssembly_id: 717,
									},
									{
										id: 11225,
										name: "Giheta Gacaca court of sector ",
										generalAssembly_id: 717,
									},
								],
							},
							{
								id: 718,
								name: "Rubange ",
								sector_id: 50304,
								courtOfCells: [
									{
										id: 4065,
										name: "KABUKENDE",
										generalAssembly_id: 718,
									},
									{
										id: 4066,
										name: "KARAMBI",
										generalAssembly_id: 718,
									},
									{
										id: 4067,
										name: "KARWASA",
										generalAssembly_id: 718,
									},
									{
										id: 4068,
										name: "BURENGO",
										generalAssembly_id: 718,
									},
									{
										id: 4069,
										name: "SARAZI",
										generalAssembly_id: 718,
									},
									{
										id: 9693,
										name: "Rubange  Court of Appeal ",
										generalAssembly_id: 718,
									},
									{
										id: 11226,
										name: "Rubange  Gacaca court of sector ",
										generalAssembly_id: 718,
									},
								],
							},
						],
					},
					{
						id: 50305,
						name: "Gataraga",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030504,
								name: "Rungu",
								sector_id: 50305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030503,
								name: "Rubindi",
								sector_id: 50305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030502,
								name: "Murago",
								sector_id: 50305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030501,
								name: "Mudakama",
								sector_id: 50305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 719,
								name: "Gataraga",
								sector_id: 50305,
								courtOfCells: [
									{
										id: 4070,
										name: "BUTAKANYUNDO",
										generalAssembly_id: 719,
									},
									{
										id: 4071,
										name: "GACONDO",
										generalAssembly_id: 719,
									},
									{
										id: 4072,
										name: "GATARAGA I",
										generalAssembly_id: 719,
									},
									{
										id: 4073,
										name: "GATARAGA II",
										generalAssembly_id: 719,
									},
									{
										id: 4074,
										name: "RUKINGO I",
										generalAssembly_id: 719,
									},
									{
										id: 4075,
										name: "RUKINGO II",
										generalAssembly_id: 719,
									},
									{
										id: 9694,
										name: "Gataraga Court of Appeal ",
										generalAssembly_id: 719,
									},
									{
										id: 11227,
										name: "Gataraga Gacaca court of sector ",
										generalAssembly_id: 719,
									},
								],
							},
							{
								id: 720,
								name: "Nyabirehe",
								sector_id: 50305,
								courtOfCells: [
									{
										id: 4076,
										name: "GAHIRA",
										generalAssembly_id: 720,
									},
									{
										id: 4077,
										name: "GATOVU",
										generalAssembly_id: 720,
									},
									{
										id: 4078,
										name: "KABYAZA",
										generalAssembly_id: 720,
									},
									{
										id: 4079,
										name: "KAGONGO",
										generalAssembly_id: 720,
									},
									{
										id: 4080,
										name: "NYABIREHE",
										generalAssembly_id: 720,
									},
									{
										id: 4081,
										name: "RUBAKA",
										generalAssembly_id: 720,
									},
									{
										id: 4082,
										name: "RUTAGARA",
										generalAssembly_id: 720,
									},
									{
										id: 9695,
										name: "Nyabirehe Court of Appeal ",
										generalAssembly_id: 720,
									},
									{
										id: 11228,
										name: "Nyabirehe Gacaca court of sector ",
										generalAssembly_id: 720,
									},
								],
							},
							{
								id: 721,
								name: "Rwinzovu",
								sector_id: 50305,
								courtOfCells: [
									{
										id: 4083,
										name: "GAKUKU",
										generalAssembly_id: 721,
									},
									{
										id: 4084,
										name: "GATAKA-RUREMERA",
										generalAssembly_id: 721,
									},
									{
										id: 4085,
										name: "MANJARI I",
										generalAssembly_id: 721,
									},
									{
										id: 4086,
										name: "MANJARI II",
										generalAssembly_id: 721,
									},
									{
										id: 4087,
										name: "MIKINGO",
										generalAssembly_id: 721,
									},
									{
										id: 4088,
										name: "RUSAMBU",
										generalAssembly_id: 721,
									},
									{
										id: 4089,
										name: "RWINZOVU",
										generalAssembly_id: 721,
									},
									{
										id: 9696,
										name: "Rwinzovu Court of Appeal ",
										generalAssembly_id: 721,
									},
									{
										id: 11229,
										name: "Rwinzovu Gacaca court of sector ",
										generalAssembly_id: 721,
									},
								],
							},
						],
					},
					{
						id: 50306,
						name: "Kimonyi",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030604,
								name: "Mbizi",
								sector_id: 50306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030603,
								name: "Kivumu",
								sector_id: 50306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030602,
								name: "Buramira",
								sector_id: 50306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030601,
								name: "Birira",
								sector_id: 50306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 722,
								name: "Kabere II ",
								sector_id: 50306,
								courtOfCells: [
									{
										id: 4090,
										name: "BUDOGO",
										generalAssembly_id: 722,
									},
									{
										id: 4091,
										name: "KANYANA",
										generalAssembly_id: 722,
									},
									{
										id: 4092,
										name: "MBIZI",
										generalAssembly_id: 722,
									},
									{
										id: 4093,
										name: "MUNANIRA",
										generalAssembly_id: 722,
									},
									{
										id: 4094,
										name: "RWAZA",
										generalAssembly_id: 722,
									},
									{
										id: 9697,
										name: "Kabere II  Court of Appeal ",
										generalAssembly_id: 722,
									},
									{
										id: 11230,
										name: "Kabere II  Gacaca court of sector ",
										generalAssembly_id: 722,
									},
								],
							},
							{
								id: 723,
								name: "Kimonyi ",
								sector_id: 50306,
								courtOfCells: [
									{
										id: 4095,
										name: "KIMONYI I",
										generalAssembly_id: 723,
									},
									{
										id: 4096,
										name: "KIMONYI II",
										generalAssembly_id: 723,
									},
									{
										id: 4097,
										name: "NDORAHE",
										generalAssembly_id: 723,
									},
									{
										id: 4098,
										name: "NDURUMA",
										generalAssembly_id: 723,
									},
									{
										id: 9698,
										name: "Kimonyi  Court of Appeal ",
										generalAssembly_id: 723,
									},
									{
										id: 11231,
										name: "Kimonyi  Gacaca court of sector ",
										generalAssembly_id: 723,
									},
								],
							},
							{
								id: 724,
								name: "Kitabura ",
								sector_id: 50306,
								courtOfCells: [
									{
										id: 4099,
										name: "BUNYOMA",
										generalAssembly_id: 724,
									},
									{
										id: 4100,
										name: "BURAMIRA",
										generalAssembly_id: 724,
									},
									{
										id: 4101,
										name: "GACACA",
										generalAssembly_id: 724,
									},
									{
										id: 4102,
										name: "MUBUGA",
										generalAssembly_id: 724,
									},
									{
										id: 4103,
										name: "NYIRABAGUME",
										generalAssembly_id: 724,
									},
									{
										id: 9699,
										name: "Kitabura  Court of Appeal ",
										generalAssembly_id: 724,
									},
									{
										id: 11232,
										name: "Kitabura  Gacaca court of sector ",
										generalAssembly_id: 724,
									},
								],
							},
						],
					},
					{
						id: 50307,
						name: "Kinigi",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030705,
								name: "Nyonirima",
								sector_id: 50307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030704,
								name: "Nyabigoma",
								sector_id: 50307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030703,
								name: "Kampanga",
								sector_id: 50307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030702,
								name: "Kaguhu",
								sector_id: 50307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030701,
								name: "Bisoke",
								sector_id: 50307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 725,
								name: "Bisate",
								sector_id: 50307,
								courtOfCells: [
									{
										id: 4104,
										name: "BANNYISOGO",
										generalAssembly_id: 725,
									},
									{
										id: 4105,
										name: "BUSHOKORO",
										generalAssembly_id: 725,
									},
									{
										id: 4106,
										name: "GISASA",
										generalAssembly_id: 725,
									},
									{
										id: 4107,
										name: "KABARA",
										generalAssembly_id: 725,
									},
									{
										id: 4108,
										name: "NDUBI",
										generalAssembly_id: 725,
									},
									{
										id: 4109,
										name: "NYARUSIZI",
										generalAssembly_id: 725,
									},
									{
										id: 4110,
										name: "NYARWAYU",
										generalAssembly_id: 725,
									},
									{
										id: 4111,
										name: "RUGINGA",
										generalAssembly_id: 725,
									},
									{
										id: 9700,
										name: "Bisate Court of Appeal ",
										generalAssembly_id: 725,
									},
									{
										id: 11233,
										name: "Bisate Gacaca court of sector ",
										generalAssembly_id: 725,
									},
								],
							},
							{
								id: 726,
								name: "Kabwende",
								sector_id: 50307,
								courtOfCells: [
									{
										id: 4112,
										name: "CYABIRUMBA",
										generalAssembly_id: 726,
									},
									{
										id: 4113,
										name: "GIFUMBA",
										generalAssembly_id: 726,
									},
									{
										id: 4114,
										name: "KANSORO",
										generalAssembly_id: 726,
									},
									{
										id: 4115,
										name: "RUHANGA I",
										generalAssembly_id: 726,
									},
									{
										id: 4116,
										name: "RUHANGA II",
										generalAssembly_id: 726,
									},
									{
										id: 4117,
										name: "RYANGO",
										generalAssembly_id: 726,
									},
									{
										id: 9701,
										name: "Kabwende Court of Appeal ",
										generalAssembly_id: 726,
									},
									{
										id: 11234,
										name: "Kabwende Gacaca court of sector ",
										generalAssembly_id: 726,
									},
								],
							},
							{
								id: 727,
								name: "Kanyamiheto",
								sector_id: 50307,
								courtOfCells: [
									{
										id: 4118,
										name: "GAHETO",
										generalAssembly_id: 727,
									},
									{
										id: 4119,
										name: "KAREMBERA",
										generalAssembly_id: 727,
									},
									{
										id: 4120,
										name: "NYARUBANDE",
										generalAssembly_id: 727,
									},
									{
										id: 4121,
										name: "RUGESHI",
										generalAssembly_id: 727,
									},
									{
										id: 4122,
										name: "RUKORE",
										generalAssembly_id: 727,
									},
									{
										id: 4123,
										name: "RUSHUBI",
										generalAssembly_id: 727,
									},
									{
										id: 9702,
										name: "Kanyamiheto Court of Appeal ",
										generalAssembly_id: 727,
									},
									{
										id: 11235,
										name: "Kanyamiheto Gacaca court of sector ",
										generalAssembly_id: 727,
									},
								],
							},
							{
								id: 728,
								name: "Nyabitsinde",
								sector_id: 50307,
								courtOfCells: [
									{
										id: 4124,
										name: "BUHANGABO",
										generalAssembly_id: 728,
									},
									{
										id: 4125,
										name: "KARANDARYI",
										generalAssembly_id: 728,
									},
									{
										id: 4126,
										name: "RWAMIKORE",
										generalAssembly_id: 728,
									},
									{
										id: 4127,
										name: "RYANGO",
										generalAssembly_id: 728,
									},
									{
										id: 9703,
										name: "Nyabitsinde Court of Appeal ",
										generalAssembly_id: 728,
									},
									{
										id: 11236,
										name: "Nyabitsinde Gacaca court of sector ",
										generalAssembly_id: 728,
									},
								],
							},
						],
					},
					{
						id: 50308,
						name: "Muhoza",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030804,
								name: "Ruhengeri",
								sector_id: 50308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030803,
								name: "Mpenge",
								sector_id: 50308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030802,
								name: "Kigombe",
								sector_id: 50308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030801,
								name: "Cyabararika",
								sector_id: 50308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 729,
								name: "Gasanze",
								sector_id: 50308,
								courtOfCells: [
									{
										id: 4128,
										name: "BURINDA",
										generalAssembly_id: 729,
									},
									{
										id: 4129,
										name: "NYARUBUYE",
										generalAssembly_id: 729,
									},
									{
										id: 4130,
										name: "TARA",
										generalAssembly_id: 729,
									},
									{
										id: 9704,
										name: "Gasanze Court of Appeal ",
										generalAssembly_id: 729,
									},
									{
										id: 11237,
										name: "Gasanze Gacaca court of sector ",
										generalAssembly_id: 729,
									},
								],
							},
							{
								id: 730,
								name: "Kabaya",
								sector_id: 50308,
								courtOfCells: [
									{
										id: 4131,
										name: "BURERA",
										generalAssembly_id: 730,
									},
									{
										id: 4132,
										name: "BUSHOZI",
										generalAssembly_id: 730,
									},
									{
										id: 4133,
										name: "KABATEMBAGARA",
										generalAssembly_id: 730,
									},
									{
										id: 4134,
										name: "KABORO",
										generalAssembly_id: 730,
									},
									{
										id: 4135,
										name: "RUKEREZA",
										generalAssembly_id: 730,
									},
									{
										id: 9705,
										name: "Kabaya Court of Appeal ",
										generalAssembly_id: 730,
									},
									{
										id: 11238,
										name: "Kabaya Gacaca court of sector ",
										generalAssembly_id: 730,
									},
								],
							},
							{
								id: 731,
								name: "Mubona ",
								sector_id: 50308,
								courtOfCells: [
									{
										id: 4136,
										name: "KAVUMU",
										generalAssembly_id: 731,
									},
									{
										id: 4137,
										name: "KIRYI",
										generalAssembly_id: 731,
									},
									{
										id: 9706,
										name: "Mubona  Court of Appeal ",
										generalAssembly_id: 731,
									},
									{
										id: 11239,
										name: "Mubona  Gacaca court of sector ",
										generalAssembly_id: 731,
									},
								],
							},
							{
								id: 732,
								name: "Muhoza",
								sector_id: 50308,
								courtOfCells: [
									{
										id: 4138,
										name: "BWUZURI",
										generalAssembly_id: 732,
									},
									{
										id: 4139,
										name: "MUGARA",
										generalAssembly_id: 732,
									},
									{
										id: 4140,
										name: "RUSAGARA",
										generalAssembly_id: 732,
									},
									{
										id: 4141,
										name: "RUKORO",
										generalAssembly_id: 732,
									},
									{
										id: 9707,
										name: "Muhoza Court of Appeal ",
										generalAssembly_id: 732,
									},
									{
										id: 11240,
										name: "Muhoza Gacaca court of sector ",
										generalAssembly_id: 732,
									},
								],
							},
						],
					},
					{
						id: 50309,
						name: "Muko",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030904,
								name: "Songa",
								sector_id: 50309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030903,
								name: "Mburabuturo",
								sector_id: 50309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030902,
								name: "Cyogo",
								sector_id: 50309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030901,
								name: "Cyivugiza",
								sector_id: 50309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 733,
								name: "Kabere I ",
								sector_id: 50309,
								courtOfCells: [
									{
										id: 4142,
										name: "BUGOROZI",
										generalAssembly_id: 733,
									},
									{
										id: 4143,
										name: "BUSURA",
										generalAssembly_id: 733,
									},
									{
										id: 4144,
										name: "KAVUNDA",
										generalAssembly_id: 733,
									},
									{
										id: 4145,
										name: "MUTABO",
										generalAssembly_id: 733,
									},
									{
										id: 4146,
										name: "RUGERERO",
										generalAssembly_id: 733,
									},
									{
										id: 9708,
										name: "Kabere I  Court of Appeal ",
										generalAssembly_id: 733,
									},
									{
										id: 11241,
										name: "Kabere I  Gacaca court of sector ",
										generalAssembly_id: 733,
									},
								],
							},
							{
								id: 734,
								name: "Kanza ",
								sector_id: 50309,
								courtOfCells: [
									{
										id: 4147,
										name: "BUGESE",
										generalAssembly_id: 734,
									},
									{
										id: 4148,
										name: "KAGANDO",
										generalAssembly_id: 734,
									},
									{
										id: 4149,
										name: "KANKAMBA",
										generalAssembly_id: 734,
									},
									{
										id: 4150,
										name: "MUGARA",
										generalAssembly_id: 734,
									},
									{
										id: 4151,
										name: "RUBURANWA",
										generalAssembly_id: 734,
									},
									{
										id: 9709,
										name: "Kanza  Court of Appeal ",
										generalAssembly_id: 734,
									},
									{
										id: 11242,
										name: "Kanza  Gacaca court of sector ",
										generalAssembly_id: 734,
									},
								],
							},
							{
								id: 735,
								name: "Muko ",
								sector_id: 50309,
								courtOfCells: [
									{
										id: 4152,
										name: "GACUBA",
										generalAssembly_id: 735,
									},
									{
										id: 4153,
										name: "GAKORO",
										generalAssembly_id: 735,
									},
									{
										id: 4154,
										name: "GISENYI",
										generalAssembly_id: 735,
									},
									{
										id: 4155,
										name: "KARAMBI",
										generalAssembly_id: 735,
									},
									{
										id: 4156,
										name: "MUBUGA",
										generalAssembly_id: 735,
									},
									{
										id: 9710,
										name: "Muko  Court of Appeal ",
										generalAssembly_id: 735,
									},
									{
										id: 11243,
										name: "Muko  Gacaca court of sector ",
										generalAssembly_id: 735,
									},
								],
							},
							{
								id: 736,
								name: "Muguri ",
								sector_id: 50309,
								courtOfCells: [
									{
										id: 4157,
										name: "JOMBA",
										generalAssembly_id: 736,
									},
									{
										id: 4158,
										name: "KIBEREGE I",
										generalAssembly_id: 736,
									},
									{
										id: 4159,
										name: "KIBEREGE II",
										generalAssembly_id: 736,
									},
									{
										id: 4160,
										name: "KIYAGA I",
										generalAssembly_id: 736,
									},
									{
										id: 4161,
										name: "KIYAGA II",
										generalAssembly_id: 736,
									},
									{
										id: 4162,
										name: "NDERA",
										generalAssembly_id: 736,
									},
									{
										id: 9711,
										name: "Muguri  Court of Appeal ",
										generalAssembly_id: 736,
									},
									{
										id: 11244,
										name: "Muguri  Gacaca court of sector ",
										generalAssembly_id: 736,
									},
								],
							},
						],
					},
					{
						id: 50310,
						name: "Musanze",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031005,
								name: "Rwambogo",
								sector_id: 50310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031004,
								name: "Nyarubuye",
								sector_id: 50310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031003,
								name: "Kabazungu",
								sector_id: 50310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031002,
								name: "Garuka",
								sector_id: 50310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031001,
								name: "Cyabagarura",
								sector_id: 50310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 737,
								name: "Gahondogo",
								sector_id: 50310,
								courtOfCells: [
									{
										id: 4163,
										name: "BUKANE",
										generalAssembly_id: 737,
									},
									{
										id: 4164,
										name: "KIROBA",
										generalAssembly_id: 737,
									},
									{
										id: 4165,
										name: "RUVUMU",
										generalAssembly_id: 737,
									},
									{
										id: 9712,
										name: "Gahondogo Court of Appeal ",
										generalAssembly_id: 737,
									},
									{
										id: 11245,
										name: "Gahondogo Gacaca court of sector ",
										generalAssembly_id: 737,
									},
								],
							},
							{
								id: 738,
								name: "Musanze I",
								sector_id: 50310,
								courtOfCells: [
									{
										id: 4166,
										name: "BUHUNGE",
										generalAssembly_id: 738,
									},
									{
										id: 4167,
										name: "KIREREMA",
										generalAssembly_id: 738,
									},
									{
										id: 4168,
										name: "RUNYANGWE",
										generalAssembly_id: 738,
									},
									{
										id: 4169,
										name: "RWUNGA",
										generalAssembly_id: 738,
									},
									{
										id: 9713,
										name: "Musanze I Court of Appeal ",
										generalAssembly_id: 738,
									},
									{
										id: 11246,
										name: "Musanze I Gacaca court of sector ",
										generalAssembly_id: 738,
									},
								],
							},
							{
								id: 739,
								name: "Musanze II",
								sector_id: 50310,
								courtOfCells: [
									{
										id: 4170,
										name: "CYANTURO",
										generalAssembly_id: 739,
									},
									{
										id: 4171,
										name: "GACINYIRO",
										generalAssembly_id: 739,
									},
									{
										id: 4172,
										name: "GAPFURO",
										generalAssembly_id: 739,
									},
									{
										id: 4173,
										name: "GIKERI",
										generalAssembly_id: 739,
									},
									{
										id: 4174,
										name: "KANGANWA",
										generalAssembly_id: 739,
									},
									{
										id: 4175,
										name: "KANYABIRAYI",
										generalAssembly_id: 739,
									},
									{
										id: 4176,
										name: "KANYAMINABA",
										generalAssembly_id: 739,
									},
									{
										id: 9714,
										name: "Musanze II Court of Appeal ",
										generalAssembly_id: 739,
									},
									{
										id: 11247,
										name: "Musanze II Gacaca court of sector ",
										generalAssembly_id: 739,
									},
								],
							},
							{
								id: 740,
								name: "Nyarugina",
								sector_id: 50310,
								courtOfCells: [
									{
										id: 4177,
										name: "BIHINGA",
										generalAssembly_id: 740,
									},
									{
										id: 4178,
										name: "KANIGA",
										generalAssembly_id: 740,
									},
									{
										id: 4179,
										name: "KIDENDEZI",
										generalAssembly_id: 740,
									},
									{
										id: 4180,
										name: "MUFUKURO",
										generalAssembly_id: 740,
									},
									{
										id: 4181,
										name: "NYABAGENI",
										generalAssembly_id: 740,
									},
									{
										id: 4182,
										name: "RUCUMU",
										generalAssembly_id: 740,
									},
									{
										id: 4183,
										name: "RWUNGA",
										generalAssembly_id: 740,
									},
									{
										id: 9715,
										name: "Nyarugina Court of Appeal ",
										generalAssembly_id: 740,
									},
									{
										id: 11248,
										name: "Nyarugina Gacaca court of sector ",
										generalAssembly_id: 740,
									},
								],
							},
							{
								id: 741,
								name: "Tero",
								sector_id: 50310,
								courtOfCells: [
									{
										id: 4184,
										name: "BANNYISUKA",
										generalAssembly_id: 741,
									},
									{
										id: 4185,
										name: "KAREBA",
										generalAssembly_id: 741,
									},
									{
										id: 4186,
										name: "KAVUMBU",
										generalAssembly_id: 741,
									},
									{
										id: 4187,
										name: "MURENZI",
										generalAssembly_id: 741,
									},
									{
										id: 4188,
										name: "NTURO",
										generalAssembly_id: 741,
									},
									{
										id: 4189,
										name: "TERO",
										generalAssembly_id: 741,
									},
									{
										id: 9716,
										name: "Tero Court of Appeal ",
										generalAssembly_id: 741,
									},
									{
										id: 11249,
										name: "Tero Gacaca court of sector ",
										generalAssembly_id: 741,
									},
								],
							},
						],
					},
					{
						id: 50311,
						name: "Nkotsi",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031105,
								name: "Ruyumba",
								sector_id: 50311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031104,
								name: "Rugeshi",
								sector_id: 50311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031103,
								name: "Mubago",
								sector_id: 50311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031102,
								name: "Gashinga",
								sector_id: 50311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031101,
								name: "Bikara",
								sector_id: 50311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 742,
								name: "Gisoro",
								sector_id: 50311,
								courtOfCells: [
									{
										id: 4190,
										name: "GISORORO",
										generalAssembly_id: 742,
									},
									{
										id: 4191,
										name: "MUGUTA-MWILI",
										generalAssembly_id: 742,
									},
									{
										id: 4192,
										name: "NKURURA",
										generalAssembly_id: 742,
									},
									{
										id: 4193,
										name: "RUGANDA",
										generalAssembly_id: 742,
									},
									{
										id: 9717,
										name: "Gisoro Court of Appeal ",
										generalAssembly_id: 742,
									},
									{
										id: 11250,
										name: "Gisoro Gacaca court of sector ",
										generalAssembly_id: 742,
									},
								],
							},
							{
								id: 743,
								name: "Kabere III ",
								sector_id: 50311,
								courtOfCells: [
									{
										id: 4194,
										name: "BUGUGU-MUSEMBE",
										generalAssembly_id: 743,
									},
									{
										id: 4195,
										name: "BUHAMO",
										generalAssembly_id: 743,
									},
									{
										id: 4196,
										name: "NYAGAHONDO",
										generalAssembly_id: 743,
									},
									{
										id: 4197,
										name: "NYARUBINGO",
										generalAssembly_id: 743,
									},
									{
										id: 9718,
										name: "Kabere III  Court of Appeal ",
										generalAssembly_id: 743,
									},
									{
										id: 11251,
										name: "Kabere III  Gacaca court of sector ",
										generalAssembly_id: 743,
									},
								],
							},
							{
								id: 744,
								name: "Nkotsi",
								sector_id: 50311,
								courtOfCells: [
									{
										id: 4198,
										name: "BASUMBA",
										generalAssembly_id: 744,
									},
									{
										id: 4199,
										name: "BUGOMBE",
										generalAssembly_id: 744,
									},
									{
										id: 4200,
										name: "RUKORO",
										generalAssembly_id: 744,
									},
									{
										id: 4201,
										name: "RUSURI",
										generalAssembly_id: 744,
									},
									{
										id: 4202,
										name: "SONGA",
										generalAssembly_id: 744,
									},
									{
										id: 9719,
										name: "Nkotsi Court of Appeal ",
										generalAssembly_id: 744,
									},
									{
										id: 11252,
										name: "Nkotsi Gacaca court of sector ",
										generalAssembly_id: 744,
									},
								],
							},
							{
								id: 745,
								name: "Rubona ",
								sector_id: 50311,
								courtOfCells: [
									{
										id: 4203,
										name: "BUHARARA-BUHANGA",
										generalAssembly_id: 745,
									},
									{
										id: 4204,
										name: "GITARAGA",
										generalAssembly_id: 745,
									},
									{
										id: 4205,
										name: "KABASAZA",
										generalAssembly_id: 745,
									},
									{
										id: 4206,
										name: "MUSEBEYA",
										generalAssembly_id: 745,
									},
									{
										id: 9720,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 745,
									},
									{
										id: 11253,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 745,
									},
								],
							},
							{
								id: 746,
								name: "Rugurika",
								sector_id: 50311,
								courtOfCells: [
									{
										id: 4207,
										name: "BURIMA",
										generalAssembly_id: 746,
									},
									{
										id: 4208,
										name: "GACACA",
										generalAssembly_id: 746,
									},
									{
										id: 4209,
										name: "KIRINGA",
										generalAssembly_id: 746,
									},
									{
										id: 4210,
										name: "NGANZO",
										generalAssembly_id: 746,
									},
									{
										id: 4211,
										name: "NYAKIRIBA",
										generalAssembly_id: 746,
									},
									{
										id: 9721,
										name: "Rugurika Court of Appeal ",
										generalAssembly_id: 746,
									},
									{
										id: 11254,
										name: "Rugurika Gacaca court of sector ",
										generalAssembly_id: 746,
									},
								],
							},
						],
					},
					{
						id: 50312,
						name: "Nyange",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031205,
								name: "Ninda",
								sector_id: 50312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031204,
								name: "Muhabura",
								sector_id: 50312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031203,
								name: "Kamwumba",
								sector_id: 50312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031202,
								name: "Kabeza",
								sector_id: 50312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031201,
								name: "Cyivugiza",
								sector_id: 50312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 747,
								name: "Gasiza",
								sector_id: 50312,
								courtOfCells: [
									{
										id: 4212,
										name: "BUNYEMPOMBO",
										generalAssembly_id: 747,
									},
									{
										id: 4213,
										name: "GIKORO",
										generalAssembly_id: 747,
									},
									{
										id: 4214,
										name: "MUHABURA",
										generalAssembly_id: 747,
									},
									{
										id: 4215,
										name: "MURINZI",
										generalAssembly_id: 747,
									},
									{
										id: 4216,
										name: "RUSHARI",
										generalAssembly_id: 747,
									},
									{
										id: 4217,
										name: "RUVUMU",
										generalAssembly_id: 747,
									},
									{
										id: 9722,
										name: "Gasiza Court of Appeal ",
										generalAssembly_id: 747,
									},
									{
										id: 11255,
										name: "Gasiza Gacaca court of sector ",
										generalAssembly_id: 747,
									},
								],
							},
							{
								id: 748,
								name: "Gihora",
								sector_id: 50312,
								courtOfCells: [
									{
										id: 4218,
										name: "BUKEZI",
										generalAssembly_id: 748,
									},
									{
										id: 4219,
										name: "GASEKE",
										generalAssembly_id: 748,
									},
									{
										id: 4220,
										name: "KABARI",
										generalAssembly_id: 748,
									},
									{
										id: 4221,
										name: "KAMWUMBA",
										generalAssembly_id: 748,
									},
									{
										id: 4222,
										name: "KIROBA",
										generalAssembly_id: 748,
									},
									{
										id: 4223,
										name: "KISHALI",
										generalAssembly_id: 748,
									},
									{
										id: 4224,
										name: "MUGWATI",
										generalAssembly_id: 748,
									},
									{
										id: 4225,
										name: "MUSEKERA",
										generalAssembly_id: 748,
									},
									{
										id: 4226,
										name: "NYABITARE",
										generalAssembly_id: 748,
									},
									{
										id: 4227,
										name: "RUREBE",
										generalAssembly_id: 748,
									},
									{
										id: 9723,
										name: "Gihora Court of Appeal ",
										generalAssembly_id: 748,
									},
									{
										id: 11256,
										name: "Gihora Gacaca court of sector ",
										generalAssembly_id: 748,
									},
								],
							},
							{
								id: 749,
								name: "Kagano ",
								sector_id: 50312,
								courtOfCells: [
									{
										id: 4228,
										name: "KABARI",
										generalAssembly_id: 749,
									},
									{
										id: 4229,
										name: "KIVUMU",
										generalAssembly_id: 749,
									},
									{
										id: 4230,
										name: "MAHO",
										generalAssembly_id: 749,
									},
									{
										id: 4231,
										name: "NINDA",
										generalAssembly_id: 749,
									},
									{
										id: 4232,
										name: "NYAMIYAGA",
										generalAssembly_id: 749,
									},
									{
										id: 4233,
										name: "RUKARANKA",
										generalAssembly_id: 749,
									},
									{
										id: 9724,
										name: "Kagano  Court of Appeal ",
										generalAssembly_id: 749,
									},
									{
										id: 11257,
										name: "Kagano  Gacaca court of sector ",
										generalAssembly_id: 749,
									},
								],
							},
							{
								id: 750,
								name: "Nyange ",
								sector_id: 50312,
								courtOfCells: [
									{
										id: 4234,
										name: "GAHAMA",
										generalAssembly_id: 750,
									},
									{
										id: 4235,
										name: "KIBINGO",
										generalAssembly_id: 750,
									},
									{
										id: 4236,
										name: "KIRYAMO",
										generalAssembly_id: 750,
									},
									{
										id: 4237,
										name: "NTAMIZIRO",
										generalAssembly_id: 750,
									},
									{
										id: 4238,
										name: "NYARUBUYE",
										generalAssembly_id: 750,
									},
									{
										id: 9725,
										name: "Nyange  Court of Appeal ",
										generalAssembly_id: 750,
									},
									{
										id: 11258,
										name: "Nyange  Gacaca court of sector ",
										generalAssembly_id: 750,
									},
								],
							},
						],
					},
					{
						id: 50313,
						name: "Remera",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031305,
								name: "Rurambo",
								sector_id: 50313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031304,
								name: "Murwa",
								sector_id: 50313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031303,
								name: "Murandi",
								sector_id: 50313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031302,
								name: "Kamisave",
								sector_id: 50313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031301,
								name: "Gasongero",
								sector_id: 50313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 751,
								name: "Kiruri ",
								sector_id: 50313,
								courtOfCells: [
									{
										id: 4239,
										name: "BUKAZI",
										generalAssembly_id: 751,
									},
									{
										id: 4240,
										name: "GASEBEYA",
										generalAssembly_id: 751,
									},
									{
										id: 4241,
										name: "GISENGA",
										generalAssembly_id: 751,
									},
									{
										id: 4242,
										name: "KANYANSYO",
										generalAssembly_id: 751,
									},
									{
										id: 4243,
										name: "KARAMBI",
										generalAssembly_id: 751,
									},
									{
										id: 4244,
										name: "KIBANDA",
										generalAssembly_id: 751,
									},
									{
										id: 9726,
										name: "Kiruri  Court of Appeal ",
										generalAssembly_id: 751,
									},
									{
										id: 11259,
										name: "Kiruri  Gacaca court of sector ",
										generalAssembly_id: 751,
									},
								],
							},
							{
								id: 752,
								name: "Mukono",
								sector_id: 50313,
								courtOfCells: [
									{
										id: 4245,
										name: "GASHUNGA",
										generalAssembly_id: 752,
									},
									{
										id: 4246,
										name: "GATWA",
										generalAssembly_id: 752,
									},
									{
										id: 4247,
										name: "KABARA",
										generalAssembly_id: 752,
									},
									{
										id: 4248,
										name: "KANABA",
										generalAssembly_id: 752,
									},
									{
										id: 4249,
										name: "KANYANZIGE",
										generalAssembly_id: 752,
									},
									{
										id: 4250,
										name: "MUNANIRA",
										generalAssembly_id: 752,
									},
									{
										id: 9727,
										name: "Mukono Court of Appeal ",
										generalAssembly_id: 752,
									},
									{
										id: 11260,
										name: "Mukono Gacaca court of sector ",
										generalAssembly_id: 752,
									},
								],
							},
							{
								id: 753,
								name: "Remera",
								sector_id: 50313,
								courtOfCells: [
									{
										id: 4251,
										name: "BUMORI",
										generalAssembly_id: 753,
									},
									{
										id: 4252,
										name: "GACACA",
										generalAssembly_id: 753,
									},
									{
										id: 4253,
										name: "KARAMBO",
										generalAssembly_id: 753,
									},
									{
										id: 4254,
										name: "MUHORORO",
										generalAssembly_id: 753,
									},
									{
										id: 4255,
										name: "MURANDI",
										generalAssembly_id: 753,
									},
									{
										id: 4256,
										name: "SURE",
										generalAssembly_id: 753,
									},
									{
										id: 9728,
										name: "Remera Court of Appeal ",
										generalAssembly_id: 753,
									},
									{
										id: 11261,
										name: "Remera Gacaca court of sector ",
										generalAssembly_id: 753,
									},
								],
							},
							{
								id: 754,
								name: "Ruhinga ",
								sector_id: 50313,
								courtOfCells: [
									{
										id: 4257,
										name: "KAGOBE",
										generalAssembly_id: 754,
									},
									{
										id: 4258,
										name: "KIBUGABUGA",
										generalAssembly_id: 754,
									},
									{
										id: 4259,
										name: "RUKORE",
										generalAssembly_id: 754,
									},
									{
										id: 4260,
										name: "RUKUBANKANDA",
										generalAssembly_id: 754,
									},
									{
										id: 9729,
										name: "Ruhinga  Court of Appeal ",
										generalAssembly_id: 754,
									},
									{
										id: 11262,
										name: "Ruhinga  Gacaca court of sector ",
										generalAssembly_id: 754,
									},
								],
							},
							{
								id: 755,
								name: "Rusayo ",
								sector_id: 50313,
								courtOfCells: [
									{
										id: 4261,
										name: "BITSIBO",
										generalAssembly_id: 755,
									},
									{
										id: 4262,
										name: "GATARE",
										generalAssembly_id: 755,
									},
									{
										id: 4263,
										name: "KABUSOZO",
										generalAssembly_id: 755,
									},
									{
										id: 4264,
										name: "KAMISAVE",
										generalAssembly_id: 755,
									},
									{
										id: 4265,
										name: "MUKAKA",
										generalAssembly_id: 755,
									},
									{
										id: 4266,
										name: "TABA",
										generalAssembly_id: 755,
									},
									{
										id: 9730,
										name: "Rusayo  Court of Appeal ",
										generalAssembly_id: 755,
									},
									{
										id: 11263,
										name: "Rusayo  Gacaca court of sector ",
										generalAssembly_id: 755,
									},
								],
							},
						],
					},
					{
						id: 50314,
						name: "Rwaza",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031405,
								name: "Nyarubuye",
								sector_id: 50314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031404,
								name: "Nturo",
								sector_id: 50314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031403,
								name: "Musezero",
								sector_id: 50314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031402,
								name: "Kabushinge",
								sector_id: 50314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031401,
								name: "Bumara",
								sector_id: 50314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 756,
								name: "Kavumu",
								sector_id: 50314,
								courtOfCells: [
									{
										id: 4267,
										name: "GISORORA",
										generalAssembly_id: 756,
									},
									{
										id: 4268,
										name: "KABUYE",
										generalAssembly_id: 756,
									},
									{
										id: 4269,
										name: "KAVUMU",
										generalAssembly_id: 756,
									},
									{
										id: 4270,
										name: "MUHETA",
										generalAssembly_id: 756,
									},
									{
										id: 4271,
										name: "NYAKARAMBI",
										generalAssembly_id: 756,
									},
									{
										id: 9731,
										name: "Kavumu Court of Appeal ",
										generalAssembly_id: 756,
									},
									{
										id: 11264,
										name: "Kavumu Gacaca court of sector ",
										generalAssembly_id: 756,
									},
								],
							},
							{
								id: 757,
								name: "Kiganda",
								sector_id: 50314,
								courtOfCells: [
									{
										id: 4272,
										name: "KAMABUYE",
										generalAssembly_id: 757,
									},
									{
										id: 4273,
										name: "KIBINGO",
										generalAssembly_id: 757,
									},
									{
										id: 4274,
										name: "KIGANDA",
										generalAssembly_id: 757,
									},
									{
										id: 4275,
										name: "KIMPUNGU",
										generalAssembly_id: 757,
									},
									{
										id: 4276,
										name: "MATABA",
										generalAssembly_id: 757,
									},
									{
										id: 4277,
										name: "NYAKARAMBI",
										generalAssembly_id: 757,
									},
									{
										id: 4278,
										name: "NYARUBUYE",
										generalAssembly_id: 757,
									},
									{
										id: 9732,
										name: "Kiganda Court of Appeal ",
										generalAssembly_id: 757,
									},
									{
										id: 11265,
										name: "Kiganda Gacaca court of sector ",
										generalAssembly_id: 757,
									},
								],
							},
							{
								id: 758,
								name: "Rutare ",
								sector_id: 50314,
								courtOfCells: [
									{
										id: 4279,
										name: "MUGOGO",
										generalAssembly_id: 758,
									},
									{
										id: 4280,
										name: "RUBABI",
										generalAssembly_id: 758,
									},
									{
										id: 4281,
										name: "RUGALI",
										generalAssembly_id: 758,
									},
									{
										id: 4282,
										name: "RUGOGWE",
										generalAssembly_id: 758,
									},
									{
										id: 4283,
										name: "RUVUMU",
										generalAssembly_id: 758,
									},
									{
										id: 9733,
										name: "Rutare  Court of Appeal ",
										generalAssembly_id: 758,
									},
									{
										id: 11266,
										name: "Rutare  Gacaca court of sector ",
										generalAssembly_id: 758,
									},
								],
							},
							{
								id: 759,
								name: "Rwaza",
								sector_id: 50314,
								courtOfCells: [
									{
										id: 4284,
										name: "GIHANGO",
										generalAssembly_id: 759,
									},
									{
										id: 4285,
										name: "KABUGA",
										generalAssembly_id: 759,
									},
									{
										id: 4286,
										name: "MURAMBI",
										generalAssembly_id: 759,
									},
									{
										id: 4287,
										name: "NYARUGANDO",
										generalAssembly_id: 759,
									},
									{
										id: 4288,
										name: "RAMBA",
										generalAssembly_id: 759,
									},
									{
										id: 4289,
										name: "RWAZA",
										generalAssembly_id: 759,
									},
									{
										id: 9734,
										name: "Rwaza Court of Appeal ",
										generalAssembly_id: 759,
									},
									{
										id: 11267,
										name: "Rwaza Gacaca court of sector ",
										generalAssembly_id: 759,
									},
								],
							},
							{
								id: 760,
								name: "Ngege ",
								sector_id: 50314,
								courtOfCells: [
									{
										id: 4290,
										name: "BUHAMA",
										generalAssembly_id: 760,
									},
									{
										id: 4291,
										name: "BUKORO",
										generalAssembly_id: 760,
									},
									{
										id: 4292,
										name: "MURAMBI",
										generalAssembly_id: 760,
									},
									{
										id: 4293,
										name: "RUSAKI",
										generalAssembly_id: 760,
									},
									{
										id: 4294,
										name: "SAYO",
										generalAssembly_id: 760,
									},
									{
										id: 9735,
										name: "Ngege  Court of Appeal ",
										generalAssembly_id: 760,
									},
									{
										id: 11268,
										name: "Ngege  Gacaca court of sector ",
										generalAssembly_id: 760,
									},
								],
							},
						],
					},
					{
						id: 50315,
						name: "Shingiro",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5031504,
								name: "Mugari",
								sector_id: 50315,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031503,
								name: "Mudende",
								sector_id: 50315,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031502,
								name: "Kibuguzo",
								sector_id: 50315,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5031501,
								name: "Gakingo",
								sector_id: 50315,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 761,
								name: "Gikoro",
								sector_id: 50315,
								courtOfCells: [
									{
										id: 4295,
										name: "GIKORO I",
										generalAssembly_id: 761,
									},
									{
										id: 4296,
										name: "GIKORO II",
										generalAssembly_id: 761,
									},
									{
										id: 4297,
										name: "NYAKAYIRA",
										generalAssembly_id: 761,
									},
									{
										id: 4298,
										name: "RUTOMVU",
										generalAssembly_id: 761,
									},
									{
										id: 9736,
										name: "Gikoro Court of Appeal ",
										generalAssembly_id: 761,
									},
									{
										id: 11269,
										name: "Gikoro Gacaca court of sector ",
										generalAssembly_id: 761,
									},
								],
							},
							{
								id: 762,
								name: "Muhigo",
								sector_id: 50315,
								courtOfCells: [
									{
										id: 4299,
										name: "KAGESHI",
										generalAssembly_id: 762,
									},
									{
										id: 4300,
										name: "MUCACA",
										generalAssembly_id: 762,
									},
									{
										id: 4301,
										name: "MUHINGO",
										generalAssembly_id: 762,
									},
									{
										id: 4302,
										name: "NYARUBARA",
										generalAssembly_id: 762,
									},
									{
										id: 4303,
										name: "NYARUHANGA",
										generalAssembly_id: 762,
									},
									{
										id: 4304,
										name: "NYIRANKONA",
										generalAssembly_id: 762,
									},
									{
										id: 9737,
										name: "Muhigo Court of Appeal ",
										generalAssembly_id: 762,
									},
									{
										id: 11270,
										name: "Muhigo Gacaca court of sector ",
										generalAssembly_id: 762,
									},
								],
							},
							{
								id: 763,
								name: "Shingiro",
								sector_id: 50315,
								courtOfCells: [
									{
										id: 4305,
										name: "BUHIRA",
										generalAssembly_id: 763,
									},
									{
										id: 4306,
										name: "KIBWA",
										generalAssembly_id: 763,
									},
									{
										id: 4307,
										name: "KINEGE",
										generalAssembly_id: 763,
									},
									{
										id: 4308,
										name: "NYAMURIMIRWA",
										generalAssembly_id: 763,
									},
									{
										id: 4309,
										name: "RUHAMIRO",
										generalAssembly_id: 763,
									},
									{
										id: 4310,
										name: "RUKOMA",
										generalAssembly_id: 763,
									},
									{
										id: 4311,
										name: "RUTOVU",
										generalAssembly_id: 763,
									},
									{
										id: 4312,
										name: "RWAMAHORO",
										generalAssembly_id: 763,
									},
									{
										id: 4313,
										name: "SHINGIRO",
										generalAssembly_id: 763,
									},
									{
										id: 9738,
										name: "Shingiro Court of Appeal ",
										generalAssembly_id: 763,
									},
									{
										id: 11271,
										name: "Shingiro Gacaca court of sector ",
										generalAssembly_id: 763,
									},
								],
							},
						],
					},
					{
						id: 50301,
						name: "Busogo",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030104,
								name: "Sahara",
								sector_id: 50301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030103,
								name: "Nyagisozi",
								sector_id: 50301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030102,
								name: "Kavumu",
								sector_id: 50301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030101,
								name: "Gisesero",
								sector_id: 50301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [],
					},
					{
						id: 50303,
						name: "Gacaca",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030304,
								name: "Karwasa",
								sector_id: 50303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030303,
								name: "Kabirizi",
								sector_id: 50303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030302,
								name: "Gasakuza",
								sector_id: 50303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030301,
								name: "Gakoro",
								sector_id: 50303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [],
					},
					{
						id: 50302,
						name: "Cyuve",
						district_id: 503,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5030206,
								name: "Rwebeya",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030205,
								name: "Migeshi",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030204,
								name: "Kabeza",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030203,
								name: "Cyanya",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030202,
								name: "Buruba",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5030201,
								name: "Bukinanyana",
								sector_id: 50302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [],
					},
				],
			},
			{
				id: 501,
				name: "Rulindo",
				province_id: 5,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 50101,
						name: "BASE",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010103,
								name: "Rwamahwa",
								sector_id: 50101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010102,
								name: "Gitare",
								sector_id: 50101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010101,
								name: "Cyohoha",
								sector_id: 50101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1335,
								name: "Mugenda",
								sector_id: 50101,
								courtOfCells: [
									{
										id: 7710,
										name: "bushyiga",
										generalAssembly_id: 1335,
									},
									{
										id: 7711,
										name: "gatete",
										generalAssembly_id: 1335,
									},
									{
										id: 7712,
										name: "mugenda_i",
										generalAssembly_id: 1335,
									},
									{
										id: 7713,
										name: "mugenda_ii",
										generalAssembly_id: 1335,
									},
									{
										id: 7714,
										name: "nyamugari",
										generalAssembly_id: 1335,
									},
									{
										id: 10309,
										name: "Mugenda Court of Appeal ",
										generalAssembly_id: 1335,
									},
									{
										id: 11842,
										name: "Mugenda Gacaca court of sector ",
										generalAssembly_id: 1335,
									},
								],
							},
							{
								id: 1336,
								name: "mushongi",
								sector_id: 50101,
								courtOfCells: [
									{
										id: 7715,
										name: "buramba",
										generalAssembly_id: 1336,
									},
									{
										id: 7716,
										name: "gihanga",
										generalAssembly_id: 1336,
									},
									{
										id: 7717,
										name: "gihemba",
										generalAssembly_id: 1336,
									},
									{
										id: 7718,
										name: "musenyi",
										generalAssembly_id: 1336,
									},
									{
										id: 7719,
										name: "rubanda",
										generalAssembly_id: 1336,
									},
									{
										id: 10310,
										name: "mushongi Court of Appeal ",
										generalAssembly_id: 1336,
									},
									{
										id: 11843,
										name: "mushongi Gacaca court of sector ",
										generalAssembly_id: 1336,
									},
								],
							},
							{
								id: 1337,
								name: "muvumo",
								sector_id: 50101,
								courtOfCells: [
									{
										id: 7720,
										name: "gatete",
										generalAssembly_id: 1337,
									},
									{
										id: 7721,
										name: "gitovu",
										generalAssembly_id: 1337,
									},
									{
										id: 7722,
										name: "murambo",
										generalAssembly_id: 1337,
									},
									{
										id: 10311,
										name: "muvumo Court of Appeal ",
										generalAssembly_id: 1337,
									},
									{
										id: 11844,
										name: "muvumo Gacaca court of sector ",
										generalAssembly_id: 1337,
									},
								],
							},
						],
					},
					{
						id: 50102,
						name: "BUREGA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010203,
								name: "Taba",
								sector_id: 50102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010202,
								name: "Karengeri",
								sector_id: 50102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010201,
								name: "Butangampundu",
								sector_id: 50102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1338,
								name: "burega",
								sector_id: 50102,
								courtOfCells: [
									{
										id: 7723,
										name: "bugoboka",
										generalAssembly_id: 1338,
									},
									{
										id: 7724,
										name: "byerwa",
										generalAssembly_id: 1338,
									},
									{
										id: 7725,
										name: "gasare",
										generalAssembly_id: 1338,
									},
									{
										id: 7726,
										name: "gasharu",
										generalAssembly_id: 1338,
									},
									{
										id: 7727,
										name: "kanunga",
										generalAssembly_id: 1338,
									},
									{
										id: 7728,
										name: "karengeri",
										generalAssembly_id: 1338,
									},
									{
										id: 7729,
										name: "cyinzuzi",
										generalAssembly_id: 1338,
									},
									{
										id: 10312,
										name: "burega Court of Appeal ",
										generalAssembly_id: 1338,
									},
									{
										id: 11845,
										name: "burega Gacaca court of sector ",
										generalAssembly_id: 1338,
									},
								],
							},
							{
								id: 1339,
								name: "butangampundu",
								sector_id: 50102,
								courtOfCells: [
									{
										id: 7730,
										name: "butangampundu",
										generalAssembly_id: 1339,
									},
									{
										id: 7731,
										name: "gitenga",
										generalAssembly_id: 1339,
									},
									{
										id: 7732,
										name: "kerera",
										generalAssembly_id: 1339,
									},
									{
										id: 7733,
										name: "kisigiro",
										generalAssembly_id: 1339,
									},
									{
										id: 7734,
										name: "muhondo",
										generalAssembly_id: 1339,
									},
									{
										id: 7735,
										name: "nyakibande",
										generalAssembly_id: 1339,
									},
									{
										id: 10313,
										name: "butangampundu Court of Appeal ",
										generalAssembly_id: 1339,
									},
									{
										id: 11846,
										name: "butangampundu Gacaca court of sector ",
										generalAssembly_id: 1339,
									},
								],
							},
							{
								id: 1340,
								name: "taba",
								sector_id: 50102,
								courtOfCells: [
									{
										id: 7736,
										name: "cyinzuzi",
										generalAssembly_id: 1340,
									},
									{
										id: 7737,
										name: "kiboha",
										generalAssembly_id: 1340,
									},
									{
										id: 7738,
										name: "mwite",
										generalAssembly_id: 1340,
									},
									{
										id: 7739,
										name: "ngange",
										generalAssembly_id: 1340,
									},
									{
										id: 7740,
										name: "rusamanzuki",
										generalAssembly_id: 1340,
									},
									{
										id: 7741,
										name: "taba",
										generalAssembly_id: 1340,
									},
									{
										id: 10314,
										name: "taba Court of Appeal ",
										generalAssembly_id: 1340,
									},
									{
										id: 11847,
										name: "taba Gacaca court of sector ",
										generalAssembly_id: 1340,
									},
								],
							},
						],
					},
					{
						id: 50103,
						name: "BUSHOKI",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010305,
								name: "Nyirangarama",
								sector_id: 50103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010304,
								name: "Mukoto",
								sector_id: 50103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010303,
								name: "Kayenzi",
								sector_id: 50103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010302,
								name: "Giko",
								sector_id: 50103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010301,
								name: "Gasiza",
								sector_id: 50103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1341,
								name: "bugerera",
								sector_id: 50103,
								courtOfCells: [
									{
										id: 7742,
										name: "bugerera",
										generalAssembly_id: 1341,
									},
									{
										id: 7743,
										name: "rukoko",
										generalAssembly_id: 1341,
									},
									{
										id: 7744,
										name: "gitabu",
										generalAssembly_id: 1341,
									},
									{
										id: 7745,
										name: "gishyita",
										generalAssembly_id: 1341,
									},
									{
										id: 10315,
										name: "bugerera Court of Appeal ",
										generalAssembly_id: 1341,
									},
									{
										id: 11848,
										name: "bugerera Gacaca court of sector ",
										generalAssembly_id: 1341,
									},
								],
							},
							{
								id: 1342,
								name: "buramira",
								sector_id: 50103,
								courtOfCells: [
									{
										id: 7746,
										name: "buramira_i",
										generalAssembly_id: 1342,
									},
									{
										id: 7747,
										name: "buramira_ii",
										generalAssembly_id: 1342,
									},
									{
										id: 7748,
										name: "cyiri",
										generalAssembly_id: 1342,
									},
									{
										id: 7749,
										name: "kigamba",
										generalAssembly_id: 1342,
									},
									{
										id: 7750,
										name: "ngarama",
										generalAssembly_id: 1342,
									},
									{
										id: 7751,
										name: "rugote",
										generalAssembly_id: 1342,
									},
									{
										id: 10316,
										name: "buramira Court of Appeal ",
										generalAssembly_id: 1342,
									},
									{
										id: 11849,
										name: "buramira Gacaca court of sector ",
										generalAssembly_id: 1342,
									},
								],
							},
							{
								id: 1343,
								name: "Bushoki",
								sector_id: 50103,
								courtOfCells: [
									{
										id: 7752,
										name: "bushoki_i",
										generalAssembly_id: 1343,
									},
									{
										id: 7753,
										name: "bushoki_ii",
										generalAssembly_id: 1343,
									},
									{
										id: 7754,
										name: "gatare_i",
										generalAssembly_id: 1343,
									},
									{
										id: 7755,
										name: "gatare_ii",
										generalAssembly_id: 1343,
									},
									{
										id: 7756,
										name: "muko_i",
										generalAssembly_id: 1343,
									},
									{
										id: 7757,
										name: "muko_ii",
										generalAssembly_id: 1343,
									},
									{
										id: 7758,
										name: "muko_iii",
										generalAssembly_id: 1343,
									},
									{
										id: 10317,
										name: "Bushoki Court of Appeal ",
										generalAssembly_id: 1343,
									},
									{
										id: 11850,
										name: "Bushoki Gacaca court of sector ",
										generalAssembly_id: 1343,
									},
								],
							},
							{
								id: 1344,
								name: "remera",
								sector_id: 50103,
								courtOfCells: [
									{
										id: 7759,
										name: "budaha",
										generalAssembly_id: 1344,
									},
									{
										id: 7760,
										name: "buhande_i",
										generalAssembly_id: 1344,
									},
									{
										id: 7761,
										name: "buhande_ii",
										generalAssembly_id: 1344,
									},
									{
										id: 7762,
										name: "kaniga",
										generalAssembly_id: 1344,
									},
									{
										id: 7763,
										name: "remera",
										generalAssembly_id: 1344,
									},
									{
										id: 7764,
										name: "rulindo",
										generalAssembly_id: 1344,
									},
									{
										id: 10318,
										name: "remera Court of Appeal ",
										generalAssembly_id: 1344,
									},
									{
										id: 11851,
										name: "remera Gacaca court of sector ",
										generalAssembly_id: 1344,
									},
								],
							},
							{
								id: 1345,
								name: "tare",
								sector_id: 50103,
								courtOfCells: [
									{
										id: 7765,
										name: "bubiro",
										generalAssembly_id: 1345,
									},
									{
										id: 7766,
										name: "gifuba",
										generalAssembly_id: 1345,
									},
									{
										id: 7767,
										name: "remera",
										generalAssembly_id: 1345,
									},
									{
										id: 7768,
										name: "tare_i",
										generalAssembly_id: 1345,
									},
									{
										id: 7769,
										name: "tare_ii",
										generalAssembly_id: 1345,
									},
									{
										id: 7770,
										name: "teranzara",
										generalAssembly_id: 1345,
									},
									{
										id: 10319,
										name: "tare Court of Appeal ",
										generalAssembly_id: 1345,
									},
									{
										id: 11852,
										name: "tare Gacaca court of sector ",
										generalAssembly_id: 1345,
									},
								],
							},
						],
					},
					{
						id: 50104,
						name: "BUYOGA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010407,
								name: "Ndarage",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010406,
								name: "Mwumba",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010405,
								name: "Karama",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010404,
								name: "Gitumba",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010403,
								name: "Gahororo",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010402,
								name: "Butare",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010401,
								name: "Busoro",
								sector_id: 50104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1346,
								name: "busoro",
								sector_id: 50104,
								courtOfCells: [
									{
										id: 7771,
										name: "buyoga",
										generalAssembly_id: 1346,
									},
									{
										id: 7772,
										name: "gaseke",
										generalAssembly_id: 1346,
									},
									{
										id: 7773,
										name: "gashana",
										generalAssembly_id: 1346,
									},
									{
										id: 7774,
										name: "murambo",
										generalAssembly_id: 1346,
									},
									{
										id: 7775,
										name: "mwumba",
										generalAssembly_id: 1346,
									},
									{
										id: 7776,
										name: "nkongi",
										generalAssembly_id: 1346,
									},
									{
										id: 7777,
										name: "rugarama",
										generalAssembly_id: 1346,
									},
									{
										id: 10320,
										name: "busoro Court of Appeal ",
										generalAssembly_id: 1346,
									},
									{
										id: 11853,
										name: "busoro Gacaca court of sector ",
										generalAssembly_id: 1346,
									},
								],
							},
							{
								id: 1347,
								name: "murambi",
								sector_id: 50104,
								courtOfCells: [
									{
										id: 7778,
										name: "butare",
										generalAssembly_id: 1347,
									},
									{
										id: 7779,
										name: "gikingo",
										generalAssembly_id: 1347,
									},
									{
										id: 7780,
										name: "kagozi",
										generalAssembly_id: 1347,
									},
									{
										id: 7781,
										name: "ndarage",
										generalAssembly_id: 1347,
									},
									{
										id: 7782,
										name: "nyundo",
										generalAssembly_id: 1347,
									},
									{
										id: 7783,
										name: "tabyuka",
										generalAssembly_id: 1347,
									},
									{
										id: 10321,
										name: "murambi Court of Appeal ",
										generalAssembly_id: 1347,
									},
									{
										id: 11854,
										name: "murambi Gacaca court of sector ",
										generalAssembly_id: 1347,
									},
								],
							},
							{
								id: 1348,
								name: "nyabisiga",
								sector_id: 50104,
								courtOfCells: [
									{
										id: 7784,
										name: "bunyana",
										generalAssembly_id: 1348,
									},
									{
										id: 7785,
										name: "gahororo",
										generalAssembly_id: 1348,
									},
									{
										id: 7786,
										name: "gatare",
										generalAssembly_id: 1348,
									},
									{
										id: 7787,
										name: "gitaba",
										generalAssembly_id: 1348,
									},
									{
										id: 7788,
										name: "gitabura",
										generalAssembly_id: 1348,
									},
									{
										id: 7789,
										name: "mutamasi",
										generalAssembly_id: 1348,
									},
									{
										id: 7790,
										name: "rugazi",
										generalAssembly_id: 1348,
									},
									{
										id: 10322,
										name: "nyabisiga Court of Appeal ",
										generalAssembly_id: 1348,
									},
									{
										id: 11855,
										name: "nyabisiga Gacaca court of sector ",
										generalAssembly_id: 1348,
									},
								],
							},
							{
								id: 1349,
								name: "karama_ii",
								sector_id: 50104,
								courtOfCells: [
									{
										id: 7791,
										name: "bukamba",
										generalAssembly_id: 1349,
									},
									{
										id: 7792,
										name: "kadendegeri",
										generalAssembly_id: 1349,
									},
									{
										id: 7793,
										name: "karama",
										generalAssembly_id: 1349,
									},
									{
										id: 7794,
										name: "runoga",
										generalAssembly_id: 1349,
									},
									{
										id: 10323,
										name: "karama_ii Court of Appeal ",
										generalAssembly_id: 1349,
									},
									{
										id: 11856,
										name: "karama_ii Gacaca court of sector ",
										generalAssembly_id: 1349,
									},
								],
							},
						],
					},
					{
						id: 50105,
						name: "CYINZUZI",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010503,
								name: "Rudogo",
								sector_id: 50105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010502,
								name: "Migendezo",
								sector_id: 50105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010501,
								name: "Budakiranya",
								sector_id: 50105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1350,
								name: "cyinzuzi",
								sector_id: 50105,
								courtOfCells: [
									{
										id: 7795,
										name: "gasekabuye",
										generalAssembly_id: 1350,
									},
									{
										id: 7796,
										name: "gaseke",
										generalAssembly_id: 1350,
									},
									{
										id: 7797,
										name: "gasizi",
										generalAssembly_id: 1350,
									},
									{
										id: 7798,
										name: "gihuke",
										generalAssembly_id: 1350,
									},
									{
										id: 7799,
										name: "kirambo",
										generalAssembly_id: 1350,
									},
									{
										id: 7800,
										name: "munoga",
										generalAssembly_id: 1350,
									},
									{
										id: 7801,
										name: "musenyi",
										generalAssembly_id: 1350,
									},
									{
										id: 7802,
										name: "rudogo",
										generalAssembly_id: 1350,
									},
									{
										id: 10324,
										name: "cyinzuzi Court of Appeal ",
										generalAssembly_id: 1350,
									},
									{
										id: 11857,
										name: "cyinzuzi Gacaca court of sector ",
										generalAssembly_id: 1350,
									},
								],
							},
							{
								id: 1351,
								name: "rusagara",
								sector_id: 50105,
								courtOfCells: [
									{
										id: 7803,
										name: "cyanya",
										generalAssembly_id: 1351,
									},
									{
										id: 7804,
										name: "gitabage",
										generalAssembly_id: 1351,
									},
									{
										id: 7805,
										name: "karambo",
										generalAssembly_id: 1351,
									},
									{
										id: 7806,
										name: "migendezo",
										generalAssembly_id: 1351,
									},
									{
										id: 7807,
										name: "ngabitsinze",
										generalAssembly_id: 1351,
									},
									{
										id: 7808,
										name: "nyamugari",
										generalAssembly_id: 1351,
									},
									{
										id: 7809,
										name: "remera",
										generalAssembly_id: 1351,
									},
									{
										id: 7810,
										name: "rusagara",
										generalAssembly_id: 1351,
									},
									{
										id: 10325,
										name: "rusagara Court of Appeal ",
										generalAssembly_id: 1351,
									},
									{
										id: 11858,
										name: "rusagara Gacaca court of sector ",
										generalAssembly_id: 1351,
									},
								],
							},
							{
								id: 1352,
								name: "kanyoni",
								sector_id: 50105,
								courtOfCells: [
									{
										id: 7811,
										name: "gatagara",
										generalAssembly_id: 1352,
									},
									{
										id: 7812,
										name: "gihinga",
										generalAssembly_id: 1352,
									},
									{
										id: 7813,
										name: "kamatongo",
										generalAssembly_id: 1352,
									},
									{
										id: 7814,
										name: "kanyoni",
										generalAssembly_id: 1352,
									},
									{
										id: 7815,
										name: "kavumu",
										generalAssembly_id: 1352,
									},
									{
										id: 7816,
										name: "kigarama",
										generalAssembly_id: 1352,
									},
									{
										id: 7817,
										name: "nyakabanga",
										generalAssembly_id: 1352,
									},
									{
										id: 7818,
										name: "rugaragara",
										generalAssembly_id: 1352,
									},
									{
										id: 10326,
										name: "kanyoni Court of Appeal ",
										generalAssembly_id: 1352,
									},
									{
										id: 11859,
										name: "kanyoni Gacaca court of sector ",
										generalAssembly_id: 1352,
									},
								],
							},
						],
					},
					{
						id: 50106,
						name: "CYUNGO",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010603,
								name: "Rwili",
								sector_id: 50106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010602,
								name: "Marembo",
								sector_id: 50106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010601,
								name: "Burehe",
								sector_id: 50106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1353,
								name: "gitandi ",
								sector_id: 50106,
								courtOfCells: [
									{
										id: 7819,
										name: "gahinga",
										generalAssembly_id: 1353,
									},
									{
										id: 7820,
										name: "kanyereri",
										generalAssembly_id: 1353,
									},
									{
										id: 7821,
										name: "kibande",
										generalAssembly_id: 1353,
									},
									{
										id: 7822,
										name: "rugarama",
										generalAssembly_id: 1353,
									},
									{
										id: 10327,
										name: "gitandi  Court of Appeal ",
										generalAssembly_id: 1353,
									},
									{
										id: 11860,
										name: "gitandi  Gacaca court of sector ",
										generalAssembly_id: 1353,
									},
								],
							},
							{
								id: 1354,
								name: "gitare ",
								sector_id: 50106,
								courtOfCells: [
									{
										id: 7823,
										name: "bitagata",
										generalAssembly_id: 1354,
									},
									{
										id: 7824,
										name: "gaseke",
										generalAssembly_id: 1354,
									},
									{
										id: 7825,
										name: "kabanda",
										generalAssembly_id: 1354,
									},
									{
										id: 7826,
										name: "karambi",
										generalAssembly_id: 1354,
									},
									{
										id: 7827,
										name: "nyabisasa",
										generalAssembly_id: 1354,
									},
									{
										id: 10328,
										name: "gitare  Court of Appeal ",
										generalAssembly_id: 1354,
									},
									{
										id: 11861,
										name: "gitare  Gacaca court of sector ",
										generalAssembly_id: 1354,
									},
								],
							},
							{
								id: 1355,
								name: "gicuba ",
								sector_id: 50106,
								courtOfCells: [
									{
										id: 7828,
										name: "buyaga",
										generalAssembly_id: 1355,
									},
									{
										id: 7829,
										name: "kidomo",
										generalAssembly_id: 1355,
									},
									{
										id: 7830,
										name: "nganzo",
										generalAssembly_id: 1355,
									},
									{
										id: 10329,
										name: "gicuba  Court of Appeal ",
										generalAssembly_id: 1355,
									},
									{
										id: 11862,
										name: "gicuba  Gacaca court of sector ",
										generalAssembly_id: 1355,
									},
								],
							},
						],
					},
					{
						id: 50107,
						name: "KINIHIRA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010704,
								name: "Rebero",
								sector_id: 50107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010703,
								name: "Marembo",
								sector_id: 50107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010702,
								name: "Karegamazi",
								sector_id: 50107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010701,
								name: "Butunzi",
								sector_id: 50107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1356,
								name: "birayi",
								sector_id: 50107,
								courtOfCells: [
									{
										id: 7831,
										name: "butare",
										generalAssembly_id: 1356,
									},
									{
										id: 7832,
										name: "kirwa",
										generalAssembly_id: 1356,
									},
									{
										id: 7833,
										name: "mayumba",
										generalAssembly_id: 1356,
									},
									{
										id: 10330,
										name: "birayi Court of Appeal ",
										generalAssembly_id: 1356,
									},
									{
										id: 11863,
										name: "birayi Gacaca court of sector ",
										generalAssembly_id: 1356,
									},
								],
							},
							{
								id: 1357,
								name: "kimiryi",
								sector_id: 50107,
								courtOfCells: [
									{
										id: 7834,
										name: "buhita",
										generalAssembly_id: 1357,
									},
									{
										id: 7835,
										name: "bwishya",
										generalAssembly_id: 1357,
									},
									{
										id: 7836,
										name: "magezi",
										generalAssembly_id: 1357,
									},
									{
										id: 7837,
										name: "mpinda",
										generalAssembly_id: 1357,
									},
									{
										id: 7838,
										name: "museke",
										generalAssembly_id: 1357,
									},
									{
										id: 7839,
										name: "ntunguru",
										generalAssembly_id: 1357,
									},
									{
										id: 10331,
										name: "kimiryi Court of Appeal ",
										generalAssembly_id: 1357,
									},
									{
										id: 11864,
										name: "kimiryi Gacaca court of sector ",
										generalAssembly_id: 1357,
									},
								],
							},
							{
								id: 1358,
								name: "kinihira",
								sector_id: 50107,
								courtOfCells: [
									{
										id: 7840,
										name: "bunahi",
										generalAssembly_id: 1358,
									},
									{
										id: 7841,
										name: "gisekuru",
										generalAssembly_id: 1358,
									},
									{
										id: 7842,
										name: "ndorandi",
										generalAssembly_id: 1358,
									},
									{
										id: 7843,
										name: "gahinga",
										generalAssembly_id: 1358,
									},
									{
										id: 10332,
										name: "kinihira Court of Appeal ",
										generalAssembly_id: 1358,
									},
									{
										id: 11865,
										name: "kinihira Gacaca court of sector ",
										generalAssembly_id: 1358,
									},
								],
							},
							{
								id: 1359,
								name: "karama_i",
								sector_id: 50107,
								courtOfCells: [
									{
										id: 7844,
										name: "buhunde",
										generalAssembly_id: 1359,
									},
									{
										id: 7845,
										name: "cyogo",
										generalAssembly_id: 1359,
									},
									{
										id: 7846,
										name: "kigali",
										generalAssembly_id: 1359,
									},
									{
										id: 7847,
										name: "kiyebe",
										generalAssembly_id: 1359,
									},
									{
										id: 7848,
										name: "muvumo",
										generalAssembly_id: 1359,
									},
									{
										id: 10333,
										name: "karama_i Court of Appeal ",
										generalAssembly_id: 1359,
									},
									{
										id: 11866,
										name: "karama_i Gacaca court of sector ",
										generalAssembly_id: 1359,
									},
								],
							},
						],
					},
					{
						id: 50108,
						name: "KISARO",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010806,
								name: "Sayo",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010805,
								name: "Murama",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010804,
								name: "Mubuga",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010803,
								name: "Kigarama",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010802,
								name: "Kamushenyi",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010801,
								name: "Gitatsa",
								sector_id: 50108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1360,
								name: "burenga ",
								sector_id: 50108,
								courtOfCells: [
									{
										id: 7849,
										name: "burorandi",
										generalAssembly_id: 1360,
									},
									{
										id: 7850,
										name: "gitatsa",
										generalAssembly_id: 1360,
									},
									{
										id: 7851,
										name: "karambo",
										generalAssembly_id: 1360,
									},
									{
										id: 7852,
										name: "kibanda",
										generalAssembly_id: 1360,
									},
									{
										id: 7853,
										name: "murambi",
										generalAssembly_id: 1360,
									},
									{
										id: 7854,
										name: "rutabo",
										generalAssembly_id: 1360,
									},
									{
										id: 7855,
										name: "sayo",
										generalAssembly_id: 1360,
									},
									{
										id: 10334,
										name: "burenga  Court of Appeal ",
										generalAssembly_id: 1360,
									},
									{
										id: 11867,
										name: "burenga  Gacaca court of sector ",
										generalAssembly_id: 1360,
									},
								],
							},
							{
								id: 1361,
								name: "muranzi ",
								sector_id: 50108,
								courtOfCells: [
									{
										id: 7856,
										name: "buramba",
										generalAssembly_id: 1361,
									},
									{
										id: 7857,
										name: "gaseke",
										generalAssembly_id: 1361,
									},
									{
										id: 7858,
										name: "kagandu",
										generalAssembly_id: 1361,
									},
									{
										id: 7859,
										name: "kigarama",
										generalAssembly_id: 1361,
									},
									{
										id: 7860,
										name: "mpamo",
										generalAssembly_id: 1361,
									},
									{
										id: 7861,
										name: "murama",
										generalAssembly_id: 1361,
									},
									{
										id: 7862,
										name: "rwintare",
										generalAssembly_id: 1361,
									},
									{
										id: 10335,
										name: "muranzi  Court of Appeal ",
										generalAssembly_id: 1361,
									},
									{
										id: 11868,
										name: "muranzi  Gacaca court of sector ",
										generalAssembly_id: 1361,
									},
								],
							},
							{
								id: 1362,
								name: "rubona",
								sector_id: 50108,
								courtOfCells: [
									{
										id: 7863,
										name: "cyinyo",
										generalAssembly_id: 1362,
									},
									{
										id: 7864,
										name: "kamushenyi",
										generalAssembly_id: 1362,
									},
									{
										id: 7865,
										name: "mataba",
										generalAssembly_id: 1362,
									},
									{
										id: 7866,
										name: "muhihi",
										generalAssembly_id: 1362,
									},
									{
										id: 10336,
										name: "rubona Court of Appeal ",
										generalAssembly_id: 1362,
									},
									{
										id: 11869,
										name: "rubona Gacaca court of sector ",
										generalAssembly_id: 1362,
									},
								],
							},
						],
					},
					{
						id: 50109,
						name: "MASORO",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5010905,
								name: "Shengampuli",
								sector_id: 50109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010904,
								name: "Nyamyumba",
								sector_id: 50109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010903,
								name: "Kivugiza",
								sector_id: 50109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010902,
								name: "Kigarama",
								sector_id: 50109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5010901,
								name: "Kabuga",
								sector_id: 50109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1363,
								name: "kirwa",
								sector_id: 50109,
								courtOfCells: [
									{
										id: 7867,
										name: "bitsibo",
										generalAssembly_id: 1363,
									},
									{
										id: 7868,
										name: "gahama",
										generalAssembly_id: 1363,
									},
									{
										id: 7869,
										name: "gasambya",
										generalAssembly_id: 1363,
									},
									{
										id: 7870,
										name: "gasenga",
										generalAssembly_id: 1363,
									},
									{
										id: 7871,
										name: "gatobotobo",
										generalAssembly_id: 1363,
									},
									{
										id: 7872,
										name: "gisiza",
										generalAssembly_id: 1363,
									},
									{
										id: 7873,
										name: "kabuga",
										generalAssembly_id: 1363,
									},
									{
										id: 7874,
										name: "kivugiza",
										generalAssembly_id: 1363,
									},
									{
										id: 7875,
										name: "murehe",
										generalAssembly_id: 1363,
									},
									{
										id: 7876,
										name: "ngango",
										generalAssembly_id: 1363,
									},
									{
										id: 7877,
										name: "nyakibande",
										generalAssembly_id: 1363,
									},
									{
										id: 7878,
										name: "nyamikingo",
										generalAssembly_id: 1363,
									},
									{
										id: 10337,
										name: "kirwa Court of Appeal ",
										generalAssembly_id: 1363,
									},
									{
										id: 11870,
										name: "kirwa Gacaca court of sector ",
										generalAssembly_id: 1363,
									},
								],
							},
							{
								id: 1364,
								name: "masoro",
								sector_id: 50109,
								courtOfCells: [
									{
										id: 7879,
										name: "bajunjuguzi",
										generalAssembly_id: 1364,
									},
									{
										id: 7880,
										name: "barihi",
										generalAssembly_id: 1364,
									},
									{
										id: 7881,
										name: "bweranyange",
										generalAssembly_id: 1364,
									},
									{
										id: 7882,
										name: "giharabuge",
										generalAssembly_id: 1364,
									},
									{
										id: 7883,
										name: "kabuga",
										generalAssembly_id: 1364,
									},
									{
										id: 7884,
										name: "kigarama",
										generalAssembly_id: 1364,
									},
									{
										id: 7885,
										name: "kinteko",
										generalAssembly_id: 1364,
									},
									{
										id: 7886,
										name: "marenge",
										generalAssembly_id: 1364,
									},
									{
										id: 7887,
										name: "nyagasozi",
										generalAssembly_id: 1364,
									},
									{
										id: 7888,
										name: "nyamyumba",
										generalAssembly_id: 1364,
									},
									{
										id: 7889,
										name: "shengampuri",
										generalAssembly_id: 1364,
									},
									{
										id: 10338,
										name: "masoro Court of Appeal ",
										generalAssembly_id: 1364,
									},
									{
										id: 11871,
										name: "masoro Gacaca court of sector ",
										generalAssembly_id: 1364,
									},
								],
							},
						],
					},
					{
						id: 50110,
						name: "MBOGO",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011004,
								name: "Rurenge",
								sector_id: 50110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011003,
								name: "Ngiramazi",
								sector_id: 50110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011002,
								name: "Mushari",
								sector_id: 50110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011001,
								name: "Bukoro",
								sector_id: 50110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1365,
								name: "gisha",
								sector_id: 50110,
								courtOfCells: [
									{
										id: 7890,
										name: "gikombe",
										generalAssembly_id: 1365,
									},
									{
										id: 7891,
										name: "gisha",
										generalAssembly_id: 1365,
									},
									{
										id: 7892,
										name: "nyakabembe",
										generalAssembly_id: 1365,
									},
									{
										id: 10339,
										name: "gisha Court of Appeal ",
										generalAssembly_id: 1365,
									},
									{
										id: 11872,
										name: "gisha Gacaca court of sector ",
										generalAssembly_id: 1365,
									},
								],
							},
							{
								id: 1366,
								name: "binaga",
								sector_id: 50110,
								courtOfCells: [
									{
										id: 7893,
										name: "bukongi",
										generalAssembly_id: 1366,
									},
									{
										id: 7894,
										name: "buyanja",
										generalAssembly_id: 1366,
									},
									{
										id: 7895,
										name: "gitaba",
										generalAssembly_id: 1366,
									},
									{
										id: 7896,
										name: "mushali",
										generalAssembly_id: 1366,
									},
									{
										id: 7897,
										name: "nkurura-rwambogo",
										generalAssembly_id: 1366,
									},
									{
										id: 7898,
										name: "nyakabuye",
										generalAssembly_id: 1366,
									},
									{
										id: 10340,
										name: "binaga Court of Appeal ",
										generalAssembly_id: 1366,
									},
									{
										id: 11873,
										name: "binaga Gacaca court of sector ",
										generalAssembly_id: 1366,
									},
								],
							},
							{
								id: 1367,
								name: "mbogo",
								sector_id: 50110,
								courtOfCells: [
									{
										id: 7899,
										name: "gakoma",
										generalAssembly_id: 1367,
									},
									{
										id: 7900,
										name: "gicumbi",
										generalAssembly_id: 1367,
									},
									{
										id: 7901,
										name: "gitaba",
										generalAssembly_id: 1367,
									},
									{
										id: 7902,
										name: "karehe",
										generalAssembly_id: 1367,
									},
									{
										id: 7903,
										name: "kibungo",
										generalAssembly_id: 1367,
									},
									{
										id: 7904,
										name: "muhora",
										generalAssembly_id: 1367,
									},
									{
										id: 7905,
										name: "ruhondo",
										generalAssembly_id: 1367,
									},
									{
										id: 7906,
										name: "rurenge",
										generalAssembly_id: 1367,
									},
									{
										id: 7907,
										name: "rutonde",
										generalAssembly_id: 1367,
									},
									{
										id: 10341,
										name: "mbogo Court of Appeal ",
										generalAssembly_id: 1367,
									},
									{
										id: 11874,
										name: "mbogo Gacaca court of sector ",
										generalAssembly_id: 1367,
									},
								],
							},
							{
								id: 1368,
								name: "ruhanya",
								sector_id: 50110,
								courtOfCells: [
									{
										id: 7908,
										name: "buhira",
										generalAssembly_id: 1368,
									},
									{
										id: 7909,
										name: "bukoro",
										generalAssembly_id: 1368,
									},
									{
										id: 7910,
										name: "gasama",
										generalAssembly_id: 1368,
									},
									{
										id: 7911,
										name: "gihonga",
										generalAssembly_id: 1368,
									},
									{
										id: 7912,
										name: "kalindi",
										generalAssembly_id: 1368,
									},
									{
										id: 7913,
										name: "kibamba",
										generalAssembly_id: 1368,
									},
									{
										id: 7914,
										name: "kibaya",
										generalAssembly_id: 1368,
									},
									{
										id: 7915,
										name: "ruhanya",
										generalAssembly_id: 1368,
									},
									{
										id: 7916,
										name: "rwambogo",
										generalAssembly_id: 1368,
									},
									{
										id: 10342,
										name: "ruhanya Court of Appeal ",
										generalAssembly_id: 1368,
									},
									{
										id: 11875,
										name: "ruhanya Gacaca court of sector ",
										generalAssembly_id: 1368,
									},
								],
							},
						],
					},
					{
						id: 50111,
						name: "MURAMBI",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011104,
								name: "Mvuzo",
								sector_id: 50111,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011103,
								name: "Mugambazi",
								sector_id: 50111,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011102,
								name: "Gatwa",
								sector_id: 50111,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011101,
								name: "Bubangu",
								sector_id: 50111,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1369,
								name: "muhororo",
								sector_id: 50111,
								courtOfCells: [
									{
										id: 7917,
										name: "birembo",
										generalAssembly_id: 1369,
									},
									{
										id: 7918,
										name: "bubangu",
										generalAssembly_id: 1369,
									},
									{
										id: 7919,
										name: "karambo",
										generalAssembly_id: 1369,
									},
									{
										id: 7920,
										name: "muhororo",
										generalAssembly_id: 1369,
									},
									{
										id: 7921,
										name: "nyabirege",
										generalAssembly_id: 1369,
									},
									{
										id: 7922,
										name: "nyagisozi",
										generalAssembly_id: 1369,
									},
									{
										id: 7923,
										name: "nyanza",
										generalAssembly_id: 1369,
									},
									{
										id: 10343,
										name: "muhororo Court of Appeal ",
										generalAssembly_id: 1369,
									},
									{
										id: 11876,
										name: "muhororo Gacaca court of sector ",
										generalAssembly_id: 1369,
									},
								],
							},
							{
								id: 1370,
								name: "murambi",
								sector_id: 50111,
								courtOfCells: [
									{
										id: 7924,
										name: "gapfunsi",
										generalAssembly_id: 1370,
									},
									{
										id: 7925,
										name: "gasambya",
										generalAssembly_id: 1370,
									},
									{
										id: 7926,
										name: "gashinge",
										generalAssembly_id: 1370,
									},
									{
										id: 7927,
										name: "gatwa",
										generalAssembly_id: 1370,
									},
									{
										id: 7928,
										name: "gishenyi",
										generalAssembly_id: 1370,
									},
									{
										id: 7929,
										name: "gisiza",
										generalAssembly_id: 1370,
									},
									{
										id: 7930,
										name: "mugambazi",
										generalAssembly_id: 1370,
									},
									{
										id: 7931,
										name: "mutagata",
										generalAssembly_id: 1370,
									},
									{
										id: 7932,
										name: "ngango",
										generalAssembly_id: 1370,
									},
									{
										id: 10344,
										name: "murambi Court of Appeal ",
										generalAssembly_id: 1370,
									},
									{
										id: 11877,
										name: "murambi Gacaca court of sector ",
										generalAssembly_id: 1370,
									},
								],
							},
							{
								id: 1371,
								name: "ntyaba",
								sector_id: 50111,
								courtOfCells: [
									{
										id: 7933,
										name: "gatare",
										generalAssembly_id: 1371,
									},
									{
										id: 7934,
										name: "gisanze",
										generalAssembly_id: 1371,
									},
									{
										id: 7935,
										name: "gitorerwa",
										generalAssembly_id: 1371,
									},
									{
										id: 7936,
										name: "kiyagara",
										generalAssembly_id: 1371,
									},
									{
										id: 7937,
										name: "mvuzo",
										generalAssembly_id: 1371,
									},
									{
										id: 7938,
										name: "nyakibande",
										generalAssembly_id: 1371,
									},
									{
										id: 7939,
										name: "nyiragapfunsi",
										generalAssembly_id: 1371,
									},
									{
										id: 7940,
										name: "rwitema",
										generalAssembly_id: 1371,
									},
									{
										id: 10345,
										name: "ntyaba Court of Appeal ",
										generalAssembly_id: 1371,
									},
									{
										id: 11878,
										name: "ntyaba Gacaca court of sector ",
										generalAssembly_id: 1371,
									},
								],
							},
						],
					},
					{
						id: 50112,
						name: "NGOMA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011204,
								name: "Munyarwanda",
								sector_id: 50112,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011203,
								name: "Mugote",
								sector_id: 50112,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011202,
								name: "Karambo",
								sector_id: 50112,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011201,
								name: "Kabuga",
								sector_id: 50112,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1372,
								name: "museke",
								sector_id: 50112,
								courtOfCells: [
									{
										id: 7941,
										name: "cyabasigi",
										generalAssembly_id: 1372,
									},
									{
										id: 7942,
										name: "gaseke",
										generalAssembly_id: 1372,
									},
									{
										id: 7943,
										name: "mwishya",
										generalAssembly_id: 1372,
									},
									{
										id: 7944,
										name: "nyaruvumu",
										generalAssembly_id: 1372,
									},
									{
										id: 7945,
										name: "riryi",
										generalAssembly_id: 1372,
									},
									{
										id: 10346,
										name: "museke Court of Appeal ",
										generalAssembly_id: 1372,
									},
									{
										id: 11879,
										name: "museke Gacaca court of sector ",
										generalAssembly_id: 1372,
									},
								],
							},
							{
								id: 1373,
								name: "ngoma",
								sector_id: 50112,
								courtOfCells: [
									{
										id: 7946,
										name: "busizi",
										generalAssembly_id: 1373,
									},
									{
										id: 7947,
										name: "kiboha",
										generalAssembly_id: 1373,
									},
									{
										id: 7948,
										name: "kirungu",
										generalAssembly_id: 1373,
									},
									{
										id: 7949,
										name: "muyange",
										generalAssembly_id: 1373,
									},
									{
										id: 7950,
										name: "ngaru",
										generalAssembly_id: 1373,
									},
									{
										id: 7951,
										name: "rukoma",
										generalAssembly_id: 1373,
									},
									{
										id: 7952,
										name: "rushubi",
										generalAssembly_id: 1373,
									},
									{
										id: 7953,
										name: "sakara",
										generalAssembly_id: 1373,
									},
									{
										id: 10347,
										name: "ngoma Court of Appeal ",
										generalAssembly_id: 1373,
									},
									{
										id: 11880,
										name: "ngoma Gacaca court of sector ",
										generalAssembly_id: 1373,
									},
								],
							},
							{
								id: 1374,
								name: "nyabuko",
								sector_id: 50112,
								courtOfCells: [
									{
										id: 7954,
										name: "gatete",
										generalAssembly_id: 1374,
									},
									{
										id: 7955,
										name: "kagwa",
										generalAssembly_id: 1374,
									},
									{
										id: 7956,
										name: "kareshya",
										generalAssembly_id: 1374,
									},
									{
										id: 7957,
										name: "kayebe",
										generalAssembly_id: 1374,
									},
									{
										id: 7958,
										name: "kirambo",
										generalAssembly_id: 1374,
									},
									{
										id: 7959,
										name: "kiruli",
										generalAssembly_id: 1374,
									},
									{
										id: 7960,
										name: "masetsa",
										generalAssembly_id: 1374,
									},
									{
										id: 7961,
										name: "mushike",
										generalAssembly_id: 1374,
									},
									{
										id: 7962,
										name: "nyabuko",
										generalAssembly_id: 1374,
									},
									{
										id: 7963,
										name: "rubona",
										generalAssembly_id: 1374,
									},
									{
										id: 10348,
										name: "nyabuko Court of Appeal ",
										generalAssembly_id: 1374,
									},
									{
										id: 11881,
										name: "nyabuko Gacaca court of sector ",
										generalAssembly_id: 1374,
									},
								],
							},
						],
					},
					{
						id: 50113,
						name: "NTARABANA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011303,
								name: "Mahaza",
								sector_id: 50113,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011302,
								name: "Kiyanza",
								sector_id: 50113,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011301,
								name: "Kajevuba",
								sector_id: 50113,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1375,
								name: "kiyanza",
								sector_id: 50113,
								courtOfCells: [
									{
										id: 7964,
										name: "cyiri",
										generalAssembly_id: 1375,
									},
									{
										id: 7965,
										name: "kabilizi",
										generalAssembly_id: 1375,
									},
									{
										id: 7966,
										name: "kayenzi",
										generalAssembly_id: 1375,
									},
									{
										id: 7967,
										name: "kirimbi",
										generalAssembly_id: 1375,
									},
									{
										id: 7968,
										name: "kivubwe",
										generalAssembly_id: 1375,
									},
									{
										id: 7969,
										name: "kiyanza_i",
										generalAssembly_id: 1375,
									},
									{
										id: 7970,
										name: "kiyanza_ii",
										generalAssembly_id: 1375,
									},
									{
										id: 7971,
										name: "nyagisozi",
										generalAssembly_id: 1375,
									},
									{
										id: 10349,
										name: "kiyanza Court of Appeal ",
										generalAssembly_id: 1375,
									},
									{
										id: 11882,
										name: "kiyanza Gacaca court of sector ",
										generalAssembly_id: 1375,
									},
								],
							},
							{
								id: 1376,
								name: "Ntarabana",
								sector_id: 50113,
								courtOfCells: [
									{
										id: 7972,
										name: "karera",
										generalAssembly_id: 1376,
									},
									{
										id: 7973,
										name: "mahaza",
										generalAssembly_id: 1376,
									},
									{
										id: 7974,
										name: "mubanga",
										generalAssembly_id: 1376,
									},
									{
										id: 7975,
										name: "nyarusange",
										generalAssembly_id: 1376,
									},
									{
										id: 7976,
										name: "rwampaka",
										generalAssembly_id: 1376,
									},
									{
										id: 10350,
										name: "Ntarabana Court of Appeal ",
										generalAssembly_id: 1376,
									},
									{
										id: 11883,
										name: "Ntarabana Gacaca court of sector ",
										generalAssembly_id: 1376,
									},
								],
							},
							{
								id: 1377,
								name: "rusasa",
								sector_id: 50113,
								courtOfCells: [
									{
										id: 7977,
										name: "kazi",
										generalAssembly_id: 1377,
									},
									{
										id: 7978,
										name: "ndimiro",
										generalAssembly_id: 1377,
									},
									{
										id: 7979,
										name: "nyarubuye",
										generalAssembly_id: 1377,
									},
									{
										id: 7980,
										name: "rugumira",
										generalAssembly_id: 1377,
									},
									{
										id: 7981,
										name: "rukore",
										generalAssembly_id: 1377,
									},
									{
										id: 7982,
										name: "busoro",
										generalAssembly_id: 1377,
									},
									{
										id: 7983,
										name: "bikamba",
										generalAssembly_id: 1377,
									},
									{
										id: 7984,
										name: "umurema",
										generalAssembly_id: 1377,
									},
									{
										id: 10351,
										name: "rusasa Court of Appeal ",
										generalAssembly_id: 1377,
									},
									{
										id: 11884,
										name: "rusasa Gacaca court of sector ",
										generalAssembly_id: 1377,
									},
								],
							},
						],
					},
					{
						id: 50114,
						name: "RUKOZO",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011404,
								name: "Mbuye",
								sector_id: 50114,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011403,
								name: "Mberuka",
								sector_id: 50114,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011402,
								name: "Bwimo",
								sector_id: 50114,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011401,
								name: "Buraro",
								sector_id: 50114,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1378,
								name: "bwimo ",
								sector_id: 50114,
								courtOfCells: [
									{
										id: 7985,
										name: "busubizo",
										generalAssembly_id: 1378,
									},
									{
										id: 7986,
										name: "gisiza",
										generalAssembly_id: 1378,
									},
									{
										id: 7987,
										name: "kabera",
										generalAssembly_id: 1378,
									},
									{
										id: 7988,
										name: "kavumo",
										generalAssembly_id: 1378,
									},
									{
										id: 10352,
										name: "bwimo  Court of Appeal ",
										generalAssembly_id: 1378,
									},
									{
										id: 11885,
										name: "bwimo  Gacaca court of sector ",
										generalAssembly_id: 1378,
									},
								],
							},
							{
								id: 1379,
								name: "kabingo",
								sector_id: 50114,
								courtOfCells: [
									{
										id: 7989,
										name: "buhoro",
										generalAssembly_id: 1379,
									},
									{
										id: 7990,
										name: "gatorwa",
										generalAssembly_id: 1379,
									},
									{
										id: 7991,
										name: "mutara",
										generalAssembly_id: 1379,
									},
									{
										id: 7992,
										name: "mwana",
										generalAssembly_id: 1379,
									},
									{
										id: 7993,
										name: "nyakibande",
										generalAssembly_id: 1379,
									},
									{
										id: 10353,
										name: "kabingo Court of Appeal ",
										generalAssembly_id: 1379,
									},
									{
										id: 11886,
										name: "kabingo Gacaca court of sector ",
										generalAssembly_id: 1379,
									},
								],
							},
							{
								id: 1380,
								name: "rukozo ",
								sector_id: 50114,
								courtOfCells: [
									{
										id: 7994,
										name: "gakubo",
										generalAssembly_id: 1380,
									},
									{
										id: 7995,
										name: "kabari",
										generalAssembly_id: 1380,
									},
									{
										id: 7996,
										name: "mutungo",
										generalAssembly_id: 1380,
									},
									{
										id: 7997,
										name: "nyarurambi",
										generalAssembly_id: 1380,
									},
									{
										id: 7998,
										name: "ruhanga",
										generalAssembly_id: 1380,
									},
									{
										id: 10354,
										name: "rukozo  Court of Appeal ",
										generalAssembly_id: 1380,
									},
									{
										id: 11887,
										name: "rukozo  Gacaca court of sector ",
										generalAssembly_id: 1380,
									},
								],
							},
						],
					},
					{
						id: 50115,
						name: "RUSIGA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011503,
								name: "Taba",
								sector_id: 50115,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011502,
								name: "Kirenge",
								sector_id: 50115,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011501,
								name: "Gako",
								sector_id: 50115,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1381,
								name: "ntarabana",
								sector_id: 50115,
								courtOfCells: [
									{
										id: 7999,
										name: "buremeri",
										generalAssembly_id: 1381,
									},
									{
										id: 8000,
										name: "gisiza",
										generalAssembly_id: 1381,
									},
									{
										id: 8001,
										name: "ntarabana",
										generalAssembly_id: 1381,
									},
									{
										id: 8002,
										name: "ntonde",
										generalAssembly_id: 1381,
									},
									{
										id: 8003,
										name: "nyange",
										generalAssembly_id: 1381,
									},
									{
										id: 8004,
										name: "rukingu",
										generalAssembly_id: 1381,
									},
									{
										id: 10355,
										name: "ntarabana Court of Appeal ",
										generalAssembly_id: 1381,
									},
									{
										id: 11888,
										name: "ntarabana Gacaca court of sector ",
										generalAssembly_id: 1381,
									},
								],
							},
							{
								id: 1382,
								name: "gitanda",
								sector_id: 50115,
								courtOfCells: [
									{
										id: 8005,
										name: "bitare",
										generalAssembly_id: 1382,
									},
									{
										id: 8006,
										name: "butare_cyingazi",
										generalAssembly_id: 1382,
									},
									{
										id: 8007,
										name: "gahondo",
										generalAssembly_id: 1382,
									},
									{
										id: 8008,
										name: "karenge",
										generalAssembly_id: 1382,
									},
									{
										id: 8009,
										name: "nyakarama",
										generalAssembly_id: 1382,
									},
									{
										id: 8010,
										name: "taba",
										generalAssembly_id: 1382,
									},
									{
										id: 10356,
										name: "gitanda Court of Appeal ",
										generalAssembly_id: 1382,
									},
									{
										id: 11889,
										name: "gitanda Gacaca court of sector ",
										generalAssembly_id: 1382,
									},
								],
							},
							{
								id: 1383,
								name: "rusiga",
								sector_id: 50115,
								courtOfCells: [
									{
										id: 8011,
										name: "buseke",
										generalAssembly_id: 1383,
									},
									{
										id: 8012,
										name: "bwili",
										generalAssembly_id: 1383,
									},
									{
										id: 8013,
										name: "gasigati",
										generalAssembly_id: 1383,
									},
									{
										id: 8014,
										name: "gifumba",
										generalAssembly_id: 1383,
									},
									{
										id: 8015,
										name: "kabuye",
										generalAssembly_id: 1383,
									},
									{
										id: 8016,
										name: "mataba",
										generalAssembly_id: 1383,
									},
									{
										id: 8017,
										name: "mugera",
										generalAssembly_id: 1383,
									},
									{
										id: 8018,
										name: "ntakara",
										generalAssembly_id: 1383,
									},
									{
										id: 8019,
										name: "nkanga_kabunigu",
										generalAssembly_id: 1383,
									},
									{
										id: 8020,
										name: "nyakarama",
										generalAssembly_id: 1383,
									},
									{
										id: 8021,
										name: "ruvumba",
										generalAssembly_id: 1383,
									},
									{
										id: 10357,
										name: "rusiga Court of Appeal ",
										generalAssembly_id: 1383,
									},
									{
										id: 11890,
										name: "rusiga Gacaca court of sector ",
										generalAssembly_id: 1383,
									},
								],
							},
						],
					},
					{
						id: 50116,
						name: "SHYORONGI",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011605,
								name: "Rutonde",
								sector_id: 50116,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011604,
								name: "Rubona",
								sector_id: 50116,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011603,
								name: "Muvumu",
								sector_id: 50116,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011602,
								name: "Kijabagwe",
								sector_id: 50116,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011601,
								name: "Bugaragara",
								sector_id: 50116,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1384,
								name: "rutonde",
								sector_id: 50116,
								courtOfCells: [
									{
										id: 8022,
										name: "bugarura",
										generalAssembly_id: 1384,
									},
									{
										id: 8023,
										name: "gaseke",
										generalAssembly_id: 1384,
									},
									{
										id: 8024,
										name: "kabagabaga",
										generalAssembly_id: 1384,
									},
									{
										id: 8025,
										name: "kabakene",
										generalAssembly_id: 1384,
									},
									{
										id: 8026,
										name: "kijabagwe",
										generalAssembly_id: 1384,
									},
									{
										id: 8027,
										name: "mwagiro",
										generalAssembly_id: 1384,
									},
									{
										id: 8028,
										name: "ngendo",
										generalAssembly_id: 1384,
									},
									{
										id: 8029,
										name: "nyabisindu",
										generalAssembly_id: 1384,
									},
									{
										id: 8030,
										name: "nyamirembe",
										generalAssembly_id: 1384,
									},
									{
										id: 8031,
										name: "rugendabari",
										generalAssembly_id: 1384,
									},
									{
										id: 8032,
										name: "rutonde",
										generalAssembly_id: 1384,
									},
									{
										id: 8033,
										name: "rweya",
										generalAssembly_id: 1384,
									},
									{
										id: 10358,
										name: "rutonde Court of Appeal ",
										generalAssembly_id: 1384,
									},
									{
										id: 11891,
										name: "rutonde Gacaca court of sector ",
										generalAssembly_id: 1384,
									},
								],
							},
							{
								id: 1385,
								name: "rwahi",
								sector_id: 50116,
								courtOfCells: [
									{
										id: 8034,
										name: "bwimo",
										generalAssembly_id: 1385,
									},
									{
										id: 8035,
										name: "curabana",
										generalAssembly_id: 1385,
									},
									{
										id: 8036,
										name: "cyikera",
										generalAssembly_id: 1385,
									},
									{
										id: 8037,
										name: "gicuba",
										generalAssembly_id: 1385,
									},
									{
										id: 8038,
										name: "gishyita",
										generalAssembly_id: 1385,
									},
									{
										id: 8039,
										name: "gisoro",
										generalAssembly_id: 1385,
									},
									{
										id: 8040,
										name: "kagunda",
										generalAssembly_id: 1385,
									},
									{
										id: 8041,
										name: "karama",
										generalAssembly_id: 1385,
									},
									{
										id: 8042,
										name: "kavoma",
										generalAssembly_id: 1385,
									},
									{
										id: 8043,
										name: "kavumu",
										generalAssembly_id: 1385,
									},
									{
										id: 8044,
										name: "kigali",
										generalAssembly_id: 1385,
									},
									{
										id: 8045,
										name: "kirurumo",
										generalAssembly_id: 1385,
									},
									{
										id: 8046,
										name: "kivili",
										generalAssembly_id: 1385,
									},
									{
										id: 8047,
										name: "mukumba",
										generalAssembly_id: 1385,
									},
									{
										id: 8048,
										name: "muvumu",
										generalAssembly_id: 1385,
									},
									{
										id: 8049,
										name: "nyarunyinya",
										generalAssembly_id: 1385,
									},
									{
										id: 8050,
										name: "nyarusange",
										generalAssembly_id: 1385,
									},
									{
										id: 8051,
										name: "rubona",
										generalAssembly_id: 1385,
									},
									{
										id: 8052,
										name: "ruhanga",
										generalAssembly_id: 1385,
									},
									{
										id: 10359,
										name: "rwahi Court of Appeal ",
										generalAssembly_id: 1385,
									},
									{
										id: 11892,
										name: "rwahi Gacaca court of sector ",
										generalAssembly_id: 1385,
									},
								],
							},
							{
								id: 1386,
								name: "shyorongi",
								sector_id: 50116,
								courtOfCells: [
									{
										id: 8053,
										name: "bugaragara",
										generalAssembly_id: 1386,
									},
									{
										id: 8054,
										name: "gasyo",
										generalAssembly_id: 1386,
									},
									{
										id: 8055,
										name: "gisiza",
										generalAssembly_id: 1386,
									},
									{
										id: 8056,
										name: "kabaraza_kimanama",
										generalAssembly_id: 1386,
									},
									{
										id: 8057,
										name: "kagali",
										generalAssembly_id: 1386,
									},
									{
										id: 8058,
										name: "kigarama",
										generalAssembly_id: 1386,
									},
									{
										id: 8059,
										name: "kiziranyenzi",
										generalAssembly_id: 1386,
									},
									{
										id: 8060,
										name: "matanda",
										generalAssembly_id: 1386,
									},
									{
										id: 8061,
										name: "nyakaruri",
										generalAssembly_id: 1386,
									},
									{
										id: 8062,
										name: "nyarushinya",
										generalAssembly_id: 1386,
									},
									{
										id: 10360,
										name: "shyorongi Court of Appeal ",
										generalAssembly_id: 1386,
									},
									{
										id: 11893,
										name: "shyorongi Gacaca court of sector ",
										generalAssembly_id: 1386,
									},
								],
							},
						],
					},
					{
						id: 50117,
						name: "TUMBA",
						district_id: 501,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 5011705,
								name: "Taba",
								sector_id: 50117,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011704,
								name: "Nyirabirori",
								sector_id: 50117,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011703,
								name: "Misezero",
								sector_id: 50117,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011702,
								name: "Gahabwa",
								sector_id: 50117,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 5011701,
								name: "Barari",
								sector_id: 50117,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1387,
								name: "gihinga",
								sector_id: 50117,
								courtOfCells: [
									{
										id: 8063,
										name: "cyanya",
										generalAssembly_id: 1387,
									},
									{
										id: 8064,
										name: "gatoki",
										generalAssembly_id: 1387,
									},
									{
										id: 8065,
										name: "gihinga",
										generalAssembly_id: 1387,
									},
									{
										id: 8066,
										name: "kagora",
										generalAssembly_id: 1387,
									},
									{
										id: 8067,
										name: "vungu",
										generalAssembly_id: 1387,
									},
									{
										id: 10361,
										name: "gihinga Court of Appeal ",
										generalAssembly_id: 1387,
									},
									{
										id: 11894,
										name: "gihinga Gacaca court of sector ",
										generalAssembly_id: 1387,
									},
								],
							},
							{
								id: 1388,
								name: "murambi",
								sector_id: 50117,
								courtOfCells: [
									{
										id: 8068,
										name: "bukinga_i",
										generalAssembly_id: 1388,
									},
									{
										id: 8069,
										name: "bukinga_ii",
										generalAssembly_id: 1388,
									},
									{
										id: 8070,
										name: "murambi_i",
										generalAssembly_id: 1388,
									},
									{
										id: 8071,
										name: "murambi_ii",
										generalAssembly_id: 1388,
									},
									{
										id: 8072,
										name: "rusura_i",
										generalAssembly_id: 1388,
									},
									{
										id: 8073,
										name: "rusura_ii",
										generalAssembly_id: 1388,
									},
									{
										id: 10362,
										name: "murambi Court of Appeal ",
										generalAssembly_id: 1388,
									},
									{
										id: 11895,
										name: "murambi Gacaca court of sector ",
										generalAssembly_id: 1388,
									},
								],
							},
							{
								id: 1389,
								name: "rukore ",
								sector_id: 50117,
								courtOfCells: [
									{
										id: 8074,
										name: "burali",
										generalAssembly_id: 1389,
									},
									{
										id: 8075,
										name: "gaseke",
										generalAssembly_id: 1389,
									},
									{
										id: 8076,
										name: "kirambo",
										generalAssembly_id: 1389,
									},
									{
										id: 8077,
										name: "rukore",
										generalAssembly_id: 1389,
									},
									{
										id: 8078,
										name: "taba ",
										generalAssembly_id: 1389,
									},
									{
										id: 10363,
										name: "rukore  Court of Appeal ",
										generalAssembly_id: 1389,
									},
									{
										id: 11896,
										name: "rukore  Gacaca court of sector ",
										generalAssembly_id: 1389,
									},
								],
							},
							{
								id: 1390,
								name: "ruvumba",
								sector_id: 50117,
								courtOfCells: [
									{
										id: 8079,
										name: "kamuragi",
										generalAssembly_id: 1390,
									},
									{
										id: 8080,
										name: "mwili",
										generalAssembly_id: 1390,
									},
									{
										id: 8081,
										name: "rugarama",
										generalAssembly_id: 1390,
									},
									{
										id: 8082,
										name: "ruvumba",
										generalAssembly_id: 1390,
									},
									{
										id: 8083,
										name: "kanaba",
										generalAssembly_id: 1390,
									},
									{
										id: 10364,
										name: "ruvumba Court of Appeal ",
										generalAssembly_id: 1390,
									},
									{
										id: 11897,
										name: "ruvumba Gacaca court of sector ",
										generalAssembly_id: 1390,
									},
								],
							},
							{
								id: 1391,
								name: "tumba",
								sector_id: 50117,
								courtOfCells: [
									{
										id: 8084,
										name: "kanaba",
										generalAssembly_id: 1391,
									},
									{
										id: 8085,
										name: "nyamirembe",
										generalAssembly_id: 1391,
									},
									{
										id: 8086,
										name: "rurambo",
										generalAssembly_id: 1391,
									},
									{
										id: 8087,
										name: "tumba",
										generalAssembly_id: 1391,
									},
									{
										id: 10365,
										name: "tumba Court of Appeal ",
										generalAssembly_id: 1391,
									},
									{
										id: 11898,
										name: "tumba Gacaca court of sector ",
										generalAssembly_id: 1391,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		id: 3,
		name: "Kigali",
		createdAt: "2021-05-03T07:57:21.181Z",
		updatedAt: "2021-05-03T07:57:21.181Z",
		districts: [
			{
				id: 302,
				name: "Gasabo",
				province_id: 3,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 30201,
						name: "Bumbogo",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020107,
								name: "Nyagasozi",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020106,
								name: "Nyabikenke",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020105,
								name: "Nkuzuzu",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020104,
								name: "Ngara",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020103,
								name: "Mvuzo",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020102,
								name: "Musave",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020101,
								name: "Kinyaga",
								sector_id: 30201,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 173,
								name: "Bumbogo",
								sector_id: 30201,
								courtOfCells: [
									{
										id: 1130,
										name: "GISASA",
										generalAssembly_id: 173,
									},
									{
										id: 1131,
										name: "KIYORO",
										generalAssembly_id: 173,
									},
									{
										id: 1132,
										name: "MUNINI",
										generalAssembly_id: 173,
									},
									{
										id: 1133,
										name: "MURARAMBO",
										generalAssembly_id: 173,
									},
									{
										id: 1134,
										name: "MVUZO",
										generalAssembly_id: 173,
									},
									{
										id: 1135,
										name: "NGARA",
										generalAssembly_id: 173,
									},
									{
										id: 1136,
										name: "NKONA",
										generalAssembly_id: 173,
									},
									{
										id: 1137,
										name: "NYAKABINGO",
										generalAssembly_id: 173,
									},
									{
										id: 1138,
										name: "RUNYINYA",
										generalAssembly_id: 173,
									},
									{
										id: 1139,
										name: "UWARURAZA",
										generalAssembly_id: 173,
									},
									{
										id: 9148,
										name: "Bumbogo Court of Appeal ",
										generalAssembly_id: 173,
									},
									{
										id: 10681,
										name: "Bumbogo Gacaca court of sector ",
										generalAssembly_id: 173,
									},
								],
							},
							{
								id: 174,
								name: "Gishaka",
								sector_id: 30201,
								courtOfCells: [
									{
										id: 1140,
										name: "AKANYIRAMUGARURA",
										generalAssembly_id: 174,
									},
									{
										id: 1141,
										name: "AKASEDOGO",
										generalAssembly_id: 174,
									},
									{
										id: 1142,
										name: "AKIGABIRO",
										generalAssembly_id: 174,
									},
									{
										id: 1143,
										name: "AKIMPAMA",
										generalAssembly_id: 174,
									},
									{
										id: 1144,
										name: "BULIMA",
										generalAssembly_id: 174,
									},
									{
										id: 1145,
										name: "KABUYE",
										generalAssembly_id: 174,
									},
									{
										id: 1146,
										name: "KITYAZO",
										generalAssembly_id: 174,
									},
									{
										id: 1147,
										name: "MPABWA",
										generalAssembly_id: 174,
									},
									{
										id: 1148,
										name: "NKUZUZU",
										generalAssembly_id: 174,
									},
									{
										id: 1149,
										name: "NYAGASAMBU",
										generalAssembly_id: 174,
									},
									{
										id: 1150,
										name: "NYAGASOZI",
										generalAssembly_id: 174,
									},
									{
										id: 9149,
										name: "Gishaka Court of Appeal ",
										generalAssembly_id: 174,
									},
									{
										id: 10682,
										name: "Gishaka Gacaca court of sector ",
										generalAssembly_id: 174,
									},
								],
							},
							{
								id: 175,
								name: "Karama",
								sector_id: 30201,
								courtOfCells: [
									{
										id: 1151,
										name: "KIGARA",
										generalAssembly_id: 175,
									},
									{
										id: 1152,
										name: "MASIZI",
										generalAssembly_id: 175,
									},
									{
										id: 1153,
										name: "NYABIKENKE",
										generalAssembly_id: 175,
									},
									{
										id: 1154,
										name: "RYAKABAKA",
										generalAssembly_id: 175,
									},
									{
										id: 9150,
										name: "Karama Court of Appeal ",
										generalAssembly_id: 175,
									},
									{
										id: 10683,
										name: "Karama Gacaca court of sector ",
										generalAssembly_id: 175,
									},
								],
							},
							{
								id: 176,
								name: "Rubungo",
								sector_id: 30201,
								courtOfCells: [
									{
										id: 1155,
										name: "KAYUMBA",
										generalAssembly_id: 176,
									},
									{
										id: 1156,
										name: "KIGARAMA",
										generalAssembly_id: 176,
									},
									{
										id: 1157,
										name: "KINYAGA",
										generalAssembly_id: 176,
									},
									{
										id: 1158,
										name: "MUSAVE",
										generalAssembly_id: 176,
									},
									{
										id: 9151,
										name: "Rubungo Court of Appeal ",
										generalAssembly_id: 176,
									},
									{
										id: 10684,
										name: "Rubungo Gacaca court of sector ",
										generalAssembly_id: 176,
									},
								],
							},
						],
					},
					{
						id: 30202,
						name: "Gatsata",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020203,
								name: "Nyamugari",
								sector_id: 30202,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020202,
								name: "Nyamabuye ",
								sector_id: 30202,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020201,
								name: "Karuruma",
								sector_id: 30202,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 177,
								name: "Gatsata",
								sector_id: 30202,
								courtOfCells: [
									{
										id: 1159,
										name: "AKAMWUNGUZI",
										generalAssembly_id: 177,
									},
									{
										id: 1160,
										name: "NYAMABUYE",
										generalAssembly_id: 177,
									},
									{
										id: 1161,
										name: "NYAMUGALI",
										generalAssembly_id: 177,
									},
									{
										id: 1162,
										name: "RWINYANA",
										generalAssembly_id: 177,
									},
									{
										id: 1163,
										name: "AKARUBIMBURA",
										generalAssembly_id: 177,
									},
									{
										id: 1164,
										name: "NYAGASOZI",
										generalAssembly_id: 177,
									},
									{
										id: 1165,
										name: "KARURUMA",
										generalAssembly_id: 177,
									},
									{
										id: 1166,
										name: "RUNYONZA",
										generalAssembly_id: 177,
									},
									{
										id: 1167,
										name: "RUBONOBONO",
										generalAssembly_id: 177,
									},
									{
										id: 9152,
										name: "Gatsata Court of Appeal ",
										generalAssembly_id: 177,
									},
									{
										id: 10685,
										name: "Gatsata Gacaca court of sector ",
										generalAssembly_id: 177,
									},
								],
							},
						],
					},
					{
						id: 30203,
						name: "Gikomero",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020305,
								name: "Murambi",
								sector_id: 30203,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020304,
								name: "Munini ",
								sector_id: 30203,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020303,
								name: "Kibara",
								sector_id: 30203,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020302,
								name: "Gicaca",
								sector_id: 30203,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020301,
								name: "Gasagara",
								sector_id: 30203,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 178,
								name: "Gicaca II ",
								sector_id: 30203,
								courtOfCells: [
									{
										id: 1168,
										name: "GAHINGA",
										generalAssembly_id: 178,
									},
									{
										id: 1169,
										name: "GASHARU",
										generalAssembly_id: 178,
									},
									{
										id: 1170,
										name: "KABUYE",
										generalAssembly_id: 178,
									},
									{
										id: 1171,
										name: "KAMATORO",
										generalAssembly_id: 178,
									},
									{
										id: 1172,
										name: "KARESHYA",
										generalAssembly_id: 178,
									},
									{
										id: 1173,
										name: "NOMBE",
										generalAssembly_id: 178,
									},
									{
										id: 1174,
										name: "NTAGANZWA",
										generalAssembly_id: 178,
									},
									{
										id: 1175,
										name: "NYAGASOZI",
										generalAssembly_id: 178,
									},
									{
										id: 1176,
										name: "NYAGISOZI",
										generalAssembly_id: 178,
									},
									{
										id: 1177,
										name: "NYAKABUNGO",
										generalAssembly_id: 178,
									},
									{
										id: 1178,
										name: "RUGANDA",
										generalAssembly_id: 178,
									},
									{
										id: 9153,
										name: "Gicaca II  Court of Appeal ",
										generalAssembly_id: 178,
									},
									{
										id: 10686,
										name: "Gicaca II  Gacaca court of sector ",
										generalAssembly_id: 178,
									},
								],
							},
							{
								id: 179,
								name: "Gikomero",
								sector_id: 30203,
								courtOfCells: [
									{
										id: 1179,
										name: "GASAGARA",
										generalAssembly_id: 179,
									},
									{
										id: 1180,
										name: "GATOVU",
										generalAssembly_id: 179,
									},
									{
										id: 1181,
										name: "KABERE",
										generalAssembly_id: 179,
									},
									{
										id: 1182,
										name: "KIBOBO",
										generalAssembly_id: 179,
									},
									{
										id: 1183,
										name: "KIMISEBEYA",
										generalAssembly_id: 179,
									},
									{
										id: 1184,
										name: "KIVUGIZA",
										generalAssembly_id: 179,
									},
									{
										id: 1185,
										name: "MUNINI",
										generalAssembly_id: 179,
									},
									{
										id: 1186,
										name: "MUTOKEREZWA",
										generalAssembly_id: 179,
									},
									{
										id: 1187,
										name: "RUGARAMA",
										generalAssembly_id: 179,
									},
									{
										id: 1188,
										name: "RUGWIZA",
										generalAssembly_id: 179,
									},
									{
										id: 1189,
										name: "RUNYINYA",
										generalAssembly_id: 179,
									},
									{
										id: 1190,
										name: "BWIMIYANGE",
										generalAssembly_id: 179,
									},
									{
										id: 1191,
										name: "BWINGEYO",
										generalAssembly_id: 179,
									},
									{
										id: 1192,
										name: "TWINA",
										generalAssembly_id: 179,
									},
									{
										id: 9154,
										name: "Gikomero Court of Appeal ",
										generalAssembly_id: 179,
									},
									{
										id: 10687,
										name: "Gikomero Gacaca court of sector ",
										generalAssembly_id: 179,
									},
								],
							},
						],
					},
					{
						id: 30204,
						name: "Gisozi",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020402,
								name: "Ruhango",
								sector_id: 30204,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020401,
								name: "Musezero ",
								sector_id: 30204,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 180,
								name: "Gisozi",
								sector_id: 30204,
								courtOfCells: [
									{
										id: 1193,
										name: "GASHARU",
										generalAssembly_id: 180,
									},
									{
										id: 1194,
										name: "GASAVE",
										generalAssembly_id: 180,
									},
									{
										id: 1195,
										name: "KUMUKENKE",
										generalAssembly_id: 180,
									},
									{
										id: 1196,
										name: "MUSEZERO",
										generalAssembly_id: 180,
									},
									{
										id: 1197,
										name: "NTORA",
										generalAssembly_id: 180,
									},
									{
										id: 1198,
										name: "RUHANGO",
										generalAssembly_id: 180,
									},
									{
										id: 9155,
										name: "Gisozi Court of Appeal ",
										generalAssembly_id: 180,
									},
									{
										id: 10688,
										name: "Gisozi Gacaca court of sector ",
										generalAssembly_id: 180,
									},
								],
							},
						],
					},
					{
						id: 30205,
						name: "Jabana",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020505,
								name: "Ngiryi",
								sector_id: 30205,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020504,
								name: "Kidashya",
								sector_id: 30205,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020503,
								name: "Kabuye",
								sector_id: 30205,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020502,
								name: "Bweramvura",
								sector_id: 30205,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020501,
								name: "Akamatamu",
								sector_id: 30205,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 181,
								name: "Jabana",
								sector_id: 30205,
								courtOfCells: [
									{
										id: 1199,
										name: "KIDASHYA",
										generalAssembly_id: 181,
									},
									{
										id: 1200,
										name: "AKAMATAMU",
										generalAssembly_id: 181,
									},
									{
										id: 1201,
										name: "CYEYERE",
										generalAssembly_id: 181,
									},
									{
										id: 1202,
										name: "MUREHE",
										generalAssembly_id: 181,
									},
									{
										id: 1203,
										name: "NYAMWERU",
										generalAssembly_id: 181,
									},
									{
										id: 1204,
										name: "NYAGASOZI",
										generalAssembly_id: 181,
									},
									{
										id: 1205,
										name: "AGATARE",
										generalAssembly_id: 181,
									},
									{
										id: 1206,
										name: "AKARWIRU",
										generalAssembly_id: 181,
									},
									{
										id: 9156,
										name: "Jabana Court of Appeal ",
										generalAssembly_id: 181,
									},
									{
										id: 10689,
										name: "Jabana Gacaca court of sector ",
										generalAssembly_id: 181,
									},
								],
							},
							{
								id: 182,
								name: "Kabuye",
								sector_id: 30205,
								courtOfCells: [
									{
										id: 1207,
										name: "AGATWA",
										generalAssembly_id: 182,
									},
									{
										id: 1208,
										name: "RUNYOVI",
										generalAssembly_id: 182,
									},
									{
										id: 1209,
										name: "CYEYERE",
										generalAssembly_id: 182,
									},
									{
										id: 1210,
										name: "GIKINGO",
										generalAssembly_id: 182,
									},
									{
										id: 1211,
										name: "MURAMA",
										generalAssembly_id: 182,
									},
									{
										id: 1212,
										name: "NYARURAMA",
										generalAssembly_id: 182,
									},
									{
										id: 1213,
										name: "RUTETE",
										generalAssembly_id: 182,
									},
									{
										id: 9157,
										name: "Kabuye Court of Appeal ",
										generalAssembly_id: 182,
									},
									{
										id: 10690,
										name: "Kabuye Gacaca court of sector ",
										generalAssembly_id: 182,
									},
								],
							},
							{
								id: 183,
								name: "Ngiryi",
								sector_id: 30205,
								courtOfCells: [
									{
										id: 1214,
										name: "RUBONA",
										generalAssembly_id: 183,
									},
									{
										id: 1215,
										name: "JURWE",
										generalAssembly_id: 183,
									},
									{
										id: 1216,
										name: "AGASHARU",
										generalAssembly_id: 183,
									},
									{
										id: 1217,
										name: "RWANYANZA",
										generalAssembly_id: 183,
									},
									{
										id: 1218,
										name: "NYAKIREHE",
										generalAssembly_id: 183,
									},
									{
										id: 1219,
										name: "UWANYANGE",
										generalAssembly_id: 183,
									},
									{
										id: 9158,
										name: "Ngiryi Court of Appeal ",
										generalAssembly_id: 183,
									},
									{
										id: 10691,
										name: "Ngiryi Gacaca court of sector ",
										generalAssembly_id: 183,
									},
								],
							},
						],
					},
					{
						id: 30206,
						name: "Jali",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020607,
								name: "Nyamitanga",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020606,
								name: "Nyakabungo",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020605,
								name: "Nyabuliba",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020604,
								name: "Nkusi",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020603,
								name: "Muko",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020602,
								name: "Buhiza ",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020601,
								name: "Agateko",
								sector_id: 30206,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 184,
								name: "Cyuga",
								sector_id: 30206,
								courtOfCells: [
									{
										id: 1220,
										name: "KIGUFI",
										generalAssembly_id: 184,
									},
									{
										id: 1221,
										name: "BUHIZA",
										generalAssembly_id: 184,
									},
									{
										id: 1222,
										name: "CYUGA",
										generalAssembly_id: 184,
									},
									{
										id: 1223,
										name: "KIGARAMA",
										generalAssembly_id: 184,
									},
									{
										id: 1224,
										name: "NYAGASOZI",
										generalAssembly_id: 184,
									},
									{
										id: 1225,
										name: "NYAKABUNGO",
										generalAssembly_id: 184,
									},
									{
										id: 1226,
										name: "NYAMUNINI",
										generalAssembly_id: 184,
									},
									{
										id: 9159,
										name: "Cyuga Court of Appeal ",
										generalAssembly_id: 184,
									},
									{
										id: 10692,
										name: "Cyuga Gacaca court of sector ",
										generalAssembly_id: 184,
									},
								],
							},
							{
								id: 185,
								name: "Gihogwe ",
								sector_id: 30206,
								courtOfCells: [
									{
										id: 1227,
										name: "UMUBANGA",
										generalAssembly_id: 185,
									},
									{
										id: 1228,
										name: "GATEKO",
										generalAssembly_id: 185,
									},
									{
										id: 1229,
										name: "KAVUMU",
										generalAssembly_id: 185,
									},
									{
										id: 9160,
										name: "Gihogwe  Court of Appeal ",
										generalAssembly_id: 185,
									},
									{
										id: 10693,
										name: "Gihogwe  Gacaca court of sector ",
										generalAssembly_id: 185,
									},
								],
							},
							{
								id: 186,
								name: "Jali",
								sector_id: 30206,
								courtOfCells: [
									{
										id: 1230,
										name: "MURAMBI",
										generalAssembly_id: 186,
									},
									{
										id: 1231,
										name: "NYAMITANGA",
										generalAssembly_id: 186,
									},
									{
										id: 1232,
										name: "NYABURIBA",
										generalAssembly_id: 186,
									},
									{
										id: 1233,
										name: "GITOVU",
										generalAssembly_id: 186,
									},
									{
										id: 1234,
										name: "NYARUBARE",
										generalAssembly_id: 186,
									},
									{
										id: 1235,
										name: "MUREHE",
										generalAssembly_id: 186,
									},
									{
										id: 9161,
										name: "Jali Court of Appeal ",
										generalAssembly_id: 186,
									},
									{
										id: 10694,
										name: "Jali Gacaca court of sector ",
										generalAssembly_id: 186,
									},
								],
							},
							{
								id: 187,
								name: "Rubingo",
								sector_id: 30206,
								courtOfCells: [
									{
										id: 1236,
										name: "KABAHARA",
										generalAssembly_id: 187,
									},
									{
										id: 1237,
										name: "BUVA",
										generalAssembly_id: 187,
									},
									{
										id: 1238,
										name: "MBOZA",
										generalAssembly_id: 187,
									},
									{
										id: 1239,
										name: "MUKO",
										generalAssembly_id: 187,
									},
									{
										id: 1240,
										name: "NKUSI",
										generalAssembly_id: 187,
									},
									{
										id: 9162,
										name: "Rubingo Court of Appeal ",
										generalAssembly_id: 187,
									},
									{
										id: 10695,
										name: "Rubingo Gacaca court of sector ",
										generalAssembly_id: 187,
									},
								],
							},
						],
					},
					{
						id: 30207,
						name: "Kacyiru",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020703,
								name: "Kibaza",
								sector_id: 30207,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020702,
								name: "Kamutwa",
								sector_id: 30207,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020701,
								name: "Kamatamu ",
								sector_id: 30207,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 188,
								name: "Kacyiru",
								sector_id: 30207,
								courtOfCells: [
									{
										id: 1241,
										name: "KAMATAMU",
										generalAssembly_id: 188,
									},
									{
										id: 1242,
										name: "KAMUTWA",
										generalAssembly_id: 188,
									},
									{
										id: 1243,
										name: "KARUKAMBA",
										generalAssembly_id: 188,
									},
									{
										id: 1244,
										name: "KINAMBA",
										generalAssembly_id: 188,
									},
									{
										id: 1245,
										name: "AMAHORO",
										generalAssembly_id: 188,
									},
									{
										id: 1246,
										name: "KIBAZA",
										generalAssembly_id: 188,
									},
									{
										id: 9163,
										name: "Kacyiru Court of Appeal ",
										generalAssembly_id: 188,
									},
									{
										id: 10696,
										name: "Kacyiru Gacaca court of sector ",
										generalAssembly_id: 188,
									},
								],
							},
						],
					},
					{
						id: 30208,
						name: "Kimihurura",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020803,
								name: "Rugando",
								sector_id: 30208,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020802,
								name: "Kimihurura",
								sector_id: 30208,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020801,
								name: "Kamukina",
								sector_id: 30208,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 189,
								name: "Kimihurura",
								sector_id: 30208,
								courtOfCells: [
									{
										id: 1247,
										name: "KAMUKINA",
										generalAssembly_id: 189,
									},
									{
										id: 1248,
										name: "KIMIHURURA",
										generalAssembly_id: 189,
									},
									{
										id: 1249,
										name: "RUGARAMA",
										generalAssembly_id: 189,
									},
									{
										id: 1250,
										name: "KIMICANGA",
										generalAssembly_id: 189,
									},
									{
										id: 1251,
										name: "RUGANDO",
										generalAssembly_id: 189,
									},
									{
										id: 9164,
										name: "Kimihurura Court of Appeal ",
										generalAssembly_id: 189,
									},
									{
										id: 10697,
										name: "Kimihurura Gacaca court of sector ",
										generalAssembly_id: 189,
									},
								],
							},
						],
					},
					{
						id: 30209,
						name: "Kimironko",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3020903,
								name: "Nyagatovu ",
								sector_id: 30209,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020902,
								name: "Kibagabaga",
								sector_id: 30209,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3020901,
								name: "Bibare",
								sector_id: 30209,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 190,
								name: "Kimironko",
								sector_id: 30209,
								courtOfCells: [
									{
										id: 1252,
										name: "BIBARE",
										generalAssembly_id: 190,
									},
									{
										id: 1253,
										name: "KIBAGABAGA",
										generalAssembly_id: 190,
									},
									{
										id: 1254,
										name: "NYAGATOVU",
										generalAssembly_id: 190,
									},
									{
										id: 1255,
										name: "KIMIRONKO I",
										generalAssembly_id: 190,
									},
									{
										id: 1256,
										name: "KIMIRONKO II",
										generalAssembly_id: 190,
									},
									{
										id: 9165,
										name: "Kimironko Court of Appeal ",
										generalAssembly_id: 190,
									},
									{
										id: 10698,
										name: "Kimironko Gacaca court of sector ",
										generalAssembly_id: 190,
									},
								],
							},
						],
					},
					{
						id: 30210,
						name: "Kinyinya",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021004,
								name: "Murama",
								sector_id: 30210,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021003,
								name: "Kagugu",
								sector_id: 30210,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021002,
								name: "Gasharu",
								sector_id: 30210,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021001,
								name: "Gacuriro",
								sector_id: 30210,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 191,
								name: "Kagugu",
								sector_id: 30210,
								courtOfCells: [
									{
										id: 1257,
										name: "GIHEKA",
										generalAssembly_id: 191,
									},
									{
										id: 1258,
										name: "URUBANDA",
										generalAssembly_id: 191,
									},
									{
										id: 1259,
										name: "KIYOVU",
										generalAssembly_id: 191,
									},
									{
										id: 1260,
										name: "RUGENGE",
										generalAssembly_id: 191,
									},
									{
										id: 1261,
										name: "BATSINDA",
										generalAssembly_id: 191,
									},
									{
										id: 1262,
										name: "URUGARAMA",
										generalAssembly_id: 191,
									},
									{
										id: 1263,
										name: "AGATARE",
										generalAssembly_id: 191,
									},
									{
										id: 9166,
										name: "Kagugu Court of Appeal ",
										generalAssembly_id: 191,
									},
									{
										id: 10699,
										name: "Kagugu Gacaca court of sector ",
										generalAssembly_id: 191,
									},
								],
							},
							{
								id: 192,
								name: "Kinyinya",
								sector_id: 30210,
								courtOfCells: [
									{
										id: 1264,
										name: "RWANKUBA",
										generalAssembly_id: 192,
									},
									{
										id: 1265,
										name: "BINUNGA",
										generalAssembly_id: 192,
									},
									{
										id: 1266,
										name: "GASHARU II",
										generalAssembly_id: 192,
									},
									{
										id: 1267,
										name: "MURAMA",
										generalAssembly_id: 192,
									},
									{
										id: 1268,
										name: "GASHARU I",
										generalAssembly_id: 192,
									},
									{
										id: 1269,
										name: "KAGUGU",
										generalAssembly_id: 192,
									},
									{
										id: 9167,
										name: "Kinyinya Court of Appeal ",
										generalAssembly_id: 192,
									},
									{
										id: 10700,
										name: "Kinyinya Gacaca court of sector ",
										generalAssembly_id: 192,
									},
								],
							},
						],
					},
					{
						id: 30211,
						name: "Ndera",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021106,
								name: "Rudashya",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021105,
								name: "Mukuyu",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021104,
								name: "Masoro",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021103,
								name: "Kibenga",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021102,
								name: "Cyaruzinge",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021101,
								name: "Bwiza ",
								sector_id: 30211,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 193,
								name: "Ndera",
								sector_id: 30211,
								courtOfCells: [
									{
										id: 1270,
										name: "KIBENGA",
										generalAssembly_id: 193,
									},
									{
										id: 1271,
										name: "KINUNGA",
										generalAssembly_id: 193,
									},
									{
										id: 9168,
										name: "Ndera Court of Appeal ",
										generalAssembly_id: 193,
									},
									{
										id: 10701,
										name: "Ndera Gacaca court of sector ",
										generalAssembly_id: 193,
									},
								],
							},
							{
								id: 194,
								name: "Gasogi",
								sector_id: 30211,
								courtOfCells: [
									{
										id: 1272,
										name: "BWIZA",
										generalAssembly_id: 194,
									},
									{
										id: 1273,
										name: "CYARUZINGE",
										generalAssembly_id: 194,
									},
									{
										id: 1274,
										name: "RUDASHYA",
										generalAssembly_id: 194,
									},
									{
										id: 1275,
										name: "NYAKWIBEREKA",
										generalAssembly_id: 194,
									},
									{
										id: 1276,
										name: "GASHURE",
										generalAssembly_id: 194,
									},
									{
										id: 9169,
										name: "Gasogi Court of Appeal ",
										generalAssembly_id: 194,
									},
									{
										id: 10702,
										name: "Gasogi Gacaca court of sector ",
										generalAssembly_id: 194,
									},
								],
							},
							{
								id: 195,
								name: "Jurwe",
								sector_id: 30211,
								courtOfCells: [
									{
										id: 1277,
										name: "NYAMABUYE",
										generalAssembly_id: 195,
									},
									{
										id: 1278,
										name: "BUSHYANTOBO",
										generalAssembly_id: 195,
									},
									{
										id: 1279,
										name: "MUKUYU",
										generalAssembly_id: 195,
									},
									{
										id: 1280,
										name: "GISHURE",
										generalAssembly_id: 195,
									},
									{
										id: 9170,
										name: "Jurwe Court of Appeal ",
										generalAssembly_id: 195,
									},
									{
										id: 10703,
										name: "Jurwe Gacaca court of sector ",
										generalAssembly_id: 195,
									},
								],
							},
							{
								id: 196,
								name: "Masoro",
								sector_id: 30211,
								courtOfCells: [
									{
										id: 1281,
										name: "MASORO",
										generalAssembly_id: 196,
									},
									{
										id: 1282,
										name: "MUNINI",
										generalAssembly_id: 196,
									},
									{
										id: 9171,
										name: "Masoro Court of Appeal ",
										generalAssembly_id: 196,
									},
									{
										id: 10704,
										name: "Masoro Gacaca court of sector ",
										generalAssembly_id: 196,
									},
								],
							},
						],
					},
					{
						id: 30212,
						name: "Nduba",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021207,
								name: "Shango",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021206,
								name: "Sha",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021205,
								name: "Muremure",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021204,
								name: "Gatunga",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021203,
								name: "Gasura",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021202,
								name: "Gasanze",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021201,
								name: "Butare",
								sector_id: 30212,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 197,
								name: "Nduba",
								sector_id: 30212,
								courtOfCells: [
									{
										id: 1283,
										name: "NYABITARE",
										generalAssembly_id: 197,
									},
									{
										id: 1284,
										name: "RUGARAMA",
										generalAssembly_id: 197,
									},
									{
										id: 1285,
										name: "NYARUBANDE",
										generalAssembly_id: 197,
									},
									{
										id: 1286,
										name: "MUREMURE",
										generalAssembly_id: 197,
									},
									{
										id: 1287,
										name: "KIBUNGO",
										generalAssembly_id: 197,
									},
									{
										id: 1288,
										name: "NYABURORO",
										generalAssembly_id: 197,
									},
									{
										id: 1289,
										name: "GASANZE",
										generalAssembly_id: 197,
									},
									{
										id: 1290,
										name: "GATOBOTOBO",
										generalAssembly_id: 197,
									},
									{
										id: 1291,
										name: "GATUNGA",
										generalAssembly_id: 197,
									},
									{
										id: 1292,
										name: "KARAMA",
										generalAssembly_id: 197,
									},
									{
										id: 1293,
										name: "MUSEZERO",
										generalAssembly_id: 197,
									},
									{
										id: 1294,
										name: "NYAKABUNGO",
										generalAssembly_id: 197,
									},
									{
										id: 1295,
										name: "BURUNGERO",
										generalAssembly_id: 197,
									},
									{
										id: 9172,
										name: "Nduba Court of Appeal ",
										generalAssembly_id: 197,
									},
									{
										id: 10705,
										name: "Nduba Gacaca court of sector ",
										generalAssembly_id: 197,
									},
								],
							},
							{
								id: 198,
								name: "Sha ",
								sector_id: 30212,
								courtOfCells: [
									{
										id: 1296,
										name: "GIKOMBE",
										generalAssembly_id: 198,
									},
									{
										id: 1297,
										name: "KAZI",
										generalAssembly_id: 198,
									},
									{
										id: 1298,
										name: "GASHINYA",
										generalAssembly_id: 198,
									},
									{
										id: 1299,
										name: "KIGARAMA",
										generalAssembly_id: 198,
									},
									{
										id: 1300,
										name: "GASURA",
										generalAssembly_id: 198,
									},
									{
										id: 1301,
										name: "AKAMUYANGE",
										generalAssembly_id: 198,
									},
									{
										id: 1302,
										name: "GATARE",
										generalAssembly_id: 198,
									},
									{
										id: 1303,
										name: "NYIRAKIBEHE",
										generalAssembly_id: 198,
									},
									{
										id: 1304,
										name: "RUGARAMA",
										generalAssembly_id: 198,
									},
									{
										id: 1305,
										name: "KIGUFI",
										generalAssembly_id: 198,
									},
									{
										id: 1306,
										name: "BIKUMBA",
										generalAssembly_id: 198,
									},
									{
										id: 1307,
										name: "GAKIZI",
										generalAssembly_id: 198,
									},
									{
										id: 9173,
										name: "Sha  Court of Appeal ",
										generalAssembly_id: 198,
									},
									{
										id: 10706,
										name: "Sha  Gacaca court of sector ",
										generalAssembly_id: 198,
									},
								],
							},
							{
								id: 199,
								name: "Shango",
								sector_id: 30212,
								courtOfCells: [
									{
										id: 1308,
										name: "AKAZI",
										generalAssembly_id: 199,
									},
									{
										id: 1309,
										name: "MUNINI",
										generalAssembly_id: 199,
									},
									{
										id: 1310,
										name: "KIDAHE",
										generalAssembly_id: 199,
									},
									{
										id: 1311,
										name: "NYAMIGINA",
										generalAssembly_id: 199,
									},
									{
										id: 1312,
										name: "KADUHA",
										generalAssembly_id: 199,
									},
									{
										id: 1313,
										name: "MIRAMBI",
										generalAssembly_id: 199,
									},
									{
										id: 1314,
										name: "KIGABIRO",
										generalAssembly_id: 199,
									},
									{
										id: 1315,
										name: "NYAMURAMBI",
										generalAssembly_id: 199,
									},
									{
										id: 1316,
										name: "NYARUBUYE",
										generalAssembly_id: 199,
									},
									{
										id: 1317,
										name: "RUGARAMA",
										generalAssembly_id: 199,
									},
									{
										id: 1318,
										name: "NYURA",
										generalAssembly_id: 199,
									},
									{
										id: 9174,
										name: "Shango Court of Appeal ",
										generalAssembly_id: 199,
									},
									{
										id: 10707,
										name: "Shango Gacaca court of sector ",
										generalAssembly_id: 199,
									},
								],
							},
						],
					},
					{
						id: 30213,
						name: "Remera",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021304,
								name: "Rukiri Ii",
								sector_id: 30213,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021303,
								name: "Rukiri I",
								sector_id: 30213,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021302,
								name: "Nyarutarama",
								sector_id: 30213,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021301,
								name: "Nyabisindu",
								sector_id: 30213,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 200,
								name: "Remera",
								sector_id: 30213,
								courtOfCells: [
									{
										id: 1319,
										name: "NYABISINDU",
										generalAssembly_id: 200,
									},
									{
										id: 1320,
										name: "RUKIRI III",
										generalAssembly_id: 200,
									},
									{
										id: 1321,
										name: "NYARUTARAMA",
										generalAssembly_id: 200,
									},
									{
										id: 1322,
										name: "RUKIRI IV",
										generalAssembly_id: 200,
									},
									{
										id: 1323,
										name: "RUKIRI II",
										generalAssembly_id: 200,
									},
									{
										id: 1324,
										name: "RUKIRI I",
										generalAssembly_id: 200,
									},
									{
										id: 9175,
										name: "Remera Court of Appeal ",
										generalAssembly_id: 200,
									},
									{
										id: 10708,
										name: "Remera Gacaca court of sector ",
										generalAssembly_id: 200,
									},
								],
							},
						],
					},
					{
						id: 30214,
						name: "Rusororo",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021408,
								name: "Ruhanga",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021407,
								name: "Nyagahinga",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021406,
								name: "Mbandazi",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021405,
								name: "Kinyana",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021404,
								name: "Kabuga Ii",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021403,
								name: "Kabuga I",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021402,
								name: "Gasagara",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021401,
								name: "Bisenga",
								sector_id: 30214,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 201,
								name: "Rusororo",
								sector_id: 30214,
								courtOfCells: [
									{
										id: 1325,
										name: "CYABATANZI",
										generalAssembly_id: 201,
									},
									{
										id: 1326,
										name: "SAMUDUHA",
										generalAssembly_id: 201,
									},
									{
										id: 1327,
										name: "NYAGAHINGA",
										generalAssembly_id: 201,
									},
									{
										id: 1328,
										name: "CYERU",
										generalAssembly_id: 201,
									},
									{
										id: 1329,
										name: "RUNYONZA",
										generalAssembly_id: 201,
									},
									{
										id: 1330,
										name: "KABUGA I",
										generalAssembly_id: 201,
									},
									{
										id: 1331,
										name: "KABUGA II",
										generalAssembly_id: 201,
									},
									{
										id: 9176,
										name: "Rusororo Court of Appeal ",
										generalAssembly_id: 201,
									},
									{
										id: 10709,
										name: "Rusororo Gacaca court of sector ",
										generalAssembly_id: 201,
									},
								],
							},
							{
								id: 202,
								name: "Gicaca I",
								sector_id: 30214,
								courtOfCells: [
									{
										id: 1332,
										name: "BISENGA",
										generalAssembly_id: 202,
									},
									{
										id: 1333,
										name: "BUSENYI",
										generalAssembly_id: 202,
									},
									{
										id: 1334,
										name: "NYAGACYAMO",
										generalAssembly_id: 202,
									},
									{
										id: 1335,
										name: "KINYAGA",
										generalAssembly_id: 202,
									},
									{
										id: 1336,
										name: "RYABAZANA-RUGAGI",
										generalAssembly_id: 202,
									},
									{
										id: 1337,
										name: "NYAGISOZI",
										generalAssembly_id: 202,
									},
									{
										id: 1338,
										name: "RUGENDE",
										generalAssembly_id: 202,
									},
									{
										id: 1339,
										name: "RUHANGA",
										generalAssembly_id: 202,
									},
									{
										id: 1340,
										name: "CYABATANZI",
										generalAssembly_id: 202,
									},
									{
										id: 1341,
										name: "GAKENYERI",
										generalAssembly_id: 202,
									},
									{
										id: 1342,
										name: "GASAGARA",
										generalAssembly_id: 202,
									},
									{
										id: 1343,
										name: "KIDOGO",
										generalAssembly_id: 202,
									},
									{
										id: 1344,
										name: "KIGABIRO",
										generalAssembly_id: 202,
									},
									{
										id: 1345,
										name: "KINYANA",
										generalAssembly_id: 202,
									},
									{
										id: 1346,
										name: "NYAMIRAMA",
										generalAssembly_id: 202,
									},
									{
										id: 9177,
										name: "Gicaca I Court of Appeal ",
										generalAssembly_id: 202,
									},
									{
										id: 10710,
										name: "Gicaca I Gacaca court of sector ",
										generalAssembly_id: 202,
									},
								],
							},
						],
					},
					{
						id: 30215,
						name: "Rutunga",
						district_id: 302,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3021506,
								name: "Kigabiro",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021505,
								name: "Kibenga",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021504,
								name: "Kacyatwa",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021503,
								name: "Kabaliza",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021502,
								name: "Indatemwa",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3021501,
								name: "Gasabo",
								sector_id: 30215,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 203,
								name: "Gasabo",
								sector_id: 30215,
								courtOfCells: [
									{
										id: 1347,
										name: "GASHARU",
										generalAssembly_id: 203,
									},
									{
										id: 1348,
										name: "KAMUSENGO",
										generalAssembly_id: 203,
									},
									{
										id: 1349,
										name: "KAREKARE",
										generalAssembly_id: 203,
									},
									{
										id: 1350,
										name: "KIGABIRO",
										generalAssembly_id: 203,
									},
									{
										id: 1351,
										name: "MURINDI",
										generalAssembly_id: 203,
									},
									{
										id: 1352,
										name: "NYAKABANDE",
										generalAssembly_id: 203,
									},
									{
										id: 1353,
										name: "VUGAVUGE",
										generalAssembly_id: 203,
									},
									{
										id: 9178,
										name: "Gasabo Court of Appeal ",
										generalAssembly_id: 203,
									},
									{
										id: 10711,
										name: "Gasabo Gacaca court of sector ",
										generalAssembly_id: 203,
									},
								],
							},
							{
								id: 204,
								name: "Kayanga",
								sector_id: 30215,
								courtOfCells: [
									{
										id: 1354,
										name: "CYIRI",
										generalAssembly_id: 204,
									},
									{
										id: 1355,
										name: "KAMUSARE",
										generalAssembly_id: 204,
									},
									{
										id: 1356,
										name: "KANDAMIRA",
										generalAssembly_id: 204,
									},
									{
										id: 1357,
										name: "KANTABANA",
										generalAssembly_id: 204,
									},
									{
										id: 1358,
										name: "KACYATWA",
										generalAssembly_id: 204,
									},
									{
										id: 1359,
										name: "KARWIRU",
										generalAssembly_id: 204,
									},
									{
										id: 1360,
										name: "KIGABIRO",
										generalAssembly_id: 204,
									},
									{
										id: 1361,
										name: "MUNINI",
										generalAssembly_id: 204,
									},
									{
										id: 1362,
										name: "NYAMISE",
										generalAssembly_id: 204,
									},
									{
										id: 1363,
										name: "RUKEREREZA",
										generalAssembly_id: 204,
									},
									{
										id: 1364,
										name: "RWINTARE",
										generalAssembly_id: 204,
									},
									{
										id: 9179,
										name: "Kayanga Court of Appeal ",
										generalAssembly_id: 204,
									},
									{
										id: 10712,
										name: "Kayanga Gacaca court of sector ",
										generalAssembly_id: 204,
									},
								],
							},
							{
								id: 205,
								name: "Rutunga",
								sector_id: 30215,
								courtOfCells: [
									{
										id: 1365,
										name: "ABANYANGEYO",
										generalAssembly_id: 205,
									},
									{
										id: 1366,
										name: "KABARERA",
										generalAssembly_id: 205,
									},
									{
										id: 1367,
										name: "KABARIZA",
										generalAssembly_id: 205,
									},
									{
										id: 1368,
										name: "KARURANGA",
										generalAssembly_id: 205,
									},
									{
										id: 1369,
										name: "KIBENGA",
										generalAssembly_id: 205,
									},
									{
										id: 1370,
										name: "NYAMVUMVU",
										generalAssembly_id: 205,
									},
									{
										id: 1371,
										name: "RWANYANZA",
										generalAssembly_id: 205,
									},
									{
										id: 9180,
										name: "Rutunga Court of Appeal ",
										generalAssembly_id: 205,
									},
									{
										id: 10713,
										name: "Rutunga Gacaca court of sector ",
										generalAssembly_id: 205,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 303,
				name: "Kicukiro",
				province_id: 3,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 30301,
						name: "Gahanga",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030106,
								name: "Rwabutenge",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030105,
								name: "Nunga",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030104,
								name: "Murinja",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030103,
								name: "Karembure",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030102,
								name: "Kagasa",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030101,
								name: "Gahanga",
								sector_id: 30301,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 625,
								name: "Gahanga",
								sector_id: 30301,
								courtOfCells: [
									{
										id: 3413,
										name: "KAREMBURE",
										generalAssembly_id: 625,
									},
									{
										id: 3414,
										name: "KARUSHAKA",
										generalAssembly_id: 625,
									},
									{
										id: 3415,
										name: "MUBUGA",
										generalAssembly_id: 625,
									},
									{
										id: 3416,
										name: "NUNGA",
										generalAssembly_id: 625,
									},
									{
										id: 9600,
										name: "Gahanga Court of Appeal ",
										generalAssembly_id: 625,
									},
									{
										id: 11133,
										name: "Gahanga Gacaca court of sector ",
										generalAssembly_id: 625,
									},
								],
							},
							{
								id: 626,
								name: "Kagasa",
								sector_id: 30301,
								courtOfCells: [
									{
										id: 3417,
										name: "KABEZA",
										generalAssembly_id: 626,
									},
									{
										id: 3418,
										name: "KIYANJA",
										generalAssembly_id: 626,
									},
									{
										id: 3419,
										name: "NYAKUGUMA",
										generalAssembly_id: 626,
									},
									{
										id: 9601,
										name: "Kagasa Court of Appeal ",
										generalAssembly_id: 626,
									},
									{
										id: 11134,
										name: "Kagasa Gacaca court of sector ",
										generalAssembly_id: 626,
									},
								],
							},
							{
								id: 627,
								name: "Rwabutenge",
								sector_id: 30301,
								courtOfCells: [
									{
										id: 3420,
										name: "GATARE",
										generalAssembly_id: 627,
									},
									{
										id: 3421,
										name: "MULINJA",
										generalAssembly_id: 627,
									},
									{
										id: 3422,
										name: "NYABIGUGU",
										generalAssembly_id: 627,
									},
									{
										id: 3423,
										name: "REBERO",
										generalAssembly_id: 627,
									},
									{
										id: 3424,
										name: "RUNZENZE",
										generalAssembly_id: 627,
									},
									{
										id: 9602,
										name: "Rwabutenge Court of Appeal ",
										generalAssembly_id: 627,
									},
									{
										id: 11135,
										name: "Rwabutenge Gacaca court of sector ",
										generalAssembly_id: 627,
									},
								],
							},
						],
					},
					{
						id: 30302,
						name: "Gatenga",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030204,
								name: "Nyarurama",
								sector_id: 30302,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030203,
								name: "Nyanza",
								sector_id: 30302,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030202,
								name: "Karambo",
								sector_id: 30302,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030201,
								name: "Gatenga",
								sector_id: 30302,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 628,
								name: "Gatenga",
								sector_id: 30302,
								courtOfCells: [
									{
										id: 3425,
										name: "GAKOKI",
										generalAssembly_id: 628,
									},
									{
										id: 3426,
										name: "GASHYEKERO",
										generalAssembly_id: 628,
									},
									{
										id: 3427,
										name: "GATENGA",
										generalAssembly_id: 628,
									},
									{
										id: 3428,
										name: "KANENGWA",
										generalAssembly_id: 628,
									},
									{
										id: 3429,
										name: "KARAMBO I",
										generalAssembly_id: 628,
									},
									{
										id: 3430,
										name: "KARAMBO II",
										generalAssembly_id: 628,
									},
									{
										id: 3431,
										name: "KARAMBO III",
										generalAssembly_id: 628,
									},
									{
										id: 3432,
										name: "MURAMBI",
										generalAssembly_id: 628,
									},
									{
										id: 9603,
										name: "Gatenga Court of Appeal ",
										generalAssembly_id: 628,
									},
									{
										id: 11136,
										name: "Gatenga Gacaca court of sector ",
										generalAssembly_id: 628,
									},
								],
							},
							{
								id: 629,
								name: "Nyarurama",
								sector_id: 30302,
								courtOfCells: [
									{
										id: 3433,
										name: "BIGO",
										generalAssembly_id: 629,
									},
									{
										id: 3434,
										name: "BISAMBU",
										generalAssembly_id: 629,
									},
									{
										id: 3435,
										name: "NYABIKENKE",
										generalAssembly_id: 629,
									},
									{
										id: 9604,
										name: "Nyarurama Court of Appeal ",
										generalAssembly_id: 629,
									},
									{
										id: 11137,
										name: "Nyarurama Gacaca court of sector ",
										generalAssembly_id: 629,
									},
								],
							},
						],
					},
					{
						id: 30303,
						name: "Gikondo",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030303,
								name: "Kinunga",
								sector_id: 30303,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030302,
								name: "Kanserege",
								sector_id: 30303,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030301,
								name: "Kagunga",
								sector_id: 30303,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 630,
								name: "Gikondo",
								sector_id: 30303,
								courtOfCells: [
									{
										id: 3436,
										name: "KAGUNGA",
										generalAssembly_id: 630,
									},
									{
										id: 3437,
										name: "KANSEREGE",
										generalAssembly_id: 630,
									},
									{
										id: 3438,
										name: "KINUNGA",
										generalAssembly_id: 630,
									},
									{
										id: 3439,
										name: "MAREMBO",
										generalAssembly_id: 630,
									},
									{
										id: 3440,
										name: "RUGANWA",
										generalAssembly_id: 630,
									},
									{
										id: 9605,
										name: "Gikondo Court of Appeal ",
										generalAssembly_id: 630,
									},
									{
										id: 11138,
										name: "Gikondo Gacaca court of sector ",
										generalAssembly_id: 630,
									},
								],
							},
						],
					},
					{
						id: 30304,
						name: "Kagarama",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030403,
								name: "Rukatsa",
								sector_id: 30304,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030402,
								name: "Muyange",
								sector_id: 30304,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030401,
								name: "Kanserege",
								sector_id: 30304,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 631,
								name: "Kagarama ",
								sector_id: 30304,
								courtOfCells: [
									{
										id: 3441,
										name: "KANSEREGE",
										generalAssembly_id: 631,
									},
									{
										id: 3442,
										name: "MUYANGE",
										generalAssembly_id: 631,
									},
									{
										id: 3443,
										name: "NYANZA",
										generalAssembly_id: 631,
									},
									{
										id: 3444,
										name: "RUKATSA",
										generalAssembly_id: 631,
									},
									{
										id: 9606,
										name: "Kagarama  Court of Appeal ",
										generalAssembly_id: 631,
									},
									{
										id: 11139,
										name: "Kagarama  Gacaca court of sector ",
										generalAssembly_id: 631,
									},
								],
							},
						],
					},
					{
						id: 30305,
						name: "Kanombe",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030504,
								name: "Rubirizi",
								sector_id: 30305,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030503,
								name: "Karama",
								sector_id: 30305,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030502,
								name: "Kabeza",
								sector_id: 30305,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030501,
								name: "Busanza ",
								sector_id: 30305,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 632,
								name: "Busanza",
								sector_id: 30305,
								courtOfCells: [
									{
										id: 3445,
										name: "GAKOROKOMBE",
										generalAssembly_id: 632,
									},
									{
										id: 3446,
										name: "KARAMA",
										generalAssembly_id: 632,
									},
									{
										id: 3447,
										name: "MUSIGA",
										generalAssembly_id: 632,
									},
									{
										id: 3448,
										name: "NYARUGUGU",
										generalAssembly_id: 632,
									},
									{
										id: 9607,
										name: "Busanza Court of Appeal ",
										generalAssembly_id: 632,
									},
									{
										id: 11140,
										name: "Busanza Gacaca court of sector ",
										generalAssembly_id: 632,
									},
								],
							},
							{
								id: 633,
								name: "Kanombe",
								sector_id: 30305,
								courtOfCells: [
									{
										id: 3449,
										name: "AYISANGA",
										generalAssembly_id: 633,
									},
									{
										id: 3450,
										name: "GAKONI",
										generalAssembly_id: 633,
									},
									{
										id: 3451,
										name: "KABEZA",
										generalAssembly_id: 633,
									},
									{
										id: 3452,
										name: "SAMUDUHA",
										generalAssembly_id: 633,
									},
									{
										id: 9608,
										name: "Kanombe Court of Appeal ",
										generalAssembly_id: 633,
									},
									{
										id: 11141,
										name: "Kanombe Gacaca court of sector ",
										generalAssembly_id: 633,
									},
								],
							},
						],
					},
					{
						id: 30306,
						name: "Kicukiro",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030604,
								name: "Ngoma",
								sector_id: 30306,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030603,
								name: "Kicukiro",
								sector_id: 30306,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030602,
								name: "Kagina",
								sector_id: 30306,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030601,
								name: "Gasharu",
								sector_id: 30306,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 634,
								name: "Kicukiro",
								sector_id: 30306,
								courtOfCells: [
									{
										id: 3453,
										name: "GASHARU I",
										generalAssembly_id: 634,
									},
									{
										id: 3454,
										name: "GASHARU II",
										generalAssembly_id: 634,
									},
									{
										id: 3455,
										name: "KAGINA",
										generalAssembly_id: 634,
									},
									{
										id: 3456,
										name: "NGOMA",
										generalAssembly_id: 634,
									},
									{
										id: 3457,
										name: "ZINIYA",
										generalAssembly_id: 634,
									},
									{
										id: 9609,
										name: "Kicukiro Court of Appeal ",
										generalAssembly_id: 634,
									},
									{
										id: 11142,
										name: "Kicukiro Gacaca court of sector ",
										generalAssembly_id: 634,
									},
								],
							},
						],
					},
					{
						id: 30307,
						name: "Kigarama",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030705,
								name: "Rwampara",
								sector_id: 30307,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030704,
								name: "Nyarurama",
								sector_id: 30307,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030703,
								name: "Kigarama",
								sector_id: 30307,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030702,
								name: "Karugira",
								sector_id: 30307,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030701,
								name: "Bwerankori",
								sector_id: 30307,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 635,
								name: "Kigarama",
								sector_id: 30307,
								courtOfCells: [
									{
										id: 3458,
										name: "KARUGIRA",
										generalAssembly_id: 635,
									},
									{
										id: 3459,
										name: "KIGARAMA",
										generalAssembly_id: 635,
									},
									{
										id: 3460,
										name: "MBURABUTURO",
										generalAssembly_id: 635,
									},
									{
										id: 3461,
										name: "RUTOKI",
										generalAssembly_id: 635,
									},
									{
										id: 3462,
										name: "RWAMPARA",
										generalAssembly_id: 635,
									},
									{
										id: 3463,
										name: "SGEEM",
										generalAssembly_id: 635,
									},
									{
										id: 3464,
										name: "UBUTARE",
										generalAssembly_id: 635,
									},
									{
										id: 3465,
										name: "KIGALI",
										generalAssembly_id: 635,
									},
									{
										id: 9610,
										name: "Kigarama Court of Appeal ",
										generalAssembly_id: 635,
									},
									{
										id: 11143,
										name: "Kigarama Gacaca court of sector ",
										generalAssembly_id: 635,
									},
								],
							},
							{
								id: 636,
								name: "Kimisange",
								sector_id: 30307,
								courtOfCells: [
									{
										id: 3466,
										name: "BWERANKOLI",
										generalAssembly_id: 636,
									},
									{
										id: 3467,
										name: "NYARURAMA",
										generalAssembly_id: 636,
									},
									{
										id: 3468,
										name: "NYENYERI",
										generalAssembly_id: 636,
									},
									{
										id: 3469,
										name: "REBERO",
										generalAssembly_id: 636,
									},
									{
										id: 9611,
										name: "Kimisange Court of Appeal ",
										generalAssembly_id: 636,
									},
									{
										id: 11144,
										name: "Kimisange Gacaca court of sector ",
										generalAssembly_id: 636,
									},
								],
							},
						],
					},
					{
						id: 30308,
						name: "Masaka",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030806,
								name: "Rusheshe",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030805,
								name: "Mbabe",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030804,
								name: "Gitaraga",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030803,
								name: "Gako",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030802,
								name: "Cyimo",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030801,
								name: "Ayabaraya",
								sector_id: 30308,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 637,
								name: "Ayabaraya",
								sector_id: 30308,
								courtOfCells: [
									{
										id: 3470,
										name: "AYABARAYA",
										generalAssembly_id: 637,
									},
									{
										id: 3471,
										name: "GIHUKE",
										generalAssembly_id: 637,
									},
									{
										id: 3472,
										name: "KINGUMBA",
										generalAssembly_id: 637,
									},
									{
										id: 3473,
										name: "NYAMYIJIMA",
										generalAssembly_id: 637,
									},
									{
										id: 3474,
										name: "RUYAGA",
										generalAssembly_id: 637,
									},
									{
										id: 9612,
										name: "Ayabaraya Court of Appeal ",
										generalAssembly_id: 637,
									},
									{
										id: 11145,
										name: "Ayabaraya Gacaca court of sector ",
										generalAssembly_id: 637,
									},
								],
							},
							{
								id: 638,
								name: "Masaka",
								sector_id: 30308,
								courtOfCells: [
									{
										id: 3475,
										name: "GAKO",
										generalAssembly_id: 638,
									},
									{
										id: 3476,
										name: "GITARAGA",
										generalAssembly_id: 638,
									},
									{
										id: 3477,
										name: "CYIMO",
										generalAssembly_id: 638,
									},
									{
										id: 3478,
										name: "NYAKAGUNGA",
										generalAssembly_id: 638,
									},
									{
										id: 3479,
										name: "RUHANGA",
										generalAssembly_id: 638,
									},
									{
										id: 3480,
										name: "RWINTARE",
										generalAssembly_id: 638,
									},
									{
										id: 9613,
										name: "Masaka Court of Appeal ",
										generalAssembly_id: 638,
									},
									{
										id: 11146,
										name: "Masaka Gacaca court of sector ",
										generalAssembly_id: 638,
									},
								],
							},
							{
								id: 639,
								name: "Rusheshe",
								sector_id: 30308,
								courtOfCells: [
									{
										id: 3481,
										name: "CYANKONGI",
										generalAssembly_id: 639,
									},
									{
										id: 3482,
										name: "KAGESE",
										generalAssembly_id: 639,
									},
									{
										id: 3483,
										name: "KAMASHASHI",
										generalAssembly_id: 639,
									},
									{
										id: 3484,
										name: "KANYETABI",
										generalAssembly_id: 639,
									},
									{
										id: 3485,
										name: "MBABE",
										generalAssembly_id: 639,
									},
									{
										id: 3486,
										name: "MURAMBI",
										generalAssembly_id: 639,
									},
									{
										id: 9614,
										name: "Rusheshe Court of Appeal ",
										generalAssembly_id: 639,
									},
									{
										id: 11147,
										name: "Rusheshe Gacaca court of sector ",
										generalAssembly_id: 639,
									},
								],
							},
						],
					},
					{
						id: 30309,
						name: "Niboye",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3030903,
								name: "Nyakabanda",
								sector_id: 30309,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030902,
								name: "Niboye",
								sector_id: 30309,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3030901,
								name: "Gatare",
								sector_id: 30309,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 640,
								name: "Niboye",
								sector_id: 30309,
								courtOfCells: [
									{
										id: 3487,
										name: "BYIMANA",
										generalAssembly_id: 640,
									},
									{
										id: 3488,
										name: "GATARE",
										generalAssembly_id: 640,
									},
									{
										id: 3489,
										name: "NIBOYE",
										generalAssembly_id: 640,
									},
									{
										id: 3490,
										name: "NYAKABANDA I",
										generalAssembly_id: 640,
									},
									{
										id: 3491,
										name: "NYAKABANDA II",
										generalAssembly_id: 640,
									},
									{
										id: 3492,
										name: "SAHARA",
										generalAssembly_id: 640,
									},
									{
										id: 9615,
										name: "Niboye Court of Appeal ",
										generalAssembly_id: 640,
									},
									{
										id: 11148,
										name: "Niboye Gacaca court of sector ",
										generalAssembly_id: 640,
									},
								],
							},
						],
					},
					{
						id: 30310,
						name: "Nyarugunga",
						district_id: 303,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3031003,
								name: "Rwimbogo",
								sector_id: 30310,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3031002,
								name: "Nonko",
								sector_id: 30310,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3031001,
								name: "Kamashashi ",
								sector_id: 30310,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 641,
								name: "Nyarugunga ",
								sector_id: 30310,
								courtOfCells: [
									{
										id: 3493,
										name: "GIHANGA",
										generalAssembly_id: 641,
									},
									{
										id: 3494,
										name: "KAMASHASHI",
										generalAssembly_id: 641,
									},
									{
										id: 3495,
										name: "NONKO",
										generalAssembly_id: 641,
									},
									{
										id: 3496,
										name: "NYARUGUNGA",
										generalAssembly_id: 641,
									},
									{
										id: 3497,
										name: "RWIMBOGO",
										generalAssembly_id: 641,
									},
									{
										id: 9616,
										name: "Nyarugunga  Court of Appeal ",
										generalAssembly_id: 641,
									},
									{
										id: 11149,
										name: "Nyarugunga  Gacaca court of sector ",
										generalAssembly_id: 641,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 301,
				name: "Nyarugenge",
				province_id: 3,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 30101,
						name: "Gitega",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010106,
								name: "Kora",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010105,
								name: "Kinyange",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010104,
								name: "Kigarama",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010103,
								name: "Gacyamo",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010102,
								name: "Akabeza",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010101,
								name: "Akabahizi",
								sector_id: 30101,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1157,
								name: "Cyahafi",
								sector_id: 30101,
								courtOfCells: [
									{
										id: 6681,
										name: "VUGIZO",
										generalAssembly_id: 1157,
									},
									{
										id: 6682,
										name: "KORA",
										generalAssembly_id: 1157,
									},
									{
										id: 6683,
										name: "RUGANO",
										generalAssembly_id: 1157,
									},
									{
										id: 6684,
										name: "UMUCYO",
										generalAssembly_id: 1157,
									},
									{
										id: 6685,
										name: "AKABAHIZI",
										generalAssembly_id: 1157,
									},
									{
										id: 6686,
										name: "AKABEZA",
										generalAssembly_id: 1157,
									},
									{
										id: 6687,
										name: "GITWA",
										generalAssembly_id: 1157,
									},
									{
										id: 10132,
										name: "Cyahafi Court of Appeal ",
										generalAssembly_id: 1157,
									},
									{
										id: 11665,
										name: "Cyahafi Gacaca court of sector ",
										generalAssembly_id: 1157,
									},
								],
							},
							{
								id: 1158,
								name: "Gitega",
								sector_id: 30101,
								courtOfCells: [
									{
										id: 6688,
										name: "URUMULI",
										generalAssembly_id: 1158,
									},
									{
										id: 6689,
										name: "KINYANGE",
										generalAssembly_id: 1158,
									},
									{
										id: 6690,
										name: "KIGARAMA",
										generalAssembly_id: 1158,
									},
									{
										id: 6691,
										name: "INGELI",
										generalAssembly_id: 1158,
									},
									{
										id: 6692,
										name: "MUDUHA",
										generalAssembly_id: 1158,
									},
									{
										id: 6693,
										name: "GACYAMO",
										generalAssembly_id: 1158,
									},
									{
										id: 10133,
										name: "Gitega Court of Appeal ",
										generalAssembly_id: 1158,
									},
									{
										id: 11666,
										name: "Gitega Gacaca court of sector ",
										generalAssembly_id: 1158,
									},
								],
							},
						],
					},
					{
						id: 30102,
						name: "Kanyinya",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010203,
								name: "Taba",
								sector_id: 30102,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010202,
								name: "Nzove",
								sector_id: 30102,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010201,
								name: "Nyamweru",
								sector_id: 30102,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1159,
								name: "Kanyinya",
								sector_id: 30102,
								courtOfCells: [
									{
										id: 6694,
										name: "MUBUGA",
										generalAssembly_id: 1159,
									},
									{
										id: 6695,
										name: "NYAMWERU",
										generalAssembly_id: 1159,
									},
									{
										id: 6696,
										name: "NYARURAMA",
										generalAssembly_id: 1159,
									},
									{
										id: 6697,
										name: "NYARUSANGE",
										generalAssembly_id: 1159,
									},
									{
										id: 6698,
										name: "GATARE",
										generalAssembly_id: 1159,
									},
									{
										id: 6699,
										name: "KAGARAMIRA",
										generalAssembly_id: 1159,
									},
									{
										id: 6700,
										name: "NYAKIRAMBI",
										generalAssembly_id: 1159,
									},
									{
										id: 6701,
										name: "RUHENGERI",
										generalAssembly_id: 1159,
									},
									{
										id: 6702,
										name: "TABA",
										generalAssembly_id: 1159,
									},
									{
										id: 6703,
										name: "NGENDO",
										generalAssembly_id: 1159,
									},
									{
										id: 6704,
										name: "RWAKIVUMU",
										generalAssembly_id: 1159,
									},
									{
										id: 10134,
										name: "Kanyinya Court of Appeal ",
										generalAssembly_id: 1159,
									},
									{
										id: 11667,
										name: "Kanyinya Gacaca court of sector ",
										generalAssembly_id: 1159,
									},
								],
							},
							{
								id: 1160,
								name: "Nzove",
								sector_id: 30102,
								courtOfCells: [
									{
										id: 6705,
										name: "KAGASA",
										generalAssembly_id: 1160,
									},
									{
										id: 6706,
										name: "NYABIHU",
										generalAssembly_id: 1160,
									},
									{
										id: 6707,
										name: "RUTAGARA",
										generalAssembly_id: 1160,
									},
									{
										id: 6708,
										name: "BWIZA",
										generalAssembly_id: 1160,
									},
									{
										id: 6709,
										name: "BIBUNGO",
										generalAssembly_id: 1160,
									},
									{
										id: 6710,
										name: "RUYENZI",
										generalAssembly_id: 1160,
									},
									{
										id: 10135,
										name: "Nzove Court of Appeal ",
										generalAssembly_id: 1160,
									},
									{
										id: 11668,
										name: "Nzove Gacaca court of sector ",
										generalAssembly_id: 1160,
									},
								],
							},
						],
					},
					{
						id: 30103,
						name: "Kigali",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010305,
								name: "Rwesero",
								sector_id: 30103,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010304,
								name: "Ruriba",
								sector_id: 30103,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010303,
								name: "Nyabugogo",
								sector_id: 30103,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010302,
								name: "Mwendo",
								sector_id: 30103,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010301,
								name: "Kigali",
								sector_id: 30103,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1161,
								name: "Kigali",
								sector_id: 30103,
								courtOfCells: [
									{
										id: 6711,
										name: "KIGALI",
										generalAssembly_id: 1161,
									},
									{
										id: 6712,
										name: "NYABUGOGO",
										generalAssembly_id: 1161,
									},
									{
										id: 6713,
										name: "MUGANZA",
										generalAssembly_id: 1161,
									},
									{
										id: 6714,
										name: "KARAMA",
										generalAssembly_id: 1161,
									},
									{
										id: 6715,
										name: "RUHARABUGE",
										generalAssembly_id: 1161,
									},
									{
										id: 6716,
										name: "KIRUHURA",
										generalAssembly_id: 1161,
									},
									{
										id: 6717,
										name: "GISENGA",
										generalAssembly_id: 1161,
									},
									{
										id: 10136,
										name: "Kigali Court of Appeal ",
										generalAssembly_id: 1161,
									},
									{
										id: 11669,
										name: "Kigali Gacaca court of sector ",
										generalAssembly_id: 1161,
									},
								],
							},
							{
								id: 1162,
								name: "Mwendo",
								sector_id: 30103,
								courtOfCells: [
									{
										id: 6718,
										name: "BIRAMBO",
										generalAssembly_id: 1162,
									},
									{
										id: 6719,
										name: "MWENDO",
										generalAssembly_id: 1162,
									},
									{
										id: 6720,
										name: "RURIBA",
										generalAssembly_id: 1162,
									},
									{
										id: 6721,
										name: "KARAMBO",
										generalAssembly_id: 1162,
									},
									{
										id: 10137,
										name: "Mwendo Court of Appeal ",
										generalAssembly_id: 1162,
									},
									{
										id: 11670,
										name: "Mwendo Gacaca court of sector ",
										generalAssembly_id: 1162,
									},
								],
							},
							{
								id: 1163,
								name: "Rwesero",
								sector_id: 30103,
								courtOfCells: [
									{
										id: 6722,
										name: "KANYABAMI",
										generalAssembly_id: 1163,
									},
									{
										id: 6723,
										name: "RWEZA",
										generalAssembly_id: 1163,
									},
									{
										id: 6724,
										name: "MUSIMBA",
										generalAssembly_id: 1163,
									},
									{
										id: 6725,
										name: "RWESERO",
										generalAssembly_id: 1163,
									},
									{
										id: 10138,
										name: "Rwesero Court of Appeal ",
										generalAssembly_id: 1163,
									},
									{
										id: 11671,
										name: "Rwesero Gacaca court of sector ",
										generalAssembly_id: 1163,
									},
								],
							},
						],
					},
					{
						id: 30104,
						name: "Kimisagara",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010403,
								name: "Kimisagara",
								sector_id: 30104,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010402,
								name: "Katabaro",
								sector_id: 30104,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010401,
								name: "Kamuhoza",
								sector_id: 30104,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1164,
								name: "Kimisagara",
								sector_id: 30104,
								courtOfCells: [
									{
										id: 6726,
										name: "KIMISAGARA",
										generalAssembly_id: 1164,
									},
									{
										id: 6727,
										name: "KATABARO",
										generalAssembly_id: 1164,
									},
									{
										id: 6728,
										name: "KAMUHOZA",
										generalAssembly_id: 1164,
									},
									{
										id: 10139,
										name: "Kimisagara Court of Appeal ",
										generalAssembly_id: 1164,
									},
									{
										id: 11672,
										name: "Kimisagara Gacaca court of sector ",
										generalAssembly_id: 1164,
									},
								],
							},
						],
					},
					{
						id: 30105,
						name: "Mageregere",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010507,
								name: "Runzenze",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010506,
								name: "Nyarurenzi",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010505,
								name: "Nyarufunzo",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010504,
								name: "Ntungamo",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010503,
								name: "Mataba",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010502,
								name: "Kavumu",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010501,
								name: "Kankuba",
								sector_id: 30105,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1165,
								name: "Burema",
								sector_id: 30105,
								courtOfCells: [
									{
										id: 6729,
										name: "MATABA",
										generalAssembly_id: 1165,
									},
									{
										id: 6730,
										name: "GAHOMBO",
										generalAssembly_id: 1165,
									},
									{
										id: 6731,
										name: "RUSHUBI",
										generalAssembly_id: 1165,
									},
									{
										id: 6732,
										name: "BUREMA",
										generalAssembly_id: 1165,
									},
									{
										id: 6733,
										name: "GIKUYU",
										generalAssembly_id: 1165,
									},
									{
										id: 10140,
										name: "Burema Court of Appeal ",
										generalAssembly_id: 1165,
									},
									{
										id: 11673,
										name: "Burema Gacaca court of sector ",
										generalAssembly_id: 1165,
									},
								],
							},
							{
								id: 1166,
								name: "Butamwa",
								sector_id: 30105,
								courtOfCells: [
									{
										id: 6734,
										name: "KAVUMU",
										generalAssembly_id: 1166,
									},
									{
										id: 6735,
										name: "KANKUBA",
										generalAssembly_id: 1166,
									},
									{
										id: 6736,
										name: "MAYA",
										generalAssembly_id: 1166,
									},
									{
										id: 6737,
										name: "MUBURA",
										generalAssembly_id: 1166,
									},
									{
										id: 6738,
										name: "KARUKINA",
										generalAssembly_id: 1166,
									},
									{
										id: 6739,
										name: "RUGENDABALI",
										generalAssembly_id: 1166,
									},
									{
										id: 6740,
										name: "RUBETE",
										generalAssembly_id: 1166,
									},
									{
										id: 6741,
										name: "NYARUFUNZO",
										generalAssembly_id: 1166,
									},
									{
										id: 6742,
										name: "BUTAMWA",
										generalAssembly_id: 1166,
									},
									{
										id: 6743,
										name: "BUYE",
										generalAssembly_id: 1166,
									},
									{
										id: 10141,
										name: "Butamwa Court of Appeal ",
										generalAssembly_id: 1166,
									},
									{
										id: 11674,
										name: "Butamwa Gacaca court of sector ",
										generalAssembly_id: 1166,
									},
								],
							},
							{
								id: 1167,
								name: "Nyarubande",
								sector_id: 30105,
								courtOfCells: [
									{
										id: 6744,
										name: "UWURUGENGE",
										generalAssembly_id: 1167,
									},
									{
										id: 6745,
										name: "RUBUNGO",
										generalAssembly_id: 1167,
									},
									{
										id: 6746,
										name: "NYARUBANDE",
										generalAssembly_id: 1167,
									},
									{
										id: 6747,
										name: "NYARURENZI",
										generalAssembly_id: 1167,
									},
									{
										id: 6748,
										name: "NTUNGAMO",
										generalAssembly_id: 1167,
									},
									{
										id: 6749,
										name: "RUNZENZE",
										generalAssembly_id: 1167,
									},
									{
										id: 6750,
										name: "NYABIRONDO",
										generalAssembly_id: 1167,
									},
									{
										id: 6751,
										name: "MPANGA",
										generalAssembly_id: 1167,
									},
									{
										id: 10142,
										name: "Nyarubande Court of Appeal ",
										generalAssembly_id: 1167,
									},
									{
										id: 11675,
										name: "Nyarubande Gacaca court of sector ",
										generalAssembly_id: 1167,
									},
								],
							},
						],
					},
					{
						id: 30106,
						name: "Muhima",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010607,
								name: "Ubumwe",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010606,
								name: "Tetero",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010605,
								name: "Rugenge",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010604,
								name: "Nyabugogo",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010603,
								name: "Kabeza",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010602,
								name: "Kabasengerezi",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010601,
								name: "Amahoro",
								sector_id: 30106,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1168,
								name: "Muhima",
								sector_id: 30106,
								courtOfCells: [
									{
										id: 6752,
										name: "TETERO",
										generalAssembly_id: 1168,
									},
									{
										id: 6753,
										name: "KABEZA",
										generalAssembly_id: 1168,
									},
									{
										id: 6754,
										name: "KABAKENE",
										generalAssembly_id: 1168,
									},
									{
										id: 6755,
										name: "RUHURURA",
										generalAssembly_id: 1168,
									},
									{
										id: 6756,
										name: "NYABUGOGO",
										generalAssembly_id: 1168,
									},
									{
										id: 6757,
										name: "KABIRIZI",
										generalAssembly_id: 1168,
									},
									{
										id: 6758,
										name: "KANUNGA",
										generalAssembly_id: 1168,
									},
									{
										id: 10143,
										name: "Muhima Court of Appeal ",
										generalAssembly_id: 1168,
									},
									{
										id: 11676,
										name: "Muhima Gacaca court of sector ",
										generalAssembly_id: 1168,
									},
								],
							},
							{
								id: 1169,
								name: "Rugenge",
								sector_id: 30106,
								courtOfCells: [
									{
										id: 6759,
										name: "NDJAMENA",
										generalAssembly_id: 1169,
									},
									{
										id: 6760,
										name: "UBUMANZI",
										generalAssembly_id: 1169,
									},
									{
										id: 6761,
										name: "KABASENGEREZI",
										generalAssembly_id: 1169,
									},
									{
										id: 6762,
										name: "RUGENGE",
										generalAssembly_id: 1169,
									},
									{
										id: 6763,
										name: "AMAHORO",
										generalAssembly_id: 1169,
									},
									{
										id: 6764,
										name: "UBUMWE",
										generalAssembly_id: 1169,
									},
									{
										id: 6765,
										name: "BWAHIRIMBA",
										generalAssembly_id: 1169,
									},
									{
										id: 10144,
										name: "Rugenge Court of Appeal ",
										generalAssembly_id: 1169,
									},
									{
										id: 11677,
										name: "Rugenge Gacaca court of sector ",
										generalAssembly_id: 1169,
									},
								],
							},
						],
					},
					{
						id: 30107,
						name: "Nyakabanda",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010704,
								name: "Nyakabanda Ii",
								sector_id: 30107,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010703,
								name: "Nyakabanda I",
								sector_id: 30107,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010702,
								name: "Munanira Ii",
								sector_id: 30107,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010701,
								name: "Munanira I",
								sector_id: 30107,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1170,
								name: "Nyakabanda",
								sector_id: 30107,
								courtOfCells: [
									{
										id: 6766,
										name: "MUNANIRA I",
										generalAssembly_id: 1170,
									},
									{
										id: 6767,
										name: "NYAKABANDA I",
										generalAssembly_id: 1170,
									},
									{
										id: 6768,
										name: "MUNANIRA II",
										generalAssembly_id: 1170,
									},
									{
										id: 6769,
										name: "NYAKABANDA II",
										generalAssembly_id: 1170,
									},
									{
										id: 10145,
										name: "Nyakabanda Court of Appeal ",
										generalAssembly_id: 1170,
									},
									{
										id: 11678,
										name: "Nyakabanda Gacaca court of sector ",
										generalAssembly_id: 1170,
									},
								],
							},
						],
					},
					{
						id: 30108,
						name: "Nyamirambo",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010804,
								name: "Rugarama",
								sector_id: 30108,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010803,
								name: "Mumena",
								sector_id: 30108,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010802,
								name: "Gasharu ",
								sector_id: 30108,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010801,
								name: "Cyivugiza",
								sector_id: 30108,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1171,
								name: "Nyamirambo",
								sector_id: 30108,
								courtOfCells: [
									{
										id: 6770,
										name: "MUMENA",
										generalAssembly_id: 1171,
									},
									{
										id: 6771,
										name: "GATARE",
										generalAssembly_id: 1171,
									},
									{
										id: 6772,
										name: "NYABITARE",
										generalAssembly_id: 1171,
									},
									{
										id: 6773,
										name: "CYIVUGIZA",
										generalAssembly_id: 1171,
									},
									{
										id: 10146,
										name: "Nyamirambo Court of Appeal ",
										generalAssembly_id: 1171,
									},
									{
										id: 11679,
										name: "Nyamirambo Gacaca court of sector ",
										generalAssembly_id: 1171,
									},
								],
							},
							{
								id: 1172,
								name: "Akumunigo",
								sector_id: 30108,
								courtOfCells: [
									{
										id: 6774,
										name: "RWARUTABURA",
										generalAssembly_id: 1172,
									},
									{
										id: 6775,
										name: "AKUMUNIGO",
										generalAssembly_id: 1172,
									},
									{
										id: 6776,
										name: "RUGARAMA",
										generalAssembly_id: 1172,
									},
									{
										id: 10147,
										name: "Akumunigo Court of Appeal ",
										generalAssembly_id: 1172,
									},
									{
										id: 11680,
										name: "Akumunigo Gacaca court of sector ",
										generalAssembly_id: 1172,
									},
								],
							},
							{
								id: 1173,
								name: "Rugarama ",
								sector_id: 30108,
								courtOfCells: [
									{
										id: 6777,
										name: "KABAHIMA",
										generalAssembly_id: 1173,
									},
									{
										id: 6778,
										name: "GASHARU",
										generalAssembly_id: 1173,
									},
									{
										id: 10148,
										name: "Rugarama  Court of Appeal ",
										generalAssembly_id: 1173,
									},
									{
										id: 11681,
										name: "Rugarama  Gacaca court of sector ",
										generalAssembly_id: 1173,
									},
								],
							},
						],
					},
					{
						id: 30109,
						name: "Nyarugenge",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3010904,
								name: "Rwampara",
								sector_id: 30109,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010903,
								name: "Kiyovu",
								sector_id: 30109,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010902,
								name: "Biryogo",
								sector_id: 30109,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3010901,
								name: "Agatare",
								sector_id: 30109,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1174,
								name: "Biryogo",
								sector_id: 30109,
								courtOfCells: [
									{
										id: 6779,
										name: "RUGUNGA",
										generalAssembly_id: 1174,
									},
									{
										id: 6780,
										name: "AGATARE",
										generalAssembly_id: 1174,
									},
									{
										id: 6781,
										name: "RWAMPARA",
										generalAssembly_id: 1174,
									},
									{
										id: 6782,
										name: "GABIRO",
										generalAssembly_id: 1174,
									},
									{
										id: 6783,
										name: "BIRYOGO",
										generalAssembly_id: 1174,
									},
									{
										id: 10149,
										name: "Biryogo Court of Appeal ",
										generalAssembly_id: 1174,
									},
									{
										id: 11682,
										name: "Biryogo Gacaca court of sector ",
										generalAssembly_id: 1174,
									},
								],
							},
							{
								id: 1175,
								name: "Nyarugenge",
								sector_id: 30109,
								courtOfCells: [
									{
										id: 6784,
										name: "QUARTIER COMMERCIAL",
										generalAssembly_id: 1175,
									},
									{
										id: 6785,
										name: "KIYOVU",
										generalAssembly_id: 1175,
									},
									{
										id: 10150,
										name: "Nyarugenge Court of Appeal ",
										generalAssembly_id: 1175,
									},
									{
										id: 11683,
										name: "Nyarugenge Gacaca court of sector ",
										generalAssembly_id: 1175,
									},
								],
							},
						],
					},
					{
						id: 30110,
						name: "Rwezamenyo",
						district_id: 301,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 3011004,
								name: "Rwezamenyo Ii",
								sector_id: 30110,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3011003,
								name: "Rwezamenyo I",
								sector_id: 30110,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3011002,
								name: "Kabuguru Ii",
								sector_id: 30110,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 3011001,
								name: "Kabuguru I",
								sector_id: 30110,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1176,
								name: "Rwezamenyo",
								sector_id: 30110,
								courtOfCells: [
									{
										id: 6786,
										name: "RWEZAMENYO I",
										generalAssembly_id: 1176,
									},
									{
										id: 6787,
										name: "KABUGURU I",
										generalAssembly_id: 1176,
									},
									{
										id: 6788,
										name: "RWEZAMENYO II",
										generalAssembly_id: 1176,
									},
									{
										id: 6789,
										name: "KABUGURU II",
										generalAssembly_id: 1176,
									},
									{
										id: 10151,
										name: "Rwezamenyo Court of Appeal ",
										generalAssembly_id: 1176,
									},
									{
										id: 11684,
										name: "Rwezamenyo Gacaca court of sector ",
										generalAssembly_id: 1176,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		id: 1,
		name: "South",
		createdAt: "2021-05-03T07:57:21.181Z",
		updatedAt: "2021-05-03T07:57:21.181Z",
		districts: [
			{
				id: 102,
				name: "Gisagara",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10201,
						name: "Gikonko",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020104,
								name: "Mbogo",
								sector_id: 10201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020103,
								name: "Gikonko",
								sector_id: 10201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020102,
								name: "Gasagara",
								sector_id: 10201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020101,
								name: "Cyiri",
								sector_id: 10201,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 320,
								name: "Curusi",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2043,
										name: "CURUSI",
										generalAssembly_id: 320,
									},
									{
										id: 2044,
										name: "KIMPUNGA",
										generalAssembly_id: 320,
									},
									{
										id: 2045,
										name: "MURAMBI",
										generalAssembly_id: 320,
									},
									{
										id: 2046,
										name: "MUSAMBI",
										generalAssembly_id: 320,
									},
									{
										id: 9295,
										name: "Curusi Court of Appeal ",
										generalAssembly_id: 320,
									},
									{
										id: 10828,
										name: "Curusi Gacaca court of sector ",
										generalAssembly_id: 320,
									},
								],
							},
							{
								id: 321,
								name: "Gikonko",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2047,
										name: "GAHABWA",
										generalAssembly_id: 321,
									},
									{
										id: 2048,
										name: "MANYINYA",
										generalAssembly_id: 321,
									},
									{
										id: 2049,
										name: "RUNYINYA",
										generalAssembly_id: 321,
									},
									{
										id: 9296,
										name: "Gikonko Court of Appeal ",
										generalAssembly_id: 321,
									},
									{
										id: 10829,
										name: "Gikonko Gacaca court of sector ",
										generalAssembly_id: 321,
									},
								],
							},
							{
								id: 322,
								name: "Mbogo",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2050,
										name: "BUREMERA",
										generalAssembly_id: 322,
									},
									{
										id: 2051,
										name: "BUTEGAMO",
										generalAssembly_id: 322,
									},
									{
										id: 2052,
										name: "RWINTARE",
										generalAssembly_id: 322,
									},
									{
										id: 9297,
										name: "Mbogo Court of Appeal ",
										generalAssembly_id: 322,
									},
									{
										id: 10830,
										name: "Mbogo Gacaca court of sector ",
										generalAssembly_id: 322,
									},
								],
							},
							{
								id: 323,
								name: "Mugusa",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2053,
										name: "BIBUNGO",
										generalAssembly_id: 323,
									},
									{
										id: 2054,
										name: "KAREHE",
										generalAssembly_id: 323,
									},
									{
										id: 2055,
										name: "MUYANGE",
										generalAssembly_id: 323,
									},
									{
										id: 2056,
										name: "REMERA",
										generalAssembly_id: 323,
									},
									{
										id: 9298,
										name: "Mugusa Court of Appeal ",
										generalAssembly_id: 323,
									},
									{
										id: 10831,
										name: "Mugusa Gacaca court of sector ",
										generalAssembly_id: 323,
									},
								],
							},
							{
								id: 324,
								name: "Nyarubuye",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2057,
										name: "AGASENYI",
										generalAssembly_id: 324,
									},
									{
										id: 2058,
										name: "KARUKAMBIRA",
										generalAssembly_id: 324,
									},
									{
										id: 2059,
										name: "UMUBEZI",
										generalAssembly_id: 324,
									},
									{
										id: 9299,
										name: "Nyarubuye Court of Appeal ",
										generalAssembly_id: 324,
									},
									{
										id: 10832,
										name: "Nyarubuye Gacaca court of sector ",
										generalAssembly_id: 324,
									},
								],
							},
							{
								id: 325,
								name: "Sanzu",
								sector_id: 10201,
								courtOfCells: [
									{
										id: 2060,
										name: "CYENDAJURU",
										generalAssembly_id: 325,
									},
									{
										id: 2061,
										name: "KIGITEGA",
										generalAssembly_id: 325,
									},
									{
										id: 2062,
										name: "KINYANA",
										generalAssembly_id: 325,
									},
									{
										id: 9300,
										name: "Sanzu Court of Appeal ",
										generalAssembly_id: 325,
									},
									{
										id: 10833,
										name: "Sanzu Gacaca court of sector ",
										generalAssembly_id: 325,
									},
								],
							},
						],
					},
					{
						id: 10202,
						name: "Gishubi",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020204,
								name: "Nyeranzi",
								sector_id: 10202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020203,
								name: "Nyakibungo",
								sector_id: 10202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020202,
								name: "Nyabitare",
								sector_id: 10202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020201,
								name: "Gabiro",
								sector_id: 10202,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 326,
								name: "Muduha",
								sector_id: 10202,
								courtOfCells: [
									{
										id: 2063,
										name: "GIKUYO",
										generalAssembly_id: 326,
									},
									{
										id: 2064,
										name: "MUDUHA",
										generalAssembly_id: 326,
									},
									{
										id: 2065,
										name: "MUYINZA",
										generalAssembly_id: 326,
									},
									{
										id: 9301,
										name: "Muduha Court of Appeal ",
										generalAssembly_id: 326,
									},
									{
										id: 10834,
										name: "Muduha Gacaca court of sector ",
										generalAssembly_id: 326,
									},
								],
							},
							{
								id: 327,
								name: "Nyeranzi ",
								sector_id: 10202,
								courtOfCells: [
									{
										id: 2066,
										name: "KAGOMA",
										generalAssembly_id: 327,
									},
									{
										id: 2067,
										name: "GISHYA",
										generalAssembly_id: 327,
									},
									{
										id: 9302,
										name: "Nyeranzi  Court of Appeal ",
										generalAssembly_id: 327,
									},
									{
										id: 10835,
										name: "Nyeranzi  Gacaca court of sector ",
										generalAssembly_id: 327,
									},
								],
							},
							{
								id: 328,
								name: "Gishubi ",
								sector_id: 10202,
								courtOfCells: [
									{
										id: 2068,
										name: "NYUNDO",
										generalAssembly_id: 328,
									},
									{
										id: 2069,
										name: "ZAMWE",
										generalAssembly_id: 328,
									},
									{
										id: 9303,
										name: "Gishubi  Court of Appeal ",
										generalAssembly_id: 328,
									},
									{
										id: 10836,
										name: "Gishubi  Gacaca court of sector ",
										generalAssembly_id: 328,
									},
								],
							},
							{
								id: 329,
								name: "Nyabitare ",
								sector_id: 10202,
								courtOfCells: [
									{
										id: 2070,
										name: "HEMBA",
										generalAssembly_id: 329,
									},
									{
										id: 2071,
										name: "MUTOBO",
										generalAssembly_id: 329,
									},
									{
										id: 2072,
										name: "NYIRAKANYWERO",
										generalAssembly_id: 329,
									},
									{
										id: 9304,
										name: "Nyabitare  Court of Appeal ",
										generalAssembly_id: 329,
									},
									{
										id: 10837,
										name: "Nyabitare  Gacaca court of sector ",
										generalAssembly_id: 329,
									},
								],
							},
							{
								id: 330,
								name: "Nyagahuru_II ",
								sector_id: 10202,
								courtOfCells: [
									{
										id: 2073,
										name: "MUNYINYA",
										generalAssembly_id: 330,
									},
									{
										id: 2074,
										name: "NYAGAHURU",
										generalAssembly_id: 330,
									},
									{
										id: 9305,
										name: "Nyagahuru_II  Court of Appeal ",
										generalAssembly_id: 330,
									},
									{
										id: 10838,
										name: "Nyagahuru_II  Gacaca court of sector ",
										generalAssembly_id: 330,
									},
								],
							},
						],
					},
					{
						id: 10203,
						name: "Kansi",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020304,
								name: "Umunini",
								sector_id: 10203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020303,
								name: "Sabusaro",
								sector_id: 10203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020302,
								name: "Bwiza",
								sector_id: 10203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020301,
								name: "Akaboti",
								sector_id: 10203,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 331,
								name: "Gikore ",
								sector_id: 10203,
								courtOfCells: [
									{
										id: 2075,
										name: "NYARUNAZI",
										generalAssembly_id: 331,
									},
									{
										id: 2076,
										name: "RUHANGAYE",
										generalAssembly_id: 331,
									},
									{
										id: 2077,
										name: "RWIMBOGO",
										generalAssembly_id: 331,
									},
									{
										id: 9306,
										name: "Gikore  Court of Appeal ",
										generalAssembly_id: 331,
									},
									{
										id: 10839,
										name: "Gikore  Gacaca court of sector ",
										generalAssembly_id: 331,
									},
								],
							},
							{
								id: 332,
								name: "Kansi ",
								sector_id: 10203,
								courtOfCells: [
									{
										id: 2078,
										name: "GATARE",
										generalAssembly_id: 332,
									},
									{
										id: 2079,
										name: "KANSEREGE",
										generalAssembly_id: 332,
									},
									{
										id: 2080,
										name: "RUGARAMA",
										generalAssembly_id: 332,
									},
									{
										id: 9307,
										name: "Kansi  Court of Appeal ",
										generalAssembly_id: 332,
									},
									{
										id: 10840,
										name: "Kansi  Gacaca court of sector ",
										generalAssembly_id: 332,
									},
								],
							},
							{
								id: 333,
								name: "Linganwe ",
								sector_id: 10203,
								courtOfCells: [
									{
										id: 2081,
										name: "KINYONZWE",
										generalAssembly_id: 333,
									},
									{
										id: 2082,
										name: "MWANGANSHURO",
										generalAssembly_id: 333,
									},
									{
										id: 2083,
										name: "NKOMERO",
										generalAssembly_id: 333,
									},
									{
										id: 2084,
										name: "NYARUKURAZO",
										generalAssembly_id: 333,
									},
									{
										id: 2085,
										name: "RUBONA",
										generalAssembly_id: 333,
									},
									{
										id: 9308,
										name: "Linganwe  Court of Appeal ",
										generalAssembly_id: 333,
									},
									{
										id: 10841,
										name: "Linganwe  Gacaca court of sector ",
										generalAssembly_id: 333,
									},
								],
							},
							{
								id: 334,
								name: "Nyange ",
								sector_id: 10203,
								courtOfCells: [
									{
										id: 2086,
										name: "KAMUGANI",
										generalAssembly_id: 334,
									},
									{
										id: 2087,
										name: "KIGARAMA",
										generalAssembly_id: 334,
									},
									{
										id: 2088,
										name: "RYANKORA",
										generalAssembly_id: 334,
									},
									{
										id: 9309,
										name: "Nyange  Court of Appeal ",
										generalAssembly_id: 334,
									},
									{
										id: 10842,
										name: "Nyange  Gacaca court of sector ",
										generalAssembly_id: 334,
									},
								],
							},
							{
								id: 335,
								name: "Nyaruhengeri ",
								sector_id: 10203,
								courtOfCells: [
									{
										id: 2089,
										name: "BUCYO",
										generalAssembly_id: 335,
									},
									{
										id: 2090,
										name: "GIHAMBO",
										generalAssembly_id: 335,
									},
									{
										id: 2091,
										name: "GITWA",
										generalAssembly_id: 335,
									},
									{
										id: 2092,
										name: "MUDOBORI",
										generalAssembly_id: 335,
									},
									{
										id: 2093,
										name: "MURAMBI",
										generalAssembly_id: 335,
									},
									{
										id: 9310,
										name: "Nyaruhengeri  Court of Appeal ",
										generalAssembly_id: 335,
									},
									{
										id: 10843,
										name: "Nyaruhengeri  Gacaca court of sector ",
										generalAssembly_id: 335,
									},
								],
							},
						],
					},
					{
						id: 10204,
						name: "Kibirizi",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020404,
								name: "Ruturo",
								sector_id: 10204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020403,
								name: "Muyira",
								sector_id: 10204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020402,
								name: "Kibirizi",
								sector_id: 10204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020401,
								name: "Duwani",
								sector_id: 10204,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 336,
								name: "Burashi ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2094,
										name: "BUHORO",
										generalAssembly_id: 336,
									},
									{
										id: 2095,
										name: "MURAMBI",
										generalAssembly_id: 336,
									},
									{
										id: 2096,
										name: "RWUYA",
										generalAssembly_id: 336,
									},
									{
										id: 9311,
										name: "Burashi  Court of Appeal ",
										generalAssembly_id: 336,
									},
									{
										id: 10844,
										name: "Burashi  Gacaca court of sector ",
										generalAssembly_id: 336,
									},
								],
							},
							{
								id: 337,
								name: "Karama ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2097,
										name: "AKIBIRARO",
										generalAssembly_id: 337,
									},
									{
										id: 2098,
										name: "KARENGERA",
										generalAssembly_id: 337,
									},
									{
										id: 9312,
										name: "Karama  Court of Appeal ",
										generalAssembly_id: 337,
									},
									{
										id: 10845,
										name: "Karama  Gacaca court of sector ",
										generalAssembly_id: 337,
									},
								],
							},
							{
								id: 338,
								name: "Kinazi ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2099,
										name: "MUYIRA",
										generalAssembly_id: 338,
									},
									{
										id: 2100,
										name: "NYAGISOZI",
										generalAssembly_id: 338,
									},
									{
										id: 9313,
										name: "Kinazi  Court of Appeal ",
										generalAssembly_id: 338,
									},
									{
										id: 10846,
										name: "Kinazi  Gacaca court of sector ",
										generalAssembly_id: 338,
									},
								],
							},
							{
								id: 339,
								name: "Kinteko ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2101,
										name: "DUWANE_MUBUGA",
										generalAssembly_id: 339,
									},
									{
										id: 2102,
										name: "KIVUMU",
										generalAssembly_id: 339,
									},
									{
										id: 9314,
										name: "Kinteko  Court of Appeal ",
										generalAssembly_id: 339,
									},
									{
										id: 10847,
										name: "Kinteko  Gacaca court of sector ",
										generalAssembly_id: 339,
									},
								],
							},
							{
								id: 340,
								name: "Kinyenyeri ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2103,
										name: "NYAGISASA",
										generalAssembly_id: 340,
									},
									{
										id: 2104,
										name: "ZIHARE",
										generalAssembly_id: 340,
									},
									{
										id: 9315,
										name: "Kinyenyeri  Court of Appeal ",
										generalAssembly_id: 340,
									},
									{
										id: 10848,
										name: "Kinyenyeri  Gacaca court of sector ",
										generalAssembly_id: 340,
									},
								],
							},
							{
								id: 341,
								name: "Muzenga ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2105,
										name: "NDUBA",
										generalAssembly_id: 341,
									},
									{
										id: 2106,
										name: "RUYANGE",
										generalAssembly_id: 341,
									},
									{
										id: 9316,
										name: "Muzenga  Court of Appeal ",
										generalAssembly_id: 341,
									},
									{
										id: 10849,
										name: "Muzenga  Gacaca court of sector ",
										generalAssembly_id: 341,
									},
								],
							},
							{
								id: 342,
								name: "Kibilizi ",
								sector_id: 10204,
								courtOfCells: [
									{
										id: 2107,
										name: "BURASI",
										generalAssembly_id: 342,
									},
									{
										id: 2108,
										name: "GISORORO",
										generalAssembly_id: 342,
									},
									{
										id: 2109,
										name: "GITWA",
										generalAssembly_id: 342,
									},
									{
										id: 2110,
										name: "HENENE",
										generalAssembly_id: 342,
									},
									{
										id: 2111,
										name: "RAMBA",
										generalAssembly_id: 342,
									},
									{
										id: 2112,
										name: "NYABIDUHA",
										generalAssembly_id: 342,
									},
									{
										id: 2113,
										name: "AKABANGA",
										generalAssembly_id: 342,
									},
									{
										id: 9317,
										name: "Kibilizi  Court of Appeal ",
										generalAssembly_id: 342,
									},
									{
										id: 10850,
										name: "Kibilizi  Gacaca court of sector ",
										generalAssembly_id: 342,
									},
								],
							},
						],
					},
					{
						id: 10205,
						name: "Kigembe",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020506,
								name: "Rusagara",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020505,
								name: "Rubona",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020504,
								name: "Nyabikenke",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020503,
								name: "Impinga",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020502,
								name: "Gatovu",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020501,
								name: "Agahabwa",
								sector_id: 10205,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 343,
								name: "Bimba ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2114,
										name: "GATOVU",
										generalAssembly_id: 343,
									},
									{
										id: 2115,
										name: "MUTOBO",
										generalAssembly_id: 343,
									},
									{
										id: 2116,
										name: "NYARUBANDE",
										generalAssembly_id: 343,
									},
									{
										id: 9318,
										name: "Bimba  Court of Appeal ",
										generalAssembly_id: 343,
									},
									{
										id: 10851,
										name: "Bimba  Gacaca court of sector ",
										generalAssembly_id: 343,
									},
								],
							},
							{
								id: 344,
								name: "Karama ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2117,
										name: "GATETE",
										generalAssembly_id: 344,
									},
									{
										id: 2118,
										name: "NYABIKENKE",
										generalAssembly_id: 344,
									},
									{
										id: 2119,
										name: "SHYOMBO",
										generalAssembly_id: 344,
									},
									{
										id: 9319,
										name: "Karama  Court of Appeal ",
										generalAssembly_id: 344,
									},
									{
										id: 10852,
										name: "Karama  Gacaca court of sector ",
										generalAssembly_id: 344,
									},
								],
							},
							{
								id: 345,
								name: "Kigembe ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2120,
										name: "AGAHABWA",
										generalAssembly_id: 345,
									},
									{
										id: 2121,
										name: "AGAHEHE",
										generalAssembly_id: 345,
									},
									{
										id: 2122,
										name: "RWAMABARE",
										generalAssembly_id: 345,
									},
									{
										id: 9320,
										name: "Kigembe  Court of Appeal ",
										generalAssembly_id: 345,
									},
									{
										id: 10853,
										name: "Kigembe  Gacaca court of sector ",
										generalAssembly_id: 345,
									},
								],
							},
							{
								id: 346,
								name: "Rubona ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2123,
										name: "GAHARANYONGA",
										generalAssembly_id: 346,
									},
									{
										id: 2124,
										name: "GATONGATI",
										generalAssembly_id: 346,
									},
									{
										id: 2125,
										name: "MUREHE",
										generalAssembly_id: 346,
									},
									{
										id: 9321,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 346,
									},
									{
										id: 10854,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 346,
									},
								],
							},
							{
								id: 347,
								name: "Rusagara ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2126,
										name: "AGASHARU",
										generalAssembly_id: 347,
									},
									{
										id: 2127,
										name: "AKANKUBA",
										generalAssembly_id: 347,
									},
									{
										id: 2128,
										name: "URUTANGA",
										generalAssembly_id: 347,
									},
									{
										id: 9322,
										name: "Rusagara  Court of Appeal ",
										generalAssembly_id: 347,
									},
									{
										id: 10855,
										name: "Rusagara  Gacaca court of sector ",
										generalAssembly_id: 347,
									},
								],
							},
							{
								id: 348,
								name: "Rwimpyisi ",
								sector_id: 10205,
								courtOfCells: [
									{
										id: 2129,
										name: "AKADOGO",
										generalAssembly_id: 348,
									},
									{
										id: 2130,
										name: "IMPINGA",
										generalAssembly_id: 348,
									},
									{
										id: 2131,
										name: "MUTARAMA",
										generalAssembly_id: 348,
									},
									{
										id: 9323,
										name: "Rwimpyisi  Court of Appeal ",
										generalAssembly_id: 348,
									},
									{
										id: 10856,
										name: "Rwimpyisi  Gacaca court of sector ",
										generalAssembly_id: 348,
									},
								],
							},
						],
					},
					{
						id: 10206,
						name: "Mamba",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020605,
								name: "Ramba",
								sector_id: 10206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020604,
								name: "Muyaga",
								sector_id: 10206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020603,
								name: "Mamba",
								sector_id: 10206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020602,
								name: "Kabumbwe",
								sector_id: 10206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020601,
								name: "Gakoma",
								sector_id: 10206,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 349,
								name: "Gakoma ",
								sector_id: 10206,
								courtOfCells: [
									{
										id: 2132,
										name: "GATARE",
										generalAssembly_id: 349,
									},
									{
										id: 2133,
										name: "KIGARAMA",
										generalAssembly_id: 349,
									},
									{
										id: 2134,
										name: "KIVOMO",
										generalAssembly_id: 349,
									},
									{
										id: 2135,
										name: "NYAMUGALI",
										generalAssembly_id: 349,
									},
									{
										id: 9324,
										name: "Gakoma  Court of Appeal ",
										generalAssembly_id: 349,
									},
									{
										id: 10857,
										name: "Gakoma  Gacaca court of sector ",
										generalAssembly_id: 349,
									},
								],
							},
							{
								id: 350,
								name: "Kabumbwe ",
								sector_id: 10206,
								courtOfCells: [
									{
										id: 2136,
										name: "KABUMBWE",
										generalAssembly_id: 350,
									},
									{
										id: 2137,
										name: "MUGANZA",
										generalAssembly_id: 350,
									},
									{
										id: 2138,
										name: "MUNOPFU",
										generalAssembly_id: 350,
									},
									{
										id: 9325,
										name: "Kabumbwe  Court of Appeal ",
										generalAssembly_id: 350,
									},
									{
										id: 10858,
										name: "Kabumbwe  Gacaca court of sector ",
										generalAssembly_id: 350,
									},
								],
							},
							{
								id: 351,
								name: "Mamba ",
								sector_id: 10206,
								courtOfCells: [
									{
										id: 2139,
										name: "GATOVU",
										generalAssembly_id: 351,
									},
									{
										id: 2140,
										name: "MASHENYI",
										generalAssembly_id: 351,
									},
									{
										id: 2141,
										name: "RUGUNGA",
										generalAssembly_id: 351,
									},
									{
										id: 9326,
										name: "Mamba  Court of Appeal ",
										generalAssembly_id: 351,
									},
									{
										id: 10859,
										name: "Mamba  Gacaca court of sector ",
										generalAssembly_id: 351,
									},
								],
							},
							{
								id: 352,
								name: "Muyaga ",
								sector_id: 10206,
								courtOfCells: [
									{
										id: 2142,
										name: "KIBUMBA",
										generalAssembly_id: 352,
									},
									{
										id: 2143,
										name: "MUTOLI",
										generalAssembly_id: 352,
									},
									{
										id: 2144,
										name: "NYAMIRAMA",
										generalAssembly_id: 352,
									},
									{
										id: 9327,
										name: "Muyaga  Court of Appeal ",
										generalAssembly_id: 352,
									},
									{
										id: 10860,
										name: "Muyaga  Gacaca court of sector ",
										generalAssembly_id: 352,
									},
								],
							},
							{
								id: 353,
								name: "Ramba ",
								sector_id: 10206,
								courtOfCells: [
									{
										id: 2145,
										name: "ISHA",
										generalAssembly_id: 353,
									},
									{
										id: 2146,
										name: "KANYIRANTIBA",
										generalAssembly_id: 353,
									},
									{
										id: 2147,
										name: "MUZENGA",
										generalAssembly_id: 353,
									},
									{
										id: 2148,
										name: "RAMBA",
										generalAssembly_id: 353,
									},
									{
										id: 9328,
										name: "Ramba  Court of Appeal ",
										generalAssembly_id: 353,
									},
									{
										id: 10861,
										name: "Ramba  Gacaca court of sector ",
										generalAssembly_id: 353,
									},
								],
							},
						],
					},
					{
						id: 10207,
						name: "Muganza",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020705,
								name: "Saga",
								sector_id: 10207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020704,
								name: "Rwamiko",
								sector_id: 10207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020703,
								name: "Remera",
								sector_id: 10207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020702,
								name: "Muganza",
								sector_id: 10207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020701,
								name: "Cyumba",
								sector_id: 10207,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 354,
								name: "Cyumba ",
								sector_id: 10207,
								courtOfCells: [
									{
										id: 2149,
										name: "GITWA",
										generalAssembly_id: 354,
									},
									{
										id: 2150,
										name: "MUSATSI",
										generalAssembly_id: 354,
									},
									{
										id: 9329,
										name: "Cyumba  Court of Appeal ",
										generalAssembly_id: 354,
									},
									{
										id: 10862,
										name: "Cyumba  Gacaca court of sector ",
										generalAssembly_id: 354,
									},
								],
							},
							{
								id: 355,
								name: "Muganza ",
								sector_id: 10207,
								courtOfCells: [
									{
										id: 2151,
										name: "KIGARAMA",
										generalAssembly_id: 355,
									},
									{
										id: 2152,
										name: "ZIMBA",
										generalAssembly_id: 355,
									},
									{
										id: 9330,
										name: "Muganza  Court of Appeal ",
										generalAssembly_id: 355,
									},
									{
										id: 10863,
										name: "Muganza  Gacaca court of sector ",
										generalAssembly_id: 355,
									},
								],
							},
							{
								id: 356,
								name: "Remera ",
								sector_id: 10207,
								courtOfCells: [
									{
										id: 2153,
										name: "BUTARE",
										generalAssembly_id: 356,
									},
									{
										id: 2154,
										name: "GAKURWE",
										generalAssembly_id: 356,
									},
									{
										id: 2155,
										name: "KAJYANAMA",
										generalAssembly_id: 356,
									},
									{
										id: 9331,
										name: "Remera  Court of Appeal ",
										generalAssembly_id: 356,
									},
									{
										id: 10864,
										name: "Remera  Gacaca court of sector ",
										generalAssembly_id: 356,
									},
								],
							},
							{
								id: 357,
								name: "Rwamiko ",
								sector_id: 10207,
								courtOfCells: [
									{
										id: 2156,
										name: "KIBANGU",
										generalAssembly_id: 357,
									},
									{
										id: 2157,
										name: "MWARYI",
										generalAssembly_id: 357,
									},
									{
										id: 2158,
										name: "NZEBWE",
										generalAssembly_id: 357,
									},
									{
										id: 9332,
										name: "Rwamiko  Court of Appeal ",
										generalAssembly_id: 357,
									},
									{
										id: 10865,
										name: "Rwamiko  Gacaca court of sector ",
										generalAssembly_id: 357,
									},
								],
							},
							{
								id: 358,
								name: "Saga ",
								sector_id: 10207,
								courtOfCells: [
									{
										id: 2159,
										name: "BUCAYA",
										generalAssembly_id: 358,
									},
									{
										id: 2160,
										name: "BUHIZA",
										generalAssembly_id: 358,
									},
									{
										id: 2161,
										name: "NYAMIHETO",
										generalAssembly_id: 358,
									},
									{
										id: 9333,
										name: "Saga  Court of Appeal ",
										generalAssembly_id: 358,
									},
									{
										id: 10866,
										name: "Saga  Gacaca court of sector ",
										generalAssembly_id: 358,
									},
								],
							},
						],
					},
					{
						id: 10208,
						name: "Mugombwa",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020805,
								name: "Mukomacara",
								sector_id: 10208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020804,
								name: "Mugombwa",
								sector_id: 10208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020803,
								name: "Kibu",
								sector_id: 10208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020802,
								name: "Kibayi",
								sector_id: 10208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020801,
								name: "Baziro",
								sector_id: 10208,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 359,
								name: "Baziro ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2162,
										name: "NYAMIRAMA",
										generalAssembly_id: 359,
									},
									{
										id: 2163,
										name: "NYESUMO",
										generalAssembly_id: 359,
									},
									{
										id: 9334,
										name: "Baziro  Court of Appeal ",
										generalAssembly_id: 359,
									},
									{
										id: 10867,
										name: "Baziro  Gacaca court of sector ",
										generalAssembly_id: 359,
									},
								],
							},
							{
								id: 360,
								name: "Kivomo ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2164,
										name: "BAZANKURU",
										generalAssembly_id: 360,
									},
									{
										id: 2165,
										name: "BISHYA",
										generalAssembly_id: 360,
									},
									{
										id: 9335,
										name: "Kivomo  Court of Appeal ",
										generalAssembly_id: 360,
									},
									{
										id: 10868,
										name: "Kivomo  Gacaca court of sector ",
										generalAssembly_id: 360,
									},
								],
							},
							{
								id: 361,
								name: "Mugombwa ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2166,
										name: "KARONKANO",
										generalAssembly_id: 361,
									},
									{
										id: 2167,
										name: "NYARUNAZI",
										generalAssembly_id: 361,
									},
									{
										id: 9336,
										name: "Mugombwa  Court of Appeal ",
										generalAssembly_id: 361,
									},
									{
										id: 10869,
										name: "Mugombwa  Gacaca court of sector ",
										generalAssembly_id: 361,
									},
								],
							},
							{
								id: 362,
								name: "Mukomacara ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2168,
										name: "AGAKOMANSYO",
										generalAssembly_id: 362,
									},
									{
										id: 2169,
										name: "AKANYAGAHUNGU",
										generalAssembly_id: 362,
									},
									{
										id: 2170,
										name: "MUGIRAMPEKE",
										generalAssembly_id: 362,
									},
									{
										id: 2171,
										name: "RUGERAGERE",
										generalAssembly_id: 362,
									},
									{
										id: 9337,
										name: "Mukomacara  Court of Appeal ",
										generalAssembly_id: 362,
									},
									{
										id: 10870,
										name: "Mukomacara  Gacaca court of sector ",
										generalAssembly_id: 362,
									},
								],
							},
							{
								id: 363,
								name: "Nyagahuru_I ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2172,
										name: "AKAGASHUMA",
										generalAssembly_id: 363,
									},
									{
										id: 2173,
										name: "KIBU",
										generalAssembly_id: 363,
									},
									{
										id: 2174,
										name: "MUSHONGI",
										generalAssembly_id: 363,
									},
									{
										id: 9338,
										name: "Nyagahuru_I  Court of Appeal ",
										generalAssembly_id: 363,
									},
									{
										id: 10871,
										name: "Nyagahuru_I  Gacaca court of sector ",
										generalAssembly_id: 363,
									},
								],
							},
							{
								id: 364,
								name: "Kibayi ",
								sector_id: 10208,
								courtOfCells: [
									{
										id: 2175,
										name: "KIBAYI",
										generalAssembly_id: 364,
									},
									{
										id: 2176,
										name: "RWAHAMBI",
										generalAssembly_id: 364,
									},
									{
										id: 9339,
										name: "Kibayi  Court of Appeal ",
										generalAssembly_id: 364,
									},
									{
										id: 10872,
										name: "Kibayi  Gacaca court of sector ",
										generalAssembly_id: 364,
									},
								],
							},
						],
					},
					{
						id: 10209,
						name: "Mukindo",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1020904,
								name: "Runyinya",
								sector_id: 10209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020903,
								name: "Nyabisagara",
								sector_id: 10209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020902,
								name: "Mukiza",
								sector_id: 10209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1020901,
								name: "Gitega",
								sector_id: 10209,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 365,
								name: "Joma ",
								sector_id: 10209,
								courtOfCells: [
									{
										id: 2177,
										name: "GITEGA",
										generalAssembly_id: 365,
									},
									{
										id: 2178,
										name: "NYAMABUYE",
										generalAssembly_id: 365,
									},
									{
										id: 2179,
										name: "REBERO",
										generalAssembly_id: 365,
									},
									{
										id: 9340,
										name: "Joma  Court of Appeal ",
										generalAssembly_id: 365,
									},
									{
										id: 10873,
										name: "Joma  Gacaca court of sector ",
										generalAssembly_id: 365,
									},
								],
							},
							{
								id: 366,
								name: "Mukindo ",
								sector_id: 10209,
								courtOfCells: [
									{
										id: 2180,
										name: "KANAGE",
										generalAssembly_id: 366,
									},
									{
										id: 2181,
										name: "NYABISAGARA",
										generalAssembly_id: 366,
									},
									{
										id: 2182,
										name: "RUSUSA",
										generalAssembly_id: 366,
									},
									{
										id: 9341,
										name: "Mukindo  Court of Appeal ",
										generalAssembly_id: 366,
									},
									{
										id: 10874,
										name: "Mukindo  Gacaca court of sector ",
										generalAssembly_id: 366,
									},
								],
							},
							{
								id: 367,
								name: "Runyinya ",
								sector_id: 10209,
								courtOfCells: [
									{
										id: 2183,
										name: "AGASHARU",
										generalAssembly_id: 367,
									},
									{
										id: 2184,
										name: "GITEGA",
										generalAssembly_id: 367,
									},
									{
										id: 2185,
										name: "KIREMBWA",
										generalAssembly_id: 367,
									},
									{
										id: 2186,
										name: "MUNYEGERA",
										generalAssembly_id: 367,
									},
									{
										id: 9342,
										name: "Runyinya  Court of Appeal ",
										generalAssembly_id: 367,
									},
									{
										id: 10875,
										name: "Runyinya  Gacaca court of sector ",
										generalAssembly_id: 367,
									},
								],
							},
							{
								id: 368,
								name: "Shyombo  ",
								sector_id: 10209,
								courtOfCells: [
									{
										id: 2187,
										name: "CYIMANA",
										generalAssembly_id: 368,
									},
									{
										id: 2188,
										name: "KIGOYI",
										generalAssembly_id: 368,
									},
									{
										id: 2189,
										name: "NYABIRYO",
										generalAssembly_id: 368,
									},
									{
										id: 2190,
										name: "NYAKAZANA",
										generalAssembly_id: 368,
									},
									{
										id: 9343,
										name: "Shyombo   Court of Appeal ",
										generalAssembly_id: 368,
									},
									{
										id: 10876,
										name: "Shyombo   Gacaca court of sector ",
										generalAssembly_id: 368,
									},
								],
							},
						],
					},
					{
						id: 10210,
						name: "Musha",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1021004,
								name: "Kimana",
								sector_id: 10210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021003,
								name: "Kigarama",
								sector_id: 10210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021002,
								name: "Gatovu",
								sector_id: 10210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021001,
								name: "Bukinanyana",
								sector_id: 10210,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 369,
								name: "Buhoro ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2191,
										name: "KIGARAMA",
										generalAssembly_id: 369,
									},
									{
										id: 2192,
										name: "RUGARA",
										generalAssembly_id: 369,
									},
									{
										id: 2193,
										name: "RUGARAMA",
										generalAssembly_id: 369,
									},
									{
										id: 9344,
										name: "Buhoro  Court of Appeal ",
										generalAssembly_id: 369,
									},
									{
										id: 10877,
										name: "Buhoro  Gacaca court of sector ",
										generalAssembly_id: 369,
									},
								],
							},
							{
								id: 370,
								name: "Cyayi ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2194,
										name: "GATOVU",
										generalAssembly_id: 370,
									},
									{
										id: 2195,
										name: "MUTARAMA",
										generalAssembly_id: 370,
									},
									{
										id: 2196,
										name: "NYABITARE",
										generalAssembly_id: 370,
									},
									{
										id: 2197,
										name: "RYAMUGUNGA",
										generalAssembly_id: 370,
									},
									{
										id: 9345,
										name: "Cyayi  Court of Appeal ",
										generalAssembly_id: 370,
									},
									{
										id: 10878,
										name: "Cyayi  Gacaca court of sector ",
										generalAssembly_id: 370,
									},
								],
							},
							{
								id: 371,
								name: "Jurwe ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2198,
										name: "GASEKE",
										generalAssembly_id: 371,
									},
									{
										id: 2199,
										name: "KAGUNDA",
										generalAssembly_id: 371,
									},
									{
										id: 2200,
										name: "KIGARAMA",
										generalAssembly_id: 371,
									},
									{
										id: 9346,
										name: "Jurwe  Court of Appeal ",
										generalAssembly_id: 371,
									},
									{
										id: 10879,
										name: "Jurwe  Gacaca court of sector ",
										generalAssembly_id: 371,
									},
								],
							},
							{
								id: 372,
								name: "Kibirizi ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2201,
										name: "AKABANGA",
										generalAssembly_id: 372,
									},
									{
										id: 2202,
										name: "NYABIDUHA",
										generalAssembly_id: 372,
									},
									{
										id: 2203,
										name: "RAMBA",
										generalAssembly_id: 372,
									},
									{
										id: 9347,
										name: "Kibirizi  Court of Appeal ",
										generalAssembly_id: 372,
									},
									{
										id: 10880,
										name: "Kibirizi  Gacaca court of sector ",
										generalAssembly_id: 372,
									},
								],
							},
							{
								id: 373,
								name: "Munyegera ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2204,
										name: "KIGOMA",
										generalAssembly_id: 373,
									},
									{
										id: 2205,
										name: "MUJYEJURU",
										generalAssembly_id: 373,
									},
									{
										id: 2206,
										name: "MUNYEGERA",
										generalAssembly_id: 373,
									},
									{
										id: 9348,
										name: "Munyegera  Court of Appeal ",
										generalAssembly_id: 373,
									},
									{
										id: 10881,
										name: "Munyegera  Gacaca court of sector ",
										generalAssembly_id: 373,
									},
								],
							},
							{
								id: 374,
								name: "Musha ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2207,
										name: "BUKINANYANA",
										generalAssembly_id: 374,
									},
									{
										id: 2208,
										name: "RUGARAMA",
										generalAssembly_id: 374,
									},
									{
										id: 2209,
										name: "RUKONI",
										generalAssembly_id: 374,
									},
									{
										id: 9349,
										name: "Musha  Court of Appeal ",
										generalAssembly_id: 374,
									},
									{
										id: 10882,
										name: "Musha  Gacaca court of sector ",
										generalAssembly_id: 374,
									},
								],
							},
							{
								id: 375,
								name: "Nyarugenge ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2210,
										name: "MURAMBI",
										generalAssembly_id: 375,
									},
									{
										id: 2211,
										name: "NYARUGENGE",
										generalAssembly_id: 375,
									},
									{
										id: 2212,
										name: "RUSENYI",
										generalAssembly_id: 375,
									},
									{
										id: 9350,
										name: "Nyarugenge  Court of Appeal ",
										generalAssembly_id: 375,
									},
									{
										id: 10883,
										name: "Nyarugenge  Gacaca court of sector ",
										generalAssembly_id: 375,
									},
								],
							},
							{
								id: 376,
								name: "Buremera ",
								sector_id: 10210,
								courtOfCells: [
									{
										id: 2213,
										name: "BUGAGIRO",
										generalAssembly_id: 376,
									},
									{
										id: 2214,
										name: "KAGUNGA",
										generalAssembly_id: 376,
									},
									{
										id: 2215,
										name: "NYESONGA",
										generalAssembly_id: 376,
									},
									{
										id: 9351,
										name: "Buremera  Court of Appeal ",
										generalAssembly_id: 376,
									},
									{
										id: 10884,
										name: "Buremera  Gacaca court of sector ",
										generalAssembly_id: 376,
									},
								],
							},
						],
					},
					{
						id: 10211,
						name: "Ndora",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1021105,
								name: "Mukande",
								sector_id: 10211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021104,
								name: "Gisagara",
								sector_id: 10211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021103,
								name: "Dahwe",
								sector_id: 10211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021102,
								name: "Cyamukuza",
								sector_id: 10211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021101,
								name: "Bweya",
								sector_id: 10211,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 377,
								name: "Bweya ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2216,
										name: "KIMINAZI",
										generalAssembly_id: 377,
									},
									{
										id: 2217,
										name: "MIRAYI",
										generalAssembly_id: 377,
									},
									{
										id: 2218,
										name: "SABUDARI",
										generalAssembly_id: 377,
									},
									{
										id: 9352,
										name: "Bweya  Court of Appeal ",
										generalAssembly_id: 377,
									},
									{
										id: 10885,
										name: "Bweya  Gacaca court of sector ",
										generalAssembly_id: 377,
									},
								],
							},
							{
								id: 378,
								name: "Cyamukuza ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2219,
										name: "BUHURAMBUGA",
										generalAssembly_id: 378,
									},
									{
										id: 2220,
										name: "BUTABANGIRA",
										generalAssembly_id: 378,
									},
									{
										id: 2221,
										name: "NYAKIBUNGO",
										generalAssembly_id: 378,
									},
									{
										id: 2222,
										name: "CYAMUKUZA",
										generalAssembly_id: 378,
									},
									{
										id: 9353,
										name: "Cyamukuza  Court of Appeal ",
										generalAssembly_id: 378,
									},
									{
										id: 10886,
										name: "Cyamukuza  Gacaca court of sector ",
										generalAssembly_id: 378,
									},
								],
							},
							{
								id: 379,
								name: "Gisagara ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2223,
										name: "KABUYE",
										generalAssembly_id: 379,
									},
									{
										id: 2224,
										name: "NYAMIGANGO",
										generalAssembly_id: 379,
									},
									{
										id: 9354,
										name: "Gisagara  Court of Appeal ",
										generalAssembly_id: 379,
									},
									{
										id: 10887,
										name: "Gisagara  Gacaca court of sector ",
										generalAssembly_id: 379,
									},
								],
							},
							{
								id: 380,
								name: "Mukande ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2225,
										name: "BUGARAMA",
										generalAssembly_id: 380,
									},
									{
										id: 2226,
										name: "KIDWANGE",
										generalAssembly_id: 380,
									},
									{
										id: 2227,
										name: "NKINDA",
										generalAssembly_id: 380,
									},
									{
										id: 9355,
										name: "Mukande  Court of Appeal ",
										generalAssembly_id: 380,
									},
									{
										id: 10888,
										name: "Mukande  Gacaca court of sector ",
										generalAssembly_id: 380,
									},
								],
							},
							{
								id: 381,
								name: "Ndora ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2228,
										name: "NYABITARE",
										generalAssembly_id: 381,
									},
									{
										id: 2229,
										name: "RUGARA",
										generalAssembly_id: 381,
									},
									{
										id: 9356,
										name: "Ndora  Court of Appeal ",
										generalAssembly_id: 381,
									},
									{
										id: 10889,
										name: "Ndora  Gacaca court of sector ",
										generalAssembly_id: 381,
									},
								],
							},
							{
								id: 382,
								name: "Dahwe ",
								sector_id: 10211,
								courtOfCells: [
									{
										id: 2230,
										name: "GAHONDO",
										generalAssembly_id: 382,
									},
									{
										id: 2231,
										name: "GITWA",
										generalAssembly_id: 382,
									},
									{
										id: 2232,
										name: "NYARUSANGE",
										generalAssembly_id: 382,
									},
									{
										id: 2233,
										name: "RUYANGE",
										generalAssembly_id: 382,
									},
									{
										id: 9357,
										name: "Dahwe  Court of Appeal ",
										generalAssembly_id: 382,
									},
									{
										id: 10890,
										name: "Dahwe  Gacaca court of sector ",
										generalAssembly_id: 382,
									},
								],
							},
						],
					},
					{
						id: 10212,
						name: "Nyanza",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1021204,
								name: "Umubanga",
								sector_id: 10212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021203,
								name: "Nyaruteja",
								sector_id: 10212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021202,
								name: "Nyamugari",
								sector_id: 10212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021201,
								name: "Higiro",
								sector_id: 10212,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 383,
								name: "Kigali ",
								sector_id: 10212,
								courtOfCells: [
									{
										id: 2234,
										name: "AKAYANGE",
										generalAssembly_id: 383,
									},
									{
										id: 2235,
										name: "GATARINZIRA",
										generalAssembly_id: 383,
									},
									{
										id: 2236,
										name: "NYAMUGARI",
										generalAssembly_id: 383,
									},
									{
										id: 2237,
										name: "SARE",
										generalAssembly_id: 383,
									},
									{
										id: 2238,
										name: "NYARUNAZI",
										generalAssembly_id: 383,
									},
									{
										id: 9358,
										name: "Kigali  Court of Appeal ",
										generalAssembly_id: 383,
									},
									{
										id: 10891,
										name: "Kigali  Gacaca court of sector ",
										generalAssembly_id: 383,
									},
								],
							},
							{
								id: 384,
								name: "Nyanza ",
								sector_id: 10212,
								courtOfCells: [
									{
										id: 2239,
										name: "NYAMIHATSI",
										generalAssembly_id: 384,
									},
									{
										id: 2240,
										name: "AKAGARAMA",
										generalAssembly_id: 384,
									},
									{
										id: 2241,
										name: "RUVUGIZO",
										generalAssembly_id: 384,
									},
									{
										id: 9359,
										name: "Nyanza  Court of Appeal ",
										generalAssembly_id: 384,
									},
									{
										id: 10892,
										name: "Nyanza  Gacaca court of sector ",
										generalAssembly_id: 384,
									},
								],
							},
							{
								id: 385,
								name: "Nyaruteja ",
								sector_id: 10212,
								courtOfCells: [
									{
										id: 2242,
										name: "MUKUGE",
										generalAssembly_id: 385,
									},
									{
										id: 2243,
										name: "RUKOKO",
										generalAssembly_id: 385,
									},
									{
										id: 2244,
										name: "RWANYANZA",
										generalAssembly_id: 385,
									},
									{
										id: 9360,
										name: "Nyaruteja  Court of Appeal ",
										generalAssembly_id: 385,
									},
									{
										id: 10893,
										name: "Nyaruteja  Gacaca court of sector ",
										generalAssembly_id: 385,
									},
								],
							},
							{
								id: 386,
								name: "Ruhororo ",
								sector_id: 10212,
								courtOfCells: [
									{
										id: 2245,
										name: "AKARWISHYURA",
										generalAssembly_id: 386,
									},
									{
										id: 2246,
										name: "KIGWA",
										generalAssembly_id: 386,
									},
									{
										id: 2247,
										name: "REMERA",
										generalAssembly_id: 386,
									},
									{
										id: 9361,
										name: "Ruhororo  Court of Appeal ",
										generalAssembly_id: 386,
									},
									{
										id: 10894,
										name: "Ruhororo  Gacaca court of sector ",
										generalAssembly_id: 386,
									},
								],
							},
						],
					},
					{
						id: 10213,
						name: "Save",
						district_id: 102,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1021305,
								name: "Zivu",
								sector_id: 10213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021304,
								name: "Shyanda",
								sector_id: 10213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021303,
								name: "Rwanza",
								sector_id: 10213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021302,
								name: "Munazi",
								sector_id: 10213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1021301,
								name: "Gatoki",
								sector_id: 10213,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 387,
								name: "Bwinyambo ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2248,
										name: "BAZENGA",
										generalAssembly_id: 387,
									},
									{
										id: 2249,
										name: "BITABIRE",
										generalAssembly_id: 387,
									},
									{
										id: 2250,
										name: "GAHORA",
										generalAssembly_id: 387,
									},
									{
										id: 9362,
										name: "Bwinyambo  Court of Appeal ",
										generalAssembly_id: 387,
									},
									{
										id: 10895,
										name: "Bwinyambo  Gacaca court of sector ",
										generalAssembly_id: 387,
									},
								],
							},
							{
								id: 388,
								name: "Gasharu ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2251,
										name: "KABITOKI",
										generalAssembly_id: 388,
									},
									{
										id: 2252,
										name: "KADURUMBA",
										generalAssembly_id: 388,
									},
									{
										id: 2253,
										name: "KIGWA",
										generalAssembly_id: 388,
									},
									{
										id: 9363,
										name: "Gasharu  Court of Appeal ",
										generalAssembly_id: 388,
									},
									{
										id: 10896,
										name: "Gasharu  Gacaca court of sector ",
										generalAssembly_id: 388,
									},
								],
							},
							{
								id: 389,
								name: "Gatoki ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2254,
										name: "GASHUBI",
										generalAssembly_id: 389,
									},
									{
										id: 2255,
										name: "KARAMA",
										generalAssembly_id: 389,
									},
									{
										id: 2256,
										name: "NYARIGINA",
										generalAssembly_id: 389,
									},
									{
										id: 9364,
										name: "Gatoki  Court of Appeal ",
										generalAssembly_id: 389,
									},
									{
										id: 10897,
										name: "Gatoki  Gacaca court of sector ",
										generalAssembly_id: 389,
									},
								],
							},
							{
								id: 390,
								name: "Kamudahunga ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2257,
										name: "KIRUHURA",
										generalAssembly_id: 390,
									},
									{
										id: 2258,
										name: "NYAGACYAMU",
										generalAssembly_id: 390,
									},
									{
										id: 2259,
										name: "RWANZA",
										generalAssembly_id: 390,
									},
									{
										id: 9365,
										name: "Kamudahunga  Court of Appeal ",
										generalAssembly_id: 390,
									},
									{
										id: 10898,
										name: "Kamudahunga  Gacaca court of sector ",
										generalAssembly_id: 390,
									},
								],
							},
							{
								id: 391,
								name: "Kayenzi ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2260,
										name: "GAKOMBE",
										generalAssembly_id: 391,
									},
									{
										id: 2261,
										name: "GITWA",
										generalAssembly_id: 391,
									},
									{
										id: 2262,
										name: "RWESEREZO",
										generalAssembly_id: 391,
									},
									{
										id: 9366,
										name: "Kayenzi  Court of Appeal ",
										generalAssembly_id: 391,
									},
									{
										id: 10899,
										name: "Kayenzi  Gacaca court of sector ",
										generalAssembly_id: 391,
									},
								],
							},
							{
								id: 392,
								name: "Kiziguro ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2263,
										name: "GASHARU",
										generalAssembly_id: 392,
									},
									{
										id: 2264,
										name: "KIGARAMA",
										generalAssembly_id: 392,
									},
									{
										id: 2265,
										name: "RUSENYI",
										generalAssembly_id: 392,
									},
									{
										id: 9367,
										name: "Kiziguro  Court of Appeal ",
										generalAssembly_id: 392,
									},
									{
										id: 10900,
										name: "Kiziguro  Gacaca court of sector ",
										generalAssembly_id: 392,
									},
								],
							},
							{
								id: 393,
								name: "Munanira  ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2266,
										name: "BAKENDE",
										generalAssembly_id: 393,
									},
									{
										id: 2267,
										name: "KIMISHIBU",
										generalAssembly_id: 393,
									},
									{
										id: 2268,
										name: "RUGARAMA",
										generalAssembly_id: 393,
									},
									{
										id: 9368,
										name: "Munanira   Court of Appeal ",
										generalAssembly_id: 393,
									},
									{
										id: 10901,
										name: "Munanira   Gacaca court of sector ",
										generalAssembly_id: 393,
									},
								],
							},
							{
								id: 394,
								name: "Zivu  ",
								sector_id: 10213,
								courtOfCells: [
									{
										id: 2269,
										name: "MUSEKERA",
										generalAssembly_id: 394,
									},
									{
										id: 2270,
										name: "RWANZANA",
										generalAssembly_id: 394,
									},
									{
										id: 2271,
										name: "TAMBA",
										generalAssembly_id: 394,
									},
									{
										id: 9369,
										name: "Zivu   Court of Appeal ",
										generalAssembly_id: 394,
									},
									{
										id: 10902,
										name: "Zivu   Gacaca court of sector ",
										generalAssembly_id: 394,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 104,
				name: "Huye",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10401,
						name: "Gishamvu",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040104,
								name: "Shori",
								sector_id: 10401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1040103,
								name: "Ryakibogo",
								sector_id: 10401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1040102,
								name: "Nyumba",
								sector_id: 10401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1040101,
								name: "Nyakibanda",
								sector_id: 10401,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 395,
								name: "Vumbi",
								sector_id: 10401,
								courtOfCells: [
									{
										id: 2272,
										name: "GAKOMBE",
										generalAssembly_id: 395,
									},
									{
										id: 2273,
										name: "KIDAHIRE",
										generalAssembly_id: 395,
									},
									{
										id: 2274,
										name: "KIDUHA",
										generalAssembly_id: 395,
									},
									{
										id: 2275,
										name: "RUGARAMA",
										generalAssembly_id: 395,
									},
									{
										id: 9370,
										name: "Vumbi Court of Appeal ",
										generalAssembly_id: 395,
									},
									{
										id: 10903,
										name: "Vumbi Gacaca court of sector ",
										generalAssembly_id: 395,
									},
								],
							},
							{
								id: 396,
								name: "Gishamvu",
								sector_id: 10401,
								courtOfCells: [
									{
										id: 2276,
										name: "BUSORO",
										generalAssembly_id: 396,
									},
									{
										id: 2277,
										name: "GISHAMVU",
										generalAssembly_id: 396,
									},
									{
										id: 2278,
										name: "MUBONI",
										generalAssembly_id: 396,
									},
									{
										id: 9371,
										name: "Gishamvu Court of Appeal ",
										generalAssembly_id: 396,
									},
									{
										id: 10904,
										name: "Gishamvu Gacaca court of sector ",
										generalAssembly_id: 396,
									},
								],
							},
							{
								id: 397,
								name: "Nyakibanda",
								sector_id: 10401,
								courtOfCells: [
									{
										id: 2279,
										name: "NYAKIBANDA",
										generalAssembly_id: 397,
									},
									{
										id: 2280,
										name: "RWIMBOGO",
										generalAssembly_id: 397,
									},
									{
										id: 9372,
										name: "Nyakibanda Court of Appeal ",
										generalAssembly_id: 397,
									},
									{
										id: 10905,
										name: "Nyakibanda Gacaca court of sector ",
										generalAssembly_id: 397,
									},
								],
							},
							{
								id: 398,
								name: "Shori",
								sector_id: 10401,
								courtOfCells: [
									{
										id: 2281,
										name: "BUGURI",
										generalAssembly_id: 398,
									},
									{
										id: 2282,
										name: "CYAMBWE",
										generalAssembly_id: 398,
									},
									{
										id: 9373,
										name: "Shori Court of Appeal ",
										generalAssembly_id: 398,
									},
									{
										id: 10906,
										name: "Shori Gacaca court of sector ",
										generalAssembly_id: 398,
									},
								],
							},
						],
					},
					{
						id: 10402,
						name: "Huye",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040204,
								name: "Sovu",
								sector_id: 10402,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040203,
								name: "Rukira",
								sector_id: 10402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1040202,
								name: "Nyakagezi",
								sector_id: 10402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1040201,
								name: "Muyogoro",
								sector_id: 10402,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 399,
								name: "Mpungwe",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2283,
										name: "KABAKA",
										generalAssembly_id: 399,
									},
									{
										id: 2284,
										name: "KIGARAMA",
										generalAssembly_id: 399,
									},
									{
										id: 9374,
										name: "Mpungwe Court of Appeal ",
										generalAssembly_id: 399,
									},
									{
										id: 10907,
										name: "Mpungwe Gacaca court of sector ",
										generalAssembly_id: 399,
									},
								],
							},
							{
								id: 400,
								name: "Nkima",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2285,
										name: "KINYANA",
										generalAssembly_id: 400,
									},
									{
										id: 2286,
										name: "NYARUNAZI",
										generalAssembly_id: 400,
									},
									{
										id: 9375,
										name: "Nkima Court of Appeal ",
										generalAssembly_id: 400,
									},
									{
										id: 10908,
										name: "Nkima Gacaca court of sector ",
										generalAssembly_id: 400,
									},
								],
							},
							{
								id: 401,
								name: "Nyanza",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2287,
										name: "AGASHARU",
										generalAssembly_id: 401,
									},
									{
										id: 2288,
										name: "GITWA",
										generalAssembly_id: 401,
									},
									{
										id: 9376,
										name: "Nyanza Court of Appeal ",
										generalAssembly_id: 401,
									},
									{
										id: 10909,
										name: "Nyanza Gacaca court of sector ",
										generalAssembly_id: 401,
									},
								],
							},
							{
								id: 402,
								name: "Rukara",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2289,
										name: "NYARUTOVU",
										generalAssembly_id: 402,
									},
									{
										id: 2290,
										name: "NYARWUMBA",
										generalAssembly_id: 402,
									},
									{
										id: 2291,
										name: "RWANKONI",
										generalAssembly_id: 402,
									},
									{
										id: 2292,
										name: "RWAZA",
										generalAssembly_id: 402,
									},
									{
										id: 9377,
										name: "Rukara Court of Appeal ",
										generalAssembly_id: 402,
									},
									{
										id: 10910,
										name: "Rukara Gacaca court of sector ",
										generalAssembly_id: 402,
									},
								],
							},
							{
								id: 403,
								name: "Rukira",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2293,
										name: "AKANAZI",
										generalAssembly_id: 403,
									},
									{
										id: 2294,
										name: "KIGARAMA",
										generalAssembly_id: 403,
									},
									{
										id: 9378,
										name: "Rukira Court of Appeal ",
										generalAssembly_id: 403,
									},
									{
										id: 10911,
										name: "Rukira Gacaca court of sector ",
										generalAssembly_id: 403,
									},
								],
							},
							{
								id: 404,
								name: "Sovu",
								sector_id: 10402,
								courtOfCells: [
									{
										id: 2295,
										name: "GAKO",
										generalAssembly_id: 404,
									},
									{
										id: 2296,
										name: "KARUHAYI",
										generalAssembly_id: 404,
									},
									{
										id: 2297,
										name: "KIGARAMA",
										generalAssembly_id: 404,
									},
									{
										id: 9379,
										name: "Sovu Court of Appeal ",
										generalAssembly_id: 404,
									},
									{
										id: 10912,
										name: "Sovu Gacaca court of sector ",
										generalAssembly_id: 404,
									},
								],
							},
						],
					},
					{
						id: 10403,
						name: "Karama",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040305,
								name: "Muhembe",
								sector_id: 10403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040304,
								name: "Kibingo",
								sector_id: 10403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040303,
								name: "Gahororo",
								sector_id: 10403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040302,
								name: "Bunazi",
								sector_id: 10403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040301,
								name: "Buhoro",
								sector_id: 10403,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 405,
								name: "Buhoro",
								sector_id: 10403,
								courtOfCells: [
									{
										id: 2298,
										name: "KIBINGO",
										generalAssembly_id: 405,
									},
									{
										id: 2299,
										name: "MATABA",
										generalAssembly_id: 405,
									},
									{
										id: 2300,
										name: "NYAMAPFUNDA",
										generalAssembly_id: 405,
									},
									{
										id: 2301,
										name: "NYAMIKABA",
										generalAssembly_id: 405,
									},
									{
										id: 9380,
										name: "Buhoro Court of Appeal ",
										generalAssembly_id: 405,
									},
									{
										id: 10913,
										name: "Buhoro Gacaca court of sector ",
										generalAssembly_id: 405,
									},
								],
							},
							{
								id: 406,
								name: "Gikombe",
								sector_id: 10403,
								courtOfCells: [
									{
										id: 2302,
										name: "GIKOMBE",
										generalAssembly_id: 406,
									},
									{
										id: 2303,
										name: "NYARWUMBA",
										generalAssembly_id: 406,
									},
									{
										id: 2304,
										name: "RUSHUBI",
										generalAssembly_id: 406,
									},
									{
										id: 9381,
										name: "Gikombe Court of Appeal ",
										generalAssembly_id: 406,
									},
									{
										id: 10914,
										name: "Gikombe Gacaca court of sector ",
										generalAssembly_id: 406,
									},
								],
							},
							{
								id: 407,
								name: "Kaburemera",
								sector_id: 10403,
								courtOfCells: [
									{
										id: 2305,
										name: "BUTARE-KABUREMERA",
										generalAssembly_id: 407,
									},
									{
										id: 2306,
										name: "CYETETE",
										generalAssembly_id: 407,
									},
									{
										id: 2307,
										name: "MUHEMBE",
										generalAssembly_id: 407,
									},
									{
										id: 9382,
										name: "Kaburemera Court of Appeal ",
										generalAssembly_id: 407,
									},
									{
										id: 10915,
										name: "Kaburemera Gacaca court of sector ",
										generalAssembly_id: 407,
									},
								],
							},
							{
								id: 408,
								name: "Karama",
								sector_id: 10403,
								courtOfCells: [
									{
										id: 2308,
										name: "GAHORORO",
										generalAssembly_id: 408,
									},
									{
										id: 2309,
										name: "GIKONGORO",
										generalAssembly_id: 408,
									},
									{
										id: 2310,
										name: "NYARUSANGE",
										generalAssembly_id: 408,
									},
									{
										id: 2311,
										name: "UMUYANGE",
										generalAssembly_id: 408,
									},
									{
										id: 2312,
										name: "UWARUGONDO",
										generalAssembly_id: 408,
									},
									{
										id: 9383,
										name: "Karama Court of Appeal ",
										generalAssembly_id: 408,
									},
									{
										id: 10916,
										name: "Karama Gacaca court of sector ",
										generalAssembly_id: 408,
									},
								],
							},
							{
								id: 409,
								name: "Kibingo",
								sector_id: 10403,
								courtOfCells: [
									{
										id: 2313,
										name: "GIHUMA",
										generalAssembly_id: 409,
									},
									{
										id: 2314,
										name: "KIBINGO",
										generalAssembly_id: 409,
									},
									{
										id: 2315,
										name: "MUKIMBA",
										generalAssembly_id: 409,
									},
									{
										id: 2316,
										name: "ZAGA",
										generalAssembly_id: 409,
									},
									{
										id: 9384,
										name: "Kibingo Court of Appeal ",
										generalAssembly_id: 409,
									},
									{
										id: 10917,
										name: "Kibingo Gacaca court of sector ",
										generalAssembly_id: 409,
									},
								],
							},
						],
					},
					{
						id: 10404,
						name: "Kigoma",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040408,
								name: "Shanga",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040407,
								name: "Rugarama",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040406,
								name: "Nyabisindu",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040405,
								name: "Musebeya",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040404,
								name: "Karambi",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040403,
								name: "Kabuga",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040402,
								name: "Kabatwa",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040401,
								name: "Gishihe",
								sector_id: 10404,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 410,
								name: "Bitare",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2317,
										name: "BUHANA",
										generalAssembly_id: 410,
									},
									{
										id: 2318,
										name: "FUKWE",
										generalAssembly_id: 410,
									},
									{
										id: 2319,
										name: "KIGARAMA",
										generalAssembly_id: 410,
									},
									{
										id: 2320,
										name: "MURAMBI",
										generalAssembly_id: 410,
									},
									{
										id: 2321,
										name: "RUGARAMA",
										generalAssembly_id: 410,
									},
									{
										id: 9385,
										name: "Bitare Court of Appeal ",
										generalAssembly_id: 410,
									},
									{
										id: 10918,
										name: "Bitare Gacaca court of sector ",
										generalAssembly_id: 410,
									},
								],
							},
							{
								id: 411,
								name: "Gakomeye",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2322,
										name: "CYENENE",
										generalAssembly_id: 411,
									},
									{
										id: 2323,
										name: "KABICUKI",
										generalAssembly_id: 411,
									},
									{
										id: 2324,
										name: "NTUNTU",
										generalAssembly_id: 411,
									},
									{
										id: 2325,
										name: "NYABIVUMU",
										generalAssembly_id: 411,
									},
									{
										id: 2326,
										name: "RUKINGA",
										generalAssembly_id: 411,
									},
									{
										id: 9386,
										name: "Gakomeye Court of Appeal ",
										generalAssembly_id: 411,
									},
									{
										id: 10919,
										name: "Gakomeye Gacaca court of sector ",
										generalAssembly_id: 411,
									},
								],
							},
							{
								id: 412,
								name: "Karama",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2327,
										name: "BANDE",
										generalAssembly_id: 412,
									},
									{
										id: 2328,
										name: "KABATWA",
										generalAssembly_id: 412,
									},
									{
										id: 2329,
										name: "KINYATA",
										generalAssembly_id: 412,
									},
									{
										id: 2330,
										name: "MAHWA",
										generalAssembly_id: 412,
									},
									{
										id: 2331,
										name: "MBOGO",
										generalAssembly_id: 412,
									},
									{
										id: 9387,
										name: "Karama Court of Appeal ",
										generalAssembly_id: 412,
									},
									{
										id: 10920,
										name: "Karama Gacaca court of sector ",
										generalAssembly_id: 412,
									},
								],
							},
							{
								id: 413,
								name: "Kiyaga",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2332,
										name: "BIRAMBO",
										generalAssembly_id: 413,
									},
									{
										id: 2333,
										name: "GATOVU",
										generalAssembly_id: 413,
									},
									{
										id: 2334,
										name: "GIHANDA",
										generalAssembly_id: 413,
									},
									{
										id: 2335,
										name: "KABABAJI",
										generalAssembly_id: 413,
									},
									{
										id: 2336,
										name: "KABAKOBWA",
										generalAssembly_id: 413,
									},
									{
										id: 2337,
										name: "KAVUMU",
										generalAssembly_id: 413,
									},
									{
										id: 2338,
										name: "MUSEBEYA",
										generalAssembly_id: 413,
									},
									{
										id: 9388,
										name: "Kiyaga Court of Appeal ",
										generalAssembly_id: 413,
									},
									{
										id: 10921,
										name: "Kiyaga Gacaca court of sector ",
										generalAssembly_id: 413,
									},
								],
							},
							{
								id: 414,
								name: "Murera",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2339,
										name: "CYANYA",
										generalAssembly_id: 414,
									},
									{
										id: 2340,
										name: "GATARE",
										generalAssembly_id: 414,
									},
									{
										id: 2341,
										name: "KIGARAMA",
										generalAssembly_id: 414,
									},
									{
										id: 2342,
										name: "SANZU",
										generalAssembly_id: 414,
									},
									{
										id: 2343,
										name: "ZIGE",
										generalAssembly_id: 414,
									},
									{
										id: 9389,
										name: "Murera Court of Appeal ",
										generalAssembly_id: 414,
									},
									{
										id: 10922,
										name: "Murera Gacaca court of sector ",
										generalAssembly_id: 414,
									},
								],
							},
							{
								id: 415,
								name: "Nyarusange",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2344,
										name: "KABUGABO",
										generalAssembly_id: 415,
									},
									{
										id: 2345,
										name: "KARAMBI",
										generalAssembly_id: 415,
									},
									{
										id: 2346,
										name: "NYABISINDU",
										generalAssembly_id: 415,
									},
									{
										id: 2347,
										name: "NYABIVUMU",
										generalAssembly_id: 415,
									},
									{
										id: 2348,
										name: "NYANZAMURA",
										generalAssembly_id: 415,
									},
									{
										id: 9390,
										name: "Nyarusange Court of Appeal ",
										generalAssembly_id: 415,
									},
									{
										id: 10923,
										name: "Nyarusange Gacaca court of sector ",
										generalAssembly_id: 415,
									},
								],
							},
							{
								id: 416,
								name: "Rwamweru",
								sector_id: 10404,
								courtOfCells: [
									{
										id: 2349,
										name: "GASURA",
										generalAssembly_id: 416,
									},
									{
										id: 2350,
										name: "GITWA",
										generalAssembly_id: 416,
									},
									{
										id: 2351,
										name: "KIGARAMA",
										generalAssembly_id: 416,
									},
									{
										id: 2352,
										name: "MBURI",
										generalAssembly_id: 416,
									},
									{
										id: 2353,
										name: "REBO",
										generalAssembly_id: 416,
									},
									{
										id: 9391,
										name: "Rwamweru Court of Appeal ",
										generalAssembly_id: 416,
									},
									{
										id: 10924,
										name: "Rwamweru Gacaca court of sector ",
										generalAssembly_id: 416,
									},
								],
							},
						],
					},
					{
						id: 10405,
						name: "Kinazi",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040505,
								name: "Sazange",
								sector_id: 10405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040504,
								name: "Kabona",
								sector_id: 10405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040503,
								name: "Gitovu",
								sector_id: 10405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040502,
								name: "Gahana",
								sector_id: 10405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040501,
								name: "Byinza",
								sector_id: 10405,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 417,
								name: "Buremera",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2354,
										name: "MAGU",
										generalAssembly_id: 417,
									},
									{
										id: 2355,
										name: "NYARUGUNGA",
										generalAssembly_id: 417,
									},
									{
										id: 9392,
										name: "Buremera Court of Appeal ",
										generalAssembly_id: 417,
									},
									{
										id: 10925,
										name: "Buremera Gacaca court of sector ",
										generalAssembly_id: 417,
									},
								],
							},
							{
								id: 418,
								name: "Gahana",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2356,
										name: "GAHANA",
										generalAssembly_id: 418,
									},
									{
										id: 2357,
										name: "SOGWE",
										generalAssembly_id: 418,
									},
									{
										id: 9393,
										name: "Gahana Court of Appeal ",
										generalAssembly_id: 418,
									},
									{
										id: 10926,
										name: "Gahana Gacaca court of sector ",
										generalAssembly_id: 418,
									},
								],
							},
							{
								id: 419,
								name: "Kabona",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2358,
										name: "GIKORO",
										generalAssembly_id: 419,
									},
									{
										id: 2359,
										name: "KABONA",
										generalAssembly_id: 419,
									},
									{
										id: 9394,
										name: "Kabona Court of Appeal ",
										generalAssembly_id: 419,
									},
									{
										id: 10927,
										name: "Kabona Gacaca court of sector ",
										generalAssembly_id: 419,
									},
								],
							},
							{
								id: 420,
								name: "Kinazi",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2360,
										name: "GITOVU",
										generalAssembly_id: 420,
									},
									{
										id: 2361,
										name: "KINAZI",
										generalAssembly_id: 420,
									},
									{
										id: 2362,
										name: "NTARE",
										generalAssembly_id: 420,
									},
									{
										id: 9395,
										name: "Kinazi Court of Appeal ",
										generalAssembly_id: 420,
									},
									{
										id: 10928,
										name: "Kinazi Gacaca court of sector ",
										generalAssembly_id: 420,
									},
								],
							},
							{
								id: 421,
								name: "Maza",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2363,
										name: "MAZA",
										generalAssembly_id: 421,
									},
									{
										id: 2364,
										name: "SAZANGE",
										generalAssembly_id: 421,
									},
									{
										id: 9396,
										name: "Maza Court of Appeal ",
										generalAssembly_id: 421,
									},
									{
										id: 10929,
										name: "Maza Gacaca court of sector ",
										generalAssembly_id: 421,
									},
								],
							},
							{
								id: 422,
								name: "Nyagisenyi",
								sector_id: 10405,
								courtOfCells: [
									{
										id: 2365,
										name: "BUTARE",
										generalAssembly_id: 422,
									},
									{
										id: 2366,
										name: "GITEGA",
										generalAssembly_id: 422,
									},
									{
										id: 9397,
										name: "Nyagisenyi Court of Appeal ",
										generalAssembly_id: 422,
									},
									{
										id: 10930,
										name: "Nyagisenyi Gacaca court of sector ",
										generalAssembly_id: 422,
									},
								],
							},
						],
					},
					{
						id: 10406,
						name: "Maraba",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040606,
								name: "Shyembe",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040605,
								name: "Shanga",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040604,
								name: "Kanyinya",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040603,
								name: "Kabuye",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040602,
								name: "Gasumba",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040601,
								name: "Buremera",
								sector_id: 10406,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 423,
								name: "Cyarumbo",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2367,
										name: "KAGOMA",
										generalAssembly_id: 423,
									},
									{
										id: 2368,
										name: "NYAMABUYE",
										generalAssembly_id: 423,
									},
									{
										id: 2369,
										name: "SHYEMBE",
										generalAssembly_id: 423,
									},
									{
										id: 9398,
										name: "Cyarumbo Court of Appeal ",
										generalAssembly_id: 423,
									},
									{
										id: 10931,
										name: "Cyarumbo Gacaca court of sector ",
										generalAssembly_id: 423,
									},
								],
							},
							{
								id: 424,
								name: "Buremera",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2370,
										name: "BUREMERA",
										generalAssembly_id: 424,
									},
									{
										id: 2371,
										name: "GASARABUYE",
										generalAssembly_id: 424,
									},
									{
										id: 2372,
										name: "GASUMBA",
										generalAssembly_id: 424,
									},
									{
										id: 2373,
										name: "KINAZI",
										generalAssembly_id: 424,
									},
									{
										id: 2374,
										name: "NKORWE",
										generalAssembly_id: 424,
									},
									{
										id: 2375,
										name: "NYARUSANGE",
										generalAssembly_id: 424,
									},
									{
										id: 2376,
										name: "RUGARAGARA",
										generalAssembly_id: 424,
									},
									{
										id: 9399,
										name: "Buremera Court of Appeal ",
										generalAssembly_id: 424,
									},
									{
										id: 10932,
										name: "Buremera Gacaca court of sector ",
										generalAssembly_id: 424,
									},
								],
							},
							{
								id: 425,
								name: "Kabuye",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2377,
										name: "GASHARU",
										generalAssembly_id: 425,
									},
									{
										id: 2378,
										name: "NYAMVUMBA",
										generalAssembly_id: 425,
									},
									{
										id: 2379,
										name: "RUKERI",
										generalAssembly_id: 425,
									},
									{
										id: 9400,
										name: "Kabuye Court of Appeal ",
										generalAssembly_id: 425,
									},
									{
										id: 10933,
										name: "Kabuye Gacaca court of sector ",
										generalAssembly_id: 425,
									},
								],
							},
							{
								id: 426,
								name: "Maraba",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2380,
										name: "KIZI",
										generalAssembly_id: 426,
									},
									{
										id: 2381,
										name: "MIKINGO",
										generalAssembly_id: 426,
									},
									{
										id: 2382,
										name: "NYAMIYAGA",
										generalAssembly_id: 426,
									},
									{
										id: 9401,
										name: "Maraba Court of Appeal ",
										generalAssembly_id: 426,
									},
									{
										id: 10934,
										name: "Maraba Gacaca court of sector ",
										generalAssembly_id: 426,
									},
								],
							},
							{
								id: 427,
								name: "Shanga",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2383,
										name: "NYANTENDE",
										generalAssembly_id: 427,
									},
									{
										id: 2384,
										name: "SHYINGA",
										generalAssembly_id: 427,
									},
									{
										id: 9402,
										name: "Shanga Court of Appeal ",
										generalAssembly_id: 427,
									},
									{
										id: 10935,
										name: "Shanga Gacaca court of sector ",
										generalAssembly_id: 427,
									},
								],
							},
							{
								id: 428,
								name: "Mugano",
								sector_id: 10406,
								courtOfCells: [
									{
										id: 2385,
										name: "KAMUCACA",
										generalAssembly_id: 428,
									},
									{
										id: 2386,
										name: "KANYINYA",
										generalAssembly_id: 428,
									},
									{
										id: 2387,
										name: "MUHERO",
										generalAssembly_id: 428,
									},
									{
										id: 2388,
										name: "MUKONI",
										generalAssembly_id: 428,
									},
									{
										id: 2389,
										name: "MUNINI",
										generalAssembly_id: 428,
									},
									{
										id: 2390,
										name: "MUYINZA",
										generalAssembly_id: 428,
									},
									{
										id: 9403,
										name: "Mugano Court of Appeal ",
										generalAssembly_id: 428,
									},
									{
										id: 10936,
										name: "Mugano Gacaca court of sector ",
										generalAssembly_id: 428,
									},
								],
							},
						],
					},
					{
						id: 10407,
						name: "Mbazi",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040707,
								name: "Tare",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040706,
								name: "Rusagara",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040705,
								name: "Rugango",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040704,
								name: "Mwulire",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040703,
								name: "Mutunda",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040702,
								name: "Kabuga",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040701,
								name: "Gatobotobo",
								sector_id: 10407,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 429,
								name: "Mbazi",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2391,
										name: "BIGANGARA",
										generalAssembly_id: 429,
									},
									{
										id: 2392,
										name: "GATOBOTOBO",
										generalAssembly_id: 429,
									},
									{
										id: 2393,
										name: "KAMAGONDE",
										generalAssembly_id: 429,
									},
									{
										id: 2394,
										name: "KANYARUHINDA",
										generalAssembly_id: 429,
									},
									{
										id: 9404,
										name: "Mbazi Court of Appeal ",
										generalAssembly_id: 429,
									},
									{
										id: 10937,
										name: "Mbazi Gacaca court of sector ",
										generalAssembly_id: 429,
									},
								],
							},
							{
								id: 430,
								name: "Gihindamuyaga",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2395,
										name: "KABAKONO",
										generalAssembly_id: 430,
									},
									{
										id: 2396,
										name: "NDOBOGO",
										generalAssembly_id: 430,
									},
									{
										id: 2397,
										name: "NYABISINDU",
										generalAssembly_id: 430,
									},
									{
										id: 2398,
										name: "RUGANGO",
										generalAssembly_id: 430,
									},
									{
										id: 9405,
										name: "Gihindamuyaga Court of Appeal ",
										generalAssembly_id: 430,
									},
									{
										id: 10938,
										name: "Gihindamuyaga Gacaca court of sector ",
										generalAssembly_id: 430,
									},
								],
							},
							{
								id: 431,
								name: "Kabuga",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2399,
										name: "GASHARU",
										generalAssembly_id: 431,
									},
									{
										id: 2400,
										name: "GICUBUKA",
										generalAssembly_id: 431,
									},
									{
										id: 2401,
										name: "MPINGA",
										generalAssembly_id: 431,
									},
									{
										id: 9406,
										name: "Kabuga Court of Appeal ",
										generalAssembly_id: 431,
									},
									{
										id: 10939,
										name: "Kabuga Gacaca court of sector ",
										generalAssembly_id: 431,
									},
								],
							},
							{
								id: 432,
								name: "Mbogo",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2402,
										name: "GAHANGA",
										generalAssembly_id: 432,
									},
									{
										id: 2403,
										name: "KIBIRARO",
										generalAssembly_id: 432,
									},
									{
										id: 2404,
										name: "KIMUNA",
										generalAssembly_id: 432,
									},
									{
										id: 9407,
										name: "Mbogo Court of Appeal ",
										generalAssembly_id: 432,
									},
									{
										id: 10940,
										name: "Mbogo Gacaca court of sector ",
										generalAssembly_id: 432,
									},
								],
							},
							{
								id: 433,
								name: "Mutunda",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2405,
										name: "GITWA",
										generalAssembly_id: 433,
									},
									{
										id: 2406,
										name: "KAGERA",
										generalAssembly_id: 433,
									},
									{
										id: 2407,
										name: "KIGUSA",
										generalAssembly_id: 433,
									},
									{
										id: 2408,
										name: "RURYANGO",
										generalAssembly_id: 433,
									},
									{
										id: 9408,
										name: "Mutunda Court of Appeal ",
										generalAssembly_id: 433,
									},
									{
										id: 10941,
										name: "Mutunda Gacaca court of sector ",
										generalAssembly_id: 433,
									},
								],
							},
							{
								id: 434,
								name: "Mwurire",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2409,
										name: "BUMBOGO",
										generalAssembly_id: 434,
									},
									{
										id: 2410,
										name: "CYAYOVE",
										generalAssembly_id: 434,
									},
									{
										id: 2411,
										name: "KABURUBA",
										generalAssembly_id: 434,
									},
									{
										id: 2412,
										name: "MURAMBI",
										generalAssembly_id: 434,
									},
									{
										id: 9409,
										name: "Mwurire Court of Appeal ",
										generalAssembly_id: 434,
									},
									{
										id: 10942,
										name: "Mwurire Gacaca court of sector ",
										generalAssembly_id: 434,
									},
								],
							},
							{
								id: 435,
								name: "Rusagara",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2413,
										name: "BUHORO",
										generalAssembly_id: 435,
									},
									{
										id: 2414,
										name: "GITWA",
										generalAssembly_id: 435,
									},
									{
										id: 2415,
										name: "KIBIRIZI",
										generalAssembly_id: 435,
									},
									{
										id: 9410,
										name: "Rusagara Court of Appeal ",
										generalAssembly_id: 435,
									},
									{
										id: 10943,
										name: "Rusagara Gacaca court of sector ",
										generalAssembly_id: 435,
									},
								],
							},
							{
								id: 436,
								name: "Tare",
								sector_id: 10407,
								courtOfCells: [
									{
										id: 2416,
										name: "GASHIKIRI",
										generalAssembly_id: 436,
									},
									{
										id: 2417,
										name: "KAVUMU",
										generalAssembly_id: 436,
									},
									{
										id: 2418,
										name: "MWENDO",
										generalAssembly_id: 436,
									},
									{
										id: 9411,
										name: "Tare Court of Appeal ",
										generalAssembly_id: 436,
									},
									{
										id: 10944,
										name: "Tare Gacaca court of sector ",
										generalAssembly_id: 436,
									},
								],
							},
						],
					},
					{
						id: 10408,
						name: "Mukura",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040804,
								name: "Rango A",
								sector_id: 10408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040803,
								name: "Icyeru",
								sector_id: 10408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040802,
								name: "Buvumu",
								sector_id: 10408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040801,
								name: "Bukomeye",
								sector_id: 10408,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 437,
								name: "Nkubi",
								sector_id: 10408,
								courtOfCells: [
									{
										id: 2419,
										name: "AGAKERA",
										generalAssembly_id: 437,
									},
									{
										id: 2420,
										name: "AGASHARU",
										generalAssembly_id: 437,
									},
									{
										id: 2421,
										name: "NYAGACYAMU",
										generalAssembly_id: 437,
									},
									{
										id: 2422,
										name: "RWINUMA",
										generalAssembly_id: 437,
									},
									{
										id: 9412,
										name: "Nkubi Court of Appeal ",
										generalAssembly_id: 437,
									},
									{
										id: 10945,
										name: "Nkubi Gacaca court of sector ",
										generalAssembly_id: 437,
									},
								],
							},
							{
								id: 438,
								name: "Sahera",
								sector_id: 10408,
								courtOfCells: [
									{
										id: 2423,
										name: "AKABIZIKO",
										generalAssembly_id: 438,
									},
									{
										id: 2424,
										name: "AKANIGA",
										generalAssembly_id: 438,
									},
									{
										id: 2425,
										name: "AKAREHE",
										generalAssembly_id: 438,
									},
									{
										id: 2426,
										name: "ICYERU",
										generalAssembly_id: 438,
									},
									{
										id: 9413,
										name: "Sahera Court of Appeal ",
										generalAssembly_id: 438,
									},
									{
										id: 10946,
										name: "Sahera Gacaca court of sector ",
										generalAssembly_id: 438,
									},
								],
							},
							{
								id: 439,
								name: "Buvumo",
								sector_id: 10408,
								courtOfCells: [
									{
										id: 2427,
										name: "KIRAMA",
										generalAssembly_id: 439,
									},
									{
										id: 2428,
										name: "NYAKABAZI",
										generalAssembly_id: 439,
									},
									{
										id: 2429,
										name: "RUTARE",
										generalAssembly_id: 439,
									},
									{
										id: 2430,
										name: "RWINTEMBE",
										generalAssembly_id: 439,
									},
									{
										id: 9414,
										name: "Buvumo Court of Appeal ",
										generalAssembly_id: 439,
									},
									{
										id: 10947,
										name: "Buvumo Gacaca court of sector ",
										generalAssembly_id: 439,
									},
								],
							},
							{
								id: 440,
								name: "Mubumbano",
								sector_id: 10408,
								courtOfCells: [
									{
										id: 2431,
										name: "BWERAMANA",
										generalAssembly_id: 440,
									},
									{
										id: 2432,
										name: "KIZENGA",
										generalAssembly_id: 440,
									},
									{
										id: 2433,
										name: "RWINKUBA",
										generalAssembly_id: 440,
									},
									{
										id: 2434,
										name: "SHINGANGABO",
										generalAssembly_id: 440,
									},
									{
										id: 9415,
										name: "Mubumbano Court of Appeal ",
										generalAssembly_id: 440,
									},
									{
										id: 10948,
										name: "Mubumbano Gacaca court of sector ",
										generalAssembly_id: 440,
									},
								],
							},
						],
					},
					{
						id: 10409,
						name: "Ngoma",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1040904,
								name: "Ngoma",
								sector_id: 10409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040903,
								name: "Matyazo",
								sector_id: 10409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040902,
								name: "Kaburemera",
								sector_id: 10409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1040901,
								name: "Butare",
								sector_id: 10409,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 441,
								name: "ButareVille",
								sector_id: 10409,
								courtOfCells: [
									{
										id: 2435,
										name: "BUYE",
										generalAssembly_id: 441,
									},
									{
										id: 2436,
										name: "KABUTARE",
										generalAssembly_id: 441,
									},
									{
										id: 2437,
										name: "MAMBA",
										generalAssembly_id: 441,
									},
									{
										id: 9416,
										name: "ButareVille Court of Appeal ",
										generalAssembly_id: 441,
									},
									{
										id: 10949,
										name: "ButareVille Gacaca court of sector ",
										generalAssembly_id: 441,
									},
								],
							},
							{
								id: 442,
								name: "Kaburemera",
								sector_id: 10409,
								courtOfCells: [
									{
										id: 2438,
										name: "KIRAVUMBA",
										generalAssembly_id: 442,
									},
									{
										id: 2439,
										name: "NYARURENDA",
										generalAssembly_id: 442,
									},
									{
										id: 2440,
										name: "RUNGA",
										generalAssembly_id: 442,
									},
									{
										id: 9417,
										name: "Kaburemera Court of Appeal ",
										generalAssembly_id: 442,
									},
									{
										id: 10950,
										name: "Kaburemera Gacaca court of sector ",
										generalAssembly_id: 442,
									},
								],
							},
							{
								id: 443,
								name: "Matyazo",
								sector_id: 10409,
								courtOfCells: [
									{
										id: 2441,
										name: "RURENDA",
										generalAssembly_id: 443,
									},
									{
										id: 2442,
										name: "TONGA",
										generalAssembly_id: 443,
									},
									{
										id: 9418,
										name: "Matyazo Court of Appeal ",
										generalAssembly_id: 443,
									},
									{
										id: 10951,
										name: "Matyazo Gacaca court of sector ",
										generalAssembly_id: 443,
									},
								],
							},
							{
								id: 444,
								name: "Ngoma",
								sector_id: 10409,
								courtOfCells: [
									{
										id: 2443,
										name: "NGOMA A",
										generalAssembly_id: 444,
									},
									{
										id: 2444,
										name: "NGOMA B",
										generalAssembly_id: 444,
									},
									{
										id: 9419,
										name: "Ngoma Court of Appeal ",
										generalAssembly_id: 444,
									},
									{
										id: 10952,
										name: "Ngoma Gacaca court of sector ",
										generalAssembly_id: 444,
									},
								],
							},
						],
					},
					{
						id: 10410,
						name: "Ruhashya",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1041007,
								name: "Ruhashya",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041006,
								name: "Rugogwe",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041005,
								name: "Muhororo",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041004,
								name: "Mara",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041003,
								name: "Karama",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041002,
								name: "Gatovu",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041001,
								name: "Busheshi",
								sector_id: 10410,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 445,
								name: "Busheshi",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2445,
										name: "BUSHESHI A",
										generalAssembly_id: 445,
									},
									{
										id: 2446,
										name: "BUSHESHI B",
										generalAssembly_id: 445,
									},
									{
										id: 2447,
										name: "NYABIJYO",
										generalAssembly_id: 445,
									},
									{
										id: 2448,
										name: "NYAGATOVU",
										generalAssembly_id: 445,
									},
									{
										id: 9420,
										name: "Busheshi Court of Appeal ",
										generalAssembly_id: 445,
									},
									{
										id: 10953,
										name: "Busheshi Gacaca court of sector ",
										generalAssembly_id: 445,
									},
								],
							},
							{
								id: 446,
								name: "Gatovu",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2449,
										name: "DUTARE",
										generalAssembly_id: 446,
									},
									{
										id: 2450,
										name: "KIGOMA",
										generalAssembly_id: 446,
									},
									{
										id: 2451,
										name: "RUGARAMA",
										generalAssembly_id: 446,
									},
									{
										id: 9421,
										name: "Gatovu Court of Appeal ",
										generalAssembly_id: 446,
									},
									{
										id: 10954,
										name: "Gatovu Gacaca court of sector ",
										generalAssembly_id: 446,
									},
								],
							},
							{
								id: 447,
								name: "Mara",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2452,
										name: "BWANKUSI",
										generalAssembly_id: 447,
									},
									{
										id: 2453,
										name: "BWANYAKABWA",
										generalAssembly_id: 447,
									},
									{
										id: 2454,
										name: "GASHIKIRI",
										generalAssembly_id: 447,
									},
									{
										id: 9422,
										name: "Mara Court of Appeal ",
										generalAssembly_id: 447,
									},
									{
										id: 10955,
										name: "Mara Gacaca court of sector ",
										generalAssembly_id: 447,
									},
								],
							},
							{
								id: 448,
								name: "Rugogwe",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2455,
										name: "NYAKIBUNGO",
										generalAssembly_id: 448,
									},
									{
										id: 2456,
										name: "RUGOGWE",
										generalAssembly_id: 448,
									},
									{
										id: 9423,
										name: "Rugogwe Court of Appeal ",
										generalAssembly_id: 448,
									},
									{
										id: 10956,
										name: "Rugogwe Gacaca court of sector ",
										generalAssembly_id: 448,
									},
								],
							},
							{
								id: 449,
								name: "Ruhashya",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2457,
										name: "GASANDO",
										generalAssembly_id: 449,
									},
									{
										id: 2458,
										name: "MBAGABAGA",
										generalAssembly_id: 449,
									},
									{
										id: 2459,
										name: "MUGINGA",
										generalAssembly_id: 449,
									},
									{
										id: 2460,
										name: "RUHASHYA",
										generalAssembly_id: 449,
									},
									{
										id: 9424,
										name: "Ruhashya Court of Appeal ",
										generalAssembly_id: 449,
									},
									{
										id: 10957,
										name: "Ruhashya Gacaca court of sector ",
										generalAssembly_id: 449,
									},
								],
							},
							{
								id: 450,
								name: "Karama",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2461,
										name: "GAKONI",
										generalAssembly_id: 450,
									},
									{
										id: 2462,
										name: "GIHISI",
										generalAssembly_id: 450,
									},
									{
										id: 2463,
										name: "KAZENGA",
										generalAssembly_id: 450,
									},
									{
										id: 2464,
										name: "NYAKIGEZI",
										generalAssembly_id: 450,
									},
									{
										id: 9425,
										name: "Karama Court of Appeal ",
										generalAssembly_id: 450,
									},
									{
										id: 10958,
										name: "Karama Gacaca court of sector ",
										generalAssembly_id: 450,
									},
								],
							},
							{
								id: 451,
								name: "Muhororo",
								sector_id: 10410,
								courtOfCells: [
									{
										id: 2465,
										name: "AGASHARU",
										generalAssembly_id: 451,
									},
									{
										id: 2466,
										name: "KINZIRAMUHINDO",
										generalAssembly_id: 451,
									},
									{
										id: 2467,
										name: "SHYARA",
										generalAssembly_id: 451,
									},
									{
										id: 9426,
										name: "Muhororo Court of Appeal ",
										generalAssembly_id: 451,
									},
									{
										id: 10959,
										name: "Muhororo Gacaca court of sector ",
										generalAssembly_id: 451,
									},
								],
							},
						],
					},
					{
						id: 10411,
						name: "Rusatira",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1041106,
								name: "Mugogwe",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041105,
								name: "Kiruhura",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041104,
								name: "Kimuna",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041103,
								name: "Kimirehe",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041102,
								name: "Gafumba",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041101,
								name: "Buhimba",
								sector_id: 10411,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 452,
								name: "Gikirambwa",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2468,
										name: "AKAGARAMA",
										generalAssembly_id: 452,
									},
									{
										id: 2469,
										name: "ISAR-RUBONA",
										generalAssembly_id: 452,
									},
									{
										id: 2470,
										name: "MUSASU",
										generalAssembly_id: 452,
									},
									{
										id: 2471,
										name: "RUGARAMA",
										generalAssembly_id: 452,
									},
									{
										id: 9427,
										name: "Gikirambwa Court of Appeal ",
										generalAssembly_id: 452,
									},
									{
										id: 10960,
										name: "Gikirambwa Gacaca court of sector ",
										generalAssembly_id: 452,
									},
								],
							},
							{
								id: 453,
								name: "Kato",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2472,
										name: "BUSHYAMA",
										generalAssembly_id: 453,
									},
									{
										id: 2473,
										name: "KIMIREHE",
										generalAssembly_id: 453,
									},
									{
										id: 2474,
										name: "MAHEMBE",
										generalAssembly_id: 453,
									},
									{
										id: 9428,
										name: "Kato Court of Appeal ",
										generalAssembly_id: 453,
									},
									{
										id: 10961,
										name: "Kato Gacaca court of sector ",
										generalAssembly_id: 453,
									},
								],
							},
							{
								id: 454,
								name: "Rusatira",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2475,
										name: "BUHIMBA",
										generalAssembly_id: 454,
									},
									{
										id: 2476,
										name: "KINKANGA",
										generalAssembly_id: 454,
									},
									{
										id: 2477,
										name: "RUSATIRA",
										generalAssembly_id: 454,
									},
									{
										id: 9429,
										name: "Rusatira Court of Appeal ",
										generalAssembly_id: 454,
									},
									{
										id: 10962,
										name: "Rusatira Gacaca court of sector ",
										generalAssembly_id: 454,
									},
								],
							},
							{
								id: 455,
								name: "Gafumba",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2478,
										name: "KABALIZA",
										generalAssembly_id: 455,
									},
									{
										id: 2479,
										name: "KABURANJWIRI",
										generalAssembly_id: 455,
									},
									{
										id: 2480,
										name: "KIGARAMA",
										generalAssembly_id: 455,
									},
									{
										id: 9430,
										name: "Gafumba Court of Appeal ",
										generalAssembly_id: 455,
									},
									{
										id: 10963,
										name: "Gafumba Gacaca court of sector ",
										generalAssembly_id: 455,
									},
								],
							},
							{
								id: 456,
								name: "Kimuna",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2481,
										name: "KAMABUYE",
										generalAssembly_id: 456,
									},
									{
										id: 2482,
										name: "KIMIGO",
										generalAssembly_id: 456,
									},
									{
										id: 2483,
										name: "KIMUNA",
										generalAssembly_id: 456,
									},
									{
										id: 2484,
										name: "NYABUSUNZU",
										generalAssembly_id: 456,
									},
									{
										id: 9431,
										name: "Kimuna Court of Appeal ",
										generalAssembly_id: 456,
									},
									{
										id: 10964,
										name: "Kimuna Gacaca court of sector ",
										generalAssembly_id: 456,
									},
								],
							},
							{
								id: 457,
								name: "Mugogwe",
								sector_id: 10411,
								courtOfCells: [
									{
										id: 2485,
										name: "GACACA",
										generalAssembly_id: 457,
									},
									{
										id: 2486,
										name: "GISENYI",
										generalAssembly_id: 457,
									},
									{
										id: 2487,
										name: "MUGOGWE",
										generalAssembly_id: 457,
									},
									{
										id: 9432,
										name: "Mugogwe Court of Appeal ",
										generalAssembly_id: 457,
									},
									{
										id: 10965,
										name: "Mugogwe Gacaca court of sector ",
										generalAssembly_id: 457,
									},
								],
							},
						],
					},
					{
						id: 10412,
						name: "Rwaniro",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1041207,
								name: "Shyunga",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041206,
								name: "Nyaruhombo",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041205,
								name: "Nyamabuye",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041204,
								name: "Mwendo",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041203,
								name: "Kibiraro",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041202,
								name: "Kamwambi",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041201,
								name: "Gatwaro",
								sector_id: 10412,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 458,
								name: "Gashoba",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2488,
										name: "KARAMA",
										generalAssembly_id: 458,
									},
									{
										id: 2489,
										name: "KARUGUMYA",
										generalAssembly_id: 458,
									},
									{
										id: 2490,
										name: "KIBOGA",
										generalAssembly_id: 458,
									},
									{
										id: 2491,
										name: "RUGARAMA",
										generalAssembly_id: 458,
									},
									{
										id: 9433,
										name: "Gashoba Court of Appeal ",
										generalAssembly_id: 458,
									},
									{
										id: 10966,
										name: "Gashoba Gacaca court of sector ",
										generalAssembly_id: 458,
									},
								],
							},
							{
								id: 459,
								name: "Kibika",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2492,
										name: "MUREHE",
										generalAssembly_id: 459,
									},
									{
										id: 2493,
										name: "NYAMIVUMU",
										generalAssembly_id: 459,
									},
									{
										id: 9434,
										name: "Kibika Court of Appeal ",
										generalAssembly_id: 459,
									},
									{
										id: 10967,
										name: "Kibika Gacaca court of sector ",
										generalAssembly_id: 459,
									},
								],
							},
							{
								id: 460,
								name: "Kigarama",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2494,
										name: "BURANGA",
										generalAssembly_id: 460,
									},
									{
										id: 2495,
										name: "KIGARAMA",
										generalAssembly_id: 460,
									},
									{
										id: 9435,
										name: "Kigarama Court of Appeal ",
										generalAssembly_id: 460,
									},
									{
										id: 10968,
										name: "Kigarama Gacaca court of sector ",
										generalAssembly_id: 460,
									},
								],
							},
							{
								id: 461,
								name: "Rwaniro",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2496,
										name: "KIRWA",
										generalAssembly_id: 461,
									},
									{
										id: 2497,
										name: "RWANIRO",
										generalAssembly_id: 461,
									},
									{
										id: 9436,
										name: "Rwaniro Court of Appeal ",
										generalAssembly_id: 461,
									},
									{
										id: 10969,
										name: "Rwaniro Gacaca court of sector ",
										generalAssembly_id: 461,
									},
								],
							},
							{
								id: 462,
								name: "Sheke",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2498,
										name: "BWERAMANA",
										generalAssembly_id: 462,
									},
									{
										id: 2499,
										name: "GISHYITA",
										generalAssembly_id: 462,
									},
									{
										id: 2500,
										name: "BITARE",
										generalAssembly_id: 462,
									},
									{
										id: 9437,
										name: "Sheke Court of Appeal ",
										generalAssembly_id: 462,
									},
									{
										id: 10970,
										name: "Sheke Gacaca court of sector ",
										generalAssembly_id: 462,
									},
								],
							},
							{
								id: 463,
								name: "Kamweru",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2501,
										name: "GASHARU",
										generalAssembly_id: 463,
									},
									{
										id: 2502,
										name: "KIBARA",
										generalAssembly_id: 463,
									},
									{
										id: 2503,
										name: "KIGARAMA",
										generalAssembly_id: 463,
									},
									{
										id: 2504,
										name: "MURAMBI",
										generalAssembly_id: 463,
									},
									{
										id: 2505,
										name: "MWEZI",
										generalAssembly_id: 463,
									},
									{
										id: 2506,
										name: "NYABUJENGWE",
										generalAssembly_id: 463,
									},
									{
										id: 2507,
										name: "RUGARAMA",
										generalAssembly_id: 463,
									},
									{
										id: 2508,
										name: "RUKIRIRO",
										generalAssembly_id: 463,
									},
									{
										id: 2509,
										name: "RYARUGORA",
										generalAssembly_id: 463,
									},
									{
										id: 9438,
										name: "Kamweru Court of Appeal ",
										generalAssembly_id: 463,
									},
									{
										id: 10971,
										name: "Kamweru Gacaca court of sector ",
										generalAssembly_id: 463,
									},
								],
							},
							{
								id: 464,
								name: "Muhanga",
								sector_id: 10412,
								courtOfCells: [
									{
										id: 2510,
										name: "KAMWAMBI",
										generalAssembly_id: 464,
									},
									{
										id: 2511,
										name: "KARAMBO",
										generalAssembly_id: 464,
									},
									{
										id: 2512,
										name: "KASHYAKA",
										generalAssembly_id: 464,
									},
									{
										id: 2513,
										name: "KIBUGAZI",
										generalAssembly_id: 464,
									},
									{
										id: 2514,
										name: "NYABINYENGA",
										generalAssembly_id: 464,
									},
									{
										id: 2515,
										name: "NYARUBANDE",
										generalAssembly_id: 464,
									},
									{
										id: 2516,
										name: "RWIMPIRI",
										generalAssembly_id: 464,
									},
									{
										id: 9439,
										name: "Muhanga Court of Appeal ",
										generalAssembly_id: 464,
									},
									{
										id: 10972,
										name: "Muhanga Gacaca court of sector ",
										generalAssembly_id: 464,
									},
								],
							},
						],
					},
					{
						id: 10413,
						name: "Simbi",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1041305,
								name: "Nyangazi",
								sector_id: 10413,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041304,
								name: "Mugobore",
								sector_id: 10413,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041303,
								name: "Kabusanza",
								sector_id: 10413,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041302,
								name: "Gisakura",
								sector_id: 10413,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041301,
								name: "Cyendajuru",
								sector_id: 10413,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 465,
								name: "Bunzazi",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2517,
										name: "MARAMBO",
										generalAssembly_id: 465,
									},
									{
										id: 2518,
										name: "RUHERU",
										generalAssembly_id: 465,
									},
									{
										id: 9440,
										name: "Bunzazi Court of Appeal ",
										generalAssembly_id: 465,
									},
									{
										id: 10973,
										name: "Bunzazi Gacaca court of sector ",
										generalAssembly_id: 465,
									},
								],
							},
							{
								id: 466,
								name: "Gisakura",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2519,
										name: "GASHARU",
										generalAssembly_id: 466,
									},
									{
										id: 2520,
										name: "KADUHA",
										generalAssembly_id: 466,
									},
									{
										id: 2521,
										name: "RWIMPYISI",
										generalAssembly_id: 466,
									},
									{
										id: 9441,
										name: "Gisakura Court of Appeal ",
										generalAssembly_id: 466,
									},
									{
										id: 10974,
										name: "Gisakura Gacaca court of sector ",
										generalAssembly_id: 466,
									},
								],
							},
							{
								id: 467,
								name: "Kabusanza",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2522,
										name: "BWIZA",
										generalAssembly_id: 467,
									},
									{
										id: 2523,
										name: "MALIZA",
										generalAssembly_id: 467,
									},
									{
										id: 2524,
										name: "NTOBWE",
										generalAssembly_id: 467,
									},
									{
										id: 9442,
										name: "Kabusanza Court of Appeal ",
										generalAssembly_id: 467,
									},
									{
										id: 10975,
										name: "Kabusanza Gacaca court of sector ",
										generalAssembly_id: 467,
									},
								],
							},
							{
								id: 468,
								name: "Kibanda",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2525,
										name: "CYENDAJURU",
										generalAssembly_id: 468,
									},
									{
										id: 2526,
										name: "RWATSI",
										generalAssembly_id: 468,
									},
									{
										id: 2527,
										name: "RYAMUGANZA",
										generalAssembly_id: 468,
									},
									{
										id: 9443,
										name: "Kibanda Court of Appeal ",
										generalAssembly_id: 468,
									},
									{
										id: 10976,
										name: "Kibanda Gacaca court of sector ",
										generalAssembly_id: 468,
									},
								],
							},
							{
								id: 469,
								name: "Nyangazi",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2528,
										name: "NGORORERO",
										generalAssembly_id: 469,
									},
									{
										id: 2529,
										name: "NYARUKURAZO",
										generalAssembly_id: 469,
									},
									{
										id: 2530,
										name: "SHUNGA",
										generalAssembly_id: 469,
									},
									{
										id: 2531,
										name: "UMUYANGE",
										generalAssembly_id: 469,
									},
									{
										id: 9444,
										name: "Nyangazi Court of Appeal ",
										generalAssembly_id: 469,
									},
									{
										id: 10977,
										name: "Nyangazi Gacaca court of sector ",
										generalAssembly_id: 469,
									},
								],
							},
							{
								id: 470,
								name: "Simbi",
								sector_id: 10413,
								courtOfCells: [
									{
										id: 2532,
										name: "MPANDA",
										generalAssembly_id: 470,
									},
									{
										id: 2533,
										name: "NYAMIRAMA",
										generalAssembly_id: 470,
									},
									{
										id: 2534,
										name: "NYARUREMBO",
										generalAssembly_id: 470,
									},
									{
										id: 9445,
										name: "Simbi Court of Appeal ",
										generalAssembly_id: 470,
									},
									{
										id: 10978,
										name: "Simbi Gacaca court of sector ",
										generalAssembly_id: 470,
									},
								],
							},
						],
					},
					{
						id: 10414,
						name: "Tumba",
						district_id: 104,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1041405,
								name: "Rango B",
								sector_id: 10414,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041404,
								name: "Mpare",
								sector_id: 10414,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041403,
								name: "Gitwa",
								sector_id: 10414,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041402,
								name: "Cyimana",
								sector_id: 10414,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1041401,
								name: "Cyarwa",
								sector_id: 10414,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 471,
								name: "Cyarwa-Cyimana",
								sector_id: 10414,
								courtOfCells: [
									{
										id: 2535,
										name: "AGAKENYERI",
										generalAssembly_id: 471,
									},
									{
										id: 2536,
										name: "AKAKAREHE",
										generalAssembly_id: 471,
									},
									{
										id: 2537,
										name: "AKAMUZERWA",
										generalAssembly_id: 471,
									},
									{
										id: 9446,
										name: "Cyarwa-Cyimana Court of Appeal ",
										generalAssembly_id: 471,
									},
									{
										id: 10979,
										name: "Cyarwa-Cyimana Gacaca court of sector ",
										generalAssembly_id: 471,
									},
								],
							},
							{
								id: 472,
								name: "Cyarwa-Sumo",
								sector_id: 10414,
								courtOfCells: [
									{
										id: 2538,
										name: "AGAHORA",
										generalAssembly_id: 472,
									},
									{
										id: 2539,
										name: "AGASENGASENGE",
										generalAssembly_id: 472,
									},
									{
										id: 2540,
										name: "ICYIRI",
										generalAssembly_id: 472,
									},
									{
										id: 9447,
										name: "Cyarwa-Sumo Court of Appeal ",
										generalAssembly_id: 472,
									},
									{
										id: 10980,
										name: "Cyarwa-Sumo Gacaca court of sector ",
										generalAssembly_id: 472,
									},
								],
							},
							{
								id: 473,
								name: "Mpare",
								sector_id: 10414,
								courtOfCells: [
									{
										id: 2541,
										name: "AGASHARU",
										generalAssembly_id: 473,
									},
									{
										id: 2542,
										name: "RUNYINYA",
										generalAssembly_id: 473,
									},
									{
										id: 9448,
										name: "Mpare Court of Appeal ",
										generalAssembly_id: 473,
									},
									{
										id: 10981,
										name: "Mpare Gacaca court of sector ",
										generalAssembly_id: 473,
									},
								],
							},
							{
								id: 474,
								name: "Musange",
								sector_id: 10414,
								courtOfCells: [
									{
										id: 2543,
										name: "AKUBUTARE",
										generalAssembly_id: 474,
									},
									{
										id: 2544,
										name: "AKARUGIRANKA",
										generalAssembly_id: 474,
									},
									{
										id: 9449,
										name: "Musange Court of Appeal ",
										generalAssembly_id: 474,
									},
									{
										id: 10982,
										name: "Musange Gacaca court of sector ",
										generalAssembly_id: 474,
									},
								],
							},
							{
								id: 475,
								name: "Tumba",
								sector_id: 10414,
								courtOfCells: [
									{
										id: 2545,
										name: "GITWA",
										generalAssembly_id: 475,
									},
									{
										id: 2546,
										name: "KIGARAMA",
										generalAssembly_id: 475,
									},
									{
										id: 2547,
										name: "RANGO A",
										generalAssembly_id: 475,
									},
									{
										id: 2548,
										name: "RANGO B",
										generalAssembly_id: 475,
									},
									{
										id: 2549,
										name: "UBUTAMENWA",
										generalAssembly_id: 475,
									},
									{
										id: 9450,
										name: "Tumba Court of Appeal ",
										generalAssembly_id: 475,
									},
									{
										id: 10983,
										name: "Tumba Gacaca court of sector ",
										generalAssembly_id: 475,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 108,
				name: "Kamonyi",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10801,
						name: "Gacurabwenge",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080104,
								name: "Nkingo",
								sector_id: 10801,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080103,
								name: "Kigembe",
								sector_id: 10801,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080102,
								name: "Gihira",
								sector_id: 10801,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080101,
								name: "Gihinga",
								sector_id: 10801,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 476,
								name: "Gatizo  ",
								sector_id: 10801,
								courtOfCells: [
									{
										id: 2550,
										name: "BUHORO",
										generalAssembly_id: 476,
									},
									{
										id: 2551,
										name: "KIGEMBE",
										generalAssembly_id: 476,
									},
									{
										id: 2552,
										name: "MIGINA",
										generalAssembly_id: 476,
									},
									{
										id: 2553,
										name: "MUNANIRA",
										generalAssembly_id: 476,
									},
									{
										id: 2554,
										name: "NYAKABUNGO",
										generalAssembly_id: 476,
									},
									{
										id: 2555,
										name: "RUGOBAGOBA",
										generalAssembly_id: 476,
									},
									{
										id: 2556,
										name: "RUHANGO",
										generalAssembly_id: 476,
									},
									{
										id: 9451,
										name: "Gatizo   Court of Appeal ",
										generalAssembly_id: 476,
									},
									{
										id: 10984,
										name: "Gatizo   Gacaca court of sector ",
										generalAssembly_id: 476,
									},
								],
							},
							{
								id: 477,
								name: "Gihira  ",
								sector_id: 10801,
								courtOfCells: [
									{
										id: 2557,
										name: "BUGABA",
										generalAssembly_id: 477,
									},
									{
										id: 2558,
										name: "KIBANZA",
										generalAssembly_id: 477,
									},
									{
										id: 2559,
										name: "KIDATURWA",
										generalAssembly_id: 477,
									},
									{
										id: 2560,
										name: "NYABITARE",
										generalAssembly_id: 477,
									},
									{
										id: 9452,
										name: "Gihira   Court of Appeal ",
										generalAssembly_id: 477,
									},
									{
										id: 10985,
										name: "Gihira   Gacaca court of sector ",
										generalAssembly_id: 477,
									},
								],
							},
							{
								id: 478,
								name: "Kamonyi  ",
								sector_id: 10801,
								courtOfCells: [
									{
										id: 2561,
										name: "GASHARU",
										generalAssembly_id: 478,
									},
									{
										id: 2562,
										name: "GIHINGA",
										generalAssembly_id: 478,
									},
									{
										id: 2563,
										name: "GISITWE",
										generalAssembly_id: 478,
									},
									{
										id: 2564,
										name: "KAGARAMA",
										generalAssembly_id: 478,
									},
									{
										id: 2565,
										name: "KAMONYI",
										generalAssembly_id: 478,
									},
									{
										id: 2566,
										name: "NKINGO",
										generalAssembly_id: 478,
									},
									{
										id: 2567,
										name: "SHENGA",
										generalAssembly_id: 478,
									},
									{
										id: 2568,
										name: "NYAGIHINGA",
										generalAssembly_id: 478,
									},
									{
										id: 2569,
										name: "NYARUGENGE",
										generalAssembly_id: 478,
									},
									{
										id: 2570,
										name: "RUBONA",
										generalAssembly_id: 478,
									},
									{
										id: 9453,
										name: "Kamonyi   Court of Appeal ",
										generalAssembly_id: 478,
									},
									{
										id: 10986,
										name: "Kamonyi   Gacaca court of sector ",
										generalAssembly_id: 478,
									},
								],
							},
						],
					},
					{
						id: 10802,
						name: "Karama",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080204,
								name: "Nyamirembe",
								sector_id: 10802,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080203,
								name: "Muganza",
								sector_id: 10802,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080202,
								name: "Bunyonga",
								sector_id: 10802,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080201,
								name: "Bitare",
								sector_id: 10802,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 479,
								name: "Bitare  ",
								sector_id: 10802,
								courtOfCells: [
									{
										id: 2571,
										name: "JANJA",
										generalAssembly_id: 479,
									},
									{
										id: 2572,
										name: "KARAMA",
										generalAssembly_id: 479,
									},
									{
										id: 2573,
										name: "NYAKABUYE",
										generalAssembly_id: 479,
									},
									{
										id: 2574,
										name: "RURAMBO",
										generalAssembly_id: 479,
									},
									{
										id: 9454,
										name: "Bitare   Court of Appeal ",
										generalAssembly_id: 479,
									},
									{
										id: 10987,
										name: "Bitare   Gacaca court of sector ",
										generalAssembly_id: 479,
									},
								],
							},
							{
								id: 480,
								name: "Bunyonga  ",
								sector_id: 10802,
								courtOfCells: [
									{
										id: 2575,
										name: "KAGOBORE",
										generalAssembly_id: 480,
									},
									{
										id: 2576,
										name: "KAMATONGO",
										generalAssembly_id: 480,
									},
									{
										id: 2577,
										name: "NYARUNYINYA",
										generalAssembly_id: 480,
									},
									{
										id: 2578,
										name: "RARO",
										generalAssembly_id: 480,
									},
									{
										id: 9455,
										name: "Bunyonga   Court of Appeal ",
										generalAssembly_id: 480,
									},
									{
										id: 10988,
										name: "Bunyonga   Gacaca court of sector ",
										generalAssembly_id: 480,
									},
								],
							},
							{
								id: 481,
								name: "Muganza  ",
								sector_id: 10802,
								courtOfCells: [
									{
										id: 2579,
										name: "BYERWA",
										generalAssembly_id: 481,
									},
									{
										id: 2580,
										name: "GASHENYI",
										generalAssembly_id: 481,
									},
									{
										id: 2581,
										name: "NYABIKONI",
										generalAssembly_id: 481,
									},
									{
										id: 2582,
										name: "RWISHIMA",
										generalAssembly_id: 481,
									},
									{
										id: 9456,
										name: "Muganza   Court of Appeal ",
										generalAssembly_id: 481,
									},
									{
										id: 10989,
										name: "Muganza   Gacaca court of sector ",
										generalAssembly_id: 481,
									},
								],
							},
							{
								id: 482,
								name: "Nyamirembe  ",
								sector_id: 10802,
								courtOfCells: [
									{
										id: 2583,
										name: "BUBAZI",
										generalAssembly_id: 482,
									},
									{
										id: 2584,
										name: "GAJI",
										generalAssembly_id: 482,
									},
									{
										id: 2585,
										name: "GASHARU",
										generalAssembly_id: 482,
									},
									{
										id: 2586,
										name: "KABERE",
										generalAssembly_id: 482,
									},
									{
										id: 2587,
										name: "KAVUMU",
										generalAssembly_id: 482,
									},
									{
										id: 9457,
										name: "Nyamirembe   Court of Appeal ",
										generalAssembly_id: 482,
									},
									{
										id: 10990,
										name: "Nyamirembe   Gacaca court of sector ",
										generalAssembly_id: 482,
									},
								],
							},
							{
								id: 483,
								name: "Marenga  ",
								sector_id: 10802,
								courtOfCells: [
									{
										id: 2588,
										name: "BUSHARA",
										generalAssembly_id: 483,
									},
									{
										id: 2589,
										name: "KAYONZA",
										generalAssembly_id: 483,
									},
									{
										id: 2590,
										name: "KIBUYE",
										generalAssembly_id: 483,
									},
									{
										id: 2591,
										name: "MUZA",
										generalAssembly_id: 483,
									},
									{
										id: 2592,
										name: "NYABUBARE",
										generalAssembly_id: 483,
									},
									{
										id: 2593,
										name: "RUGARAGARA",
										generalAssembly_id: 483,
									},
									{
										id: 9458,
										name: "Marenga   Court of Appeal ",
										generalAssembly_id: 483,
									},
									{
										id: 10991,
										name: "Marenga   Gacaca court of sector ",
										generalAssembly_id: 483,
									},
								],
							},
						],
					},
					{
						id: 10803,
						name: "Kayenzi",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080306,
								name: "Nyamirama",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080305,
								name: "Mataba",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080304,
								name: "Kirwa",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080303,
								name: "Kayonza",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080302,
								name: "Cubi",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080301,
								name: "Bugarama",
								sector_id: 10803,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 484,
								name: "Bugarama  ",
								sector_id: 10803,
								courtOfCells: [
									{
										id: 2594,
										name: "BUHURURA",
										generalAssembly_id: 484,
									},
									{
										id: 2595,
										name: "MUNYEGERA",
										generalAssembly_id: 484,
									},
									{
										id: 2596,
										name: "NYARURAMA",
										generalAssembly_id: 484,
									},
									{
										id: 2597,
										name: "REMERA",
										generalAssembly_id: 484,
									},
									{
										id: 9459,
										name: "Bugarama   Court of Appeal ",
										generalAssembly_id: 484,
									},
									{
										id: 10992,
										name: "Bugarama   Gacaca court of sector ",
										generalAssembly_id: 484,
									},
								],
							},
							{
								id: 485,
								name: "Cubi  ",
								sector_id: 10803,
								courtOfCells: [
									{
										id: 2598,
										name: "GITWA",
										generalAssembly_id: 485,
									},
									{
										id: 2599,
										name: "KAMABUYE",
										generalAssembly_id: 485,
									},
									{
										id: 2600,
										name: "NTWALI",
										generalAssembly_id: 485,
									},
									{
										id: 2601,
										name: "NYAKIGEZI",
										generalAssembly_id: 485,
									},
									{
										id: 9460,
										name: "Cubi   Court of Appeal ",
										generalAssembly_id: 485,
									},
									{
										id: 10993,
										name: "Cubi   Gacaca court of sector ",
										generalAssembly_id: 485,
									},
								],
							},
							{
								id: 486,
								name: "Kayenzi  ",
								sector_id: 10803,
								courtOfCells: [
									{
										id: 2602,
										name: "GASASA",
										generalAssembly_id: 486,
									},
									{
										id: 2603,
										name: "GIKURUBUYE",
										generalAssembly_id: 486,
									},
									{
										id: 2604,
										name: "KABONA",
										generalAssembly_id: 486,
									},
									{
										id: 2605,
										name: "NYARUBAYA",
										generalAssembly_id: 486,
									},
									{
										id: 2606,
										name: "RUGOMA",
										generalAssembly_id: 486,
									},
									{
										id: 9461,
										name: "Kayenzi   Court of Appeal ",
										generalAssembly_id: 486,
									},
									{
										id: 10994,
										name: "Kayenzi   Gacaca court of sector ",
										generalAssembly_id: 486,
									},
								],
							},
							{
								id: 487,
								name: "Kirwa  ",
								sector_id: 10803,
								courtOfCells: [
									{
										id: 2607,
										name: "GASAMBA",
										generalAssembly_id: 487,
									},
									{
										id: 2608,
										name: "GISIZI",
										generalAssembly_id: 487,
									},
									{
										id: 2609,
										name: "GITWA",
										generalAssembly_id: 487,
									},
									{
										id: 2610,
										name: "KIGUNGA",
										generalAssembly_id: 487,
									},
									{
										id: 2611,
										name: "RUHEKA",
										generalAssembly_id: 487,
									},
									{
										id: 9462,
										name: "Kirwa   Court of Appeal ",
										generalAssembly_id: 487,
									},
									{
										id: 10995,
										name: "Kirwa   Gacaca court of sector ",
										generalAssembly_id: 487,
									},
								],
							},
						],
					},
					{
						id: 10804,
						name: "Kayumbu",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080404,
								name: "Muyange",
								sector_id: 10804,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080403,
								name: "Giko",
								sector_id: 10804,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080402,
								name: "Gaseke",
								sector_id: 10804,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080401,
								name: "Busoro",
								sector_id: 10804,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 488,
								name: "Giko  ",
								sector_id: 10804,
								courtOfCells: [
									{
										id: 2612,
										name: "GASEKE",
										generalAssembly_id: 488,
									},
									{
										id: 2613,
										name: "GIKO",
										generalAssembly_id: 488,
									},
									{
										id: 2614,
										name: "KABERE",
										generalAssembly_id: 488,
									},
									{
										id: 2615,
										name: "KIRAMBO",
										generalAssembly_id: 488,
									},
									{
										id: 2616,
										name: "MASAMBU",
										generalAssembly_id: 488,
									},
									{
										id: 9463,
										name: "Giko   Court of Appeal ",
										generalAssembly_id: 488,
									},
									{
										id: 10996,
										name: "Giko   Gacaca court of sector ",
										generalAssembly_id: 488,
									},
								],
							},
							{
								id: 489,
								name: "Mara  ",
								sector_id: 10804,
								courtOfCells: [
									{
										id: 2617,
										name: "MUNINI",
										generalAssembly_id: 489,
									},
									{
										id: 2618,
										name: "MWARO",
										generalAssembly_id: 489,
									},
									{
										id: 2619,
										name: "NYARUREMBO",
										generalAssembly_id: 489,
									},
									{
										id: 2620,
										name: "RUHIMBI",
										generalAssembly_id: 489,
									},
									{
										id: 9464,
										name: "Mara   Court of Appeal ",
										generalAssembly_id: 489,
									},
									{
										id: 10997,
										name: "Mara   Gacaca court of sector ",
										generalAssembly_id: 489,
									},
								],
							},
							{
								id: 490,
								name: "Ntonde  ",
								sector_id: 10804,
								courtOfCells: [
									{
										id: 2621,
										name: "BUSORO",
										generalAssembly_id: 490,
									},
									{
										id: 2622,
										name: "KIRENGE",
										generalAssembly_id: 490,
									},
									{
										id: 2623,
										name: "MANYANA",
										generalAssembly_id: 490,
									},
									{
										id: 2624,
										name: "NTONDE",
										generalAssembly_id: 490,
									},
									{
										id: 2625,
										name: "RUTOBWE",
										generalAssembly_id: 490,
									},
									{
										id: 9465,
										name: "Ntonde   Court of Appeal ",
										generalAssembly_id: 490,
									},
									{
										id: 10998,
										name: "Ntonde   Gacaca court of sector ",
										generalAssembly_id: 490,
									},
								],
							},
						],
					},
					{
						id: 10805,
						name: "Mugina",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080505,
								name: "Nteko",
								sector_id: 10805,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080504,
								name: "Mugina",
								sector_id: 10805,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080503,
								name: "Mbati",
								sector_id: 10805,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080502,
								name: "Kabugondo",
								sector_id: 10805,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080501,
								name: "Jenda",
								sector_id: 10805,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 491,
								name: "Cyeru  ",
								sector_id: 10805,
								courtOfCells: [
									{
										id: 2626,
										name: "BIHENGA",
										generalAssembly_id: 491,
									},
									{
										id: 2627,
										name: "CYERU",
										generalAssembly_id: 491,
									},
									{
										id: 2628,
										name: "MATABA_SUD",
										generalAssembly_id: 491,
									},
									{
										id: 2629,
										name: "RUNZENZE",
										generalAssembly_id: 491,
									},
									{
										id: 9466,
										name: "Cyeru   Court of Appeal ",
										generalAssembly_id: 491,
									},
									{
										id: 10999,
										name: "Cyeru   Gacaca court of sector ",
										generalAssembly_id: 491,
									},
								],
							},
							{
								id: 492,
								name: "Kiyonza  ",
								sector_id: 10805,
								courtOfCells: [
									{
										id: 2630,
										name: "KIGARAMA",
										generalAssembly_id: 492,
									},
									{
										id: 2631,
										name: "KIYONZA",
										generalAssembly_id: 492,
									},
									{
										id: 2632,
										name: "MATABA_NORD",
										generalAssembly_id: 492,
									},
									{
										id: 2633,
										name: "MUNINI",
										generalAssembly_id: 492,
									},
									{
										id: 2634,
										name: "NGUGU",
										generalAssembly_id: 492,
									},
									{
										id: 9467,
										name: "Kiyonza   Court of Appeal ",
										generalAssembly_id: 492,
									},
									{
										id: 11000,
										name: "Kiyonza   Gacaca court of sector ",
										generalAssembly_id: 492,
									},
								],
							},
							{
								id: 493,
								name: "Mbati   ",
								sector_id: 10805,
								courtOfCells: [
									{
										id: 2635,
										name: "KANSORO",
										generalAssembly_id: 493,
									},
									{
										id: 2636,
										name: "KIGORORA",
										generalAssembly_id: 493,
									},
									{
										id: 2637,
										name: "MBATI",
										generalAssembly_id: 493,
									},
									{
										id: 2638,
										name: "MURAMBI",
										generalAssembly_id: 493,
									},
									{
										id: 2639,
										name: "MUREHE",
										generalAssembly_id: 493,
									},
									{
										id: 2640,
										name: "RUBONA",
										generalAssembly_id: 493,
									},
									{
										id: 9468,
										name: "Mbati    Court of Appeal ",
										generalAssembly_id: 493,
									},
									{
										id: 11001,
										name: "Mbati    Gacaca court of sector ",
										generalAssembly_id: 493,
									},
								],
							},
							{
								id: 494,
								name: "Mugina  ",
								sector_id: 10805,
								courtOfCells: [
									{
										id: 2641,
										name: "KAGASA",
										generalAssembly_id: 494,
									},
									{
										id: 2642,
										name: "KIREKA",
										generalAssembly_id: 494,
									},
									{
										id: 2643,
										name: "MPARO",
										generalAssembly_id: 494,
									},
									{
										id: 2644,
										name: "MUGINA",
										generalAssembly_id: 494,
									},
									{
										id: 9469,
										name: "Mugina   Court of Appeal ",
										generalAssembly_id: 494,
									},
									{
										id: 11002,
										name: "Mugina   Gacaca court of sector ",
										generalAssembly_id: 494,
									},
								],
							},
							{
								id: 495,
								name: "Nteko  ",
								sector_id: 10805,
								courtOfCells: [
									{
										id: 2645,
										name: "GISHARI",
										generalAssembly_id: 495,
									},
									{
										id: 2646,
										name: "NTEKO",
										generalAssembly_id: 495,
									},
									{
										id: 2647,
										name: "NYAGISOZI",
										generalAssembly_id: 495,
									},
									{
										id: 2648,
										name: "RUSOROKONA",
										generalAssembly_id: 495,
									},
									{
										id: 9470,
										name: "Nteko   Court of Appeal ",
										generalAssembly_id: 495,
									},
									{
										id: 11003,
										name: "Nteko   Gacaca court of sector ",
										generalAssembly_id: 495,
									},
								],
							},
						],
					},
					{
						id: 10806,
						name: "Musambira",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080606,
								name: "Rukambura",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080605,
								name: "Mpushi",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080604,
								name: "Kivumu",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080603,
								name: "Karengera",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080602,
								name: "Cyambwe",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080601,
								name: "Buhoro",
								sector_id: 10806,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 496,
								name: "Nkomane  ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2649,
										name: "BITSIBO",
										generalAssembly_id: 496,
									},
									{
										id: 2650,
										name: "KABINDI",
										generalAssembly_id: 496,
									},
									{
										id: 2651,
										name: "RUKAMBURA",
										generalAssembly_id: 496,
									},
									{
										id: 9471,
										name: "Nkomane   Court of Appeal ",
										generalAssembly_id: 496,
									},
									{
										id: 11004,
										name: "Nkomane   Gacaca court of sector ",
										generalAssembly_id: 496,
									},
								],
							},
							{
								id: 497,
								name: "Bimomwe  ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2652,
										name: "BIMOMWE",
										generalAssembly_id: 497,
									},
									{
										id: 2653,
										name: "CYAMBWE",
										generalAssembly_id: 497,
									},
									{
										id: 2654,
										name: "GACACA",
										generalAssembly_id: 497,
									},
									{
										id: 2655,
										name: "GIHETA",
										generalAssembly_id: 497,
									},
									{
										id: 2656,
										name: "KIGWA",
										generalAssembly_id: 497,
									},
									{
										id: 2657,
										name: "RUGARAMA",
										generalAssembly_id: 497,
									},
									{
										id: 9472,
										name: "Bimomwe   Court of Appeal ",
										generalAssembly_id: 497,
									},
									{
										id: 11005,
										name: "Bimomwe   Gacaca court of sector ",
										generalAssembly_id: 497,
									},
								],
							},
							{
								id: 498,
								name: "Gihembe  ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2658,
										name: "BUHORO",
										generalAssembly_id: 498,
									},
									{
										id: 2659,
										name: "BUSASAMANA",
										generalAssembly_id: 498,
									},
									{
										id: 2660,
										name: "GIHOGWE",
										generalAssembly_id: 498,
									},
									{
										id: 2661,
										name: "KANSHIRI",
										generalAssembly_id: 498,
									},
									{
										id: 9473,
										name: "Gihembe   Court of Appeal ",
										generalAssembly_id: 498,
									},
									{
										id: 11006,
										name: "Gihembe   Gacaca court of sector ",
										generalAssembly_id: 498,
									},
								],
							},
							{
								id: 499,
								name: "Kivumu  ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2662,
										name: "GAHONDO",
										generalAssembly_id: 499,
									},
									{
										id: 2663,
										name: "GITEGA_KIRICO",
										generalAssembly_id: 499,
									},
									{
										id: 2664,
										name: "MUNAZI",
										generalAssembly_id: 499,
									},
									{
										id: 2665,
										name: "NYAGISOZI",
										generalAssembly_id: 499,
									},
									{
										id: 2666,
										name: "NYERENGA",
										generalAssembly_id: 499,
									},
									{
										id: 2667,
										name: "WIMANA",
										generalAssembly_id: 499,
									},
									{
										id: 9474,
										name: "Kivumu   Court of Appeal ",
										generalAssembly_id: 499,
									},
									{
										id: 11007,
										name: "Kivumu   Gacaca court of sector ",
										generalAssembly_id: 499,
									},
								],
							},
							{
								id: 500,
								name: "Musambira  ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2668,
										name: "KAMAYANJA",
										generalAssembly_id: 500,
									},
									{
										id: 2669,
										name: "KARENGERA",
										generalAssembly_id: 500,
									},
									{
										id: 2670,
										name: "MBARI",
										generalAssembly_id: 500,
									},
									{
										id: 2671,
										name: "NYARUTOVU",
										generalAssembly_id: 500,
									},
									{
										id: 2672,
										name: "RUBANGA",
										generalAssembly_id: 500,
									},
									{
										id: 9475,
										name: "Musambira   Court of Appeal ",
										generalAssembly_id: 500,
									},
									{
										id: 11008,
										name: "Musambira   Gacaca court of sector ",
										generalAssembly_id: 500,
									},
								],
							},
							{
								id: 501,
								name: "Mpushi   ",
								sector_id: 10806,
								courtOfCells: [
									{
										id: 2673,
										name: "GITWIKO",
										generalAssembly_id: 501,
									},
									{
										id: 2674,
										name: "KABERE",
										generalAssembly_id: 501,
									},
									{
										id: 2675,
										name: "KAMASHASHI",
										generalAssembly_id: 501,
									},
									{
										id: 2676,
										name: "KINGOMA",
										generalAssembly_id: 501,
									},
									{
										id: 2677,
										name: "NYARUBUYE",
										generalAssembly_id: 501,
									},
									{
										id: 2678,
										name: "NYARURAMA",
										generalAssembly_id: 501,
									},
									{
										id: 9476,
										name: "Mpushi    Court of Appeal ",
										generalAssembly_id: 501,
									},
									{
										id: 11009,
										name: "Mpushi    Gacaca court of sector ",
										generalAssembly_id: 501,
									},
								],
							},
						],
					},
					{
						id: 10807,
						name: "Ngamba",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080703,
								name: "Marembo",
								sector_id: 10807,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080702,
								name: "Kazirabonde",
								sector_id: 10807,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080701,
								name: "Kabuga",
								sector_id: 10807,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 502,
								name: "Karangara  ",
								sector_id: 10807,
								courtOfCells: [
									{
										id: 2679,
										name: "GATARAGA",
										generalAssembly_id: 502,
									},
									{
										id: 2680,
										name: "KAGARAMA",
										generalAssembly_id: 502,
									},
									{
										id: 2681,
										name: "KARANGARA",
										generalAssembly_id: 502,
									},
									{
										id: 2682,
										name: "KAZIRABONDE",
										generalAssembly_id: 502,
									},
									{
										id: 2683,
										name: "MASOGWE",
										generalAssembly_id: 502,
									},
									{
										id: 2684,
										name: "MBURABUTURO",
										generalAssembly_id: 502,
									},
									{
										id: 2685,
										name: "MUCYAMO",
										generalAssembly_id: 502,
									},
									{
										id: 9477,
										name: "Karangara   Court of Appeal ",
										generalAssembly_id: 502,
									},
									{
										id: 11010,
										name: "Karangara   Gacaca court of sector ",
										generalAssembly_id: 502,
									},
								],
							},
							{
								id: 503,
								name: "Musenyi  ",
								sector_id: 10807,
								courtOfCells: [
									{
										id: 2686,
										name: "GASAVE",
										generalAssembly_id: 503,
									},
									{
										id: 2687,
										name: "KABASARE",
										generalAssembly_id: 503,
									},
									{
										id: 2688,
										name: "KAGUGU",
										generalAssembly_id: 503,
									},
									{
										id: 2689,
										name: "CYERU",
										generalAssembly_id: 503,
									},
									{
										id: 9478,
										name: "Musenyi   Court of Appeal ",
										generalAssembly_id: 503,
									},
									{
										id: 11011,
										name: "Musenyi   Gacaca court of sector ",
										generalAssembly_id: 503,
									},
								],
							},
							{
								id: 504,
								name: "Ngamba ",
								sector_id: 10807,
								courtOfCells: [
									{
										id: 2690,
										name: "MARE",
										generalAssembly_id: 504,
									},
									{
										id: 2691,
										name: "NKOTO",
										generalAssembly_id: 504,
									},
									{
										id: 2692,
										name: "NYAMURAMBANYA",
										generalAssembly_id: 504,
									},
									{
										id: 2693,
										name: "RUBUYE",
										generalAssembly_id: 504,
									},
									{
										id: 2694,
										name: "RWEZAMENYO",
										generalAssembly_id: 504,
									},
									{
										id: 9479,
										name: "Ngamba  Court of Appeal ",
										generalAssembly_id: 504,
									},
									{
										id: 11012,
										name: "Ngamba  Gacaca court of sector ",
										generalAssembly_id: 504,
									},
								],
							},
						],
					},
					{
						id: 10808,
						name: "Nyamiyaga",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080805,
								name: "Ngoma",
								sector_id: 10808,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080804,
								name: "Mukinga",
								sector_id: 10808,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080803,
								name: "Kidahwe",
								sector_id: 10808,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080802,
								name: "Kabashumba",
								sector_id: 10808,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080801,
								name: "Bibungo",
								sector_id: 10808,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 505,
								name: "Bibungo  ",
								sector_id: 10808,
								courtOfCells: [
									{
										id: 2695,
										name: "BYENENE",
										generalAssembly_id: 505,
									},
									{
										id: 2696,
										name: "KANYWIRIRI",
										generalAssembly_id: 505,
									},
									{
										id: 2697,
										name: "MURAMBI",
										generalAssembly_id: 505,
									},
									{
										id: 2698,
										name: "NKIMBIRI",
										generalAssembly_id: 505,
									},
									{
										id: 9480,
										name: "Bibungo   Court of Appeal ",
										generalAssembly_id: 505,
									},
									{
										id: 11013,
										name: "Bibungo   Gacaca court of sector ",
										generalAssembly_id: 505,
									},
								],
							},
							{
								id: 506,
								name: "Mukinga  ",
								sector_id: 10808,
								courtOfCells: [
									{
										id: 2699,
										name: "MBAYAYA",
										generalAssembly_id: 506,
									},
									{
										id: 2700,
										name: "MUKINGA",
										generalAssembly_id: 506,
									},
									{
										id: 2701,
										name: "NYARUGENGE",
										generalAssembly_id: 506,
									},
									{
										id: 2702,
										name: "NYARUHENGERI",
										generalAssembly_id: 506,
									},
									{
										id: 9481,
										name: "Mukinga   Court of Appeal ",
										generalAssembly_id: 506,
									},
									{
										id: 11014,
										name: "Mukinga   Gacaca court of sector ",
										generalAssembly_id: 506,
									},
								],
							},
							{
								id: 507,
								name: "Ngoma  ",
								sector_id: 10808,
								courtOfCells: [
									{
										id: 2703,
										name: "BUHORO",
										generalAssembly_id: 507,
									},
									{
										id: 2704,
										name: "KABAHIZI",
										generalAssembly_id: 507,
									},
									{
										id: 2705,
										name: "KINANIRA",
										generalAssembly_id: 507,
									},
									{
										id: 2706,
										name: "KIVUGIZA",
										generalAssembly_id: 507,
									},
									{
										id: 2707,
										name: "MASWA",
										generalAssembly_id: 507,
									},
									{
										id: 2708,
										name: "RWANKEKE",
										generalAssembly_id: 507,
									},
									{
										id: 2709,
										name: "SABUNUNGA",
										generalAssembly_id: 507,
									},
									{
										id: 9482,
										name: "Ngoma   Court of Appeal ",
										generalAssembly_id: 507,
									},
									{
										id: 11015,
										name: "Ngoma   Gacaca court of sector ",
										generalAssembly_id: 507,
									},
								],
							},
							{
								id: 508,
								name: "Nyamiyaga  ",
								sector_id: 10808,
								courtOfCells: [
									{
										id: 2710,
										name: "BUMBOGO",
										generalAssembly_id: 508,
									},
									{
										id: 2711,
										name: "BUYE",
										generalAssembly_id: 508,
									},
									{
										id: 2712,
										name: "GACUMU",
										generalAssembly_id: 508,
									},
									{
										id: 2713,
										name: "KIDAHWE",
										generalAssembly_id: 508,
									},
									{
										id: 2714,
										name: "KIREHE",
										generalAssembly_id: 508,
									},
									{
										id: 2715,
										name: "NKOTO",
										generalAssembly_id: 508,
									},
									{
										id: 2716,
										name: "RUGARAMA",
										generalAssembly_id: 508,
									},
									{
										id: 2717,
										name: "RUVUGIZO",
										generalAssembly_id: 508,
									},
									{
										id: 2718,
										name: "RUZIRANYENZI",
										generalAssembly_id: 508,
									},
									{
										id: 9483,
										name: "Nyamiyaga   Court of Appeal ",
										generalAssembly_id: 508,
									},
									{
										id: 11016,
										name: "Nyamiyaga   Gacaca court of sector ",
										generalAssembly_id: 508,
									},
								],
							},
						],
					},
					{
						id: 10809,
						name: "Nyarubaka",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1080905,
								name: "Ruyanza",
								sector_id: 10809,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080904,
								name: "Nyagishubi",
								sector_id: 10809,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080903,
								name: "Kigusa",
								sector_id: 10809,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080902,
								name: "Kambyeyi",
								sector_id: 10809,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1080901,
								name: "Gitare",
								sector_id: 10809,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 509,
								name: "Birambo  ",
								sector_id: 10809,
								courtOfCells: [
									{
										id: 2719,
										name: "BUHUNGA",
										generalAssembly_id: 509,
									},
									{
										id: 2720,
										name: "GATAGARA",
										generalAssembly_id: 509,
									},
									{
										id: 2721,
										name: "GITEGA",
										generalAssembly_id: 509,
									},
									{
										id: 2722,
										name: "KANOMBE",
										generalAssembly_id: 509,
									},
									{
										id: 2723,
										name: "KAVUMU",
										generalAssembly_id: 509,
									},
									{
										id: 2724,
										name: "NGARAMA",
										generalAssembly_id: 509,
									},
									{
										id: 2725,
										name: "TARE",
										generalAssembly_id: 509,
									},
									{
										id: 9484,
										name: "Birambo   Court of Appeal ",
										generalAssembly_id: 509,
									},
									{
										id: 11017,
										name: "Birambo   Gacaca court of sector ",
										generalAssembly_id: 509,
									},
								],
							},
							{
								id: 510,
								name: "Kambyeyi  ",
								sector_id: 10809,
								courtOfCells: [
									{
										id: 2726,
										name: "GIHETA",
										generalAssembly_id: 510,
									},
									{
										id: 2727,
										name: "KABUNGO",
										generalAssembly_id: 510,
									},
									{
										id: 2728,
										name: "KAVUMU",
										generalAssembly_id: 510,
									},
									{
										id: 2729,
										name: "KIGWENE",
										generalAssembly_id: 510,
									},
									{
										id: 2730,
										name: "RUHUHA",
										generalAssembly_id: 510,
									},
									{
										id: 9485,
										name: "Kambyeyi   Court of Appeal ",
										generalAssembly_id: 510,
									},
									{
										id: 11018,
										name: "Kambyeyi   Gacaca court of sector ",
										generalAssembly_id: 510,
									},
								],
							},
							{
								id: 511,
								name: "Munyinya  ",
								sector_id: 10809,
								courtOfCells: [
									{
										id: 2731,
										name: "KABERE",
										generalAssembly_id: 511,
									},
									{
										id: 2732,
										name: "NOMBE",
										generalAssembly_id: 511,
									},
									{
										id: 2733,
										name: "NYAGASOZI",
										generalAssembly_id: 511,
									},
									{
										id: 2734,
										name: "NYAGISHUBI",
										generalAssembly_id: 511,
									},
									{
										id: 9486,
										name: "Munyinya   Court of Appeal ",
										generalAssembly_id: 511,
									},
									{
										id: 11019,
										name: "Munyinya   Gacaca court of sector ",
										generalAssembly_id: 511,
									},
								],
							},
							{
								id: 512,
								name: "Musumba  ",
								sector_id: 10809,
								courtOfCells: [
									{
										id: 2735,
										name: "KARORA",
										generalAssembly_id: 512,
									},
									{
										id: 2736,
										name: "KIBINGO",
										generalAssembly_id: 512,
									},
									{
										id: 2737,
										name: "MUGEREKE",
										generalAssembly_id: 512,
									},
									{
										id: 2738,
										name: "REMERA",
										generalAssembly_id: 512,
									},
									{
										id: 2739,
										name: "RWIGERERO",
										generalAssembly_id: 512,
									},
									{
										id: 9487,
										name: "Musumba   Court of Appeal ",
										generalAssembly_id: 512,
									},
									{
										id: 11020,
										name: "Musumba   Gacaca court of sector ",
										generalAssembly_id: 512,
									},
								],
							},
							{
								id: 513,
								name: "Nyarubaka  ",
								sector_id: 10809,
								courtOfCells: [
									{
										id: 2740,
										name: "BIREMBO",
										generalAssembly_id: 513,
									},
									{
										id: 2741,
										name: "GASEREGE",
										generalAssembly_id: 513,
									},
									{
										id: 2742,
										name: "KIGARAMA",
										generalAssembly_id: 513,
									},
									{
										id: 2743,
										name: "KIGUSA",
										generalAssembly_id: 513,
									},
									{
										id: 2744,
										name: "KIRWA",
										generalAssembly_id: 513,
									},
									{
										id: 2745,
										name: "RUZIRANYONI",
										generalAssembly_id: 513,
									},
									{
										id: 2746,
										name: "RWINANKA",
										generalAssembly_id: 513,
									},
									{
										id: 9488,
										name: "Nyarubaka   Court of Appeal ",
										generalAssembly_id: 513,
									},
									{
										id: 11021,
										name: "Nyarubaka   Gacaca court of sector ",
										generalAssembly_id: 513,
									},
								],
							},
						],
					},
					{
						id: 10810,
						name: "Rugarika",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1081005,
								name: "Sheli",
								sector_id: 10810,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081004,
								name: "Nyarubuye",
								sector_id: 10810,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081003,
								name: "Masaka",
								sector_id: 10810,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081002,
								name: "Kigese",
								sector_id: 10810,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081001,
								name: "Bihembe",
								sector_id: 10810,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 514,
								name: "Kigese  ",
								sector_id: 10810,
								courtOfCells: [
									{
										id: 2747,
										name: "BIKAMBA",
										generalAssembly_id: 514,
									},
									{
										id: 2748,
										name: "KIGESE",
										generalAssembly_id: 514,
									},
									{
										id: 2749,
										name: "KIREGA",
										generalAssembly_id: 514,
									},
									{
										id: 2750,
										name: "MASAKA",
										generalAssembly_id: 514,
									},
									{
										id: 2751,
										name: "MIBIRIZI",
										generalAssembly_id: 514,
									},
									{
										id: 2752,
										name: "MPUNGWE",
										generalAssembly_id: 514,
									},
									{
										id: 2753,
										name: "RUGARAMA",
										generalAssembly_id: 514,
									},
									{
										id: 2754,
										name: "RURAMBA",
										generalAssembly_id: 514,
									},
									{
										id: 2755,
										name: "RWIMONDO",
										generalAssembly_id: 514,
									},
									{
										id: 2756,
										name: "TABA",
										generalAssembly_id: 514,
									},
									{
										id: 9489,
										name: "Kigese   Court of Appeal ",
										generalAssembly_id: 514,
									},
									{
										id: 11022,
										name: "Kigese   Gacaca court of sector ",
										generalAssembly_id: 514,
									},
								],
							},
							{
								id: 515,
								name: "Kinyambi  ",
								sector_id: 10810,
								courtOfCells: [
									{
										id: 2757,
										name: "BIHEMBE",
										generalAssembly_id: 515,
									},
									{
										id: 2758,
										name: "GITWA",
										generalAssembly_id: 515,
									},
									{
										id: 2759,
										name: "KADASAYA",
										generalAssembly_id: 515,
									},
									{
										id: 2760,
										name: "KARAMA",
										generalAssembly_id: 515,
									},
									{
										id: 2761,
										name: "KIGARAMA",
										generalAssembly_id: 515,
									},
									{
										id: 2762,
										name: "RUBONA",
										generalAssembly_id: 515,
									},
									{
										id: 9490,
										name: "Kinyambi   Court of Appeal ",
										generalAssembly_id: 515,
									},
									{
										id: 11023,
										name: "Kinyambi   Gacaca court of sector ",
										generalAssembly_id: 515,
									},
								],
							},
							{
								id: 516,
								name: "Rugalika  ",
								sector_id: 10810,
								courtOfCells: [
									{
										id: 2763,
										name: "KABARAMA",
										generalAssembly_id: 516,
									},
									{
										id: 2764,
										name: "MUSAVE",
										generalAssembly_id: 516,
									},
									{
										id: 2765,
										name: "NYARUBUYE",
										generalAssembly_id: 516,
									},
									{
										id: 2766,
										name: "NZAGWA",
										generalAssembly_id: 516,
									},
									{
										id: 2767,
										name: "REMERA",
										generalAssembly_id: 516,
									},
									{
										id: 2768,
										name: "RUHOGO",
										generalAssembly_id: 516,
									},
									{
										id: 2769,
										name: "RUTOVU",
										generalAssembly_id: 516,
									},
									{
										id: 9491,
										name: "Rugalika   Court of Appeal ",
										generalAssembly_id: 516,
									},
									{
										id: 11024,
										name: "Rugalika   Gacaca court of sector ",
										generalAssembly_id: 516,
									},
								],
							},
							{
								id: 517,
								name: "Sheli  ",
								sector_id: 10810,
								courtOfCells: [
									{
										id: 2770,
										name: "BUTERA",
										generalAssembly_id: 517,
									},
									{
										id: 2771,
										name: "GATOVU",
										generalAssembly_id: 517,
									},
									{
										id: 2772,
										name: "KAGANGAYIRE",
										generalAssembly_id: 517,
									},
									{
										id: 2773,
										name: "KAREHE",
										generalAssembly_id: 517,
									},
									{
										id: 2774,
										name: "NTEBE",
										generalAssembly_id: 517,
									},
									{
										id: 9492,
										name: "Sheli   Court of Appeal ",
										generalAssembly_id: 517,
									},
									{
										id: 11025,
										name: "Sheli   Gacaca court of sector ",
										generalAssembly_id: 517,
									},
								],
							},
						],
					},
					{
						id: 10811,
						name: "Rukoma",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1081107,
								name: "Taba",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081106,
								name: "Remera",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081105,
								name: "Mwirute",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081104,
								name: "Murehe",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081103,
								name: "Gishyeshye",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081102,
								name: "Buguri",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081101,
								name: "Bugoba",
								sector_id: 10811,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 518,
								name: "Bugoba  ",
								sector_id: 10811,
								courtOfCells: [
									{
										id: 2775,
										name: "BUGOBA",
										generalAssembly_id: 518,
									},
									{
										id: 2776,
										name: "GATARE",
										generalAssembly_id: 518,
									},
									{
										id: 2777,
										name: "KABUGA",
										generalAssembly_id: 518,
									},
									{
										id: 2778,
										name: "KARULI",
										generalAssembly_id: 518,
									},
									{
										id: 2779,
										name: "MWIRUTE",
										generalAssembly_id: 518,
									},
									{
										id: 2780,
										name: "NYARURAMA",
										generalAssembly_id: 518,
									},
									{
										id: 2781,
										name: "NYARUSAVE",
										generalAssembly_id: 518,
									},
									{
										id: 2782,
										name: "NYENGE",
										generalAssembly_id: 518,
									},
									{
										id: 2783,
										name: "NYIRABIHANYA",
										generalAssembly_id: 518,
									},
									{
										id: 9493,
										name: "Bugoba   Court of Appeal ",
										generalAssembly_id: 518,
									},
									{
										id: 11026,
										name: "Bugoba   Gacaca court of sector ",
										generalAssembly_id: 518,
									},
								],
							},
							{
								id: 519,
								name: "Buguli ",
								sector_id: 10811,
								courtOfCells: [
									{
										id: 2784,
										name: "BUGULI",
										generalAssembly_id: 519,
									},
									{
										id: 2785,
										name: "NYABUVOMO",
										generalAssembly_id: 519,
									},
									{
										id: 2786,
										name: "NYAGASOZI",
										generalAssembly_id: 519,
									},
									{
										id: 2787,
										name: "NYAKABANDE",
										generalAssembly_id: 519,
									},
									{
										id: 2788,
										name: "RUZEGE",
										generalAssembly_id: 519,
									},
									{
										id: 2789,
										name: "TUNZA",
										generalAssembly_id: 519,
									},
									{
										id: 9494,
										name: "Buguli  Court of Appeal ",
										generalAssembly_id: 519,
									},
									{
										id: 11027,
										name: "Buguli  Gacaca court of sector ",
										generalAssembly_id: 519,
									},
								],
							},
							{
								id: 520,
								name: "Gishyeshye ",
								sector_id: 10811,
								courtOfCells: [
									{
										id: 2790,
										name: "GISENYI",
										generalAssembly_id: 520,
									},
									{
										id: 2791,
										name: "GISHYESHYE",
										generalAssembly_id: 520,
									},
									{
										id: 2792,
										name: "GAHUNGERI",
										generalAssembly_id: 520,
									},
									{
										id: 2793,
										name: "NYAMABUYE",
										generalAssembly_id: 520,
									},
									{
										id: 2794,
										name: "RUBARE",
										generalAssembly_id: 520,
									},
									{
										id: 2795,
										name: "RUHOZANKANDA",
										generalAssembly_id: 520,
									},
									{
										id: 9495,
										name: "Gishyeshye  Court of Appeal ",
										generalAssembly_id: 520,
									},
									{
										id: 11028,
										name: "Gishyeshye  Gacaca court of sector ",
										generalAssembly_id: 520,
									},
								],
							},
							{
								id: 521,
								name: "Murehe ",
								sector_id: 10811,
								courtOfCells: [
									{
										id: 2796,
										name: "GAFONOGO",
										generalAssembly_id: 521,
									},
									{
										id: 2797,
										name: "KABAGABO",
										generalAssembly_id: 521,
									},
									{
										id: 2798,
										name: "KAMUZI",
										generalAssembly_id: 521,
									},
									{
										id: 2799,
										name: "MUBUGA",
										generalAssembly_id: 521,
									},
									{
										id: 2800,
										name: "RUBUYE",
										generalAssembly_id: 521,
									},
									{
										id: 2801,
										name: "RUGARAMA",
										generalAssembly_id: 521,
									},
									{
										id: 2802,
										name: "RUSHIKIRI",
										generalAssembly_id: 521,
									},
									{
										id: 2803,
										name: "TABA",
										generalAssembly_id: 521,
									},
									{
										id: 2804,
										name: "UWINGANDO",
										generalAssembly_id: 521,
									},
									{
										id: 9496,
										name: "Murehe  Court of Appeal ",
										generalAssembly_id: 521,
									},
									{
										id: 11029,
										name: "Murehe  Gacaca court of sector ",
										generalAssembly_id: 521,
									},
								],
							},
							{
								id: 522,
								name: "Taba  ",
								sector_id: 10811,
								courtOfCells: [
									{
										id: 2805,
										name: "BUKOKORA",
										generalAssembly_id: 522,
									},
									{
										id: 2806,
										name: "KAJAGI",
										generalAssembly_id: 522,
									},
									{
										id: 2807,
										name: "KANYINYA",
										generalAssembly_id: 522,
									},
									{
										id: 2808,
										name: "KIGARAMA_MBIZI",
										generalAssembly_id: 522,
									},
									{
										id: 2809,
										name: "NYARUSANGE",
										generalAssembly_id: 522,
									},
									{
										id: 2810,
										name: "REMERA",
										generalAssembly_id: 522,
									},
									{
										id: 9497,
										name: "Taba   Court of Appeal ",
										generalAssembly_id: 522,
									},
									{
										id: 11030,
										name: "Taba   Gacaca court of sector ",
										generalAssembly_id: 522,
									},
								],
							},
						],
					},
					{
						id: 10812,
						name: "Runda",
						district_id: 108,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1081205,
								name: "Ruyenzi",
								sector_id: 10812,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081204,
								name: "Muganza",
								sector_id: 10812,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081203,
								name: "Kagina",
								sector_id: 10812,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081202,
								name: "Kabagesera",
								sector_id: 10812,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1081201,
								name: "Gihara",
								sector_id: 10812,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 523,
								name: "Gihara  ",
								sector_id: 10812,
								courtOfCells: [
									{
										id: 2811,
										name: "BIMBA",
										generalAssembly_id: 523,
									},
									{
										id: 2812,
										name: "BUKIMBA",
										generalAssembly_id: 523,
									},
									{
										id: 2813,
										name: "GASHARARA",
										generalAssembly_id: 523,
									},
									{
										id: 2814,
										name: "KABASANZA",
										generalAssembly_id: 523,
									},
									{
										id: 2815,
										name: "KAGINA",
										generalAssembly_id: 523,
									},
									{
										id: 2816,
										name: "KAMUHOZA",
										generalAssembly_id: 523,
									},
									{
										id: 2817,
										name: "KIGUSA",
										generalAssembly_id: 523,
									},
									{
										id: 2818,
										name: "NYAGATARE",
										generalAssembly_id: 523,
									},
									{
										id: 2819,
										name: "RUGARAMA",
										generalAssembly_id: 523,
									},
									{
										id: 2820,
										name: "RUKARAGATA",
										generalAssembly_id: 523,
									},
									{
										id: 2821,
										name: "RUYIGI",
										generalAssembly_id: 523,
									},
									{
										id: 9498,
										name: "Gihara   Court of Appeal ",
										generalAssembly_id: 523,
									},
									{
										id: 11031,
										name: "Gihara   Gacaca court of sector ",
										generalAssembly_id: 523,
									},
								],
							},
							{
								id: 524,
								name: "Runda ",
								sector_id: 10812,
								courtOfCells: [
									{
										id: 2822,
										name: "BWIRABO",
										generalAssembly_id: 524,
									},
									{
										id: 2823,
										name: "KABAGESERA",
										generalAssembly_id: 524,
									},
									{
										id: 2824,
										name: "MUHAMBARA",
										generalAssembly_id: 524,
									},
									{
										id: 2825,
										name: "RUBUYE",
										generalAssembly_id: 524,
									},
									{
										id: 2826,
										name: "RUGOGWE",
										generalAssembly_id: 524,
									},
									{
										id: 9499,
										name: "Runda  Court of Appeal ",
										generalAssembly_id: 524,
									},
									{
										id: 11032,
										name: "Runda  Gacaca court of sector ",
										generalAssembly_id: 524,
									},
								],
							},
							{
								id: 525,
								name: "Ruyenzi ",
								sector_id: 10812,
								courtOfCells: [
									{
										id: 2827,
										name: "KIBAYA",
										generalAssembly_id: 525,
									},
									{
										id: 2828,
										name: "KIGABIRO",
										generalAssembly_id: 525,
									},
									{
										id: 2829,
										name: "MUSEBEYA",
										generalAssembly_id: 525,
									},
									{
										id: 2830,
										name: "NYABITARE",
										generalAssembly_id: 525,
									},
									{
										id: 2831,
										name: "NYAGACACA",
										generalAssembly_id: 525,
									},
									{
										id: 2832,
										name: "NYAGACYAMO",
										generalAssembly_id: 525,
									},
									{
										id: 2833,
										name: "NYARUHOKO",
										generalAssembly_id: 525,
									},
									{
										id: 2834,
										name: "RUBONA",
										generalAssembly_id: 525,
									},
									{
										id: 2835,
										name: "RUBUMBA",
										generalAssembly_id: 525,
									},
									{
										id: 2836,
										name: "RUGAZI",
										generalAssembly_id: 525,
									},
									{
										id: 9500,
										name: "Ruyenzi  Court of Appeal ",
										generalAssembly_id: 525,
									},
									{
										id: 11033,
										name: "Ruyenzi  Gacaca court of sector ",
										generalAssembly_id: 525,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 107,
				name: "Muhanga",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10701,
						name: "Cyeza",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070106,
								name: "Shori",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070105,
								name: "Nyarunyinya",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070104,
								name: "Makera",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070103,
								name: "Kivumu",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070102,
								name: "Kigarama",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070101,
								name: "Biringaga",
								sector_id: 10701,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 663,
								name: "Gatenzi",
								sector_id: 10701,
								courtOfCells: [
									{
										id: 3669,
										name: "KAMANGA",
										generalAssembly_id: 663,
									},
									{
										id: 3670,
										name: "KANGENZI",
										generalAssembly_id: 663,
									},
									{
										id: 3671,
										name: "KARAMA",
										generalAssembly_id: 663,
									},
									{
										id: 3672,
										name: "KIGARAMA",
										generalAssembly_id: 663,
									},
									{
										id: 3673,
										name: "KIRIMAHWA",
										generalAssembly_id: 663,
									},
									{
										id: 3674,
										name: "MUSENGO",
										generalAssembly_id: 663,
									},
									{
										id: 3675,
										name: "MUTARA-KIGARAMA",
										generalAssembly_id: 663,
									},
									{
										id: 3676,
										name: "NYAGASOZI",
										generalAssembly_id: 663,
									},
									{
										id: 3677,
										name: "BIHEMBE",
										generalAssembly_id: 663,
									},
									{
										id: 3678,
										name: "NYERENGA",
										generalAssembly_id: 663,
									},
									{
										id: 9638,
										name: "Gatenzi Court of Appeal ",
										generalAssembly_id: 663,
									},
									{
										id: 11171,
										name: "Gatenzi Gacaca court of sector ",
										generalAssembly_id: 663,
									},
								],
							},
							{
								id: 664,
								name: "Takwe",
								sector_id: 10701,
								courtOfCells: [
									{
										id: 3679,
										name: "BWIRIKA-MPENGE",
										generalAssembly_id: 664,
									},
									{
										id: 3680,
										name: "BWIZA",
										generalAssembly_id: 664,
									},
									{
										id: 3681,
										name: "GASAVE",
										generalAssembly_id: 664,
									},
									{
										id: 3682,
										name: "KAGARAMA",
										generalAssembly_id: 664,
									},
									{
										id: 3683,
										name: "RUSEBEYA",
										generalAssembly_id: 664,
									},
									{
										id: 3684,
										name: "TAKWE",
										generalAssembly_id: 664,
									},
									{
										id: 3685,
										name: "GICURICYUNGWE",
										generalAssembly_id: 664,
									},
									{
										id: 3686,
										name: "GISARI",
										generalAssembly_id: 664,
									},
									{
										id: 9639,
										name: "Takwe Court of Appeal ",
										generalAssembly_id: 664,
									},
									{
										id: 11172,
										name: "Takwe Gacaca court of sector ",
										generalAssembly_id: 664,
									},
								],
							},
							{
								id: 665,
								name: "Cyeza",
								sector_id: 10701,
								courtOfCells: [
									{
										id: 3687,
										name: "CYEZA",
										generalAssembly_id: 665,
									},
									{
										id: 3688,
										name: "GATABI",
										generalAssembly_id: 665,
									},
									{
										id: 3689,
										name: "KABATI",
										generalAssembly_id: 665,
									},
									{
										id: 3690,
										name: "KIREMERI",
										generalAssembly_id: 665,
									},
									{
										id: 9640,
										name: "Cyeza Court of Appeal ",
										generalAssembly_id: 665,
									},
									{
										id: 11173,
										name: "Cyeza Gacaca court of sector ",
										generalAssembly_id: 665,
									},
								],
							},
							{
								id: 666,
								name: "Gatovu",
								sector_id: 10701,
								courtOfCells: [
									{
										id: 3691,
										name: "GAHUNGA",
										generalAssembly_id: 666,
									},
									{
										id: 3692,
										name: "GISIZA",
										generalAssembly_id: 666,
									},
									{
										id: 3693,
										name: "KANKURI",
										generalAssembly_id: 666,
									},
									{
										id: 3694,
										name: "KARAMA",
										generalAssembly_id: 666,
									},
									{
										id: 3695,
										name: "KARAMBO",
										generalAssembly_id: 666,
									},
									{
										id: 3696,
										name: "NYAMIRAMA",
										generalAssembly_id: 666,
									},
									{
										id: 9641,
										name: "Gatovu Court of Appeal ",
										generalAssembly_id: 666,
									},
									{
										id: 11174,
										name: "Gatovu Gacaca court of sector ",
										generalAssembly_id: 666,
									},
								],
							},
							{
								id: 667,
								name: "Shyanda",
								sector_id: 10701,
								courtOfCells: [
									{
										id: 3697,
										name: "BUSEKERA",
										generalAssembly_id: 667,
									},
									{
										id: 3698,
										name: "GIFUMBA",
										generalAssembly_id: 667,
									},
									{
										id: 3699,
										name: "KAGOMA",
										generalAssembly_id: 667,
									},
									{
										id: 3700,
										name: "NYAGISANZE",
										generalAssembly_id: 667,
									},
									{
										id: 3701,
										name: "SHYANDA",
										generalAssembly_id: 667,
									},
									{
										id: 9642,
										name: "Shyanda Court of Appeal ",
										generalAssembly_id: 667,
									},
									{
										id: 11175,
										name: "Shyanda Gacaca court of sector ",
										generalAssembly_id: 667,
									},
								],
							},
						],
					},
					{
						id: 10702,
						name: "Kabacuzi",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070208,
								name: "Sholi",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070207,
								name: "Ngoma",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070206,
								name: "Ngarama",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070205,
								name: "Kibyimba",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070204,
								name: "Kavumu",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070203,
								name: "Kabuye",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070202,
								name: "Butare",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070201,
								name: "Buramba",
								sector_id: 10702,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 668,
								name: "Kabuye",
								sector_id: 10702,
								courtOfCells: [
									{
										id: 3702,
										name: "CYANKERI",
										generalAssembly_id: 668,
									},
									{
										id: 3703,
										name: "KABUYE",
										generalAssembly_id: 668,
									},
									{
										id: 3704,
										name: "KAMIRANZOGERA",
										generalAssembly_id: 668,
									},
									{
										id: 3705,
										name: "KANKA",
										generalAssembly_id: 668,
									},
									{
										id: 3706,
										name: "KIBYIMBA",
										generalAssembly_id: 668,
									},
									{
										id: 3707,
										name: "KIGARAMA",
										generalAssembly_id: 668,
									},
									{
										id: 3708,
										name: "KINOGI",
										generalAssembly_id: 668,
									},
									{
										id: 3709,
										name: "MATABA",
										generalAssembly_id: 668,
									},
									{
										id: 3710,
										name: "MURAMBI",
										generalAssembly_id: 668,
									},
									{
										id: 9643,
										name: "Kabuye Court of Appeal ",
										generalAssembly_id: 668,
									},
									{
										id: 11176,
										name: "Kabuye Gacaca court of sector ",
										generalAssembly_id: 668,
									},
								],
							},
							{
								id: 669,
								name: "Ngoma",
								sector_id: 10702,
								courtOfCells: [
									{
										id: 3711,
										name: "BURORERO",
										generalAssembly_id: 669,
									},
									{
										id: 3712,
										name: "CYICIRO",
										generalAssembly_id: 669,
									},
									{
										id: 3713,
										name: "GASHARU",
										generalAssembly_id: 669,
									},
									{
										id: 3714,
										name: "GASIZA",
										generalAssembly_id: 669,
									},
									{
										id: 3715,
										name: "GISIZA",
										generalAssembly_id: 669,
									},
									{
										id: 3716,
										name: "KABEZA",
										generalAssembly_id: 669,
									},
									{
										id: 3717,
										name: "KAVUMU",
										generalAssembly_id: 669,
									},
									{
										id: 3718,
										name: "NGOMA",
										generalAssembly_id: 669,
									},
									{
										id: 3719,
										name: "NYAMATETE",
										generalAssembly_id: 669,
									},
									{
										id: 3720,
										name: "RWAMIKO",
										generalAssembly_id: 669,
									},
									{
										id: 3721,
										name: "SHOLI",
										generalAssembly_id: 669,
									},
									{
										id: 9644,
										name: "Ngoma Court of Appeal ",
										generalAssembly_id: 669,
									},
									{
										id: 11177,
										name: "Ngoma Gacaca court of sector ",
										generalAssembly_id: 669,
									},
								],
							},
							{
								id: 670,
								name: "Rutongo",
								sector_id: 10702,
								courtOfCells: [
									{
										id: 3722,
										name: "RUTONGO",
										generalAssembly_id: 670,
									},
									{
										id: 3723,
										name: "BUTARE",
										generalAssembly_id: 670,
									},
									{
										id: 3724,
										name: "REBE",
										generalAssembly_id: 670,
									},
									{
										id: 3725,
										name: "RUSHOKA",
										generalAssembly_id: 670,
									},
									{
										id: 9645,
										name: "Rutongo Court of Appeal ",
										generalAssembly_id: 670,
									},
									{
										id: 11178,
										name: "Rutongo Gacaca court of sector ",
										generalAssembly_id: 670,
									},
								],
							},
							{
								id: 671,
								name: "Buramba",
								sector_id: 10702,
								courtOfCells: [
									{
										id: 3726,
										name: "GITURWA",
										generalAssembly_id: 671,
									},
									{
										id: 3727,
										name: "MBATAMA",
										generalAssembly_id: 671,
									},
									{
										id: 3728,
										name: "MPANGA",
										generalAssembly_id: 671,
									},
									{
										id: 3729,
										name: "MWENDO",
										generalAssembly_id: 671,
									},
									{
										id: 3730,
										name: "NGOMBA",
										generalAssembly_id: 671,
									},
									{
										id: 3731,
										name: "NYANGE",
										generalAssembly_id: 671,
									},
									{
										id: 3732,
										name: "NZOVI",
										generalAssembly_id: 671,
									},
									{
										id: 3733,
										name: "RAMBI",
										generalAssembly_id: 671,
									},
									{
										id: 3734,
										name: "REMERA",
										generalAssembly_id: 671,
									},
									{
										id: 9646,
										name: "Buramba Court of Appeal ",
										generalAssembly_id: 671,
									},
									{
										id: 11179,
										name: "Buramba Gacaca court of sector ",
										generalAssembly_id: 671,
									},
								],
							},
							{
								id: 672,
								name: "Cyubi",
								sector_id: 10702,
								courtOfCells: [
									{
										id: 3735,
										name: "JANDALI",
										generalAssembly_id: 672,
									},
									{
										id: 3736,
										name: "KINYONI",
										generalAssembly_id: 672,
									},
									{
										id: 3737,
										name: "NGARYI",
										generalAssembly_id: 672,
									},
									{
										id: 3738,
										name: "SHOLI",
										generalAssembly_id: 672,
									},
									{
										id: 3739,
										name: "CYUBI",
										generalAssembly_id: 672,
									},
									{
										id: 9647,
										name: "Cyubi Court of Appeal ",
										generalAssembly_id: 672,
									},
									{
										id: 11180,
										name: "Cyubi Gacaca court of sector ",
										generalAssembly_id: 672,
									},
								],
							},
						],
					},
					{
						id: 10703,
						name: "Kibangu",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070306,
								name: "Ryakanimba",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070305,
								name: "Rubyiniro",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070304,
								name: "Mubuga",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070303,
								name: "Jurwe",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070302,
								name: "Gitega",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070301,
								name: "Gisharu",
								sector_id: 10703,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 673,
								name: "Kagogwe",
								sector_id: 10703,
								courtOfCells: [
									{
										id: 3740,
										name: "KIREHE",
										generalAssembly_id: 673,
									},
									{
										id: 3741,
										name: "MURAMBI",
										generalAssembly_id: 673,
									},
									{
										id: 3742,
										name: "MURANDI",
										generalAssembly_id: 673,
									},
									{
										id: 3743,
										name: "MUSEZERO",
										generalAssembly_id: 673,
									},
									{
										id: 3744,
										name: "REMERA",
										generalAssembly_id: 673,
									},
									{
										id: 3745,
										name: "RUMIRANTEGE",
										generalAssembly_id: 673,
									},
									{
										id: 3746,
										name: "RWESERO",
										generalAssembly_id: 673,
									},
									{
										id: 3747,
										name: "BUHORO",
										generalAssembly_id: 673,
									},
									{
										id: 9648,
										name: "Kagogwe Court of Appeal ",
										generalAssembly_id: 673,
									},
									{
										id: 11181,
										name: "Kagogwe Gacaca court of sector ",
										generalAssembly_id: 673,
									},
								],
							},
							{
								id: 674,
								name: "Kibangu",
								sector_id: 10703,
								courtOfCells: [
									{
										id: 3748,
										name: "CYERARO",
										generalAssembly_id: 674,
									},
									{
										id: 3749,
										name: "GAFUNZO",
										generalAssembly_id: 674,
									},
									{
										id: 3750,
										name: "JARAMA",
										generalAssembly_id: 674,
									},
									{
										id: 3751,
										name: "KAVUMU",
										generalAssembly_id: 674,
									},
									{
										id: 3752,
										name: "MUGWATO",
										generalAssembly_id: 674,
									},
									{
										id: 3753,
										name: "MUHORORO",
										generalAssembly_id: 674,
									},
									{
										id: 3754,
										name: "MURAMBI",
										generalAssembly_id: 674,
									},
									{
										id: 3755,
										name: "MUSAMBAGIRO",
										generalAssembly_id: 674,
									},
									{
										id: 3756,
										name: "NDAGO",
										generalAssembly_id: 674,
									},
									{
										id: 3757,
										name: "NYAKAREKARE",
										generalAssembly_id: 674,
									},
									{
										id: 9649,
										name: "Kibangu Court of Appeal ",
										generalAssembly_id: 674,
									},
									{
										id: 11182,
										name: "Kibangu Gacaca court of sector ",
										generalAssembly_id: 674,
									},
								],
							},
							{
								id: 675,
								name: "Kibimba",
								sector_id: 10703,
								courtOfCells: [
									{
										id: 3758,
										name: "MUCYAMO",
										generalAssembly_id: 675,
									},
									{
										id: 3759,
										name: "MUGALI",
										generalAssembly_id: 675,
									},
									{
										id: 3760,
										name: "MUREHE",
										generalAssembly_id: 675,
									},
									{
										id: 3761,
										name: "NZARWA",
										generalAssembly_id: 675,
									},
									{
										id: 3762,
										name: "BUKIRO",
										generalAssembly_id: 675,
									},
									{
										id: 3763,
										name: "BUTARE",
										generalAssembly_id: 675,
									},
									{
										id: 3764,
										name: "GAKURWE",
										generalAssembly_id: 675,
									},
									{
										id: 3765,
										name: "JURWE",
										generalAssembly_id: 675,
									},
									{
										id: 3766,
										name: "KIMISANGE",
										generalAssembly_id: 675,
									},
									{
										id: 9650,
										name: "Kibimba Court of Appeal ",
										generalAssembly_id: 675,
									},
									{
										id: 11183,
										name: "Kibimba Gacaca court of sector ",
										generalAssembly_id: 675,
									},
								],
							},
							{
								id: 676,
								name: "Kivumo",
								sector_id: 10703,
								courtOfCells: [
									{
										id: 3767,
										name: "MUBUGA",
										generalAssembly_id: 676,
									},
									{
										id: 3768,
										name: "MUSEKERA",
										generalAssembly_id: 676,
									},
									{
										id: 3769,
										name: "MUSHUBAGURIKO",
										generalAssembly_id: 676,
									},
									{
										id: 3770,
										name: "MWUMBA",
										generalAssembly_id: 676,
									},
									{
										id: 3771,
										name: "NYAMUGARI",
										generalAssembly_id: 676,
									},
									{
										id: 3772,
										name: "NYARUBUYE",
										generalAssembly_id: 676,
									},
									{
										id: 3773,
										name: "NYARUVUMU",
										generalAssembly_id: 676,
									},
									{
										id: 3774,
										name: "KINOGI",
										generalAssembly_id: 676,
									},
									{
										id: 3775,
										name: "MATOSHYA",
										generalAssembly_id: 676,
									},
									{
										id: 9651,
										name: "Kivumo Court of Appeal ",
										generalAssembly_id: 676,
									},
									{
										id: 11184,
										name: "Kivumo Gacaca court of sector ",
										generalAssembly_id: 676,
									},
								],
							},
						],
					},
					{
						id: 10704,
						name: "Kiyumba",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070405,
								name: "Rukeri",
								sector_id: 10704,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070404,
								name: "Ruhina",
								sector_id: 10704,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070403,
								name: "Remera",
								sector_id: 10704,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070402,
								name: "Ndago",
								sector_id: 10704,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070401,
								name: "Budende",
								sector_id: 10704,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 677,
								name: "Kigwaguro",
								sector_id: 10704,
								courtOfCells: [
									{
										id: 3776,
										name: "BUGARYI",
										generalAssembly_id: 677,
									},
									{
										id: 3777,
										name: "KARAMBI",
										generalAssembly_id: 677,
									},
									{
										id: 3778,
										name: "KIGWAGURO",
										generalAssembly_id: 677,
									},
									{
										id: 3779,
										name: "MUDUHA",
										generalAssembly_id: 677,
									},
									{
										id: 3780,
										name: "MURAMBI",
										generalAssembly_id: 677,
									},
									{
										id: 3781,
										name: "MUSENYI",
										generalAssembly_id: 677,
									},
									{
										id: 3782,
										name: "MUSHYITSI",
										generalAssembly_id: 677,
									},
									{
										id: 3783,
										name: "NDAGO",
										generalAssembly_id: 677,
									},
									{
										id: 3784,
										name: "NYABISHUNZI",
										generalAssembly_id: 677,
									},
									{
										id: 3785,
										name: "NYARUSANGE",
										generalAssembly_id: 677,
									},
									{
										id: 3786,
										name: "RURAMBA",
										generalAssembly_id: 677,
									},
									{
										id: 9652,
										name: "Kigwaguro Court of Appeal ",
										generalAssembly_id: 677,
									},
									{
										id: 11185,
										name: "Kigwaguro Gacaca court of sector ",
										generalAssembly_id: 677,
									},
								],
							},
							{
								id: 678,
								name: "Kiyumba",
								sector_id: 10704,
								courtOfCells: [
									{
										id: 3787,
										name: "BUGANZA",
										generalAssembly_id: 678,
									},
									{
										id: 3788,
										name: "CYAYI",
										generalAssembly_id: 678,
									},
									{
										id: 3789,
										name: "GAKOMA",
										generalAssembly_id: 678,
									},
									{
										id: 3790,
										name: "KIYUMBA",
										generalAssembly_id: 678,
									},
									{
										id: 3791,
										name: "MIKO",
										generalAssembly_id: 678,
									},
									{
										id: 3792,
										name: "NYABIKENKE",
										generalAssembly_id: 678,
									},
									{
										id: 3793,
										name: "NYARUNYEGE",
										generalAssembly_id: 678,
									},
									{
										id: 3794,
										name: "NYARUSANGE",
										generalAssembly_id: 678,
									},
									{
										id: 3795,
										name: "RUBEZI",
										generalAssembly_id: 678,
									},
									{
										id: 3796,
										name: "SHYANDA",
										generalAssembly_id: 678,
									},
									{
										id: 9653,
										name: "Kiyumba Court of Appeal ",
										generalAssembly_id: 678,
									},
									{
										id: 11186,
										name: "Kiyumba Gacaca court of sector ",
										generalAssembly_id: 678,
									},
								],
							},
							{
								id: 679,
								name: "Mahembe",
								sector_id: 10704,
								courtOfCells: [
									{
										id: 3797,
										name: "AKEZA",
										generalAssembly_id: 679,
									},
									{
										id: 3798,
										name: "BUNUNGU",
										generalAssembly_id: 679,
									},
									{
										id: 3799,
										name: "BUSINDI",
										generalAssembly_id: 679,
									},
									{
										id: 3800,
										name: "CYIRI",
										generalAssembly_id: 679,
									},
									{
										id: 3801,
										name: "GISHUSHU",
										generalAssembly_id: 679,
									},
									{
										id: 3802,
										name: "MBOGO",
										generalAssembly_id: 679,
									},
									{
										id: 3803,
										name: "MUGEYO",
										generalAssembly_id: 679,
									},
									{
										id: 3804,
										name: "MUKO",
										generalAssembly_id: 679,
									},
									{
										id: 3805,
										name: "MWENDO",
										generalAssembly_id: 679,
									},
									{
										id: 3806,
										name: "NEMBA",
										generalAssembly_id: 679,
									},
									{
										id: 9654,
										name: "Mahembe Court of Appeal ",
										generalAssembly_id: 679,
									},
									{
										id: 11187,
										name: "Mahembe Gacaca court of sector ",
										generalAssembly_id: 679,
									},
								],
							},
							{
								id: 680,
								name: "Rukaragata",
								sector_id: 10704,
								courtOfCells: [
									{
										id: 3807,
										name: "BIKOMBE",
										generalAssembly_id: 680,
									},
									{
										id: 3808,
										name: "GASHIRU",
										generalAssembly_id: 680,
									},
									{
										id: 3809,
										name: "GITONDE",
										generalAssembly_id: 680,
									},
									{
										id: 3810,
										name: "NGANZO",
										generalAssembly_id: 680,
									},
									{
										id: 3811,
										name: "REMERA",
										generalAssembly_id: 680,
									},
									{
										id: 9655,
										name: "Rukaragata Court of Appeal ",
										generalAssembly_id: 680,
									},
									{
										id: 11188,
										name: "Rukaragata Gacaca court of sector ",
										generalAssembly_id: 680,
									},
								],
							},
						],
					},
					{
						id: 10705,
						name: "Muhanga",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070505,
								name: "Tyazo",
								sector_id: 10705,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070504,
								name: "Remera",
								sector_id: 10705,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070503,
								name: "Nyamirama",
								sector_id: 10705,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070502,
								name: "Nganzo",
								sector_id: 10705,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070501,
								name: "Kanyinya",
								sector_id: 10705,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 681,
								name: "Karama",
								sector_id: 10705,
								courtOfCells: [
									{
										id: 3812,
										name: "BIGURUBE",
										generalAssembly_id: 681,
									},
									{
										id: 3813,
										name: "GATARE",
										generalAssembly_id: 681,
									},
									{
										id: 3814,
										name: "GIHEMBE",
										generalAssembly_id: 681,
									},
									{
										id: 3815,
										name: "KUMUKENKE",
										generalAssembly_id: 681,
									},
									{
										id: 3816,
										name: "NGANZO",
										generalAssembly_id: 681,
									},
									{
										id: 3817,
										name: "NYAMYUMBA",
										generalAssembly_id: 681,
									},
									{
										id: 3818,
										name: "NYARUBUYE",
										generalAssembly_id: 681,
									},
									{
										id: 9656,
										name: "Karama Court of Appeal ",
										generalAssembly_id: 681,
									},
									{
										id: 11189,
										name: "Karama Gacaca court of sector ",
										generalAssembly_id: 681,
									},
								],
							},
							{
								id: 682,
								name: "Gatikabisi",
								sector_id: 10705,
								courtOfCells: [
									{
										id: 3819,
										name: "GAHONDO",
										generalAssembly_id: 682,
									},
									{
										id: 3820,
										name: "GASOVU",
										generalAssembly_id: 682,
									},
									{
										id: 3821,
										name: "KABANDA",
										generalAssembly_id: 682,
									},
									{
										id: 3822,
										name: "KABUBA",
										generalAssembly_id: 682,
									},
									{
										id: 3823,
										name: "MANITUMVA",
										generalAssembly_id: 682,
									},
									{
										id: 3824,
										name: "NGOMA",
										generalAssembly_id: 682,
									},
									{
										id: 3825,
										name: "NYARUNYINYA",
										generalAssembly_id: 682,
									},
									{
										id: 9657,
										name: "Gatikabisi Court of Appeal ",
										generalAssembly_id: 682,
									},
									{
										id: 11190,
										name: "Gatikabisi Gacaca court of sector ",
										generalAssembly_id: 682,
									},
								],
							},
							{
								id: 683,
								name: "Kagarama",
								sector_id: 10705,
								courtOfCells: [
									{
										id: 3826,
										name: "KANYINYA",
										generalAssembly_id: 683,
									},
									{
										id: 3827,
										name: "MUNZERERI",
										generalAssembly_id: 683,
									},
									{
										id: 3828,
										name: "MURAMA",
										generalAssembly_id: 683,
									},
									{
										id: 3829,
										name: "REMERA",
										generalAssembly_id: 683,
									},
									{
										id: 9658,
										name: "Kagarama Court of Appeal ",
										generalAssembly_id: 683,
									},
									{
										id: 11191,
										name: "Kagarama Gacaca court of sector ",
										generalAssembly_id: 683,
									},
								],
							},
							{
								id: 684,
								name: "Mata",
								sector_id: 10705,
								courtOfCells: [
									{
										id: 3830,
										name: "KIGARAMA",
										generalAssembly_id: 684,
									},
									{
										id: 3831,
										name: "MUBUGA",
										generalAssembly_id: 684,
									},
									{
										id: 3832,
										name: "MUHORORO",
										generalAssembly_id: 684,
									},
									{
										id: 3833,
										name: "MURAMBI",
										generalAssembly_id: 684,
									},
									{
										id: 3834,
										name: "NKOMA",
										generalAssembly_id: 684,
									},
									{
										id: 3835,
										name: "RUGENDABARI",
										generalAssembly_id: 684,
									},
									{
										id: 9659,
										name: "Mata Court of Appeal ",
										generalAssembly_id: 684,
									},
									{
										id: 11192,
										name: "Mata Gacaca court of sector ",
										generalAssembly_id: 684,
									},
								],
							},
							{
								id: 685,
								name: "Muhanga",
								sector_id: 10705,
								courtOfCells: [
									{
										id: 3836,
										name: "GISIZA",
										generalAssembly_id: 685,
									},
									{
										id: 3837,
										name: "KAGOGWE",
										generalAssembly_id: 685,
									},
									{
										id: 3838,
										name: "MUHANGA",
										generalAssembly_id: 685,
									},
									{
										id: 3839,
										name: "MULINDI",
										generalAssembly_id: 685,
									},
									{
										id: 3840,
										name: "MULINJA",
										generalAssembly_id: 685,
									},
									{
										id: 3841,
										name: "NYAMIRAMA",
										generalAssembly_id: 685,
									},
									{
										id: 3842,
										name: "RUTAKA",
										generalAssembly_id: 685,
									},
									{
										id: 9660,
										name: "Muhanga Court of Appeal ",
										generalAssembly_id: 685,
									},
									{
										id: 11193,
										name: "Muhanga Gacaca court of sector ",
										generalAssembly_id: 685,
									},
								],
							},
						],
					},
					{
						id: 10706,
						name: "Mushishiro",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070606,
								name: "Rwigerero",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070605,
								name: "Rwasare",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070604,
								name: "Rukaragata",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070603,
								name: "Nyagasozi",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070602,
								name: "Munazi",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070601,
								name: "Matyazo",
								sector_id: 10706,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 686,
								name: "Bulinga",
								sector_id: 10706,
								courtOfCells: [
									{
										id: 3843,
										name: "BULINGA",
										generalAssembly_id: 686,
									},
									{
										id: 3844,
										name: "GIHARA",
										generalAssembly_id: 686,
									},
									{
										id: 3845,
										name: "GISOVU",
										generalAssembly_id: 686,
									},
									{
										id: 3846,
										name: "KARUYENZI",
										generalAssembly_id: 686,
									},
									{
										id: 3847,
										name: "KAVUMU",
										generalAssembly_id: 686,
									},
									{
										id: 3848,
										name: "KIBINGO",
										generalAssembly_id: 686,
									},
									{
										id: 3849,
										name: "MUHORORO",
										generalAssembly_id: 686,
									},
									{
										id: 3850,
										name: "MWENDO",
										generalAssembly_id: 686,
									},
									{
										id: 9661,
										name: "Bulinga Court of Appeal ",
										generalAssembly_id: 686,
									},
									{
										id: 11194,
										name: "Bulinga Gacaca court of sector ",
										generalAssembly_id: 686,
									},
								],
							},
							{
								id: 687,
								name: "Musange",
								sector_id: 10706,
								courtOfCells: [
									{
										id: 3851,
										name: "KAGARAMA",
										generalAssembly_id: 687,
									},
									{
										id: 3852,
										name: "KIVUMU",
										generalAssembly_id: 687,
									},
									{
										id: 3853,
										name: "MUSANGE",
										generalAssembly_id: 687,
									},
									{
										id: 3854,
										name: "NGIRYI",
										generalAssembly_id: 687,
									},
									{
										id: 3855,
										name: "NYARUNYINYA",
										generalAssembly_id: 687,
									},
									{
										id: 3856,
										name: "NYARUVUMU",
										generalAssembly_id: 687,
									},
									{
										id: 3857,
										name: "RUSUZUMIRO",
										generalAssembly_id: 687,
									},
									{
										id: 9662,
										name: "Musange Court of Appeal ",
										generalAssembly_id: 687,
									},
									{
										id: 11195,
										name: "Musange Gacaca court of sector ",
										generalAssembly_id: 687,
									},
								],
							},
							{
								id: 688,
								name: "Musenyi",
								sector_id: 10706,
								courtOfCells: [
									{
										id: 3858,
										name: "MUSENYI",
										generalAssembly_id: 688,
									},
									{
										id: 3859,
										name: "MUSHISHIRO",
										generalAssembly_id: 688,
									},
									{
										id: 3860,
										name: "RUGALI",
										generalAssembly_id: 688,
									},
									{
										id: 3861,
										name: "RUKENDU",
										generalAssembly_id: 688,
									},
									{
										id: 3862,
										name: "KASEMAKENDE",
										generalAssembly_id: 688,
									},
									{
										id: 9663,
										name: "Musenyi Court of Appeal ",
										generalAssembly_id: 688,
									},
									{
										id: 11196,
										name: "Musenyi Gacaca court of sector ",
										generalAssembly_id: 688,
									},
								],
							},
							{
								id: 689,
								name: "Nyabitare",
								sector_id: 10706,
								courtOfCells: [
									{
										id: 3863,
										name: "NYANZA",
										generalAssembly_id: 689,
									},
									{
										id: 3864,
										name: "GATARAGA",
										generalAssembly_id: 689,
									},
									{
										id: 3865,
										name: "GISENYI",
										generalAssembly_id: 689,
									},
									{
										id: 3866,
										name: "MANAMA",
										generalAssembly_id: 689,
									},
									{
										id: 3867,
										name: "MITSIMBI",
										generalAssembly_id: 689,
									},
									{
										id: 3868,
										name: "MWUMBA",
										generalAssembly_id: 689,
									},
									{
										id: 3869,
										name: "NYAGISOZI",
										generalAssembly_id: 689,
									},
									{
										id: 9664,
										name: "Nyabitare Court of Appeal ",
										generalAssembly_id: 689,
									},
									{
										id: 11197,
										name: "Nyabitare Gacaca court of sector ",
										generalAssembly_id: 689,
									},
								],
							},
							{
								id: 690,
								name: "Nyarutovu",
								sector_id: 10706,
								courtOfCells: [
									{
										id: 3870,
										name: "KAZENGA",
										generalAssembly_id: 690,
									},
									{
										id: 3871,
										name: "KIRIGI",
										generalAssembly_id: 690,
									},
									{
										id: 3872,
										name: "NYARUTOVU",
										generalAssembly_id: 690,
									},
									{
										id: 3873,
										name: "RUGERERO",
										generalAssembly_id: 690,
									},
									{
										id: 9665,
										name: "Nyarutovu Court of Appeal ",
										generalAssembly_id: 690,
									},
									{
										id: 11198,
										name: "Nyarutovu Gacaca court of sector ",
										generalAssembly_id: 690,
									},
								],
							},
						],
					},
					{
						id: 10707,
						name: "Nyabinoni",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070705,
								name: "Nyarusozi",
								sector_id: 10707,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070704,
								name: "Muvumba",
								sector_id: 10707,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070703,
								name: "Mbuga",
								sector_id: 10707,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070702,
								name: "Masangano",
								sector_id: 10707,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070701,
								name: "Gashorera",
								sector_id: 10707,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 691,
								name: "Gitumba",
								sector_id: 10707,
								courtOfCells: [
									{
										id: 3874,
										name: "BUGOMBE",
										generalAssembly_id: 691,
									},
									{
										id: 3875,
										name: "BULIGO",
										generalAssembly_id: 691,
									},
									{
										id: 3876,
										name: "GITABA-KAGOTE",
										generalAssembly_id: 691,
									},
									{
										id: 3877,
										name: "MURINZI",
										generalAssembly_id: 691,
									},
									{
										id: 3878,
										name: "MURAMA",
										generalAssembly_id: 691,
									},
									{
										id: 3879,
										name: "NDUBA",
										generalAssembly_id: 691,
									},
									{
										id: 3880,
										name: "NYARUSANGE",
										generalAssembly_id: 691,
									},
									{
										id: 9666,
										name: "Gitumba Court of Appeal ",
										generalAssembly_id: 691,
									},
									{
										id: 11199,
										name: "Gitumba Gacaca court of sector ",
										generalAssembly_id: 691,
									},
								],
							},
							{
								id: 692,
								name: "Ngaru",
								sector_id: 10707,
								courtOfCells: [
									{
										id: 3881,
										name: "BUNEGANZUKI",
										generalAssembly_id: 692,
									},
									{
										id: 3882,
										name: "GATABA",
										generalAssembly_id: 692,
									},
									{
										id: 3883,
										name: "GATARAGA",
										generalAssembly_id: 692,
									},
									{
										id: 3884,
										name: "KASARE",
										generalAssembly_id: 692,
									},
									{
										id: 3885,
										name: "KIRURI",
										generalAssembly_id: 692,
									},
									{
										id: 3886,
										name: "KIVUMU",
										generalAssembly_id: 692,
									},
									{
										id: 3887,
										name: "KIZINGA",
										generalAssembly_id: 692,
									},
									{
										id: 3888,
										name: "MURAMBI",
										generalAssembly_id: 692,
									},
									{
										id: 3889,
										name: "MUREHE",
										generalAssembly_id: 692,
									},
									{
										id: 3890,
										name: "RUBIRIZI",
										generalAssembly_id: 692,
									},
									{
										id: 3891,
										name: "RWANKUKU",
										generalAssembly_id: 692,
									},
									{
										id: 9667,
										name: "Ngaru Court of Appeal ",
										generalAssembly_id: 692,
									},
									{
										id: 11200,
										name: "Ngaru Gacaca court of sector ",
										generalAssembly_id: 692,
									},
								],
							},
							{
								id: 693,
								name: "Nyabinoni",
								sector_id: 10707,
								courtOfCells: [
									{
										id: 3892,
										name: "BUBAJI",
										generalAssembly_id: 693,
									},
									{
										id: 3893,
										name: "GISORO",
										generalAssembly_id: 693,
									},
									{
										id: 3894,
										name: "GITABA",
										generalAssembly_id: 693,
									},
									{
										id: 3895,
										name: "KASHANGO",
										generalAssembly_id: 693,
									},
									{
										id: 3896,
										name: "MUGENI",
										generalAssembly_id: 693,
									},
									{
										id: 3897,
										name: "MUVUMBA",
										generalAssembly_id: 693,
									},
									{
										id: 3898,
										name: "NYAKAGEZI",
										generalAssembly_id: 693,
									},
									{
										id: 3899,
										name: "NYAMABUYE",
										generalAssembly_id: 693,
									},
									{
										id: 3900,
										name: "RUSEBEYA",
										generalAssembly_id: 693,
									},
									{
										id: 3901,
										name: "RUSENGE",
										generalAssembly_id: 693,
									},
									{
										id: 9668,
										name: "Nyabinoni Court of Appeal ",
										generalAssembly_id: 693,
									},
									{
										id: 11201,
										name: "Nyabinoni Gacaca court of sector ",
										generalAssembly_id: 693,
									},
								],
							},
							{
								id: 694,
								name: "Shaki ",
								sector_id: 10707,
								courtOfCells: [
									{
										id: 3902,
										name: "CYAMAGANA",
										generalAssembly_id: 694,
									},
									{
										id: 3903,
										name: "GAKENKE",
										generalAssembly_id: 694,
									},
									{
										id: 3904,
										name: "KARA",
										generalAssembly_id: 694,
									},
									{
										id: 3905,
										name: "KARERA",
										generalAssembly_id: 694,
									},
									{
										id: 3906,
										name: "KIVUMU",
										generalAssembly_id: 694,
									},
									{
										id: 3907,
										name: "MUBUGA",
										generalAssembly_id: 694,
									},
									{
										id: 3908,
										name: "MURAMBI",
										generalAssembly_id: 694,
									},
									{
										id: 9669,
										name: "Shaki  Court of Appeal ",
										generalAssembly_id: 694,
									},
									{
										id: 11202,
										name: "Shaki  Gacaca court of sector ",
										generalAssembly_id: 694,
									},
								],
							},
						],
					},
					{
						id: 10708,
						name: "Nyamabuye",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070804,
								name: "Remera",
								sector_id: 10708,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070803,
								name: "Gitarama",
								sector_id: 10708,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070802,
								name: "Gifumba",
								sector_id: 10708,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070801,
								name: "Gahogo",
								sector_id: 10708,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 695,
								name: "Gahogo",
								sector_id: 10708,
								courtOfCells: [
									{
										id: 3909,
										name: "BITI-NETI",
										generalAssembly_id: 695,
									},
									{
										id: 3910,
										name: "KAVUMU",
										generalAssembly_id: 695,
									},
									{
										id: 3911,
										name: "NYARUCYAMU",
										generalAssembly_id: 695,
									},
									{
										id: 3912,
										name: "RUTENGA",
										generalAssembly_id: 695,
									},
									{
										id: 9670,
										name: "Gahogo Court of Appeal ",
										generalAssembly_id: 695,
									},
									{
										id: 11203,
										name: "Gahogo Gacaca court of sector ",
										generalAssembly_id: 695,
									},
								],
							},
							{
								id: 696,
								name: "Gifumba",
								sector_id: 10708,
								courtOfCells: [
									{
										id: 3913,
										name: "GIFUMBA",
										generalAssembly_id: 696,
									},
									{
										id: 3914,
										name: "GISIZA",
										generalAssembly_id: 696,
									},
									{
										id: 3915,
										name: "KIREBE",
										generalAssembly_id: 696,
									},
									{
										id: 3916,
										name: "RUGARAMA",
										generalAssembly_id: 696,
									},
									{
										id: 3917,
										name: "RUTARABANA",
										generalAssembly_id: 696,
									},
									{
										id: 3918,
										name: "SAMUDUHA",
										generalAssembly_id: 696,
									},
									{
										id: 9671,
										name: "Gifumba Court of Appeal ",
										generalAssembly_id: 696,
									},
									{
										id: 11204,
										name: "Gifumba Gacaca court of sector ",
										generalAssembly_id: 696,
									},
								],
							},
							{
								id: 697,
								name: "Gihuma",
								sector_id: 10708,
								courtOfCells: [
									{
										id: 3919,
										name: "GIHUMA",
										generalAssembly_id: 697,
									},
									{
										id: 3920,
										name: "KAMAZURU",
										generalAssembly_id: 697,
									},
									{
										id: 3921,
										name: "KAVUMU",
										generalAssembly_id: 697,
									},
									{
										id: 9672,
										name: "Gihuma Court of Appeal ",
										generalAssembly_id: 697,
									},
									{
										id: 11205,
										name: "Gihuma Gacaca court of sector ",
										generalAssembly_id: 697,
									},
								],
							},
							{
								id: 698,
								name: "Gitarama",
								sector_id: 10708,
								courtOfCells: [
									{
										id: 3922,
										name: "KAVUMU",
										generalAssembly_id: 698,
									},
									{
										id: 3923,
										name: "NYABISINDU",
										generalAssembly_id: 698,
									},
									{
										id: 3924,
										name: "NYAMABUYE",
										generalAssembly_id: 698,
									},
									{
										id: 3925,
										name: "GITARAMA",
										generalAssembly_id: 698,
									},
									{
										id: 9673,
										name: "Gitarama Court of Appeal ",
										generalAssembly_id: 698,
									},
									{
										id: 11206,
										name: "Gitarama Gacaca court of sector ",
										generalAssembly_id: 698,
									},
								],
							},
							{
								id: 699,
								name: "Remera",
								sector_id: 10708,
								courtOfCells: [
									{
										id: 3926,
										name: "GASENYI",
										generalAssembly_id: 699,
									},
									{
										id: 3927,
										name: "GASHARU",
										generalAssembly_id: 699,
									},
									{
										id: 3928,
										name: "KINYENKANDA",
										generalAssembly_id: 699,
									},
									{
										id: 3929,
										name: "MUNINI",
										generalAssembly_id: 699,
									},
									{
										id: 3930,
										name: "NYAKABINGO",
										generalAssembly_id: 699,
									},
									{
										id: 3931,
										name: "REMERA",
										generalAssembly_id: 699,
									},
									{
										id: 3932,
										name: "BURIBA",
										generalAssembly_id: 699,
									},
									{
										id: 3933,
										name: "MUGUNGURU",
										generalAssembly_id: 699,
									},
									{
										id: 3934,
										name: "NYARUBUYE",
										generalAssembly_id: 699,
									},
									{
										id: 9674,
										name: "Remera Court of Appeal ",
										generalAssembly_id: 699,
									},
									{
										id: 11207,
										name: "Remera Gacaca court of sector ",
										generalAssembly_id: 699,
									},
								],
							},
						],
					},
					{
						id: 10709,
						name: "Nyarusange",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1070904,
								name: "Rusovu",
								sector_id: 10709,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070903,
								name: "Ngaru",
								sector_id: 10709,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070902,
								name: "Musongati",
								sector_id: 10709,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1070901,
								name: "Mbiriri",
								sector_id: 10709,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 700,
								name: "Gikomero",
								sector_id: 10709,
								courtOfCells: [
									{
										id: 3935,
										name: "GIKOMERO",
										generalAssembly_id: 700,
									},
									{
										id: 3936,
										name: "MBIRIRI",
										generalAssembly_id: 700,
									},
									{
										id: 3937,
										name: "NTENDERI",
										generalAssembly_id: 700,
									},
									{
										id: 3938,
										name: "NTIMBIRI",
										generalAssembly_id: 700,
									},
									{
										id: 9675,
										name: "Gikomero Court of Appeal ",
										generalAssembly_id: 700,
									},
									{
										id: 11208,
										name: "Gikomero Gacaca court of sector ",
										generalAssembly_id: 700,
									},
								],
							},
							{
								id: 701,
								name: "Kaduha",
								sector_id: 10709,
								courtOfCells: [
									{
										id: 3939,
										name: "REMERA",
										generalAssembly_id: 701,
									},
									{
										id: 3940,
										name: "CYICIRO",
										generalAssembly_id: 701,
									},
									{
										id: 3941,
										name: "JABIRO",
										generalAssembly_id: 701,
									},
									{
										id: 3942,
										name: "KAGARAMA",
										generalAssembly_id: 701,
									},
									{
										id: 3943,
										name: "MURAMBI",
										generalAssembly_id: 701,
									},
									{
										id: 3944,
										name: "MUREHERWA",
										generalAssembly_id: 701,
									},
									{
										id: 9676,
										name: "Kaduha Court of Appeal ",
										generalAssembly_id: 701,
									},
									{
										id: 11209,
										name: "Kaduha Gacaca court of sector ",
										generalAssembly_id: 701,
									},
								],
							},
							{
								id: 702,
								name: "Mwaka ",
								sector_id: 10709,
								courtOfCells: [
									{
										id: 3945,
										name: "MATABA",
										generalAssembly_id: 702,
									},
									{
										id: 3946,
										name: "MUTUTU",
										generalAssembly_id: 702,
									},
									{
										id: 3947,
										name: "NYARUSANGE",
										generalAssembly_id: 702,
									},
									{
										id: 3948,
										name: "RUSENGESI",
										generalAssembly_id: 702,
									},
									{
										id: 3949,
										name: "VUGO",
										generalAssembly_id: 702,
									},
									{
										id: 3950,
										name: "GASUMO",
										generalAssembly_id: 702,
									},
									{
										id: 9677,
										name: "Mwaka  Court of Appeal ",
										generalAssembly_id: 702,
									},
									{
										id: 11210,
										name: "Mwaka  Gacaca court of sector ",
										generalAssembly_id: 702,
									},
								],
							},
							{
								id: 703,
								name: "Cukiro",
								sector_id: 10709,
								courtOfCells: [
									{
										id: 3951,
										name: "GACUKIRO",
										generalAssembly_id: 703,
									},
									{
										id: 3952,
										name: "KANYONI",
										generalAssembly_id: 703,
									},
									{
										id: 3953,
										name: "NGARU",
										generalAssembly_id: 703,
									},
									{
										id: 3954,
										name: "NYANZA",
										generalAssembly_id: 703,
									},
									{
										id: 3955,
										name: "RUKAMIRO",
										generalAssembly_id: 703,
									},
									{
										id: 9678,
										name: "Cukiro Court of Appeal ",
										generalAssembly_id: 703,
									},
									{
										id: 11211,
										name: "Cukiro Gacaca court of sector ",
										generalAssembly_id: 703,
									},
								],
							},
						],
					},
					{
						id: 10710,
						name: "Rongi",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1071005,
								name: "Ruhango",
								sector_id: 10710,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071004,
								name: "Nyamirambo",
								sector_id: 10710,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071003,
								name: "Karambo",
								sector_id: 10710,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071002,
								name: "Gasharu",
								sector_id: 10710,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071001,
								name: "Gasagara",
								sector_id: 10710,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 704,
								name: "Gitovu",
								sector_id: 10710,
								courtOfCells: [
									{
										id: 3956,
										name: "GISURA",
										generalAssembly_id: 704,
									},
									{
										id: 3957,
										name: "GITOVU",
										generalAssembly_id: 704,
									},
									{
										id: 3958,
										name: "KABERE",
										generalAssembly_id: 704,
									},
									{
										id: 3959,
										name: "KADUHA",
										generalAssembly_id: 704,
									},
									{
										id: 3960,
										name: "KAZIKA",
										generalAssembly_id: 704,
									},
									{
										id: 3961,
										name: "NKANGA",
										generalAssembly_id: 704,
									},
									{
										id: 3962,
										name: "NTARABANA",
										generalAssembly_id: 704,
									},
									{
										id: 3963,
										name: "NTUNGAMO",
										generalAssembly_id: 704,
									},
									{
										id: 3964,
										name: "NYARUSANGE",
										generalAssembly_id: 704,
									},
									{
										id: 3965,
										name: "RONGI",
										generalAssembly_id: 704,
									},
									{
										id: 3966,
										name: "RUBONA",
										generalAssembly_id: 704,
									},
									{
										id: 3967,
										name: "RWAMATOVU",
										generalAssembly_id: 704,
									},
									{
										id: 9679,
										name: "Gitovu Court of Appeal ",
										generalAssembly_id: 704,
									},
									{
										id: 11212,
										name: "Gitovu Gacaca court of sector ",
										generalAssembly_id: 704,
									},
								],
							},
							{
								id: 705,
								name: "Kigina ",
								sector_id: 10710,
								courtOfCells: [
									{
										id: 3968,
										name: "BUDUNDURI",
										generalAssembly_id: 705,
									},
									{
										id: 3969,
										name: "GASHINGE",
										generalAssembly_id: 705,
									},
									{
										id: 3970,
										name: "GISORO",
										generalAssembly_id: 705,
									},
									{
										id: 3971,
										name: "GISOVU",
										generalAssembly_id: 705,
									},
									{
										id: 3972,
										name: "KABAGANDO",
										generalAssembly_id: 705,
									},
									{
										id: 3973,
										name: "KABIRIZI",
										generalAssembly_id: 705,
									},
									{
										id: 3974,
										name: "KIGINGA",
										generalAssembly_id: 705,
									},
									{
										id: 3975,
										name: "NYAGASOZI",
										generalAssembly_id: 705,
									},
									{
										id: 3976,
										name: "NYAMIYAGA",
										generalAssembly_id: 705,
									},
									{
										id: 3977,
										name: "NYENYERI",
										generalAssembly_id: 705,
									},
									{
										id: 9680,
										name: "Kigina  Court of Appeal ",
										generalAssembly_id: 705,
									},
									{
										id: 11213,
										name: "Kigina  Gacaca court of sector ",
										generalAssembly_id: 705,
									},
								],
							},
							{
								id: 706,
								name: "Mugunga",
								sector_id: 10710,
								courtOfCells: [
									{
										id: 3978,
										name: "GASIZA",
										generalAssembly_id: 706,
									},
									{
										id: 3979,
										name: "GITWA",
										generalAssembly_id: 706,
									},
									{
										id: 3980,
										name: "KABERE",
										generalAssembly_id: 706,
									},
									{
										id: 3981,
										name: "KAGEYO",
										generalAssembly_id: 706,
									},
									{
										id: 3982,
										name: "KIRUMBA",
										generalAssembly_id: 706,
									},
									{
										id: 3983,
										name: "MUGUNGA",
										generalAssembly_id: 706,
									},
									{
										id: 3984,
										name: "NYAGAHONDO",
										generalAssembly_id: 706,
									},
									{
										id: 3985,
										name: "NYUNDO",
										generalAssembly_id: 706,
									},
									{
										id: 3986,
										name: "RUHINDAGE",
										generalAssembly_id: 706,
									},
									{
										id: 9681,
										name: "Mugunga Court of Appeal ",
										generalAssembly_id: 706,
									},
									{
										id: 11214,
										name: "Mugunga Gacaca court of sector ",
										generalAssembly_id: 706,
									},
								],
							},
							{
								id: 707,
								name: "Rusuli",
								sector_id: 10710,
								courtOfCells: [
									{
										id: 3987,
										name: "GITABA",
										generalAssembly_id: 707,
									},
									{
										id: 3988,
										name: "JANDARI",
										generalAssembly_id: 707,
									},
									{
										id: 3989,
										name: "KAGUSA",
										generalAssembly_id: 707,
									},
									{
										id: 3990,
										name: "MUNYEGERA",
										generalAssembly_id: 707,
									},
									{
										id: 3991,
										name: "NYARUTOVU",
										generalAssembly_id: 707,
									},
									{
										id: 3992,
										name: "RORERO",
										generalAssembly_id: 707,
									},
									{
										id: 9682,
										name: "Rusuli Court of Appeal ",
										generalAssembly_id: 707,
									},
									{
										id: 11215,
										name: "Rusuli Gacaca court of sector ",
										generalAssembly_id: 707,
									},
								],
							},
							{
								id: 708,
								name: "Gasagara ",
								sector_id: 10710,
								courtOfCells: [
									{
										id: 3993,
										name: "GASHARU",
										generalAssembly_id: 708,
									},
									{
										id: 3994,
										name: "KAVUMU",
										generalAssembly_id: 708,
									},
									{
										id: 3995,
										name: "KIDAHWE",
										generalAssembly_id: 708,
									},
									{
										id: 3996,
										name: "KIGALI",
										generalAssembly_id: 708,
									},
									{
										id: 3997,
										name: "MUREHE",
										generalAssembly_id: 708,
									},
									{
										id: 9683,
										name: "Gasagara  Court of Appeal ",
										generalAssembly_id: 708,
									},
									{
										id: 11216,
										name: "Gasagara  Gacaca court of sector ",
										generalAssembly_id: 708,
									},
								],
							},
						],
					},
					{
						id: 10711,
						name: "Rugendabari",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1071105,
								name: "Nsanga",
								sector_id: 10711,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071104,
								name: "Mpinga",
								sector_id: 10711,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071103,
								name: "Kibaga",
								sector_id: 10711,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071102,
								name: "Kanyana",
								sector_id: 10711,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071101,
								name: "Gasave",
								sector_id: 10711,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 709,
								name: "Gasovu",
								sector_id: 10711,
								courtOfCells: [
									{
										id: 3998,
										name: "BWIMO",
										generalAssembly_id: 709,
									},
									{
										id: 3999,
										name: "KABUBA",
										generalAssembly_id: 709,
									},
									{
										id: 4000,
										name: "KANYANA",
										generalAssembly_id: 709,
									},
									{
										id: 4001,
										name: "MUGALI",
										generalAssembly_id: 709,
									},
									{
										id: 4002,
										name: "MUHETA",
										generalAssembly_id: 709,
									},
									{
										id: 4003,
										name: "NTONDE",
										generalAssembly_id: 709,
									},
									{
										id: 4004,
										name: "RUSEKE",
										generalAssembly_id: 709,
									},
									{
										id: 9684,
										name: "Gasovu Court of Appeal ",
										generalAssembly_id: 709,
									},
									{
										id: 11217,
										name: "Gasovu Gacaca court of sector ",
										generalAssembly_id: 709,
									},
								],
							},
							{
								id: 710,
								name: "Gasave",
								sector_id: 10711,
								courtOfCells: [
									{
										id: 4005,
										name: "GASAVE",
										generalAssembly_id: 710,
									},
									{
										id: 4006,
										name: "KIDATURWA",
										generalAssembly_id: 710,
									},
									{
										id: 4007,
										name: "MUSHINGISHIRO",
										generalAssembly_id: 710,
									},
									{
										id: 4008,
										name: "NYAKIBUYE",
										generalAssembly_id: 710,
									},
									{
										id: 4009,
										name: "NYAMATETE",
										generalAssembly_id: 710,
									},
									{
										id: 9685,
										name: "Gasave Court of Appeal ",
										generalAssembly_id: 710,
									},
									{
										id: 11218,
										name: "Gasave Gacaca court of sector ",
										generalAssembly_id: 710,
									},
								],
							},
							{
								id: 711,
								name: "Kirwa",
								sector_id: 10711,
								courtOfCells: [
									{
										id: 4010,
										name: "BUGANDA",
										generalAssembly_id: 711,
									},
									{
										id: 4011,
										name: "KIBAGA",
										generalAssembly_id: 711,
									},
									{
										id: 4012,
										name: "KIDUHA",
										generalAssembly_id: 711,
									},
									{
										id: 4013,
										name: "KINOGI",
										generalAssembly_id: 711,
									},
									{
										id: 4014,
										name: "KIRAMO",
										generalAssembly_id: 711,
									},
									{
										id: 4015,
										name: "MPINGA",
										generalAssembly_id: 711,
									},
									{
										id: 4016,
										name: "NDORA",
										generalAssembly_id: 711,
									},
									{
										id: 4017,
										name: "NKEGETE",
										generalAssembly_id: 711,
									},
									{
										id: 4018,
										name: "NYAKAGEZI",
										generalAssembly_id: 711,
									},
									{
										id: 4019,
										name: "NYAMIVUMU",
										generalAssembly_id: 711,
									},
									{
										id: 9686,
										name: "Kirwa Court of Appeal ",
										generalAssembly_id: 711,
									},
									{
										id: 11219,
										name: "Kirwa Gacaca court of sector ",
										generalAssembly_id: 711,
									},
								],
							},
							{
								id: 712,
								name: "Rugendabari",
								sector_id: 10711,
								courtOfCells: [
									{
										id: 4020,
										name: "GISHARI",
										generalAssembly_id: 712,
									},
									{
										id: 4021,
										name: "MPONGO",
										generalAssembly_id: 712,
									},
									{
										id: 4022,
										name: "NGANDO",
										generalAssembly_id: 712,
									},
									{
										id: 4023,
										name: "NSANGA",
										generalAssembly_id: 712,
									},
									{
										id: 4024,
										name: "NYUNDO",
										generalAssembly_id: 712,
									},
									{
										id: 4025,
										name: "RUGENDABARI",
										generalAssembly_id: 712,
									},
									{
										id: 4026,
										name: "TWABUMBOGO",
										generalAssembly_id: 712,
									},
									{
										id: 9687,
										name: "Rugendabari Court of Appeal ",
										generalAssembly_id: 712,
									},
									{
										id: 11220,
										name: "Rugendabari Gacaca court of sector ",
										generalAssembly_id: 712,
									},
								],
							},
						],
					},
					{
						id: 10712,
						name: "Shyogwe",
						district_id: 107,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1071204,
								name: "Ruli",
								sector_id: 10712,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071203,
								name: "Mubuga",
								sector_id: 10712,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071202,
								name: "Mbare",
								sector_id: 10712,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1071201,
								name: "Kinini",
								sector_id: 10712,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 713,
								name: "Ruli",
								sector_id: 10712,
								courtOfCells: [
									{
										id: 4027,
										name: "GAKOMBE",
										generalAssembly_id: 713,
									},
									{
										id: 4028,
										name: "KAGUHU",
										generalAssembly_id: 713,
									},
									{
										id: 4029,
										name: "KARAMA",
										generalAssembly_id: 713,
									},
									{
										id: 4030,
										name: "KIGANDA",
										generalAssembly_id: 713,
									},
									{
										id: 4031,
										name: "MUHORORO",
										generalAssembly_id: 713,
									},
									{
										id: 4032,
										name: "MURAMBI",
										generalAssembly_id: 713,
									},
									{
										id: 4033,
										name: "NYAGACYAMU",
										generalAssembly_id: 713,
									},
									{
										id: 4034,
										name: "NYARUCYAMO",
										generalAssembly_id: 713,
									},
									{
										id: 4035,
										name: "RUHINA",
										generalAssembly_id: 713,
									},
									{
										id: 9688,
										name: "Ruli Court of Appeal ",
										generalAssembly_id: 713,
									},
									{
										id: 11221,
										name: "Ruli Gacaca court of sector ",
										generalAssembly_id: 713,
									},
								],
							},
							{
								id: 714,
								name: "Rwamaraba",
								sector_id: 10712,
								courtOfCells: [
									{
										id: 4036,
										name: "CYIRI",
										generalAssembly_id: 714,
									},
									{
										id: 4037,
										name: "GAKOMEYE",
										generalAssembly_id: 714,
									},
									{
										id: 4038,
										name: "MAPFUNDO",
										generalAssembly_id: 714,
									},
									{
										id: 4039,
										name: "MATSINSI",
										generalAssembly_id: 714,
									},
									{
										id: 4040,
										name: "RUCUNSHU",
										generalAssembly_id: 714,
									},
									{
										id: 4041,
										name: "RUKAZA",
										generalAssembly_id: 714,
									},
									{
										id: 4042,
										name: "RWAMARABA",
										generalAssembly_id: 714,
									},
									{
										id: 9689,
										name: "Rwamaraba Court of Appeal ",
										generalAssembly_id: 714,
									},
									{
										id: 11222,
										name: "Rwamaraba Gacaca court of sector ",
										generalAssembly_id: 714,
									},
								],
							},
							{
								id: 715,
								name: "Shyogwe",
								sector_id: 10712,
								courtOfCells: [
									{
										id: 4043,
										name: "GASHARU",
										generalAssembly_id: 715,
									},
									{
										id: 4044,
										name: "GATARE",
										generalAssembly_id: 715,
									},
									{
										id: 4045,
										name: "KABUNGO",
										generalAssembly_id: 715,
									},
									{
										id: 4046,
										name: "KIGARAMA",
										generalAssembly_id: 715,
									},
									{
										id: 4047,
										name: "KINYAMI",
										generalAssembly_id: 715,
									},
									{
										id: 4048,
										name: "MUREMBERI",
										generalAssembly_id: 715,
									},
									{
										id: 4049,
										name: "NYAKABINGO",
										generalAssembly_id: 715,
									},
									{
										id: 4050,
										name: "NYAKAGUHU",
										generalAssembly_id: 715,
									},
									{
										id: 4051,
										name: "NYAMAGANDA",
										generalAssembly_id: 715,
									},
									{
										id: 4052,
										name: "NYARUCYAMU",
										generalAssembly_id: 715,
									},
									{
										id: 4053,
										name: "RWAKIRENGE",
										generalAssembly_id: 715,
									},
									{
										id: 9690,
										name: "Shyogwe Court of Appeal ",
										generalAssembly_id: 715,
									},
									{
										id: 11223,
										name: "Shyogwe Gacaca court of sector ",
										generalAssembly_id: 715,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 105,
				name: "Nyamagabe",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10501,
						name: "Buruhukiro",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050106,
								name: "Rambya",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050105,
								name: "Munini",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050104,
								name: "Kizimyamuriro",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050103,
								name: "Gifurwe",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050102,
								name: "Byimana",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050101,
								name: "Bushigishigi",
								sector_id: 10501,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 958,
								name: "Bushigishigi ",
								sector_id: 10501,
								courtOfCells: [
									{
										id: 5465,
										name: "BUSHIGISHIGI",
										generalAssembly_id: 958,
									},
									{
										id: 5466,
										name: "GIHARAYUMBU",
										generalAssembly_id: 958,
									},
									{
										id: 5467,
										name: "GIKUNGU",
										generalAssembly_id: 958,
									},
									{
										id: 5468,
										name: "GISHWATI",
										generalAssembly_id: 958,
									},
									{
										id: 5469,
										name: "KINABA",
										generalAssembly_id: 958,
									},
									{
										id: 5470,
										name: "MINAGA",
										generalAssembly_id: 958,
									},
									{
										id: 5471,
										name: "MUGOTE",
										generalAssembly_id: 958,
									},
									{
										id: 5472,
										name: "MUJERENGE",
										generalAssembly_id: 958,
									},
									{
										id: 5473,
										name: "RUSEKERA",
										generalAssembly_id: 958,
									},
									{
										id: 5474,
										name: "TANTAMARA",
										generalAssembly_id: 958,
									},
									{
										id: 5475,
										name: "UWINZIRA",
										generalAssembly_id: 958,
									},
									{
										id: 9933,
										name: "Bushigishigi  Court of Appeal ",
										generalAssembly_id: 958,
									},
									{
										id: 11466,
										name: "Bushigishigi  Gacaca court of sector ",
										generalAssembly_id: 958,
									},
								],
							},
							{
								id: 959,
								name: "Gakangaga ",
								sector_id: 10501,
								courtOfCells: [
									{
										id: 5476,
										name: "BISHYIGA",
										generalAssembly_id: 959,
									},
									{
										id: 5477,
										name: "BITEBO",
										generalAssembly_id: 959,
									},
									{
										id: 5478,
										name: "BURUHUKIRO",
										generalAssembly_id: 959,
									},
									{
										id: 5479,
										name: "GAKANGAGA",
										generalAssembly_id: 959,
									},
									{
										id: 5480,
										name: "GIHUMO",
										generalAssembly_id: 959,
									},
									{
										id: 5481,
										name: "KIBUBURO",
										generalAssembly_id: 959,
									},
									{
										id: 5482,
										name: "MPANGA",
										generalAssembly_id: 959,
									},
									{
										id: 5483,
										name: "NKAMBA",
										generalAssembly_id: 959,
									},
									{
										id: 5484,
										name: "RUKERI",
										generalAssembly_id: 959,
									},
									{
										id: 5485,
										name: "RUSEKE",
										generalAssembly_id: 959,
									},
									{
										id: 9934,
										name: "Gakangaga  Court of Appeal ",
										generalAssembly_id: 959,
									},
									{
										id: 11467,
										name: "Gakangaga  Gacaca court of sector ",
										generalAssembly_id: 959,
									},
								],
							},
							{
								id: 960,
								name: "Gifurwe ",
								sector_id: 10501,
								courtOfCells: [
									{
										id: 5486,
										name: "BITABA",
										generalAssembly_id: 960,
									},
									{
										id: 5487,
										name: "CYINYONZA",
										generalAssembly_id: 960,
									},
									{
										id: 5488,
										name: "GIFURWE",
										generalAssembly_id: 960,
									},
									{
										id: 5489,
										name: "GITOVU",
										generalAssembly_id: 960,
									},
									{
										id: 5490,
										name: "KABUGA",
										generalAssembly_id: 960,
									},
									{
										id: 5491,
										name: "MAGUMIRA",
										generalAssembly_id: 960,
									},
									{
										id: 5492,
										name: "MATSINDA",
										generalAssembly_id: 960,
									},
									{
										id: 5493,
										name: "MUNINI",
										generalAssembly_id: 960,
									},
									{
										id: 5494,
										name: "NGANZO",
										generalAssembly_id: 960,
									},
									{
										id: 5495,
										name: "NYAMABERI",
										generalAssembly_id: 960,
									},
									{
										id: 5496,
										name: "RUKWANDU",
										generalAssembly_id: 960,
									},
									{
										id: 5497,
										name: "RURONZI",
										generalAssembly_id: 960,
									},
									{
										id: 5498,
										name: "UWANKIRIYE",
										generalAssembly_id: 960,
									},
									{
										id: 5499,
										name: "UWINZOVU",
										generalAssembly_id: 960,
									},
									{
										id: 9935,
										name: "Gifurwe  Court of Appeal ",
										generalAssembly_id: 960,
									},
									{
										id: 11468,
										name: "Gifurwe  Gacaca court of sector ",
										generalAssembly_id: 960,
									},
								],
							},
						],
					},
					{
						id: 10502,
						name: "Cyanika",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050206,
								name: "Nyanzoga",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050205,
								name: "Nyanza",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050204,
								name: "Ngoma",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050203,
								name: "Kiyumba",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050202,
								name: "Karama",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050201,
								name: "Gitega",
								sector_id: 10502,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 961,
								name: "Cyanika ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5500,
										name: "BIRAMBO_KARABA",
										generalAssembly_id: 961,
									},
									{
										id: 5501,
										name: "KARAMA",
										generalAssembly_id: 961,
									},
									{
										id: 5502,
										name: "MUGAMBA",
										generalAssembly_id: 961,
									},
									{
										id: 5503,
										name: "MUNYINYA",
										generalAssembly_id: 961,
									},
									{
										id: 5504,
										name: "NYAMISAVE",
										generalAssembly_id: 961,
									},
									{
										id: 5505,
										name: "NYANZA_I",
										generalAssembly_id: 961,
									},
									{
										id: 5506,
										name: "RWAMAGANA",
										generalAssembly_id: 961,
									},
									{
										id: 9936,
										name: "Cyanika  Court of Appeal ",
										generalAssembly_id: 961,
									},
									{
										id: 11469,
										name: "Cyanika  Gacaca court of sector ",
										generalAssembly_id: 961,
									},
								],
							},
							{
								id: 962,
								name: "Gitega ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5507,
										name: "BUTARE",
										generalAssembly_id: 962,
									},
									{
										id: 5508,
										name: "GASEKE",
										generalAssembly_id: 962,
									},
									{
										id: 5509,
										name: "GASHARU",
										generalAssembly_id: 962,
									},
									{
										id: 5510,
										name: "GITEGA",
										generalAssembly_id: 962,
									},
									{
										id: 5511,
										name: "KIGARAMA",
										generalAssembly_id: 962,
									},
									{
										id: 5512,
										name: "MUNYERERI",
										generalAssembly_id: 962,
									},
									{
										id: 5513,
										name: "MUSASA",
										generalAssembly_id: 962,
									},
									{
										id: 5514,
										name: "RUSARASI",
										generalAssembly_id: 962,
									},
									{
										id: 5515,
										name: "RWINGOMA",
										generalAssembly_id: 962,
									},
									{
										id: 9937,
										name: "Gitega  Court of Appeal ",
										generalAssembly_id: 962,
									},
									{
										id: 11470,
										name: "Gitega  Gacaca court of sector ",
										generalAssembly_id: 962,
									},
								],
							},
							{
								id: 963,
								name: "Kibingo ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5516,
										name: "BUHIGA",
										generalAssembly_id: 963,
									},
									{
										id: 5517,
										name: "MIRAMA",
										generalAssembly_id: 963,
									},
									{
										id: 5518,
										name: "MUGOMBWA",
										generalAssembly_id: 963,
									},
									{
										id: 5519,
										name: "MUGWE",
										generalAssembly_id: 963,
									},
									{
										id: 5520,
										name: "NYABISINDU",
										generalAssembly_id: 963,
									},
									{
										id: 5521,
										name: "NYANZA_II",
										generalAssembly_id: 963,
									},
									{
										id: 5522,
										name: "RUGARAGARA",
										generalAssembly_id: 963,
									},
									{
										id: 9938,
										name: "Kibingo  Court of Appeal ",
										generalAssembly_id: 963,
									},
									{
										id: 11471,
										name: "Kibingo  Gacaca court of sector ",
										generalAssembly_id: 963,
									},
								],
							},
							{
								id: 964,
								name: "Kiyumba ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5523,
										name: "GATARE",
										generalAssembly_id: 964,
									},
									{
										id: 5524,
										name: "GATENTWE",
										generalAssembly_id: 964,
									},
									{
										id: 5525,
										name: "GIKOMERO",
										generalAssembly_id: 964,
									},
									{
										id: 5526,
										name: "GISHIKE",
										generalAssembly_id: 964,
									},
									{
										id: 5527,
										name: "KABARERA",
										generalAssembly_id: 964,
									},
									{
										id: 5528,
										name: "KAGARAMA",
										generalAssembly_id: 964,
									},
									{
										id: 5529,
										name: "NYARUCYAMO",
										generalAssembly_id: 964,
									},
									{
										id: 9939,
										name: "Kiyumba  Court of Appeal ",
										generalAssembly_id: 964,
									},
									{
										id: 11472,
										name: "Kiyumba  Gacaca court of sector ",
										generalAssembly_id: 964,
									},
								],
							},
							{
								id: 965,
								name: "Ngoma ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5530,
										name: "BIGAZI",
										generalAssembly_id: 965,
									},
									{
										id: 5531,
										name: "KABANGU",
										generalAssembly_id: 965,
									},
									{
										id: 5532,
										name: "KAMUHIRWA",
										generalAssembly_id: 965,
									},
									{
										id: 5533,
										name: "KAVUMU",
										generalAssembly_id: 965,
									},
									{
										id: 5534,
										name: "KINGA",
										generalAssembly_id: 965,
									},
									{
										id: 5535,
										name: "MURAMA",
										generalAssembly_id: 965,
									},
									{
										id: 5536,
										name: "NYARUTOVU",
										generalAssembly_id: 965,
									},
									{
										id: 9940,
										name: "Ngoma  Court of Appeal ",
										generalAssembly_id: 965,
									},
									{
										id: 11473,
										name: "Ngoma  Gacaca court of sector ",
										generalAssembly_id: 965,
									},
								],
							},
							{
								id: 966,
								name: "Nyanzoga ",
								sector_id: 10502,
								courtOfCells: [
									{
										id: 5537,
										name: "GAFUHISHA",
										generalAssembly_id: 966,
									},
									{
										id: 5538,
										name: "KAGARAMA",
										generalAssembly_id: 966,
									},
									{
										id: 5539,
										name: "KARUVENYA",
										generalAssembly_id: 966,
									},
									{
										id: 5540,
										name: "MBEHO",
										generalAssembly_id: 966,
									},
									{
										id: 5541,
										name: "MUGALI",
										generalAssembly_id: 966,
									},
									{
										id: 5542,
										name: "RUSENYI",
										generalAssembly_id: 966,
									},
									{
										id: 9941,
										name: "Nyanzoga  Court of Appeal ",
										generalAssembly_id: 966,
									},
									{
										id: 11474,
										name: "Nyanzoga  Gacaca court of sector ",
										generalAssembly_id: 966,
									},
								],
							},
						],
					},
					{
						id: 10503,
						name: "Gasaka",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050306,
								name: "Remera",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050305,
								name: "Nzega",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050304,
								name: "Nyamugari",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050303,
								name: "Nyabivumu",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050302,
								name: "Ngiryi",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050301,
								name: "Kigeme",
								sector_id: 10503,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 967,
								name: "Gasaka ",
								sector_id: 10503,
								courtOfCells: [
									{
										id: 5543,
										name: "BIHANGA",
										generalAssembly_id: 967,
									},
									{
										id: 5544,
										name: "GASAKA",
										generalAssembly_id: 967,
									},
									{
										id: 5545,
										name: "MUNOMBE",
										generalAssembly_id: 967,
									},
									{
										id: 5546,
										name: "NYENTANGA",
										generalAssembly_id: 967,
									},
									{
										id: 5547,
										name: "NZEGA",
										generalAssembly_id: 967,
									},
									{
										id: 9942,
										name: "Gasaka  Court of Appeal ",
										generalAssembly_id: 967,
									},
									{
										id: 11475,
										name: "Gasaka  Gacaca court of sector ",
										generalAssembly_id: 967,
									},
								],
							},
							{
								id: 968,
								name: "Gikongoro ",
								sector_id: 10503,
								courtOfCells: [
									{
										id: 5548,
										name: "GIKONGORO",
										generalAssembly_id: 968,
									},
									{
										id: 5549,
										name: "GITANTU",
										generalAssembly_id: 968,
									},
									{
										id: 5550,
										name: "KABACUZI",
										generalAssembly_id: 968,
									},
									{
										id: 5551,
										name: "MULICO",
										generalAssembly_id: 968,
									},
									{
										id: 5552,
										name: "NYAMUGALI",
										generalAssembly_id: 968,
									},
									{
										id: 5553,
										name: "NZEGA_KADOMA",
										generalAssembly_id: 968,
									},
									{
										id: 9943,
										name: "Gikongoro  Court of Appeal ",
										generalAssembly_id: 968,
									},
									{
										id: 11476,
										name: "Gikongoro  Gacaca court of sector ",
										generalAssembly_id: 968,
									},
								],
							},
							{
								id: 969,
								name: "Ngiryi ",
								sector_id: 10503,
								courtOfCells: [
									{
										id: 5554,
										name: "GASHARU",
										generalAssembly_id: 969,
									},
									{
										id: 5555,
										name: "KARAMBI",
										generalAssembly_id: 969,
									},
									{
										id: 5556,
										name: "KIBANDA",
										generalAssembly_id: 969,
									},
									{
										id: 5557,
										name: "KITAZIGURWA",
										generalAssembly_id: 969,
									},
									{
										id: 5558,
										name: "NGIRYI",
										generalAssembly_id: 969,
									},
									{
										id: 5559,
										name: "NYABIVUMU",
										generalAssembly_id: 969,
									},
									{
										id: 5560,
										name: "RARO",
										generalAssembly_id: 969,
									},
									{
										id: 5561,
										name: "SUMBA",
										generalAssembly_id: 969,
									},
									{
										id: 9944,
										name: "Ngiryi  Court of Appeal ",
										generalAssembly_id: 969,
									},
									{
										id: 11477,
										name: "Ngiryi  Gacaca court of sector ",
										generalAssembly_id: 969,
									},
								],
							},
							{
								id: 970,
								name: "Remera ",
								sector_id: 10503,
								courtOfCells: [
									{
										id: 5562,
										name: "GITWA",
										generalAssembly_id: 970,
									},
									{
										id: 5563,
										name: "MURAMBI",
										generalAssembly_id: 970,
									},
									{
										id: 5564,
										name: "MURIRO",
										generalAssembly_id: 970,
									},
									{
										id: 5565,
										name: "NYAMIFUMBA",
										generalAssembly_id: 970,
									},
									{
										id: 9945,
										name: "Remera  Court of Appeal ",
										generalAssembly_id: 970,
									},
									{
										id: 11478,
										name: "Remera  Gacaca court of sector ",
										generalAssembly_id: 970,
									},
								],
							},
						],
					},
					{
						id: 10504,
						name: "Gatare",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050405,
								name: "Shyeru",
								sector_id: 10504,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050404,
								name: "Ruganda",
								sector_id: 10504,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050403,
								name: "Mukongoro",
								sector_id: 10504,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050402,
								name: "Gatare",
								sector_id: 10504,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050401,
								name: "Bakopfu",
								sector_id: 10504,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 971,
								name: "Gikungu ",
								sector_id: 10504,
								courtOfCells: [
									{
										id: 5566,
										name: "BITABA",
										generalAssembly_id: 971,
									},
									{
										id: 5567,
										name: "GATARE",
										generalAssembly_id: 971,
									},
									{
										id: 5568,
										name: "GIKUNGU",
										generalAssembly_id: 971,
									},
									{
										id: 5569,
										name: "KAGEYO",
										generalAssembly_id: 971,
									},
									{
										id: 5570,
										name: "KARUMBI",
										generalAssembly_id: 971,
									},
									{
										id: 5571,
										name: "RUKORE",
										generalAssembly_id: 971,
									},
									{
										id: 5572,
										name: "RWAMAKARA",
										generalAssembly_id: 971,
									},
									{
										id: 5573,
										name: "UWISURI",
										generalAssembly_id: 971,
									},
									{
										id: 9946,
										name: "Gikungu  Court of Appeal ",
										generalAssembly_id: 971,
									},
									{
										id: 11479,
										name: "Gikungu  Gacaca court of sector ",
										generalAssembly_id: 971,
									},
								],
							},
							{
								id: 972,
								name: "Murambi ",
								sector_id: 10504,
								courtOfCells: [
									{
										id: 5574,
										name: "GAKOMA",
										generalAssembly_id: 972,
									},
									{
										id: 5575,
										name: "GIHUMO",
										generalAssembly_id: 972,
									},
									{
										id: 5576,
										name: "KAGEYO",
										generalAssembly_id: 972,
									},
									{
										id: 5577,
										name: "MURAMBI",
										generalAssembly_id: 972,
									},
									{
										id: 5578,
										name: "MUYANGE",
										generalAssembly_id: 972,
									},
									{
										id: 5579,
										name: "NYABYUMBA",
										generalAssembly_id: 972,
									},
									{
										id: 9947,
										name: "Murambi  Court of Appeal ",
										generalAssembly_id: 972,
									},
									{
										id: 11480,
										name: "Murambi  Gacaca court of sector ",
										generalAssembly_id: 972,
									},
								],
							},
							{
								id: 973,
								name: "Nyarwungo ",
								sector_id: 10504,
								courtOfCells: [
									{
										id: 5580,
										name: "GAKOMEYE",
										generalAssembly_id: 973,
									},
									{
										id: 5581,
										name: "GITAKE",
										generalAssembly_id: 973,
									},
									{
										id: 5582,
										name: "KIBONWA",
										generalAssembly_id: 973,
									},
									{
										id: 5583,
										name: "MWUFE",
										generalAssembly_id: 973,
									},
									{
										id: 5584,
										name: "NYABYURO",
										generalAssembly_id: 973,
									},
									{
										id: 5585,
										name: "NYARWUNGO",
										generalAssembly_id: 973,
									},
									{
										id: 5586,
										name: "RUBOBO",
										generalAssembly_id: 973,
									},
									{
										id: 9948,
										name: "Nyarwungo  Court of Appeal ",
										generalAssembly_id: 973,
									},
									{
										id: 11481,
										name: "Nyarwungo  Gacaca court of sector ",
										generalAssembly_id: 973,
									},
								],
							},
						],
					},
					{
						id: 10505,
						name: "Kaduha",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050505,
								name: "Nyamiyaga",
								sector_id: 10505,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050504,
								name: "Nyabisindu",
								sector_id: 10505,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050503,
								name: "Musenyi",
								sector_id: 10505,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050502,
								name: "Murambi",
								sector_id: 10505,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050501,
								name: "Kavumu",
								sector_id: 10505,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 974,
								name: "Gasiza ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5587,
										name: "BITARE",
										generalAssembly_id: 974,
									},
									{
										id: 5588,
										name: "CUKWE",
										generalAssembly_id: 974,
									},
									{
										id: 5589,
										name: "GASHARU",
										generalAssembly_id: 974,
									},
									{
										id: 5590,
										name: "GASHIRU",
										generalAssembly_id: 974,
									},
									{
										id: 5591,
										name: "NYAMABUYE",
										generalAssembly_id: 974,
									},
									{
										id: 5592,
										name: "RUKERI",
										generalAssembly_id: 974,
									},
									{
										id: 5593,
										name: "CYUGARO",
										generalAssembly_id: 974,
									},
									{
										id: 9949,
										name: "Gasiza  Court of Appeal ",
										generalAssembly_id: 974,
									},
									{
										id: 11482,
										name: "Gasiza  Gacaca court of sector ",
										generalAssembly_id: 974,
									},
								],
							},
							{
								id: 975,
								name: "Joma ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5594,
										name: "GITABAGE",
										generalAssembly_id: 975,
									},
									{
										id: 5595,
										name: "JOMA",
										generalAssembly_id: 975,
									},
									{
										id: 5596,
										name: "KIREHE",
										generalAssembly_id: 975,
									},
									{
										id: 5597,
										name: "NYABISINDU",
										generalAssembly_id: 975,
									},
									{
										id: 5598,
										name: "TUNANI",
										generalAssembly_id: 975,
									},
									{
										id: 9950,
										name: "Joma  Court of Appeal ",
										generalAssembly_id: 975,
									},
									{
										id: 11483,
										name: "Joma  Gacaca court of sector ",
										generalAssembly_id: 975,
									},
								],
							},
							{
								id: 976,
								name: "Kavumu ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5599,
										name: "KADUHA",
										generalAssembly_id: 976,
									},
									{
										id: 5600,
										name: "KAREHE",
										generalAssembly_id: 976,
									},
									{
										id: 5601,
										name: "KAVUMU",
										generalAssembly_id: 976,
									},
									{
										id: 5602,
										name: "NYABIKENKE",
										generalAssembly_id: 976,
									},
									{
										id: 5603,
										name: "NYAGANE",
										generalAssembly_id: 976,
									},
									{
										id: 5604,
										name: "RUNYINYA",
										generalAssembly_id: 976,
									},
									{
										id: 9951,
										name: "Kavumu  Court of Appeal ",
										generalAssembly_id: 976,
									},
									{
										id: 11484,
										name: "Kavumu  Gacaca court of sector ",
										generalAssembly_id: 976,
									},
								],
							},
							{
								id: 977,
								name: "Mugote ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5605,
										name: "BWERAMANA",
										generalAssembly_id: 977,
									},
									{
										id: 5606,
										name: "KIBIRARO",
										generalAssembly_id: 977,
									},
									{
										id: 5607,
										name: "MURAMBI",
										generalAssembly_id: 977,
									},
									{
										id: 5608,
										name: "NYARUTOVU",
										generalAssembly_id: 977,
									},
									{
										id: 5609,
										name: "RUGARAMA",
										generalAssembly_id: 977,
									},
									{
										id: 9952,
										name: "Mugote  Court of Appeal ",
										generalAssembly_id: 977,
									},
									{
										id: 11485,
										name: "Mugote  Gacaca court of sector ",
										generalAssembly_id: 977,
									},
								],
							},
							{
								id: 978,
								name: "Mukongoro ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5610,
										name: "KABAZIRO",
										generalAssembly_id: 978,
									},
									{
										id: 5611,
										name: "KANYEGENYEGE",
										generalAssembly_id: 978,
									},
									{
										id: 5612,
										name: "KASEMAZI_KINYANA SUD",
										generalAssembly_id: 978,
									},
									{
										id: 5613,
										name: "MUDUHA",
										generalAssembly_id: 978,
									},
									{
										id: 5614,
										name: "NYABISINDU",
										generalAssembly_id: 978,
									},
									{
										id: 9953,
										name: "Mukongoro  Court of Appeal ",
										generalAssembly_id: 978,
									},
									{
										id: 11486,
										name: "Mukongoro  Gacaca court of sector ",
										generalAssembly_id: 978,
									},
								],
							},
							{
								id: 979,
								name: "Musenyi ",
								sector_id: 10505,
								courtOfCells: [
									{
										id: 5615,
										name: "KIRWA",
										generalAssembly_id: 979,
									},
									{
										id: 5616,
										name: "MUNINI",
										generalAssembly_id: 979,
									},
									{
										id: 5617,
										name: "MUSENYI",
										generalAssembly_id: 979,
									},
									{
										id: 5618,
										name: "NYAKIRAMBI",
										generalAssembly_id: 979,
									},
									{
										id: 5619,
										name: "RUKUKUMBO",
										generalAssembly_id: 979,
									},
									{
										id: 5620,
										name: "SHYITO",
										generalAssembly_id: 979,
									},
									{
										id: 5621,
										name: "BURENGO",
										generalAssembly_id: 979,
									},
									{
										id: 5622,
										name: "NGANZO",
										generalAssembly_id: 979,
									},
									{
										id: 5623,
										name: "UWAMARUMBA",
										generalAssembly_id: 979,
									},
									{
										id: 9954,
										name: "Musenyi  Court of Appeal ",
										generalAssembly_id: 979,
									},
									{
										id: 11487,
										name: "Musenyi  Gacaca court of sector ",
										generalAssembly_id: 979,
									},
								],
							},
						],
					},
					{
						id: 10506,
						name: "Kamegeri",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050606,
								name: "Rususa",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050605,
								name: "Nyarusiza",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050604,
								name: "Kizi",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050603,
								name: "Kirehe",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050602,
								name: "Kamegeri",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050601,
								name: "Bwama",
								sector_id: 10506,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 980,
								name: "Kamegeri ",
								sector_id: 10506,
								courtOfCells: [
									{
										id: 5624,
										name: "GASHARU",
										generalAssembly_id: 980,
									},
									{
										id: 5625,
										name: "KAMEGERI_SOVU",
										generalAssembly_id: 980,
									},
									{
										id: 5626,
										name: "KIDAKAMA",
										generalAssembly_id: 980,
									},
									{
										id: 5627,
										name: "KIREHE",
										generalAssembly_id: 980,
									},
									{
										id: 5628,
										name: "RUGENDABARI",
										generalAssembly_id: 980,
									},
									{
										id: 9955,
										name: "Kamegeri  Court of Appeal ",
										generalAssembly_id: 980,
									},
									{
										id: 11488,
										name: "Kamegeri  Gacaca court of sector ",
										generalAssembly_id: 980,
									},
								],
							},
							{
								id: 981,
								name: "Kizi ",
								sector_id: 10506,
								courtOfCells: [
									{
										id: 5629,
										name: "BWAMA",
										generalAssembly_id: 981,
									},
									{
										id: 5630,
										name: "GASAVE",
										generalAssembly_id: 981,
									},
									{
										id: 5631,
										name: "KAGARAMA",
										generalAssembly_id: 981,
									},
									{
										id: 5632,
										name: "KIVUMU",
										generalAssembly_id: 981,
									},
									{
										id: 5633,
										name: "KIZI",
										generalAssembly_id: 981,
									},
									{
										id: 5634,
										name: "UWABAHIMA",
										generalAssembly_id: 981,
									},
									{
										id: 9956,
										name: "Kizi  Court of Appeal ",
										generalAssembly_id: 981,
									},
									{
										id: 11489,
										name: "Kizi  Gacaca court of sector ",
										generalAssembly_id: 981,
									},
								],
							},
							{
								id: 982,
								name: "Rususa ",
								sector_id: 10506,
								courtOfCells: [
									{
										id: 5635,
										name: "MUHEMBE",
										generalAssembly_id: 982,
									},
									{
										id: 5636,
										name: "MURAMBI",
										generalAssembly_id: 982,
									},
									{
										id: 5637,
										name: "NYARURE",
										generalAssembly_id: 982,
									},
									{
										id: 5638,
										name: "NYARUSANGE",
										generalAssembly_id: 982,
									},
									{
										id: 5639,
										name: "NYARUSIZA",
										generalAssembly_id: 982,
									},
									{
										id: 5640,
										name: "RUSUSA",
										generalAssembly_id: 982,
									},
									{
										id: 5641,
										name: "RUTUNA_NYARURE",
										generalAssembly_id: 982,
									},
									{
										id: 9957,
										name: "Rususa  Court of Appeal ",
										generalAssembly_id: 982,
									},
									{
										id: 11490,
										name: "Rususa  Gacaca court of sector ",
										generalAssembly_id: 982,
									},
								],
							},
						],
					},
					{
						id: 10507,
						name: "Kibirizi",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050706,
								name: "Uwindekezi",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050705,
								name: "Ruhunga",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050704,
								name: "Karambo",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050703,
								name: "Gashiha",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050702,
								name: "Bugarura",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050701,
								name: "Bugarama",
								sector_id: 10507,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 983,
								name: "Kibirizi  ",
								sector_id: 10507,
								courtOfCells: [
									{
										id: 5642,
										name: "CYAMASHYA",
										generalAssembly_id: 983,
									},
									{
										id: 5643,
										name: "GAKOMA",
										generalAssembly_id: 983,
									},
									{
										id: 5644,
										name: "GASHARU",
										generalAssembly_id: 983,
									},
									{
										id: 5645,
										name: "KARUMBI",
										generalAssembly_id: 983,
									},
									{
										id: 5646,
										name: "KIBIRIZI",
										generalAssembly_id: 983,
									},
									{
										id: 5647,
										name: "MUNOMBE",
										generalAssembly_id: 983,
									},
									{
										id: 5648,
										name: "NYAGISHUBI",
										generalAssembly_id: 983,
									},
									{
										id: 5649,
										name: "RUHUNGA",
										generalAssembly_id: 983,
									},
									{
										id: 9958,
										name: "Kibirizi   Court of Appeal ",
										generalAssembly_id: 983,
									},
									{
										id: 11491,
										name: "Kibirizi   Gacaca court of sector ",
										generalAssembly_id: 983,
									},
								],
							},
							{
								id: 984,
								name: "Muganza ",
								sector_id: 10507,
								courtOfCells: [
									{
										id: 5650,
										name: "NYABUBARE",
										generalAssembly_id: 984,
									},
									{
										id: 5651,
										name: "BUGARURA",
										generalAssembly_id: 984,
									},
									{
										id: 5652,
										name: "GASHIHA",
										generalAssembly_id: 984,
									},
									{
										id: 5653,
										name: "KIRWA",
										generalAssembly_id: 984,
									},
									{
										id: 5654,
										name: "MUDUHA",
										generalAssembly_id: 984,
									},
									{
										id: 5655,
										name: "MUGANZA",
										generalAssembly_id: 984,
									},
									{
										id: 5656,
										name: "NYAKIBYEYI",
										generalAssembly_id: 984,
									},
									{
										id: 9959,
										name: "Muganza  Court of Appeal ",
										generalAssembly_id: 984,
									},
									{
										id: 11492,
										name: "Muganza  Gacaca court of sector ",
										generalAssembly_id: 984,
									},
								],
							},
							{
								id: 985,
								name: "Bugarama ",
								sector_id: 10507,
								courtOfCells: [
									{
										id: 5657,
										name: "BITABA",
										generalAssembly_id: 985,
									},
									{
										id: 5658,
										name: "GATOVU",
										generalAssembly_id: 985,
									},
									{
										id: 5659,
										name: "GISENYI",
										generalAssembly_id: 985,
									},
									{
										id: 5660,
										name: "MUGOTE",
										generalAssembly_id: 985,
									},
									{
										id: 5661,
										name: "RURABWE",
										generalAssembly_id: 985,
									},
									{
										id: 5662,
										name: "RUSHARA",
										generalAssembly_id: 985,
									},
									{
										id: 5663,
										name: "RUSUZUMIRO",
										generalAssembly_id: 985,
									},
									{
										id: 5664,
										name: "UWAMATABA",
										generalAssembly_id: 985,
									},
									{
										id: 5665,
										name: "UWINDEKEZI",
										generalAssembly_id: 985,
									},
									{
										id: 9960,
										name: "Bugarama  Court of Appeal ",
										generalAssembly_id: 985,
									},
									{
										id: 11493,
										name: "Bugarama  Gacaca court of sector ",
										generalAssembly_id: 985,
									},
								],
							},
						],
					},
					{
						id: 10508,
						name: "Kibumbwe",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050804,
								name: "Nyakiza",
								sector_id: 10508,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050803,
								name: "Kibibi",
								sector_id: 10508,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050802,
								name: "Gakanka",
								sector_id: 10508,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050801,
								name: "Bwenda",
								sector_id: 10508,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 986,
								name: "Kibumbwe ",
								sector_id: 10508,
								courtOfCells: [
									{
										id: 5666,
										name: "CYINDIMIRO",
										generalAssembly_id: 986,
									},
									{
										id: 5667,
										name: "KADUHA",
										generalAssembly_id: 986,
									},
									{
										id: 5668,
										name: "KIBUMBWE",
										generalAssembly_id: 986,
									},
									{
										id: 5669,
										name: "KIRWA",
										generalAssembly_id: 986,
									},
									{
										id: 5670,
										name: "RUNYINYA",
										generalAssembly_id: 986,
									},
									{
										id: 5671,
										name: "UWIKAMIRO",
										generalAssembly_id: 986,
									},
									{
										id: 9961,
										name: "Kibumbwe  Court of Appeal ",
										generalAssembly_id: 986,
									},
									{
										id: 11494,
										name: "Kibumbwe  Gacaca court of sector ",
										generalAssembly_id: 986,
									},
								],
							},
							{
								id: 987,
								name: "Kiraro ",
								sector_id: 10508,
								courtOfCells: [
									{
										id: 5672,
										name: "BUZIMBA",
										generalAssembly_id: 987,
									},
									{
										id: 5673,
										name: "KAMUYUMBO",
										generalAssembly_id: 987,
									},
									{
										id: 5674,
										name: "KARAMBO",
										generalAssembly_id: 987,
									},
									{
										id: 5675,
										name: "MUREMURE",
										generalAssembly_id: 987,
									},
									{
										id: 5676,
										name: "MUYANGE",
										generalAssembly_id: 987,
									},
									{
										id: 5677,
										name: "NYAMIRAMA",
										generalAssembly_id: 987,
									},
									{
										id: 5678,
										name: "UWINYANA",
										generalAssembly_id: 987,
									},
									{
										id: 9962,
										name: "Kiraro  Court of Appeal ",
										generalAssembly_id: 987,
									},
									{
										id: 11495,
										name: "Kiraro  Gacaca court of sector ",
										generalAssembly_id: 987,
									},
								],
							},
							{
								id: 988,
								name: "Nyakiza ",
								sector_id: 10508,
								courtOfCells: [
									{
										id: 5679,
										name: "GAHARO",
										generalAssembly_id: 988,
									},
									{
										id: 5680,
										name: "KARAMBO",
										generalAssembly_id: 988,
									},
									{
										id: 5681,
										name: "KINYANA_NORD",
										generalAssembly_id: 988,
									},
									{
										id: 5682,
										name: "MURAMBI",
										generalAssembly_id: 988,
									},
									{
										id: 5683,
										name: "NYAKIZU",
										generalAssembly_id: 988,
									},
									{
										id: 5684,
										name: "ZIGATI",
										generalAssembly_id: 988,
									},
									{
										id: 9963,
										name: "Nyakiza  Court of Appeal ",
										generalAssembly_id: 988,
									},
									{
										id: 11496,
										name: "Nyakiza  Gacaca court of sector ",
										generalAssembly_id: 988,
									},
								],
							},
							{
								id: 989,
								name: "Rugarama ",
								sector_id: 10508,
								courtOfCells: [
									{
										id: 5685,
										name: "BUNYOMA",
										generalAssembly_id: 989,
									},
									{
										id: 5686,
										name: "BWENDA",
										generalAssembly_id: 989,
									},
									{
										id: 5687,
										name: "KAMURWA",
										generalAssembly_id: 989,
									},
									{
										id: 5688,
										name: "NYAMIRUNDE",
										generalAssembly_id: 989,
									},
									{
										id: 5689,
										name: "RUGARAMA",
										generalAssembly_id: 989,
									},
									{
										id: 5690,
										name: "UWINANKA",
										generalAssembly_id: 989,
									},
									{
										id: 9964,
										name: "Rugarama  Court of Appeal ",
										generalAssembly_id: 989,
									},
									{
										id: 11497,
										name: "Rugarama  Gacaca court of sector ",
										generalAssembly_id: 989,
									},
								],
							},
							{
								id: 990,
								name: "Nkurubuye ",
								sector_id: 10508,
								courtOfCells: [
									{
										id: 5691,
										name: "BYINTARE",
										generalAssembly_id: 990,
									},
									{
										id: 5692,
										name: "NKURUBUYE",
										generalAssembly_id: 990,
									},
									{
										id: 5693,
										name: "NYABIKENKE",
										generalAssembly_id: 990,
									},
									{
										id: 5694,
										name: "NYARUBUYE",
										generalAssembly_id: 990,
									},
									{
										id: 5695,
										name: "NYARUNYINYA",
										generalAssembly_id: 990,
									},
									{
										id: 9965,
										name: "Nkurubuye  Court of Appeal ",
										generalAssembly_id: 990,
									},
									{
										id: 11498,
										name: "Nkurubuye  Gacaca court of sector ",
										generalAssembly_id: 990,
									},
								],
							},
						],
					},
					{
						id: 10509,
						name: "Kitabi",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1050905,
								name: "Uwingugu",
								sector_id: 10509,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050904,
								name: "Shaba",
								sector_id: 10509,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050903,
								name: "Mukungu",
								sector_id: 10509,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050902,
								name: "Mujuga",
								sector_id: 10509,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1050901,
								name: "Kagano",
								sector_id: 10509,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 991,
								name: "Kitabi ",
								sector_id: 10509,
								courtOfCells: [
									{
										id: 5696,
										name: "KAGANO",
										generalAssembly_id: 991,
									},
									{
										id: 5697,
										name: "KINTOBO",
										generalAssembly_id: 991,
									},
									{
										id: 5698,
										name: "TURONZI",
										generalAssembly_id: 991,
									},
									{
										id: 5699,
										name: "UWABUMENYI",
										generalAssembly_id: 991,
									},
									{
										id: 5700,
										name: "UWARWUBATSI",
										generalAssembly_id: 991,
									},
									{
										id: 9966,
										name: "Kitabi  Court of Appeal ",
										generalAssembly_id: 991,
									},
									{
										id: 11499,
										name: "Kitabi  Gacaca court of sector ",
										generalAssembly_id: 991,
									},
								],
							},
							{
								id: 992,
								name: "Mujuga ",
								sector_id: 10509,
								courtOfCells: [
									{
										id: 5701,
										name: "GAHANDE",
										generalAssembly_id: 992,
									},
									{
										id: 5702,
										name: "GASASA",
										generalAssembly_id: 992,
									},
									{
										id: 5703,
										name: "MUJUGA",
										generalAssembly_id: 992,
									},
									{
										id: 5704,
										name: "MUKAKA",
										generalAssembly_id: 992,
									},
									{
										id: 5705,
										name: "RWUFE",
										generalAssembly_id: 992,
									},
									{
										id: 5706,
										name: "UWANYAKANYERI",
										generalAssembly_id: 992,
									},
									{
										id: 5707,
										name: "UWINKA",
										generalAssembly_id: 992,
									},
									{
										id: 9967,
										name: "Mujuga  Court of Appeal ",
										generalAssembly_id: 992,
									},
									{
										id: 11500,
										name: "Mujuga  Gacaca court of sector ",
										generalAssembly_id: 992,
									},
								],
							},
							{
								id: 993,
								name: "Mukungu ",
								sector_id: 10509,
								courtOfCells: [
									{
										id: 5708,
										name: "GAHIRA",
										generalAssembly_id: 993,
									},
									{
										id: 5709,
										name: "GATARE",
										generalAssembly_id: 993,
									},
									{
										id: 5710,
										name: "KARAMBI",
										generalAssembly_id: 993,
									},
									{
										id: 5711,
										name: "UWICURANGIRO",
										generalAssembly_id: 993,
									},
									{
										id: 5712,
										name: "UWINTYABIRE",
										generalAssembly_id: 993,
									},
									{
										id: 5713,
										name: "UWURUNAZI",
										generalAssembly_id: 993,
									},
									{
										id: 9968,
										name: "Mukungu  Court of Appeal ",
										generalAssembly_id: 993,
									},
									{
										id: 11501,
										name: "Mukungu  Gacaca court of sector ",
										generalAssembly_id: 993,
									},
								],
							},
							{
								id: 994,
								name: "Uwingugu ",
								sector_id: 10509,
								courtOfCells: [
									{
										id: 5714,
										name: "AKAMANA",
										generalAssembly_id: 994,
									},
									{
										id: 5715,
										name: "GISARENDA",
										generalAssembly_id: 994,
									},
									{
										id: 5716,
										name: "GISUNZU",
										generalAssembly_id: 994,
									},
									{
										id: 5717,
										name: "KIGALI",
										generalAssembly_id: 994,
									},
									{
										id: 5718,
										name: "RUBUYE",
										generalAssembly_id: 994,
									},
									{
										id: 5719,
										name: "RUHANGA",
										generalAssembly_id: 994,
									},
									{
										id: 5720,
										name: "UWIMISIGATI",
										generalAssembly_id: 994,
									},
									{
										id: 5721,
										name: "UWURUNAZI",
										generalAssembly_id: 994,
									},
									{
										id: 9969,
										name: "Uwingugu  Court of Appeal ",
										generalAssembly_id: 994,
									},
									{
										id: 11502,
										name: "Uwingugu  Gacaca court of sector ",
										generalAssembly_id: 994,
									},
								],
							},
							{
								id: 995,
								name: "Shaba  ",
								sector_id: 10509,
								courtOfCells: [
									{
										id: 5722,
										name: "BITABA",
										generalAssembly_id: 995,
									},
									{
										id: 5723,
										name: "GAKOKO",
										generalAssembly_id: 995,
									},
									{
										id: 5724,
										name: "KUMUGANZA",
										generalAssembly_id: 995,
									},
									{
										id: 5725,
										name: "SHABA",
										generalAssembly_id: 995,
									},
									{
										id: 5726,
										name: "UWABISONGA",
										generalAssembly_id: 995,
									},
									{
										id: 5727,
										name: "UWINKA",
										generalAssembly_id: 995,
									},
									{
										id: 9970,
										name: "Shaba   Court of Appeal ",
										generalAssembly_id: 995,
									},
									{
										id: 11503,
										name: "Shaba   Gacaca court of sector ",
										generalAssembly_id: 995,
									},
								],
							},
						],
					},
					{
						id: 10510,
						name: "Mbazi",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051004,
								name: "Ngara",
								sector_id: 10510,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051003,
								name: "Ngambi",
								sector_id: 10510,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051002,
								name: "Mutiwingoma",
								sector_id: 10510,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051001,
								name: "Manwari",
								sector_id: 10510,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 996,
								name: "Mbazi ",
								sector_id: 10510,
								courtOfCells: [
									{
										id: 5728,
										name: "GASHARU",
										generalAssembly_id: 996,
									},
									{
										id: 5729,
										name: "KIGARAMA",
										generalAssembly_id: 996,
									},
									{
										id: 5730,
										name: "KIVOMO",
										generalAssembly_id: 996,
									},
									{
										id: 5731,
										name: "MUHORORO",
										generalAssembly_id: 996,
									},
									{
										id: 5732,
										name: "MUNANIRA",
										generalAssembly_id: 996,
									},
									{
										id: 5733,
										name: "NGAMBI",
										generalAssembly_id: 996,
									},
									{
										id: 9971,
										name: "Mbazi  Court of Appeal ",
										generalAssembly_id: 996,
									},
									{
										id: 11504,
										name: "Mbazi  Gacaca court of sector ",
										generalAssembly_id: 996,
									},
								],
							},
							{
								id: 997,
								name: "Gikoni  ",
								sector_id: 10510,
								courtOfCells: [
									{
										id: 5734,
										name: "GATWA",
										generalAssembly_id: 997,
									},
									{
										id: 5735,
										name: "KABERE",
										generalAssembly_id: 997,
									},
									{
										id: 5736,
										name: "KAMENAMUTWE",
										generalAssembly_id: 997,
									},
									{
										id: 5737,
										name: "KIBUMBA",
										generalAssembly_id: 997,
									},
									{
										id: 5738,
										name: "MUDUHA",
										generalAssembly_id: 997,
									},
									{
										id: 5739,
										name: "NYABIHEKE",
										generalAssembly_id: 997,
									},
									{
										id: 5740,
										name: "NYAMIRAMA",
										generalAssembly_id: 997,
									},
									{
										id: 9972,
										name: "Gikoni   Court of Appeal ",
										generalAssembly_id: 997,
									},
									{
										id: 11505,
										name: "Gikoni   Gacaca court of sector ",
										generalAssembly_id: 997,
									},
								],
							},
							{
								id: 998,
								name: "Ngara ",
								sector_id: 10510,
								courtOfCells: [
									{
										id: 5741,
										name: "BUTARE",
										generalAssembly_id: 998,
									},
									{
										id: 5742,
										name: "GASEKE",
										generalAssembly_id: 998,
									},
									{
										id: 5743,
										name: "GASHARU",
										generalAssembly_id: 998,
									},
									{
										id: 5744,
										name: "GISIZA",
										generalAssembly_id: 998,
									},
									{
										id: 5745,
										name: "GITUNTU",
										generalAssembly_id: 998,
									},
									{
										id: 5746,
										name: "NYAGISHUMBU",
										generalAssembly_id: 998,
									},
									{
										id: 5747,
										name: "RUSEKE",
										generalAssembly_id: 998,
									},
									{
										id: 9973,
										name: "Ngara  Court of Appeal ",
										generalAssembly_id: 998,
									},
									{
										id: 11506,
										name: "Ngara  Gacaca court of sector ",
										generalAssembly_id: 998,
									},
								],
							},
						],
					},
					{
						id: 10511,
						name: "Mugano",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051106,
								name: "Yonde",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051105,
								name: "Suti",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051104,
								name: "Sovu",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051103,
								name: "Ruhinga",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051102,
								name: "Gitwa",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051101,
								name: "Gitondorero",
								sector_id: 10511,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 999,
								name: "Cyabute ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5748,
										name: "CYABUTE_I",
										generalAssembly_id: 999,
									},
									{
										id: 5749,
										name: "CYABUTE_II",
										generalAssembly_id: 999,
									},
									{
										id: 5750,
										name: "KARAMBI",
										generalAssembly_id: 999,
									},
									{
										id: 5751,
										name: "MATYAZO",
										generalAssembly_id: 999,
									},
									{
										id: 5752,
										name: "TURYANGO",
										generalAssembly_id: 999,
									},
									{
										id: 9974,
										name: "Cyabute  Court of Appeal ",
										generalAssembly_id: 999,
									},
									{
										id: 11507,
										name: "Cyabute  Gacaca court of sector ",
										generalAssembly_id: 999,
									},
								],
							},
							{
								id: 1000,
								name: "Gitondorero ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5753,
										name: "GITONDORERO",
										generalAssembly_id: 1000,
									},
									{
										id: 5754,
										name: "GITUNTU",
										generalAssembly_id: 1000,
									},
									{
										id: 5755,
										name: "KARAMBI",
										generalAssembly_id: 1000,
									},
									{
										id: 5756,
										name: "MASO",
										generalAssembly_id: 1000,
									},
									{
										id: 5757,
										name: "MUSEBEYA",
										generalAssembly_id: 1000,
									},
									{
										id: 5758,
										name: "RUHABWA",
										generalAssembly_id: 1000,
									},
									{
										id: 5759,
										name: "RUSAVE",
										generalAssembly_id: 1000,
									},
									{
										id: 9975,
										name: "Gitondorero  Court of Appeal ",
										generalAssembly_id: 1000,
									},
									{
										id: 11508,
										name: "Gitondorero  Gacaca court of sector ",
										generalAssembly_id: 1000,
									},
								],
							},
							{
								id: 1001,
								name: "Sovu ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5760,
										name: "GAKURWE",
										generalAssembly_id: 1001,
									},
									{
										id: 5761,
										name: "KABAKARAZA_NYABURONDWE",
										generalAssembly_id: 1001,
									},
									{
										id: 5762,
										name: "KIBUYE",
										generalAssembly_id: 1001,
									},
									{
										id: 5763,
										name: "MUBUGA",
										generalAssembly_id: 1001,
									},
									{
										id: 5764,
										name: "RUGARAMA",
										generalAssembly_id: 1001,
									},
									{
										id: 5765,
										name: "RUHANGA",
										generalAssembly_id: 1001,
									},
									{
										id: 5766,
										name: "SOVU",
										generalAssembly_id: 1001,
									},
									{
										id: 9976,
										name: "Sovu  Court of Appeal ",
										generalAssembly_id: 1001,
									},
									{
										id: 11509,
										name: "Sovu  Gacaca court of sector ",
										generalAssembly_id: 1001,
									},
								],
							},
							{
								id: 1002,
								name: "Yonde ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5767,
										name: "GISOVU",
										generalAssembly_id: 1002,
									},
									{
										id: 5768,
										name: "KANYEGENYEGE",
										generalAssembly_id: 1002,
									},
									{
										id: 5769,
										name: "KIBINGO",
										generalAssembly_id: 1002,
									},
									{
										id: 5770,
										name: "NYARUGOTE",
										generalAssembly_id: 1002,
									},
									{
										id: 5771,
										name: "NYARUSAZI",
										generalAssembly_id: 1002,
									},
									{
										id: 5772,
										name: "YONDE_A",
										generalAssembly_id: 1002,
									},
									{
										id: 5773,
										name: "YONDE_B",
										generalAssembly_id: 1002,
									},
									{
										id: 9977,
										name: "Yonde  Court of Appeal ",
										generalAssembly_id: 1002,
									},
									{
										id: 11510,
										name: "Yonde  Gacaca court of sector ",
										generalAssembly_id: 1002,
									},
								],
							},
							{
								id: 1003,
								name: "Ruhinga ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5774,
										name: "KABUYE",
										generalAssembly_id: 1003,
									},
									{
										id: 5775,
										name: "KARUTABANA",
										generalAssembly_id: 1003,
									},
									{
										id: 5776,
										name: "KINZIRA",
										generalAssembly_id: 1003,
									},
									{
										id: 5777,
										name: "MUGARAGARA",
										generalAssembly_id: 1003,
									},
									{
										id: 5778,
										name: "RUNYINYA",
										generalAssembly_id: 1003,
									},
									{
										id: 5779,
										name: "SUTI",
										generalAssembly_id: 1003,
									},
									{
										id: 9978,
										name: "Ruhinga  Court of Appeal ",
										generalAssembly_id: 1003,
									},
									{
										id: 11511,
										name: "Ruhinga  Gacaca court of sector ",
										generalAssembly_id: 1003,
									},
								],
							},
							{
								id: 1004,
								name: "Mwumba ",
								sector_id: 10511,
								courtOfCells: [
									{
										id: 5780,
										name: "KADUHA",
										generalAssembly_id: 1004,
									},
									{
										id: 5781,
										name: "KAVUMU",
										generalAssembly_id: 1004,
									},
									{
										id: 5782,
										name: "MWUMBA",
										generalAssembly_id: 1004,
									},
									{
										id: 5783,
										name: "NYAKIBINGO",
										generalAssembly_id: 1004,
									},
									{
										id: 5784,
										name: "NYAMURE",
										generalAssembly_id: 1004,
									},
									{
										id: 9979,
										name: "Mwumba  Court of Appeal ",
										generalAssembly_id: 1004,
									},
									{
										id: 11512,
										name: "Mwumba  Gacaca court of sector ",
										generalAssembly_id: 1004,
									},
								],
							},
						],
					},
					{
						id: 10512,
						name: "Musange",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051206,
								name: "Nyagisozi",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051205,
								name: "Masizi",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051204,
								name: "Masangano",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051203,
								name: "Masagara",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051202,
								name: "Jenda",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051201,
								name: "Gasave",
								sector_id: 10512,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1005,
								name: "Cyabasana ",
								sector_id: 10512,
								courtOfCells: [
									{
										id: 5785,
										name: "CYABASANA",
										generalAssembly_id: 1005,
									},
									{
										id: 5786,
										name: "CYARUVUNGE",
										generalAssembly_id: 1005,
									},
									{
										id: 5787,
										name: "KIGARAMA",
										generalAssembly_id: 1005,
									},
									{
										id: 5788,
										name: "MURAMBI",
										generalAssembly_id: 1005,
									},
									{
										id: 5789,
										name: "NYABIVUMU",
										generalAssembly_id: 1005,
									},
									{
										id: 5790,
										name: "NYAGIHIMA",
										generalAssembly_id: 1005,
									},
									{
										id: 5791,
										name: "NYAKABUYE",
										generalAssembly_id: 1005,
									},
									{
										id: 5792,
										name: "RUNYINYA",
										generalAssembly_id: 1005,
									},
									{
										id: 5793,
										name: "RWANKANGO",
										generalAssembly_id: 1005,
									},
									{
										id: 5794,
										name: "RWINA",
										generalAssembly_id: 1005,
									},
									{
										id: 9980,
										name: "Cyabasana  Court of Appeal ",
										generalAssembly_id: 1005,
									},
									{
										id: 11513,
										name: "Cyabasana  Gacaca court of sector ",
										generalAssembly_id: 1005,
									},
								],
							},
							{
								id: 1006,
								name: "Kibaga ",
								sector_id: 10512,
								courtOfCells: [
									{
										id: 5795,
										name: "DUSENYI",
										generalAssembly_id: 1006,
									},
									{
										id: 5796,
										name: "GASURA",
										generalAssembly_id: 1006,
									},
									{
										id: 5797,
										name: "KABINGO",
										generalAssembly_id: 1006,
									},
									{
										id: 5798,
										name: "KIBAGA",
										generalAssembly_id: 1006,
									},
									{
										id: 5799,
										name: "MUNYINYA",
										generalAssembly_id: 1006,
									},
									{
										id: 5800,
										name: "NYAGISOZI",
										generalAssembly_id: 1006,
									},
									{
										id: 5801,
										name: "REMERA",
										generalAssembly_id: 1006,
									},
									{
										id: 5802,
										name: "RUHUGA",
										generalAssembly_id: 1006,
									},
									{
										id: 5803,
										name: "UWABARASHI",
										generalAssembly_id: 1006,
									},
									{
										id: 9981,
										name: "Kibaga  Court of Appeal ",
										generalAssembly_id: 1006,
									},
									{
										id: 11514,
										name: "Kibaga  Gacaca court of sector ",
										generalAssembly_id: 1006,
									},
								],
							},
							{
								id: 1007,
								name: "Kigoma ",
								sector_id: 10512,
								courtOfCells: [
									{
										id: 5804,
										name: "CYABAGOMBA",
										generalAssembly_id: 1007,
									},
									{
										id: 5805,
										name: "GITUNTU",
										generalAssembly_id: 1007,
									},
									{
										id: 5806,
										name: "MUHORORO",
										generalAssembly_id: 1007,
									},
									{
										id: 5807,
										name: "MURAMBI",
										generalAssembly_id: 1007,
									},
									{
										id: 5808,
										name: "MUSANGE",
										generalAssembly_id: 1007,
									},
									{
										id: 5809,
										name: "MUTAKARA",
										generalAssembly_id: 1007,
									},
									{
										id: 5810,
										name: "MUTUNTU",
										generalAssembly_id: 1007,
									},
									{
										id: 9982,
										name: "Kigoma  Court of Appeal ",
										generalAssembly_id: 1007,
									},
									{
										id: 11515,
										name: "Kigoma  Gacaca court of sector ",
										generalAssembly_id: 1007,
									},
								],
							},
							{
								id: 1008,
								name: "Jenda ",
								sector_id: 10512,
								courtOfCells: [
									{
										id: 5811,
										name: "JENDA",
										generalAssembly_id: 1008,
									},
									{
										id: 5812,
										name: "KABAKANNYI",
										generalAssembly_id: 1008,
									},
									{
										id: 5813,
										name: "KABIRIZI",
										generalAssembly_id: 1008,
									},
									{
										id: 5814,
										name: "MUNINI",
										generalAssembly_id: 1008,
									},
									{
										id: 5815,
										name: "NYAMUGARI",
										generalAssembly_id: 1008,
									},
									{
										id: 5816,
										name: "NYARUZI",
										generalAssembly_id: 1008,
									},
									{
										id: 9983,
										name: "Jenda  Court of Appeal ",
										generalAssembly_id: 1008,
									},
									{
										id: 11516,
										name: "Jenda  Gacaca court of sector ",
										generalAssembly_id: 1008,
									},
								],
							},
							{
								id: 1009,
								name: "Nkore ",
								sector_id: 10512,
								courtOfCells: [
									{
										id: 5817,
										name: "KIBUMBA",
										generalAssembly_id: 1009,
									},
									{
										id: 5818,
										name: "KAMWARO",
										generalAssembly_id: 1009,
									},
									{
										id: 5819,
										name: "MASANGANO",
										generalAssembly_id: 1009,
									},
									{
										id: 5820,
										name: "MUBUGA",
										generalAssembly_id: 1009,
									},
									{
										id: 5821,
										name: "NYAKABUYE",
										generalAssembly_id: 1009,
									},
									{
										id: 5822,
										name: "RUTUNTU",
										generalAssembly_id: 1009,
									},
									{
										id: 5823,
										name: "NYAKIRAMBI",
										generalAssembly_id: 1009,
									},
									{
										id: 5824,
										name: "MUREHE",
										generalAssembly_id: 1009,
									},
									{
										id: 9984,
										name: "Nkore  Court of Appeal ",
										generalAssembly_id: 1009,
									},
									{
										id: 11517,
										name: "Nkore  Gacaca court of sector ",
										generalAssembly_id: 1009,
									},
								],
							},
						],
					},
					{
						id: 10513,
						name: "Musebeya",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051306,
								name: "Sekera",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051305,
								name: "Rusekera",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051304,
								name: "Runege",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051303,
								name: "Rugano",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051302,
								name: "Nyarurambi",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051301,
								name: "Gatovu",
								sector_id: 10513,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1010,
								name: "Rugazi  ",
								sector_id: 10513,
								courtOfCells: [
									{
										id: 5825,
										name: "BIGUGU",
										generalAssembly_id: 1010,
									},
									{
										id: 5826,
										name: "MASINDE",
										generalAssembly_id: 1010,
									},
									{
										id: 5827,
										name: "MUGANO",
										generalAssembly_id: 1010,
									},
									{
										id: 5828,
										name: "NKOMERO",
										generalAssembly_id: 1010,
									},
									{
										id: 5829,
										name: "RUBUMBURI",
										generalAssembly_id: 1010,
									},
									{
										id: 5830,
										name: "RUKARANKA",
										generalAssembly_id: 1010,
									},
									{
										id: 9985,
										name: "Rugazi   Court of Appeal ",
										generalAssembly_id: 1010,
									},
									{
										id: 11518,
										name: "Rugazi   Gacaca court of sector ",
										generalAssembly_id: 1010,
									},
								],
							},
							{
								id: 1011,
								name: "Gatovu ",
								sector_id: 10513,
								courtOfCells: [
									{
										id: 5831,
										name: "BISEREGANYA",
										generalAssembly_id: 1011,
									},
									{
										id: 5832,
										name: "BITABA",
										generalAssembly_id: 1011,
									},
									{
										id: 5833,
										name: "GACUNDURA",
										generalAssembly_id: 1011,
									},
									{
										id: 5834,
										name: "GAKEREKO",
										generalAssembly_id: 1011,
									},
									{
										id: 5835,
										name: "GATOVU",
										generalAssembly_id: 1011,
									},
									{
										id: 5836,
										name: "GITOVU",
										generalAssembly_id: 1011,
									},
									{
										id: 5837,
										name: "NYARUHURA",
										generalAssembly_id: 1011,
									},
									{
										id: 9986,
										name: "Gatovu  Court of Appeal ",
										generalAssembly_id: 1011,
									},
									{
										id: 11519,
										name: "Gatovu  Gacaca court of sector ",
										generalAssembly_id: 1011,
									},
								],
							},
							{
								id: 1012,
								name: "Rugano ",
								sector_id: 10513,
								courtOfCells: [
									{
										id: 5838,
										name: "BUGARAMA",
										generalAssembly_id: 1012,
									},
									{
										id: 5839,
										name: "CYABWIMBA",
										generalAssembly_id: 1012,
									},
									{
										id: 5840,
										name: "GATITI",
										generalAssembly_id: 1012,
									},
									{
										id: 5841,
										name: "GIHETA",
										generalAssembly_id: 1012,
									},
									{
										id: 5842,
										name: "MUJYEJURU",
										generalAssembly_id: 1012,
									},
									{
										id: 5843,
										name: "NGOMA",
										generalAssembly_id: 1012,
									},
									{
										id: 5844,
										name: "RUGANO",
										generalAssembly_id: 1012,
									},
									{
										id: 9987,
										name: "Rugano  Court of Appeal ",
										generalAssembly_id: 1012,
									},
									{
										id: 11520,
										name: "Rugano  Gacaca court of sector ",
										generalAssembly_id: 1012,
									},
								],
							},
							{
								id: 1013,
								name: "Rusekera ",
								sector_id: 10513,
								courtOfCells: [
									{
										id: 5845,
										name: "BUSANZA",
										generalAssembly_id: 1013,
									},
									{
										id: 5846,
										name: "KIBANDIRWA",
										generalAssembly_id: 1013,
									},
									{
										id: 5847,
										name: "RUKUNGU",
										generalAssembly_id: 1013,
									},
									{
										id: 5848,
										name: "RUSEKERA",
										generalAssembly_id: 1013,
									},
									{
										id: 5849,
										name: "SHAKI",
										generalAssembly_id: 1013,
									},
									{
										id: 5850,
										name: "TWUMBA",
										generalAssembly_id: 1013,
									},
									{
										id: 5851,
										name: "UWIMITUZA",
										generalAssembly_id: 1013,
									},
									{
										id: 9988,
										name: "Rusekera  Court of Appeal ",
										generalAssembly_id: 1013,
									},
									{
										id: 11521,
										name: "Rusekera  Gacaca court of sector ",
										generalAssembly_id: 1013,
									},
								],
							},
						],
					},
					{
						id: 10514,
						name: "Mushubi",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051403,
								name: "Gashwati",
								sector_id: 10514,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051402,
								name: "Cyobe",
								sector_id: 10514,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051401,
								name: "Buteteri",
								sector_id: 10514,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1014,
								name: "Cyobe ",
								sector_id: 10514,
								courtOfCells: [
									{
										id: 5852,
										name: "CYOBE",
										generalAssembly_id: 1014,
									},
									{
										id: 5853,
										name: "GASEKE",
										generalAssembly_id: 1014,
									},
									{
										id: 5854,
										name: "GITIKIREMA",
										generalAssembly_id: 1014,
									},
									{
										id: 5855,
										name: "NYAGISUMO",
										generalAssembly_id: 1014,
									},
									{
										id: 5856,
										name: "NYAKABINGO",
										generalAssembly_id: 1014,
									},
									{
										id: 5857,
										name: "NYAKIRAMBI",
										generalAssembly_id: 1014,
									},
									{
										id: 5858,
										name: "NYARUSHIKE",
										generalAssembly_id: 1014,
									},
									{
										id: 5859,
										name: "RUTOYI",
										generalAssembly_id: 1014,
									},
									{
										id: 9989,
										name: "Cyobe  Court of Appeal ",
										generalAssembly_id: 1014,
									},
									{
										id: 11522,
										name: "Cyobe  Gacaca court of sector ",
										generalAssembly_id: 1014,
									},
								],
							},
							{
								id: 1015,
								name: "Jimbu ",
								sector_id: 10514,
								courtOfCells: [
									{
										id: 5860,
										name: "BUTETERI",
										generalAssembly_id: 1015,
									},
									{
										id: 5861,
										name: "GORWE",
										generalAssembly_id: 1015,
									},
									{
										id: 5862,
										name: "JIMBU",
										generalAssembly_id: 1015,
									},
									{
										id: 5863,
										name: "MUGUNDA",
										generalAssembly_id: 1015,
									},
									{
										id: 5864,
										name: "MURAMBI",
										generalAssembly_id: 1015,
									},
									{
										id: 5865,
										name: "NYAKIBANDE_A",
										generalAssembly_id: 1015,
									},
									{
										id: 5866,
										name: "NYAKIBANDE_B",
										generalAssembly_id: 1015,
									},
									{
										id: 5867,
										name: "REMERA",
										generalAssembly_id: 1015,
									},
									{
										id: 5868,
										name: "RUSOYO",
										generalAssembly_id: 1015,
									},
									{
										id: 9990,
										name: "Jimbu  Court of Appeal ",
										generalAssembly_id: 1015,
									},
									{
										id: 11523,
										name: "Jimbu  Gacaca court of sector ",
										generalAssembly_id: 1015,
									},
								],
							},
							{
								id: 1016,
								name: "Muko ",
								sector_id: 10514,
								courtOfCells: [
									{
										id: 5869,
										name: "GITUZA",
										generalAssembly_id: 1016,
									},
									{
										id: 5870,
										name: "MUHEMBE",
										generalAssembly_id: 1016,
									},
									{
										id: 5871,
										name: "MUKO",
										generalAssembly_id: 1016,
									},
									{
										id: 5872,
										name: "MUSHUBI",
										generalAssembly_id: 1016,
									},
									{
										id: 5873,
										name: "NYARURUMBI",
										generalAssembly_id: 1016,
									},
									{
										id: 5874,
										name: "RUCUNDA",
										generalAssembly_id: 1016,
									},
									{
										id: 5875,
										name: "RUSOYO",
										generalAssembly_id: 1016,
									},
									{
										id: 9991,
										name: "Muko  Court of Appeal ",
										generalAssembly_id: 1016,
									},
									{
										id: 11524,
										name: "Muko  Gacaca court of sector ",
										generalAssembly_id: 1016,
									},
								],
							},
						],
					},
					{
						id: 10515,
						name: "Nkomane",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051506,
								name: "Twiya",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051505,
								name: "Nyarwungo",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051504,
								name: "Nkomane",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051503,
								name: "Mutengeri",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051502,
								name: "Musaraba",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051501,
								name: "Bitandara",
								sector_id: 10515,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1017,
								name: "Bitandara ",
								sector_id: 10515,
								courtOfCells: [
									{
										id: 5876,
										name: "BANDA",
										generalAssembly_id: 1017,
									},
									{
										id: 5877,
										name: "BITANDARA",
										generalAssembly_id: 1017,
									},
									{
										id: 5878,
										name: "BUHANZI",
										generalAssembly_id: 1017,
									},
									{
										id: 5879,
										name: "KAGANO",
										generalAssembly_id: 1017,
									},
									{
										id: 5880,
										name: "MUGARI",
										generalAssembly_id: 1017,
									},
									{
										id: 5881,
										name: "MUNANIRA",
										generalAssembly_id: 1017,
									},
									{
										id: 5882,
										name: "MUTARAMA",
										generalAssembly_id: 1017,
									},
									{
										id: 5883,
										name: "MUYANGE",
										generalAssembly_id: 1017,
									},
									{
										id: 5884,
										name: "RUGEYO",
										generalAssembly_id: 1017,
									},
									{
										id: 9992,
										name: "Bitandara  Court of Appeal ",
										generalAssembly_id: 1017,
									},
									{
										id: 11525,
										name: "Bitandara  Gacaca court of sector ",
										generalAssembly_id: 1017,
									},
								],
							},
							{
								id: 1018,
								name: "Kabavu ",
								sector_id: 10515,
								courtOfCells: [
									{
										id: 5885,
										name: "CYURWUFE",
										generalAssembly_id: 1018,
									},
									{
										id: 5886,
										name: "GAKOMEYE",
										generalAssembly_id: 1018,
									},
									{
										id: 5887,
										name: "GISHENGE",
										generalAssembly_id: 1018,
									},
									{
										id: 5888,
										name: "KARUKOMA",
										generalAssembly_id: 1018,
									},
									{
										id: 5889,
										name: "KIBUGA",
										generalAssembly_id: 1018,
									},
									{
										id: 5890,
										name: "KIVUMU",
										generalAssembly_id: 1018,
									},
									{
										id: 5891,
										name: "TUBUYE",
										generalAssembly_id: 1018,
									},
									{
										id: 5892,
										name: "TWIYA",
										generalAssembly_id: 1018,
									},
									{
										id: 9993,
										name: "Kabavu  Court of Appeal ",
										generalAssembly_id: 1018,
									},
									{
										id: 11526,
										name: "Kabavu  Gacaca court of sector ",
										generalAssembly_id: 1018,
									},
								],
							},
							{
								id: 1019,
								name: "Musaraba ",
								sector_id: 10515,
								courtOfCells: [
									{
										id: 5893,
										name: "BUKERO",
										generalAssembly_id: 1019,
									},
									{
										id: 5894,
										name: "GATOROVE",
										generalAssembly_id: 1019,
									},
									{
										id: 5895,
										name: "GIHUNGA",
										generalAssembly_id: 1019,
									},
									{
										id: 5896,
										name: "KIMBOGO",
										generalAssembly_id: 1019,
									},
									{
										id: 5897,
										name: "MUSARABA",
										generalAssembly_id: 1019,
									},
									{
										id: 5898,
										name: "NYARUHOMBO",
										generalAssembly_id: 1019,
									},
									{
										id: 5899,
										name: "NYARWUNGO",
										generalAssembly_id: 1019,
									},
									{
										id: 5900,
										name: "RANGI",
										generalAssembly_id: 1019,
									},
									{
										id: 5901,
										name: "RUSOYO",
										generalAssembly_id: 1019,
									},
									{
										id: 5902,
										name: "RUTARE",
										generalAssembly_id: 1019,
									},
									{
										id: 5903,
										name: "RWIMPILI",
										generalAssembly_id: 1019,
									},
									{
										id: 9994,
										name: "Musaraba  Court of Appeal ",
										generalAssembly_id: 1019,
									},
									{
										id: 11527,
										name: "Musaraba  Gacaca court of sector ",
										generalAssembly_id: 1019,
									},
								],
							},
						],
					},
					{
						id: 10516,
						name: "Tare",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051606,
								name: "Nyamigina",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051605,
								name: "Nkumbure",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051604,
								name: "Kaganza",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051603,
								name: "Gatovu",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051602,
								name: "Gasarenda",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051601,
								name: "Buhoro",
								sector_id: 10516,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1020,
								name: "Buhoro ",
								sector_id: 10516,
								courtOfCells: [
									{
										id: 5904,
										name: "BUHORO",
										generalAssembly_id: 1020,
									},
									{
										id: 5905,
										name: "GISANZE",
										generalAssembly_id: 1020,
									},
									{
										id: 5906,
										name: "KANSEREGE",
										generalAssembly_id: 1020,
									},
									{
										id: 5907,
										name: "KIRWA",
										generalAssembly_id: 1020,
									},
									{
										id: 5908,
										name: "NYABWOMA",
										generalAssembly_id: 1020,
									},
									{
										id: 5909,
										name: "NYARWOTSI",
										generalAssembly_id: 1020,
									},
									{
										id: 5910,
										name: "RWUFE",
										generalAssembly_id: 1020,
									},
									{
										id: 9995,
										name: "Buhoro  Court of Appeal ",
										generalAssembly_id: 1020,
									},
									{
										id: 11528,
										name: "Buhoro  Gacaca court of sector ",
										generalAssembly_id: 1020,
									},
								],
							},
							{
								id: 1021,
								name: "Nkumbure ",
								sector_id: 10516,
								courtOfCells: [
									{
										id: 5911,
										name: "BIRARO",
										generalAssembly_id: 1021,
									},
									{
										id: 5912,
										name: "GAHEMBE",
										generalAssembly_id: 1021,
									},
									{
										id: 5913,
										name: "GASENGE",
										generalAssembly_id: 1021,
									},
									{
										id: 5914,
										name: "GATOVU",
										generalAssembly_id: 1021,
									},
									{
										id: 5915,
										name: "KIGUSA",
										generalAssembly_id: 1021,
									},
									{
										id: 5916,
										name: "KIBWIJE",
										generalAssembly_id: 1021,
									},
									{
										id: 5917,
										name: "KIMINA",
										generalAssembly_id: 1021,
									},
									{
										id: 5918,
										name: "NKUMBURE",
										generalAssembly_id: 1021,
									},
									{
										id: 5919,
										name: "VUMWE",
										generalAssembly_id: 1021,
									},
									{
										id: 9996,
										name: "Nkumbure  Court of Appeal ",
										generalAssembly_id: 1021,
									},
									{
										id: 11529,
										name: "Nkumbure  Gacaca court of sector ",
										generalAssembly_id: 1021,
									},
								],
							},
							{
								id: 1022,
								name: "Nyamigina ",
								sector_id: 10516,
								courtOfCells: [
									{
										id: 5920,
										name: "GAKOMA",
										generalAssembly_id: 1022,
									},
									{
										id: 5921,
										name: "NYAMIGINA",
										generalAssembly_id: 1022,
									},
									{
										id: 5922,
										name: "RUKOKO",
										generalAssembly_id: 1022,
									},
									{
										id: 5923,
										name: "UWINYANA_NYESAYO",
										generalAssembly_id: 1022,
									},
									{
										id: 9997,
										name: "Nyamigina  Court of Appeal ",
										generalAssembly_id: 1022,
									},
									{
										id: 11530,
										name: "Nyamigina  Gacaca court of sector ",
										generalAssembly_id: 1022,
									},
								],
							},
							{
								id: 1023,
								name: "Tare_I  ",
								sector_id: 10516,
								courtOfCells: [
									{
										id: 5924,
										name: "BIVUMU",
										generalAssembly_id: 1023,
									},
									{
										id: 5925,
										name: "BUREMERA",
										generalAssembly_id: 1023,
									},
									{
										id: 5926,
										name: "GASARENDA",
										generalAssembly_id: 1023,
									},
									{
										id: 5927,
										name: "KAGANZA",
										generalAssembly_id: 1023,
									},
									{
										id: 5928,
										name: "KAGARAMA",
										generalAssembly_id: 1023,
									},
									{
										id: 5929,
										name: "KIVURUGA",
										generalAssembly_id: 1023,
									},
									{
										id: 5930,
										name: "MUSE",
										generalAssembly_id: 1023,
									},
									{
										id: 5931,
										name: "RUGANZA",
										generalAssembly_id: 1023,
									},
									{
										id: 5932,
										name: "TARE",
										generalAssembly_id: 1023,
									},
									{
										id: 5933,
										name: "UWINKOMO",
										generalAssembly_id: 1023,
									},
									{
										id: 9998,
										name: "Tare_I   Court of Appeal ",
										generalAssembly_id: 1023,
									},
									{
										id: 11531,
										name: "Tare_I   Gacaca court of sector ",
										generalAssembly_id: 1023,
									},
								],
							},
						],
					},
					{
						id: 10517,
						name: "Uwinkingi",
						district_id: 105,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1051706,
								name: "Rugogwe",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051705,
								name: "Munyege",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051704,
								name: "Mudasomwa",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051703,
								name: "Kibyagira",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051702,
								name: "Gahira",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1051701,
								name: "Bigumira",
								sector_id: 10517,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1024,
								name: "Kibyagira ",
								sector_id: 10517,
								courtOfCells: [
									{
										id: 5934,
										name: "CYUMUGANZA",
										generalAssembly_id: 1024,
									},
									{
										id: 5935,
										name: "IRYANYIRAKANANI",
										generalAssembly_id: 1024,
									},
									{
										id: 5936,
										name: "KABUGA",
										generalAssembly_id: 1024,
									},
									{
										id: 5937,
										name: "KIMPYISI",
										generalAssembly_id: 1024,
									},
									{
										id: 5938,
										name: "MUKAKA",
										generalAssembly_id: 1024,
									},
									{
										id: 9999,
										name: "Kibyagira  Court of Appeal ",
										generalAssembly_id: 1024,
									},
									{
										id: 11532,
										name: "Kibyagira  Gacaca court of sector ",
										generalAssembly_id: 1024,
									},
								],
							},
							{
								id: 1025,
								name: "Munini ",
								sector_id: 10517,
								courtOfCells: [
									{
										id: 5939,
										name: "BIGUMIRA",
										generalAssembly_id: 1025,
									},
									{
										id: 5940,
										name: "GAKOKO",
										generalAssembly_id: 1025,
									},
									{
										id: 5941,
										name: "MABENDE",
										generalAssembly_id: 1025,
									},
									{
										id: 5942,
										name: "MAGUMIRA",
										generalAssembly_id: 1025,
									},
									{
										id: 5943,
										name: "MUNINI",
										generalAssembly_id: 1025,
									},
									{
										id: 5944,
										name: "MWISHOGWE",
										generalAssembly_id: 1025,
									},
									{
										id: 5945,
										name: "RUGOGWE",
										generalAssembly_id: 1025,
									},
									{
										id: 10000,
										name: "Munini  Court of Appeal ",
										generalAssembly_id: 1025,
									},
									{
										id: 11533,
										name: "Munini  Gacaca court of sector ",
										generalAssembly_id: 1025,
									},
								],
							},
							{
								id: 1026,
								name: "Tare_II  ",
								sector_id: 10517,
								courtOfCells: [
									{
										id: 5946,
										name: "BITABA",
										generalAssembly_id: 1026,
									},
									{
										id: 5947,
										name: "GAHANGO",
										generalAssembly_id: 1026,
									},
									{
										id: 5948,
										name: "GICACA",
										generalAssembly_id: 1026,
									},
									{
										id: 5949,
										name: "KANYAMPONGO",
										generalAssembly_id: 1026,
									},
									{
										id: 5950,
										name: "KARAMBO",
										generalAssembly_id: 1026,
									},
									{
										id: 5951,
										name: "KIMINA",
										generalAssembly_id: 1026,
									},
									{
										id: 5952,
										name: "MUNYEGE",
										generalAssembly_id: 1026,
									},
									{
										id: 5953,
										name: "NSINDUKA",
										generalAssembly_id: 1026,
									},
									{
										id: 5954,
										name: "NYARURAMBI",
										generalAssembly_id: 1026,
									},
									{
										id: 5955,
										name: "RUSHUBI",
										generalAssembly_id: 1026,
									},
									{
										id: 10001,
										name: "Tare_II   Court of Appeal ",
										generalAssembly_id: 1026,
									},
									{
										id: 11534,
										name: "Tare_II   Gacaca court of sector ",
										generalAssembly_id: 1026,
									},
								],
							},
							{
								id: 1027,
								name: "Uwinkingi  ",
								sector_id: 10517,
								courtOfCells: [
									{
										id: 5956,
										name: "BUNYUNYU",
										generalAssembly_id: 1027,
									},
									{
										id: 5957,
										name: "GAHIRA",
										generalAssembly_id: 1027,
									},
									{
										id: 5958,
										name: "GITITI",
										generalAssembly_id: 1027,
									},
									{
										id: 5959,
										name: "KIBUGAZI",
										generalAssembly_id: 1027,
									},
									{
										id: 5960,
										name: "RUGEYO",
										generalAssembly_id: 1027,
									},
									{
										id: 5961,
										name: "UWINKINGI",
										generalAssembly_id: 1027,
									},
									{
										id: 10002,
										name: "Uwinkingi   Court of Appeal ",
										generalAssembly_id: 1027,
									},
									{
										id: 11535,
										name: "Uwinkingi   Gacaca court of sector ",
										generalAssembly_id: 1027,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 101,
				name: "Nyanza",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10101,
						name: "Busasamana",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010105,
								name: "Rwesero",
								sector_id: 10101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010104,
								name: "Nyanza",
								sector_id: 10101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010103,
								name: "Kibinja",
								sector_id: 10101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010102,
								name: "Kavumu",
								sector_id: 10101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010101,
								name: "Gahondo",
								sector_id: 10101,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1101,
								name: "Busasamana ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6408,
										name: "BUNYESHYWA",
										generalAssembly_id: 1101,
									},
									{
										id: 6409,
										name: "GATARE",
										generalAssembly_id: 1101,
									},
									{
										id: 6410,
										name: "GATUNGURU",
										generalAssembly_id: 1101,
									},
									{
										id: 6411,
										name: "GISHIKE",
										generalAssembly_id: 1101,
									},
									{
										id: 6412,
										name: "KIVUMU",
										generalAssembly_id: 1101,
									},
									{
										id: 6413,
										name: "NYARUNYINYA",
										generalAssembly_id: 1101,
									},
									{
										id: 6414,
										name: "RUBONA",
										generalAssembly_id: 1101,
									},
									{
										id: 10076,
										name: "Busasamana  Court of Appeal ",
										generalAssembly_id: 1101,
									},
									{
										id: 11609,
										name: "Busasamana  Gacaca court of sector ",
										generalAssembly_id: 1101,
									},
								],
							},
							{
								id: 1102,
								name: "Gahanda ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6415,
										name: "BUKINANKWAVU",
										generalAssembly_id: 1102,
									},
									{
										id: 6416,
										name: "GISANDO",
										generalAssembly_id: 1102,
									},
									{
										id: 6417,
										name: "RUGARAMA",
										generalAssembly_id: 1102,
									},
									{
										id: 6418,
										name: "TABA",
										generalAssembly_id: 1102,
									},
									{
										id: 10077,
										name: "Gahanda  Court of Appeal ",
										generalAssembly_id: 1102,
									},
									{
										id: 11610,
										name: "Gahanda  Gacaca court of sector ",
										generalAssembly_id: 1102,
									},
								],
							},
							{
								id: 1103,
								name: "Gahondo ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6419,
										name: "KAMATOVU",
										generalAssembly_id: 1103,
									},
									{
										id: 6420,
										name: "KARAMA_KIBAGA",
										generalAssembly_id: 1103,
									},
									{
										id: 6421,
										name: "KAVUMU_NYARUTOVU",
										generalAssembly_id: 1103,
									},
									{
										id: 6422,
										name: "KIBERINKA",
										generalAssembly_id: 1103,
									},
									{
										id: 6423,
										name: "RWINYANA",
										generalAssembly_id: 1103,
									},
									{
										id: 10078,
										name: "Gahondo  Court of Appeal ",
										generalAssembly_id: 1103,
									},
									{
										id: 11611,
										name: "Gahondo  Gacaca court of sector ",
										generalAssembly_id: 1103,
									},
								],
							},
							{
								id: 1104,
								name: "Kavumu ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6424,
										name: "AKIRABO",
										generalAssembly_id: 1104,
									},
									{
										id: 6425,
										name: "BUGURA",
										generalAssembly_id: 1104,
									},
									{
										id: 6426,
										name: "GIHISI",
										generalAssembly_id: 1104,
									},
									{
										id: 6427,
										name: "KARUKORANYA",
										generalAssembly_id: 1104,
									},
									{
										id: 6428,
										name: "KAVUMU_I",
										generalAssembly_id: 1104,
									},
									{
										id: 6429,
										name: "KAVUMU_II",
										generalAssembly_id: 1104,
									},
									{
										id: 6430,
										name: "MUGANDAMURE",
										generalAssembly_id: 1104,
									},
									{
										id: 6431,
										name: "MUKONI",
										generalAssembly_id: 1104,
									},
									{
										id: 6432,
										name: "NYAGATOVU",
										generalAssembly_id: 1104,
									},
									{
										id: 6433,
										name: "NYAMAGANA",
										generalAssembly_id: 1104,
									},
									{
										id: 6434,
										name: "RUKANDIRO",
										generalAssembly_id: 1104,
									},
									{
										id: 10079,
										name: "Kavumu  Court of Appeal ",
										generalAssembly_id: 1104,
									},
									{
										id: 11612,
										name: "Kavumu  Gacaca court of sector ",
										generalAssembly_id: 1104,
									},
								],
							},
							{
								id: 1105,
								name: "Kibinja ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6435,
										name: "KABUZURU",
										generalAssembly_id: 1105,
									},
									{
										id: 6436,
										name: "KIGARAMA",
										generalAssembly_id: 1105,
									},
									{
										id: 6437,
										name: "MUKINDO",
										generalAssembly_id: 1105,
									},
									{
										id: 6438,
										name: "NGORONGARI",
										generalAssembly_id: 1105,
									},
									{
										id: 6439,
										name: "RUGALI",
										generalAssembly_id: 1105,
									},
									{
										id: 6440,
										name: "RUGARAMA",
										generalAssembly_id: 1105,
									},
									{
										id: 10080,
										name: "Kibinja  Court of Appeal ",
										generalAssembly_id: 1105,
									},
									{
										id: 11613,
										name: "Kibinja  Gacaca court of sector ",
										generalAssembly_id: 1105,
									},
								],
							},
							{
								id: 1106,
								name: "Nyanza ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6441,
										name: "GATSINTSINO",
										generalAssembly_id: 1106,
									},
									{
										id: 6442,
										name: "KIGARAMA",
										generalAssembly_id: 1106,
									},
									{
										id: 6443,
										name: "MUGONZI",
										generalAssembly_id: 1106,
									},
									{
										id: 6444,
										name: "GAKENYERI_A",
										generalAssembly_id: 1106,
									},
									{
										id: 6445,
										name: "GAKENYERI_B",
										generalAssembly_id: 1106,
									},
									{
										id: 6446,
										name: "NYANZA",
										generalAssembly_id: 1106,
									},
									{
										id: 10081,
										name: "Nyanza  Court of Appeal ",
										generalAssembly_id: 1106,
									},
									{
										id: 11614,
										name: "Nyanza  Gacaca court of sector ",
										generalAssembly_id: 1106,
									},
								],
							},
							{
								id: 1107,
								name: "Rwesero ",
								sector_id: 10101,
								courtOfCells: [
									{
										id: 6447,
										name: "KIDATURWA",
										generalAssembly_id: 1107,
									},
									{
										id: 6448,
										name: "MURAMBI",
										generalAssembly_id: 1107,
									},
									{
										id: 6449,
										name: "MWIMA",
										generalAssembly_id: 1107,
									},
									{
										id: 6450,
										name: "RUGARAMA",
										generalAssembly_id: 1107,
									},
									{
										id: 10082,
										name: "Rwesero  Court of Appeal ",
										generalAssembly_id: 1107,
									},
									{
										id: 11615,
										name: "Rwesero  Gacaca court of sector ",
										generalAssembly_id: 1107,
									},
								],
							},
						],
					},
					{
						id: 10102,
						name: "Busoro",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010206,
								name: "Shyira",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010205,
								name: "Rukingiro",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010204,
								name: "Munyinya",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010203,
								name: "Masangano",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010202,
								name: "Kimirama",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010201,
								name: "Gitovu",
								sector_id: 10102,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1108,
								name: "Busoro ",
								sector_id: 10102,
								courtOfCells: [
									{
										id: 6451,
										name: "BUSORO",
										generalAssembly_id: 1108,
									},
									{
										id: 6452,
										name: "GIKOMBE",
										generalAssembly_id: 1108,
									},
									{
										id: 6453,
										name: "RUNYONZA",
										generalAssembly_id: 1108,
									},
									{
										id: 6454,
										name: "SHINGA",
										generalAssembly_id: 1108,
									},
									{
										id: 10083,
										name: "Busoro  Court of Appeal ",
										generalAssembly_id: 1108,
									},
									{
										id: 11616,
										name: "Busoro  Gacaca court of sector ",
										generalAssembly_id: 1108,
									},
								],
							},
							{
								id: 1109,
								name: "Mukoma ",
								sector_id: 10102,
								courtOfCells: [
									{
										id: 6455,
										name: "CYAMUGANI",
										generalAssembly_id: 1109,
									},
									{
										id: 6456,
										name: "MUKOMA",
										generalAssembly_id: 1109,
									},
									{
										id: 6457,
										name: "NYABIKERI",
										generalAssembly_id: 1109,
									},
									{
										id: 10084,
										name: "Mukoma  Court of Appeal ",
										generalAssembly_id: 1109,
									},
									{
										id: 11617,
										name: "Mukoma  Gacaca court of sector ",
										generalAssembly_id: 1109,
									},
								],
							},
							{
								id: 1110,
								name: "Munyinya ",
								sector_id: 10102,
								courtOfCells: [
									{
										id: 6458,
										name: "KAGARAMA",
										generalAssembly_id: 1110,
									},
									{
										id: 6459,
										name: "KARAMBI",
										generalAssembly_id: 1110,
									},
									{
										id: 6460,
										name: "KIGALI",
										generalAssembly_id: 1110,
									},
									{
										id: 6461,
										name: "RWARA",
										generalAssembly_id: 1110,
									},
									{
										id: 10085,
										name: "Munyinya  Court of Appeal ",
										generalAssembly_id: 1110,
									},
									{
										id: 11618,
										name: "Munyinya  Gacaca court of sector ",
										generalAssembly_id: 1110,
									},
								],
							},
							{
								id: 1111,
								name: "Gitovu ",
								sector_id: 10102,
								courtOfCells: [
									{
										id: 6462,
										name: "KANYEGERA",
										generalAssembly_id: 1111,
									},
									{
										id: 6463,
										name: "GITWA",
										generalAssembly_id: 1111,
									},
									{
										id: 6464,
										name: "MUHINDO",
										generalAssembly_id: 1111,
									},
									{
										id: 6465,
										name: "NYAGASAMBU",
										generalAssembly_id: 1111,
									},
									{
										id: 6466,
										name: "NYAMIYONGA",
										generalAssembly_id: 1111,
									},
									{
										id: 6467,
										name: "RUGENGE",
										generalAssembly_id: 1111,
									},
									{
										id: 6468,
										name: "RUSHOKA",
										generalAssembly_id: 1111,
									},
									{
										id: 10086,
										name: "Gitovu  Court of Appeal ",
										generalAssembly_id: 1111,
									},
									{
										id: 11619,
										name: "Gitovu  Gacaca court of sector ",
										generalAssembly_id: 1111,
									},
								],
							},
							{
								id: 1112,
								name: "Shyira ",
								sector_id: 10102,
								courtOfCells: [
									{
										id: 6469,
										name: "GAKO",
										generalAssembly_id: 1112,
									},
									{
										id: 6470,
										name: "KIMIRAMA",
										generalAssembly_id: 1112,
									},
									{
										id: 6471,
										name: "RUGARAMA",
										generalAssembly_id: 1112,
									},
									{
										id: 6472,
										name: "RUSHARU",
										generalAssembly_id: 1112,
									},
									{
										id: 6473,
										name: "SARUDUHA",
										generalAssembly_id: 1112,
									},
									{
										id: 10087,
										name: "Shyira  Court of Appeal ",
										generalAssembly_id: 1112,
									},
									{
										id: 11620,
										name: "Shyira  Gacaca court of sector ",
										generalAssembly_id: 1112,
									},
								],
							},
						],
					},
					{
						id: 10103,
						name: "Cyabakamyi",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010305,
								name: "Rubona",
								sector_id: 10103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010304,
								name: "Nyarurama",
								sector_id: 10103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010303,
								name: "Nyabinyenga",
								sector_id: 10103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010302,
								name: "Karama",
								sector_id: 10103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010301,
								name: "Kadaho",
								sector_id: 10103,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1113,
								name: "Cyabakamyi ",
								sector_id: 10103,
								courtOfCells: [
									{
										id: 6474,
										name: "KABYUMA",
										generalAssembly_id: 1113,
									},
									{
										id: 6475,
										name: "KIGARAMA",
										generalAssembly_id: 1113,
									},
									{
										id: 6476,
										name: "KIROMBOZI",
										generalAssembly_id: 1113,
									},
									{
										id: 6477,
										name: "NYARURAMA",
										generalAssembly_id: 1113,
									},
									{
										id: 6478,
										name: "RUVUZO",
										generalAssembly_id: 1113,
									},
									{
										id: 6479,
										name: "RWABATWA",
										generalAssembly_id: 1113,
									},
									{
										id: 10088,
										name: "Cyabakamyi  Court of Appeal ",
										generalAssembly_id: 1113,
									},
									{
										id: 11621,
										name: "Cyabakamyi  Gacaca court of sector ",
										generalAssembly_id: 1113,
									},
								],
							},
							{
								id: 1114,
								name: "Kadaho ",
								sector_id: 10103,
								courtOfCells: [
									{
										id: 6480,
										name: "GAHENGERI",
										generalAssembly_id: 1114,
									},
									{
										id: 6481,
										name: "GASENYI",
										generalAssembly_id: 1114,
									},
									{
										id: 6482,
										name: "GATABA",
										generalAssembly_id: 1114,
									},
									{
										id: 6483,
										name: "GITEGA",
										generalAssembly_id: 1114,
									},
									{
										id: 6484,
										name: "KABERE",
										generalAssembly_id: 1114,
									},
									{
										id: 6485,
										name: "KADAHO",
										generalAssembly_id: 1114,
									},
									{
										id: 6486,
										name: "NYABISAZI",
										generalAssembly_id: 1114,
									},
									{
										id: 6487,
										name: "NYABYIYONI",
										generalAssembly_id: 1114,
									},
									{
										id: 6488,
										name: "NYARURAMBA",
										generalAssembly_id: 1114,
									},
									{
										id: 10089,
										name: "Kadaho  Court of Appeal ",
										generalAssembly_id: 1114,
									},
									{
										id: 11622,
										name: "Kadaho  Gacaca court of sector ",
										generalAssembly_id: 1114,
									},
								],
							},
							{
								id: 1115,
								name: "Mucubira ",
								sector_id: 10103,
								courtOfCells: [
									{
										id: 6489,
										name: "GASHARU",
										generalAssembly_id: 1115,
									},
									{
										id: 6490,
										name: "GATONGATI",
										generalAssembly_id: 1115,
									},
									{
										id: 6491,
										name: "KAGARAMA",
										generalAssembly_id: 1115,
									},
									{
										id: 6492,
										name: "KARAMA",
										generalAssembly_id: 1115,
									},
									{
										id: 6493,
										name: "NYAGISOZI",
										generalAssembly_id: 1115,
									},
									{
										id: 6494,
										name: "RUSAVE",
										generalAssembly_id: 1115,
									},
									{
										id: 6495,
										name: "RUSENYI",
										generalAssembly_id: 1115,
									},
									{
										id: 10090,
										name: "Mucubira  Court of Appeal ",
										generalAssembly_id: 1115,
									},
									{
										id: 11623,
										name: "Mucubira  Gacaca court of sector ",
										generalAssembly_id: 1115,
									},
								],
							},
							{
								id: 1116,
								name: "Nyabinyenga ",
								sector_id: 10103,
								courtOfCells: [
									{
										id: 6496,
										name: "KABERE",
										generalAssembly_id: 1116,
									},
									{
										id: 6497,
										name: "KIMIYUMBU",
										generalAssembly_id: 1116,
									},
									{
										id: 6498,
										name: "RUGWA",
										generalAssembly_id: 1116,
									},
									{
										id: 6499,
										name: "RWAMAGANA",
										generalAssembly_id: 1116,
									},
									{
										id: 10091,
										name: "Nyabinyenga  Court of Appeal ",
										generalAssembly_id: 1116,
									},
									{
										id: 11624,
										name: "Nyabinyenga  Gacaca court of sector ",
										generalAssembly_id: 1116,
									},
								],
							},
							{
								id: 1117,
								name: "Rubona ",
								sector_id: 10103,
								courtOfCells: [
									{
										id: 6500,
										name: "BIKOMBE",
										generalAssembly_id: 1117,
									},
									{
										id: 6501,
										name: "BUGARAMA",
										generalAssembly_id: 1117,
									},
									{
										id: 6502,
										name: "GAHUNGA",
										generalAssembly_id: 1117,
									},
									{
										id: 6503,
										name: "KAVUMU",
										generalAssembly_id: 1117,
									},
									{
										id: 6504,
										name: "MURAMBI",
										generalAssembly_id: 1117,
									},
									{
										id: 6505,
										name: "NYABISHIKE",
										generalAssembly_id: 1117,
									},
									{
										id: 6506,
										name: "NYAMINAZI",
										generalAssembly_id: 1117,
									},
									{
										id: 6507,
										name: "NYARUTOVU",
										generalAssembly_id: 1117,
									},
									{
										id: 6508,
										name: "RUGENDABARI",
										generalAssembly_id: 1117,
									},
									{
										id: 10092,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 1117,
									},
									{
										id: 11625,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 1117,
									},
								],
							},
						],
					},
					{
						id: 10104,
						name: "Kibilizi",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010404,
								name: "Rwotso",
								sector_id: 10104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010403,
								name: "Mututu",
								sector_id: 10104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010402,
								name: "Mbuye",
								sector_id: 10104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010401,
								name: "Cyeru",
								sector_id: 10104,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1118,
								name: "Gisasa  ",
								sector_id: 10104,
								courtOfCells: [
									{
										id: 6509,
										name: "GASHARU",
										generalAssembly_id: 1118,
									},
									{
										id: 6510,
										name: "KITABAYE",
										generalAssembly_id: 1118,
									},
									{
										id: 6511,
										name: "RUNYONZA",
										generalAssembly_id: 1118,
									},
									{
										id: 10093,
										name: "Gisasa   Court of Appeal ",
										generalAssembly_id: 1118,
									},
									{
										id: 11626,
										name: "Gisasa   Gacaca court of sector ",
										generalAssembly_id: 1118,
									},
								],
							},
							{
								id: 1119,
								name: "Kibilizi ",
								sector_id: 10104,
								courtOfCells: [
									{
										id: 6512,
										name: "MUTIMA",
										generalAssembly_id: 1119,
									},
									{
										id: 6513,
										name: "RWOTSO",
										generalAssembly_id: 1119,
									},
									{
										id: 10094,
										name: "Kibilizi  Court of Appeal ",
										generalAssembly_id: 1119,
									},
									{
										id: 11627,
										name: "Kibilizi  Gacaca court of sector ",
										generalAssembly_id: 1119,
									},
								],
							},
							{
								id: 1120,
								name: "Matara ",
								sector_id: 10104,
								courtOfCells: [
									{
										id: 6514,
										name: "CYERU",
										generalAssembly_id: 1120,
									},
									{
										id: 6515,
										name: "GISIKA",
										generalAssembly_id: 1120,
									},
									{
										id: 6516,
										name: "MUYEBE",
										generalAssembly_id: 1120,
									},
									{
										id: 6517,
										name: "RUGUNGA",
										generalAssembly_id: 1120,
									},
									{
										id: 10095,
										name: "Matara  Court of Appeal ",
										generalAssembly_id: 1120,
									},
									{
										id: 11628,
										name: "Matara  Gacaca court of sector ",
										generalAssembly_id: 1120,
									},
								],
							},
							{
								id: 1121,
								name: "Mbuye ",
								sector_id: 10104,
								courtOfCells: [
									{
										id: 6518,
										name: "MPANDA",
										generalAssembly_id: 1121,
									},
									{
										id: 6519,
										name: "RUKORE",
										generalAssembly_id: 1121,
									},
									{
										id: 10096,
										name: "Mbuye  Court of Appeal ",
										generalAssembly_id: 1121,
									},
									{
										id: 11629,
										name: "Mbuye  Gacaca court of sector ",
										generalAssembly_id: 1121,
									},
								],
							},
							{
								id: 1122,
								name: "Mututu ",
								sector_id: 10104,
								courtOfCells: [
									{
										id: 6520,
										name: "MAHWA",
										generalAssembly_id: 1122,
									},
									{
										id: 6521,
										name: "MUTUTU",
										generalAssembly_id: 1122,
									},
									{
										id: 6522,
										name: "RUBONA",
										generalAssembly_id: 1122,
									},
									{
										id: 6523,
										name: "SHANGO",
										generalAssembly_id: 1122,
									},
									{
										id: 10097,
										name: "Mututu  Court of Appeal ",
										generalAssembly_id: 1122,
									},
									{
										id: 11630,
										name: "Mututu  Gacaca court of sector ",
										generalAssembly_id: 1122,
									},
								],
							},
						],
					},
					{
						id: 10105,
						name: "Kigoma",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010505,
								name: "Mulinja",
								sector_id: 10105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010504,
								name: "Gasoro",
								sector_id: 10105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010503,
								name: "Gahombo",
								sector_id: 10105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010502,
								name: "Butara",
								sector_id: 10105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010501,
								name: "Butansinda",
								sector_id: 10105,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1123,
								name: "Gasoro ",
								sector_id: 10105,
								courtOfCells: [
									{
										id: 6524,
										name: "BUGARURA",
										generalAssembly_id: 1123,
									},
									{
										id: 6525,
										name: "GASORO",
										generalAssembly_id: 1123,
									},
									{
										id: 6526,
										name: "KIDATURWA",
										generalAssembly_id: 1123,
									},
									{
										id: 6527,
										name: "KINENE",
										generalAssembly_id: 1123,
									},
									{
										id: 6528,
										name: "MUTENDE",
										generalAssembly_id: 1123,
									},
									{
										id: 6529,
										name: "SHORI",
										generalAssembly_id: 1123,
									},
									{
										id: 10098,
										name: "Gasoro  Court of Appeal ",
										generalAssembly_id: 1123,
									},
									{
										id: 11631,
										name: "Gasoro  Gacaca court of sector ",
										generalAssembly_id: 1123,
									},
								],
							},
							{
								id: 1124,
								name: "Butara ",
								sector_id: 10105,
								courtOfCells: [
									{
										id: 6530,
										name: "BURUBA",
										generalAssembly_id: 1124,
									},
									{
										id: 6531,
										name: "GASHARU",
										generalAssembly_id: 1124,
									},
									{
										id: 6532,
										name: "KAVUMU",
										generalAssembly_id: 1124,
									},
									{
										id: 10099,
										name: "Butara  Court of Appeal ",
										generalAssembly_id: 1124,
									},
									{
										id: 11632,
										name: "Butara  Gacaca court of sector ",
										generalAssembly_id: 1124,
									},
								],
							},
							{
								id: 1125,
								name: "Mulinja ",
								sector_id: 10105,
								courtOfCells: [
									{
										id: 6533,
										name: "KARAMA",
										generalAssembly_id: 1125,
									},
									{
										id: 6534,
										name: "RUGANDO",
										generalAssembly_id: 1125,
									},
									{
										id: 6535,
										name: "SABUNUNGA",
										generalAssembly_id: 1125,
									},
									{
										id: 10100,
										name: "Mulinja  Court of Appeal ",
										generalAssembly_id: 1125,
									},
									{
										id: 11633,
										name: "Mulinja  Gacaca court of sector ",
										generalAssembly_id: 1125,
									},
								],
							},
							{
								id: 1126,
								name: "Gahombo ",
								sector_id: 10105,
								courtOfCells: [
									{
										id: 6536,
										name: "GAHOMBO",
										generalAssembly_id: 1126,
									},
									{
										id: 6537,
										name: "KASEBASENGO",
										generalAssembly_id: 1126,
									},
									{
										id: 6538,
										name: "MUSENYI",
										generalAssembly_id: 1126,
									},
									{
										id: 6539,
										name: "NYAMAGANA",
										generalAssembly_id: 1126,
									},
									{
										id: 6540,
										name: "NYAMAHINGA",
										generalAssembly_id: 1126,
									},
									{
										id: 10101,
										name: "Gahombo  Court of Appeal ",
										generalAssembly_id: 1126,
									},
									{
										id: 11634,
										name: "Gahombo  Gacaca court of sector ",
										generalAssembly_id: 1126,
									},
								],
							},
							{
								id: 1127,
								name: "Kigoma ",
								sector_id: 10105,
								courtOfCells: [
									{
										id: 6541,
										name: "GITARE",
										generalAssembly_id: 1127,
									},
									{
										id: 6542,
										name: "KARAMA",
										generalAssembly_id: 1127,
									},
									{
										id: 6543,
										name: "KIGOMA",
										generalAssembly_id: 1127,
									},
									{
										id: 6544,
										name: "MARONGI",
										generalAssembly_id: 1127,
									},
									{
										id: 6545,
										name: "NYESONGA",
										generalAssembly_id: 1127,
									},
									{
										id: 6546,
										name: "BUTANSINDA",
										generalAssembly_id: 1127,
									},
									{
										id: 10102,
										name: "Kigoma  Court of Appeal ",
										generalAssembly_id: 1127,
									},
									{
										id: 11635,
										name: "Kigoma  Gacaca court of sector ",
										generalAssembly_id: 1127,
									},
								],
							},
						],
					},
					{
						id: 10106,
						name: "Mukingo",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010606,
								name: "Nkomero",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010605,
								name: "Ngwa",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010604,
								name: "Mpanga",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010603,
								name: "Kiruli",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010602,
								name: "Gatagara",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010601,
								name: "Cyerezo",
								sector_id: 10106,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1128,
								name: "Mpanga ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6547,
										name: "KINYINYA",
										generalAssembly_id: 1128,
									},
									{
										id: 6548,
										name: "MPANGA",
										generalAssembly_id: 1128,
									},
									{
										id: 6549,
										name: "NKINDA_RUBAGA",
										generalAssembly_id: 1128,
									},
									{
										id: 6550,
										name: "NYAKABUYE",
										generalAssembly_id: 1128,
									},
									{
										id: 6551,
										name: "NYAMAZI",
										generalAssembly_id: 1128,
									},
									{
										id: 6552,
										name: "REMERA",
										generalAssembly_id: 1128,
									},
									{
										id: 10103,
										name: "Mpanga  Court of Appeal ",
										generalAssembly_id: 1128,
									},
									{
										id: 11636,
										name: "Mpanga  Gacaca court of sector ",
										generalAssembly_id: 1128,
									},
								],
							},
							{
								id: 1129,
								name: "Mukingo ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6553,
										name: "GATAGARA",
										generalAssembly_id: 1129,
									},
									{
										id: 6554,
										name: "KARAMA",
										generalAssembly_id: 1129,
									},
									{
										id: 6555,
										name: "KINYOGOTO",
										generalAssembly_id: 1129,
									},
									{
										id: 6556,
										name: "MUHORORO",
										generalAssembly_id: 1129,
									},
									{
										id: 6557,
										name: "NYAMIYAGA",
										generalAssembly_id: 1129,
									},
									{
										id: 6558,
										name: "NYAMUKO",
										generalAssembly_id: 1129,
									},
									{
										id: 10104,
										name: "Mukingo  Court of Appeal ",
										generalAssembly_id: 1129,
									},
									{
										id: 11637,
										name: "Mukingo  Gacaca court of sector ",
										generalAssembly_id: 1129,
									},
								],
							},
							{
								id: 1130,
								name: "Kiruri ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6559,
										name: "GAHOKO",
										generalAssembly_id: 1130,
									},
									{
										id: 6560,
										name: "KIGANDA",
										generalAssembly_id: 1130,
									},
									{
										id: 6561,
										name: "KIGARAMA",
										generalAssembly_id: 1130,
									},
									{
										id: 6562,
										name: "MUGANZA",
										generalAssembly_id: 1130,
									},
									{
										id: 6563,
										name: "MUREHE",
										generalAssembly_id: 1130,
									},
									{
										id: 6564,
										name: "MUTURIRWA",
										generalAssembly_id: 1130,
									},
									{
										id: 10105,
										name: "Kiruri  Court of Appeal ",
										generalAssembly_id: 1130,
									},
									{
										id: 11638,
										name: "Kiruri  Gacaca court of sector ",
										generalAssembly_id: 1130,
									},
								],
							},
							{
								id: 1131,
								name: "Ngwa ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6565,
										name: "BIRORO",
										generalAssembly_id: 1131,
									},
									{
										id: 6566,
										name: "KAGWA",
										generalAssembly_id: 1131,
									},
									{
										id: 6567,
										name: "KIGARAMA",
										generalAssembly_id: 1131,
									},
									{
										id: 6568,
										name: "MWANABIRI",
										generalAssembly_id: 1131,
									},
									{
										id: 6569,
										name: "NYARUNYINYA",
										generalAssembly_id: 1131,
									},
									{
										id: 6570,
										name: "RUTETE",
										generalAssembly_id: 1131,
									},
									{
										id: 10106,
										name: "Ngwa  Court of Appeal ",
										generalAssembly_id: 1131,
									},
									{
										id: 11639,
										name: "Ngwa  Gacaca court of sector ",
										generalAssembly_id: 1131,
									},
								],
							},
							{
								id: 1132,
								name: "Nkomero ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6571,
										name: "CYIMANA",
										generalAssembly_id: 1132,
									},
									{
										id: 6572,
										name: "GISUMA",
										generalAssembly_id: 1132,
									},
									{
										id: 6573,
										name: "KABARIMA",
										generalAssembly_id: 1132,
									},
									{
										id: 6574,
										name: "KABANOZA",
										generalAssembly_id: 1132,
									},
									{
										id: 6575,
										name: "KABURENGERO",
										generalAssembly_id: 1132,
									},
									{
										id: 6576,
										name: "KIBONDE",
										generalAssembly_id: 1132,
									},
									{
										id: 6577,
										name: "KIGARAMA",
										generalAssembly_id: 1132,
									},
									{
										id: 6578,
										name: "NYACYOMA",
										generalAssembly_id: 1132,
									},
									{
										id: 6579,
										name: "NZUKI",
										generalAssembly_id: 1132,
									},
									{
										id: 6580,
										name: "RUHOSHA_NYAKABUNGO",
										generalAssembly_id: 1132,
									},
									{
										id: 10107,
										name: "Nkomero  Court of Appeal ",
										generalAssembly_id: 1132,
									},
									{
										id: 11640,
										name: "Nkomero  Gacaca court of sector ",
										generalAssembly_id: 1132,
									},
								],
							},
							{
								id: 1133,
								name: "Runyengando ",
								sector_id: 10106,
								courtOfCells: [
									{
										id: 6581,
										name: "BIRAMBO",
										generalAssembly_id: 1133,
									},
									{
										id: 6582,
										name: "BWERAMANA",
										generalAssembly_id: 1133,
									},
									{
										id: 6583,
										name: "CYEREZO",
										generalAssembly_id: 1133,
									},
									{
										id: 6584,
										name: "CYIKIREHE",
										generalAssembly_id: 1133,
									},
									{
										id: 6585,
										name: "CYUMBA",
										generalAssembly_id: 1133,
									},
									{
										id: 6586,
										name: "GASHARU",
										generalAssembly_id: 1133,
									},
									{
										id: 6587,
										name: "KARAMBI",
										generalAssembly_id: 1133,
									},
									{
										id: 6588,
										name: "NYARUTOVU",
										generalAssembly_id: 1133,
									},
									{
										id: 10108,
										name: "Runyengando  Court of Appeal ",
										generalAssembly_id: 1133,
									},
									{
										id: 11641,
										name: "Runyengando  Gacaca court of sector ",
										generalAssembly_id: 1133,
									},
								],
							},
						],
					},
					{
						id: 10107,
						name: "Muyira",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010705,
								name: "Nyundo",
								sector_id: 10107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010704,
								name: "Nyamure",
								sector_id: 10107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010703,
								name: "Nyamiyaga",
								sector_id: 10107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010702,
								name: "Migina",
								sector_id: 10107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010701,
								name: "Gati",
								sector_id: 10107,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1134,
								name: "Kimvuzo ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6589,
										name: "KARIRISI",
										generalAssembly_id: 1134,
									},
									{
										id: 6590,
										name: "KINYANA",
										generalAssembly_id: 1134,
									},
									{
										id: 10109,
										name: "Kimvuzo  Court of Appeal ",
										generalAssembly_id: 1134,
									},
									{
										id: 11642,
										name: "Kimvuzo  Gacaca court of sector ",
										generalAssembly_id: 1134,
									},
								],
							},
							{
								id: 1135,
								name: "Gatonde ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6591,
										name: "CYEGERA",
										generalAssembly_id: 1135,
									},
									{
										id: 6592,
										name: "GITUZA",
										generalAssembly_id: 1135,
									},
									{
										id: 6593,
										name: "KAVUMU",
										generalAssembly_id: 1135,
									},
									{
										id: 10110,
										name: "Gatonde  Court of Appeal ",
										generalAssembly_id: 1135,
									},
									{
										id: 11643,
										name: "Gatonde  Gacaca court of sector ",
										generalAssembly_id: 1135,
									},
								],
							},
							{
								id: 1136,
								name: "Karama ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6594,
										name: "KANKIMA",
										generalAssembly_id: 1136,
									},
									{
										id: 6595,
										name: "KARUYUMBU",
										generalAssembly_id: 1136,
									},
									{
										id: 10111,
										name: "Karama  Court of Appeal ",
										generalAssembly_id: 1136,
									},
									{
										id: 11644,
										name: "Karama  Gacaca court of sector ",
										generalAssembly_id: 1136,
									},
								],
							},
							{
								id: 1137,
								name: "Kayanza ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6596,
										name: "BUHAZA",
										generalAssembly_id: 1137,
									},
									{
										id: 6597,
										name: "KINYONI",
										generalAssembly_id: 1137,
									},
									{
										id: 10112,
										name: "Kayanza  Court of Appeal ",
										generalAssembly_id: 1137,
									},
									{
										id: 11645,
										name: "Kayanza  Gacaca court of sector ",
										generalAssembly_id: 1137,
									},
								],
							},
							{
								id: 1138,
								name: "Muyira ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6598,
										name: "JARI",
										generalAssembly_id: 1138,
									},
									{
										id: 6599,
										name: "MUYIRA",
										generalAssembly_id: 1138,
									},
									{
										id: 6600,
										name: "NYUNDO",
										generalAssembly_id: 1138,
									},
									{
										id: 6601,
										name: "NZOGA",
										generalAssembly_id: 1138,
									},
									{
										id: 10113,
										name: "Muyira  Court of Appeal ",
										generalAssembly_id: 1138,
									},
									{
										id: 11646,
										name: "Muyira  Gacaca court of sector ",
										generalAssembly_id: 1138,
									},
								],
							},
							{
								id: 1139,
								name: "Nyakibungo ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6602,
										name: "KIMFIZI",
										generalAssembly_id: 1139,
									},
									{
										id: 6603,
										name: "KINIGA",
										generalAssembly_id: 1139,
									},
									{
										id: 6604,
										name: "RUYENZI",
										generalAssembly_id: 1139,
									},
									{
										id: 10114,
										name: "Nyakibungo  Court of Appeal ",
										generalAssembly_id: 1139,
									},
									{
										id: 11647,
										name: "Nyakibungo  Gacaca court of sector ",
										generalAssembly_id: 1139,
									},
								],
							},
							{
								id: 1140,
								name: "Nyamiyaga ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6605,
										name: "GIHAMA",
										generalAssembly_id: 1140,
									},
									{
										id: 6606,
										name: "KABUYE",
										generalAssembly_id: 1140,
									},
									{
										id: 6607,
										name: "MUGALI",
										generalAssembly_id: 1140,
									},
									{
										id: 6608,
										name: "NZOVI",
										generalAssembly_id: 1140,
									},
									{
										id: 6609,
										name: "RUGESE",
										generalAssembly_id: 1140,
									},
									{
										id: 10115,
										name: "Nyamiyaga  Court of Appeal ",
										generalAssembly_id: 1140,
									},
									{
										id: 11648,
										name: "Nyamiyaga  Gacaca court of sector ",
										generalAssembly_id: 1140,
									},
								],
							},
							{
								id: 1141,
								name: "Nyamure ",
								sector_id: 10107,
								courtOfCells: [
									{
										id: 6610,
										name: "GATARE",
										generalAssembly_id: 1141,
									},
									{
										id: 6611,
										name: "MUSENYI",
										generalAssembly_id: 1141,
									},
									{
										id: 6612,
										name: "NYARUGUNGA",
										generalAssembly_id: 1141,
									},
									{
										id: 10116,
										name: "Nyamure  Court of Appeal ",
										generalAssembly_id: 1141,
									},
									{
										id: 11649,
										name: "Nyamure  Gacaca court of sector ",
										generalAssembly_id: 1141,
									},
								],
							},
						],
					},
					{
						id: 10108,
						name: "Ntyazo",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010804,
								name: "Katarara",
								sector_id: 10108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010803,
								name: "Kagunga",
								sector_id: 10108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010802,
								name: "Cyotamakara",
								sector_id: 10108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010801,
								name: "Bugali",
								sector_id: 10108,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1142,
								name: "Bugari ",
								sector_id: 10108,
								courtOfCells: [
									{
										id: 6613,
										name: "GISAYURA",
										generalAssembly_id: 1142,
									},
									{
										id: 6614,
										name: "NDAGO",
										generalAssembly_id: 1142,
									},
									{
										id: 6615,
										name: "RUGARAMA",
										generalAssembly_id: 1142,
									},
									{
										id: 10117,
										name: "Bugari  Court of Appeal ",
										generalAssembly_id: 1142,
									},
									{
										id: 11650,
										name: "Bugari  Gacaca court of sector ",
										generalAssembly_id: 1142,
									},
								],
							},
							{
								id: 1143,
								name: "Kagunga  ",
								sector_id: 10108,
								courtOfCells: [
									{
										id: 6616,
										name: "NYAKABUNGO",
										generalAssembly_id: 1143,
									},
									{
										id: 6617,
										name: "RUSASA",
										generalAssembly_id: 1143,
									},
									{
										id: 6618,
										name: "SAMUDUHA",
										generalAssembly_id: 1143,
									},
									{
										id: 10118,
										name: "Kagunga   Court of Appeal ",
										generalAssembly_id: 1143,
									},
									{
										id: 11651,
										name: "Kagunga   Gacaca court of sector ",
										generalAssembly_id: 1143,
									},
								],
							},
							{
								id: 1144,
								name: "Ntyazo ",
								sector_id: 10108,
								courtOfCells: [
									{
										id: 6619,
										name: "GASHARU",
										generalAssembly_id: 1144,
									},
									{
										id: 6620,
										name: "KATARARA",
										generalAssembly_id: 1144,
									},
									{
										id: 6621,
										name: "MUHERO",
										generalAssembly_id: 1144,
									},
									{
										id: 10119,
										name: "Ntyazo  Court of Appeal ",
										generalAssembly_id: 1144,
									},
									{
										id: 11652,
										name: "Ntyazo  Gacaca court of sector ",
										generalAssembly_id: 1144,
									},
								],
							},
							{
								id: 1145,
								name: "Ruyenzi ",
								sector_id: 10108,
								courtOfCells: [
									{
										id: 6622,
										name: "BAYI",
										generalAssembly_id: 1145,
									},
									{
										id: 6623,
										name: "CYOTAMAKARA",
										generalAssembly_id: 1145,
									},
									{
										id: 10120,
										name: "Ruyenzi  Court of Appeal ",
										generalAssembly_id: 1145,
									},
									{
										id: 11653,
										name: "Ruyenzi  Gacaca court of sector ",
										generalAssembly_id: 1145,
									},
								],
							},
						],
					},
					{
						id: 10109,
						name: "Nyagisozi",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1010905,
								name: "Rurangazi",
								sector_id: 10109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010904,
								name: "Kirambi",
								sector_id: 10109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010903,
								name: "Kabuga",
								sector_id: 10109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010902,
								name: "Kabirizi",
								sector_id: 10109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1010901,
								name: "Gahunga",
								sector_id: 10109,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1146,
								name: "Gahunga ",
								sector_id: 10109,
								courtOfCells: [
									{
										id: 6624,
										name: "GAHUNGA",
										generalAssembly_id: 1146,
									},
									{
										id: 6625,
										name: "GIHARA",
										generalAssembly_id: 1146,
									},
									{
										id: 6626,
										name: "GITUNTU",
										generalAssembly_id: 1146,
									},
									{
										id: 6627,
										name: "KIGOHE",
										generalAssembly_id: 1146,
									},
									{
										id: 6628,
										name: "MWEYA",
										generalAssembly_id: 1146,
									},
									{
										id: 6629,
										name: "NYAMUGALI",
										generalAssembly_id: 1146,
									},
									{
										id: 6630,
										name: "UWARUKARA",
										generalAssembly_id: 1146,
									},
									{
										id: 10121,
										name: "Gahunga  Court of Appeal ",
										generalAssembly_id: 1146,
									},
									{
										id: 11654,
										name: "Gahunga  Gacaca court of sector ",
										generalAssembly_id: 1146,
									},
								],
							},
							{
								id: 1147,
								name: "Kabilizi ",
								sector_id: 10109,
								courtOfCells: [
									{
										id: 6631,
										name: "GIHIMBI",
										generalAssembly_id: 1147,
									},
									{
										id: 6632,
										name: "KABIRIZI",
										generalAssembly_id: 1147,
									},
									{
										id: 6633,
										name: "MUHAGA",
										generalAssembly_id: 1147,
									},
									{
										id: 6634,
										name: "MWUMBA",
										generalAssembly_id: 1147,
									},
									{
										id: 6635,
										name: "NYAGATOVU",
										generalAssembly_id: 1147,
									},
									{
										id: 10122,
										name: "Kabilizi  Court of Appeal ",
										generalAssembly_id: 1147,
									},
									{
										id: 11655,
										name: "Kabilizi  Gacaca court of sector ",
										generalAssembly_id: 1147,
									},
								],
							},
							{
								id: 1148,
								name: "Kirambi  ",
								sector_id: 10109,
								courtOfCells: [
									{
										id: 6636,
										name: "BWERU",
										generalAssembly_id: 1148,
									},
									{
										id: 6637,
										name: "GASHARU",
										generalAssembly_id: 1148,
									},
									{
										id: 6638,
										name: "KIRAMBI",
										generalAssembly_id: 1148,
									},
									{
										id: 6639,
										name: "MPAZA",
										generalAssembly_id: 1148,
									},
									{
										id: 6640,
										name: "MWEZI",
										generalAssembly_id: 1148,
									},
									{
										id: 6641,
										name: "RWANKUBA",
										generalAssembly_id: 1148,
									},
									{
										id: 6642,
										name: "RWIMBAZI",
										generalAssembly_id: 1148,
									},
									{
										id: 10123,
										name: "Kirambi   Court of Appeal ",
										generalAssembly_id: 1148,
									},
									{
										id: 11656,
										name: "Kirambi   Gacaca court of sector ",
										generalAssembly_id: 1148,
									},
								],
							},
							{
								id: 1149,
								name: "Nyagisozi ",
								sector_id: 10109,
								courtOfCells: [
									{
										id: 6643,
										name: "GASHYENZI",
										generalAssembly_id: 1149,
									},
									{
										id: 6644,
										name: "KABAKOCO",
										generalAssembly_id: 1149,
									},
									{
										id: 6645,
										name: "MUSONGATI",
										generalAssembly_id: 1149,
									},
									{
										id: 6646,
										name: "NYAGISOZI",
										generalAssembly_id: 1149,
									},
									{
										id: 6647,
										name: "NYAMAGANA",
										generalAssembly_id: 1149,
									},
									{
										id: 6648,
										name: "RUSHUBI",
										generalAssembly_id: 1149,
									},
									{
										id: 6649,
										name: "RURANGAZI",
										generalAssembly_id: 1149,
									},
									{
										id: 10124,
										name: "Nyagisozi  Court of Appeal ",
										generalAssembly_id: 1149,
									},
									{
										id: 11657,
										name: "Nyagisozi  Gacaca court of sector ",
										generalAssembly_id: 1149,
									},
								],
							},
							{
								id: 1150,
								name: "Remera ",
								sector_id: 10109,
								courtOfCells: [
									{
										id: 6650,
										name: "GATOKI",
										generalAssembly_id: 1150,
									},
									{
										id: 6651,
										name: "MIREHE",
										generalAssembly_id: 1150,
									},
									{
										id: 6652,
										name: "MURANDARYI",
										generalAssembly_id: 1150,
									},
									{
										id: 6653,
										name: "MWOKORA",
										generalAssembly_id: 1150,
									},
									{
										id: 6654,
										name: "NYAMITOBO",
										generalAssembly_id: 1150,
									},
									{
										id: 6655,
										name: "UWABUSHINGWE",
										generalAssembly_id: 1150,
									},
									{
										id: 6656,
										name: "UWAGISOZI",
										generalAssembly_id: 1150,
									},
									{
										id: 6657,
										name: "UWIMPURA",
										generalAssembly_id: 1150,
									},
									{
										id: 10125,
										name: "Remera  Court of Appeal ",
										generalAssembly_id: 1150,
									},
									{
										id: 11658,
										name: "Remera  Gacaca court of sector ",
										generalAssembly_id: 1150,
									},
								],
							},
						],
					},
					{
						id: 10110,
						name: "Rwabicuma",
						district_id: 101,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1011006,
								name: "Runga",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1011005,
								name: "Nyarusange",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1011004,
								name: "Mushirarungu",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1011003,
								name: "Mubuga",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1011002,
								name: "Gishike",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1011001,
								name: "Gacu",
								sector_id: 10110,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1151,
								name: "Cyaratsi ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6658,
										name: "BUSORYO",
										generalAssembly_id: 1151,
									},
									{
										id: 6659,
										name: "GASHIKIRI",
										generalAssembly_id: 1151,
									},
									{
										id: 6660,
										name: "KARWIRU",
										generalAssembly_id: 1151,
									},
									{
										id: 6661,
										name: "NYARUTOVU",
										generalAssembly_id: 1151,
									},
									{
										id: 10126,
										name: "Cyaratsi  Court of Appeal ",
										generalAssembly_id: 1151,
									},
									{
										id: 11659,
										name: "Cyaratsi  Gacaca court of sector ",
										generalAssembly_id: 1151,
									},
								],
							},
							{
								id: 1152,
								name: "Gacu ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6662,
										name: "BISAMBU",
										generalAssembly_id: 1152,
									},
									{
										id: 6663,
										name: "GISAKE",
										generalAssembly_id: 1152,
									},
									{
										id: 6664,
										name: "KAREHE",
										generalAssembly_id: 1152,
									},
									{
										id: 6665,
										name: "NYAMIYAGA",
										generalAssembly_id: 1152,
									},
									{
										id: 10127,
										name: "Gacu  Court of Appeal ",
										generalAssembly_id: 1152,
									},
									{
										id: 11660,
										name: "Gacu  Gacaca court of sector ",
										generalAssembly_id: 1152,
									},
								],
							},
							{
								id: 1153,
								name: "Mushirarungu ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6666,
										name: "GISORO",
										generalAssembly_id: 1153,
									},
									{
										id: 6667,
										name: "KIRWA",
										generalAssembly_id: 1153,
									},
									{
										id: 6668,
										name: "MUNYINYA",
										generalAssembly_id: 1153,
									},
									{
										id: 10128,
										name: "Mushirarungu  Court of Appeal ",
										generalAssembly_id: 1153,
									},
									{
										id: 11661,
										name: "Mushirarungu  Gacaca court of sector ",
										generalAssembly_id: 1153,
									},
								],
							},
							{
								id: 1154,
								name: "Nyarusange ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6669,
										name: "CYARWA",
										generalAssembly_id: 1154,
									},
									{
										id: 6670,
										name: "KAMUVUNYI",
										generalAssembly_id: 1154,
									},
									{
										id: 6671,
										name: "RWAGAHAGO",
										generalAssembly_id: 1154,
									},
									{
										id: 6672,
										name: "KAVUMU",
										generalAssembly_id: 1154,
									},
									{
										id: 10129,
										name: "Nyarusange  Court of Appeal ",
										generalAssembly_id: 1154,
									},
									{
										id: 11662,
										name: "Nyarusange  Gacaca court of sector ",
										generalAssembly_id: 1154,
									},
								],
							},
							{
								id: 1155,
								name: "Runga ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6673,
										name: "KIGARAMA",
										generalAssembly_id: 1155,
									},
									{
										id: 6674,
										name: "MURAMBI",
										generalAssembly_id: 1155,
									},
									{
										id: 6675,
										name: "NDAGO",
										generalAssembly_id: 1155,
									},
									{
										id: 6676,
										name: "RUGARAMA",
										generalAssembly_id: 1155,
									},
									{
										id: 10130,
										name: "Runga  Court of Appeal ",
										generalAssembly_id: 1155,
									},
									{
										id: 11663,
										name: "Runga  Gacaca court of sector ",
										generalAssembly_id: 1155,
									},
								],
							},
							{
								id: 1156,
								name: "Rwabicuma ",
								sector_id: 10110,
								courtOfCells: [
									{
										id: 6677,
										name: "GASIZA_RWAMUSHUMBA",
										generalAssembly_id: 1156,
									},
									{
										id: 6678,
										name: "GISHIKE",
										generalAssembly_id: 1156,
									},
									{
										id: 6679,
										name: "KARAMBO_NYAMABUYE",
										generalAssembly_id: 1156,
									},
									{
										id: 6680,
										name: "KARUSIMBI",
										generalAssembly_id: 1156,
									},
									{
										id: 10131,
										name: "Rwabicuma  Court of Appeal ",
										generalAssembly_id: 1156,
									},
									{
										id: 11664,
										name: "Rwabicuma  Gacaca court of sector ",
										generalAssembly_id: 1156,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 103,
				name: "Nyaruguru",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10301,
						name: "Busanze",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030105,
								name: "Shororo",
								sector_id: 10301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030104,
								name: "Runyombyi",
								sector_id: 10301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030103,
								name: "Nteko",
								sector_id: 10301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030102,
								name: "Nkanda",
								sector_id: 10301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030101,
								name: "Kirarangombe",
								sector_id: 10301,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1178,
								name: "Busanze  ",
								sector_id: 10301,
								courtOfCells: [
									{
										id: 6790,
										name: "BITARE",
										generalAssembly_id: 1178,
									},
									{
										id: 6791,
										name: "BUKINGA",
										generalAssembly_id: 1178,
									},
									{
										id: 6792,
										name: "GATWARO",
										generalAssembly_id: 1178,
									},
									{
										id: 6793,
										name: "MUNYANGALI",
										generalAssembly_id: 1178,
									},
									{
										id: 6794,
										name: "MUSEBEYA",
										generalAssembly_id: 1178,
									},
									{
										id: 6795,
										name: "RUTABO",
										generalAssembly_id: 1178,
									},
									{
										id: 6796,
										name: "UWAMAKUMBA",
										generalAssembly_id: 1178,
									},
									{
										id: 6797,
										name: "SHORORO",
										generalAssembly_id: 1178,
									},
									{
										id: 10152,
										name: "Busanze   Court of Appeal ",
										generalAssembly_id: 1178,
									},
									{
										id: 11685,
										name: "Busanze   Gacaca court of sector ",
										generalAssembly_id: 1178,
									},
								],
							},
							{
								id: 1179,
								name: "Gisoro ",
								sector_id: 10301,
								courtOfCells: [
									{
										id: 6798,
										name: "BUKINANYANA",
										generalAssembly_id: 1179,
									},
									{
										id: 6799,
										name: "KABAVOMO",
										generalAssembly_id: 1179,
									},
									{
										id: 6800,
										name: "NDATEMWA",
										generalAssembly_id: 1179,
									},
									{
										id: 6801,
										name: "NTEKO",
										generalAssembly_id: 1179,
									},
									{
										id: 6802,
										name: "NYARUKERI",
										generalAssembly_id: 1179,
									},
									{
										id: 6803,
										name: "NYARUSANGE",
										generalAssembly_id: 1179,
									},
									{
										id: 6804,
										name: "UWAMASATA",
										generalAssembly_id: 1179,
									},
									{
										id: 10153,
										name: "Gisoro  Court of Appeal ",
										generalAssembly_id: 1179,
									},
									{
										id: 11686,
										name: "Gisoro  Gacaca court of sector ",
										generalAssembly_id: 1179,
									},
								],
							},
							{
								id: 1180,
								name: "Kirarangombe ",
								sector_id: 10301,
								courtOfCells: [
									{
										id: 6805,
										name: "GITWE",
										generalAssembly_id: 1180,
									},
									{
										id: 6806,
										name: "KINYINYA",
										generalAssembly_id: 1180,
									},
									{
										id: 6807,
										name: "MASIGA",
										generalAssembly_id: 1180,
									},
									{
										id: 6808,
										name: "MUTOBO",
										generalAssembly_id: 1180,
									},
									{
										id: 6809,
										name: "RYANYIRARWEVU",
										generalAssembly_id: 1180,
									},
									{
										id: 6810,
										name: "UWINDAVA",
										generalAssembly_id: 1180,
									},
									{
										id: 10154,
										name: "Kirarangombe  Court of Appeal ",
										generalAssembly_id: 1180,
									},
									{
										id: 11687,
										name: "Kirarangombe  Gacaca court of sector ",
										generalAssembly_id: 1180,
									},
								],
							},
							{
								id: 1181,
								name: "Runyombyi ",
								sector_id: 10301,
								courtOfCells: [
									{
										id: 6811,
										name: "BUGINA",
										generalAssembly_id: 1181,
									},
									{
										id: 6812,
										name: "GABIRO",
										generalAssembly_id: 1181,
									},
									{
										id: 6813,
										name: "MURENDA",
										generalAssembly_id: 1181,
									},
									{
										id: 6814,
										name: "RANGO",
										generalAssembly_id: 1181,
									},
									{
										id: 6815,
										name: "SHWIMA",
										generalAssembly_id: 1181,
									},
									{
										id: 10155,
										name: "Runyombyi  Court of Appeal ",
										generalAssembly_id: 1181,
									},
									{
										id: 11688,
										name: "Runyombyi  Gacaca court of sector ",
										generalAssembly_id: 1181,
									},
								],
							},
						],
					},
					{
						id: 10302,
						name: "Cyahinda",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030205,
								name: "Rutobwe",
								sector_id: 10302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030204,
								name: "Muhambara",
								sector_id: 10302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030203,
								name: "Gasasa",
								sector_id: 10302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030202,
								name: "Cyahinda",
								sector_id: 10302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030201,
								name: "Coko",
								sector_id: 10302,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1182,
								name: "Coko ",
								sector_id: 10302,
								courtOfCells: [
									{
										id: 6816,
										name: "AGASHARU",
										generalAssembly_id: 1182,
									},
									{
										id: 6817,
										name: "AKANINI",
										generalAssembly_id: 1182,
									},
									{
										id: 6818,
										name: "COKO",
										generalAssembly_id: 1182,
									},
									{
										id: 6819,
										name: "MIRABYO",
										generalAssembly_id: 1182,
									},
									{
										id: 6820,
										name: "RUKO",
										generalAssembly_id: 1182,
									},
									{
										id: 10156,
										name: "Coko  Court of Appeal ",
										generalAssembly_id: 1182,
									},
									{
										id: 11689,
										name: "Coko  Gacaca court of sector ",
										generalAssembly_id: 1182,
									},
								],
							},
							{
								id: 1183,
								name: "Cyahinda ",
								sector_id: 10302,
								courtOfCells: [
									{
										id: 6821,
										name: "CYAHINDA",
										generalAssembly_id: 1183,
									},
									{
										id: 6822,
										name: "CYANWA",
										generalAssembly_id: 1183,
									},
									{
										id: 6823,
										name: "KINYAGA",
										generalAssembly_id: 1183,
									},
									{
										id: 6824,
										name: "SABURUNDURU",
										generalAssembly_id: 1183,
									},
									{
										id: 10157,
										name: "Cyahinda  Court of Appeal ",
										generalAssembly_id: 1183,
									},
									{
										id: 11690,
										name: "Cyahinda  Gacaca court of sector ",
										generalAssembly_id: 1183,
									},
								],
							},
							{
								id: 1184,
								name: "Gasasa ",
								sector_id: 10302,
								courtOfCells: [
									{
										id: 6825,
										name: "GASASA",
										generalAssembly_id: 1184,
									},
									{
										id: 6826,
										name: "KAVUMU",
										generalAssembly_id: 1184,
									},
									{
										id: 10158,
										name: "Gasasa  Court of Appeal ",
										generalAssembly_id: 1184,
									},
									{
										id: 11691,
										name: "Gasasa  Gacaca court of sector ",
										generalAssembly_id: 1184,
									},
								],
							},
							{
								id: 1185,
								name: "Muhambara  ",
								sector_id: 10302,
								courtOfCells: [
									{
										id: 6827,
										name: "BYANONE",
										generalAssembly_id: 1185,
									},
									{
										id: 6828,
										name: "GASHARU",
										generalAssembly_id: 1185,
									},
									{
										id: 6829,
										name: "NYAGATOVU",
										generalAssembly_id: 1185,
									},
									{
										id: 6830,
										name: "REBERO",
										generalAssembly_id: 1185,
									},
									{
										id: 10159,
										name: "Muhambara   Court of Appeal ",
										generalAssembly_id: 1185,
									},
									{
										id: 11692,
										name: "Muhambara   Gacaca court of sector ",
										generalAssembly_id: 1185,
									},
								],
							},
							{
								id: 1186,
								name: "Rutobwe  ",
								sector_id: 10302,
								courtOfCells: [
									{
										id: 6831,
										name: "CYIBUMBA",
										generalAssembly_id: 1186,
									},
									{
										id: 6832,
										name: "NGOBYI",
										generalAssembly_id: 1186,
									},
									{
										id: 6833,
										name: "RUGARAMA",
										generalAssembly_id: 1186,
									},
									{
										id: 6834,
										name: "RUTOBWE",
										generalAssembly_id: 1186,
									},
									{
										id: 10160,
										name: "Rutobwe   Court of Appeal ",
										generalAssembly_id: 1186,
									},
									{
										id: 11693,
										name: "Rutobwe   Gacaca court of sector ",
										generalAssembly_id: 1186,
									},
								],
							},
						],
					},
					{
						id: 10303,
						name: "Kibeho",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030306,
								name: "Nyange",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030305,
								name: "Mubuga",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030304,
								name: "Mpanda",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030303,
								name: "Mbasa",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030302,
								name: "Kibeho",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030301,
								name: "Gakoma",
								sector_id: 10303,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1187,
								name: "Kibeho  ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6835,
										name: "AGASHARU_MPUNGE",
										generalAssembly_id: 1187,
									},
									{
										id: 6836,
										name: "AGATEKO",
										generalAssembly_id: 1187,
									},
									{
										id: 6837,
										name: "AKAJONGE",
										generalAssembly_id: 1187,
									},
									{
										id: 6838,
										name: "NYARUNYINYA",
										generalAssembly_id: 1187,
									},
									{
										id: 6839,
										name: "UWARURAYI",
										generalAssembly_id: 1187,
									},
									{
										id: 6840,
										name: "UWISASU",
										generalAssembly_id: 1187,
									},
									{
										id: 10161,
										name: "Kibeho   Court of Appeal ",
										generalAssembly_id: 1187,
									},
									{
										id: 11694,
										name: "Kibeho   Gacaca court of sector ",
										generalAssembly_id: 1187,
									},
								],
							},
							{
								id: 1188,
								name: "Nyarushishi  ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6841,
										name: "AGATEKO-KIGONA",
										generalAssembly_id: 1188,
									},
									{
										id: 6842,
										name: "NKOMERO_NYABISINDU_MPATSWE",
										generalAssembly_id: 1188,
									},
									{
										id: 6843,
										name: "NYANGE",
										generalAssembly_id: 1188,
									},
									{
										id: 6844,
										name: "NYARUSHISHI",
										generalAssembly_id: 1188,
									},
									{
										id: 6845,
										name: "UWIFURO",
										generalAssembly_id: 1188,
									},
									{
										id: 10162,
										name: "Nyarushishi   Court of Appeal ",
										generalAssembly_id: 1188,
									},
									{
										id: 11695,
										name: "Nyarushishi   Gacaca court of sector ",
										generalAssembly_id: 1188,
									},
								],
							},
							{
								id: 1189,
								name: "Nyarusovu ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6846,
										name: "ROHERO_BUCYEYE",
										generalAssembly_id: 1189,
									},
									{
										id: 6847,
										name: "MUBUGA",
										generalAssembly_id: 1189,
									},
									{
										id: 6848,
										name: "NGARAMA",
										generalAssembly_id: 1189,
									},
									{
										id: 6849,
										name: "NYARUSOVU",
										generalAssembly_id: 1189,
									},
									{
										id: 10163,
										name: "Nyarusovu  Court of Appeal ",
										generalAssembly_id: 1189,
									},
									{
										id: 11696,
										name: "Nyarusovu  Gacaca court of sector ",
										generalAssembly_id: 1189,
									},
								],
							},
							{
								id: 1190,
								name: "Nyarwumba ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6850,
										name: "AGASHARU",
										generalAssembly_id: 1190,
									},
									{
										id: 6851,
										name: "AKABUBURU",
										generalAssembly_id: 1190,
									},
									{
										id: 6852,
										name: "UWAGAHIRIMA",
										generalAssembly_id: 1190,
									},
									{
										id: 6853,
										name: "NYARWUMBA",
										generalAssembly_id: 1190,
									},
									{
										id: 6854,
										name: "UMURAMBI",
										generalAssembly_id: 1190,
									},
									{
										id: 6855,
										name: "UWINTOBO",
										generalAssembly_id: 1190,
									},
									{
										id: 10164,
										name: "Nyarwumba  Court of Appeal ",
										generalAssembly_id: 1190,
									},
									{
										id: 11697,
										name: "Nyarwumba  Gacaca court of sector ",
										generalAssembly_id: 1190,
									},
								],
							},
							{
								id: 1191,
								name: "Runyinya ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6856,
										name: "NYAGISHAYO",
										generalAssembly_id: 1191,
									},
									{
										id: 6857,
										name: "RUNYINYA",
										generalAssembly_id: 1191,
									},
									{
										id: 6858,
										name: "RUREMBO_NYABICUMA_KIBILIZI",
										generalAssembly_id: 1191,
									},
									{
										id: 6859,
										name: "VIRO_GAKOMA",
										generalAssembly_id: 1191,
									},
									{
										id: 10165,
										name: "Runyinya  Court of Appeal ",
										generalAssembly_id: 1191,
									},
									{
										id: 11698,
										name: "Runyinya  Gacaca court of sector ",
										generalAssembly_id: 1191,
									},
								],
							},
							{
								id: 1192,
								name: "Mbasa ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6860,
										name: "KINAZI",
										generalAssembly_id: 1192,
									},
									{
										id: 6861,
										name: "MIGINA",
										generalAssembly_id: 1192,
									},
									{
										id: 6862,
										name: "NYARUTOVU",
										generalAssembly_id: 1192,
									},
									{
										id: 6863,
										name: "RWIMBOGO",
										generalAssembly_id: 1192,
									},
									{
										id: 10166,
										name: "Mbasa  Court of Appeal ",
										generalAssembly_id: 1192,
									},
									{
										id: 11699,
										name: "Mbasa  Gacaca court of sector ",
										generalAssembly_id: 1192,
									},
								],
							},
							{
								id: 1193,
								name: "Mpanda ",
								sector_id: 10303,
								courtOfCells: [
									{
										id: 6864,
										name: "BANGA",
										generalAssembly_id: 1193,
									},
									{
										id: 6865,
										name: "KIBAYI",
										generalAssembly_id: 1193,
									},
									{
										id: 6866,
										name: "MUNEGE",
										generalAssembly_id: 1193,
									},
									{
										id: 6867,
										name: "UWARUGINA",
										generalAssembly_id: 1193,
									},
									{
										id: 10167,
										name: "Mpanda  Court of Appeal ",
										generalAssembly_id: 1193,
									},
									{
										id: 11700,
										name: "Mpanda  Gacaca court of sector ",
										generalAssembly_id: 1193,
									},
								],
							},
						],
					},
					{
						id: 10304,
						name: "Kivu",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030405,
								name: "Rugerero",
								sector_id: 10304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030404,
								name: "Kivu",
								sector_id: 10304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030403,
								name: "Kimina",
								sector_id: 10304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030402,
								name: "Gahurizo",
								sector_id: 10304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030401,
								name: "Cyanyirankora",
								sector_id: 10304,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1194,
								name: "Cyanyirankora ",
								sector_id: 10304,
								courtOfCells: [
									{
										id: 6868,
										name: "CYANYIRANKORA",
										generalAssembly_id: 1194,
									},
									{
										id: 6869,
										name: "KABINGO",
										generalAssembly_id: 1194,
									},
									{
										id: 6870,
										name: "KAVUMU",
										generalAssembly_id: 1194,
									},
									{
										id: 6871,
										name: "NYARWOTSI",
										generalAssembly_id: 1194,
									},
									{
										id: 6872,
										name: "RUGANZA",
										generalAssembly_id: 1194,
									},
									{
										id: 6873,
										name: "RUGERERO",
										generalAssembly_id: 1194,
									},
									{
										id: 10168,
										name: "Cyanyirankora  Court of Appeal ",
										generalAssembly_id: 1194,
									},
									{
										id: 11701,
										name: "Cyanyirankora  Gacaca court of sector ",
										generalAssembly_id: 1194,
									},
								],
							},
							{
								id: 1195,
								name: "Gahurizo ",
								sector_id: 10304,
								courtOfCells: [
									{
										id: 6874,
										name: "GAHURIZO",
										generalAssembly_id: 1195,
									},
									{
										id: 6875,
										name: "GAKUTA",
										generalAssembly_id: 1195,
									},
									{
										id: 6876,
										name: "KINTARE",
										generalAssembly_id: 1195,
									},
									{
										id: 6877,
										name: "MIRONZI",
										generalAssembly_id: 1195,
									},
									{
										id: 6878,
										name: "UWANYIRAMARIZA",
										generalAssembly_id: 1195,
									},
									{
										id: 10169,
										name: "Gahurizo  Court of Appeal ",
										generalAssembly_id: 1195,
									},
									{
										id: 11702,
										name: "Gahurizo  Gacaca court of sector ",
										generalAssembly_id: 1195,
									},
								],
							},
							{
								id: 1196,
								name: "Kivu  ",
								sector_id: 10304,
								courtOfCells: [
									{
										id: 6879,
										name: "BIKAMBA",
										generalAssembly_id: 1196,
									},
									{
										id: 6880,
										name: "KAVUMU",
										generalAssembly_id: 1196,
									},
									{
										id: 6881,
										name: "KIMINA",
										generalAssembly_id: 1196,
									},
									{
										id: 6882,
										name: "KIVU",
										generalAssembly_id: 1196,
									},
									{
										id: 6883,
										name: "RUBUMBURI",
										generalAssembly_id: 1196,
									},
									{
										id: 6884,
										name: "UWISAGA",
										generalAssembly_id: 1196,
									},
									{
										id: 10170,
										name: "Kivu   Court of Appeal ",
										generalAssembly_id: 1196,
									},
									{
										id: 11703,
										name: "Kivu   Gacaca court of sector ",
										generalAssembly_id: 1196,
									},
								],
							},
						],
					},
					{
						id: 10305,
						name: "Mata",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030505,
								name: "Rwamiko",
								sector_id: 10305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030504,
								name: "Ramba",
								sector_id: 10305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030503,
								name: "Nyamabuye",
								sector_id: 10305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030502,
								name: "Murambi",
								sector_id: 10305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030501,
								name: "Gorwe",
								sector_id: 10305,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1197,
								name: "Gorwe ",
								sector_id: 10305,
								courtOfCells: [
									{
										id: 6885,
										name: "GORWE",
										generalAssembly_id: 1197,
									},
									{
										id: 6886,
										name: "RIMBANYA",
										generalAssembly_id: 1197,
									},
									{
										id: 6887,
										name: "MUHORA_RWOMEKA",
										generalAssembly_id: 1197,
									},
									{
										id: 6888,
										name: "MUNIGANE",
										generalAssembly_id: 1197,
									},
									{
										id: 6889,
										name: "RUHUNGA",
										generalAssembly_id: 1197,
									},
									{
										id: 10171,
										name: "Gorwe  Court of Appeal ",
										generalAssembly_id: 1197,
									},
									{
										id: 11704,
										name: "Gorwe  Gacaca court of sector ",
										generalAssembly_id: 1197,
									},
								],
							},
							{
								id: 1198,
								name: "Mata ",
								sector_id: 10305,
								courtOfCells: [
									{
										id: 6890,
										name: "CYAFURWE",
										generalAssembly_id: 1198,
									},
									{
										id: 6891,
										name: "MATA",
										generalAssembly_id: 1198,
									},
									{
										id: 6892,
										name: "MURAMBI",
										generalAssembly_id: 1198,
									},
									{
										id: 6893,
										name: "NYAKAVUMU",
										generalAssembly_id: 1198,
									},
									{
										id: 6894,
										name: "NYAMABUYE",
										generalAssembly_id: 1198,
									},
									{
										id: 6895,
										name: "RUNONO",
										generalAssembly_id: 1198,
									},
									{
										id: 6896,
										name: "TUBUBURU",
										generalAssembly_id: 1198,
									},
									{
										id: 6897,
										name: "NYAMYUMBA",
										generalAssembly_id: 1198,
									},
									{
										id: 10172,
										name: "Mata  Court of Appeal ",
										generalAssembly_id: 1198,
									},
									{
										id: 11705,
										name: "Mata  Gacaca court of sector ",
										generalAssembly_id: 1198,
									},
								],
							},
							{
								id: 1199,
								name: "Rwamiko ",
								sector_id: 10305,
								courtOfCells: [
									{
										id: 6898,
										name: "GASIZI",
										generalAssembly_id: 1199,
									},
									{
										id: 6899,
										name: "NYAMABUYE",
										generalAssembly_id: 1199,
									},
									{
										id: 6900,
										name: "MASHETSA_RWINANKA",
										generalAssembly_id: 1199,
									},
									{
										id: 6901,
										name: "RWAMIKO",
										generalAssembly_id: 1199,
									},
									{
										id: 6902,
										name: "UWABABANDA",
										generalAssembly_id: 1199,
									},
									{
										id: 10173,
										name: "Rwamiko  Court of Appeal ",
										generalAssembly_id: 1199,
									},
									{
										id: 11706,
										name: "Rwamiko  Gacaca court of sector ",
										generalAssembly_id: 1199,
									},
								],
							},
							{
								id: 1200,
								name: "Ramba ",
								sector_id: 10305,
								courtOfCells: [
									{
										id: 6903,
										name: "GASASA",
										generalAssembly_id: 1200,
									},
									{
										id: 6904,
										name: "RUNYWAMAZI",
										generalAssembly_id: 1200,
									},
									{
										id: 6905,
										name: "GATWARO",
										generalAssembly_id: 1200,
									},
									{
										id: 6906,
										name: "RAMBA ",
										generalAssembly_id: 1200,
									},
									{
										id: 6907,
										name: "KIBARI",
										generalAssembly_id: 1200,
									},
									{
										id: 10174,
										name: "Ramba  Court of Appeal ",
										generalAssembly_id: 1200,
									},
									{
										id: 11707,
										name: "Ramba  Gacaca court of sector ",
										generalAssembly_id: 1200,
									},
								],
							},
						],
					},
					{
						id: 10306,
						name: "Muganza",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030604,
								name: "Uwacyiza",
								sector_id: 10306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030603,
								name: "Samiyonga",
								sector_id: 10306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030602,
								name: "Rukore",
								sector_id: 10306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030601,
								name: "Muganza",
								sector_id: 10306,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1201,
								name: "Mutovu ",
								sector_id: 10306,
								courtOfCells: [
									{
										id: 6908,
										name: "BITABA",
										generalAssembly_id: 1201,
									},
									{
										id: 6909,
										name: "KABARI",
										generalAssembly_id: 1201,
									},
									{
										id: 6910,
										name: "MIGENDO",
										generalAssembly_id: 1201,
									},
									{
										id: 6911,
										name: "MUKONGORO",
										generalAssembly_id: 1201,
									},
									{
										id: 6912,
										name: "MURABA",
										generalAssembly_id: 1201,
									},
									{
										id: 6913,
										name: "MUTOVU",
										generalAssembly_id: 1201,
									},
									{
										id: 6914,
										name: "SAMIYONGA",
										generalAssembly_id: 1201,
									},
									{
										id: 6915,
										name: "NSHIRI",
										generalAssembly_id: 1201,
									},
									{
										id: 10175,
										name: "Mutovu  Court of Appeal ",
										generalAssembly_id: 1201,
									},
									{
										id: 11708,
										name: "Mutovu  Gacaca court of sector ",
										generalAssembly_id: 1201,
									},
								],
							},
							{
								id: 1202,
								name: "Rwishywa ",
								sector_id: 10306,
								courtOfCells: [
									{
										id: 6916,
										name: "CYURUKORE",
										generalAssembly_id: 1202,
									},
									{
										id: 6917,
										name: "KANAZI",
										generalAssembly_id: 1202,
									},
									{
										id: 6918,
										name: "KARANKA",
										generalAssembly_id: 1202,
									},
									{
										id: 6919,
										name: "RUKORE",
										generalAssembly_id: 1202,
									},
									{
										id: 6920,
										name: "RWISHYWA",
										generalAssembly_id: 1202,
									},
									{
										id: 6921,
										name: "UWAGAFU",
										generalAssembly_id: 1202,
									},
									{
										id: 6922,
										name: "UWINZIRA",
										generalAssembly_id: 1202,
									},
									{
										id: 10176,
										name: "Rwishywa  Court of Appeal ",
										generalAssembly_id: 1202,
									},
									{
										id: 11709,
										name: "Rwishywa  Gacaca court of sector ",
										generalAssembly_id: 1202,
									},
								],
							},
							{
								id: 1203,
								name: "Tangabo ",
								sector_id: 10306,
								courtOfCells: [
									{
										id: 6923,
										name: "BIGUGU",
										generalAssembly_id: 1203,
									},
									{
										id: 6924,
										name: "GITUNTU",
										generalAssembly_id: 1203,
									},
									{
										id: 6925,
										name: "KIGWENE",
										generalAssembly_id: 1203,
									},
									{
										id: 6926,
										name: "TANGABO",
										generalAssembly_id: 1203,
									},
									{
										id: 10177,
										name: "Tangabo  Court of Appeal ",
										generalAssembly_id: 1203,
									},
									{
										id: 11710,
										name: "Tangabo  Gacaca court of sector ",
										generalAssembly_id: 1203,
									},
								],
							},
							{
								id: 1204,
								name: "Muganza ",
								sector_id: 10306,
								courtOfCells: [
									{
										id: 6927,
										name: "GASHINGE",
										generalAssembly_id: 1204,
									},
									{
										id: 6928,
										name: "MUBAZI",
										generalAssembly_id: 1204,
									},
									{
										id: 6929,
										name: "MUGANZA",
										generalAssembly_id: 1204,
									},
									{
										id: 6930,
										name: "NGARA",
										generalAssembly_id: 1204,
									},
									{
										id: 6931,
										name: "NYABIRONDO",
										generalAssembly_id: 1204,
									},
									{
										id: 6932,
										name: "RAMBYANYANA",
										generalAssembly_id: 1204,
									},
									{
										id: 6933,
										name: "MIGENDO",
										generalAssembly_id: 1204,
									},
									{
										id: 10178,
										name: "Muganza  Court of Appeal ",
										generalAssembly_id: 1204,
									},
									{
										id: 11711,
										name: "Muganza  Gacaca court of sector ",
										generalAssembly_id: 1204,
									},
								],
							},
						],
					},
					{
						id: 10307,
						name: "Munini",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030705,
								name: "Nyarure",
								sector_id: 10307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030704,
								name: "Ntwali",
								sector_id: 10307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030703,
								name: "Ngeri",
								sector_id: 10307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030702,
								name: "Ngarurira",
								sector_id: 10307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030701,
								name: "Giheta",
								sector_id: 10307,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1205,
								name: "Shororo ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6934,
										name: "KIBYAGIRA",
										generalAssembly_id: 1205,
									},
									{
										id: 6935,
										name: "KIGARAMA",
										generalAssembly_id: 1205,
									},
									{
										id: 6936,
										name: "MURAMBI",
										generalAssembly_id: 1205,
									},
									{
										id: 6937,
										name: "RORERO",
										generalAssembly_id: 1205,
									},
									{
										id: 6938,
										name: "RYANYAMI",
										generalAssembly_id: 1205,
									},
									{
										id: 6939,
										name: "UWINTEKO",
										generalAssembly_id: 1205,
									},
									{
										id: 10179,
										name: "Shororo  Court of Appeal ",
										generalAssembly_id: 1205,
									},
									{
										id: 11712,
										name: "Shororo  Gacaca court of sector ",
										generalAssembly_id: 1205,
									},
								],
							},
							{
								id: 1206,
								name: "Gasare ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6940,
										name: "GACUMU",
										generalAssembly_id: 1206,
									},
									{
										id: 6941,
										name: "GAHANGO",
										generalAssembly_id: 1206,
									},
									{
										id: 6942,
										name: "GASARE",
										generalAssembly_id: 1206,
									},
									{
										id: 6943,
										name: "GASOVU",
										generalAssembly_id: 1206,
									},
									{
										id: 6944,
										name: "GIHETA",
										generalAssembly_id: 1206,
									},
									{
										id: 6945,
										name: "MASHYA",
										generalAssembly_id: 1206,
									},
									{
										id: 10180,
										name: "Gasare  Court of Appeal ",
										generalAssembly_id: 1206,
									},
									{
										id: 11713,
										name: "Gasare  Gacaca court of sector ",
										generalAssembly_id: 1206,
									},
								],
							},
							{
								id: 1207,
								name: "Gisizi ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6946,
										name: "AGATARE",
										generalAssembly_id: 1207,
									},
									{
										id: 6947,
										name: "AKAREHE",
										generalAssembly_id: 1207,
									},
									{
										id: 6948,
										name: "GISIZI",
										generalAssembly_id: 1207,
									},
									{
										id: 6949,
										name: "GITEGA",
										generalAssembly_id: 1207,
									},
									{
										id: 6950,
										name: "NGARURIRA",
										generalAssembly_id: 1207,
									},
									{
										id: 6951,
										name: "UWUMUKO",
										generalAssembly_id: 1207,
									},
									{
										id: 10181,
										name: "Gisizi  Court of Appeal ",
										generalAssembly_id: 1207,
									},
									{
										id: 11714,
										name: "Gisizi  Gacaca court of sector ",
										generalAssembly_id: 1207,
									},
								],
							},
							{
								id: 1208,
								name: "Kabilizi ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6952,
										name: "KABILIZI",
										generalAssembly_id: 1208,
									},
									{
										id: 6953,
										name: "NTWALI",
										generalAssembly_id: 1208,
									},
									{
										id: 6954,
										name: "NYAMBARAGASA",
										generalAssembly_id: 1208,
									},
									{
										id: 6955,
										name: "RWINANKA",
										generalAssembly_id: 1208,
									},
									{
										id: 6956,
										name: "UWIMFIZI",
										generalAssembly_id: 1208,
									},
									{
										id: 10182,
										name: "Kabilizi  Court of Appeal ",
										generalAssembly_id: 1208,
									},
									{
										id: 11715,
										name: "Kabilizi  Gacaca court of sector ",
										generalAssembly_id: 1208,
									},
								],
							},
							{
								id: 1209,
								name: "Kamana ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6957,
										name: "KAMANA",
										generalAssembly_id: 1209,
									},
									{
										id: 6958,
										name: "KIMENA",
										generalAssembly_id: 1209,
									},
									{
										id: 6959,
										name: "MUHORORO",
										generalAssembly_id: 1209,
									},
									{
										id: 6960,
										name: "MUNANIRA",
										generalAssembly_id: 1209,
									},
									{
										id: 6961,
										name: "NYARURE",
										generalAssembly_id: 1209,
									},
									{
										id: 6962,
										name: "SHEKE",
										generalAssembly_id: 1209,
									},
									{
										id: 10183,
										name: "Kamana  Court of Appeal ",
										generalAssembly_id: 1209,
									},
									{
										id: 11716,
										name: "Kamana  Gacaca court of sector ",
										generalAssembly_id: 1209,
									},
								],
							},
							{
								id: 1210,
								name: "Ruseke ",
								sector_id: 10307,
								courtOfCells: [
									{
										id: 6963,
										name: "AKAGERA",
										generalAssembly_id: 1210,
									},
									{
										id: 6964,
										name: "MUSHWATI",
										generalAssembly_id: 1210,
									},
									{
										id: 6965,
										name: "NDAGO",
										generalAssembly_id: 1210,
									},
									{
										id: 6966,
										name: "RUBONA_NGERI",
										generalAssembly_id: 1210,
									},
									{
										id: 6967,
										name: "RUSEKE",
										generalAssembly_id: 1210,
									},
									{
										id: 6968,
										name: "RUSHUBI",
										generalAssembly_id: 1210,
									},
									{
										id: 10184,
										name: "Ruseke  Court of Appeal ",
										generalAssembly_id: 1210,
									},
									{
										id: 11717,
										name: "Ruseke  Gacaca court of sector ",
										generalAssembly_id: 1210,
									},
								],
							},
						],
					},
					{
						id: 10308,
						name: "Ngera",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030806,
								name: "Yaramba",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030805,
								name: "Nyanza",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030804,
								name: "Nyamirama",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030803,
								name: "Murama",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030802,
								name: "Mukuge",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030801,
								name: "Bitare",
								sector_id: 10308,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1211,
								name: "Sheke ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6969,
										name: "GASHIRU",
										generalAssembly_id: 1211,
									},
									{
										id: 6970,
										name: "SHEKE",
										generalAssembly_id: 1211,
									},
									{
										id: 6971,
										name: "BITARE",
										generalAssembly_id: 1211,
									},
									{
										id: 10185,
										name: "Sheke  Court of Appeal ",
										generalAssembly_id: 1211,
									},
									{
										id: 11718,
										name: "Sheke  Gacaca court of sector ",
										generalAssembly_id: 1211,
									},
								],
							},
							{
								id: 1212,
								name: "Yaramba  ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6972,
										name: "BUHUNGA",
										generalAssembly_id: 1212,
									},
									{
										id: 6973,
										name: "KIRWA",
										generalAssembly_id: 1212,
									},
									{
										id: 6974,
										name: "MUSUMBA",
										generalAssembly_id: 1212,
									},
									{
										id: 6975,
										name: "YARAMBA",
										generalAssembly_id: 1212,
									},
									{
										id: 10186,
										name: "Yaramba   Court of Appeal ",
										generalAssembly_id: 1212,
									},
									{
										id: 11719,
										name: "Yaramba   Gacaca court of sector ",
										generalAssembly_id: 1212,
									},
								],
							},
							{
								id: 1213,
								name: "Kibingo  ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6976,
										name: "GIHUMA",
										generalAssembly_id: 1213,
									},
									{
										id: 6977,
										name: "KIBINGO",
										generalAssembly_id: 1213,
									},
									{
										id: 6978,
										name: "GISOZI",
										generalAssembly_id: 1213,
									},
									{
										id: 6979,
										name: "MPINGA",
										generalAssembly_id: 1213,
									},
									{
										id: 6980,
										name: "NYANZA",
										generalAssembly_id: 1213,
									},
									{
										id: 10187,
										name: "Kibingo   Court of Appeal ",
										generalAssembly_id: 1213,
									},
									{
										id: 11720,
										name: "Kibingo   Gacaca court of sector ",
										generalAssembly_id: 1213,
									},
								],
							},
							{
								id: 1214,
								name: "Murama  ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6981,
										name: "KAGANDA",
										generalAssembly_id: 1214,
									},
									{
										id: 6982,
										name: "NYARUGANO",
										generalAssembly_id: 1214,
									},
									{
										id: 10188,
										name: "Murama   Court of Appeal ",
										generalAssembly_id: 1214,
									},
									{
										id: 11721,
										name: "Murama   Gacaca court of sector ",
										generalAssembly_id: 1214,
									},
								],
							},
							{
								id: 1215,
								name: "Ngera  ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6983,
										name: "MBOGO",
										generalAssembly_id: 1215,
									},
									{
										id: 6984,
										name: "RUNYAMI",
										generalAssembly_id: 1215,
									},
									{
										id: 10189,
										name: "Ngera   Court of Appeal ",
										generalAssembly_id: 1215,
									},
									{
										id: 11722,
										name: "Ngera   Gacaca court of sector ",
										generalAssembly_id: 1215,
									},
								],
							},
							{
								id: 1216,
								name: "Riba  ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6985,
										name: "KINTEKO",
										generalAssembly_id: 1216,
									},
									{
										id: 6986,
										name: "MUBUGA",
										generalAssembly_id: 1216,
									},
									{
										id: 6987,
										name: "NYAMIRAMA",
										generalAssembly_id: 1216,
									},
									{
										id: 10190,
										name: "Riba   Court of Appeal ",
										generalAssembly_id: 1216,
									},
									{
										id: 11723,
										name: "Riba   Gacaca court of sector ",
										generalAssembly_id: 1216,
									},
								],
							},
							{
								id: 1217,
								name: "Mukuge ",
								sector_id: 10308,
								courtOfCells: [
									{
										id: 6988,
										name: "CYAMUTUMBA",
										generalAssembly_id: 1217,
									},
									{
										id: 6989,
										name: "CYARATSI",
										generalAssembly_id: 1217,
									},
									{
										id: 6990,
										name: "MUKUGE",
										generalAssembly_id: 1217,
									},
									{
										id: 10191,
										name: "Mukuge  Court of Appeal ",
										generalAssembly_id: 1217,
									},
									{
										id: 11724,
										name: "Mukuge  Gacaca court of sector ",
										generalAssembly_id: 1217,
									},
								],
							},
						],
					},
					{
						id: 10309,
						name: "Ngoma",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1030906,
								name: "Rubona",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030905,
								name: "Nyamirama",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030904,
								name: "Mbuye",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030903,
								name: "Kiyonza",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030902,
								name: "Kibangu",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1030901,
								name: "Fugi",
								sector_id: 10309,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1218,
								name: "Kibangu  ",
								sector_id: 10309,
								courtOfCells: [
									{
										id: 6991,
										name: "KIBANGU",
										generalAssembly_id: 1218,
									},
									{
										id: 6992,
										name: "RUBONA",
										generalAssembly_id: 1218,
									},
									{
										id: 6993,
										name: "SHYOKO",
										generalAssembly_id: 1218,
									},
									{
										id: 6994,
										name: "GITURAMIGINA",
										generalAssembly_id: 1218,
									},
									{
										id: 10192,
										name: "Kibangu   Court of Appeal ",
										generalAssembly_id: 1218,
									},
									{
										id: 11725,
										name: "Kibangu   Gacaca court of sector ",
										generalAssembly_id: 1218,
									},
								],
							},
							{
								id: 1219,
								name: "Fugi  ",
								sector_id: 10309,
								courtOfCells: [
									{
										id: 6995,
										name: "MUTAKWA",
										generalAssembly_id: 1219,
									},
									{
										id: 6996,
										name: "NTEKO",
										generalAssembly_id: 1219,
									},
									{
										id: 6997,
										name: "NYAMIRAMA",
										generalAssembly_id: 1219,
									},
									{
										id: 6998,
										name: "RULI",
										generalAssembly_id: 1219,
									},
									{
										id: 6999,
										name: "RUSHUBI",
										generalAssembly_id: 1219,
									},
									{
										id: 10193,
										name: "Fugi   Court of Appeal ",
										generalAssembly_id: 1219,
									},
									{
										id: 11726,
										name: "Fugi   Gacaca court of sector ",
										generalAssembly_id: 1219,
									},
								],
							},
							{
								id: 1220,
								name: "Kivuru  ",
								sector_id: 10309,
								courtOfCells: [
									{
										id: 7000,
										name: "AKABUYE",
										generalAssembly_id: 1220,
									},
									{
										id: 7001,
										name: "MBUYE",
										generalAssembly_id: 1220,
									},
									{
										id: 7002,
										name: "MUGOBE",
										generalAssembly_id: 1220,
									},
									{
										id: 7003,
										name: "MUJAHU",
										generalAssembly_id: 1220,
									},
									{
										id: 10194,
										name: "Kivuru   Court of Appeal ",
										generalAssembly_id: 1220,
									},
									{
										id: 11727,
										name: "Kivuru   Gacaca court of sector ",
										generalAssembly_id: 1220,
									},
								],
							},
							{
								id: 1221,
								name: "Ngoma  ",
								sector_id: 10309,
								courtOfCells: [
									{
										id: 7004,
										name: "GACUMBI",
										generalAssembly_id: 1221,
									},
									{
										id: 7005,
										name: "NYAKABANDA",
										generalAssembly_id: 1221,
									},
									{
										id: 7006,
										name: "RUHORORO",
										generalAssembly_id: 1221,
									},
									{
										id: 7007,
										name: "AGAHEHE",
										generalAssembly_id: 1221,
									},
									{
										id: 10195,
										name: "Ngoma   Court of Appeal ",
										generalAssembly_id: 1221,
									},
									{
										id: 11728,
										name: "Ngoma   Gacaca court of sector ",
										generalAssembly_id: 1221,
									},
								],
							},
						],
					},
					{
						id: 10310,
						name: "Nyabimata",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1031005,
								name: "Ruhinga",
								sector_id: 10310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031004,
								name: "Nyabimata",
								sector_id: 10310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031003,
								name: "Mishungero",
								sector_id: 10310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031002,
								name: "Kabere",
								sector_id: 10310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031001,
								name: "Gihemvu",
								sector_id: 10310,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1222,
								name: "Masunzu  ",
								sector_id: 10310,
								courtOfCells: [
									{
										id: 7008,
										name: "BIHEMBE",
										generalAssembly_id: 1222,
									},
									{
										id: 7009,
										name: "BUGINA",
										generalAssembly_id: 1222,
									},
									{
										id: 7010,
										name: "GIHEMVU",
										generalAssembly_id: 1222,
									},
									{
										id: 7011,
										name: "KABERE",
										generalAssembly_id: 1222,
									},
									{
										id: 7012,
										name: "NYARUNAZI",
										generalAssembly_id: 1222,
									},
									{
										id: 7013,
										name: "RUGARAMA",
										generalAssembly_id: 1222,
									},
									{
										id: 7014,
										name: "UWURUSUGI",
										generalAssembly_id: 1222,
									},
									{
										id: 10196,
										name: "Masunzu   Court of Appeal ",
										generalAssembly_id: 1222,
									},
									{
										id: 11729,
										name: "Masunzu   Gacaca court of sector ",
										generalAssembly_id: 1222,
									},
								],
							},
							{
								id: 1223,
								name: "Nyabimata  ",
								sector_id: 10310,
								courtOfCells: [
									{
										id: 7015,
										name: "AGASUGI",
										generalAssembly_id: 1223,
									},
									{
										id: 7016,
										name: "CYUMUZI",
										generalAssembly_id: 1223,
									},
									{
										id: 7017,
										name: "MURAMBI",
										generalAssembly_id: 1223,
									},
									{
										id: 7018,
										name: "MUTOBWE",
										generalAssembly_id: 1223,
									},
									{
										id: 7019,
										name: "NYABIMATA",
										generalAssembly_id: 1223,
									},
									{
										id: 7020,
										name: "RUHINGA",
										generalAssembly_id: 1223,
									},
									{
										id: 7021,
										name: "RWERERE",
										generalAssembly_id: 1223,
									},
									{
										id: 10197,
										name: "Nyabimata   Court of Appeal ",
										generalAssembly_id: 1223,
									},
									{
										id: 11730,
										name: "Nyabimata   Gacaca court of sector ",
										generalAssembly_id: 1223,
									},
								],
							},
							{
								id: 1224,
								name: "Rutiti  ",
								sector_id: 10310,
								courtOfCells: [
									{
										id: 7022,
										name: "MISHUNGERO",
										generalAssembly_id: 1224,
									},
									{
										id: 7023,
										name: "MUYIRA",
										generalAssembly_id: 1224,
									},
									{
										id: 7024,
										name: "NGARAMA",
										generalAssembly_id: 1224,
									},
									{
										id: 7025,
										name: "RUBINDI",
										generalAssembly_id: 1224,
									},
									{
										id: 7026,
										name: "UWARUHIGI",
										generalAssembly_id: 1224,
									},
									{
										id: 7027,
										name: "GIHONGORO",
										generalAssembly_id: 1224,
									},
									{
										id: 10198,
										name: "Rutiti   Court of Appeal ",
										generalAssembly_id: 1224,
									},
									{
										id: 11731,
										name: "Rutiti   Gacaca court of sector ",
										generalAssembly_id: 1224,
									},
								],
							},
						],
					},
					{
						id: 10311,
						name: "Nyagisozi",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1031104,
								name: "Nyagisozi",
								sector_id: 10311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031103,
								name: "Nkakwa",
								sector_id: 10311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031102,
								name: "Mwoya",
								sector_id: 10311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031101,
								name: "Maraba",
								sector_id: 10311,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1225,
								name: "Maraba  ",
								sector_id: 10311,
								courtOfCells: [
									{
										id: 7028,
										name: "BUGARAMA",
										generalAssembly_id: 1225,
									},
									{
										id: 7029,
										name: "MARABA",
										generalAssembly_id: 1225,
									},
									{
										id: 7030,
										name: "RUSHUNGURIRO",
										generalAssembly_id: 1225,
									},
									{
										id: 10199,
										name: "Maraba   Court of Appeal ",
										generalAssembly_id: 1225,
									},
									{
										id: 11732,
										name: "Maraba   Gacaca court of sector ",
										generalAssembly_id: 1225,
									},
								],
							},
							{
								id: 1226,
								name: "Mwoya ",
								sector_id: 10311,
								courtOfCells: [
									{
										id: 7031,
										name: "BWERANKORI",
										generalAssembly_id: 1226,
									},
									{
										id: 7032,
										name: "MUHOMBO",
										generalAssembly_id: 1226,
									},
									{
										id: 7033,
										name: "NKOMERO",
										generalAssembly_id: 1226,
									},
									{
										id: 7034,
										name: "NYAGASHUBI",
										generalAssembly_id: 1226,
									},
									{
										id: 10200,
										name: "Mwoya  Court of Appeal ",
										generalAssembly_id: 1226,
									},
									{
										id: 11733,
										name: "Mwoya  Gacaca court of sector ",
										generalAssembly_id: 1226,
									},
								],
							},
							{
								id: 1227,
								name: "Nkakwa  ",
								sector_id: 10311,
								courtOfCells: [
									{
										id: 7035,
										name: "KADUHA",
										generalAssembly_id: 1227,
									},
									{
										id: 7036,
										name: "NKAKWA",
										generalAssembly_id: 1227,
									},
									{
										id: 7037,
										name: "NYARUBUYE",
										generalAssembly_id: 1227,
									},
									{
										id: 10201,
										name: "Nkakwa   Court of Appeal ",
										generalAssembly_id: 1227,
									},
									{
										id: 11734,
										name: "Nkakwa   Gacaca court of sector ",
										generalAssembly_id: 1227,
									},
								],
							},
							{
								id: 1228,
								name: "Nyagisozi  ",
								sector_id: 10311,
								courtOfCells: [
									{
										id: 7038,
										name: "KARAMBA",
										generalAssembly_id: 1228,
									},
									{
										id: 7039,
										name: "KIBINGO",
										generalAssembly_id: 1228,
									},
									{
										id: 7040,
										name: "NYAGISOZI",
										generalAssembly_id: 1228,
									},
									{
										id: 10202,
										name: "Nyagisozi   Court of Appeal ",
										generalAssembly_id: 1228,
									},
									{
										id: 11735,
										name: "Nyagisozi   Gacaca court of sector ",
										generalAssembly_id: 1228,
									},
								],
							},
						],
					},
					{
						id: 10312,
						name: "Ruheru",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1031205,
								name: "Uwumusebeya",
								sector_id: 10312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031204,
								name: "Ruyenzi",
								sector_id: 10312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031203,
								name: "Remera",
								sector_id: 10312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031202,
								name: "Kabere",
								sector_id: 10312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031201,
								name: "Gitita",
								sector_id: 10312,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1229,
								name: "Gisanze  ",
								sector_id: 10312,
								courtOfCells: [
									{
										id: 7041,
										name: "GAKARANKA",
										generalAssembly_id: 1229,
									},
									{
										id: 7042,
										name: "RUKARAKARA",
										generalAssembly_id: 1229,
									},
									{
										id: 7043,
										name: "RUVURU",
										generalAssembly_id: 1229,
									},
									{
										id: 7044,
										name: "RUYENZI",
										generalAssembly_id: 1229,
									},
									{
										id: 7045,
										name: "TAMBANANGA",
										generalAssembly_id: 1229,
									},
									{
										id: 7046,
										name: "UWIMBOGO",
										generalAssembly_id: 1229,
									},
									{
										id: 7047,
										name: "UWUMUSEBEYA",
										generalAssembly_id: 1229,
									},
									{
										id: 7048,
										name: "ZIRAMBI",
										generalAssembly_id: 1229,
									},
									{
										id: 7049,
										name: "SHITI",
										generalAssembly_id: 1229,
									},
									{
										id: 10203,
										name: "Gisanze   Court of Appeal ",
										generalAssembly_id: 1229,
									},
									{
										id: 11736,
										name: "Gisanze   Gacaca court of sector ",
										generalAssembly_id: 1229,
									},
								],
							},
							{
								id: 1230,
								name: "Gitita  ",
								sector_id: 10312,
								courtOfCells: [
									{
										id: 7050,
										name: "KIBYIBUSHYE",
										generalAssembly_id: 1230,
									},
									{
										id: 7051,
										name: "MUDUHA",
										generalAssembly_id: 1230,
									},
									{
										id: 7052,
										name: "NYACYONGA",
										generalAssembly_id: 1230,
									},
									{
										id: 7053,
										name: "RUGANZA",
										generalAssembly_id: 1230,
									},
									{
										id: 7054,
										name: "RUHONGORE",
										generalAssembly_id: 1230,
									},
									{
										id: 7055,
										name: "RUSAGARA",
										generalAssembly_id: 1230,
									},
									{
										id: 7056,
										name: "RYANYARUJA",
										generalAssembly_id: 1230,
									},
									{
										id: 10204,
										name: "Gitita   Court of Appeal ",
										generalAssembly_id: 1230,
									},
									{
										id: 11737,
										name: "Gitita   Gacaca court of sector ",
										generalAssembly_id: 1230,
									},
								],
							},
							{
								id: 1231,
								name: "Remera  ",
								sector_id: 10312,
								courtOfCells: [
									{
										id: 7057,
										name: "GITWA",
										generalAssembly_id: 1231,
									},
									{
										id: 7058,
										name: "KIRWA",
										generalAssembly_id: 1231,
									},
									{
										id: 7059,
										name: "KIVUGIZA",
										generalAssembly_id: 1231,
									},
									{
										id: 7060,
										name: "MUBUGA",
										generalAssembly_id: 1231,
									},
									{
										id: 7061,
										name: "MUTUMBA",
										generalAssembly_id: 1231,
									},
									{
										id: 7062,
										name: "RUGOTE",
										generalAssembly_id: 1231,
									},
									{
										id: 7063,
										name: "UWINYANA",
										generalAssembly_id: 1231,
									},
									{
										id: 10205,
										name: "Remera   Court of Appeal ",
										generalAssembly_id: 1231,
									},
									{
										id: 11738,
										name: "Remera   Gacaca court of sector ",
										generalAssembly_id: 1231,
									},
								],
							},
							{
								id: 1232,
								name: "Ruheru ",
								sector_id: 10312,
								courtOfCells: [
									{
										id: 7064,
										name: "BUSENYI",
										generalAssembly_id: 1232,
									},
									{
										id: 7065,
										name: "GAMBIRIRO",
										generalAssembly_id: 1232,
									},
									{
										id: 7066,
										name: "MUKAKA",
										generalAssembly_id: 1232,
									},
									{
										id: 7067,
										name: "MURAMBI",
										generalAssembly_id: 1232,
									},
									{
										id: 7068,
										name: "NSHENYI",
										generalAssembly_id: 1232,
									},
									{
										id: 7069,
										name: "UWIGISURA",
										generalAssembly_id: 1232,
									},
									{
										id: 10206,
										name: "Ruheru  Court of Appeal ",
										generalAssembly_id: 1232,
									},
									{
										id: 11739,
										name: "Ruheru  Gacaca court of sector ",
										generalAssembly_id: 1232,
									},
								],
							},
						],
					},
					{
						id: 10313,
						name: "Ruramba",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1031305,
								name: "Ruramba",
								sector_id: 10313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031304,
								name: "Rugogwe",
								sector_id: 10313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031303,
								name: "Nyarugano",
								sector_id: 10313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031302,
								name: "Giseke",
								sector_id: 10313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031301,
								name: "Gabiro",
								sector_id: 10313,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1233,
								name: "Bukoro ",
								sector_id: 10313,
								courtOfCells: [
									{
										id: 7070,
										name: "BUKORO",
										generalAssembly_id: 1233,
									},
									{
										id: 7071,
										name: "GABIRO",
										generalAssembly_id: 1233,
									},
									{
										id: 7072,
										name: "KANSI",
										generalAssembly_id: 1233,
									},
									{
										id: 7073,
										name: "NYAGASHURUSHURU",
										generalAssembly_id: 1233,
									},
									{
										id: 7074,
										name: "NYAMIRAMBO",
										generalAssembly_id: 1233,
									},
									{
										id: 7075,
										name: "RUHANDE",
										generalAssembly_id: 1233,
									},
									{
										id: 7076,
										name: "RYAMUHUMBI",
										generalAssembly_id: 1233,
									},
									{
										id: 10207,
										name: "Bukoro  Court of Appeal ",
										generalAssembly_id: 1233,
									},
									{
										id: 11740,
										name: "Bukoro  Gacaca court of sector ",
										generalAssembly_id: 1233,
									},
								],
							},
							{
								id: 1234,
								name: "Gisorora ",
								sector_id: 10313,
								courtOfCells: [
									{
										id: 7077,
										name: "GISEKE",
										generalAssembly_id: 1234,
									},
									{
										id: 7078,
										name: "GISORORA",
										generalAssembly_id: 1234,
									},
									{
										id: 7079,
										name: "KINYONYO",
										generalAssembly_id: 1234,
									},
									{
										id: 7080,
										name: "NYARUGANO",
										generalAssembly_id: 1234,
									},
									{
										id: 7081,
										name: "URUYANGE",
										generalAssembly_id: 1234,
									},
									{
										id: 7082,
										name: "UWIMBOGO",
										generalAssembly_id: 1234,
									},
									{
										id: 10208,
										name: "Gisorora  Court of Appeal ",
										generalAssembly_id: 1234,
									},
									{
										id: 11741,
										name: "Gisorora  Gacaca court of sector ",
										generalAssembly_id: 1234,
									},
								],
							},
							{
								id: 1235,
								name: "Matyazo ",
								sector_id: 10313,
								courtOfCells: [
									{
										id: 7083,
										name: "KABALI",
										generalAssembly_id: 1235,
									},
									{
										id: 7084,
										name: "KIDOGO",
										generalAssembly_id: 1235,
									},
									{
										id: 7085,
										name: "MATYAZO",
										generalAssembly_id: 1235,
									},
									{
										id: 7086,
										name: "RUGOGWE",
										generalAssembly_id: 1235,
									},
									{
										id: 7087,
										name: "TITI",
										generalAssembly_id: 1235,
									},
									{
										id: 7088,
										name: "TUGOGO",
										generalAssembly_id: 1235,
									},
									{
										id: 10209,
										name: "Matyazo  Court of Appeal ",
										generalAssembly_id: 1235,
									},
									{
										id: 11742,
										name: "Matyazo  Gacaca court of sector ",
										generalAssembly_id: 1235,
									},
								],
							},
							{
								id: 1236,
								name: "Ruramba  ",
								sector_id: 10313,
								courtOfCells: [
									{
										id: 7089,
										name: "BUGIZI",
										generalAssembly_id: 1236,
									},
									{
										id: 7090,
										name: "GATWARO_NYARUSAZI",
										generalAssembly_id: 1236,
									},
									{
										id: 7091,
										name: "KIBALI_KARAMBI",
										generalAssembly_id: 1236,
									},
									{
										id: 7092,
										name: "KIMPUGA",
										generalAssembly_id: 1236,
									},
									{
										id: 7093,
										name: "RURAMBA",
										generalAssembly_id: 1236,
									},
									{
										id: 10210,
										name: "Ruramba   Court of Appeal ",
										generalAssembly_id: 1236,
									},
									{
										id: 11743,
										name: "Ruramba   Gacaca court of sector ",
										generalAssembly_id: 1236,
									},
								],
							},
						],
					},
					{
						id: 10314,
						name: "Rusenge",
						district_id: 103,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1031406,
								name: "Rusenge",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031405,
								name: "Raranzige",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031404,
								name: "Mariba",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031403,
								name: "Gikunzi",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031402,
								name: "Cyuna",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
							{
								id: 1031401,
								name: "Bunge",
								sector_id: 10314,
								createdAt: "2021-05-03T07:57:21.343Z",
								updatedAt: "2021-05-03T07:57:21.343Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1237,
								name: "Bunge ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7094,
										name: "BUNGE",
										generalAssembly_id: 1237,
									},
									{
										id: 7095,
										name: "JALI",
										generalAssembly_id: 1237,
									},
									{
										id: 7096,
										name: "NYANZOGA",
										generalAssembly_id: 1237,
									},
									{
										id: 7097,
										name: "TORANIRO",
										generalAssembly_id: 1237,
									},
									{
										id: 10211,
										name: "Bunge  Court of Appeal ",
										generalAssembly_id: 1237,
									},
									{
										id: 11744,
										name: "Bunge  Gacaca court of sector ",
										generalAssembly_id: 1237,
									},
								],
							},
							{
								id: 1238,
								name: "Cyuna ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7098,
										name: "CYUNA",
										generalAssembly_id: 1238,
									},
									{
										id: 7099,
										name: "REMERA",
										generalAssembly_id: 1238,
									},
									{
										id: 7100,
										name: "UWAMUHIZI",
										generalAssembly_id: 1238,
									},
									{
										id: 10212,
										name: "Cyuna  Court of Appeal ",
										generalAssembly_id: 1238,
									},
									{
										id: 11745,
										name: "Cyuna  Gacaca court of sector ",
										generalAssembly_id: 1238,
									},
								],
							},
							{
								id: 1239,
								name: "Gihango ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7101,
										name: "GIHANGO",
										generalAssembly_id: 1239,
									},
									{
										id: 7102,
										name: "KIRAMUTSE",
										generalAssembly_id: 1239,
									},
									{
										id: 7103,
										name: "MIKO",
										generalAssembly_id: 1239,
									},
									{
										id: 10213,
										name: "Gihango  Court of Appeal ",
										generalAssembly_id: 1239,
									},
									{
										id: 11746,
										name: "Gihango  Gacaca court of sector ",
										generalAssembly_id: 1239,
									},
								],
							},
							{
								id: 1240,
								name: "Gikunzi ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7104,
										name: "GIKUNZI",
										generalAssembly_id: 1240,
									},
									{
										id: 7105,
										name: "KIBU",
										generalAssembly_id: 1240,
									},
									{
										id: 7106,
										name: "MUNANIRA",
										generalAssembly_id: 1240,
									},
									{
										id: 10214,
										name: "Gikunzi  Court of Appeal ",
										generalAssembly_id: 1240,
									},
									{
										id: 11747,
										name: "Gikunzi  Gacaca court of sector ",
										generalAssembly_id: 1240,
									},
								],
							},
							{
								id: 1241,
								name: "Mariba  ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7107,
										name: "NTANDA",
										generalAssembly_id: 1241,
									},
									{
										id: 7108,
										name: "NYAMUGARI",
										generalAssembly_id: 1241,
									},
									{
										id: 7109,
										name: "RASANIRO",
										generalAssembly_id: 1241,
									},
									{
										id: 10215,
										name: "Mariba   Court of Appeal ",
										generalAssembly_id: 1241,
									},
									{
										id: 11748,
										name: "Mariba   Gacaca court of sector ",
										generalAssembly_id: 1241,
									},
								],
							},
							{
								id: 1242,
								name: "Rusenge  ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7110,
										name: "KABACUZI",
										generalAssembly_id: 1242,
									},
									{
										id: 7111,
										name: "KABUYE",
										generalAssembly_id: 1242,
									},
									{
										id: 7112,
										name: "KAVUMU",
										generalAssembly_id: 1242,
									},
									{
										id: 7113,
										name: "RUNYINYA",
										generalAssembly_id: 1242,
									},
									{
										id: 7114,
										name: "RUSENGE",
										generalAssembly_id: 1242,
									},
									{
										id: 10216,
										name: "Rusenge   Court of Appeal ",
										generalAssembly_id: 1242,
									},
									{
										id: 11749,
										name: "Rusenge   Gacaca court of sector ",
										generalAssembly_id: 1242,
									},
								],
							},
							{
								id: 1243,
								name: "Raranzige   ",
								sector_id: 10314,
								courtOfCells: [
									{
										id: 7115,
										name: "AKABACURA",
										generalAssembly_id: 1243,
									},
									{
										id: 7116,
										name: "GASAVE",
										generalAssembly_id: 1243,
									},
									{
										id: 7117,
										name: "KARIMBA",
										generalAssembly_id: 1243,
									},
									{
										id: 10217,
										name: "Raranzige    Court of Appeal ",
										generalAssembly_id: 1243,
									},
									{
										id: 11750,
										name: "Raranzige    Gacaca court of sector ",
										generalAssembly_id: 1243,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 106,
				name: "Ruhango",
				province_id: 1,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 10601,
						name: "Bweramana",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060105,
								name: "Rwinyana",
								sector_id: 10601,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060104,
								name: "Rubona",
								sector_id: 10601,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060103,
								name: "Murama",
								sector_id: 10601,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060102,
								name: "Gitisi",
								sector_id: 10601,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060101,
								name: "Buhanda",
								sector_id: 10601,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1287,
								name: "Gitisi ",
								sector_id: 10601,
								courtOfCells: [
									{
										id: 7408,
										name: "GITISI",
										generalAssembly_id: 1287,
									},
									{
										id: 7409,
										name: "NYAMARABA",
										generalAssembly_id: 1287,
									},
									{
										id: 7410,
										name: "NYARUGENGE",
										generalAssembly_id: 1287,
									},
									{
										id: 7411,
										name: "RUVUGIZO",
										generalAssembly_id: 1287,
									},
									{
										id: 10261,
										name: "Gitisi  Court of Appeal ",
										generalAssembly_id: 1287,
									},
									{
										id: 11794,
										name: "Gitisi  Gacaca court of sector ",
										generalAssembly_id: 1287,
									},
								],
							},
							{
								id: 1288,
								name: "Rubona ",
								sector_id: 10601,
								courtOfCells: [
									{
										id: 7412,
										name: "MASAMBU",
										generalAssembly_id: 1288,
									},
									{
										id: 7413,
										name: "NYAKABANDA",
										generalAssembly_id: 1288,
									},
									{
										id: 7414,
										name: "BIRAMBO",
										generalAssembly_id: 1288,
									},
									{
										id: 7415,
										name: "MUNYINYA",
										generalAssembly_id: 1288,
									},
									{
										id: 10262,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 1288,
									},
									{
										id: 11795,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 1288,
									},
								],
							},
							{
								id: 1289,
								name: "Bweramana ",
								sector_id: 10601,
								courtOfCells: [
									{
										id: 7416,
										name: "GASHARU",
										generalAssembly_id: 1289,
									},
									{
										id: 7417,
										name: "KARUTSINDO",
										generalAssembly_id: 1289,
									},
									{
										id: 7418,
										name: "KIGARAMA",
										generalAssembly_id: 1289,
									},
									{
										id: 7419,
										name: "KIVOMO",
										generalAssembly_id: 1289,
									},
									{
										id: 7420,
										name: "RWAVUNINGOMA",
										generalAssembly_id: 1289,
									},
									{
										id: 7421,
										name: "RWINGWE",
										generalAssembly_id: 1289,
									},
									{
										id: 10263,
										name: "Bweramana  Court of Appeal ",
										generalAssembly_id: 1289,
									},
									{
										id: 11796,
										name: "Bweramana  Gacaca court of sector ",
										generalAssembly_id: 1289,
									},
								],
							},
							{
								id: 1290,
								name: "Rugogwe ",
								sector_id: 10601,
								courtOfCells: [
									{
										id: 7422,
										name: "KIGURUBE",
										generalAssembly_id: 1290,
									},
									{
										id: 7423,
										name: "KUMUNYINYA",
										generalAssembly_id: 1290,
									},
									{
										id: 7424,
										name: "NYAGAKOMBE",
										generalAssembly_id: 1290,
									},
									{
										id: 7425,
										name: "NYAGITONGWE",
										generalAssembly_id: 1290,
									},
									{
										id: 7426,
										name: "NYARUBUYE",
										generalAssembly_id: 1290,
									},
									{
										id: 7427,
										name: "RWINYANA",
										generalAssembly_id: 1290,
									},
									{
										id: 7428,
										name: "SAMBA",
										generalAssembly_id: 1290,
									},
									{
										id: 10264,
										name: "Rugogwe  Court of Appeal ",
										generalAssembly_id: 1290,
									},
									{
										id: 11797,
										name: "Rugogwe  Gacaca court of sector ",
										generalAssembly_id: 1290,
									},
								],
							},
							{
								id: 1291,
								name: "Joma ",
								sector_id: 10601,
								courtOfCells: [
									{
										id: 7429,
										name: "BUGUFI",
										generalAssembly_id: 1291,
									},
									{
										id: 7430,
										name: "BUHANDA",
										generalAssembly_id: 1291,
									},
									{
										id: 7431,
										name: "GIKARABIRO",
										generalAssembly_id: 1291,
									},
									{
										id: 7432,
										name: "KABERE",
										generalAssembly_id: 1291,
									},
									{
										id: 7433,
										name: "KARAMBO",
										generalAssembly_id: 1291,
									},
									{
										id: 7434,
										name: "KAVUMU",
										generalAssembly_id: 1291,
									},
									{
										id: 7435,
										name: "RUTARABANA",
										generalAssembly_id: 1291,
									},
									{
										id: 10265,
										name: "Joma  Court of Appeal ",
										generalAssembly_id: 1291,
									},
									{
										id: 11798,
										name: "Joma  Gacaca court of sector ",
										generalAssembly_id: 1291,
									},
								],
							},
						],
					},
					{
						id: 10602,
						name: "Byimana",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060207,
								name: "Nyakabuye",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060206,
								name: "Ntenyo",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060205,
								name: "Muhororo",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060204,
								name: "Mpanda",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060203,
								name: "Mahembe",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060202,
								name: "Kirengeri",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060201,
								name: "Kamusenyi",
								sector_id: 10602,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1292,
								name: "Gitega ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7436,
										name: "BISIKA",
										generalAssembly_id: 1292,
									},
									{
										id: 7437,
										name: "GATWA",
										generalAssembly_id: 1292,
									},
									{
										id: 7438,
										name: "KIBANDE",
										generalAssembly_id: 1292,
									},
									{
										id: 7439,
										name: "MPANDA",
										generalAssembly_id: 1292,
									},
									{
										id: 7440,
										name: "NYABURONDWE",
										generalAssembly_id: 1292,
									},
									{
										id: 7441,
										name: "NYAGAHINGA",
										generalAssembly_id: 1292,
									},
									{
										id: 10266,
										name: "Gitega  Court of Appeal ",
										generalAssembly_id: 1292,
									},
									{
										id: 11799,
										name: "Gitega  Gacaca court of sector ",
										generalAssembly_id: 1292,
									},
								],
							},
							{
								id: 1293,
								name: "Kamusenyi ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7442,
										name: "GAKOMEYE",
										generalAssembly_id: 1293,
									},
									{
										id: 7443,
										name: "GASHARU",
										generalAssembly_id: 1293,
									},
									{
										id: 7444,
										name: "GITANGA",
										generalAssembly_id: 1293,
									},
									{
										id: 7445,
										name: "MAYEBE",
										generalAssembly_id: 1293,
									},
									{
										id: 7446,
										name: "NYAKABUNGO",
										generalAssembly_id: 1293,
									},
									{
										id: 7447,
										name: "NYARUSANGE",
										generalAssembly_id: 1293,
									},
									{
										id: 10267,
										name: "Kamusenyi  Court of Appeal ",
										generalAssembly_id: 1293,
									},
									{
										id: 11800,
										name: "Kamusenyi  Gacaca court of sector ",
										generalAssembly_id: 1293,
									},
								],
							},
							{
								id: 1294,
								name: "Mahembe ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7448,
										name: "KAMATONGO",
										generalAssembly_id: 1294,
									},
									{
										id: 7449,
										name: "KAVUMU",
										generalAssembly_id: 1294,
									},
									{
										id: 7450,
										name: "MUJYEJURU",
										generalAssembly_id: 1294,
									},
									{
										id: 7451,
										name: "MUTOBO",
										generalAssembly_id: 1294,
									},
									{
										id: 7452,
										name: "NYAGISOZI",
										generalAssembly_id: 1294,
									},
									{
										id: 10268,
										name: "Mahembe  Court of Appeal ",
										generalAssembly_id: 1294,
									},
									{
										id: 11801,
										name: "Mahembe  Gacaca court of sector ",
										generalAssembly_id: 1294,
									},
								],
							},
							{
								id: 1295,
								name: "Muhororo ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7453,
										name: "KARAMA",
										generalAssembly_id: 1295,
									},
									{
										id: 7454,
										name: "KARENGE",
										generalAssembly_id: 1295,
									},
									{
										id: 7455,
										name: "KIGARAMA",
										generalAssembly_id: 1295,
									},
									{
										id: 7456,
										name: "NYARUNYINYA",
										generalAssembly_id: 1295,
									},
									{
										id: 7457,
										name: "REMERA",
										generalAssembly_id: 1295,
									},
									{
										id: 7458,
										name: "RUKURO",
										generalAssembly_id: 1295,
									},
									{
										id: 10269,
										name: "Muhororo  Court of Appeal ",
										generalAssembly_id: 1295,
									},
									{
										id: 11802,
										name: "Muhororo  Gacaca court of sector ",
										generalAssembly_id: 1295,
									},
								],
							},
							{
								id: 1296,
								name: "Ntenyo ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7459,
										name: "KAGEYO",
										generalAssembly_id: 1296,
									},
									{
										id: 7460,
										name: "KAMURENZI",
										generalAssembly_id: 1296,
									},
									{
										id: 7461,
										name: "MUCUBI",
										generalAssembly_id: 1296,
									},
									{
										id: 7462,
										name: "NTENYO",
										generalAssembly_id: 1296,
									},
									{
										id: 7463,
										name: "RUKIRIZA",
										generalAssembly_id: 1296,
									},
									{
										id: 10270,
										name: "Ntenyo  Court of Appeal ",
										generalAssembly_id: 1296,
									},
									{
										id: 11803,
										name: "Ntenyo  Gacaca court of sector ",
										generalAssembly_id: 1296,
									},
								],
							},
							{
								id: 1297,
								name: "Nyagasozi ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7464,
										name: "GAHENGERI",
										generalAssembly_id: 1297,
									},
									{
										id: 7465,
										name: "GATOKI",
										generalAssembly_id: 1297,
									},
									{
										id: 7466,
										name: "KAMONYI",
										generalAssembly_id: 1297,
									},
									{
										id: 7467,
										name: "KIRENGELI",
										generalAssembly_id: 1297,
									},
									{
										id: 7468,
										name: "MASAKA",
										generalAssembly_id: 1297,
									},
									{
										id: 7469,
										name: "RUSORORO",
										generalAssembly_id: 1297,
									},
									{
										id: 10271,
										name: "Nyagasozi  Court of Appeal ",
										generalAssembly_id: 1297,
									},
									{
										id: 11804,
										name: "Nyagasozi  Gacaca court of sector ",
										generalAssembly_id: 1297,
									},
								],
							},
							{
								id: 1298,
								name: "Nyakabuye ",
								sector_id: 10602,
								courtOfCells: [
									{
										id: 7470,
										name: "GASASA",
										generalAssembly_id: 1298,
									},
									{
										id: 7471,
										name: "GATOBOTOBO",
										generalAssembly_id: 1298,
									},
									{
										id: 7472,
										name: "KIZIBAZIBA",
										generalAssembly_id: 1298,
									},
									{
										id: 7473,
										name: "NDAGO",
										generalAssembly_id: 1298,
									},
									{
										id: 7474,
										name: "NYARUBUMBIRO",
										generalAssembly_id: 1298,
									},
									{
										id: 7475,
										name: "NYARUTOVU",
										generalAssembly_id: 1298,
									},
									{
										id: 10272,
										name: "Nyakabuye  Court of Appeal ",
										generalAssembly_id: 1298,
									},
									{
										id: 11805,
										name: "Nyakabuye  Gacaca court of sector ",
										generalAssembly_id: 1298,
									},
								],
							},
						],
					},
					{
						id: 10603,
						name: "Kabagali",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060306,
								name: "Rwoga",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060305,
								name: "Rwesero",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060304,
								name: "Remera",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060303,
								name: "Munanira",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060302,
								name: "Karambi",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060301,
								name: "Bihembe",
								sector_id: 10603,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1299,
								name: "Karambi ",
								sector_id: 10603,
								courtOfCells: [
									{
										id: 7476,
										name: "BUGARAMANTARE",
										generalAssembly_id: 1299,
									},
									{
										id: 7477,
										name: "KAMUHIRWA",
										generalAssembly_id: 1299,
									},
									{
										id: 7478,
										name: "KARAMBI",
										generalAssembly_id: 1299,
									},
									{
										id: 7479,
										name: "KARURARA",
										generalAssembly_id: 1299,
									},
									{
										id: 7480,
										name: "MBUYE",
										generalAssembly_id: 1299,
									},
									{
										id: 7481,
										name: "MUHOZA",
										generalAssembly_id: 1299,
									},
									{
										id: 7482,
										name: "RAMBYANYANA",
										generalAssembly_id: 1299,
									},
									{
										id: 10273,
										name: "Karambi  Court of Appeal ",
										generalAssembly_id: 1299,
									},
									{
										id: 11806,
										name: "Karambi  Gacaca court of sector ",
										generalAssembly_id: 1299,
									},
								],
							},
							{
								id: 1300,
								name: "Munanira ",
								sector_id: 10603,
								courtOfCells: [
									{
										id: 7483,
										name: "BIHEMBE",
										generalAssembly_id: 1300,
									},
									{
										id: 7484,
										name: "KAVUMU",
										generalAssembly_id: 1300,
									},
									{
										id: 7485,
										name: "KIRWA",
										generalAssembly_id: 1300,
									},
									{
										id: 7486,
										name: "MUNANIRA",
										generalAssembly_id: 1300,
									},
									{
										id: 7487,
										name: "MUREMERA",
										generalAssembly_id: 1300,
									},
									{
										id: 7488,
										name: "NYAGATOVU",
										generalAssembly_id: 1300,
									},
									{
										id: 7489,
										name: "RUYOGORO",
										generalAssembly_id: 1300,
									},
									{
										id: 10274,
										name: "Munanira  Court of Appeal ",
										generalAssembly_id: 1300,
									},
									{
										id: 11807,
										name: "Munanira  Gacaca court of sector ",
										generalAssembly_id: 1300,
									},
								],
							},
							{
								id: 1301,
								name: "Rwankuba ",
								sector_id: 10603,
								courtOfCells: [
									{
										id: 7490,
										name: "BIRAMBO",
										generalAssembly_id: 1301,
									},
									{
										id: 7491,
										name: "BWANA",
										generalAssembly_id: 1301,
									},
									{
										id: 7492,
										name: "KABACUZI",
										generalAssembly_id: 1301,
									},
									{
										id: 7493,
										name: "KANYINYA",
										generalAssembly_id: 1301,
									},
									{
										id: 7494,
										name: "MISAMBIGIRO",
										generalAssembly_id: 1301,
									},
									{
										id: 7495,
										name: "MUHORORO",
										generalAssembly_id: 1301,
									},
									{
										id: 7496,
										name: "REMERA",
										generalAssembly_id: 1301,
									},
									{
										id: 7497,
										name: "RUHARE",
										generalAssembly_id: 1301,
									},
									{
										id: 10275,
										name: "Rwankuba  Court of Appeal ",
										generalAssembly_id: 1301,
									},
									{
										id: 11808,
										name: "Rwankuba  Gacaca court of sector ",
										generalAssembly_id: 1301,
									},
								],
							},
							{
								id: 1302,
								name: "Rwesero ",
								sector_id: 10603,
								courtOfCells: [
									{
										id: 7498,
										name: "BUGARAMANTARE",
										generalAssembly_id: 1302,
									},
									{
										id: 7499,
										name: "MAYEBE",
										generalAssembly_id: 1302,
									},
									{
										id: 7500,
										name: "MUSEKERA",
										generalAssembly_id: 1302,
									},
									{
										id: 7501,
										name: "NYABIVUMU",
										generalAssembly_id: 1302,
									},
									{
										id: 7502,
										name: "NYABYUNYU",
										generalAssembly_id: 1302,
									},
									{
										id: 7503,
										name: "REMERA",
										generalAssembly_id: 1302,
									},
									{
										id: 7504,
										name: "RWESERO",
										generalAssembly_id: 1302,
									},
									{
										id: 7505,
										name: "SERUGEME",
										generalAssembly_id: 1302,
									},
									{
										id: 10276,
										name: "Rwesero  Court of Appeal ",
										generalAssembly_id: 1302,
									},
									{
										id: 11809,
										name: "Rwesero  Gacaca court of sector ",
										generalAssembly_id: 1302,
									},
								],
							},
							{
								id: 1303,
								name: "Rwoga ",
								sector_id: 10603,
								courtOfCells: [
									{
										id: 7506,
										name: "GASHARU",
										generalAssembly_id: 1303,
									},
									{
										id: 7507,
										name: "GITWA",
										generalAssembly_id: 1303,
									},
									{
										id: 7508,
										name: "KANYINYA",
										generalAssembly_id: 1303,
									},
									{
										id: 7509,
										name: "KAVUMU",
										generalAssembly_id: 1303,
									},
									{
										id: 7510,
										name: "KIYANJA",
										generalAssembly_id: 1303,
									},
									{
										id: 7511,
										name: "NYARUSHISHI",
										generalAssembly_id: 1303,
									},
									{
										id: 7512,
										name: "RUSEBEYA",
										generalAssembly_id: 1303,
									},
									{
										id: 10277,
										name: "Rwoga  Court of Appeal ",
										generalAssembly_id: 1303,
									},
									{
										id: 11810,
										name: "Rwoga  Gacaca court of sector ",
										generalAssembly_id: 1303,
									},
								],
							},
						],
					},
					{
						id: 10604,
						name: "Kinazi",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060405,
								name: "Rutabo",
								sector_id: 10604,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060404,
								name: "Rubona",
								sector_id: 10604,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060403,
								name: "Kinazi",
								sector_id: 10604,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060402,
								name: "Gisali",
								sector_id: 10604,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060401,
								name: "Burima",
								sector_id: 10604,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1304,
								name: "Gisari ",
								sector_id: 10604,
								courtOfCells: [
									{
										id: 7513,
										name: "BUHANIKA",
										generalAssembly_id: 1304,
									},
									{
										id: 7514,
										name: "GISARE",
										generalAssembly_id: 1304,
									},
									{
										id: 7515,
										name: "KADUHA",
										generalAssembly_id: 1304,
									},
									{
										id: 7516,
										name: "KAKIRENZI",
										generalAssembly_id: 1304,
									},
									{
										id: 7517,
										name: "KIBANDA",
										generalAssembly_id: 1304,
									},
									{
										id: 7518,
										name: "MATARA",
										generalAssembly_id: 1304,
									},
									{
										id: 7519,
										name: "NYARUGENGE",
										generalAssembly_id: 1304,
									},
									{
										id: 7520,
										name: "NYARUTEJA",
										generalAssembly_id: 1304,
									},
									{
										id: 10278,
										name: "Gisari  Court of Appeal ",
										generalAssembly_id: 1304,
									},
									{
										id: 11811,
										name: "Gisari  Gacaca court of sector ",
										generalAssembly_id: 1304,
									},
								],
							},
							{
								id: 1305,
								name: "Kinazi ",
								sector_id: 10604,
								courtOfCells: [
									{
										id: 7521,
										name: "KAMABUYE",
										generalAssembly_id: 1305,
									},
									{
										id: 7522,
										name: "KARAMA",
										generalAssembly_id: 1305,
									},
									{
										id: 7523,
										name: "KINAZI",
										generalAssembly_id: 1305,
									},
									{
										id: 7524,
										name: "MIRAMBI",
										generalAssembly_id: 1305,
									},
									{
										id: 7525,
										name: "MPEMBA",
										generalAssembly_id: 1305,
									},
									{
										id: 7526,
										name: "NYABUSUNZU",
										generalAssembly_id: 1305,
									},
									{
										id: 7527,
										name: "NYAGAHAMA",
										generalAssembly_id: 1305,
									},
									{
										id: 7528,
										name: "NYARUGENGE",
										generalAssembly_id: 1305,
									},
									{
										id: 7529,
										name: "RUSUNGA",
										generalAssembly_id: 1305,
									},
									{
										id: 10279,
										name: "Kinazi  Court of Appeal ",
										generalAssembly_id: 1305,
									},
									{
										id: 11812,
										name: "Kinazi  Gacaca court of sector ",
										generalAssembly_id: 1305,
									},
								],
							},
							{
								id: 1306,
								name: "Rubona ",
								sector_id: 10604,
								courtOfCells: [
									{
										id: 7530,
										name: "GAFUMBA",
										generalAssembly_id: 1306,
									},
									{
										id: 7531,
										name: "GAKO",
										generalAssembly_id: 1306,
									},
									{
										id: 7532,
										name: "KAGAZI",
										generalAssembly_id: 1306,
									},
									{
										id: 7533,
										name: "RUBONA",
										generalAssembly_id: 1306,
									},
									{
										id: 7534,
										name: "SUSA",
										generalAssembly_id: 1306,
									},
									{
										id: 10280,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 1306,
									},
									{
										id: 11813,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 1306,
									},
								],
							},
							{
								id: 1307,
								name: "Rutabo ",
								sector_id: 10604,
								courtOfCells: [
									{
										id: 7535,
										name: "GITWA",
										generalAssembly_id: 1307,
									},
									{
										id: 7536,
										name: "KANKA",
										generalAssembly_id: 1307,
									},
									{
										id: 7537,
										name: "MUKOMA",
										generalAssembly_id: 1307,
									},
									{
										id: 7538,
										name: "NYARUGUNGA",
										generalAssembly_id: 1307,
									},
									{
										id: 7539,
										name: "RUNZENZE",
										generalAssembly_id: 1307,
									},
									{
										id: 7540,
										name: "RUTABO",
										generalAssembly_id: 1307,
									},
									{
										id: 10281,
										name: "Rutabo  Court of Appeal ",
										generalAssembly_id: 1307,
									},
									{
										id: 11814,
										name: "Rutabo  Gacaca court of sector ",
										generalAssembly_id: 1307,
									},
								],
							},
						],
					},
					{
						id: 10605,
						name: "Kinihira",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060506,
								name: "Rukina",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060505,
								name: "Nyakogo",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060504,
								name: "Muyunzwe",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060503,
								name: "Kirwa",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060502,
								name: "Gitinda",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060501,
								name: "Bweramvura",
								sector_id: 10605,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1308,
								name: "Gitinda ",
								sector_id: 10605,
								courtOfCells: [
									{
										id: 7541,
										name: "GASIZA",
										generalAssembly_id: 1308,
									},
									{
										id: 7542,
										name: "NYAGATOVU",
										generalAssembly_id: 1308,
									},
									{
										id: 7543,
										name: "NYAMAGANA",
										generalAssembly_id: 1308,
									},
									{
										id: 7544,
										name: "NYARUTOVU",
										generalAssembly_id: 1308,
									},
									{
										id: 7545,
										name: "REMERA",
										generalAssembly_id: 1308,
									},
									{
										id: 7546,
										name: "RUBONA",
										generalAssembly_id: 1308,
									},
									{
										id: 10282,
										name: "Gitinda  Court of Appeal ",
										generalAssembly_id: 1308,
									},
									{
										id: 11815,
										name: "Gitinda  Gacaca court of sector ",
										generalAssembly_id: 1308,
									},
								],
							},
							{
								id: 1309,
								name: "Kirwa ",
								sector_id: 10605,
								courtOfCells: [
									{
										id: 7547,
										name: "MURINZI-KABARESHYA",
										generalAssembly_id: 1309,
									},
									{
										id: 7548,
										name: "MUYANGE",
										generalAssembly_id: 1309,
									},
									{
										id: 7549,
										name: "NYARUBUYE",
										generalAssembly_id: 1309,
									},
									{
										id: 7550,
										name: "RUKERI",
										generalAssembly_id: 1309,
									},
									{
										id: 7551,
										name: "WIMANA",
										generalAssembly_id: 1309,
									},
									{
										id: 10283,
										name: "Kirwa  Court of Appeal ",
										generalAssembly_id: 1309,
									},
									{
										id: 11816,
										name: "Kirwa  Gacaca court of sector ",
										generalAssembly_id: 1309,
									},
								],
							},
							{
								id: 1310,
								name: "Muremure ",
								sector_id: 10605,
								courtOfCells: [
									{
										id: 7552,
										name: "BUGARURA",
										generalAssembly_id: 1310,
									},
									{
										id: 7553,
										name: "GAHORORO",
										generalAssembly_id: 1310,
									},
									{
										id: 7554,
										name: "GIHORORO",
										generalAssembly_id: 1310,
									},
									{
										id: 7555,
										name: "KABADENDE",
										generalAssembly_id: 1310,
									},
									{
										id: 7556,
										name: "KABASANZU",
										generalAssembly_id: 1310,
									},
									{
										id: 7557,
										name: "MUREMURE",
										generalAssembly_id: 1310,
									},
									{
										id: 7558,
										name: "NYABIVUMU",
										generalAssembly_id: 1310,
									},
									{
										id: 7559,
										name: "NYAGISENYI",
										generalAssembly_id: 1310,
									},
									{
										id: 10284,
										name: "Muremure  Court of Appeal ",
										generalAssembly_id: 1310,
									},
									{
										id: 11817,
										name: "Muremure  Gacaca court of sector ",
										generalAssembly_id: 1310,
									},
								],
							},
							{
								id: 1311,
								name: "Nyakogo ",
								sector_id: 10605,
								courtOfCells: [
									{
										id: 7560,
										name: "BUHANDA",
										generalAssembly_id: 1311,
									},
									{
										id: 7561,
										name: "BWERAMANA",
										generalAssembly_id: 1311,
									},
									{
										id: 7562,
										name: "GASHIRABWOBA",
										generalAssembly_id: 1311,
									},
									{
										id: 7563,
										name: "KABIRIZI",
										generalAssembly_id: 1311,
									},
									{
										id: 7564,
										name: "KABUGA",
										generalAssembly_id: 1311,
									},
									{
										id: 7565,
										name: "KIBIRIZI",
										generalAssembly_id: 1311,
									},
									{
										id: 7566,
										name: "RUSIZI",
										generalAssembly_id: 1311,
									},
									{
										id: 7567,
										name: "SHAMBA",
										generalAssembly_id: 1311,
									},
									{
										id: 7568,
										name: "SUNZU",
										generalAssembly_id: 1311,
									},
									{
										id: 10285,
										name: "Nyakogo  Court of Appeal ",
										generalAssembly_id: 1311,
									},
									{
										id: 11818,
										name: "Nyakogo  Gacaca court of sector ",
										generalAssembly_id: 1311,
									},
								],
							},
							{
								id: 1312,
								name: "Rukina ",
								sector_id: 10605,
								courtOfCells: [
									{
										id: 7569,
										name: "DUSENYI",
										generalAssembly_id: 1312,
									},
									{
										id: 7570,
										name: "KABACUZI",
										generalAssembly_id: 1312,
									},
									{
										id: 7571,
										name: "MONYIYERA",
										generalAssembly_id: 1312,
									},
									{
										id: 7572,
										name: "MUNINI",
										generalAssembly_id: 1312,
									},
									{
										id: 7573,
										name: "MUYUNZWE",
										generalAssembly_id: 1312,
									},
									{
										id: 7574,
										name: "NYARUBUMBIRO",
										generalAssembly_id: 1312,
									},
									{
										id: 7575,
										name: "TUBINDI",
										generalAssembly_id: 1312,
									},
									{
										id: 10286,
										name: "Rukina  Court of Appeal ",
										generalAssembly_id: 1312,
									},
									{
										id: 11819,
										name: "Rukina  Gacaca court of sector ",
										generalAssembly_id: 1312,
									},
								],
							},
						],
					},
					{
						id: 10606,
						name: "Mbuye",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060607,
								name: "Nyakarekare",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060606,
								name: "Mwendo",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060605,
								name: "Mbuye",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060604,
								name: "Kizibere",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060603,
								name: "Kabuga",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060602,
								name: "Gisanga",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060601,
								name: "Cyanza",
								sector_id: 10606,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1313,
								name: "Mayunzwe ",
								sector_id: 10606,
								courtOfCells: [
									{
										id: 7576,
										name: "GITICYUMA",
										generalAssembly_id: 1313,
									},
									{
										id: 7577,
										name: "KANGOMA",
										generalAssembly_id: 1313,
									},
									{
										id: 7578,
										name: "KIZIBERE",
										generalAssembly_id: 1313,
									},
									{
										id: 7579,
										name: "MATABA",
										generalAssembly_id: 1313,
									},
									{
										id: 7580,
										name: "MAYUNZWE",
										generalAssembly_id: 1313,
									},
									{
										id: 7581,
										name: "MWENDO",
										generalAssembly_id: 1313,
									},
									{
										id: 10287,
										name: "Mayunzwe  Court of Appeal ",
										generalAssembly_id: 1313,
									},
									{
										id: 11820,
										name: "Mayunzwe  Gacaca court of sector ",
										generalAssembly_id: 1313,
									},
								],
							},
							{
								id: 1314,
								name: "Mbuye ",
								sector_id: 10606,
								courtOfCells: [
									{
										id: 7582,
										name: "CYOBE",
										generalAssembly_id: 1314,
									},
									{
										id: 7583,
										name: "KIRWA",
										generalAssembly_id: 1314,
									},
									{
										id: 7584,
										name: "MBUYE",
										generalAssembly_id: 1314,
									},
									{
										id: 7585,
										name: "MPUNGWE",
										generalAssembly_id: 1314,
									},
									{
										id: 7586,
										name: "MUSENYI",
										generalAssembly_id: 1314,
									},
									{
										id: 7587,
										name: "NYAKABANDA",
										generalAssembly_id: 1314,
									},
									{
										id: 7588,
										name: "NYAKAREKARE",
										generalAssembly_id: 1314,
									},
									{
										id: 7589,
										name: "NYARUYONGA",
										generalAssembly_id: 1314,
									},
									{
										id: 7590,
										name: "RUBONA",
										generalAssembly_id: 1314,
									},
									{
										id: 7591,
										name: "RWIMPOSHYA",
										generalAssembly_id: 1314,
									},
									{
										id: 7592,
										name: "VUGIZA",
										generalAssembly_id: 1314,
									},
									{
										id: 10288,
										name: "Mbuye  Court of Appeal ",
										generalAssembly_id: 1314,
									},
									{
										id: 11821,
										name: "Mbuye  Gacaca court of sector ",
										generalAssembly_id: 1314,
									},
								],
							},
							{
								id: 1315,
								name: "Rukaza ",
								sector_id: 10606,
								courtOfCells: [
									{
										id: 7593,
										name: "CYANZA",
										generalAssembly_id: 1315,
									},
									{
										id: 7594,
										name: "GISANGA",
										generalAssembly_id: 1315,
									},
									{
										id: 7595,
										name: "GISHARI",
										generalAssembly_id: 1315,
									},
									{
										id: 7596,
										name: "KARAMA",
										generalAssembly_id: 1315,
									},
									{
										id: 7597,
										name: "MURAMBI",
										generalAssembly_id: 1315,
									},
									{
										id: 7598,
										name: "RWAMIKO",
										generalAssembly_id: 1315,
									},
									{
										id: 10289,
										name: "Rukaza  Court of Appeal ",
										generalAssembly_id: 1315,
									},
									{
										id: 11822,
										name: "Rukaza  Gacaca court of sector ",
										generalAssembly_id: 1315,
									},
								],
							},
						],
					},
					{
						id: 10607,
						name: "Mwendo",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060708,
								name: "Saruheshyi",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060707,
								name: "Nyabibugu",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060706,
								name: "Mutara",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060705,
								name: "Kubutare",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060704,
								name: "Kigarama",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060703,
								name: "Kamujisho",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060702,
								name: "Gishweru",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060701,
								name: "Gafunzo",
								sector_id: 10607,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1316,
								name: "Dusego ",
								sector_id: 10607,
								courtOfCells: [
									{
										id: 7599,
										name: "BUHANGWA",
										generalAssembly_id: 1316,
									},
									{
										id: 7600,
										name: "BUHORO",
										generalAssembly_id: 1316,
									},
									{
										id: 7601,
										name: "KAGARAMA",
										generalAssembly_id: 1316,
									},
									{
										id: 7602,
										name: "KAMUJISHO",
										generalAssembly_id: 1316,
									},
									{
										id: 7603,
										name: "KINGANZO",
										generalAssembly_id: 1316,
									},
									{
										id: 7604,
										name: "KIREHE",
										generalAssembly_id: 1316,
									},
									{
										id: 7605,
										name: "KUBUTARE",
										generalAssembly_id: 1316,
									},
									{
										id: 7606,
										name: "NYABISAMBU",
										generalAssembly_id: 1316,
									},
									{
										id: 10290,
										name: "Dusego  Court of Appeal ",
										generalAssembly_id: 1316,
									},
									{
										id: 11823,
										name: "Dusego  Gacaca court of sector ",
										generalAssembly_id: 1316,
									},
								],
							},
							{
								id: 1317,
								name: "Giseke ",
								sector_id: 10607,
								courtOfCells: [
									{
										id: 7607,
										name: "CYIVUGIZA",
										generalAssembly_id: 1317,
									},
									{
										id: 7608,
										name: "GISEKE",
										generalAssembly_id: 1317,
									},
									{
										id: 7609,
										name: "KABINGO",
										generalAssembly_id: 1317,
									},
									{
										id: 7610,
										name: "KIGARAMA",
										generalAssembly_id: 1317,
									},
									{
										id: 7611,
										name: "NYABIRURI",
										generalAssembly_id: 1317,
									},
									{
										id: 7612,
										name: "NYAKABUYE",
										generalAssembly_id: 1317,
									},
									{
										id: 7613,
										name: "NYAKIZU",
										generalAssembly_id: 1317,
									},
									{
										id: 7614,
										name: "NYARUSHUBI",
										generalAssembly_id: 1317,
									},
									{
										id: 10291,
										name: "Giseke  Court of Appeal ",
										generalAssembly_id: 1317,
									},
									{
										id: 11824,
										name: "Giseke  Gacaca court of sector ",
										generalAssembly_id: 1317,
									},
								],
							},
							{
								id: 1318,
								name: "Kimegeri ",
								sector_id: 10607,
								courtOfCells: [
									{
										id: 7615,
										name: "BUHIGIRO",
										generalAssembly_id: 1318,
									},
									{
										id: 7616,
										name: "GASEKE",
										generalAssembly_id: 1318,
									},
									{
										id: 7617,
										name: "GITICYUMA",
										generalAssembly_id: 1318,
									},
									{
										id: 7618,
										name: "NYARUBANDE",
										generalAssembly_id: 1318,
									},
									{
										id: 10292,
										name: "Kimegeri  Court of Appeal ",
										generalAssembly_id: 1318,
									},
									{
										id: 11825,
										name: "Kimegeri  Gacaca court of sector ",
										generalAssembly_id: 1318,
									},
								],
							},
							{
								id: 1319,
								name: "Ntongwe ",
								sector_id: 10607,
								courtOfCells: [
									{
										id: 7619,
										name: "GAKOMA",
										generalAssembly_id: 1319,
									},
									{
										id: 7620,
										name: "GASIZA",
										generalAssembly_id: 1319,
									},
									{
										id: 7621,
										name: "GATOKI",
										generalAssembly_id: 1319,
									},
									{
										id: 7622,
										name: "KAVUMU",
										generalAssembly_id: 1319,
									},
									{
										id: 7623,
										name: "KIGUFI",
										generalAssembly_id: 1319,
									},
									{
										id: 7624,
										name: "MPARE",
										generalAssembly_id: 1319,
									},
									{
										id: 7625,
										name: "NYABIBUGU",
										generalAssembly_id: 1319,
									},
									{
										id: 7626,
										name: "NYARUSANGE",
										generalAssembly_id: 1319,
									},
									{
										id: 10293,
										name: "Ntongwe  Court of Appeal ",
										generalAssembly_id: 1319,
									},
									{
										id: 11826,
										name: "Ntongwe  Gacaca court of sector ",
										generalAssembly_id: 1319,
									},
								],
							},
							{
								id: 1320,
								name: "Rutagara ",
								sector_id: 10607,
								courtOfCells: [
									{
										id: 7627,
										name: "BUHA",
										generalAssembly_id: 1320,
									},
									{
										id: 7628,
										name: "KIGARAMA",
										generalAssembly_id: 1320,
									},
									{
										id: 7629,
										name: "KIMBURU",
										generalAssembly_id: 1320,
									},
									{
										id: 7630,
										name: "NYAMIGINA",
										generalAssembly_id: 1320,
									},
									{
										id: 7631,
										name: "RUHAMAGARIRO",
										generalAssembly_id: 1320,
									},
									{
										id: 10294,
										name: "Rutagara  Court of Appeal ",
										generalAssembly_id: 1320,
									},
									{
										id: 11827,
										name: "Rutagara  Gacaca court of sector ",
										generalAssembly_id: 1320,
									},
								],
							},
						],
					},
					{
						id: 10608,
						name: "Ntongwe",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060807,
								name: "Nyarurama",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060806,
								name: "Nyakabungo",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060805,
								name: "Nyagisozi",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060804,
								name: "Kebero",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060803,
								name: "Kayenzi",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060802,
								name: "Kareba",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060801,
								name: "Gako",
								sector_id: 10608,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1321,
								name: "Ntongwe ",
								sector_id: 10608,
								courtOfCells: [
									{
										id: 7632,
										name: "CYERU",
										generalAssembly_id: 1321,
									},
									{
										id: 7633,
										name: "GASUNA",
										generalAssembly_id: 1321,
									},
									{
										id: 7634,
										name: "KANYANGE",
										generalAssembly_id: 1321,
									},
									{
										id: 7635,
										name: "KIDATURWA",
										generalAssembly_id: 1321,
									},
									{
										id: 7636,
										name: "NYACYONGA",
										generalAssembly_id: 1321,
									},
									{
										id: 7637,
										name: "RUKO",
										generalAssembly_id: 1321,
									},
									{
										id: 7638,
										name: "NYAKABUNGO",
										generalAssembly_id: 1321,
									},
									{
										id: 10295,
										name: "Ntongwe  Court of Appeal ",
										generalAssembly_id: 1321,
									},
									{
										id: 11828,
										name: "Ntongwe  Gacaca court of sector ",
										generalAssembly_id: 1321,
									},
								],
							},
							{
								id: 1322,
								name: "Nyabitare ",
								sector_id: 10608,
								courtOfCells: [
									{
										id: 7639,
										name: "KANYETE",
										generalAssembly_id: 1322,
									},
									{
										id: 7640,
										name: "KAYENZI",
										generalAssembly_id: 1322,
									},
									{
										id: 7641,
										name: "KIRWA",
										generalAssembly_id: 1322,
									},
									{
										id: 7642,
										name: "NTUNGAMO",
										generalAssembly_id: 1322,
									},
									{
										id: 7643,
										name: "NYABITARE",
										generalAssembly_id: 1322,
									},
									{
										id: 7644,
										name: "NYAMIGENDE",
										generalAssembly_id: 1322,
									},
									{
										id: 10296,
										name: "Nyabitare  Court of Appeal ",
										generalAssembly_id: 1322,
									},
									{
										id: 11829,
										name: "Nyabitare  Gacaca court of sector ",
										generalAssembly_id: 1322,
									},
								],
							},
							{
								id: 1323,
								name: "Kareba ",
								sector_id: 10608,
								courtOfCells: [
									{
										id: 7645,
										name: "KARAMA",
										generalAssembly_id: 1323,
									},
									{
										id: 7646,
										name: "KAVUMU",
										generalAssembly_id: 1323,
									},
									{
										id: 7647,
										name: "KIBATSI",
										generalAssembly_id: 1323,
									},
									{
										id: 7648,
										name: "MARIMBA",
										generalAssembly_id: 1323,
									},
									{
										id: 7649,
										name: "NYAGISOZI",
										generalAssembly_id: 1323,
									},
									{
										id: 7650,
										name: "NYAMIRAMA",
										generalAssembly_id: 1323,
									},
									{
										id: 7651,
										name: "RUKO",
										generalAssembly_id: 1323,
									},
									{
										id: 10297,
										name: "Kareba  Court of Appeal ",
										generalAssembly_id: 1323,
									},
									{
										id: 11830,
										name: "Kareba  Gacaca court of sector ",
										generalAssembly_id: 1323,
									},
								],
							},
							{
								id: 1324,
								name: "Nyakabungo ",
								sector_id: 10608,
								courtOfCells: [
									{
										id: 7652,
										name: "BYIMANA",
										generalAssembly_id: 1324,
									},
									{
										id: 7653,
										name: "GACURIRO",
										generalAssembly_id: 1324,
									},
									{
										id: 7654,
										name: "KARAMA",
										generalAssembly_id: 1324,
									},
									{
										id: 7655,
										name: "KARUNDO",
										generalAssembly_id: 1324,
									},
									{
										id: 7656,
										name: "MUTIMA",
										generalAssembly_id: 1324,
									},
									{
										id: 7657,
										name: "NYAKABUNGO",
										generalAssembly_id: 1324,
									},
									{
										id: 7658,
										name: "NYAMIRAMA",
										generalAssembly_id: 1324,
									},
									{
										id: 10298,
										name: "Nyakabungo  Court of Appeal ",
										generalAssembly_id: 1324,
									},
									{
										id: 11831,
										name: "Nyakabungo  Gacaca court of sector ",
										generalAssembly_id: 1324,
									},
								],
							},
							{
								id: 1325,
								name: "Nyarurama ",
								sector_id: 10608,
								courtOfCells: [
									{
										id: 7659,
										name: "GAKO",
										generalAssembly_id: 1325,
									},
									{
										id: 7660,
										name: "GIKOMA",
										generalAssembly_id: 1325,
									},
									{
										id: 7661,
										name: "GIKONI",
										generalAssembly_id: 1325,
									},
									{
										id: 7662,
										name: "KAMAKARA",
										generalAssembly_id: 1325,
									},
									{
										id: 7663,
										name: "MUKINDO",
										generalAssembly_id: 1325,
									},
									{
										id: 7664,
										name: "MUKONI",
										generalAssembly_id: 1325,
									},
									{
										id: 7665,
										name: "NYABUHUZU",
										generalAssembly_id: 1325,
									},
									{
										id: 7666,
										name: "NYAMAHWA",
										generalAssembly_id: 1325,
									},
									{
										id: 10299,
										name: "Nyarurama  Court of Appeal ",
										generalAssembly_id: 1325,
									},
									{
										id: 11832,
										name: "Nyarurama  Gacaca court of sector ",
										generalAssembly_id: 1325,
									},
								],
							},
						],
					},
					{
						id: 10609,
						name: "Ruhango",
						district_id: 106,
						createdAt: "2021-05-03T07:57:21.275Z",
						updatedAt: "2021-05-03T07:57:21.275Z",
						cells: [
							{
								id: 1060908,
								name: "Tambwe",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060907,
								name: "Rwoga",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060906,
								name: "Nyamagana",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060905,
								name: "Musamo",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060904,
								name: "Munini",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060903,
								name: "Gikoma",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060902,
								name: "Bunyogombe",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
							{
								id: 1060901,
								name: "Buhoro",
								sector_id: 10609,
								createdAt: "2021-05-03T07:57:21.344Z",
								updatedAt: "2021-05-03T07:57:21.344Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1326,
								name: "Bunyogombe ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7667,
										name: "BUGARURA",
										generalAssembly_id: 1326,
									},
									{
										id: 7668,
										name: "BUNYOGOMBE",
										generalAssembly_id: 1326,
									},
									{
										id: 7669,
										name: "BUSEGO",
										generalAssembly_id: 1326,
									},
									{
										id: 7670,
										name: "KAREHE",
										generalAssembly_id: 1326,
									},
									{
										id: 7671,
										name: "NYABISINDU",
										generalAssembly_id: 1326,
									},
									{
										id: 7672,
										name: "REMERA",
										generalAssembly_id: 1326,
									},
									{
										id: 10300,
										name: "Bunyogombe  Court of Appeal ",
										generalAssembly_id: 1326,
									},
									{
										id: 11833,
										name: "Bunyogombe  Gacaca court of sector ",
										generalAssembly_id: 1326,
									},
								],
							},
							{
								id: 1327,
								name: "Butare ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7673,
										name: "BUMBOGO",
										generalAssembly_id: 1327,
									},
									{
										id: 7674,
										name: "BUTARE",
										generalAssembly_id: 1327,
									},
									{
										id: 7675,
										name: "KABAMBATI",
										generalAssembly_id: 1327,
									},
									{
										id: 7676,
										name: "RUHANGO",
										generalAssembly_id: 1327,
									},
									{
										id: 10301,
										name: "Butare  Court of Appeal ",
										generalAssembly_id: 1327,
									},
									{
										id: 11834,
										name: "Butare  Gacaca court of sector ",
										generalAssembly_id: 1327,
									},
								],
							},
							{
								id: 1328,
								name: "Munini ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7677,
										name: "BUGALI",
										generalAssembly_id: 1328,
									},
									{
										id: 7678,
										name: "KANAZI",
										generalAssembly_id: 1328,
									},
									{
										id: 7679,
										name: "KIBINGO",
										generalAssembly_id: 1328,
									},
									{
										id: 7680,
										name: "MUNINI",
										generalAssembly_id: 1328,
									},
									{
										id: 7681,
										name: "MUREMERA",
										generalAssembly_id: 1328,
									},
									{
										id: 7682,
										name: "NYINYA",
										generalAssembly_id: 1328,
									},
									{
										id: 10302,
										name: "Munini  Court of Appeal ",
										generalAssembly_id: 1328,
									},
									{
										id: 11835,
										name: "Munini  Gacaca court of sector ",
										generalAssembly_id: 1328,
									},
								],
							},
							{
								id: 1329,
								name: "Rwoga ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7683,
										name: "BUSHENYI",
										generalAssembly_id: 1329,
									},
									{
										id: 7684,
										name: "KAVUMU",
										generalAssembly_id: 1329,
									},
									{
										id: 7685,
										name: "NYABISINDU",
										generalAssembly_id: 1329,
									},
									{
										id: 7686,
										name: "RWINKUBA",
										generalAssembly_id: 1329,
									},
									{
										id: 10303,
										name: "Rwoga  Court of Appeal ",
										generalAssembly_id: 1329,
									},
									{
										id: 11836,
										name: "Rwoga  Gacaca court of sector ",
										generalAssembly_id: 1329,
									},
								],
							},
							{
								id: 1330,
								name: "Gikoma ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7687,
										name: "GIKUMBA",
										generalAssembly_id: 1330,
									},
									{
										id: 7688,
										name: "KARAMA",
										generalAssembly_id: 1330,
									},
									{
										id: 7689,
										name: "MURAMBI",
										generalAssembly_id: 1330,
									},
									{
										id: 7690,
										name: "NYARUSANGE",
										generalAssembly_id: 1330,
									},
									{
										id: 7691,
										name: "RUBIHA",
										generalAssembly_id: 1330,
									},
									{
										id: 10304,
										name: "Gikoma  Court of Appeal ",
										generalAssembly_id: 1330,
									},
									{
										id: 11837,
										name: "Gikoma  Gacaca court of sector ",
										generalAssembly_id: 1330,
									},
								],
							},
							{
								id: 1331,
								name: "Buhoro ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7692,
										name: "BUHORO",
										generalAssembly_id: 1331,
									},
									{
										id: 7693,
										name: "MUHORORO_I",
										generalAssembly_id: 1331,
									},
									{
										id: 7694,
										name: "MUHORORO_II",
										generalAssembly_id: 1331,
									},
									{
										id: 7695,
										name: "NTINYINSHI",
										generalAssembly_id: 1331,
									},
									{
										id: 7696,
										name: "NYARUTOVU",
										generalAssembly_id: 1331,
									},
									{
										id: 10305,
										name: "Buhoro  Court of Appeal ",
										generalAssembly_id: 1331,
									},
									{
										id: 11838,
										name: "Buhoro  Gacaca court of sector ",
										generalAssembly_id: 1331,
									},
								],
							},
							{
								id: 1332,
								name: "Tambwe ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7697,
										name: "NYAMUGARI",
										generalAssembly_id: 1332,
									},
									{
										id: 7698,
										name: "RUDUHA",
										generalAssembly_id: 1332,
									},
									{
										id: 7699,
										name: "TAMBWE",
										generalAssembly_id: 1332,
									},
									{
										id: 10306,
										name: "Tambwe  Court of Appeal ",
										generalAssembly_id: 1332,
									},
									{
										id: 11839,
										name: "Tambwe  Gacaca court of sector ",
										generalAssembly_id: 1332,
									},
								],
							},
							{
								id: 1333,
								name: "Musamo ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7700,
										name: "CYINAMA",
										generalAssembly_id: 1333,
									},
									{
										id: 7701,
										name: "MUSAMO",
										generalAssembly_id: 1333,
									},
									{
										id: 7702,
										name: "MWARI",
										generalAssembly_id: 1333,
									},
									{
										id: 7703,
										name: "RWINKUBA",
										generalAssembly_id: 1333,
									},
									{
										id: 7704,
										name: "RWINYEGE",
										generalAssembly_id: 1333,
									},
									{
										id: 7705,
										name: "WIMANA",
										generalAssembly_id: 1333,
									},
									{
										id: 10307,
										name: "Musamo  Court of Appeal ",
										generalAssembly_id: 1333,
									},
									{
										id: 11840,
										name: "Musamo  Gacaca court of sector ",
										generalAssembly_id: 1333,
									},
								],
							},
							{
								id: 1334,
								name: "Nyamagana ",
								sector_id: 10609,
								courtOfCells: [
									{
										id: 7706,
										name: "NYABIHANGA",
										generalAssembly_id: 1334,
									},
									{
										id: 7707,
										name: "NYAMAGANA",
										generalAssembly_id: 1334,
									},
									{
										id: 7708,
										name: "NYARUSANGE",
										generalAssembly_id: 1334,
									},
									{
										id: 7709,
										name: "RUHANGO",
										generalAssembly_id: 1334,
									},
									{
										id: 10308,
										name: "Nyamagana  Court of Appeal ",
										generalAssembly_id: 1334,
									},
									{
										id: 11841,
										name: "Nyamagana  Gacaca court of sector ",
										generalAssembly_id: 1334,
									},
								],
							},
						],
					},
				],
			},
		],
	},
	{
		id: 2,
		name: "West",
		createdAt: "2021-05-03T07:57:21.181Z",
		updatedAt: "2021-05-03T07:57:21.181Z",
		districts: [
			{
				id: 201,
				name: "Karongi",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20101,
						name: "Bwishyura",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010107,
								name: "Nyarusazi",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010106,
								name: "Kiniha",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010105,
								name: "Kibuye",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010104,
								name: "Kayenzi",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010103,
								name: "Gitarama",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010102,
								name: "Gasura",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010101,
								name: "Burunga",
								sector_id: 20101,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 526,
								name: "Bwishyura  ",
								sector_id: 20101,
								courtOfCells: [
									{
										id: 2837,
										name: "BWISHYURA",
										generalAssembly_id: 526,
									},
									{
										id: 2838,
										name: "KARONGI",
										generalAssembly_id: 526,
									},
									{
										id: 2839,
										name: "KIBUYE",
										generalAssembly_id: 526,
									},
									{
										id: 2840,
										name: "KINIHA",
										generalAssembly_id: 526,
									},
									{
										id: 2841,
										name: "NYARUSAZI",
										generalAssembly_id: 526,
									},
									{
										id: 9501,
										name: "Bwishyura   Court of Appeal ",
										generalAssembly_id: 526,
									},
									{
										id: 11034,
										name: "Bwishyura   Gacaca court of sector ",
										generalAssembly_id: 526,
									},
								],
							},
							{
								id: 527,
								name: "Gitarama   ",
								sector_id: 20101,
								courtOfCells: [
									{
										id: 2842,
										name: "GITARAMA",
										generalAssembly_id: 527,
									},
									{
										id: 2843,
										name: "GOMBA",
										generalAssembly_id: 527,
									},
									{
										id: 2844,
										name: "JOSI",
										generalAssembly_id: 527,
									},
									{
										id: 2845,
										name: "KIGEZI",
										generalAssembly_id: 527,
									},
									{
										id: 9502,
										name: "Gitarama    Court of Appeal ",
										generalAssembly_id: 527,
									},
									{
										id: 11035,
										name: "Gitarama    Gacaca court of sector ",
										generalAssembly_id: 527,
									},
								],
							},
							{
								id: 528,
								name: "Burunga  ",
								sector_id: 20101,
								courtOfCells: [
									{
										id: 2846,
										name: "KABUGA",
										generalAssembly_id: 528,
									},
									{
										id: 2847,
										name: "MATYAZO",
										generalAssembly_id: 528,
									},
									{
										id: 2848,
										name: "NYABIKENKE",
										generalAssembly_id: 528,
									},
									{
										id: 2849,
										name: "NYAMAREBE",
										generalAssembly_id: 528,
									},
									{
										id: 2850,
										name: "RUYENZI",
										generalAssembly_id: 528,
									},
									{
										id: 2851,
										name: "TWIMBOGO",
										generalAssembly_id: 528,
									},
									{
										id: 9503,
										name: "Burunga   Court of Appeal ",
										generalAssembly_id: 528,
									},
									{
										id: 11036,
										name: "Burunga   Gacaca court of sector ",
										generalAssembly_id: 528,
									},
								],
							},
							{
								id: 529,
								name: "Kayenzi ",
								sector_id: 20101,
								courtOfCells: [
									{
										id: 2852,
										name: "KARORABEZA",
										generalAssembly_id: 529,
									},
									{
										id: 2853,
										name: "KAYENZI",
										generalAssembly_id: 529,
									},
									{
										id: 2854,
										name: "MWENDO",
										generalAssembly_id: 529,
									},
									{
										id: 2855,
										name: "RUHANDE",
										generalAssembly_id: 529,
									},
									{
										id: 9504,
										name: "Kayenzi  Court of Appeal ",
										generalAssembly_id: 529,
									},
									{
										id: 11037,
										name: "Kayenzi  Gacaca court of sector ",
										generalAssembly_id: 529,
									},
								],
							},
							{
								id: 530,
								name: "Ruragwe  ",
								sector_id: 20101,
								courtOfCells: [
									{
										id: 2856,
										name: "NYAGAHINGA",
										generalAssembly_id: 530,
									},
									{
										id: 2857,
										name: "NYAGASOZI",
										generalAssembly_id: 530,
									},
									{
										id: 2858,
										name: "NYAKABUNGO",
										generalAssembly_id: 530,
									},
									{
										id: 2859,
										name: "RUTABO",
										generalAssembly_id: 530,
									},
									{
										id: 2860,
										name: "RWIMPONGO",
										generalAssembly_id: 530,
									},
									{
										id: 9505,
										name: "Ruragwe   Court of Appeal ",
										generalAssembly_id: 530,
									},
									{
										id: 11038,
										name: "Ruragwe   Gacaca court of sector ",
										generalAssembly_id: 530,
									},
								],
							},
						],
					},
					{
						id: 20102,
						name: "Gashari",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010205,
								name: "Tongati",
								sector_id: 20102,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010204,
								name: "Rugobagoba",
								sector_id: 20102,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010203,
								name: "Mwendo",
								sector_id: 20102,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010202,
								name: "Musasa",
								sector_id: 20102,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010201,
								name: "Birambo",
								sector_id: 20102,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 531,
								name: "Gashari  ",
								sector_id: 20102,
								courtOfCells: [
									{
										id: 2861,
										name: "BIRAMBO",
										generalAssembly_id: 531,
									},
									{
										id: 2862,
										name: "GASHARI",
										generalAssembly_id: 531,
									},
									{
										id: 2863,
										name: "KABIRIZI",
										generalAssembly_id: 531,
									},
									{
										id: 2864,
										name: "KANANIRA",
										generalAssembly_id: 531,
									},
									{
										id: 2865,
										name: "NYABIKENKE",
										generalAssembly_id: 531,
									},
									{
										id: 2866,
										name: "NYABIVUMU",
										generalAssembly_id: 531,
									},
									{
										id: 2867,
										name: "NYARUSANGE",
										generalAssembly_id: 531,
									},
									{
										id: 2868,
										name: "RUBONA",
										generalAssembly_id: 531,
									},
									{
										id: 9506,
										name: "Gashari   Court of Appeal ",
										generalAssembly_id: 531,
									},
									{
										id: 11039,
										name: "Gashari   Gacaca court of sector ",
										generalAssembly_id: 531,
									},
								],
							},
							{
								id: 532,
								name: "Nganzo  ",
								sector_id: 20102,
								courtOfCells: [
									{
										id: 2869,
										name: "KARAMBO",
										generalAssembly_id: 532,
									},
									{
										id: 2870,
										name: "KIBINGO",
										generalAssembly_id: 532,
									},
									{
										id: 2871,
										name: "MURAMBA",
										generalAssembly_id: 532,
									},
									{
										id: 2872,
										name: "MUSONGATI",
										generalAssembly_id: 532,
									},
									{
										id: 2873,
										name: "NGANZO",
										generalAssembly_id: 532,
									},
									{
										id: 9507,
										name: "Nganzo   Court of Appeal ",
										generalAssembly_id: 532,
									},
									{
										id: 11040,
										name: "Nganzo   Gacaca court of sector ",
										generalAssembly_id: 532,
									},
								],
							},
							{
								id: 533,
								name: "Kigoma  ",
								sector_id: 20102,
								courtOfCells: [
									{
										id: 2874,
										name: "BIRENGA",
										generalAssembly_id: 533,
									},
									{
										id: 2875,
										name: "KAYONGA",
										generalAssembly_id: 533,
									},
									{
										id: 2876,
										name: "NYABITARE",
										generalAssembly_id: 533,
									},
									{
										id: 2877,
										name: "NYABIVUMU",
										generalAssembly_id: 533,
									},
									{
										id: 2878,
										name: "NYAGISOZI",
										generalAssembly_id: 533,
									},
									{
										id: 2879,
										name: "RWANKUBA",
										generalAssembly_id: 533,
									},
									{
										id: 9508,
										name: "Kigoma   Court of Appeal ",
										generalAssembly_id: 533,
									},
									{
										id: 11041,
										name: "Kigoma   Gacaca court of sector ",
										generalAssembly_id: 533,
									},
								],
							},
							{
								id: 534,
								name: "Musasa  ",
								sector_id: 20102,
								courtOfCells: [
									{
										id: 2880,
										name: "KABASARE",
										generalAssembly_id: 534,
									},
									{
										id: 2881,
										name: "KADUHA",
										generalAssembly_id: 534,
									},
									{
										id: 2882,
										name: "KAGANGARE",
										generalAssembly_id: 534,
									},
									{
										id: 2883,
										name: "MUSASA",
										generalAssembly_id: 534,
									},
									{
										id: 2884,
										name: "NTARABANA",
										generalAssembly_id: 534,
									},
									{
										id: 2885,
										name: "RASANIRO",
										generalAssembly_id: 534,
									},
									{
										id: 9509,
										name: "Musasa   Court of Appeal ",
										generalAssembly_id: 534,
									},
									{
										id: 11042,
										name: "Musasa   Gacaca court of sector ",
										generalAssembly_id: 534,
									},
								],
							},
							{
								id: 535,
								name: "Muhigi  ",
								sector_id: 20102,
								courtOfCells: [
									{
										id: 2886,
										name: "BIZITIRO",
										generalAssembly_id: 535,
									},
									{
										id: 2887,
										name: "KANYEGENYEGE",
										generalAssembly_id: 535,
									},
									{
										id: 2888,
										name: "KINYOVU",
										generalAssembly_id: 535,
									},
									{
										id: 2889,
										name: "NYABISIGA",
										generalAssembly_id: 535,
									},
									{
										id: 2890,
										name: "NYARUTEMBE",
										generalAssembly_id: 535,
									},
									{
										id: 2891,
										name: "RUGOBAGOBA",
										generalAssembly_id: 535,
									},
									{
										id: 9510,
										name: "Muhigi   Court of Appeal ",
										generalAssembly_id: 535,
									},
									{
										id: 11043,
										name: "Muhigi   Gacaca court of sector ",
										generalAssembly_id: 535,
									},
								],
							},
						],
					},
					{
						id: 20103,
						name: "Gishyita",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010306,
								name: "Ngoma",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010305,
								name: "Musasa",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010304,
								name: "Munanira",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010303,
								name: "Kigarama",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010302,
								name: "Cyanya",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010301,
								name: "Buhoro",
								sector_id: 20103,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 536,
								name: "Ngoma ",
								sector_id: 20103,
								courtOfCells: [
									{
										id: 2892,
										name: "KAGANO_KIGARAMA",
										generalAssembly_id: 536,
									},
									{
										id: 2893,
										name: "KARENGE",
										generalAssembly_id: 536,
									},
									{
										id: 2894,
										name: "MAGARAMA",
										generalAssembly_id: 536,
									},
									{
										id: 2895,
										name: "RYARUTAMBARA",
										generalAssembly_id: 536,
									},
									{
										id: 2896,
										name: "UWINGABO",
										generalAssembly_id: 536,
									},
									{
										id: 2897,
										name: "KAMARIBA",
										generalAssembly_id: 536,
									},
									{
										id: 2898,
										name: "MIKINGO",
										generalAssembly_id: 536,
									},
									{
										id: 9511,
										name: "Ngoma  Court of Appeal ",
										generalAssembly_id: 536,
									},
									{
										id: 11044,
										name: "Ngoma  Gacaca court of sector ",
										generalAssembly_id: 536,
									},
								],
							},
							{
								id: 537,
								name: "Gishyita  ",
								sector_id: 20103,
								courtOfCells: [
									{
										id: 2899,
										name: "BUGINA_KAGANO",
										generalAssembly_id: 537,
									},
									{
										id: 2900,
										name: "CYANYA_GITOVU",
										generalAssembly_id: 537,
									},
									{
										id: 2901,
										name: "MPATSI_NGANZO",
										generalAssembly_id: 537,
									},
									{
										id: 2902,
										name: "RUTUNA",
										generalAssembly_id: 537,
									},
									{
										id: 2903,
										name: "RWARAMBA",
										generalAssembly_id: 537,
									},
									{
										id: 9512,
										name: "Gishyita   Court of Appeal ",
										generalAssembly_id: 537,
									},
									{
										id: 11045,
										name: "Gishyita   Gacaca court of sector ",
										generalAssembly_id: 537,
									},
								],
							},
							{
								id: 538,
								name: "Musenyi  ",
								sector_id: 20103,
								courtOfCells: [
									{
										id: 2904,
										name: "CYIKA",
										generalAssembly_id: 538,
									},
									{
										id: 2905,
										name: "MUSEBEYA",
										generalAssembly_id: 538,
									},
									{
										id: 2906,
										name: "KARAMA",
										generalAssembly_id: 538,
									},
									{
										id: 2907,
										name: "NYAMABUYE",
										generalAssembly_id: 538,
									},
									{
										id: 2908,
										name: "MUSASA",
										generalAssembly_id: 538,
									},
									{
										id: 2909,
										name: "RWABIREMBO_GASHARU",
										generalAssembly_id: 538,
									},
									{
										id: 9513,
										name: "Musenyi   Court of Appeal ",
										generalAssembly_id: 538,
									},
									{
										id: 11046,
										name: "Musenyi   Gacaca court of sector ",
										generalAssembly_id: 538,
									},
								],
							},
							{
								id: 539,
								name: "Mpembe  ",
								sector_id: 20103,
								courtOfCells: [
									{
										id: 2910,
										name: "BUHORO",
										generalAssembly_id: 539,
									},
									{
										id: 2911,
										name: "GISORO",
										generalAssembly_id: 539,
									},
									{
										id: 2912,
										name: "MBONEKO",
										generalAssembly_id: 539,
									},
									{
										id: 2913,
										name: "MUNANIRA_NGUGU",
										generalAssembly_id: 539,
									},
									{
										id: 2914,
										name: "MWEYA",
										generalAssembly_id: 539,
									},
									{
										id: 2915,
										name: "NYAKABUYE",
										generalAssembly_id: 539,
									},
									{
										id: 2916,
										name: "RUHUNDE",
										generalAssembly_id: 539,
									},
									{
										id: 9514,
										name: "Mpembe   Court of Appeal ",
										generalAssembly_id: 539,
									},
									{
										id: 11047,
										name: "Mpembe   Gacaca court of sector ",
										generalAssembly_id: 539,
									},
								],
							},
						],
					},
					{
						id: 20104,
						name: "Gitesi",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010408,
								name: "Rwariro",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010407,
								name: "Ruhinga",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010406,
								name: "Nyamiringa",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010405,
								name: "Munanira",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010404,
								name: "Kirambo",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010403,
								name: "Kanunga",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010402,
								name: "Gitega",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010401,
								name: "Gasharu",
								sector_id: 20104,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 540,
								name: "Gitesi  ",
								sector_id: 20104,
								courtOfCells: [
									{
										id: 2917,
										name: "GASHARU_GAHIGIRO",
										generalAssembly_id: 540,
									},
									{
										id: 2918,
										name: "GITICYUMA",
										generalAssembly_id: 540,
									},
									{
										id: 2919,
										name: "MUNANIRA",
										generalAssembly_id: 540,
									},
									{
										id: 2920,
										name: "MURAMBA",
										generalAssembly_id: 540,
									},
									{
										id: 2921,
										name: "NEMBA",
										generalAssembly_id: 540,
									},
									{
										id: 2922,
										name: "NYABIGUGU",
										generalAssembly_id: 540,
									},
									{
										id: 2923,
										name: "NYAGISOZI",
										generalAssembly_id: 540,
									},
									{
										id: 2924,
										name: "RUHUNDO",
										generalAssembly_id: 540,
									},
									{
										id: 9515,
										name: "Gitesi   Court of Appeal ",
										generalAssembly_id: 540,
									},
									{
										id: 11048,
										name: "Gitesi   Gacaca court of sector ",
										generalAssembly_id: 540,
									},
								],
							},
							{
								id: 541,
								name: "Buye  ",
								sector_id: 20104,
								courtOfCells: [
									{
										id: 2925,
										name: "BUREGA",
										generalAssembly_id: 541,
									},
									{
										id: 2926,
										name: "BUYE",
										generalAssembly_id: 541,
									},
									{
										id: 2927,
										name: "CYIMBA",
										generalAssembly_id: 541,
									},
									{
										id: 2928,
										name: "KIRAMBO",
										generalAssembly_id: 541,
									},
									{
										id: 2929,
										name: "NYARUSANGE",
										generalAssembly_id: 541,
									},
									{
										id: 2930,
										name: "NZABUHARA",
										generalAssembly_id: 541,
									},
									{
										id: 2931,
										name: "RWINTARE",
										generalAssembly_id: 541,
									},
									{
										id: 9516,
										name: "Buye   Court of Appeal ",
										generalAssembly_id: 541,
									},
									{
										id: 11049,
										name: "Buye   Gacaca court of sector ",
										generalAssembly_id: 541,
									},
								],
							},
							{
								id: 542,
								name: "Kagunga  ",
								sector_id: 20104,
								courtOfCells: [
									{
										id: 2932,
										name: "GASHARU",
										generalAssembly_id: 542,
									},
									{
										id: 2933,
										name: "RWIRU",
										generalAssembly_id: 542,
									},
									{
										id: 2934,
										name: "KIGARAMA",
										generalAssembly_id: 542,
									},
									{
										id: 2935,
										name: "KINYAMI",
										generalAssembly_id: 542,
									},
									{
										id: 2936,
										name: "NYARUVUMU",
										generalAssembly_id: 542,
									},
									{
										id: 2937,
										name: "RURUMBU",
										generalAssembly_id: 542,
									},
									{
										id: 2938,
										name: "RWARIRO",
										generalAssembly_id: 542,
									},
									{
										id: 9517,
										name: "Kagunga   Court of Appeal ",
										generalAssembly_id: 542,
									},
									{
										id: 11050,
										name: "Kagunga   Gacaca court of sector ",
										generalAssembly_id: 542,
									},
								],
							},
							{
								id: 543,
								name: "Ruhinga ",
								sector_id: 20104,
								courtOfCells: [
									{
										id: 2939,
										name: "GASAYO",
										generalAssembly_id: 543,
									},
									{
										id: 2940,
										name: "GITEGA",
										generalAssembly_id: 543,
									},
									{
										id: 2941,
										name: "KAGARI",
										generalAssembly_id: 543,
									},
									{
										id: 2942,
										name: "MUVUNGU",
										generalAssembly_id: 543,
									},
									{
										id: 2943,
										name: "NYAGAHINGA",
										generalAssembly_id: 543,
									},
									{
										id: 2944,
										name: "NYARUBUYE",
										generalAssembly_id: 543,
									},
									{
										id: 2945,
										name: "RUHONDO",
										generalAssembly_id: 543,
									},
									{
										id: 9518,
										name: "Ruhinga  Court of Appeal ",
										generalAssembly_id: 543,
									},
									{
										id: 11051,
										name: "Ruhinga  Gacaca court of sector ",
										generalAssembly_id: 543,
									},
								],
							},
						],
					},
					{
						id: 20105,
						name: "Mubuga",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010504,
								name: "Ryaruhanga",
								sector_id: 20105,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010503,
								name: "Nyagatovu",
								sector_id: 20105,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010502,
								name: "Murangara",
								sector_id: 20105,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010501,
								name: "Kagabiro",
								sector_id: 20105,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 544,
								name: "Gasura  ",
								sector_id: 20105,
								courtOfCells: [
									{
										id: 2946,
										name: "GAFURUGUTO",
										generalAssembly_id: 544,
									},
									{
										id: 2947,
										name: "GATOKI",
										generalAssembly_id: 544,
									},
									{
										id: 2948,
										name: "NYABIHANGA",
										generalAssembly_id: 544,
									},
									{
										id: 2949,
										name: "NYAGAHINGA",
										generalAssembly_id: 544,
									},
									{
										id: 2950,
										name: "RUGANDA",
										generalAssembly_id: 544,
									},
									{
										id: 9519,
										name: "Gasura   Court of Appeal ",
										generalAssembly_id: 544,
									},
									{
										id: 11052,
										name: "Gasura   Gacaca court of sector ",
										generalAssembly_id: 544,
									},
								],
							},
							{
								id: 545,
								name: "Mubuga  ",
								sector_id: 20105,
								courtOfCells: [
									{
										id: 2951,
										name: "BIKENKE",
										generalAssembly_id: 545,
									},
									{
										id: 2952,
										name: "GIHIRA",
										generalAssembly_id: 545,
									},
									{
										id: 2953,
										name: "JURWE",
										generalAssembly_id: 545,
									},
									{
										id: 2954,
										name: "KIZIBAZIBA",
										generalAssembly_id: 545,
									},
									{
										id: 2955,
										name: "MUBUGA",
										generalAssembly_id: 545,
									},
									{
										id: 2956,
										name: "RWAMIKO",
										generalAssembly_id: 545,
									},
									{
										id: 2957,
										name: "RYARUHANGA",
										generalAssembly_id: 545,
									},
									{
										id: 9520,
										name: "Mubuga   Court of Appeal ",
										generalAssembly_id: 545,
									},
									{
										id: 11053,
										name: "Mubuga   Gacaca court of sector ",
										generalAssembly_id: 545,
									},
								],
							},
							{
								id: 546,
								name: "Mara  ",
								sector_id: 20105,
								courtOfCells: [
									{
										id: 2958,
										name: "BIKOMERO",
										generalAssembly_id: 546,
									},
									{
										id: 2959,
										name: "GISIZI_KARORA",
										generalAssembly_id: 546,
									},
									{
										id: 2960,
										name: "MARA_NYAGATOVU",
										generalAssembly_id: 546,
									},
									{
										id: 2961,
										name: "NYANKIRA",
										generalAssembly_id: 546,
									},
									{
										id: 9521,
										name: "Mara   Court of Appeal ",
										generalAssembly_id: 546,
									},
									{
										id: 11054,
										name: "Mara   Gacaca court of sector ",
										generalAssembly_id: 546,
									},
								],
							},
							{
								id: 547,
								name: "Murangara  ",
								sector_id: 20105,
								courtOfCells: [
									{
										id: 2962,
										name: "NYABITARE_GISUNZU",
										generalAssembly_id: 547,
									},
									{
										id: 2963,
										name: "KADUHA",
										generalAssembly_id: 547,
									},
									{
										id: 2964,
										name: "KARORA",
										generalAssembly_id: 547,
									},
									{
										id: 2965,
										name: "MURANGARA",
										generalAssembly_id: 547,
									},
									{
										id: 2966,
										name: "RUBYIRO_RWAKAMURI",
										generalAssembly_id: 547,
									},
									{
										id: 9522,
										name: "Murangara   Court of Appeal ",
										generalAssembly_id: 547,
									},
									{
										id: 11055,
										name: "Murangara   Gacaca court of sector ",
										generalAssembly_id: 547,
									},
								],
							},
							{
								id: 548,
								name: "Kagabiro  ",
								sector_id: 20105,
								courtOfCells: [
									{
										id: 2967,
										name: "BUHARI",
										generalAssembly_id: 548,
									},
									{
										id: 2968,
										name: "KAGABIRO",
										generalAssembly_id: 548,
									},
									{
										id: 2969,
										name: "KAGARAMA",
										generalAssembly_id: 548,
									},
									{
										id: 2970,
										name: "NYABINYENGA",
										generalAssembly_id: 548,
									},
									{
										id: 2971,
										name: "NYAKAGEZI",
										generalAssembly_id: 548,
									},
									{
										id: 2972,
										name: "NZOGAMWEYA",
										generalAssembly_id: 548,
									},
									{
										id: 2973,
										name: "NZOGA_RUNYINYA",
										generalAssembly_id: 548,
									},
									{
										id: 2974,
										name: "RUBONDO",
										generalAssembly_id: 548,
									},
									{
										id: 9523,
										name: "Kagabiro   Court of Appeal ",
										generalAssembly_id: 548,
									},
									{
										id: 11056,
										name: "Kagabiro   Gacaca court of sector ",
										generalAssembly_id: 548,
									},
								],
							},
						],
					},
					{
						id: 20106,
						name: "Murambi",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010605,
								name: "Shyembe",
								sector_id: 20106,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010604,
								name: "Nyarunyinya",
								sector_id: 20106,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010603,
								name: "Nkoto",
								sector_id: 20106,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010602,
								name: "Muhororo",
								sector_id: 20106,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010601,
								name: "Mubuga",
								sector_id: 20106,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 549,
								name: "Murambi  ",
								sector_id: 20106,
								courtOfCells: [
									{
										id: 2975,
										name: "GITUNTU",
										generalAssembly_id: 549,
									},
									{
										id: 2976,
										name: "KAMASAMBU",
										generalAssembly_id: 549,
									},
									{
										id: 2977,
										name: "KARAMBO_KANANIRA",
										generalAssembly_id: 549,
									},
									{
										id: 2978,
										name: "KIGANDARO",
										generalAssembly_id: 549,
									},
									{
										id: 2979,
										name: "MURAMBI",
										generalAssembly_id: 549,
									},
									{
										id: 2980,
										name: "NYARUNYINYA",
										generalAssembly_id: 549,
									},
									{
										id: 9524,
										name: "Murambi   Court of Appeal ",
										generalAssembly_id: 549,
									},
									{
										id: 11057,
										name: "Murambi   Gacaca court of sector ",
										generalAssembly_id: 549,
									},
								],
							},
							{
								id: 550,
								name: "Shyembe  ",
								sector_id: 20106,
								courtOfCells: [
									{
										id: 2981,
										name: "BUGARAMANTARE",
										generalAssembly_id: 550,
									},
									{
										id: 2982,
										name: "KARORERO",
										generalAssembly_id: 550,
									},
									{
										id: 2983,
										name: "NYAMAGANA",
										generalAssembly_id: 550,
									},
									{
										id: 2984,
										name: "NYAMUGARI",
										generalAssembly_id: 550,
									},
									{
										id: 2985,
										name: "SHYEMBE",
										generalAssembly_id: 550,
									},
									{
										id: 9525,
										name: "Shyembe   Court of Appeal ",
										generalAssembly_id: 550,
									},
									{
										id: 11058,
										name: "Shyembe   Gacaca court of sector ",
										generalAssembly_id: 550,
									},
								],
							},
							{
								id: 551,
								name: "Cyamatare ",
								sector_id: 20106,
								courtOfCells: [
									{
										id: 2986,
										name: "CYAMATARE",
										generalAssembly_id: 551,
									},
									{
										id: 2987,
										name: "KAZIBAZIBA",
										generalAssembly_id: 551,
									},
									{
										id: 2988,
										name: "MIGINA",
										generalAssembly_id: 551,
									},
									{
										id: 2989,
										name: "MUBUGA",
										generalAssembly_id: 551,
									},
									{
										id: 2990,
										name: "MUHORORO",
										generalAssembly_id: 551,
									},
									{
										id: 2991,
										name: "NDAGO",
										generalAssembly_id: 551,
									},
									{
										id: 2992,
										name: "NYABWOMA",
										generalAssembly_id: 551,
									},
									{
										id: 9526,
										name: "Cyamatare  Court of Appeal ",
										generalAssembly_id: 551,
									},
									{
										id: 11059,
										name: "Cyamatare  Gacaca court of sector ",
										generalAssembly_id: 551,
									},
								],
							},
							{
								id: 552,
								name: "Nyabiranga  ",
								sector_id: 20106,
								courtOfCells: [
									{
										id: 2993,
										name: "BIRAMBO",
										generalAssembly_id: 552,
									},
									{
										id: 2994,
										name: "GAKOMA",
										generalAssembly_id: 552,
									},
									{
										id: 2995,
										name: "KAKIRINDA",
										generalAssembly_id: 552,
									},
									{
										id: 2996,
										name: "KIBAMBA",
										generalAssembly_id: 552,
									},
									{
										id: 2997,
										name: "MURAMBA",
										generalAssembly_id: 552,
									},
									{
										id: 2998,
										name: "MURONDWE",
										generalAssembly_id: 552,
									},
									{
										id: 2999,
										name: "NYABIRANGA",
										generalAssembly_id: 552,
									},
									{
										id: 3000,
										name: "NYAKABUYE",
										generalAssembly_id: 552,
									},
									{
										id: 9527,
										name: "Nyabiranga   Court of Appeal ",
										generalAssembly_id: 552,
									},
									{
										id: 11060,
										name: "Nyabiranga   Gacaca court of sector ",
										generalAssembly_id: 552,
									},
								],
							},
						],
					},
					{
						id: 20107,
						name: "Murundi",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010706,
								name: "Nzaratsi",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010705,
								name: "Nyamushishi",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010704,
								name: "Kareba",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010703,
								name: "Kamina",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010702,
								name: "Kabaya",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010701,
								name: "Bukiro",
								sector_id: 20107,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 553,
								name: "Murundi  ",
								sector_id: 20107,
								courtOfCells: [
									{
										id: 3001,
										name: "BWENDA",
										generalAssembly_id: 553,
									},
									{
										id: 3002,
										name: "GASAVE",
										generalAssembly_id: 553,
									},
									{
										id: 3003,
										name: "GISEBEYA",
										generalAssembly_id: 553,
									},
									{
										id: 3004,
										name: "KIBINGO_A",
										generalAssembly_id: 553,
									},
									{
										id: 3005,
										name: "KIBINGO_B",
										generalAssembly_id: 553,
									},
									{
										id: 3006,
										name: "MURUNDI",
										generalAssembly_id: 553,
									},
									{
										id: 9528,
										name: "Murundi   Court of Appeal ",
										generalAssembly_id: 553,
									},
									{
										id: 11061,
										name: "Murundi   Gacaca court of sector ",
										generalAssembly_id: 553,
									},
								],
							},
							{
								id: 554,
								name: "Cyanyanza  ",
								sector_id: 20107,
								courtOfCells: [
									{
										id: 3007,
										name: "KAYENZI",
										generalAssembly_id: 554,
									},
									{
										id: 3008,
										name: "MUJYOJYO",
										generalAssembly_id: 554,
									},
									{
										id: 3009,
										name: "MWUMBA",
										generalAssembly_id: 554,
									},
									{
										id: 3010,
										name: "NKUMBA",
										generalAssembly_id: 554,
									},
									{
										id: 3011,
										name: "NYABISINDU",
										generalAssembly_id: 554,
									},
									{
										id: 3012,
										name: "RUHUNGAMIYAGA",
										generalAssembly_id: 554,
									},
									{
										id: 9529,
										name: "Cyanyanza   Court of Appeal ",
										generalAssembly_id: 554,
									},
									{
										id: 11062,
										name: "Cyanyanza   Gacaca court of sector ",
										generalAssembly_id: 554,
									},
								],
							},
							{
								id: 555,
								name: "Ngoma ",
								sector_id: 20107,
								courtOfCells: [
									{
										id: 3013,
										name: "MUDUHA",
										generalAssembly_id: 555,
									},
									{
										id: 3014,
										name: "NGANZO",
										generalAssembly_id: 555,
									},
									{
										id: 3015,
										name: "NGOMA_A",
										generalAssembly_id: 555,
									},
									{
										id: 3016,
										name: "NGOMA_B",
										generalAssembly_id: 555,
									},
									{
										id: 3017,
										name: "NYAGIHAMBA",
										generalAssembly_id: 555,
									},
									{
										id: 3018,
										name: "NYAMYUMBA",
										generalAssembly_id: 555,
									},
									{
										id: 9530,
										name: "Ngoma  Court of Appeal ",
										generalAssembly_id: 555,
									},
									{
										id: 11063,
										name: "Ngoma  Gacaca court of sector ",
										generalAssembly_id: 555,
									},
								],
							},
							{
								id: 556,
								name: "Nyabinombe  ",
								sector_id: 20107,
								courtOfCells: [
									{
										id: 3019,
										name: "GITIMBA",
										generalAssembly_id: 556,
									},
									{
										id: 3020,
										name: "MIGINA",
										generalAssembly_id: 556,
									},
									{
										id: 3021,
										name: "NYABINOMBE",
										generalAssembly_id: 556,
									},
									{
										id: 3022,
										name: "NYARUYEGE",
										generalAssembly_id: 556,
									},
									{
										id: 3023,
										name: "NZARATSI",
										generalAssembly_id: 556,
									},
									{
										id: 3024,
										name: "RUMARAMPAMBA",
										generalAssembly_id: 556,
									},
									{
										id: 9531,
										name: "Nyabinombe   Court of Appeal ",
										generalAssembly_id: 556,
									},
									{
										id: 11064,
										name: "Nyabinombe   Gacaca court of sector ",
										generalAssembly_id: 556,
									},
								],
							},
							{
								id: 557,
								name: "Sanza  ",
								sector_id: 20107,
								courtOfCells: [
									{
										id: 3025,
										name: "KIRARO",
										generalAssembly_id: 557,
									},
									{
										id: 3026,
										name: "KIREHE",
										generalAssembly_id: 557,
									},
									{
										id: 3027,
										name: "KISENGE",
										generalAssembly_id: 557,
									},
									{
										id: 3028,
										name: "MUREHE",
										generalAssembly_id: 557,
									},
									{
										id: 3029,
										name: "MWUNGUZI",
										generalAssembly_id: 557,
									},
									{
										id: 3030,
										name: "NYAKARAMBI",
										generalAssembly_id: 557,
									},
									{
										id: 3031,
										name: "REMERA",
										generalAssembly_id: 557,
									},
									{
										id: 3032,
										name: "RUBONA",
										generalAssembly_id: 557,
									},
									{
										id: 9532,
										name: "Sanza   Court of Appeal ",
										generalAssembly_id: 557,
									},
									{
										id: 11065,
										name: "Sanza   Gacaca court of sector ",
										generalAssembly_id: 557,
									},
								],
							},
						],
					},
					{
						id: 20108,
						name: "Mutuntu",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010807,
								name: "Rwufi",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010806,
								name: "Murengezo",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010805,
								name: "Kinyonzwe",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010804,
								name: "Kanyege",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010803,
								name: "Gisayura",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010802,
								name: "Gasharu",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010801,
								name: "Byogo",
								sector_id: 20108,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 558,
								name: "Mutuntu ",
								sector_id: 20108,
								courtOfCells: [
									{
										id: 3033,
										name: "GASHANGA",
										generalAssembly_id: 558,
									},
									{
										id: 3034,
										name: "GASHARU",
										generalAssembly_id: 558,
									},
									{
										id: 3035,
										name: "GITITI",
										generalAssembly_id: 558,
									},
									{
										id: 3036,
										name: "MUTUNTU",
										generalAssembly_id: 558,
									},
									{
										id: 3037,
										name: "NYABIGURI",
										generalAssembly_id: 558,
									},
									{
										id: 9533,
										name: "Mutuntu  Court of Appeal ",
										generalAssembly_id: 558,
									},
									{
										id: 11066,
										name: "Mutuntu  Gacaca court of sector ",
										generalAssembly_id: 558,
									},
								],
							},
							{
								id: 559,
								name: "Kanyege  ",
								sector_id: 20108,
								courtOfCells: [
									{
										id: 3038,
										name: "GIHUNGA",
										generalAssembly_id: 559,
									},
									{
										id: 3039,
										name: "KAGEYO",
										generalAssembly_id: 559,
									},
									{
										id: 3040,
										name: "KANYEGE",
										generalAssembly_id: 559,
									},
									{
										id: 3041,
										name: "KAVUMU",
										generalAssembly_id: 559,
									},
									{
										id: 3042,
										name: "MANJI",
										generalAssembly_id: 559,
									},
									{
										id: 3043,
										name: "NYARUBUYE",
										generalAssembly_id: 559,
									},
									{
										id: 9534,
										name: "Kanyege   Court of Appeal ",
										generalAssembly_id: 559,
									},
									{
										id: 11067,
										name: "Kanyege   Gacaca court of sector ",
										generalAssembly_id: 559,
									},
								],
							},
							{
								id: 560,
								name: "Rucura   ",
								sector_id: 20108,
								courtOfCells: [
									{
										id: 3044,
										name: "KAZANA",
										generalAssembly_id: 560,
									},
									{
										id: 3045,
										name: "KABYOGO",
										generalAssembly_id: 560,
									},
									{
										id: 3046,
										name: "MURENGEZO",
										generalAssembly_id: 560,
									},
									{
										id: 3047,
										name: "MUSANGO",
										generalAssembly_id: 560,
									},
									{
										id: 3048,
										name: "WIMBUGA",
										generalAssembly_id: 560,
									},
									{
										id: 3049,
										name: "NGUGU",
										generalAssembly_id: 560,
									},
									{
										id: 3050,
										name: "NYARUSANGE",
										generalAssembly_id: 560,
									},
									{
										id: 9535,
										name: "Rucura    Court of Appeal ",
										generalAssembly_id: 560,
									},
									{
										id: 11068,
										name: "Rucura    Gacaca court of sector ",
										generalAssembly_id: 560,
									},
								],
							},
							{
								id: 561,
								name: "Rwufi  ",
								sector_id: 20108,
								courtOfCells: [
									{
										id: 3051,
										name: "CYUNYU",
										generalAssembly_id: 561,
									},
									{
										id: 3052,
										name: "KABERE",
										generalAssembly_id: 561,
									},
									{
										id: 3053,
										name: "KADEHERO",
										generalAssembly_id: 561,
									},
									{
										id: 3054,
										name: "KINYONZWE",
										generalAssembly_id: 561,
									},
									{
										id: 3055,
										name: "MASUMO",
										generalAssembly_id: 561,
									},
									{
										id: 3056,
										name: "MUKIRA",
										generalAssembly_id: 561,
									},
									{
										id: 3057,
										name: "NGESO",
										generalAssembly_id: 561,
									},
									{
										id: 3058,
										name: "NYAMUTUKURA",
										generalAssembly_id: 561,
									},
									{
										id: 3059,
										name: "NYARUKERI",
										generalAssembly_id: 561,
									},
									{
										id: 3060,
										name: "RWUFI",
										generalAssembly_id: 561,
									},
									{
										id: 9536,
										name: "Rwufi   Court of Appeal ",
										generalAssembly_id: 561,
									},
									{
										id: 11069,
										name: "Rwufi   Gacaca court of sector ",
										generalAssembly_id: 561,
									},
								],
							},
							{
								id: 562,
								name: "Gikaranka  ",
								sector_id: 20108,
								courtOfCells: [
									{
										id: 3061,
										name: "BIHUMBE",
										generalAssembly_id: 562,
									},
									{
										id: 3062,
										name: "BIVUMU",
										generalAssembly_id: 562,
									},
									{
										id: 3063,
										name: "DUHATI",
										generalAssembly_id: 562,
									},
									{
										id: 3064,
										name: "GIKARANKA",
										generalAssembly_id: 562,
									},
									{
										id: 3065,
										name: "NYABUBARE",
										generalAssembly_id: 562,
									},
									{
										id: 3066,
										name: "UWINTOBO",
										generalAssembly_id: 562,
									},
									{
										id: 9537,
										name: "Gikaranka   Court of Appeal ",
										generalAssembly_id: 562,
									},
									{
										id: 11070,
										name: "Gikaranka   Gacaca court of sector ",
										generalAssembly_id: 562,
									},
								],
							},
						],
					},
					{
						id: 20109,
						name: "Rubengera",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2010908,
								name: "Ruragwe",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010907,
								name: "Nyarugenge",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010906,
								name: "Mataba",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010905,
								name: "Kibirizi",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010904,
								name: "Gitwa",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010903,
								name: "Gisanze",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010902,
								name: "Gacaca",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2010901,
								name: "Bubazi",
								sector_id: 20109,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 563,
								name: "Rubengera  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3067,
										name: "KABAHIZI",
										generalAssembly_id: 563,
									},
									{
										id: 3068,
										name: "KABATARA",
										generalAssembly_id: 563,
									},
									{
										id: 3069,
										name: "KIBANDE",
										generalAssembly_id: 563,
									},
									{
										id: 3070,
										name: "KIGABIRO",
										generalAssembly_id: 563,
									},
									{
										id: 3071,
										name: "MUFUMBEZI",
										generalAssembly_id: 563,
									},
									{
										id: 3072,
										name: "RUVUMBU",
										generalAssembly_id: 563,
									},
									{
										id: 3073,
										name: "GISANZE",
										generalAssembly_id: 563,
									},
									{
										id: 9538,
										name: "Rubengera   Court of Appeal ",
										generalAssembly_id: 563,
									},
									{
										id: 11071,
										name: "Rubengera   Gacaca court of sector ",
										generalAssembly_id: 563,
									},
								],
							},
							{
								id: 564,
								name: "Bubazi  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3074,
										name: "KABUGA",
										generalAssembly_id: 564,
									},
									{
										id: 3075,
										name: "KAVUMU",
										generalAssembly_id: 564,
									},
									{
										id: 3076,
										name: "KIGARAMA",
										generalAssembly_id: 564,
									},
									{
										id: 3077,
										name: "NYAGAHINGA",
										generalAssembly_id: 564,
									},
									{
										id: 9539,
										name: "Bubazi   Court of Appeal ",
										generalAssembly_id: 564,
									},
									{
										id: 11072,
										name: "Bubazi   Gacaca court of sector ",
										generalAssembly_id: 564,
									},
								],
							},
							{
								id: 565,
								name: "Gacaca  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3078,
										name: "GAKOMEYE_TWARIZO",
										generalAssembly_id: 565,
									},
									{
										id: 3079,
										name: "GASHARU",
										generalAssembly_id: 565,
									},
									{
										id: 3080,
										name: "KAMUVUNYI",
										generalAssembly_id: 565,
									},
									{
										id: 3081,
										name: "KAREHE",
										generalAssembly_id: 565,
									},
									{
										id: 3082,
										name: "REMERA_NYARUBUYE",
										generalAssembly_id: 565,
									},
									{
										id: 9540,
										name: "Gacaca   Court of Appeal ",
										generalAssembly_id: 565,
									},
									{
										id: 11073,
										name: "Gacaca   Gacaca court of sector ",
										generalAssembly_id: 565,
									},
								],
							},
							{
								id: 566,
								name: "Kibirizi  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3083,
										name: "CYIMANA",
										generalAssembly_id: 566,
									},
									{
										id: 3084,
										name: "KAGARAMA",
										generalAssembly_id: 566,
									},
									{
										id: 3085,
										name: "KAMUSANGANYA",
										generalAssembly_id: 566,
									},
									{
										id: 3086,
										name: "KIMIGENGE",
										generalAssembly_id: 566,
									},
									{
										id: 3087,
										name: "NDENGWA",
										generalAssembly_id: 566,
									},
									{
										id: 3088,
										name: "RUBONA",
										generalAssembly_id: 566,
									},
									{
										id: 9541,
										name: "Kibirizi   Court of Appeal ",
										generalAssembly_id: 566,
									},
									{
										id: 11074,
										name: "Kibirizi   Gacaca court of sector ",
										generalAssembly_id: 566,
									},
								],
							},
							{
								id: 567,
								name: "Nyarugenge  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3089,
										name: "GASERUZI",
										generalAssembly_id: 567,
									},
									{
										id: 3090,
										name: "KAMBOGO",
										generalAssembly_id: 567,
									},
									{
										id: 3091,
										name: "KARUSHA",
										generalAssembly_id: 567,
									},
									{
										id: 3092,
										name: "NKOMAGURWA",
										generalAssembly_id: 567,
									},
									{
										id: 3093,
										name: "RUKARAGATA",
										generalAssembly_id: 567,
									},
									{
										id: 9542,
										name: "Nyarugenge   Court of Appeal ",
										generalAssembly_id: 567,
									},
									{
										id: 11075,
										name: "Nyarugenge   Gacaca court of sector ",
										generalAssembly_id: 567,
									},
								],
							},
							{
								id: 568,
								name: "Gitwa  ",
								sector_id: 20109,
								courtOfCells: [
									{
										id: 3094,
										name: "BIRAMBI",
										generalAssembly_id: 568,
									},
									{
										id: 3095,
										name: "BUDERIDERI",
										generalAssembly_id: 568,
									},
									{
										id: 3096,
										name: "GASEKE",
										generalAssembly_id: 568,
									},
									{
										id: 3097,
										name: "GITEGA",
										generalAssembly_id: 568,
									},
									{
										id: 3098,
										name: "KIBANDE",
										generalAssembly_id: 568,
									},
									{
										id: 3099,
										name: "MUREMERA",
										generalAssembly_id: 568,
									},
									{
										id: 3100,
										name: "RWAKIGARATI",
										generalAssembly_id: 568,
									},
									{
										id: 9543,
										name: "Gitwa   Court of Appeal ",
										generalAssembly_id: 568,
									},
									{
										id: 11076,
										name: "Gitwa   Gacaca court of sector ",
										generalAssembly_id: 568,
									},
								],
							},
						],
					},
					{
						id: 20110,
						name: "Rugabano",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2011009,
								name: "Tyazo",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011008,
								name: "Rwungo",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011007,
								name: "Rufungo",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011006,
								name: "Mucyimba",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011005,
								name: "Mubuga",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011004,
								name: "Kabuga",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011003,
								name: "Gitovu",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011002,
								name: "Gitega",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011001,
								name: "Gisiza",
								sector_id: 20110,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 569,
								name: "Rukoko  ",
								sector_id: 20110,
								courtOfCells: [
									{
										id: 3101,
										name: "BITENGA",
										generalAssembly_id: 569,
									},
									{
										id: 3102,
										name: "CYATO",
										generalAssembly_id: 569,
									},
									{
										id: 3103,
										name: "GITABI",
										generalAssembly_id: 569,
									},
									{
										id: 3104,
										name: "RUKOKO",
										generalAssembly_id: 569,
									},
									{
										id: 9544,
										name: "Rukoko   Court of Appeal ",
										generalAssembly_id: 569,
									},
									{
										id: 11077,
										name: "Rukoko   Gacaca court of sector ",
										generalAssembly_id: 569,
									},
								],
							},
							{
								id: 570,
								name: "Mugunda  ",
								sector_id: 20110,
								courtOfCells: [
									{
										id: 3105,
										name: "BISUSA",
										generalAssembly_id: 570,
									},
									{
										id: 3106,
										name: "KAREHE_I",
										generalAssembly_id: 570,
									},
									{
										id: 3107,
										name: "KAREHE_II",
										generalAssembly_id: 570,
									},
									{
										id: 3108,
										name: "KAREHE_III",
										generalAssembly_id: 570,
									},
									{
										id: 3109,
										name: "KAVUMU",
										generalAssembly_id: 570,
									},
									{
										id: 3110,
										name: "RUGABE",
										generalAssembly_id: 570,
									},
									{
										id: 3111,
										name: "RWAZA",
										generalAssembly_id: 570,
									},
									{
										id: 9545,
										name: "Mugunda   Court of Appeal ",
										generalAssembly_id: 570,
									},
									{
										id: 11078,
										name: "Mugunda   Gacaca court of sector ",
										generalAssembly_id: 570,
									},
								],
							},
							{
								id: 571,
								name: "Rusengesi  ",
								sector_id: 20110,
								courtOfCells: [
									{
										id: 3112,
										name: "GAHENGERI",
										generalAssembly_id: 571,
									},
									{
										id: 3113,
										name: "GASHARU",
										generalAssembly_id: 571,
									},
									{
										id: 3114,
										name: "KABUYE",
										generalAssembly_id: 571,
									},
									{
										id: 3115,
										name: "KIRABO",
										generalAssembly_id: 571,
									},
									{
										id: 3116,
										name: "RUBATURA",
										generalAssembly_id: 571,
									},
									{
										id: 3117,
										name: "RUGABANO",
										generalAssembly_id: 571,
									},
									{
										id: 3118,
										name: "RWUNGO",
										generalAssembly_id: 571,
									},
									{
										id: 3119,
										name: "TYAZO",
										generalAssembly_id: 571,
									},
									{
										id: 9546,
										name: "Rusengesi   Court of Appeal ",
										generalAssembly_id: 571,
									},
									{
										id: 11079,
										name: "Rusengesi   Gacaca court of sector ",
										generalAssembly_id: 571,
									},
								],
							},
							{
								id: 572,
								name: "Ngoma  ",
								sector_id: 20110,
								courtOfCells: [
									{
										id: 3120,
										name: "BUPFUBYA",
										generalAssembly_id: 572,
									},
									{
										id: 3121,
										name: "GIHARA",
										generalAssembly_id: 572,
									},
									{
										id: 3122,
										name: "KAGOMBYI",
										generalAssembly_id: 572,
									},
									{
										id: 3123,
										name: "MATYAZO",
										generalAssembly_id: 572,
									},
									{
										id: 3124,
										name: "NYAKABINGO",
										generalAssembly_id: 572,
									},
									{
										id: 3125,
										name: "RWANZOZI",
										generalAssembly_id: 572,
									},
									{
										id: 9547,
										name: "Ngoma   Court of Appeal ",
										generalAssembly_id: 572,
									},
									{
										id: 11080,
										name: "Ngoma   Gacaca court of sector ",
										generalAssembly_id: 572,
									},
								],
							},
							{
								id: 573,
								name: "Mbogo  ",
								sector_id: 20110,
								courtOfCells: [
									{
										id: 3126,
										name: "BIVUMU",
										generalAssembly_id: 573,
									},
									{
										id: 3127,
										name: "GITUNTU",
										generalAssembly_id: 573,
									},
									{
										id: 3128,
										name: "KARAMBO",
										generalAssembly_id: 573,
									},
									{
										id: 3129,
										name: "MUREHE",
										generalAssembly_id: 573,
									},
									{
										id: 3130,
										name: "NYAGASOZI",
										generalAssembly_id: 573,
									},
									{
										id: 3131,
										name: "RWIRI",
										generalAssembly_id: 573,
									},
									{
										id: 3132,
										name: "SIMBI",
										generalAssembly_id: 573,
									},
									{
										id: 9548,
										name: "Mbogo   Court of Appeal ",
										generalAssembly_id: 573,
									},
									{
										id: 11081,
										name: "Mbogo   Gacaca court of sector ",
										generalAssembly_id: 573,
									},
								],
							},
						],
					},
					{
						id: 20111,
						name: "Ruganda",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2011108,
								name: "Rugobagoba",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011107,
								name: "Rubona",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011106,
								name: "Nyamugwagwa",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011105,
								name: "Nyabikeri",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011104,
								name: "Kivumu",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011103,
								name: "Kinyovu",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011102,
								name: "Kabingo",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011101,
								name: "Biguhu",
								sector_id: 20111,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 574,
								name: "Ruganda  ",
								sector_id: 20111,
								courtOfCells: [
									{
										id: 3133,
										name: "BUGARURA",
										generalAssembly_id: 574,
									},
									{
										id: 3134,
										name: "KABINGO",
										generalAssembly_id: 574,
									},
									{
										id: 3135,
										name: "KIVUMU",
										generalAssembly_id: 574,
									},
									{
										id: 3136,
										name: "MARYOHE",
										generalAssembly_id: 574,
									},
									{
										id: 3137,
										name: "MUCIRO",
										generalAssembly_id: 574,
									},
									{
										id: 3138,
										name: "MURAMBI",
										generalAssembly_id: 574,
									},
									{
										id: 3139,
										name: "NYAGASOZI",
										generalAssembly_id: 574,
									},
									{
										id: 3140,
										name: "NYARUSANGE",
										generalAssembly_id: 574,
									},
									{
										id: 3141,
										name: "RUBONA",
										generalAssembly_id: 574,
									},
									{
										id: 9549,
										name: "Ruganda   Court of Appeal ",
										generalAssembly_id: 574,
									},
									{
										id: 11082,
										name: "Ruganda   Gacaca court of sector ",
										generalAssembly_id: 574,
									},
								],
							},
							{
								id: 575,
								name: "Shoba  ",
								sector_id: 20111,
								courtOfCells: [
									{
										id: 3142,
										name: "GAHORORO",
										generalAssembly_id: 575,
									},
									{
										id: 3143,
										name: "KIBARI",
										generalAssembly_id: 575,
									},
									{
										id: 3144,
										name: "KIGUHU",
										generalAssembly_id: 575,
									},
									{
										id: 3145,
										name: "NYABIKERI",
										generalAssembly_id: 575,
									},
									{
										id: 3146,
										name: "NYAMUGWAGWA",
										generalAssembly_id: 575,
									},
									{
										id: 9550,
										name: "Shoba   Court of Appeal ",
										generalAssembly_id: 575,
									},
									{
										id: 11083,
										name: "Shoba   Gacaca court of sector ",
										generalAssembly_id: 575,
									},
								],
							},
							{
								id: 576,
								name: "Biguhu  ",
								sector_id: 20111,
								courtOfCells: [
									{
										id: 3147,
										name: "GITWA",
										generalAssembly_id: 576,
									},
									{
										id: 3148,
										name: "KIBALI",
										generalAssembly_id: 576,
									},
									{
										id: 3149,
										name: "MURAMBI",
										generalAssembly_id: 576,
									},
									{
										id: 3150,
										name: "NGANGE",
										generalAssembly_id: 576,
									},
									{
										id: 3151,
										name: "NYAGASOZI_MUREMURE",
										generalAssembly_id: 576,
									},
									{
										id: 9551,
										name: "Biguhu   Court of Appeal ",
										generalAssembly_id: 576,
									},
									{
										id: 11084,
										name: "Biguhu   Gacaca court of sector ",
										generalAssembly_id: 576,
									},
								],
							},
						],
					},
					{
						id: 20112,
						name: "Rwankuba",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2011208,
								name: "Rubumba",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011207,
								name: "Rubazo",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011206,
								name: "Nyarusanga",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011205,
								name: "Nyakamira",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011204,
								name: "Munini",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011203,
								name: "Gasata",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011202,
								name: "Bisesero",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011201,
								name: "Bigugu",
								sector_id: 20112,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 577,
								name: "Rwankuba  ",
								sector_id: 20112,
								courtOfCells: [
									{
										id: 3152,
										name: "BIGUGU",
										generalAssembly_id: 577,
									},
									{
										id: 3153,
										name: "MUNINI",
										generalAssembly_id: 577,
									},
									{
										id: 3154,
										name: "MUYIRA",
										generalAssembly_id: 577,
									},
									{
										id: 3155,
										name: "RUSHABARARA",
										generalAssembly_id: 577,
									},
									{
										id: 3156,
										name: "RWANKUBA",
										generalAssembly_id: 577,
									},
									{
										id: 3157,
										name: "TUZANA",
										generalAssembly_id: 577,
									},
									{
										id: 3158,
										name: "ZIHARE",
										generalAssembly_id: 577,
									},
									{
										id: 9552,
										name: "Rwankuba   Court of Appeal ",
										generalAssembly_id: 577,
									},
									{
										id: 11085,
										name: "Rwankuba   Gacaca court of sector ",
										generalAssembly_id: 577,
									},
								],
							},
							{
								id: 578,
								name: "Rugaragara  ",
								sector_id: 20112,
								courtOfCells: [
									{
										id: 3159,
										name: "KANYOVU",
										generalAssembly_id: 578,
									},
									{
										id: 3160,
										name: "MAHEMBE",
										generalAssembly_id: 578,
									},
									{
										id: 3161,
										name: "NYAKAMIRA",
										generalAssembly_id: 578,
									},
									{
										id: 3162,
										name: "RUBUMBA",
										generalAssembly_id: 578,
									},
									{
										id: 3163,
										name: "RUKORE",
										generalAssembly_id: 578,
									},
									{
										id: 9553,
										name: "Rugaragara   Court of Appeal ",
										generalAssembly_id: 578,
									},
									{
										id: 11086,
										name: "Rugaragara   Gacaca court of sector ",
										generalAssembly_id: 578,
									},
								],
							},
							{
								id: 579,
								name: "Bisesero  ",
								sector_id: 20112,
								courtOfCells: [
									{
										id: 3164,
										name: "BISESERO_UWINGABO",
										generalAssembly_id: 579,
									},
									{
										id: 3165,
										name: "CYABAHANGA_RUGETI",
										generalAssembly_id: 579,
									},
									{
										id: 3166,
										name: "GASATA",
										generalAssembly_id: 579,
									},
									{
										id: 3167,
										name: "GITWA_JURWE",
										generalAssembly_id: 579,
									},
									{
										id: 3168,
										name: "KIGARAMA_NYARUTOVU",
										generalAssembly_id: 579,
									},
									{
										id: 3169,
										name: "MUHINGO_NYAGAFUMBA",
										generalAssembly_id: 579,
									},
									{
										id: 9554,
										name: "Bisesero   Court of Appeal ",
										generalAssembly_id: 579,
									},
									{
										id: 11087,
										name: "Bisesero   Gacaca court of sector ",
										generalAssembly_id: 579,
									},
								],
							},
							{
								id: 580,
								name: "Rubazo  ",
								sector_id: 20112,
								courtOfCells: [
									{
										id: 3170,
										name: "BUDAHAMAGARWA",
										generalAssembly_id: 580,
									},
									{
										id: 3171,
										name: "KIDAHE",
										generalAssembly_id: 580,
									},
									{
										id: 3172,
										name: "KIZIBA",
										generalAssembly_id: 580,
									},
									{
										id: 3173,
										name: "NYARUSANGA",
										generalAssembly_id: 580,
									},
									{
										id: 3174,
										name: "MASIZA",
										generalAssembly_id: 580,
									},
									{
										id: 9555,
										name: "Rubazo   Court of Appeal ",
										generalAssembly_id: 580,
									},
									{
										id: 11088,
										name: "Rubazo   Gacaca court of sector ",
										generalAssembly_id: 580,
									},
								],
							},
						],
					},
					{
						id: 20113,
						name: "Twumba",
						district_id: 201,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2011307,
								name: "Rutabi",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011306,
								name: "Murehe",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011305,
								name: "Kavumu",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011304,
								name: "Gitabura",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011303,
								name: "Gisovu",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011302,
								name: "Gakuta",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2011301,
								name: "Bihumbe",
								sector_id: 20113,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 581,
								name: "Gisovu ",
								sector_id: 20113,
								courtOfCells: [
									{
										id: 3175,
										name: "GASHIHE",
										generalAssembly_id: 581,
									},
									{
										id: 3176,
										name: "KANYOVU",
										generalAssembly_id: 581,
									},
									{
										id: 3177,
										name: "KARAMBO",
										generalAssembly_id: 581,
									},
									{
										id: 3178,
										name: "KIBUBURO",
										generalAssembly_id: 581,
									},
									{
										id: 3179,
										name: "NYAKABINGO",
										generalAssembly_id: 581,
									},
									{
										id: 3180,
										name: "NYARUYAGA",
										generalAssembly_id: 581,
									},
									{
										id: 3181,
										name: "RUTITI",
										generalAssembly_id: 581,
									},
									{
										id: 9556,
										name: "Gisovu  Court of Appeal ",
										generalAssembly_id: 581,
									},
									{
										id: 11089,
										name: "Gisovu  Gacaca court of sector ",
										generalAssembly_id: 581,
									},
								],
							},
							{
								id: 582,
								name: "Gitabura ",
								sector_id: 20113,
								courtOfCells: [
									{
										id: 3182,
										name: "GATARE_NYAKIYABO",
										generalAssembly_id: 582,
									},
									{
										id: 3183,
										name: "GITABURA",
										generalAssembly_id: 582,
									},
									{
										id: 3184,
										name: "KIBINGO_CYAMARABA",
										generalAssembly_id: 582,
									},
									{
										id: 3185,
										name: "RUGEYO",
										generalAssembly_id: 582,
									},
									{
										id: 3186,
										name: "TUVUNASOGI_NYARUBUYE",
										generalAssembly_id: 582,
									},
									{
										id: 9557,
										name: "Gitabura  Court of Appeal ",
										generalAssembly_id: 582,
									},
									{
										id: 11090,
										name: "Gitabura  Gacaca court of sector ",
										generalAssembly_id: 582,
									},
								],
							},
							{
								id: 583,
								name: "Kavumu  ",
								sector_id: 20113,
								courtOfCells: [
									{
										id: 3187,
										name: "GAHONDO",
										generalAssembly_id: 583,
									},
									{
										id: 3188,
										name: "GASHARU",
										generalAssembly_id: 583,
									},
									{
										id: 3189,
										name: "KAGANDA",
										generalAssembly_id: 583,
									},
									{
										id: 3190,
										name: "KAVUMU_A",
										generalAssembly_id: 583,
									},
									{
										id: 3191,
										name: "KAVUMU_  B",
										generalAssembly_id: 583,
									},
									{
										id: 3192,
										name: "MURAMBI",
										generalAssembly_id: 583,
									},
									{
										id: 3193,
										name: "MURONZI",
										generalAssembly_id: 583,
									},
									{
										id: 3194,
										name: "NYIRABUNUNU",
										generalAssembly_id: 583,
									},
									{
										id: 3195,
										name: "RUTABI",
										generalAssembly_id: 583,
									},
									{
										id: 3196,
										name: "UWINTOBO",
										generalAssembly_id: 583,
									},
									{
										id: 9558,
										name: "Kavumu   Court of Appeal ",
										generalAssembly_id: 583,
									},
									{
										id: 11091,
										name: "Kavumu   Gacaca court of sector ",
										generalAssembly_id: 583,
									},
								],
							},
							{
								id: 584,
								name: "Twumba  ",
								sector_id: 20113,
								courtOfCells: [
									{
										id: 3197,
										name: "GAKOKO",
										generalAssembly_id: 584,
									},
									{
										id: 3198,
										name: "GATEMA",
										generalAssembly_id: 584,
									},
									{
										id: 3199,
										name: "KAGANDA",
										generalAssembly_id: 584,
									},
									{
										id: 3200,
										name: "KARUMBI",
										generalAssembly_id: 584,
									},
									{
										id: 3201,
										name: "MUREHE",
										generalAssembly_id: 584,
									},
									{
										id: 3202,
										name: "NYAMIRYANGO",
										generalAssembly_id: 584,
									},
									{
										id: 3203,
										name: "TWUMBA",
										generalAssembly_id: 584,
									},
									{
										id: 9559,
										name: "Twumba   Court of Appeal ",
										generalAssembly_id: 584,
									},
									{
										id: 11092,
										name: "Twumba   Gacaca court of sector ",
										generalAssembly_id: 584,
									},
								],
							},
							{
								id: 585,
								name: "Gisayura   ",
								sector_id: 20113,
								courtOfCells: [
									{
										id: 3204,
										name: "BIRAMBO",
										generalAssembly_id: 585,
									},
									{
										id: 3205,
										name: "GASHARU",
										generalAssembly_id: 585,
									},
									{
										id: 3206,
										name: "GITUNTU",
										generalAssembly_id: 585,
									},
									{
										id: 3207,
										name: "NGUNDUSI",
										generalAssembly_id: 585,
									},
									{
										id: 3208,
										name: "NTANGA",
										generalAssembly_id: 585,
									},
									{
										id: 3209,
										name: "NYAKIRAMBI",
										generalAssembly_id: 585,
									},
									{
										id: 3210,
										name: "NYARUTOVU",
										generalAssembly_id: 585,
									},
									{
										id: 9560,
										name: "Gisayura    Court of Appeal ",
										generalAssembly_id: 585,
									},
									{
										id: 11093,
										name: "Gisayura    Gacaca court of sector ",
										generalAssembly_id: 585,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 205,
				name: "Ngororero",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20501,
						name: "BWIRA",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050105,
								name: "Ruhindage",
								sector_id: 20501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050104,
								name: "Kabarondo",
								sector_id: 20501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050103,
								name: "Gashubi",
								sector_id: 20501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050102,
								name: "Cyahafi",
								sector_id: 20501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050101,
								name: "Bungwe",
								sector_id: 20501,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 810,
								name: "Gitarama ",
								sector_id: 20501,
								courtOfCells: [
									{
										id: 4591,
										name: "RUGEYO",
										generalAssembly_id: 810,
									},
									{
										id: 4592,
										name: "GISEKURU",
										generalAssembly_id: 810,
									},
									{
										id: 4593,
										name: "KABABAGAZI",
										generalAssembly_id: 810,
									},
									{
										id: 4594,
										name: "KINDOYI",
										generalAssembly_id: 810,
									},
									{
										id: 4595,
										name: "MUKINGI",
										generalAssembly_id: 810,
									},
									{
										id: 4596,
										name: "RUBAYA",
										generalAssembly_id: 810,
									},
									{
										id: 9785,
										name: "Gitarama  Court of Appeal ",
										generalAssembly_id: 810,
									},
									{
										id: 11318,
										name: "Gitarama  Gacaca court of sector ",
										generalAssembly_id: 810,
									},
								],
							},
							{
								id: 811,
								name: "Rugarama ",
								sector_id: 20501,
								courtOfCells: [
									{
										id: 4597,
										name: "BUGARURA",
										generalAssembly_id: 811,
									},
									{
										id: 4598,
										name: "CYAVURO",
										generalAssembly_id: 811,
									},
									{
										id: 4599,
										name: "MURIRO",
										generalAssembly_id: 811,
									},
									{
										id: 4600,
										name: "NTANDA",
										generalAssembly_id: 811,
									},
									{
										id: 4601,
										name: "RUHINDAGE",
										generalAssembly_id: 811,
									},
									{
										id: 4602,
										name: "RWANGUSHO",
										generalAssembly_id: 811,
									},
									{
										id: 9786,
										name: "Rugarama  Court of Appeal ",
										generalAssembly_id: 811,
									},
									{
										id: 11319,
										name: "Rugarama  Gacaca court of sector ",
										generalAssembly_id: 811,
									},
								],
							},
							{
								id: 812,
								name: "Bwira ",
								sector_id: 20501,
								courtOfCells: [
									{
										id: 4603,
										name: "GASASA",
										generalAssembly_id: 812,
									},
									{
										id: 4604,
										name: "GITONDE",
										generalAssembly_id: 812,
									},
									{
										id: 4605,
										name: "RUGESHI",
										generalAssembly_id: 812,
									},
									{
										id: 4606,
										name: "RUKERI",
										generalAssembly_id: 812,
									},
									{
										id: 4607,
										name: "RWAMAKARA",
										generalAssembly_id: 812,
									},
									{
										id: 9787,
										name: "Bwira  Court of Appeal ",
										generalAssembly_id: 812,
									},
									{
										id: 11320,
										name: "Bwira  Gacaca court of sector ",
										generalAssembly_id: 812,
									},
								],
							},
						],
					},
					{
						id: 20502,
						name: "GATUMBA",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050206,
								name: "Rusumo",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050205,
								name: "Ruhanga",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050204,
								name: "Karambo",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050203,
								name: "Kamasiga",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050202,
								name: "Gatsibo",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050201,
								name: "Cyome",
								sector_id: 20502,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 813,
								name: "Gatumba ",
								sector_id: 20502,
								courtOfCells: [
									{
										id: 4608,
										name: "GAKOMA",
										generalAssembly_id: 813,
									},
									{
										id: 4609,
										name: "MAKOMA",
										generalAssembly_id: 813,
									},
									{
										id: 4610,
										name: "MIDUHA",
										generalAssembly_id: 813,
									},
									{
										id: 4611,
										name: "NYABISINDU",
										generalAssembly_id: 813,
									},
									{
										id: 4612,
										name: "SEREGE",
										generalAssembly_id: 813,
									},
									{
										id: 9788,
										name: "Gatumba  Court of Appeal ",
										generalAssembly_id: 813,
									},
									{
										id: 11321,
										name: "Gatumba  Gacaca court of sector ",
										generalAssembly_id: 813,
									},
								],
							},
							{
								id: 814,
								name: "Karehe ",
								sector_id: 20502,
								courtOfCells: [
									{
										id: 4613,
										name: "GAKUMBA",
										generalAssembly_id: 814,
									},
									{
										id: 4614,
										name: "MASHENYI",
										generalAssembly_id: 814,
									},
									{
										id: 4615,
										name: "MUTEKERI",
										generalAssembly_id: 814,
									},
									{
										id: 4616,
										name: "RUSUMO",
										generalAssembly_id: 814,
									},
									{
										id: 4617,
										name: "SITWE",
										generalAssembly_id: 814,
									},
									{
										id: 9789,
										name: "Karehe  Court of Appeal ",
										generalAssembly_id: 814,
									},
									{
										id: 11322,
										name: "Karehe  Gacaca court of sector ",
										generalAssembly_id: 814,
									},
								],
							},
							{
								id: 815,
								name: "Kirengo ",
								sector_id: 20502,
								courtOfCells: [
									{
										id: 4618,
										name: "GASEBEYA",
										generalAssembly_id: 815,
									},
									{
										id: 4619,
										name: "KAMAYIRA",
										generalAssembly_id: 815,
									},
									{
										id: 4620,
										name: "MURAMBI",
										generalAssembly_id: 815,
									},
									{
										id: 4621,
										name: "NTERESI",
										generalAssembly_id: 815,
									},
									{
										id: 4622,
										name: "RWANGARA",
										generalAssembly_id: 815,
									},
									{
										id: 4623,
										name: "SHISHI",
										generalAssembly_id: 815,
									},
									{
										id: 9790,
										name: "Kirengo  Court of Appeal ",
										generalAssembly_id: 815,
									},
									{
										id: 11323,
										name: "Kirengo  Gacaca court of sector ",
										generalAssembly_id: 815,
									},
								],
							},
							{
								id: 816,
								name: "Ngurugunzu ",
								sector_id: 20502,
								courtOfCells: [
									{
										id: 4624,
										name: "BURARANKA",
										generalAssembly_id: 816,
									},
									{
										id: 4625,
										name: "GAFURWE",
										generalAssembly_id: 816,
									},
									{
										id: 4626,
										name: "KIGARAMA",
										generalAssembly_id: 816,
									},
									{
										id: 4627,
										name: "NYABIKENKE",
										generalAssembly_id: 816,
									},
									{
										id: 4628,
										name: "RUKUBI",
										generalAssembly_id: 816,
									},
									{
										id: 4629,
										name: "RWAMIKO",
										generalAssembly_id: 816,
									},
									{
										id: 9791,
										name: "Ngurugunzu  Court of Appeal ",
										generalAssembly_id: 816,
									},
									{
										id: 11324,
										name: "Ngurugunzu  Gacaca court of sector ",
										generalAssembly_id: 816,
									},
								],
							},
							{
								id: 817,
								name: "Rubona ",
								sector_id: 20502,
								courtOfCells: [
									{
										id: 4630,
										name: "BUREMBA",
										generalAssembly_id: 817,
									},
									{
										id: 4631,
										name: "BUTARE",
										generalAssembly_id: 817,
									},
									{
										id: 4632,
										name: "GATYAZO",
										generalAssembly_id: 817,
									},
									{
										id: 4633,
										name: "KADEHERO",
										generalAssembly_id: 817,
									},
									{
										id: 4634,
										name: "KIGOGO",
										generalAssembly_id: 817,
									},
									{
										id: 4635,
										name: "MBAGABAGA",
										generalAssembly_id: 817,
									},
									{
										id: 4636,
										name: "RUNYOGOTE",
										generalAssembly_id: 817,
									},
									{
										id: 9792,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 817,
									},
									{
										id: 11325,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 817,
									},
								],
							},
						],
					},
					{
						id: 20503,
						name: "HINDIRO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050306,
								name: "Runyinya",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050305,
								name: "Rugendabari",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050304,
								name: "Marantima",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050303,
								name: "Kajinge",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050302,
								name: "Gatega",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050301,
								name: "Gatare",
								sector_id: 20503,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 818,
								name: "Kabuye ",
								sector_id: 20503,
								courtOfCells: [
									{
										id: 4637,
										name: "GATABA",
										generalAssembly_id: 818,
									},
									{
										id: 4638,
										name: "KABATIMBO",
										generalAssembly_id: 818,
									},
									{
										id: 4639,
										name: "KAJINGE",
										generalAssembly_id: 818,
									},
									{
										id: 4640,
										name: "KIRINGA",
										generalAssembly_id: 818,
									},
									{
										id: 4641,
										name: "NYAMIBANGA",
										generalAssembly_id: 818,
									},
									{
										id: 4642,
										name: "RUSEBEYA",
										generalAssembly_id: 818,
									},
									{
										id: 9793,
										name: "Kabuye  Court of Appeal ",
										generalAssembly_id: 818,
									},
									{
										id: 11326,
										name: "Kabuye  Gacaca court of sector ",
										generalAssembly_id: 818,
									},
								],
							},
							{
								id: 819,
								name: "Gitega ",
								sector_id: 20503,
								courtOfCells: [
									{
										id: 4643,
										name: "KABUYE",
										generalAssembly_id: 819,
									},
									{
										id: 4644,
										name: "KIBONWA",
										generalAssembly_id: 819,
									},
									{
										id: 4645,
										name: "MBUYE",
										generalAssembly_id: 819,
									},
									{
										id: 4646,
										name: "NYARUSANGE",
										generalAssembly_id: 819,
									},
									{
										id: 4647,
										name: "RUKARAGATA",
										generalAssembly_id: 819,
									},
									{
										id: 9794,
										name: "Gitega  Court of Appeal ",
										generalAssembly_id: 819,
									},
									{
										id: 11327,
										name: "Gitega  Gacaca court of sector ",
										generalAssembly_id: 819,
									},
								],
							},
							{
								id: 820,
								name: "Hindiro ",
								sector_id: 20503,
								courtOfCells: [
									{
										id: 4648,
										name: "GATARE",
										generalAssembly_id: 820,
									},
									{
										id: 4649,
										name: "KIGARAMA",
										generalAssembly_id: 820,
									},
									{
										id: 4650,
										name: "MARANTIMA",
										generalAssembly_id: 820,
									},
									{
										id: 4651,
										name: "MUHORORO",
										generalAssembly_id: 820,
									},
									{
										id: 4652,
										name: "NYABIHU",
										generalAssembly_id: 820,
									},
									{
										id: 4653,
										name: "NYAGASOZI",
										generalAssembly_id: 820,
									},
									{
										id: 9795,
										name: "Hindiro  Court of Appeal ",
										generalAssembly_id: 820,
									},
									{
										id: 11328,
										name: "Hindiro  Gacaca court of sector ",
										generalAssembly_id: 820,
									},
								],
							},
							{
								id: 821,
								name: "Rucano ",
								sector_id: 20503,
								courtOfCells: [
									{
										id: 4654,
										name: "BURENGO",
										generalAssembly_id: 821,
									},
									{
										id: 4655,
										name: "GACYAMO",
										generalAssembly_id: 821,
									},
									{
										id: 4656,
										name: "KADEHERO",
										generalAssembly_id: 821,
									},
									{
										id: 4657,
										name: "KIRIGI",
										generalAssembly_id: 821,
									},
									{
										id: 4658,
										name: "NYARUKORE",
										generalAssembly_id: 821,
									},
									{
										id: 4659,
										name: "RUNYINYA",
										generalAssembly_id: 821,
									},
									{
										id: 4660,
										name: "RUSESA",
										generalAssembly_id: 821,
									},
									{
										id: 4661,
										name: "GATABA",
										generalAssembly_id: 821,
									},
									{
										id: 9796,
										name: "Rucano  Court of Appeal ",
										generalAssembly_id: 821,
									},
									{
										id: 11329,
										name: "Rucano  Gacaca court of sector ",
										generalAssembly_id: 821,
									},
								],
							},
						],
					},
					{
						id: 20504,
						name: "KABAYA",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050406,
								name: "Nyenyeri",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050405,
								name: "Ngoma",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050404,
								name: "Mwendo",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050403,
								name: "Kabaya",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050402,
								name: "Gaseke",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050401,
								name: "Busunzu",
								sector_id: 20504,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 822,
								name: "Bukonde ",
								sector_id: 20504,
								courtOfCells: [
									{
										id: 4662,
										name: "BUTARE",
										generalAssembly_id: 822,
									},
									{
										id: 4663,
										name: "KINANIRA",
										generalAssembly_id: 822,
									},
									{
										id: 4664,
										name: "KIVUMU",
										generalAssembly_id: 822,
									},
									{
										id: 4665,
										name: "NYABIHO",
										generalAssembly_id: 822,
									},
									{
										id: 4666,
										name: "NYABURAMA",
										generalAssembly_id: 822,
									},
									{
										id: 4667,
										name: "NTURO",
										generalAssembly_id: 822,
									},
									{
										id: 9797,
										name: "Bukonde  Court of Appeal ",
										generalAssembly_id: 822,
									},
									{
										id: 11330,
										name: "Bukonde  Gacaca court of sector ",
										generalAssembly_id: 822,
									},
								],
							},
							{
								id: 823,
								name: "Gisebeya ",
								sector_id: 20504,
								courtOfCells: [
									{
										id: 4668,
										name: "GISEBEYA",
										generalAssembly_id: 823,
									},
									{
										id: 4669,
										name: "GITUMBA",
										generalAssembly_id: 823,
									},
									{
										id: 4670,
										name: "KAGESHI",
										generalAssembly_id: 823,
									},
									{
										id: 4671,
										name: "MBANDARI",
										generalAssembly_id: 823,
									},
									{
										id: 4672,
										name: "NGOMA",
										generalAssembly_id: 823,
									},
									{
										id: 4673,
										name: "NYAMUGEYO",
										generalAssembly_id: 823,
									},
									{
										id: 4674,
										name: "RUKORATI",
										generalAssembly_id: 823,
									},
									{
										id: 9798,
										name: "Gisebeya  Court of Appeal ",
										generalAssembly_id: 823,
									},
									{
										id: 11331,
										name: "Gisebeya  Gacaca court of sector ",
										generalAssembly_id: 823,
									},
								],
							},
							{
								id: 824,
								name: "Mwendo ",
								sector_id: 20504,
								courtOfCells: [
									{
										id: 4675,
										name: "BUKIRANZUKI",
										generalAssembly_id: 824,
									},
									{
										id: 4676,
										name: "BUKONDE",
										generalAssembly_id: 824,
									},
									{
										id: 4677,
										name: "GATOVU",
										generalAssembly_id: 824,
									},
									{
										id: 4678,
										name: "GITWE",
										generalAssembly_id: 824,
									},
									{
										id: 4679,
										name: "KAMPINGA",
										generalAssembly_id: 824,
									},
									{
										id: 4680,
										name: "RUBAMBIRO",
										generalAssembly_id: 824,
									},
									{
										id: 4681,
										name: "TUBUYE",
										generalAssembly_id: 824,
									},
									{
										id: 9799,
										name: "Mwendo  Court of Appeal ",
										generalAssembly_id: 824,
									},
									{
										id: 11332,
										name: "Mwendo  Gacaca court of sector ",
										generalAssembly_id: 824,
									},
								],
							},
							{
								id: 825,
								name: "Rurambo ",
								sector_id: 20504,
								courtOfCells: [
									{
										id: 4682,
										name: "CYASENGE",
										generalAssembly_id: 825,
									},
									{
										id: 4683,
										name: "GITABA",
										generalAssembly_id: 825,
									},
									{
										id: 4684,
										name: "JOTE",
										generalAssembly_id: 825,
									},
									{
										id: 4685,
										name: "KABAYA",
										generalAssembly_id: 825,
									},
									{
										id: 4686,
										name: "KAMUHIRWA",
										generalAssembly_id: 825,
									},
									{
										id: 4687,
										name: "MAHUNGUBUYE",
										generalAssembly_id: 825,
									},
									{
										id: 4688,
										name: "MUBUGU",
										generalAssembly_id: 825,
									},
									{
										id: 4689,
										name: "NYARUSHISHATI",
										generalAssembly_id: 825,
									},
									{
										id: 9800,
										name: "Rurambo  Court of Appeal ",
										generalAssembly_id: 825,
									},
									{
										id: 11333,
										name: "Rurambo  Gacaca court of sector ",
										generalAssembly_id: 825,
									},
								],
							},
							{
								id: 826,
								name: "Rwankenke ",
								sector_id: 20504,
								courtOfCells: [
									{
										id: 4690,
										name: "GISOZI",
										generalAssembly_id: 826,
									},
									{
										id: 4691,
										name: "KAVUMU",
										generalAssembly_id: 826,
									},
									{
										id: 4692,
										name: "KINANIRA",
										generalAssembly_id: 826,
									},
									{
										id: 4693,
										name: "KINTOBO",
										generalAssembly_id: 826,
									},
									{
										id: 4694,
										name: "KIRENGO",
										generalAssembly_id: 826,
									},
									{
										id: 4695,
										name: "RWANKENKE",
										generalAssembly_id: 826,
									},
									{
										id: 9801,
										name: "Rwankenke  Court of Appeal ",
										generalAssembly_id: 826,
									},
									{
										id: 11334,
										name: "Rwankenke  Gacaca court of sector ",
										generalAssembly_id: 826,
									},
								],
							},
						],
					},
					{
						id: 20505,
						name: "KAGEYO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050506,
								name: "Rwamamara",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050505,
								name: "Nyamata",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050504,
								name: "Muramba",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050503,
								name: "Mukore",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050502,
								name: "Kirwa",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050501,
								name: "Kageshi",
								sector_id: 20505,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 827,
								name: "Kageyo ",
								sector_id: 20505,
								courtOfCells: [
									{
										id: 4696,
										name: "NYAKABANDA",
										generalAssembly_id: 827,
									},
									{
										id: 4697,
										name: "RURERA",
										generalAssembly_id: 827,
									},
									{
										id: 4698,
										name: "NYABITABO",
										generalAssembly_id: 827,
									},
									{
										id: 4699,
										name: "KAMASHI",
										generalAssembly_id: 827,
									},
									{
										id: 4700,
										name: "KAGARAMA",
										generalAssembly_id: 827,
									},
									{
										id: 4701,
										name: "SEBASENGO",
										generalAssembly_id: 827,
									},
									{
										id: 9802,
										name: "Kageyo  Court of Appeal ",
										generalAssembly_id: 827,
									},
									{
										id: 11335,
										name: "Kageyo  Gacaca court of sector ",
										generalAssembly_id: 827,
									},
								],
							},
							{
								id: 828,
								name: "Musagara ",
								sector_id: 20505,
								courtOfCells: [
									{
										id: 4702,
										name: "GASIZA",
										generalAssembly_id: 828,
									},
									{
										id: 4703,
										name: "GISEKE",
										generalAssembly_id: 828,
									},
									{
										id: 4704,
										name: "KANYUNDO",
										generalAssembly_id: 828,
									},
									{
										id: 4705,
										name: "GASEKE",
										generalAssembly_id: 828,
									},
									{
										id: 4706,
										name: "MUBUGA",
										generalAssembly_id: 828,
									},
									{
										id: 9803,
										name: "Musagara  Court of Appeal ",
										generalAssembly_id: 828,
									},
									{
										id: 11336,
										name: "Musagara  Gacaca court of sector ",
										generalAssembly_id: 828,
									},
								],
							},
							{
								id: 829,
								name: "Nyampiri ",
								sector_id: 20505,
								courtOfCells: [
									{
										id: 4707,
										name: "KAGESHI",
										generalAssembly_id: 829,
									},
									{
										id: 4708,
										name: "KILIKWA",
										generalAssembly_id: 829,
									},
									{
										id: 4709,
										name: "NTETE",
										generalAssembly_id: 829,
									},
									{
										id: 4710,
										name: "RUGANDA",
										generalAssembly_id: 829,
									},
									{
										id: 4711,
										name: "RWANEMBWE",
										generalAssembly_id: 829,
									},
									{
										id: 9804,
										name: "Nyampiri  Court of Appeal ",
										generalAssembly_id: 829,
									},
									{
										id: 11337,
										name: "Nyampiri  Gacaca court of sector ",
										generalAssembly_id: 829,
									},
								],
							},
							{
								id: 830,
								name: "Suti ",
								sector_id: 20505,
								courtOfCells: [
									{
										id: 4712,
										name: "GAKINGA",
										generalAssembly_id: 830,
									},
									{
										id: 4713,
										name: "KABAYENGO",
										generalAssembly_id: 830,
									},
									{
										id: 4714,
										name: "KABINGO",
										generalAssembly_id: 830,
									},
									{
										id: 4715,
										name: "MURAMBA",
										generalAssembly_id: 830,
									},
									{
										id: 4716,
										name: "NYAGIKOMA",
										generalAssembly_id: 830,
									},
									{
										id: 9805,
										name: "Suti  Court of Appeal ",
										generalAssembly_id: 830,
									},
									{
										id: 11338,
										name: "Suti  Gacaca court of sector ",
										generalAssembly_id: 830,
									},
								],
							},
						],
					},
					{
						id: 20506,
						name: "KAVUMU",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050606,
								name: "Tetero",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050605,
								name: "Rugeshi",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050604,
								name: "Nyamugeyo",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050603,
								name: "Murinzi",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050602,
								name: "Gitwa",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050601,
								name: "Birembo",
								sector_id: 20506,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 831,
								name: "Bayi ",
								sector_id: 20506,
								courtOfCells: [
									{
										id: 4717,
										name: "BUCUKA",
										generalAssembly_id: 831,
									},
									{
										id: 4718,
										name: "KARUNDA",
										generalAssembly_id: 831,
									},
									{
										id: 4719,
										name: "MUTAKE",
										generalAssembly_id: 831,
									},
									{
										id: 4720,
										name: "NYAMUSENYI",
										generalAssembly_id: 831,
									},
									{
										id: 4721,
										name: "RUTOYI",
										generalAssembly_id: 831,
									},
									{
										id: 9806,
										name: "Bayi  Court of Appeal ",
										generalAssembly_id: 831,
									},
									{
										id: 11339,
										name: "Bayi  Gacaca court of sector ",
										generalAssembly_id: 831,
									},
								],
							},
							{
								id: 832,
								name: "Kavumu ",
								sector_id: 20506,
								courtOfCells: [
									{
										id: 4722,
										name: "GASHAKI",
										generalAssembly_id: 832,
									},
									{
										id: 4723,
										name: "GATOVU",
										generalAssembly_id: 832,
									},
									{
										id: 4724,
										name: "KANTOBO",
										generalAssembly_id: 832,
									},
									{
										id: 4725,
										name: "NYABITSINA",
										generalAssembly_id: 832,
									},
									{
										id: 4726,
										name: "NYABUBANDA",
										generalAssembly_id: 832,
									},
									{
										id: 9807,
										name: "Kavumu  Court of Appeal ",
										generalAssembly_id: 832,
									},
									{
										id: 11340,
										name: "Kavumu  Gacaca court of sector ",
										generalAssembly_id: 832,
									},
								],
							},
							{
								id: 833,
								name: "Kimanu ",
								sector_id: 20506,
								courtOfCells: [
									{
										id: 4727,
										name: "GASASO",
										generalAssembly_id: 833,
									},
									{
										id: 4728,
										name: "GASIZA",
										generalAssembly_id: 833,
									},
									{
										id: 4729,
										name: "GITWA",
										generalAssembly_id: 833,
									},
									{
										id: 4730,
										name: "NYAMBERAMO",
										generalAssembly_id: 833,
									},
									{
										id: 4731,
										name: "NYARUKARA",
										generalAssembly_id: 833,
									},
									{
										id: 9808,
										name: "Kimanu  Court of Appeal ",
										generalAssembly_id: 833,
									},
									{
										id: 11341,
										name: "Kimanu  Gacaca court of sector ",
										generalAssembly_id: 833,
									},
								],
							},
						],
					},
					{
						id: 20507,
						name: "MATYAZO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050705,
								name: "Rwamiko",
								sector_id: 20507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050704,
								name: "Rutare",
								sector_id: 20507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050703,
								name: "Matare",
								sector_id: 20507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050702,
								name: "Gitega",
								sector_id: 20507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050701,
								name: "Binana",
								sector_id: 20507,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 834,
								name: "Bukende ",
								sector_id: 20507,
								courtOfCells: [
									{
										id: 4732,
										name: "BUKENDE",
										generalAssembly_id: 834,
									},
									{
										id: 4733,
										name: "GACUNDA",
										generalAssembly_id: 834,
									},
									{
										id: 4734,
										name: "GAKO",
										generalAssembly_id: 834,
									},
									{
										id: 4735,
										name: "NTURO",
										generalAssembly_id: 834,
									},
									{
										id: 4736,
										name: "NYAKIRIBA",
										generalAssembly_id: 834,
									},
									{
										id: 4737,
										name: "RUGESHI",
										generalAssembly_id: 834,
									},
									{
										id: 4738,
										name: "RWAMABUYE",
										generalAssembly_id: 834,
									},
									{
										id: 9809,
										name: "Bukende  Court of Appeal ",
										generalAssembly_id: 834,
									},
									{
										id: 11342,
										name: "Bukende  Gacaca court of sector ",
										generalAssembly_id: 834,
									},
								],
							},
							{
								id: 835,
								name: "Gashonyi  ",
								sector_id: 20507,
								courtOfCells: [
									{
										id: 4739,
										name: "GATIKABISI",
										generalAssembly_id: 835,
									},
									{
										id: 4740,
										name: "MATARE",
										generalAssembly_id: 835,
									},
									{
										id: 4741,
										name: "MUGAMBAZI",
										generalAssembly_id: 835,
									},
									{
										id: 4742,
										name: "RUBUMBA",
										generalAssembly_id: 835,
									},
									{
										id: 4743,
										name: "RUCACA",
										generalAssembly_id: 835,
									},
									{
										id: 4744,
										name: "RUHANGA",
										generalAssembly_id: 835,
									},
									{
										id: 9810,
										name: "Gashonyi   Court of Appeal ",
										generalAssembly_id: 835,
									},
									{
										id: 11343,
										name: "Gashonyi   Gacaca court of sector ",
										generalAssembly_id: 835,
									},
								],
							},
							{
								id: 836,
								name: "Gitwa ",
								sector_id: 20507,
								courtOfCells: [
									{
										id: 4745,
										name: "GASAYO",
										generalAssembly_id: 836,
									},
									{
										id: 4746,
										name: "KANKUNGA",
										generalAssembly_id: 836,
									},
									{
										id: 4747,
										name: "KIGARAMA",
										generalAssembly_id: 836,
									},
									{
										id: 4748,
										name: "KINZAGARA",
										generalAssembly_id: 836,
									},
									{
										id: 4749,
										name: "RUSATIRA",
										generalAssembly_id: 836,
									},
									{
										id: 4750,
										name: "RWANKENKE",
										generalAssembly_id: 836,
									},
									{
										id: 9811,
										name: "Gitwa  Court of Appeal ",
										generalAssembly_id: 836,
									},
									{
										id: 11344,
										name: "Gitwa  Gacaca court of sector ",
										generalAssembly_id: 836,
									},
								],
							},
							{
								id: 837,
								name: "Matyazo ",
								sector_id: 20507,
								courtOfCells: [
									{
										id: 4751,
										name: "BUSORO",
										generalAssembly_id: 837,
									},
									{
										id: 4752,
										name: "KABUYE",
										generalAssembly_id: 837,
									},
									{
										id: 4753,
										name: "KASEKE",
										generalAssembly_id: 837,
									},
									{
										id: 4754,
										name: "KAVUMU",
										generalAssembly_id: 837,
									},
									{
										id: 4755,
										name: "NYAGISOZI",
										generalAssembly_id: 837,
									},
									{
										id: 9812,
										name: "Matyazo  Court of Appeal ",
										generalAssembly_id: 837,
									},
									{
										id: 11345,
										name: "Matyazo  Gacaca court of sector ",
										generalAssembly_id: 837,
									},
								],
							},
							{
								id: 838,
								name: "Munini  ",
								sector_id: 20507,
								courtOfCells: [
									{
										id: 4756,
										name: "BUTARE",
										generalAssembly_id: 838,
									},
									{
										id: 4757,
										name: "NYAKIBANDE",
										generalAssembly_id: 838,
									},
									{
										id: 4758,
										name: "RUSORORO",
										generalAssembly_id: 838,
									},
									{
										id: 4759,
										name: "RWAMIKO",
										generalAssembly_id: 838,
									},
									{
										id: 9813,
										name: "Munini   Court of Appeal ",
										generalAssembly_id: 838,
									},
									{
										id: 11346,
										name: "Munini   Gacaca court of sector ",
										generalAssembly_id: 838,
									},
								],
							},
						],
					},
					{
						id: 20508,
						name: "MUHANDA",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050806,
								name: "Rutagara",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050805,
								name: "Ngoma",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050804,
								name: "Nganzo",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050803,
								name: "Mashya",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050802,
								name: "Gasiza",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050801,
								name: "Bugarura",
								sector_id: 20508,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 839,
								name: "Magaba ",
								sector_id: 20508,
								courtOfCells: [
									{
										id: 4760,
										name: "BIHANDAGARA",
										generalAssembly_id: 839,
									},
									{
										id: 4761,
										name: "BISIZI",
										generalAssembly_id: 839,
									},
									{
										id: 4762,
										name: "GISAYO",
										generalAssembly_id: 839,
									},
									{
										id: 4763,
										name: "MAGABA",
										generalAssembly_id: 839,
									},
									{
										id: 4764,
										name: "MUNYEGE",
										generalAssembly_id: 839,
									},
									{
										id: 4765,
										name: "NTUNTU",
										generalAssembly_id: 839,
									},
									{
										id: 4766,
										name: "RUGARIKA",
										generalAssembly_id: 839,
									},
									{
										id: 4767,
										name: "RUKOBORA",
										generalAssembly_id: 839,
									},
									{
										id: 9814,
										name: "Magaba  Court of Appeal ",
										generalAssembly_id: 839,
									},
									{
										id: 11347,
										name: "Magaba  Gacaca court of sector ",
										generalAssembly_id: 839,
									},
								],
							},
							{
								id: 840,
								name: "Muhanda  ",
								sector_id: 20508,
								courtOfCells: [
									{
										id: 4768,
										name: "BUKUNZI",
										generalAssembly_id: 840,
									},
									{
										id: 4769,
										name: "KAMUGANDA",
										generalAssembly_id: 840,
									},
									{
										id: 4770,
										name: "KANYABIGEGA",
										generalAssembly_id: 840,
									},
									{
										id: 4771,
										name: "KOKO",
										generalAssembly_id: 840,
									},
									{
										id: 4772,
										name: "MISEMBURO",
										generalAssembly_id: 840,
									},
									{
										id: 4773,
										name: "MPANGA",
										generalAssembly_id: 840,
									},
									{
										id: 4774,
										name: "MUHANDA",
										generalAssembly_id: 840,
									},
									{
										id: 4775,
										name: "MUNYEGE",
										generalAssembly_id: 840,
									},
									{
										id: 4776,
										name: "RWANTOBOTOBO",
										generalAssembly_id: 840,
									},
									{
										id: 9815,
										name: "Muhanda   Court of Appeal ",
										generalAssembly_id: 840,
									},
									{
										id: 11348,
										name: "Muhanda   Gacaca court of sector ",
										generalAssembly_id: 840,
									},
								],
							},
							{
								id: 841,
								name: "Rwiri  ",
								sector_id: 20508,
								courtOfCells: [
									{
										id: 4777,
										name: "GAHURURA",
										generalAssembly_id: 841,
									},
									{
										id: 4778,
										name: "KABAYENGO",
										generalAssembly_id: 841,
									},
									{
										id: 4779,
										name: "KARAMBI",
										generalAssembly_id: 841,
									},
									{
										id: 4780,
										name: "MUHUMYO",
										generalAssembly_id: 841,
									},
									{
										id: 4781,
										name: "NYANDIGA",
										generalAssembly_id: 841,
									},
									{
										id: 4782,
										name: "NYANSHUNDURA",
										generalAssembly_id: 841,
									},
									{
										id: 4783,
										name: "RWILI",
										generalAssembly_id: 841,
									},
									{
										id: 9816,
										name: "Rwiri   Court of Appeal ",
										generalAssembly_id: 841,
									},
									{
										id: 11349,
										name: "Rwiri   Gacaca court of sector ",
										generalAssembly_id: 841,
									},
								],
							},
						],
					},
					{
						id: 20509,
						name: "MUHORORO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2050906,
								name: "Sanza",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050905,
								name: "Rusororo",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050904,
								name: "Rugogwe",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050903,
								name: "Myiha",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050902,
								name: "Mubuga",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2050901,
								name: "Bweramana",
								sector_id: 20509,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 842,
								name: "Mikingo ",
								sector_id: 20509,
								courtOfCells: [
									{
										id: 4784,
										name: "NEMBA",
										generalAssembly_id: 842,
									},
									{
										id: 4785,
										name: "NGANZO",
										generalAssembly_id: 842,
									},
									{
										id: 4786,
										name: "NYABIKENKE",
										generalAssembly_id: 842,
									},
									{
										id: 4787,
										name: "RUBONA",
										generalAssembly_id: 842,
									},
									{
										id: 4788,
										name: "SHAKA",
										generalAssembly_id: 842,
									},
									{
										id: 4789,
										name: "SHORI",
										generalAssembly_id: 842,
									},
									{
										id: 9817,
										name: "Mikingo  Court of Appeal ",
										generalAssembly_id: 842,
									},
									{
										id: 11350,
										name: "Mikingo  Gacaca court of sector ",
										generalAssembly_id: 842,
									},
								],
							},
							{
								id: 843,
								name: "Ntaganzwa ",
								sector_id: 20509,
								courtOfCells: [
									{
										id: 4790,
										name: "BWERAMANA",
										generalAssembly_id: 843,
									},
									{
										id: 4791,
										name: "GIKOMAGURO",
										generalAssembly_id: 843,
									},
									{
										id: 4792,
										name: "MUSANZUBIZE",
										generalAssembly_id: 843,
									},
									{
										id: 4793,
										name: "MUYAGA",
										generalAssembly_id: 843,
									},
									{
										id: 4794,
										name: "NKURAZO",
										generalAssembly_id: 843,
									},
									{
										id: 4795,
										name: "NYAGISAGARA",
										generalAssembly_id: 843,
									},
									{
										id: 4796,
										name: "NYAKIBARI",
										generalAssembly_id: 843,
									},
									{
										id: 4797,
										name: "NYARUNYINYA",
										generalAssembly_id: 843,
									},
									{
										id: 9818,
										name: "Ntaganzwa  Court of Appeal ",
										generalAssembly_id: 843,
									},
									{
										id: 11351,
										name: "Ntaganzwa  Gacaca court of sector ",
										generalAssembly_id: 843,
									},
								],
							},
							{
								id: 844,
								name: "Nyamisa ",
								sector_id: 20509,
								courtOfCells: [
									{
										id: 4798,
										name: "BURENGO",
										generalAssembly_id: 844,
									},
									{
										id: 4799,
										name: "GIHIRYI",
										generalAssembly_id: 844,
									},
									{
										id: 4800,
										name: "KANIGA",
										generalAssembly_id: 844,
									},
									{
										id: 4801,
										name: "MUSEKERA",
										generalAssembly_id: 844,
									},
									{
										id: 4802,
										name: "NYAMIVUMU",
										generalAssembly_id: 844,
									},
									{
										id: 4803,
										name: "REMERA",
										generalAssembly_id: 844,
									},
									{
										id: 9819,
										name: "Nyamisa  Court of Appeal ",
										generalAssembly_id: 844,
									},
									{
										id: 11352,
										name: "Nyamisa  Gacaca court of sector ",
										generalAssembly_id: 844,
									},
								],
							},
							{
								id: 845,
								name: "Rongi ",
								sector_id: 20509,
								courtOfCells: [
									{
										id: 4804,
										name: "BURANGA",
										generalAssembly_id: 845,
									},
									{
										id: 4805,
										name: "KIDAHWE",
										generalAssembly_id: 845,
									},
									{
										id: 4806,
										name: "NKOKWE",
										generalAssembly_id: 845,
									},
									{
										id: 4807,
										name: "RUBONA",
										generalAssembly_id: 845,
									},
									{
										id: 4808,
										name: "RUNIGA",
										generalAssembly_id: 845,
									},
									{
										id: 4809,
										name: "TUBUNGO",
										generalAssembly_id: 845,
									},
									{
										id: 9820,
										name: "Rongi  Court of Appeal ",
										generalAssembly_id: 845,
									},
									{
										id: 11353,
										name: "Rongi  Gacaca court of sector ",
										generalAssembly_id: 845,
									},
								],
							},
						],
					},
					{
						id: 20510,
						name: "NDARO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2051005,
								name: "Kinyovi",
								sector_id: 20510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051004,
								name: "Kibanda",
								sector_id: 20510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051003,
								name: "Kabageshi",
								sector_id: 20510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051002,
								name: "Bitabage",
								sector_id: 20510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051001,
								name: "Bijyojyo",
								sector_id: 20510,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 846,
								name: "Ntobwe ",
								sector_id: 20510,
								courtOfCells: [
									{
										id: 4810,
										name: "GAHUNGA",
										generalAssembly_id: 846,
									},
									{
										id: 4811,
										name: "GASHARU",
										generalAssembly_id: 846,
									},
									{
										id: 4812,
										name: "GISEKE",
										generalAssembly_id: 846,
									},
									{
										id: 4813,
										name: "KABAGESHI",
										generalAssembly_id: 846,
									},
									{
										id: 4814,
										name: "MASORO",
										generalAssembly_id: 846,
									},
									{
										id: 4815,
										name: "RUHANGA",
										generalAssembly_id: 846,
									},
									{
										id: 4816,
										name: "RUSEBEYA",
										generalAssembly_id: 846,
									},
									{
										id: 9821,
										name: "Ntobwe  Court of Appeal ",
										generalAssembly_id: 846,
									},
									{
										id: 11354,
										name: "Ntobwe  Gacaca court of sector ",
										generalAssembly_id: 846,
									},
								],
							},
							{
								id: 847,
								name: "Gasave ",
								sector_id: 20510,
								courtOfCells: [
									{
										id: 4817,
										name: "BIJYOJYO",
										generalAssembly_id: 847,
									},
									{
										id: 4818,
										name: "GASAVE",
										generalAssembly_id: 847,
									},
									{
										id: 4819,
										name: "KIBANDE",
										generalAssembly_id: 847,
									},
									{
										id: 4820,
										name: "RUNYONI",
										generalAssembly_id: 847,
									},
									{
										id: 4821,
										name: "RUTONDE",
										generalAssembly_id: 847,
									},
									{
										id: 9822,
										name: "Gasave  Court of Appeal ",
										generalAssembly_id: 847,
									},
									{
										id: 11355,
										name: "Gasave  Gacaca court of sector ",
										generalAssembly_id: 847,
									},
								],
							},
							{
								id: 848,
								name: "Kibanda  ",
								sector_id: 20510,
								courtOfCells: [
									{
										id: 4822,
										name: "CYANJONGO",
										generalAssembly_id: 848,
									},
									{
										id: 4823,
										name: "GISHIRU",
										generalAssembly_id: 848,
									},
									{
										id: 4824,
										name: "KABINGO",
										generalAssembly_id: 848,
									},
									{
										id: 4825,
										name: "KAMINA",
										generalAssembly_id: 848,
									},
									{
										id: 4826,
										name: "KIBANDA",
										generalAssembly_id: 848,
									},
									{
										id: 4827,
										name: "MPANGA",
										generalAssembly_id: 848,
									},
									{
										id: 4828,
										name: "RUSIGA",
										generalAssembly_id: 848,
									},
									{
										id: 4829,
										name: "RWAMATEKE",
										generalAssembly_id: 848,
									},
									{
										id: 9823,
										name: "Kibanda   Court of Appeal ",
										generalAssembly_id: 848,
									},
									{
										id: 11356,
										name: "Kibanda   Gacaca court of sector ",
										generalAssembly_id: 848,
									},
								],
							},
							{
								id: 849,
								name: "Ndaro ",
								sector_id: 20510,
								courtOfCells: [
									{
										id: 4830,
										name: "GITUZA",
										generalAssembly_id: 849,
									},
									{
										id: 4831,
										name: "KINGA",
										generalAssembly_id: 849,
									},
									{
										id: 4832,
										name: "NDARO",
										generalAssembly_id: 849,
									},
									{
										id: 4833,
										name: "NGUGU",
										generalAssembly_id: 849,
									},
									{
										id: 4834,
										name: "RWAMIKERI",
										generalAssembly_id: 849,
									},
									{
										id: 9824,
										name: "Ndaro  Court of Appeal ",
										generalAssembly_id: 849,
									},
									{
										id: 11357,
										name: "Ndaro  Gacaca court of sector ",
										generalAssembly_id: 849,
									},
								],
							},
						],
					},
					{
						id: 20511,
						name: "NGORORERO",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2051106,
								name: "Torero",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051105,
								name: "Rususa",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051104,
								name: "Nyange",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051103,
								name: "Mugano",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051102,
								name: "Kazabe",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051101,
								name: "Kaseke",
								sector_id: 20511,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 850,
								name: "Kiziguro ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4835,
										name: "CYANDAGO",
										generalAssembly_id: 850,
									},
									{
										id: 4836,
										name: "GATARE",
										generalAssembly_id: 850,
									},
									{
										id: 4837,
										name: "GISAYO",
										generalAssembly_id: 850,
									},
									{
										id: 4838,
										name: "KASEKE",
										generalAssembly_id: 850,
									},
									{
										id: 4839,
										name: "MUBUGA",
										generalAssembly_id: 850,
									},
									{
										id: 4840,
										name: "MWIYANIKE",
										generalAssembly_id: 850,
									},
									{
										id: 4841,
										name: "NYAGASAMBU",
										generalAssembly_id: 850,
									},
									{
										id: 4842,
										name: "KIZIGURO",
										generalAssembly_id: 850,
									},
									{
										id: 9825,
										name: "Kiziguro  Court of Appeal ",
										generalAssembly_id: 850,
									},
									{
										id: 11358,
										name: "Kiziguro  Gacaca court of sector ",
										generalAssembly_id: 850,
									},
								],
							},
							{
								id: 851,
								name: "Mbuye ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4843,
										name: "GATARE",
										generalAssembly_id: 851,
									},
									{
										id: 4844,
										name: "GIHE",
										generalAssembly_id: 851,
									},
									{
										id: 4845,
										name: "KARAMA",
										generalAssembly_id: 851,
									},
									{
										id: 4846,
										name: "NYAKAGANZO",
										generalAssembly_id: 851,
									},
									{
										id: 4847,
										name: "NYANGE",
										generalAssembly_id: 851,
									},
									{
										id: 4848,
										name: "TURAMIGINA",
										generalAssembly_id: 851,
									},
									{
										id: 9826,
										name: "Mbuye  Court of Appeal ",
										generalAssembly_id: 851,
									},
									{
										id: 11359,
										name: "Mbuye  Gacaca court of sector ",
										generalAssembly_id: 851,
									},
								],
							},
							{
								id: 852,
								name: "Ngororero  ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4849,
										name: "KABAGARI",
										generalAssembly_id: 852,
									},
									{
										id: 4850,
										name: "MAZIMERU",
										generalAssembly_id: 852,
									},
									{
										id: 4851,
										name: "MURAMBI",
										generalAssembly_id: 852,
									},
									{
										id: 4852,
										name: "NGORORERO",
										generalAssembly_id: 852,
									},
									{
										id: 4853,
										name: "RUKARAGATA",
										generalAssembly_id: 852,
									},
									{
										id: 4854,
										name: "RUSUSA",
										generalAssembly_id: 852,
									},
									{
										id: 9827,
										name: "Ngororero   Court of Appeal ",
										generalAssembly_id: 852,
									},
									{
										id: 11360,
										name: "Ngororero   Gacaca court of sector ",
										generalAssembly_id: 852,
									},
								],
							},
							{
								id: 853,
								name: "Mpara  ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4855,
										name: "GAKOMA",
										generalAssembly_id: 853,
									},
									{
										id: 4856,
										name: "KANIGA",
										generalAssembly_id: 853,
									},
									{
										id: 4857,
										name: "MUGANO",
										generalAssembly_id: 853,
									},
									{
										id: 4858,
										name: "NGANZO",
										generalAssembly_id: 853,
									},
									{
										id: 4859,
										name: "NYAMABUYE",
										generalAssembly_id: 853,
									},
									{
										id: 4860,
										name: "NYARUNYINYA",
										generalAssembly_id: 853,
									},
									{
										id: 4861,
										name: "RWEZAMENYO",
										generalAssembly_id: 853,
									},
									{
										id: 9828,
										name: "Mpara   Court of Appeal ",
										generalAssembly_id: 853,
									},
									{
										id: 11361,
										name: "Mpara   Gacaca court of sector ",
										generalAssembly_id: 853,
									},
								],
							},
							{
								id: 854,
								name: "Murambi  ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4862,
										name: "BUTEZI",
										generalAssembly_id: 854,
									},
									{
										id: 4863,
										name: "CYANSI",
										generalAssembly_id: 854,
									},
									{
										id: 4864,
										name: "CYUMBA",
										generalAssembly_id: 854,
									},
									{
										id: 4865,
										name: "GASARARA",
										generalAssembly_id: 854,
									},
									{
										id: 4866,
										name: "MURAMBI",
										generalAssembly_id: 854,
									},
									{
										id: 4867,
										name: "NYARUBINGO",
										generalAssembly_id: 854,
									},
									{
										id: 9829,
										name: "Murambi   Court of Appeal ",
										generalAssembly_id: 854,
									},
									{
										id: 11362,
										name: "Murambi   Gacaca court of sector ",
										generalAssembly_id: 854,
									},
								],
							},
							{
								id: 855,
								name: "Ruhunga ",
								sector_id: 20511,
								courtOfCells: [
									{
										id: 4868,
										name: "GITWA",
										generalAssembly_id: 855,
									},
									{
										id: 4869,
										name: "KARERA",
										generalAssembly_id: 855,
									},
									{
										id: 4870,
										name: "KAZABE",
										generalAssembly_id: 855,
									},
									{
										id: 4871,
										name: "KIRWA",
										generalAssembly_id: 855,
									},
									{
										id: 4872,
										name: "NYAKARIBA",
										generalAssembly_id: 855,
									},
									{
										id: 4873,
										name: "NYAMABUYE",
										generalAssembly_id: 855,
									},
									{
										id: 4874,
										name: "RUHUNGA_I",
										generalAssembly_id: 855,
									},
									{
										id: 4875,
										name: "RUHUNGA_II",
										generalAssembly_id: 855,
									},
									{
										id: 9830,
										name: "Ruhunga  Court of Appeal ",
										generalAssembly_id: 855,
									},
									{
										id: 11363,
										name: "Ruhunga  Gacaca court of sector ",
										generalAssembly_id: 855,
									},
								],
							},
						],
					},
					{
						id: 20512,
						name: "NYANGE",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2051204,
								name: "Vuganyana",
								sector_id: 20512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051203,
								name: "Nsibo",
								sector_id: 20512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051202,
								name: "Gaseke",
								sector_id: 20512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051201,
								name: "Bambiro",
								sector_id: 20512,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 856,
								name: "Kigali  ",
								sector_id: 20512,
								courtOfCells: [
									{
										id: 4876,
										name: "BUGABE",
										generalAssembly_id: 856,
									},
									{
										id: 4877,
										name: "BUTARE",
										generalAssembly_id: 856,
									},
									{
										id: 4878,
										name: "GAKOMA",
										generalAssembly_id: 856,
									},
									{
										id: 4879,
										name: "MUZI",
										generalAssembly_id: 856,
									},
									{
										id: 4880,
										name: "NYARUSHUBI",
										generalAssembly_id: 856,
									},
									{
										id: 4881,
										name: "RWASANKUBA",
										generalAssembly_id: 856,
									},
									{
										id: 9831,
										name: "Kigali   Court of Appeal ",
										generalAssembly_id: 856,
									},
									{
										id: 11364,
										name: "Kigali   Gacaca court of sector ",
										generalAssembly_id: 856,
									},
								],
							},
							{
								id: 857,
								name: "Kivumu  ",
								sector_id: 20512,
								courtOfCells: [
									{
										id: 4882,
										name: "KAKINYONI",
										generalAssembly_id: 857,
									},
									{
										id: 4883,
										name: "KAMURIZA",
										generalAssembly_id: 857,
									},
									{
										id: 4884,
										name: "KARAMBO",
										generalAssembly_id: 857,
									},
									{
										id: 4885,
										name: "KAZENGA",
										generalAssembly_id: 857,
									},
									{
										id: 4886,
										name: "MBOGO",
										generalAssembly_id: 857,
									},
									{
										id: 4887,
										name: "NGORORE",
										generalAssembly_id: 857,
									},
									{
										id: 4888,
										name: "NYAGATAMA",
										generalAssembly_id: 857,
									},
									{
										id: 4889,
										name: "NYAMYUNGO",
										generalAssembly_id: 857,
									},
									{
										id: 9832,
										name: "Kivumu   Court of Appeal ",
										generalAssembly_id: 857,
									},
									{
										id: 11365,
										name: "Kivumu   Gacaca court of sector ",
										generalAssembly_id: 857,
									},
								],
							},
							{
								id: 858,
								name: "Ngobagoba  ",
								sector_id: 20512,
								courtOfCells: [
									{
										id: 4890,
										name: "DUTWE",
										generalAssembly_id: 858,
									},
									{
										id: 4891,
										name: "GASEKE",
										generalAssembly_id: 858,
									},
									{
										id: 4892,
										name: "GIKO",
										generalAssembly_id: 858,
									},
									{
										id: 4893,
										name: "KABUYE",
										generalAssembly_id: 858,
									},
									{
										id: 4894,
										name: "NGOBAGOBA",
										generalAssembly_id: 858,
									},
									{
										id: 9833,
										name: "Ngobagoba   Court of Appeal ",
										generalAssembly_id: 858,
									},
									{
										id: 11366,
										name: "Ngobagoba   Gacaca court of sector ",
										generalAssembly_id: 858,
									},
								],
							},
							{
								id: 859,
								name: "Nyange  ",
								sector_id: 20512,
								courtOfCells: [
									{
										id: 4895,
										name: "CYAMBOGO",
										generalAssembly_id: 859,
									},
									{
										id: 4896,
										name: "KANYINYA",
										generalAssembly_id: 859,
									},
									{
										id: 4897,
										name: "MUGANZA",
										generalAssembly_id: 859,
									},
									{
										id: 4898,
										name: "MURAMBI",
										generalAssembly_id: 859,
									},
									{
										id: 4899,
										name: "NSIBO",
										generalAssembly_id: 859,
									},
									{
										id: 4900,
										name: "NYANGE",
										generalAssembly_id: 859,
									},
									{
										id: 4901,
										name: "VUNGU",
										generalAssembly_id: 859,
									},
									{
										id: 4902,
										name: "ZEGENYA",
										generalAssembly_id: 859,
									},
									{
										id: 9834,
										name: "Nyange   Court of Appeal ",
										generalAssembly_id: 859,
									},
									{
										id: 11367,
										name: "Nyange   Gacaca court of sector ",
										generalAssembly_id: 859,
									},
								],
							},
						],
					},
					{
						id: 20513,
						name: "SOVU",
						district_id: 205,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2051306,
								name: "Rutovu",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051305,
								name: "Nyabipfura",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051304,
								name: "Musenyi",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051303,
								name: "Kanyana",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051302,
								name: "Kagano",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2051301,
								name: "Birembo",
								sector_id: 20513,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 860,
								name: "Mabuye  ",
								sector_id: 20513,
								courtOfCells: [
									{
										id: 4903,
										name: "BIBUNGO",
										generalAssembly_id: 860,
									},
									{
										id: 4904,
										name: "MUGEGA",
										generalAssembly_id: 860,
									},
									{
										id: 4905,
										name: "NYABIPFURA",
										generalAssembly_id: 860,
									},
									{
										id: 4906,
										name: "NYAMPINGIRA",
										generalAssembly_id: 860,
									},
									{
										id: 4907,
										name: "NYARWUMBA",
										generalAssembly_id: 860,
									},
									{
										id: 9835,
										name: "Mabuye   Court of Appeal ",
										generalAssembly_id: 860,
									},
									{
										id: 11368,
										name: "Mabuye   Gacaca court of sector ",
										generalAssembly_id: 860,
									},
								],
							},
							{
								id: 861,
								name: "Sovu ",
								sector_id: 20513,
								courtOfCells: [
									{
										id: 4908,
										name: "BURINDA",
										generalAssembly_id: 861,
									},
									{
										id: 4909,
										name: "MAHEMBE",
										generalAssembly_id: 861,
									},
									{
										id: 4910,
										name: "MWUMBA",
										generalAssembly_id: 861,
									},
									{
										id: 4911,
										name: "RUKERI",
										generalAssembly_id: 861,
									},
									{
										id: 4912,
										name: "RUSENGE",
										generalAssembly_id: 861,
									},
									{
										id: 9836,
										name: "Sovu  Court of Appeal ",
										generalAssembly_id: 861,
									},
									{
										id: 11369,
										name: "Sovu  Gacaca court of sector ",
										generalAssembly_id: 861,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 204,
				name: "Nyabihu",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20401,
						name: "Bigogwe",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040106,
								name: "Rega",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040105,
								name: "Muhe",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040104,
								name: "Kora",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040103,
								name: "Kijote",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040102,
								name: "Basumba",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040101,
								name: "Arusha",
								sector_id: 20401,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 862,
								name: "Cyambara ",
								sector_id: 20401,
								courtOfCells: [
									{
										id: 4913,
										name: "ARUSHA",
										generalAssembly_id: 862,
									},
									{
										id: 4914,
										name: "GATURO",
										generalAssembly_id: 862,
									},
									{
										id: 4915,
										name: "KINYAMIYAGA",
										generalAssembly_id: 862,
									},
									{
										id: 4916,
										name: "MURAGO",
										generalAssembly_id: 862,
									},
									{
										id: 4917,
										name: "MWUNAMYI",
										generalAssembly_id: 862,
									},
									{
										id: 4918,
										name: "NGAMBA",
										generalAssembly_id: 862,
									},
									{
										id: 4919,
										name: "NGANDU",
										generalAssembly_id: 862,
									},
									{
										id: 4920,
										name: "NGANGARE",
										generalAssembly_id: 862,
									},
									{
										id: 4921,
										name: "NYAGAFUMBERI",
										generalAssembly_id: 862,
									},
									{
										id: 4922,
										name: "NYAGIHINGA",
										generalAssembly_id: 862,
									},
									{
										id: 4923,
										name: "REGA",
										generalAssembly_id: 862,
									},
									{
										id: 4924,
										name: "AKABIDEHE",
										generalAssembly_id: 862,
									},
									{
										id: 9837,
										name: "Cyambara  Court of Appeal ",
										generalAssembly_id: 862,
									},
									{
										id: 11370,
										name: "Cyambara  Gacaca court of sector ",
										generalAssembly_id: 862,
									},
								],
							},
							{
								id: 863,
								name: "Kora ",
								sector_id: 20401,
								courtOfCells: [
									{
										id: 4925,
										name: "BASUMBA",
										generalAssembly_id: 863,
									},
									{
										id: 4926,
										name: "GATAGARA",
										generalAssembly_id: 863,
									},
									{
										id: 4927,
										name: "KIJOTE_I",
										generalAssembly_id: 863,
									},
									{
										id: 4928,
										name: "KIJOTE_II",
										generalAssembly_id: 863,
									},
									{
										id: 4929,
										name: "N° 5",
										generalAssembly_id: 863,
									},
									{
										id: 4930,
										name: "N° 6",
										generalAssembly_id: 863,
									},
									{
										id: 4931,
										name: "RUHINGA",
										generalAssembly_id: 863,
									},
									{
										id: 4932,
										name: "RUKORE",
										generalAssembly_id: 863,
									},
									{
										id: 4933,
										name: "RUSENGE_I",
										generalAssembly_id: 863,
									},
									{
										id: 4934,
										name: "RUSENGE_II",
										generalAssembly_id: 863,
									},
									{
										id: 4935,
										name: "RWANKUBA",
										generalAssembly_id: 863,
									},
									{
										id: 4936,
										name: "VUGA",
										generalAssembly_id: 863,
									},
									{
										id: 4937,
										name: "BIHANGARA",
										generalAssembly_id: 863,
									},
									{
										id: 9838,
										name: "Kora  Court of Appeal ",
										generalAssembly_id: 863,
									},
									{
										id: 11371,
										name: "Kora  Gacaca court of sector ",
										generalAssembly_id: 863,
									},
								],
							},
						],
					},
					{
						id: 20402,
						name: "Jenda",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040206,
								name: "Rega",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040205,
								name: "Nyirakigugu",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040204,
								name: "Kareba",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040203,
								name: "Kabatezi",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040202,
								name: "Gasizi",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040201,
								name: "Bukinanyana",
								sector_id: 20402,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 864,
								name: "Jenda ",
								sector_id: 20402,
								courtOfCells: [
									{
										id: 4938,
										name: "GISOZI",
										generalAssembly_id: 864,
									},
									{
										id: 4939,
										name: "KABATEZI",
										generalAssembly_id: 864,
									},
									{
										id: 4940,
										name: "KAGANO",
										generalAssembly_id: 864,
									},
									{
										id: 4941,
										name: "NYAMUTUKURA",
										generalAssembly_id: 864,
									},
									{
										id: 4942,
										name: "RUNYANJA",
										generalAssembly_id: 864,
									},
									{
										id: 4943,
										name: "RURAJE",
										generalAssembly_id: 864,
									},
									{
										id: 9839,
										name: "Jenda  Court of Appeal ",
										generalAssembly_id: 864,
									},
									{
										id: 11372,
										name: "Jenda  Gacaca court of sector ",
										generalAssembly_id: 864,
									},
								],
							},
							{
								id: 865,
								name: "Kareba ",
								sector_id: 20402,
								courtOfCells: [
									{
										id: 4944,
										name: "KANZENZE",
										generalAssembly_id: 865,
									},
									{
										id: 4945,
										name: "KAREBA",
										generalAssembly_id: 865,
									},
									{
										id: 4946,
										name: "KINYANGAGI",
										generalAssembly_id: 865,
									},
									{
										id: 4947,
										name: "RUSENGE",
										generalAssembly_id: 865,
									},
									{
										id: 4948,
										name: "RUSIZA",
										generalAssembly_id: 865,
									},
									{
										id: 9840,
										name: "Kareba  Court of Appeal ",
										generalAssembly_id: 865,
									},
									{
										id: 11373,
										name: "Kareba  Gacaca court of sector ",
										generalAssembly_id: 865,
									},
								],
							},
							{
								id: 866,
								name: "Gakarara ",
								sector_id: 20402,
								courtOfCells: [
									{
										id: 4949,
										name: "BIHINGA",
										generalAssembly_id: 866,
									},
									{
										id: 4950,
										name: "BUGARAMA",
										generalAssembly_id: 866,
									},
									{
										id: 4951,
										name: "BUKINANYANA",
										generalAssembly_id: 866,
									},
									{
										id: 4952,
										name: "GASESERO",
										generalAssembly_id: 866,
									},
									{
										id: 4953,
										name: "KAJEBESHI",
										generalAssembly_id: 866,
									},
									{
										id: 4954,
										name: "REGA",
										generalAssembly_id: 866,
									},
									{
										id: 4955,
										name: "RUSENGE",
										generalAssembly_id: 866,
									},
									{
										id: 9841,
										name: "Gakarara  Court of Appeal ",
										generalAssembly_id: 866,
									},
									{
										id: 11374,
										name: "Gakarara  Gacaca court of sector ",
										generalAssembly_id: 866,
									},
								],
							},
						],
					},
					{
						id: 20403,
						name: "Jomba",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040306,
								name: "Nyamitanzi",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040305,
								name: "Kavumu",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040304,
								name: "Guriro",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040303,
								name: "Gisizi",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040302,
								name: "Gasura",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040301,
								name: "Gasiza",
								sector_id: 20403,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 867,
								name: "Gasasa ",
								sector_id: 20403,
								courtOfCells: [
									{
										id: 4956,
										name: "BIHINGA",
										generalAssembly_id: 867,
									},
									{
										id: 4957,
										name: "GASASA",
										generalAssembly_id: 867,
									},
									{
										id: 4958,
										name: "NYABUGUSA",
										generalAssembly_id: 867,
									},
									{
										id: 4959,
										name: "NYARUSONGATI",
										generalAssembly_id: 867,
									},
									{
										id: 4960,
										name: "RUTABU",
										generalAssembly_id: 867,
									},
									{
										id: 9842,
										name: "Gasasa  Court of Appeal ",
										generalAssembly_id: 867,
									},
									{
										id: 11375,
										name: "Gasasa  Gacaca court of sector ",
										generalAssembly_id: 867,
									},
								],
							},
							{
								id: 868,
								name: "Jomba ",
								sector_id: 20403,
								courtOfCells: [
									{
										id: 4961,
										name: "BWANAMWARI",
										generalAssembly_id: 868,
									},
									{
										id: 4962,
										name: "GAHANGA",
										generalAssembly_id: 868,
									},
									{
										id: 4963,
										name: "GISIZA",
										generalAssembly_id: 868,
									},
									{
										id: 4964,
										name: "KAVUMU",
										generalAssembly_id: 868,
									},
									{
										id: 4965,
										name: "MUHARI",
										generalAssembly_id: 868,
									},
									{
										id: 4966,
										name: "RUGERERO",
										generalAssembly_id: 868,
									},
									{
										id: 9843,
										name: "Jomba  Court of Appeal ",
										generalAssembly_id: 868,
									},
									{
										id: 11376,
										name: "Jomba  Gacaca court of sector ",
										generalAssembly_id: 868,
									},
								],
							},
							{
								id: 869,
								name: "Murambi ",
								sector_id: 20403,
								courtOfCells: [
									{
										id: 4967,
										name: "GASURA",
										generalAssembly_id: 869,
									},
									{
										id: 4968,
										name: "GISORO",
										generalAssembly_id: 869,
									},
									{
										id: 4969,
										name: "KAGANO",
										generalAssembly_id: 869,
									},
									{
										id: 4970,
										name: "NGABO",
										generalAssembly_id: 869,
									},
									{
										id: 4971,
										name: "RUHUNGA",
										generalAssembly_id: 869,
									},
									{
										id: 4972,
										name: "RWANDARUGARI",
										generalAssembly_id: 869,
									},
									{
										id: 9844,
										name: "Murambi  Court of Appeal ",
										generalAssembly_id: 869,
									},
									{
										id: 11377,
										name: "Murambi  Gacaca court of sector ",
										generalAssembly_id: 869,
									},
								],
							},
							{
								id: 870,
								name: "Rubona ",
								sector_id: 20403,
								courtOfCells: [
									{
										id: 4973,
										name: "CYUMBA",
										generalAssembly_id: 870,
									},
									{
										id: 4974,
										name: "GASIZA",
										generalAssembly_id: 870,
									},
									{
										id: 4975,
										name: "GIKARANKA",
										generalAssembly_id: 870,
									},
									{
										id: 4976,
										name: "KABARI",
										generalAssembly_id: 870,
									},
									{
										id: 4977,
										name: "KABINGO",
										generalAssembly_id: 870,
									},
									{
										id: 4978,
										name: "NYUNDO",
										generalAssembly_id: 870,
									},
									{
										id: 9845,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 870,
									},
									{
										id: 11378,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 870,
									},
								],
							},
						],
					},
					{
						id: 20404,
						name: "Kabatwa",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040406,
								name: "Rugarama",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040405,
								name: "Ngando",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040404,
								name: "Myuga",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040403,
								name: "Gihorwe",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040402,
								name: "Cyamvumba",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040401,
								name: "Batikoti",
								sector_id: 20404,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 871,
								name: "Gihorwe ",
								sector_id: 20404,
								courtOfCells: [
									{
										id: 4979,
										name: "BISUKIRO",
										generalAssembly_id: 871,
									},
									{
										id: 4980,
										name: "CYAMVUMBA",
										generalAssembly_id: 871,
									},
									{
										id: 4981,
										name: "GIHORWE",
										generalAssembly_id: 871,
									},
									{
										id: 4982,
										name: "NGANDO",
										generalAssembly_id: 871,
									},
									{
										id: 4983,
										name: "RUGARAMA",
										generalAssembly_id: 871,
									},
									{
										id: 4984,
										name: "RUMARANYONI",
										generalAssembly_id: 871,
									},
									{
										id: 9846,
										name: "Gihorwe  Court of Appeal ",
										generalAssembly_id: 871,
									},
									{
										id: 11379,
										name: "Gihorwe  Gacaca court of sector ",
										generalAssembly_id: 871,
									},
								],
							},
							{
								id: 872,
								name: "Kabatwa ",
								sector_id: 20404,
								courtOfCells: [
									{
										id: 4985,
										name: "N°1_H.P",
										generalAssembly_id: 872,
									},
									{
										id: 4986,
										name: "N°1_P",
										generalAssembly_id: 872,
									},
									{
										id: 4987,
										name: "N°2_H.P",
										generalAssembly_id: 872,
									},
									{
										id: 4988,
										name: "N°2_P",
										generalAssembly_id: 872,
									},
									{
										id: 4989,
										name: "N°3_H. P",
										generalAssembly_id: 872,
									},
									{
										id: 4990,
										name: "N°3_P",
										generalAssembly_id: 872,
									},
									{
										id: 4991,
										name: "N°4_P",
										generalAssembly_id: 872,
									},
									{
										id: 4992,
										name: "N°5_P",
										generalAssembly_id: 872,
									},
									{
										id: 4993,
										name: "N°6_P",
										generalAssembly_id: 872,
									},
									{
										id: 4994,
										name: "RUGENDABARI",
										generalAssembly_id: 872,
									},
									{
										id: 9847,
										name: "Kabatwa  Court of Appeal ",
										generalAssembly_id: 872,
									},
									{
										id: 11380,
										name: "Kabatwa  Gacaca court of sector ",
										generalAssembly_id: 872,
									},
								],
							},
						],
					},
					{
						id: 20405,
						name: "Karago",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040506,
								name: "Karengera",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040505,
								name: "Kadahenda",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040504,
								name: "Gihirwa",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040503,
								name: "Gatagara",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040502,
								name: "Cyamabuye",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040501,
								name: "Busoro",
								sector_id: 20405,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 873,
								name: "Mwiyanike ",
								sector_id: 20405,
								courtOfCells: [
									{
										id: 4995,
										name: "GIHIRA",
										generalAssembly_id: 873,
									},
									{
										id: 4996,
										name: "KARANDARYI",
										generalAssembly_id: 873,
									},
									{
										id: 4997,
										name: "NYABIHU",
										generalAssembly_id: 873,
									},
									{
										id: 4998,
										name: "NYABURARO",
										generalAssembly_id: 873,
									},
									{
										id: 4999,
										name: "RUGOGWE",
										generalAssembly_id: 873,
									},
									{
										id: 9848,
										name: "Mwiyanike  Court of Appeal ",
										generalAssembly_id: 873,
									},
									{
										id: 11381,
										name: "Mwiyanike  Gacaca court of sector ",
										generalAssembly_id: 873,
									},
								],
							},
							{
								id: 874,
								name: "Nanga ",
								sector_id: 20405,
								courtOfCells: [
									{
										id: 5000,
										name: "BUREMERA",
										generalAssembly_id: 874,
									},
									{
										id: 5001,
										name: "CYAMABUYE",
										generalAssembly_id: 874,
									},
									{
										id: 5002,
										name: "GASASA",
										generalAssembly_id: 874,
									},
									{
										id: 5003,
										name: "KAGOHE",
										generalAssembly_id: 874,
									},
									{
										id: 5004,
										name: "MUREMURE",
										generalAssembly_id: 874,
									},
									{
										id: 5005,
										name: "RUBARE",
										generalAssembly_id: 874,
									},
									{
										id: 5006,
										name: "RUHIGIRO",
										generalAssembly_id: 874,
									},
									{
										id: 9849,
										name: "Nanga  Court of Appeal ",
										generalAssembly_id: 874,
									},
									{
										id: 11382,
										name: "Nanga  Gacaca court of sector ",
										generalAssembly_id: 874,
									},
								],
							},
							{
								id: 875,
								name: "Ndorwa ",
								sector_id: 20405,
								courtOfCells: [
									{
										id: 5007,
										name: "BIKERERI",
										generalAssembly_id: 875,
									},
									{
										id: 5008,
										name: "GATAGARA",
										generalAssembly_id: 875,
									},
									{
										id: 5009,
										name: "KARENGERA",
										generalAssembly_id: 875,
									},
									{
										id: 5010,
										name: "KINANIRA",
										generalAssembly_id: 875,
									},
									{
										id: 5011,
										name: "KIRWA",
										generalAssembly_id: 875,
									},
									{
										id: 5012,
										name: "RUYEBE",
										generalAssembly_id: 875,
									},
									{
										id: 5013,
										name: "REMERA",
										generalAssembly_id: 875,
									},
									{
										id: 9850,
										name: "Ndorwa  Court of Appeal ",
										generalAssembly_id: 875,
									},
									{
										id: 11383,
										name: "Ndorwa  Gacaca court of sector ",
										generalAssembly_id: 875,
									},
								],
							},
						],
					},
					{
						id: 20406,
						name: "Kintobo",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040606,
								name: "Ryinyo",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040605,
								name: "Rukondo",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040604,
								name: "Nyamugari",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040603,
								name: "Nyagisozi",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040602,
								name: "Kintobo",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040601,
								name: "Gatovu",
								sector_id: 20406,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 876,
								name: "Gatovu ",
								sector_id: 20406,
								courtOfCells: [
									{
										id: 5014,
										name: "GATOVU",
										generalAssembly_id: 876,
									},
									{
										id: 5015,
										name: "GITORWA",
										generalAssembly_id: 876,
									},
									{
										id: 5016,
										name: "KIMPUNDU",
										generalAssembly_id: 876,
									},
									{
										id: 5017,
										name: "NYARUSEKERA",
										generalAssembly_id: 876,
									},
									{
										id: 5018,
										name: "RUKONDO",
										generalAssembly_id: 876,
									},
									{
										id: 9851,
										name: "Gatovu  Court of Appeal ",
										generalAssembly_id: 876,
									},
									{
										id: 11384,
										name: "Gatovu  Gacaca court of sector ",
										generalAssembly_id: 876,
									},
								],
							},
							{
								id: 877,
								name: "Kintobo ",
								sector_id: 20406,
								courtOfCells: [
									{
										id: 5019,
										name: "HUNGIRO",
										generalAssembly_id: 877,
									},
									{
										id: 5020,
										name: "KINTOBO",
										generalAssembly_id: 877,
									},
									{
										id: 5021,
										name: "NYAGISOZI",
										generalAssembly_id: 877,
									},
									{
										id: 5022,
										name: "NYAMABUNO",
										generalAssembly_id: 877,
									},
									{
										id: 5023,
										name: "NYANSHUNDURA",
										generalAssembly_id: 877,
									},
									{
										id: 9852,
										name: "Kintobo  Court of Appeal ",
										generalAssembly_id: 877,
									},
									{
										id: 11385,
										name: "Kintobo  Gacaca court of sector ",
										generalAssembly_id: 877,
									},
								],
							},
							{
								id: 878,
								name: "Runigi ",
								sector_id: 20406,
								courtOfCells: [
									{
										id: 5024,
										name: "GASURA",
										generalAssembly_id: 878,
									},
									{
										id: 5025,
										name: "KAMANGA",
										generalAssembly_id: 878,
									},
									{
										id: 5026,
										name: "KIYUMBA",
										generalAssembly_id: 878,
									},
									{
										id: 5027,
										name: "NYAMUGARI",
										generalAssembly_id: 878,
									},
									{
										id: 9853,
										name: "Runigi  Court of Appeal ",
										generalAssembly_id: 878,
									},
									{
										id: 11386,
										name: "Runigi  Gacaca court of sector ",
										generalAssembly_id: 878,
									},
								],
							},
							{
								id: 879,
								name: "Ryinyo ",
								sector_id: 20406,
								courtOfCells: [
									{
										id: 5028,
										name: "GASHARA",
										generalAssembly_id: 879,
									},
									{
										id: 5029,
										name: "GITABA",
										generalAssembly_id: 879,
									},
									{
										id: 5030,
										name: "KIRWA",
										generalAssembly_id: 879,
									},
									{
										id: 5031,
										name: "RYINYO",
										generalAssembly_id: 879,
									},
									{
										id: 9854,
										name: "Ryinyo  Court of Appeal ",
										generalAssembly_id: 879,
									},
									{
										id: 11387,
										name: "Ryinyo  Gacaca court of sector ",
										generalAssembly_id: 879,
									},
								],
							},
						],
					},
					{
						id: 20407,
						name: "Mukamira",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040707,
								name: "Rurengeri",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040706,
								name: "Rukoma",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040705,
								name: "Rugeshi",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040704,
								name: "Rubaya",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040703,
								name: "Kanyove",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040702,
								name: "Jaba",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040701,
								name: "Gasizi",
								sector_id: 20407,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 880,
								name: "Gitwa ",
								sector_id: 20407,
								courtOfCells: [
									{
										id: 5032,
										name: "GITWA",
										generalAssembly_id: 880,
									},
									{
										id: 5033,
										name: "KABYAZA",
										generalAssembly_id: 880,
									},
									{
										id: 5034,
										name: "KINYABABA",
										generalAssembly_id: 880,
									},
									{
										id: 5035,
										name: "RUBAYA",
										generalAssembly_id: 880,
									},
									{
										id: 5036,
										name: "RURENGERI",
										generalAssembly_id: 880,
									},
									{
										id: 5037,
										name: "RWAMIKERI",
										generalAssembly_id: 880,
									},
									{
										id: 9855,
										name: "Gitwa  Court of Appeal ",
										generalAssembly_id: 880,
									},
									{
										id: 11388,
										name: "Gitwa  Gacaca court of sector ",
										generalAssembly_id: 880,
									},
								],
							},
							{
								id: 881,
								name: "Mukamira ",
								sector_id: 20407,
								courtOfCells: [
									{
										id: 5038,
										name: "HESHA",
										generalAssembly_id: 881,
									},
									{
										id: 5039,
										name: "JABA",
										generalAssembly_id: 881,
									},
									{
										id: 5040,
										name: "KANYAMPERERI",
										generalAssembly_id: 881,
									},
									{
										id: 5041,
										name: "MUTOVU",
										generalAssembly_id: 881,
									},
									{
										id: 5042,
										name: "RUGESHI",
										generalAssembly_id: 881,
									},
									{
										id: 9856,
										name: "Mukamira  Court of Appeal ",
										generalAssembly_id: 881,
									},
									{
										id: 11389,
										name: "Mukamira  Gacaca court of sector ",
										generalAssembly_id: 881,
									},
								],
							},
							{
								id: 882,
								name: "Musumba ",
								sector_id: 20407,
								courtOfCells: [
									{
										id: 5043,
										name: "GASIZI",
										generalAssembly_id: 882,
									},
									{
										id: 5044,
										name: "KANYOVE",
										generalAssembly_id: 882,
									},
									{
										id: 5045,
										name: "RWASEKA",
										generalAssembly_id: 882,
									},
									{
										id: 5046,
										name: "SASANGABO",
										generalAssembly_id: 882,
									},
									{
										id: 9857,
										name: "Musumba  Court of Appeal ",
										generalAssembly_id: 882,
									},
									{
										id: 11390,
										name: "Musumba  Gacaca court of sector ",
										generalAssembly_id: 882,
									},
								],
							},
							{
								id: 883,
								name: "Rukoma  ",
								sector_id: 20407,
								courtOfCells: [
									{
										id: 5047,
										name: "BIHINGA",
										generalAssembly_id: 883,
									},
									{
										id: 5048,
										name: "GATARE",
										generalAssembly_id: 883,
									},
									{
										id: 5049,
										name: "RUGARAGARA",
										generalAssembly_id: 883,
									},
									{
										id: 5050,
										name: "RUKOMA",
										generalAssembly_id: 883,
									},
									{
										id: 9858,
										name: "Rukoma   Court of Appeal ",
										generalAssembly_id: 883,
									},
									{
										id: 11391,
										name: "Rukoma   Gacaca court of sector ",
										generalAssembly_id: 883,
									},
								],
							},
						],
					},
					{
						id: 20408,
						name: "Muringa",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040806,
								name: "Rwantobo",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040805,
								name: "Nyamasheke",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040804,
								name: "Nkomane",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040803,
								name: "Mwiyanike",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040802,
								name: "Mulinga",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040801,
								name: "Gisizi",
								sector_id: 20408,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 884,
								name: "Gihira  ",
								sector_id: 20408,
								courtOfCells: [
									{
										id: 5051,
										name: "GAHUNGERI",
										generalAssembly_id: 884,
									},
									{
										id: 5052,
										name: "GASURA",
										generalAssembly_id: 884,
									},
									{
										id: 5053,
										name: "GIHIRA",
										generalAssembly_id: 884,
									},
									{
										id: 5054,
										name: "GITEBE",
										generalAssembly_id: 884,
									},
									{
										id: 5055,
										name: "MUREMURE",
										generalAssembly_id: 884,
									},
									{
										id: 5056,
										name: "RWANTOBO",
										generalAssembly_id: 884,
									},
									{
										id: 5057,
										name: "RYAMWANA",
										generalAssembly_id: 884,
									},
									{
										id: 9859,
										name: "Gihira   Court of Appeal ",
										generalAssembly_id: 884,
									},
									{
										id: 11392,
										name: "Gihira   Gacaca court of sector ",
										generalAssembly_id: 884,
									},
								],
							},
							{
								id: 885,
								name: "Nyamugeyo  ",
								sector_id: 20408,
								courtOfCells: [
									{
										id: 5058,
										name: "KAGARAMA",
										generalAssembly_id: 885,
									},
									{
										id: 5059,
										name: "KIRUMA",
										generalAssembly_id: 885,
									},
									{
										id: 5060,
										name: "MIGONGO",
										generalAssembly_id: 885,
									},
									{
										id: 5061,
										name: "MURINGA",
										generalAssembly_id: 885,
									},
									{
										id: 5062,
										name: "NKOMANE",
										generalAssembly_id: 885,
									},
									{
										id: 5063,
										name: "RWARAMO",
										generalAssembly_id: 885,
									},
									{
										id: 9860,
										name: "Nyamugeyo   Court of Appeal ",
										generalAssembly_id: 885,
									},
									{
										id: 11393,
										name: "Nyamugeyo   Gacaca court of sector ",
										generalAssembly_id: 885,
									},
								],
							},
							{
								id: 886,
								name: "Rubare ",
								sector_id: 20408,
								courtOfCells: [
									{
										id: 5064,
										name: "GISIZI",
										generalAssembly_id: 886,
									},
									{
										id: 5065,
										name: "KINIHIRA",
										generalAssembly_id: 886,
									},
									{
										id: 5066,
										name: "MUREMURE",
										generalAssembly_id: 886,
									},
									{
										id: 5067,
										name: "RUBARE",
										generalAssembly_id: 886,
									},
									{
										id: 5068,
										name: "RUHANGA",
										generalAssembly_id: 886,
									},
									{
										id: 9861,
										name: "Rubare  Court of Appeal ",
										generalAssembly_id: 886,
									},
									{
										id: 11394,
										name: "Rubare  Gacaca court of sector ",
										generalAssembly_id: 886,
									},
								],
							},
						],
					},
					{
						id: 20409,
						name: "Rambura",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2040906,
								name: "Rugamba",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040905,
								name: "Nyundo",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040904,
								name: "Mutaho",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040903,
								name: "Kibisabo",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040902,
								name: "Guriro",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2040901,
								name: "Birembo",
								sector_id: 20409,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 887,
								name: "Birembo  ",
								sector_id: 20409,
								courtOfCells: [
									{
										id: 5069,
										name: "BIREMBO",
										generalAssembly_id: 887,
									},
									{
										id: 5070,
										name: "CYUGI",
										generalAssembly_id: 887,
									},
									{
										id: 5071,
										name: "GASIZA",
										generalAssembly_id: 887,
									},
									{
										id: 5072,
										name: "KARAMBI",
										generalAssembly_id: 887,
									},
									{
										id: 5073,
										name: "MARIBA",
										generalAssembly_id: 887,
									},
									{
										id: 5074,
										name: "NYAVUVU",
										generalAssembly_id: 887,
									},
									{
										id: 9862,
										name: "Birembo   Court of Appeal ",
										generalAssembly_id: 887,
									},
									{
										id: 11395,
										name: "Birembo   Gacaca court of sector ",
										generalAssembly_id: 887,
									},
								],
							},
							{
								id: 888,
								name: "Bumba  ",
								sector_id: 20409,
								courtOfCells: [
									{
										id: 5075,
										name: "GULIRO",
										generalAssembly_id: 888,
									},
									{
										id: 5076,
										name: "NTARAMA",
										generalAssembly_id: 888,
									},
									{
										id: 5077,
										name: "NTEKO",
										generalAssembly_id: 888,
									},
									{
										id: 9863,
										name: "Bumba   Court of Appeal ",
										generalAssembly_id: 888,
									},
									{
										id: 11396,
										name: "Bumba   Gacaca court of sector ",
										generalAssembly_id: 888,
									},
								],
							},
							{
								id: 889,
								name: "Rambura  ",
								sector_id: 20409,
								courtOfCells: [
									{
										id: 5078,
										name: "BUGONDE",
										generalAssembly_id: 889,
									},
									{
										id: 5079,
										name: "KINIHIRA",
										generalAssembly_id: 889,
									},
									{
										id: 5080,
										name: "KIRAZA",
										generalAssembly_id: 889,
									},
									{
										id: 5081,
										name: "NYUNDO",
										generalAssembly_id: 889,
									},
									{
										id: 5082,
										name: "RINGANIZA",
										generalAssembly_id: 889,
									},
									{
										id: 5083,
										name: "RUGAMBA_I",
										generalAssembly_id: 889,
									},
									{
										id: 5084,
										name: "RUGAMBA_II",
										generalAssembly_id: 889,
									},
									{
										id: 5085,
										name: "RUSEKERA",
										generalAssembly_id: 889,
									},
									{
										id: 5086,
										name: "RWINKINGI",
										generalAssembly_id: 889,
									},
									{
										id: 9864,
										name: "Rambura   Court of Appeal ",
										generalAssembly_id: 889,
									},
									{
										id: 11397,
										name: "Rambura   Gacaca court of sector ",
										generalAssembly_id: 889,
									},
								],
							},
						],
					},
					{
						id: 20410,
						name: "Rugera",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2041006,
								name: "Tyazo",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041005,
								name: "Rurembo",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041004,
								name: "Nyarutembe",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041003,
								name: "Nyagahondo",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041002,
								name: "Marangara",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041001,
								name: "Gakoro",
								sector_id: 20410,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 890,
								name: "Marangara  ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5087,
										name: "BURAMBO",
										generalAssembly_id: 890,
									},
									{
										id: 5088,
										name: "BWUMBA",
										generalAssembly_id: 890,
									},
									{
										id: 5089,
										name: "GASIZA",
										generalAssembly_id: 890,
									},
									{
										id: 5090,
										name: "GITWA",
										generalAssembly_id: 890,
									},
									{
										id: 5091,
										name: "KAVUMU",
										generalAssembly_id: 890,
									},
									{
										id: 5092,
										name: "KIGUGU",
										generalAssembly_id: 890,
									},
									{
										id: 5093,
										name: "NINDA",
										generalAssembly_id: 890,
									},
									{
										id: 5094,
										name: "NYAMYUMBA",
										generalAssembly_id: 890,
									},
									{
										id: 9865,
										name: "Marangara   Court of Appeal ",
										generalAssembly_id: 890,
									},
									{
										id: 11398,
										name: "Marangara   Gacaca court of sector ",
										generalAssembly_id: 890,
									},
								],
							},
							{
								id: 891,
								name: "Mukirangwe  ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5095,
										name: "BIHE",
										generalAssembly_id: 891,
									},
									{
										id: 5096,
										name: "GIKAZE",
										generalAssembly_id: 891,
									},
									{
										id: 5097,
										name: "KARAMBI",
										generalAssembly_id: 891,
									},
									{
										id: 5098,
										name: "KARAMBO",
										generalAssembly_id: 891,
									},
									{
										id: 5099,
										name: "MUKIRANGWE",
										generalAssembly_id: 891,
									},
									{
										id: 5100,
										name: "MUSENYI",
										generalAssembly_id: 891,
									},
									{
										id: 5101,
										name: "NYAKIGEZI",
										generalAssembly_id: 891,
									},
									{
										id: 9866,
										name: "Mukirangwe   Court of Appeal ",
										generalAssembly_id: 891,
									},
									{
										id: 11399,
										name: "Mukirangwe   Gacaca court of sector ",
										generalAssembly_id: 891,
									},
								],
							},
							{
								id: 892,
								name: "Murama ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5102,
										name: "BUKANGO",
										generalAssembly_id: 892,
									},
									{
										id: 5103,
										name: "CYASENGE",
										generalAssembly_id: 892,
									},
									{
										id: 5104,
										name: "GAHAMA",
										generalAssembly_id: 892,
									},
									{
										id: 5105,
										name: "GASEKE",
										generalAssembly_id: 892,
									},
									{
										id: 5106,
										name: "GIHURI",
										generalAssembly_id: 892,
									},
									{
										id: 5107,
										name: "MURAMA",
										generalAssembly_id: 892,
									},
									{
										id: 9867,
										name: "Murama  Court of Appeal ",
										generalAssembly_id: 892,
									},
									{
										id: 11400,
										name: "Murama  Gacaca court of sector ",
										generalAssembly_id: 892,
									},
								],
							},
							{
								id: 893,
								name: "Nyarutembe ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5108,
										name: "JARI",
										generalAssembly_id: 893,
									},
									{
										id: 5109,
										name: "KABOGO",
										generalAssembly_id: 893,
									},
									{
										id: 5110,
										name: "KIREBE",
										generalAssembly_id: 893,
									},
									{
										id: 5111,
										name: "MBIZI",
										generalAssembly_id: 893,
									},
									{
										id: 5112,
										name: "MWAMBI",
										generalAssembly_id: 893,
									},
									{
										id: 5113,
										name: "NGANZO",
										generalAssembly_id: 893,
									},
									{
										id: 5114,
										name: "NYARUTEMBE",
										generalAssembly_id: 893,
									},
									{
										id: 9868,
										name: "Nyarutembe  Court of Appeal ",
										generalAssembly_id: 893,
									},
									{
										id: 11401,
										name: "Nyarutembe  Gacaca court of sector ",
										generalAssembly_id: 893,
									},
								],
							},
							{
								id: 894,
								name: "Rugera ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5115,
										name: "CYANDAGO",
										generalAssembly_id: 894,
									},
									{
										id: 5116,
										name: "GITUMBA",
										generalAssembly_id: 894,
									},
									{
										id: 5117,
										name: "MUDUHA",
										generalAssembly_id: 894,
									},
									{
										id: 5118,
										name: "MWENDO",
										generalAssembly_id: 894,
									},
									{
										id: 5119,
										name: "RUBONA",
										generalAssembly_id: 894,
									},
									{
										id: 5120,
										name: "RUGERA",
										generalAssembly_id: 894,
									},
									{
										id: 5121,
										name: "RWEZAMENYO",
										generalAssembly_id: 894,
									},
									{
										id: 5122,
										name: "TABA",
										generalAssembly_id: 894,
									},
									{
										id: 9869,
										name: "Rugera  Court of Appeal ",
										generalAssembly_id: 894,
									},
									{
										id: 11402,
										name: "Rugera  Gacaca court of sector ",
										generalAssembly_id: 894,
									},
								],
							},
							{
								id: 895,
								name: "Kageri ",
								sector_id: 20410,
								courtOfCells: [
									{
										id: 5123,
										name: "GAHONDO",
										generalAssembly_id: 895,
									},
									{
										id: 5124,
										name: "GITEGA",
										generalAssembly_id: 895,
									},
									{
										id: 5125,
										name: "KAGANO",
										generalAssembly_id: 895,
									},
									{
										id: 5126,
										name: "KARAMBO",
										generalAssembly_id: 895,
									},
									{
										id: 5127,
										name: "MUPFUNDO",
										generalAssembly_id: 895,
									},
									{
										id: 5128,
										name: "MURUNGU",
										generalAssembly_id: 895,
									},
									{
										id: 5129,
										name: "RUGENDABARI",
										generalAssembly_id: 895,
									},
									{
										id: 9870,
										name: "Kageri  Court of Appeal ",
										generalAssembly_id: 895,
									},
									{
										id: 11403,
										name: "Kageri  Gacaca court of sector ",
										generalAssembly_id: 895,
									},
								],
							},
						],
					},
					{
						id: 20411,
						name: "Rurembo",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2041106,
								name: "Rwaza",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041105,
								name: "Mwana",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041104,
								name: "Murambi",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041103,
								name: "Kirimbogo",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041102,
								name: "Gitega",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041101,
								name: "Gahondo",
								sector_id: 20411,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 896,
								name: "Cyanika ",
								sector_id: 20411,
								courtOfCells: [
									{
										id: 5130,
										name: "CYANIKA",
										generalAssembly_id: 896,
									},
									{
										id: 5131,
										name: "KABARI",
										generalAssembly_id: 896,
									},
									{
										id: 5132,
										name: "KAZIGO",
										generalAssembly_id: 896,
									},
									{
										id: 5133,
										name: "KIGUSA",
										generalAssembly_id: 896,
									},
									{
										id: 5134,
										name: "MWANA",
										generalAssembly_id: 896,
									},
									{
										id: 5135,
										name: "NYAGAHANGARA",
										generalAssembly_id: 896,
									},
									{
										id: 5136,
										name: "RUHOMBO",
										generalAssembly_id: 896,
									},
									{
										id: 5137,
										name: "RURAMBO",
										generalAssembly_id: 896,
									},
									{
										id: 5138,
										name: "GITEGA",
										generalAssembly_id: 896,
									},
									{
										id: 9871,
										name: "Cyanika  Court of Appeal ",
										generalAssembly_id: 896,
									},
									{
										id: 11404,
										name: "Cyanika  Gacaca court of sector ",
										generalAssembly_id: 896,
									},
								],
							},
							{
								id: 897,
								name: "Tubungo ",
								sector_id: 20411,
								courtOfCells: [
									{
										id: 5139,
										name: "BUHANGA",
										generalAssembly_id: 897,
									},
									{
										id: 5140,
										name: "KABAHIMBA",
										generalAssembly_id: 897,
									},
									{
										id: 5141,
										name: "NGORORERO",
										generalAssembly_id: 897,
									},
									{
										id: 5142,
										name: "NYABITARE",
										generalAssembly_id: 897,
									},
									{
										id: 5143,
										name: "NYAKARUNGERA",
										generalAssembly_id: 897,
									},
									{
										id: 5144,
										name: "RUGANDA",
										generalAssembly_id: 897,
									},
									{
										id: 5145,
										name: "RWANTARE",
										generalAssembly_id: 897,
									},
									{
										id: 5146,
										name: "TUBUNGO",
										generalAssembly_id: 897,
									},
									{
										id: 9872,
										name: "Tubungo  Court of Appeal ",
										generalAssembly_id: 897,
									},
									{
										id: 11405,
										name: "Tubungo  Gacaca court of sector ",
										generalAssembly_id: 897,
									},
								],
							},
							{
								id: 898,
								name: "Muhungwe  ",
								sector_id: 20411,
								courtOfCells: [
									{
										id: 5147,
										name: "KABUTOZI",
										generalAssembly_id: 898,
									},
									{
										id: 5148,
										name: "KAGEGE",
										generalAssembly_id: 898,
									},
									{
										id: 5149,
										name: "KINABA",
										generalAssembly_id: 898,
									},
									{
										id: 5150,
										name: "MURAMA",
										generalAssembly_id: 898,
									},
									{
										id: 5151,
										name: "NYAMIYAGA",
										generalAssembly_id: 898,
									},
									{
										id: 5152,
										name: "RUBYINIRO",
										generalAssembly_id: 898,
									},
									{
										id: 5153,
										name: "RUGARAMBIRO",
										generalAssembly_id: 898,
									},
									{
										id: 5154,
										name: "RURAMBO",
										generalAssembly_id: 898,
									},
									{
										id: 5155,
										name: "TUBUYE",
										generalAssembly_id: 898,
									},
									{
										id: 9873,
										name: "Muhungwe   Court of Appeal ",
										generalAssembly_id: 898,
									},
									{
										id: 11406,
										name: "Muhungwe   Gacaca court of sector ",
										generalAssembly_id: 898,
									},
								],
							},
							{
								id: 899,
								name: "Rurembo ",
								sector_id: 20411,
								courtOfCells: [
									{
										id: 5156,
										name: "BUGESHI",
										generalAssembly_id: 899,
									},
									{
										id: 5157,
										name: "BUSENGE",
										generalAssembly_id: 899,
									},
									{
										id: 5158,
										name: "GASHARA",
										generalAssembly_id: 899,
									},
									{
										id: 5159,
										name: "KABYAZA",
										generalAssembly_id: 899,
									},
									{
										id: 5160,
										name: "KIDOMO",
										generalAssembly_id: 899,
									},
									{
										id: 5161,
										name: "KIRINGA",
										generalAssembly_id: 899,
									},
									{
										id: 5162,
										name: "MURAMBI",
										generalAssembly_id: 899,
									},
									{
										id: 5163,
										name: "NYARUKANGAGA",
										generalAssembly_id: 899,
									},
									{
										id: 9874,
										name: "Rurembo  Court of Appeal ",
										generalAssembly_id: 899,
									},
									{
										id: 11407,
										name: "Rurembo  Gacaca court of sector ",
										generalAssembly_id: 899,
									},
								],
							},
						],
					},
					{
						id: 20412,
						name: "Shyira",
						district_id: 204,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2041206,
								name: "Shaki",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041205,
								name: "Mutanda",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041204,
								name: "Mpinga",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041203,
								name: "Kintarure",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041202,
								name: "Kanyamitana",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2041201,
								name: "Cyimanzovu",
								sector_id: 20412,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 900,
								name: "Cyarwa ",
								sector_id: 20412,
								courtOfCells: [
									{
										id: 5164,
										name: "CYARWA",
										generalAssembly_id: 900,
									},
									{
										id: 5165,
										name: "GAKOMA",
										generalAssembly_id: 900,
									},
									{
										id: 5166,
										name: "KANYAMITANA",
										generalAssembly_id: 900,
									},
									{
										id: 5167,
										name: "RUVUMBA",
										generalAssembly_id: 900,
									},
									{
										id: 9875,
										name: "Cyarwa  Court of Appeal ",
										generalAssembly_id: 900,
									},
									{
										id: 11408,
										name: "Cyarwa  Gacaca court of sector ",
										generalAssembly_id: 900,
									},
								],
							},
							{
								id: 901,
								name: "Kintarure ",
								sector_id: 20412,
								courtOfCells: [
									{
										id: 5168,
										name: "GIKORO",
										generalAssembly_id: 901,
									},
									{
										id: 5169,
										name: "KABUGUZO",
										generalAssembly_id: 901,
									},
									{
										id: 5170,
										name: "REMERA",
										generalAssembly_id: 901,
									},
									{
										id: 5171,
										name: "MATABA",
										generalAssembly_id: 901,
									},
									{
										id: 9876,
										name: "Kintarure  Court of Appeal ",
										generalAssembly_id: 901,
									},
									{
										id: 11409,
										name: "Kintarure  Gacaca court of sector ",
										generalAssembly_id: 901,
									},
								],
							},
							{
								id: 902,
								name: "Shaki ",
								sector_id: 20412,
								courtOfCells: [
									{
										id: 5172,
										name: "RUKWI",
										generalAssembly_id: 902,
									},
									{
										id: 5173,
										name: "RUTOYI",
										generalAssembly_id: 902,
									},
									{
										id: 5174,
										name: "RWARAMO",
										generalAssembly_id: 902,
									},
									{
										id: 5175,
										name: "SHAKI",
										generalAssembly_id: 902,
									},
									{
										id: 9877,
										name: "Shaki  Court of Appeal ",
										generalAssembly_id: 902,
									},
									{
										id: 11410,
										name: "Shaki  Gacaca court of sector ",
										generalAssembly_id: 902,
									},
								],
							},
							{
								id: 903,
								name: "Shyira ",
								sector_id: 20412,
								courtOfCells: [
									{
										id: 5176,
										name: "CYIMANZOVU",
										generalAssembly_id: 903,
									},
									{
										id: 5177,
										name: "KANA",
										generalAssembly_id: 903,
									},
									{
										id: 5178,
										name: "MPINGA",
										generalAssembly_id: 903,
									},
									{
										id: 5179,
										name: "MUGWATO",
										generalAssembly_id: 903,
									},
									{
										id: 5180,
										name: "MUKAKA",
										generalAssembly_id: 903,
									},
									{
										id: 9878,
										name: "Shyira  Court of Appeal ",
										generalAssembly_id: 903,
									},
									{
										id: 11411,
										name: "Shyira  Gacaca court of sector ",
										generalAssembly_id: 903,
									},
								],
							},
							{
								id: 904,
								name: "Mutanda ",
								sector_id: 20412,
								courtOfCells: [
									{
										id: 5181,
										name: "BURANGA",
										generalAssembly_id: 904,
									},
									{
										id: 5182,
										name: "GITABURA",
										generalAssembly_id: 904,
									},
									{
										id: 5183,
										name: "NKERIMA",
										generalAssembly_id: 904,
									},
									{
										id: 5184,
										name: "RWERU",
										generalAssembly_id: 904,
									},
									{
										id: 9879,
										name: "Mutanda  Court of Appeal ",
										generalAssembly_id: 904,
									},
									{
										id: 11412,
										name: "Mutanda  Gacaca court of sector ",
										generalAssembly_id: 904,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 207,
				name: "Nyamasheke",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20701,
						name: "Bushekeri",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070104,
								name: "Nyarusange",
								sector_id: 20701,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070103,
								name: "Ngoma",
								sector_id: 20701,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070102,
								name: "Mpumbu",
								sector_id: 20701,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070101,
								name: "Buvungira",
								sector_id: 20701,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1028,
								name: "Bushekeli  ",
								sector_id: 20701,
								courtOfCells: [
									{
										id: 5962,
										name: "BUVUNGIRA",
										generalAssembly_id: 1028,
									},
									{
										id: 5963,
										name: "MPUMBU",
										generalAssembly_id: 1028,
									},
									{
										id: 5964,
										name: "RUVUMBU",
										generalAssembly_id: 1028,
									},
									{
										id: 5965,
										name: "RWUMBA",
										generalAssembly_id: 1028,
									},
									{
										id: 5966,
										name: "YOVE",
										generalAssembly_id: 1028,
									},
									{
										id: 10003,
										name: "Bushekeli   Court of Appeal ",
										generalAssembly_id: 1028,
									},
									{
										id: 11536,
										name: "Bushekeli   Gacaca court of sector ",
										generalAssembly_id: 1028,
									},
								],
							},
							{
								id: 1029,
								name: "Kagarama ",
								sector_id: 20701,
								courtOfCells: [
									{
										id: 5967,
										name: "GATOKI",
										generalAssembly_id: 1029,
									},
									{
										id: 5968,
										name: "KIBINGO",
										generalAssembly_id: 1029,
									},
									{
										id: 5969,
										name: "NYEHONGA",
										generalAssembly_id: 1029,
									},
									{
										id: 5970,
										name: "RUNDWE",
										generalAssembly_id: 1029,
									},
									{
										id: 5971,
										name: "RWABUHIRI",
										generalAssembly_id: 1029,
									},
									{
										id: 10004,
										name: "Kagarama  Court of Appeal ",
										generalAssembly_id: 1029,
									},
									{
										id: 11537,
										name: "Kagarama  Gacaca court of sector ",
										generalAssembly_id: 1029,
									},
								],
							},
							{
								id: 1030,
								name: "Ngoma  ",
								sector_id: 20701,
								courtOfCells: [
									{
										id: 5972,
										name: "CYESHERO",
										generalAssembly_id: 1030,
									},
									{
										id: 5973,
										name: "KADASOMWA",
										generalAssembly_id: 1030,
									},
									{
										id: 5974,
										name: "MASHUHIRA",
										generalAssembly_id: 1030,
									},
									{
										id: 5975,
										name: "NYARUSANGE",
										generalAssembly_id: 1030,
									},
									{
										id: 5976,
										name: "RUGEREGERE",
										generalAssembly_id: 1030,
									},
									{
										id: 10005,
										name: "Ngoma   Court of Appeal ",
										generalAssembly_id: 1030,
									},
									{
										id: 11538,
										name: "Ngoma   Gacaca court of sector ",
										generalAssembly_id: 1030,
									},
								],
							},
						],
					},
					{
						id: 20702,
						name: "Bushenge",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070204,
								name: "Karusimbi",
								sector_id: 20702,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070203,
								name: "Kagatamu",
								sector_id: 20702,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070202,
								name: "Impala",
								sector_id: 20702,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070201,
								name: "Gasheke",
								sector_id: 20702,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1031,
								name: "Biguzi  ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 5977,
										name: "BIGUZI",
										generalAssembly_id: 1031,
									},
									{
										id: 5978,
										name: "GASHEKE",
										generalAssembly_id: 1031,
									},
									{
										id: 5979,
										name: "GIKOMBE",
										generalAssembly_id: 1031,
									},
									{
										id: 5980,
										name: "GITWA",
										generalAssembly_id: 1031,
									},
									{
										id: 5981,
										name: "KAMUCYAMO",
										generalAssembly_id: 1031,
									},
									{
										id: 5982,
										name: "KARAMBO",
										generalAssembly_id: 1031,
									},
									{
										id: 5983,
										name: "KIGENGE",
										generalAssembly_id: 1031,
									},
									{
										id: 5984,
										name: "NYAMIKINGO",
										generalAssembly_id: 1031,
									},
									{
										id: 10006,
										name: "Biguzi   Court of Appeal ",
										generalAssembly_id: 1031,
									},
									{
										id: 11539,
										name: "Biguzi   Gacaca court of sector ",
										generalAssembly_id: 1031,
									},
								],
							},
							{
								id: 1032,
								name: "Bugungu ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 5985,
										name: "BUGUNGU",
										generalAssembly_id: 1032,
									},
									{
										id: 5986,
										name: "KARUNGA",
										generalAssembly_id: 1032,
									},
									{
										id: 5987,
										name: "KIDASHIRA",
										generalAssembly_id: 1032,
									},
									{
										id: 5988,
										name: "KIVOGA",
										generalAssembly_id: 1032,
									},
									{
										id: 5989,
										name: "RUHINGA",
										generalAssembly_id: 1032,
									},
									{
										id: 10007,
										name: "Bugungu  Court of Appeal ",
										generalAssembly_id: 1032,
									},
									{
										id: 11540,
										name: "Bugungu  Gacaca court of sector ",
										generalAssembly_id: 1032,
									},
								],
							},
							{
								id: 1033,
								name: "Bushenge  ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 5990,
										name: "BUNINDA",
										generalAssembly_id: 1033,
									},
									{
										id: 5991,
										name: "BUSHENGE",
										generalAssembly_id: 1033,
									},
									{
										id: 5992,
										name: "GASHARU",
										generalAssembly_id: 1033,
									},
									{
										id: 5993,
										name: "GASUMO",
										generalAssembly_id: 1033,
									},
									{
										id: 5994,
										name: "MUCUZI",
										generalAssembly_id: 1033,
									},
									{
										id: 10008,
										name: "Bushenge   Court of Appeal ",
										generalAssembly_id: 1033,
									},
									{
										id: 11541,
										name: "Bushenge   Gacaca court of sector ",
										generalAssembly_id: 1033,
									},
								],
							},
							{
								id: 1034,
								name: "Gashirabwoba ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 5995,
										name: "GAKWISI_RUHINGA",
										generalAssembly_id: 1034,
									},
									{
										id: 5996,
										name: "GASHIRABWOBA",
										generalAssembly_id: 1034,
									},
									{
										id: 5997,
										name: "GATARE",
										generalAssembly_id: 1034,
									},
									{
										id: 5998,
										name: "KAGATUMU",
										generalAssembly_id: 1034,
									},
									{
										id: 5999,
										name: "GASURA_RUHINAMAVI",
										generalAssembly_id: 1034,
									},
									{
										id: 10009,
										name: "Gashirabwoba  Court of Appeal ",
										generalAssembly_id: 1034,
									},
									{
										id: 11542,
										name: "Gashirabwoba  Gacaca court of sector ",
										generalAssembly_id: 1034,
									},
								],
							},
							{
								id: 1035,
								name: "Mwito ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 6000,
										name: "GAKOMBE",
										generalAssembly_id: 1035,
									},
									{
										id: 6001,
										name: "KARUSIMBI",
										generalAssembly_id: 1035,
									},
									{
										id: 6002,
										name: "KASENJARA",
										generalAssembly_id: 1035,
									},
									{
										id: 6003,
										name: "KIGAGA",
										generalAssembly_id: 1035,
									},
									{
										id: 6004,
										name: "NYAKAGEZI",
										generalAssembly_id: 1035,
									},
									{
										id: 6005,
										name: "RWUMUYAGA",
										generalAssembly_id: 1035,
									},
									{
										id: 10010,
										name: "Mwito  Court of Appeal ",
										generalAssembly_id: 1035,
									},
									{
										id: 11543,
										name: "Mwito  Gacaca court of sector ",
										generalAssembly_id: 1035,
									},
								],
							},
							{
								id: 1036,
								name: "Remera  ",
								sector_id: 20702,
								courtOfCells: [
									{
										id: 6006,
										name: "BAGIRAMENYO",
										generalAssembly_id: 1036,
									},
									{
										id: 6007,
										name: "GAHONGO",
										generalAssembly_id: 1036,
									},
									{
										id: 6008,
										name: "GASHARU",
										generalAssembly_id: 1036,
									},
									{
										id: 6009,
										name: "IMPALA",
										generalAssembly_id: 1036,
									},
									{
										id: 6010,
										name: "REMERA",
										generalAssembly_id: 1036,
									},
									{
										id: 10011,
										name: "Remera   Court of Appeal ",
										generalAssembly_id: 1036,
									},
									{
										id: 11544,
										name: "Remera   Gacaca court of sector ",
										generalAssembly_id: 1036,
									},
								],
							},
						],
					},
					{
						id: 20703,
						name: "Cyato",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070304,
								name: "Rugari",
								sector_id: 20703,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070303,
								name: "Mutongo",
								sector_id: 20703,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070302,
								name: "Murambi",
								sector_id: 20703,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070301,
								name: "Bisumo",
								sector_id: 20703,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1037,
								name: "Cyato ",
								sector_id: 20703,
								courtOfCells: [
									{
										id: 6011,
										name: "BIGEYO",
										generalAssembly_id: 1037,
									},
									{
										id: 6012,
										name: "CYATO",
										generalAssembly_id: 1037,
									},
									{
										id: 6013,
										name: "KAREHE",
										generalAssembly_id: 1037,
									},
									{
										id: 6014,
										name: "MURENGE",
										generalAssembly_id: 1037,
									},
									{
										id: 6015,
										name: "NYAKABINGO",
										generalAssembly_id: 1037,
									},
									{
										id: 10012,
										name: "Cyato  Court of Appeal ",
										generalAssembly_id: 1037,
									},
									{
										id: 11545,
										name: "Cyato  Gacaca court of sector ",
										generalAssembly_id: 1037,
									},
								],
							},
							{
								id: 1038,
								name: "Rwumba ",
								sector_id: 20703,
								courtOfCells: [
									{
										id: 6016,
										name: "GASASA",
										generalAssembly_id: 1038,
									},
									{
										id: 6017,
										name: "GASHIHE",
										generalAssembly_id: 1038,
									},
									{
										id: 6018,
										name: "MUTUNTU",
										generalAssembly_id: 1038,
									},
									{
										id: 6019,
										name: "NTSINDUKA",
										generalAssembly_id: 1038,
									},
									{
										id: 6020,
										name: "RUGABE",
										generalAssembly_id: 1038,
									},
									{
										id: 6021,
										name: "RUHENGERI",
										generalAssembly_id: 1038,
									},
									{
										id: 6022,
										name: "RWUMBA",
										generalAssembly_id: 1038,
									},
									{
										id: 10013,
										name: "Rwumba  Court of Appeal ",
										generalAssembly_id: 1038,
									},
									{
										id: 11546,
										name: "Rwumba  Gacaca court of sector ",
										generalAssembly_id: 1038,
									},
								],
							},
							{
								id: 1039,
								name: "Yove  ",
								sector_id: 20703,
								courtOfCells: [
									{
										id: 6023,
										name: "BWANAMA",
										generalAssembly_id: 1039,
									},
									{
										id: 6024,
										name: "GITUNTU",
										generalAssembly_id: 1039,
									},
									{
										id: 6025,
										name: "KIZINGA",
										generalAssembly_id: 1039,
									},
									{
										id: 6026,
										name: "RUSHAHAGA",
										generalAssembly_id: 1039,
									},
									{
										id: 6027,
										name: "YOVE",
										generalAssembly_id: 1039,
									},
									{
										id: 10014,
										name: "Yove   Court of Appeal ",
										generalAssembly_id: 1039,
									},
									{
										id: 11547,
										name: "Yove   Gacaca court of sector ",
										generalAssembly_id: 1039,
									},
								],
							},
						],
					},
					{
						id: 20704,
						name: "Gihombo",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070405,
								name: "Mubuga",
								sector_id: 20704,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070404,
								name: "Kibingo",
								sector_id: 20704,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070403,
								name: "Jarama",
								sector_id: 20704,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070402,
								name: "Gitwa",
								sector_id: 20704,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070401,
								name: "Butare",
								sector_id: 20704,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1040,
								name: "Butembo  ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6028,
										name: "BUNGO",
										generalAssembly_id: 1040,
									},
									{
										id: 6029,
										name: "BUTEMBO",
										generalAssembly_id: 1040,
									},
									{
										id: 6030,
										name: "MUHORORO",
										generalAssembly_id: 1040,
									},
									{
										id: 6031,
										name: "MUHAVU",
										generalAssembly_id: 1040,
									},
									{
										id: 6032,
										name: "MUBUGA",
										generalAssembly_id: 1040,
									},
									{
										id: 10015,
										name: "Butembo   Court of Appeal ",
										generalAssembly_id: 1040,
									},
									{
										id: 11548,
										name: "Butembo   Gacaca court of sector ",
										generalAssembly_id: 1040,
									},
								],
							},
							{
								id: 1041,
								name: "Gihombo  ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6033,
										name: "BUSESO",
										generalAssembly_id: 1041,
									},
									{
										id: 6034,
										name: "BUTARE",
										generalAssembly_id: 1041,
									},
									{
										id: 6035,
										name: "GAHANDA",
										generalAssembly_id: 1041,
									},
									{
										id: 6036,
										name: "GASHARU",
										generalAssembly_id: 1041,
									},
									{
										id: 6037,
										name: "MBOGO",
										generalAssembly_id: 1041,
									},
									{
										id: 6038,
										name: "RWAMATAMU",
										generalAssembly_id: 1041,
									},
									{
										id: 10016,
										name: "Gihombo   Court of Appeal ",
										generalAssembly_id: 1041,
									},
									{
										id: 11549,
										name: "Gihombo   Gacaca court of sector ",
										generalAssembly_id: 1041,
									},
								],
							},
							{
								id: 1042,
								name: "Mugozi ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6039,
										name: "GITURUKA",
										generalAssembly_id: 1042,
									},
									{
										id: 6040,
										name: "KIGARAMA",
										generalAssembly_id: 1042,
									},
									{
										id: 6041,
										name: "NYARUNYINYA",
										generalAssembly_id: 1042,
									},
									{
										id: 6042,
										name: "RUGARAGARA",
										generalAssembly_id: 1042,
									},
									{
										id: 6043,
										name: "RUSHOKA",
										generalAssembly_id: 1042,
									},
									{
										id: 6044,
										name: "RWANYUNDO",
										generalAssembly_id: 1042,
									},
									{
										id: 10017,
										name: "Mugozi  Court of Appeal ",
										generalAssembly_id: 1042,
									},
									{
										id: 11550,
										name: "Mugozi  Gacaca court of sector ",
										generalAssembly_id: 1042,
									},
								],
							},
							{
								id: 1043,
								name: "Nyagahinga ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6045,
										name: "DOGA",
										generalAssembly_id: 1043,
									},
									{
										id: 6046,
										name: "BIREHE",
										generalAssembly_id: 1043,
									},
									{
										id: 6047,
										name: "BWERANKORI",
										generalAssembly_id: 1043,
									},
									{
										id: 6048,
										name: "NYAGAHINGA",
										generalAssembly_id: 1043,
									},
									{
										id: 6049,
										name: "GASAGARA",
										generalAssembly_id: 1043,
									},
									{
										id: 6050,
										name: "NYAKABUNGO",
										generalAssembly_id: 1043,
									},
									{
										id: 6051,
										name: "RUBOREZA",
										generalAssembly_id: 1043,
									},
									{
										id: 10018,
										name: "Nyagahinga  Court of Appeal ",
										generalAssembly_id: 1043,
									},
									{
										id: 11551,
										name: "Nyagahinga  Gacaca court of sector ",
										generalAssembly_id: 1043,
									},
								],
							},
							{
								id: 1044,
								name: "Ruvumbu  ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6052,
										name: "RUVUMBU",
										generalAssembly_id: 1044,
									},
									{
										id: 6053,
										name: "BIGABIRO",
										generalAssembly_id: 1044,
									},
									{
										id: 6054,
										name: "KADOBOGO",
										generalAssembly_id: 1044,
									},
									{
										id: 6055,
										name: "KAREHE",
										generalAssembly_id: 1044,
									},
									{
										id: 6056,
										name: "KIBIRIZI",
										generalAssembly_id: 1044,
									},
									{
										id: 10019,
										name: "Ruvumbu   Court of Appeal ",
										generalAssembly_id: 1044,
									},
									{
										id: 11552,
										name: "Ruvumbu   Gacaca court of sector ",
										generalAssembly_id: 1044,
									},
								],
							},
							{
								id: 1045,
								name: "Rwabisindu ",
								sector_id: 20704,
								courtOfCells: [
									{
										id: 6057,
										name: "GASEKE_RWABISINDU",
										generalAssembly_id: 1045,
									},
									{
										id: 6058,
										name: "GASHARU",
										generalAssembly_id: 1045,
									},
									{
										id: 6059,
										name: "KINANIRA",
										generalAssembly_id: 1045,
									},
									{
										id: 6060,
										name: "NYABITARE",
										generalAssembly_id: 1045,
									},
									{
										id: 6061,
										name: "RUSUZUMIRO",
										generalAssembly_id: 1045,
									},
									{
										id: 10020,
										name: "Rwabisindu  Court of Appeal ",
										generalAssembly_id: 1045,
									},
									{
										id: 11553,
										name: "Rwabisindu  Gacaca court of sector ",
										generalAssembly_id: 1045,
									},
								],
							},
						],
					},
					{
						id: 20705,
						name: "Kagano",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070505,
								name: "Shara",
								sector_id: 20705,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070504,
								name: "Rwesero",
								sector_id: 20705,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070503,
								name: "Ninzi",
								sector_id: 20705,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070502,
								name: "Mubumbano",
								sector_id: 20705,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070501,
								name: "Gako",
								sector_id: 20705,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1046,
								name: "Butambara  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6062,
										name: "GASHARU",
										generalAssembly_id: 1046,
									},
									{
										id: 6063,
										name: "MASERA",
										generalAssembly_id: 1046,
									},
									{
										id: 6064,
										name: "KIJIBAMBA",
										generalAssembly_id: 1046,
									},
									{
										id: 6065,
										name: "MUTUSA",
										generalAssembly_id: 1046,
									},
									{
										id: 6066,
										name: "RWESERO",
										generalAssembly_id: 1046,
									},
									{
										id: 6067,
										name: "BUTAMBARA",
										generalAssembly_id: 1046,
									},
									{
										id: 10021,
										name: "Butambara   Court of Appeal ",
										generalAssembly_id: 1046,
									},
									{
										id: 11554,
										name: "Butambara   Gacaca court of sector ",
										generalAssembly_id: 1046,
									},
								],
							},
							{
								id: 1047,
								name: "Kagano  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6068,
										name: "BAGARAMA",
										generalAssembly_id: 1047,
									},
									{
										id: 6069,
										name: "BISORO",
										generalAssembly_id: 1047,
									},
									{
										id: 6070,
										name: "GAKO",
										generalAssembly_id: 1047,
									},
									{
										id: 6071,
										name: "GITABA",
										generalAssembly_id: 1047,
									},
									{
										id: 6072,
										name: "MPOMBO",
										generalAssembly_id: 1047,
									},
									{
										id: 10022,
										name: "Kagano   Court of Appeal ",
										generalAssembly_id: 1047,
									},
									{
										id: 11555,
										name: "Kagano   Gacaca court of sector ",
										generalAssembly_id: 1047,
									},
								],
							},
							{
								id: 1048,
								name: "Mubumbano  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6073,
										name: "GACYAMO",
										generalAssembly_id: 1048,
									},
									{
										id: 6074,
										name: "GASAYO",
										generalAssembly_id: 1048,
									},
									{
										id: 6075,
										name: "MIKINGO",
										generalAssembly_id: 1048,
									},
									{
										id: 6076,
										name: "NYAMARAGA",
										generalAssembly_id: 1048,
									},
									{
										id: 6077,
										name: "RWENGERO",
										generalAssembly_id: 1048,
									},
									{
										id: 10023,
										name: "Mubumbano   Court of Appeal ",
										generalAssembly_id: 1048,
									},
									{
										id: 11556,
										name: "Mubumbano   Gacaca court of sector ",
										generalAssembly_id: 1048,
									},
								],
							},
							{
								id: 1049,
								name: "Mukinja  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6078,
										name: "GAKOMEYE",
										generalAssembly_id: 1049,
									},
									{
										id: 6079,
										name: "GIKOMERO",
										generalAssembly_id: 1049,
									},
									{
										id: 6080,
										name: "KABUYE_CYERU",
										generalAssembly_id: 1049,
									},
									{
										id: 6081,
										name: "KIBARE",
										generalAssembly_id: 1049,
									},
									{
										id: 6082,
										name: "MURAMBI",
										generalAssembly_id: 1049,
									},
									{
										id: 10024,
										name: "Mukinja   Court of Appeal ",
										generalAssembly_id: 1049,
									},
									{
										id: 11557,
										name: "Mukinja   Gacaca court of sector ",
										generalAssembly_id: 1049,
									},
								],
							},
							{
								id: 1050,
								name: "Nyakabingo  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6083,
										name: "GITWA",
										generalAssembly_id: 1050,
									},
									{
										id: 6084,
										name: "REMERA",
										generalAssembly_id: 1050,
									},
									{
										id: 6085,
										name: "RUSHONDI",
										generalAssembly_id: 1050,
									},
									{
										id: 6086,
										name: "RWISOVU",
										generalAssembly_id: 1050,
									},
									{
										id: 10025,
										name: "Nyakabingo   Court of Appeal ",
										generalAssembly_id: 1050,
									},
									{
										id: 11558,
										name: "Nyakabingo   Gacaca court of sector ",
										generalAssembly_id: 1050,
									},
								],
							},
							{
								id: 1051,
								name: "Nyamasheke  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6087,
										name: "GIKUYU",
										generalAssembly_id: 1051,
									},
									{
										id: 6088,
										name: "KAVUNE",
										generalAssembly_id: 1051,
									},
									{
										id: 6089,
										name: "MURWA",
										generalAssembly_id: 1051,
									},
									{
										id: 6090,
										name: "NINZI",
										generalAssembly_id: 1051,
									},
									{
										id: 6091,
										name: "NYABAGENI",
										generalAssembly_id: 1051,
									},
									{
										id: 10026,
										name: "Nyamasheke   Court of Appeal ",
										generalAssembly_id: 1051,
									},
									{
										id: 11559,
										name: "Nyamasheke   Gacaca court of sector ",
										generalAssembly_id: 1051,
									},
								],
							},
							{
								id: 1052,
								name: "Rambira  ",
								sector_id: 20705,
								courtOfCells: [
									{
										id: 6092,
										name: "BYAHI",
										generalAssembly_id: 1052,
									},
									{
										id: 6093,
										name: "KADUHA",
										generalAssembly_id: 1052,
									},
									{
										id: 6094,
										name: "MUGOHE",
										generalAssembly_id: 1052,
									},
									{
										id: 6095,
										name: "NTUMBA",
										generalAssembly_id: 1052,
									},
									{
										id: 10027,
										name: "Rambira   Court of Appeal ",
										generalAssembly_id: 1052,
									},
									{
										id: 11560,
										name: "Rambira   Gacaca court of sector ",
										generalAssembly_id: 1052,
									},
								],
							},
						],
					},
					{
						id: 20706,
						name: "Kanjongo",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070605,
								name: "Susa",
								sector_id: 20706,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070604,
								name: "Raro",
								sector_id: 20706,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070603,
								name: "Kigoya",
								sector_id: 20706,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070602,
								name: "Kigarama",
								sector_id: 20706,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070601,
								name: "Kibogora",
								sector_id: 20706,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1053,
								name: "Gitongo  ",
								sector_id: 20706,
								courtOfCells: [
									{
										id: 6096,
										name: "GASHARU",
										generalAssembly_id: 1053,
									},
									{
										id: 6097,
										name: "KABUKUNZI",
										generalAssembly_id: 1053,
									},
									{
										id: 6098,
										name: "KABUYAGA",
										generalAssembly_id: 1053,
									},
									{
										id: 6099,
										name: "KIGUGU",
										generalAssembly_id: 1053,
									},
									{
										id: 6100,
										name: "MICYAMO",
										generalAssembly_id: 1053,
									},
									{
										id: 6101,
										name: "RWAKAYUMBU",
										generalAssembly_id: 1053,
									},
									{
										id: 10028,
										name: "Gitongo   Court of Appeal ",
										generalAssembly_id: 1053,
									},
									{
										id: 11561,
										name: "Gitongo   Gacaca court of sector ",
										generalAssembly_id: 1053,
									},
								],
							},
							{
								id: 1054,
								name: "Kanjongo  ",
								sector_id: 20706,
								courtOfCells: [
									{
										id: 6102,
										name: "KABUYE",
										generalAssembly_id: 1054,
									},
									{
										id: 6103,
										name: "KIVOGA",
										generalAssembly_id: 1054,
									},
									{
										id: 6104,
										name: "KORWE",
										generalAssembly_id: 1054,
									},
									{
										id: 6105,
										name: "NYABUSANA",
										generalAssembly_id: 1054,
									},
									{
										id: 6106,
										name: "NYAGISASA",
										generalAssembly_id: 1054,
									},
									{
										id: 6107,
										name: "NYARUHONDO",
										generalAssembly_id: 1054,
									},
									{
										id: 10029,
										name: "Kanjongo   Court of Appeal ",
										generalAssembly_id: 1054,
									},
									{
										id: 11562,
										name: "Kanjongo   Gacaca court of sector ",
										generalAssembly_id: 1054,
									},
								],
							},
							{
								id: 1055,
								name: "Muraza ",
								sector_id: 20706,
								courtOfCells: [
									{
										id: 6108,
										name: "GASHASHI",
										generalAssembly_id: 1055,
									},
									{
										id: 6109,
										name: "GITAMBI",
										generalAssembly_id: 1055,
									},
									{
										id: 6110,
										name: "KIGOTE",
										generalAssembly_id: 1055,
									},
									{
										id: 6111,
										name: "KINYINYA",
										generalAssembly_id: 1055,
									},
									{
										id: 6112,
										name: "RUBYINIRO",
										generalAssembly_id: 1055,
									},
									{
										id: 10030,
										name: "Muraza  Court of Appeal ",
										generalAssembly_id: 1055,
									},
									{
										id: 11563,
										name: "Muraza  Gacaca court of sector ",
										generalAssembly_id: 1055,
									},
								],
							},
							{
								id: 1056,
								name: "Tyazo ",
								sector_id: 20706,
								courtOfCells: [
									{
										id: 6113,
										name: "BIZENGA",
										generalAssembly_id: 1056,
									},
									{
										id: 6114,
										name: "KAGARAMA",
										generalAssembly_id: 1056,
									},
									{
										id: 6115,
										name: "KIBOGORA",
										generalAssembly_id: 1056,
									},
									{
										id: 6116,
										name: "MASEKA",
										generalAssembly_id: 1056,
									},
									{
										id: 6117,
										name: "MUNINI",
										generalAssembly_id: 1056,
									},
									{
										id: 6118,
										name: "NYAGACACA",
										generalAssembly_id: 1056,
									},
									{
										id: 6119,
										name: "RWINYANA",
										generalAssembly_id: 1056,
									},
									{
										id: 10031,
										name: "Tyazo  Court of Appeal ",
										generalAssembly_id: 1056,
									},
									{
										id: 11564,
										name: "Tyazo  Gacaca court of sector ",
										generalAssembly_id: 1056,
									},
								],
							},
							{
								id: 1057,
								name: "Ruheru  ",
								sector_id: 20706,
								courtOfCells: [
									{
										id: 6120,
										name: "KAGANO",
										generalAssembly_id: 1057,
									},
									{
										id: 6121,
										name: "KANAZI",
										generalAssembly_id: 1057,
									},
									{
										id: 6122,
										name: "KIBUYE",
										generalAssembly_id: 1057,
									},
									{
										id: 6123,
										name: "MUNYINYA",
										generalAssembly_id: 1057,
									},
									{
										id: 6124,
										name: "NYAMATEME",
										generalAssembly_id: 1057,
									},
									{
										id: 6125,
										name: "RUBONA",
										generalAssembly_id: 1057,
									},
									{
										id: 6126,
										name: "RUSAMBU",
										generalAssembly_id: 1057,
									},
									{
										id: 10032,
										name: "Ruheru   Court of Appeal ",
										generalAssembly_id: 1057,
									},
									{
										id: 11565,
										name: "Ruheru   Gacaca court of sector ",
										generalAssembly_id: 1057,
									},
								],
							},
						],
					},
					{
						id: 20707,
						name: "Karambi",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070705,
								name: "Rushyarara",
								sector_id: 20707,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070704,
								name: "Kagarama",
								sector_id: 20707,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070703,
								name: "Kabuga",
								sector_id: 20707,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070702,
								name: "Gitwe",
								sector_id: 20707,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070701,
								name: "Gasovu",
								sector_id: 20707,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1058,
								name: "Cyiya  ",
								sector_id: 20707,
								courtOfCells: [
									{
										id: 6127,
										name: "GITOVU",
										generalAssembly_id: 1058,
									},
									{
										id: 6128,
										name: "GITWE",
										generalAssembly_id: 1058,
									},
									{
										id: 6129,
										name: "MUDUHA",
										generalAssembly_id: 1058,
									},
									{
										id: 6130,
										name: "MUHORORO",
										generalAssembly_id: 1058,
									},
									{
										id: 6131,
										name: "RUGABE",
										generalAssembly_id: 1058,
									},
									{
										id: 10033,
										name: "Cyiya   Court of Appeal ",
										generalAssembly_id: 1058,
									},
									{
										id: 11566,
										name: "Cyiya   Gacaca court of sector ",
										generalAssembly_id: 1058,
									},
								],
							},
							{
								id: 1059,
								name: "Karambi  ",
								sector_id: 20707,
								courtOfCells: [
									{
										id: 6132,
										name: "GASOVU",
										generalAssembly_id: 1059,
									},
									{
										id: 6133,
										name: "KARAMBO",
										generalAssembly_id: 1059,
									},
									{
										id: 6134,
										name: "MISIRIMBO",
										generalAssembly_id: 1059,
									},
									{
										id: 6135,
										name: "MURAMBI",
										generalAssembly_id: 1059,
									},
									{
										id: 6136,
										name: "RUSHYARARA",
										generalAssembly_id: 1059,
									},
									{
										id: 6137,
										name: "RYANYAGAHANGARA",
										generalAssembly_id: 1059,
									},
									{
										id: 10034,
										name: "Karambi   Court of Appeal ",
										generalAssembly_id: 1059,
									},
									{
										id: 11567,
										name: "Karambi   Gacaca court of sector ",
										generalAssembly_id: 1059,
									},
								],
							},
							{
								id: 1060,
								name: "Mugomba  ",
								sector_id: 20707,
								courtOfCells: [
									{
										id: 6138,
										name: "BITARE",
										generalAssembly_id: 1060,
									},
									{
										id: 6139,
										name: "KAGEYO",
										generalAssembly_id: 1060,
									},
									{
										id: 6140,
										name: "KARAMBO",
										generalAssembly_id: 1060,
									},
									{
										id: 6141,
										name: "RUDAGA",
										generalAssembly_id: 1060,
									},
									{
										id: 6142,
										name: "RUSHYARARA",
										generalAssembly_id: 1060,
									},
									{
										id: 6143,
										name: "RWUNAMUKA",
										generalAssembly_id: 1060,
									},
									{
										id: 10035,
										name: "Mugomba   Court of Appeal ",
										generalAssembly_id: 1060,
									},
									{
										id: 11568,
										name: "Mugomba   Gacaca court of sector ",
										generalAssembly_id: 1060,
									},
								],
							},
							{
								id: 1061,
								name: "Ngange  ",
								sector_id: 20707,
								courtOfCells: [
									{
										id: 6144,
										name: "KABAHIGA",
										generalAssembly_id: 1061,
									},
									{
										id: 6145,
										name: "KAGARAMA",
										generalAssembly_id: 1061,
									},
									{
										id: 6146,
										name: "KAMONYI",
										generalAssembly_id: 1061,
									},
									{
										id: 6147,
										name: "MWEYA",
										generalAssembly_id: 1061,
									},
									{
										id: 6148,
										name: "NYAMYUMBA",
										generalAssembly_id: 1061,
									},
									{
										id: 6149,
										name: "RUSHUBI",
										generalAssembly_id: 1061,
									},
									{
										id: 6150,
										name: "WIBUNGO",
										generalAssembly_id: 1061,
									},
									{
										id: 10036,
										name: "Ngange   Court of Appeal ",
										generalAssembly_id: 1061,
									},
									{
										id: 11569,
										name: "Ngange   Gacaca court of sector ",
										generalAssembly_id: 1061,
									},
								],
							},
							{
								id: 1062,
								name: "Rugano ",
								sector_id: 20707,
								courtOfCells: [
									{
										id: 6151,
										name: "BUGARAMA",
										generalAssembly_id: 1062,
									},
									{
										id: 6152,
										name: "GASAYO",
										generalAssembly_id: 1062,
									},
									{
										id: 6153,
										name: "KABUGA",
										generalAssembly_id: 1062,
									},
									{
										id: 6154,
										name: "KANZU",
										generalAssembly_id: 1062,
									},
									{
										id: 6155,
										name: "KAZONGORERO",
										generalAssembly_id: 1062,
									},
									{
										id: 6156,
										name: "NYABITARE",
										generalAssembly_id: 1062,
									},
									{
										id: 6157,
										name: "NYAKIYAGA",
										generalAssembly_id: 1062,
									},
									{
										id: 6158,
										name: "RUHINGO",
										generalAssembly_id: 1062,
									},
									{
										id: 10037,
										name: "Rugano  Court of Appeal ",
										generalAssembly_id: 1062,
									},
									{
										id: 11570,
										name: "Rugano  Gacaca court of sector ",
										generalAssembly_id: 1062,
									},
								],
							},
						],
					},
					{
						id: 20708,
						name: "Karengera",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070805,
								name: "Mwezi",
								sector_id: 20708,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070804,
								name: "Miko",
								sector_id: 20708,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070803,
								name: "Higiro",
								sector_id: 20708,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070802,
								name: "Gashashi",
								sector_id: 20708,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070801,
								name: "Gasayo",
								sector_id: 20708,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1063,
								name: "Kanyinya ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6159,
										name: "GITWA",
										generalAssembly_id: 1063,
									},
									{
										id: 6160,
										name: "MUGANZA",
										generalAssembly_id: 1063,
									},
									{
										id: 6161,
										name: "NYAMUGARI",
										generalAssembly_id: 1063,
									},
									{
										id: 6162,
										name: "NYAMULIRA",
										generalAssembly_id: 1063,
									},
									{
										id: 6163,
										name: "RUBONA",
										generalAssembly_id: 1063,
									},
									{
										id: 10038,
										name: "Kanyinya  Court of Appeal ",
										generalAssembly_id: 1063,
									},
									{
										id: 11571,
										name: "Kanyinya  Gacaca court of sector ",
										generalAssembly_id: 1063,
									},
								],
							},
							{
								id: 1064,
								name: "Karambo ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6164,
										name: "BOLI",
										generalAssembly_id: 1064,
									},
									{
										id: 6165,
										name: "MBANDA",
										generalAssembly_id: 1064,
									},
									{
										id: 6166,
										name: "NYABWINSHI",
										generalAssembly_id: 1064,
									},
									{
										id: 6167,
										name: "NYAGISOZI",
										generalAssembly_id: 1064,
									},
									{
										id: 6168,
										name: "NYAMIYAGA",
										generalAssembly_id: 1064,
									},
									{
										id: 6169,
										name: "RUTARE",
										generalAssembly_id: 1064,
									},
									{
										id: 10039,
										name: "Karambo  Court of Appeal ",
										generalAssembly_id: 1064,
									},
									{
										id: 11572,
										name: "Karambo  Gacaca court of sector ",
										generalAssembly_id: 1064,
									},
								],
							},
							{
								id: 1065,
								name: "Karengera ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6170,
										name: "GITUNDA",
										generalAssembly_id: 1065,
									},
									{
										id: 6171,
										name: "MPINGA",
										generalAssembly_id: 1065,
									},
									{
										id: 6172,
										name: "MUHORA",
										generalAssembly_id: 1065,
									},
									{
										id: 6173,
										name: "RUJEBERI",
										generalAssembly_id: 1065,
									},
									{
										id: 6174,
										name: "RUKUNGURI",
										generalAssembly_id: 1065,
									},
									{
										id: 10040,
										name: "Karengera  Court of Appeal ",
										generalAssembly_id: 1065,
									},
									{
										id: 11573,
										name: "Karengera  Gacaca court of sector ",
										generalAssembly_id: 1065,
									},
								],
							},
							{
								id: 1066,
								name: "Nyanunda  ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6175,
										name: "GAKERI",
										generalAssembly_id: 1066,
									},
									{
										id: 6176,
										name: "GATAGARA",
										generalAssembly_id: 1066,
									},
									{
										id: 6177,
										name: "GIHAYA",
										generalAssembly_id: 1066,
									},
									{
										id: 6178,
										name: "NYAGASHIKURA",
										generalAssembly_id: 1066,
									},
									{
										id: 6179,
										name: "NYARUSANGE",
										generalAssembly_id: 1066,
									},
									{
										id: 6180,
										name: "RUHABWA",
										generalAssembly_id: 1066,
									},
									{
										id: 10041,
										name: "Nyanunda   Court of Appeal ",
										generalAssembly_id: 1066,
									},
									{
										id: 11574,
										name: "Nyanunda   Gacaca court of sector ",
										generalAssembly_id: 1066,
									},
								],
							},
							{
								id: 1067,
								name: "Rurama  ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6181,
										name: "KABISHESHE",
										generalAssembly_id: 1067,
									},
									{
										id: 6182,
										name: "KAMANU",
										generalAssembly_id: 1067,
									},
									{
										id: 6183,
										name: "KAREHE",
										generalAssembly_id: 1067,
									},
									{
										id: 6184,
										name: "NYAGAFUNZO",
										generalAssembly_id: 1067,
									},
									{
										id: 6185,
										name: "RUHINGA",
										generalAssembly_id: 1067,
									},
									{
										id: 10042,
										name: "Rurama   Court of Appeal ",
										generalAssembly_id: 1067,
									},
									{
										id: 11575,
										name: "Rurama   Gacaca court of sector ",
										generalAssembly_id: 1067,
									},
								],
							},
							{
								id: 1068,
								name: "Rwintare  ",
								sector_id: 20708,
								courtOfCells: [
									{
										id: 6186,
										name: "KABUYE",
										generalAssembly_id: 1068,
									},
									{
										id: 6187,
										name: "KANENGE",
										generalAssembly_id: 1068,
									},
									{
										id: 6188,
										name: "KARANGIRO",
										generalAssembly_id: 1068,
									},
									{
										id: 6189,
										name: "MWIYANDO",
										generalAssembly_id: 1068,
									},
									{
										id: 6190,
										name: "RWINKUBA",
										generalAssembly_id: 1068,
									},
									{
										id: 10043,
										name: "Rwintare   Court of Appeal ",
										generalAssembly_id: 1068,
									},
									{
										id: 11576,
										name: "Rwintare   Gacaca court of sector ",
										generalAssembly_id: 1068,
									},
								],
							},
						],
					},
					{
						id: 20709,
						name: "Kirimbi",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2070904,
								name: "Nyarusange",
								sector_id: 20709,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070903,
								name: "Muhororo",
								sector_id: 20709,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070902,
								name: "Karengera",
								sector_id: 20709,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2070901,
								name: "Cyimpindu",
								sector_id: 20709,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1069,
								name: "Cyiya  ",
								sector_id: 20709,
								courtOfCells: [
									{
										id: 6191,
										name: "KABURIRO",
										generalAssembly_id: 1069,
									},
									{
										id: 6192,
										name: "MURARAMBWE",
										generalAssembly_id: 1069,
									},
									{
										id: 6193,
										name: "NYAMITSINDA",
										generalAssembly_id: 1069,
									},
									{
										id: 6194,
										name: "NYIRARWIRU",
										generalAssembly_id: 1069,
									},
									{
										id: 6195,
										name: "RUGARAGARA",
										generalAssembly_id: 1069,
									},
									{
										id: 6196,
										name: "UWAKIBABA",
										generalAssembly_id: 1069,
									},
									{
										id: 10044,
										name: "Cyiya   Court of Appeal ",
										generalAssembly_id: 1069,
									},
									{
										id: 11577,
										name: "Cyiya   Gacaca court of sector ",
										generalAssembly_id: 1069,
									},
								],
							},
							{
								id: 1070,
								name: "Gitsimbwe  ",
								sector_id: 20709,
								courtOfCells: [
									{
										id: 6197,
										name: "GISHEKE",
										generalAssembly_id: 1070,
									},
									{
										id: 6198,
										name: "GITSIMBWE",
										generalAssembly_id: 1070,
									},
									{
										id: 6199,
										name: "MUSHUNGO",
										generalAssembly_id: 1070,
									},
									{
										id: 6200,
										name: "RWAMIKO",
										generalAssembly_id: 1070,
									},
									{
										id: 10045,
										name: "Gitsimbwe   Court of Appeal ",
										generalAssembly_id: 1070,
									},
									{
										id: 11578,
										name: "Gitsimbwe   Gacaca court of sector ",
										generalAssembly_id: 1070,
									},
								],
							},
							{
								id: 1071,
								name: "Kagunga  ",
								sector_id: 20709,
								courtOfCells: [
									{
										id: 6201,
										name: "BUHA",
										generalAssembly_id: 1071,
									},
									{
										id: 6202,
										name: "KAMATARE",
										generalAssembly_id: 1071,
									},
									{
										id: 6203,
										name: "KANYAMUGIRA",
										generalAssembly_id: 1071,
									},
									{
										id: 6204,
										name: "KATABARO",
										generalAssembly_id: 1071,
									},
									{
										id: 6205,
										name: "RUNYINYA",
										generalAssembly_id: 1071,
									},
									{
										id: 6206,
										name: "SABURUME",
										generalAssembly_id: 1071,
									},
									{
										id: 6207,
										name: "WIMANA",
										generalAssembly_id: 1071,
									},
									{
										id: 10046,
										name: "Kagunga   Court of Appeal ",
										generalAssembly_id: 1071,
									},
									{
										id: 11579,
										name: "Kagunga   Gacaca court of sector ",
										generalAssembly_id: 1071,
									},
								],
							},
							{
								id: 1072,
								name: "Kirimbi  ",
								sector_id: 20709,
								courtOfCells: [
									{
										id: 6208,
										name: "GABIRO",
										generalAssembly_id: 1072,
									},
									{
										id: 6209,
										name: "GACUMBI",
										generalAssembly_id: 1072,
									},
									{
										id: 6210,
										name: "GISEKE",
										generalAssembly_id: 1072,
									},
									{
										id: 6211,
										name: "GISESERO",
										generalAssembly_id: 1072,
									},
									{
										id: 6212,
										name: "NYAGACACA",
										generalAssembly_id: 1072,
									},
									{
										id: 6213,
										name: "NYAKABINGO",
										generalAssembly_id: 1072,
									},
									{
										id: 6214,
										name: "RUSEBEYA",
										generalAssembly_id: 1072,
									},
									{
										id: 10047,
										name: "Kirimbi   Court of Appeal ",
										generalAssembly_id: 1072,
									},
									{
										id: 11580,
										name: "Kirimbi   Gacaca court of sector ",
										generalAssembly_id: 1072,
									},
								],
							},
							{
								id: 1073,
								name: "Nyabinaga  ",
								sector_id: 20709,
								courtOfCells: [
									{
										id: 6215,
										name: "KARAMBI_ KABUGA",
										generalAssembly_id: 1073,
									},
									{
										id: 6216,
										name: "KIGARAMA",
										generalAssembly_id: 1073,
									},
									{
										id: 6217,
										name: "NYABINAGA",
										generalAssembly_id: 1073,
									},
									{
										id: 6218,
										name: "RUBONA",
										generalAssembly_id: 1073,
									},
									{
										id: 6219,
										name: "NDUBA",
										generalAssembly_id: 1073,
									},
									{
										id: 6220,
										name: "BUNYAMANZA",
										generalAssembly_id: 1073,
									},
									{
										id: 10048,
										name: "Nyabinaga   Court of Appeal ",
										generalAssembly_id: 1073,
									},
									{
										id: 11581,
										name: "Nyabinaga   Gacaca court of sector ",
										generalAssembly_id: 1073,
									},
								],
							},
						],
					},
					{
						id: 20710,
						name: "Macuba",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071005,
								name: "Vugangoma",
								sector_id: 20710,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071004,
								name: "Rugari",
								sector_id: 20710,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071003,
								name: "Nyakabingo",
								sector_id: 20710,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071002,
								name: "Mutongo",
								sector_id: 20710,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071001,
								name: "Gatare",
								sector_id: 20710,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1074,
								name: "Birembo  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6221,
										name: "BUTARE",
										generalAssembly_id: 1074,
									},
									{
										id: 6222,
										name: "GITWA",
										generalAssembly_id: 1074,
									},
									{
										id: 6223,
										name: "KIREHE",
										generalAssembly_id: 1074,
									},
									{
										id: 6224,
										name: "RUSOZI",
										generalAssembly_id: 1074,
									},
									{
										id: 6225,
										name: "RWAMIKO",
										generalAssembly_id: 1074,
									},
									{
										id: 10049,
										name: "Birembo   Court of Appeal ",
										generalAssembly_id: 1074,
									},
									{
										id: 11582,
										name: "Birembo   Gacaca court of sector ",
										generalAssembly_id: 1074,
									},
								],
							},
							{
								id: 1075,
								name: "Buhoro  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6226,
										name: "GASEKE",
										generalAssembly_id: 1075,
									},
									{
										id: 6227,
										name: "GASHARU",
										generalAssembly_id: 1075,
									},
									{
										id: 6228,
										name: "GASHWI",
										generalAssembly_id: 1075,
									},
									{
										id: 6229,
										name: "KAYENZI",
										generalAssembly_id: 1075,
									},
									{
										id: 6230,
										name: "MURAMA",
										generalAssembly_id: 1075,
									},
									{
										id: 6231,
										name: "NYAKABINGO",
										generalAssembly_id: 1075,
									},
									{
										id: 6232,
										name: "RYASAGAHARA",
										generalAssembly_id: 1075,
									},
									{
										id: 6233,
										name: "WIMANA",
										generalAssembly_id: 1075,
									},
									{
										id: 6234,
										name: "WINGABE",
										generalAssembly_id: 1075,
									},
									{
										id: 10050,
										name: "Buhoro   Court of Appeal ",
										generalAssembly_id: 1075,
									},
									{
										id: 11583,
										name: "Buhoro   Gacaca court of sector ",
										generalAssembly_id: 1075,
									},
								],
							},
							{
								id: 1076,
								name: "Macuba  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6235,
										name: "KANYANKONDO",
										generalAssembly_id: 1076,
									},
									{
										id: 6236,
										name: "MBOGO",
										generalAssembly_id: 1076,
									},
									{
										id: 6237,
										name: "MUTONGO",
										generalAssembly_id: 1076,
									},
									{
										id: 6238,
										name: "NYABIHANGA",
										generalAssembly_id: 1076,
									},
									{
										id: 6239,
										name: "RUPANGO",
										generalAssembly_id: 1076,
									},
									{
										id: 6240,
										name: "RUTOVU",
										generalAssembly_id: 1076,
									},
									{
										id: 10051,
										name: "Macuba   Court of Appeal ",
										generalAssembly_id: 1076,
									},
									{
										id: 11584,
										name: "Macuba   Gacaca court of sector ",
										generalAssembly_id: 1076,
									},
								],
							},
							{
								id: 1077,
								name: "Mwasa  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6241,
										name: "KAJUMIRO",
										generalAssembly_id: 1077,
									},
									{
										id: 6242,
										name: "KANYEGE",
										generalAssembly_id: 1077,
									},
									{
										id: 6243,
										name: "NYAKABINGO",
										generalAssembly_id: 1077,
									},
									{
										id: 6244,
										name: "NYARUSANGE",
										generalAssembly_id: 1077,
									},
									{
										id: 6245,
										name: "RUMAMFU",
										generalAssembly_id: 1077,
									},
									{
										id: 6246,
										name: "RWANKUBA",
										generalAssembly_id: 1077,
									},
									{
										id: 10052,
										name: "Mwasa   Court of Appeal ",
										generalAssembly_id: 1077,
									},
									{
										id: 11585,
										name: "Mwasa   Gacaca court of sector ",
										generalAssembly_id: 1077,
									},
								],
							},
							{
								id: 1078,
								name: "Rukanu  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6247,
										name: "BITABA",
										generalAssembly_id: 1078,
									},
									{
										id: 6248,
										name: "BUNYAMANZA",
										generalAssembly_id: 1078,
									},
									{
										id: 6249,
										name: "CYIJIMA",
										generalAssembly_id: 1078,
									},
									{
										id: 6250,
										name: "GATYAZO",
										generalAssembly_id: 1078,
									},
									{
										id: 6251,
										name: "RWAMBOGO",
										generalAssembly_id: 1078,
									},
									{
										id: 10053,
										name: "Rukanu   Court of Appeal ",
										generalAssembly_id: 1078,
									},
									{
										id: 11586,
										name: "Rukanu   Gacaca court of sector ",
										generalAssembly_id: 1078,
									},
								],
							},
							{
								id: 1079,
								name: "Rumamfu  ",
								sector_id: 20710,
								courtOfCells: [
									{
										id: 6252,
										name: "BITEGA_NYAGAHINGA",
										generalAssembly_id: 1079,
									},
									{
										id: 6253,
										name: "KAGARAMA",
										generalAssembly_id: 1079,
									},
									{
										id: 6254,
										name: "KIGANDI",
										generalAssembly_id: 1079,
									},
									{
										id: 6255,
										name: "KIRAMBIRA",
										generalAssembly_id: 1079,
									},
									{
										id: 10054,
										name: "Rumamfu   Court of Appeal ",
										generalAssembly_id: 1079,
									},
									{
										id: 11587,
										name: "Rumamfu   Gacaca court of sector ",
										generalAssembly_id: 1079,
									},
								],
							},
						],
					},
					{
						id: 20711,
						name: "Mahembe",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071104,
								name: "Nyakavumu",
								sector_id: 20711,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071103,
								name: "Nyagatare",
								sector_id: 20711,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071102,
								name: "Kagarama",
								sector_id: 20711,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071101,
								name: "Gisoke",
								sector_id: 20711,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1080,
								name: "Giko  ",
								sector_id: 20711,
								courtOfCells: [
									{
										id: 6256,
										name: "BISHARARA",
										generalAssembly_id: 1080,
									},
									{
										id: 6257,
										name: "CYIYA",
										generalAssembly_id: 1080,
									},
									{
										id: 6258,
										name: "GIKO A_B",
										generalAssembly_id: 1080,
									},
									{
										id: 6259,
										name: "KINJIRA",
										generalAssembly_id: 1080,
									},
									{
										id: 6260,
										name: "NYAKAVUMU",
										generalAssembly_id: 1080,
									},
									{
										id: 6261,
										name: "NYARUSIZA",
										generalAssembly_id: 1080,
									},
									{
										id: 10055,
										name: "Giko   Court of Appeal ",
										generalAssembly_id: 1080,
									},
									{
										id: 11588,
										name: "Giko   Gacaca court of sector ",
										generalAssembly_id: 1080,
									},
								],
							},
							{
								id: 1081,
								name: "Mahembe ",
								sector_id: 20711,
								courtOfCells: [
									{
										id: 6262,
										name: "GABIRO",
										generalAssembly_id: 1081,
									},
									{
										id: 6263,
										name: "KARAMBO",
										generalAssembly_id: 1081,
									},
									{
										id: 6264,
										name: "KIGARA",
										generalAssembly_id: 1081,
									},
									{
										id: 6265,
										name: "KIZENGA",
										generalAssembly_id: 1081,
									},
									{
										id: 6266,
										name: "MAHEMBE",
										generalAssembly_id: 1081,
									},
									{
										id: 6267,
										name: "NYAMIHEHA",
										generalAssembly_id: 1081,
									},
									{
										id: 6268,
										name: "RUKARAGATA",
										generalAssembly_id: 1081,
									},
									{
										id: 10056,
										name: "Mahembe  Court of Appeal ",
										generalAssembly_id: 1081,
									},
									{
										id: 11589,
										name: "Mahembe  Gacaca court of sector ",
										generalAssembly_id: 1081,
									},
								],
							},
							{
								id: 1082,
								name: "Muramba ",
								sector_id: 20711,
								courtOfCells: [
									{
										id: 6269,
										name: "FUMBA",
										generalAssembly_id: 1082,
									},
									{
										id: 6270,
										name: "GASHARU_RUHANGA",
										generalAssembly_id: 1082,
									},
									{
										id: 6271,
										name: "GISEBEYA_NYABUMERA",
										generalAssembly_id: 1082,
									},
									{
										id: 6272,
										name: "KAGARAMA",
										generalAssembly_id: 1082,
									},
									{
										id: 6273,
										name: "KANYONI_MURAMBA",
										generalAssembly_id: 1082,
									},
									{
										id: 10057,
										name: "Muramba  Court of Appeal ",
										generalAssembly_id: 1082,
									},
									{
										id: 11590,
										name: "Muramba  Gacaca court of sector ",
										generalAssembly_id: 1082,
									},
								],
							},
							{
								id: 1083,
								name: "Nyagahima ",
								sector_id: 20711,
								courtOfCells: [
									{
										id: 6274,
										name: "BIGALI_NYAKABANDE",
										generalAssembly_id: 1083,
									},
									{
										id: 6275,
										name: "GISOKE",
										generalAssembly_id: 1083,
									},
									{
										id: 6276,
										name: "KIVUMU",
										generalAssembly_id: 1083,
									},
									{
										id: 6277,
										name: "MURUNDO_NYAGAHIMA",
										generalAssembly_id: 1083,
									},
									{
										id: 10058,
										name: "Nyagahima  Court of Appeal ",
										generalAssembly_id: 1083,
									},
									{
										id: 11591,
										name: "Nyagahima  Gacaca court of sector ",
										generalAssembly_id: 1083,
									},
								],
							},
						],
					},
					{
						id: 20712,
						name: "Nyabitekeri",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071205,
								name: "Ntango",
								sector_id: 20712,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071204,
								name: "Muyange",
								sector_id: 20712,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071203,
								name: "Mariba",
								sector_id: 20712,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071202,
								name: "Kinunga",
								sector_id: 20712,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071201,
								name: "Kigabiro",
								sector_id: 20712,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1084,
								name: "Bugeza ",
								sector_id: 20712,
								courtOfCells: [
									{
										id: 6278,
										name: "BUGIGA",
										generalAssembly_id: 1084,
									},
									{
										id: 6279,
										name: "BUSABO",
										generalAssembly_id: 1084,
									},
									{
										id: 6280,
										name: "GISAGARA",
										generalAssembly_id: 1084,
									},
									{
										id: 6281,
										name: "GITWA",
										generalAssembly_id: 1084,
									},
									{
										id: 6282,
										name: "KABIGUZI",
										generalAssembly_id: 1084,
									},
									{
										id: 6283,
										name: "KAGARAMA",
										generalAssembly_id: 1084,
									},
									{
										id: 6284,
										name: "KAMAHONGO",
										generalAssembly_id: 1084,
									},
									{
										id: 6285,
										name: "KANKONI",
										generalAssembly_id: 1084,
									},
									{
										id: 6286,
										name: "KANUNDA",
										generalAssembly_id: 1084,
									},
									{
										id: 6287,
										name: "MURAMBI",
										generalAssembly_id: 1084,
									},
									{
										id: 6288,
										name: "RUHAMAGARIRO",
										generalAssembly_id: 1084,
									},
									{
										id: 10059,
										name: "Bugeza  Court of Appeal ",
										generalAssembly_id: 1084,
									},
									{
										id: 11592,
										name: "Bugeza  Gacaca court of sector ",
										generalAssembly_id: 1084,
									},
								],
							},
							{
								id: 1085,
								name: "Bunyangurube ",
								sector_id: 20712,
								courtOfCells: [
									{
										id: 6289,
										name: "KAGARAMA",
										generalAssembly_id: 1085,
									},
									{
										id: 6290,
										name: "KIBANDA",
										generalAssembly_id: 1085,
									},
									{
										id: 6291,
										name: "KINUNGA",
										generalAssembly_id: 1085,
									},
									{
										id: 6292,
										name: "MUKARANGE",
										generalAssembly_id: 1085,
									},
									{
										id: 6293,
										name: "MUREMURE",
										generalAssembly_id: 1085,
									},
									{
										id: 6294,
										name: "NYAGATWA",
										generalAssembly_id: 1085,
									},
									{
										id: 6295,
										name: "NYAMAVUGO",
										generalAssembly_id: 1085,
									},
									{
										id: 6296,
										name: "RUGABANO",
										generalAssembly_id: 1085,
									},
									{
										id: 6297,
										name: "RUGARAMA",
										generalAssembly_id: 1085,
									},
									{
										id: 6298,
										name: "RUKU",
										generalAssembly_id: 1085,
									},
									{
										id: 10060,
										name: "Bunyangurube  Court of Appeal ",
										generalAssembly_id: 1085,
									},
									{
										id: 11593,
										name: "Bunyangurube  Gacaca court of sector ",
										generalAssembly_id: 1085,
									},
								],
							},
							{
								id: 1086,
								name: "Bunyenga ",
								sector_id: 20712,
								courtOfCells: [
									{
										id: 6299,
										name: "BUNYENGA",
										generalAssembly_id: 1086,
									},
									{
										id: 6300,
										name: "CYAMUTI",
										generalAssembly_id: 1086,
									},
									{
										id: 6301,
										name: "KUNGA",
										generalAssembly_id: 1086,
									},
									{
										id: 6302,
										name: "MALIBA",
										generalAssembly_id: 1086,
									},
									{
										id: 6303,
										name: "MPINGA",
										generalAssembly_id: 1086,
									},
									{
										id: 6304,
										name: "MURAMBI",
										generalAssembly_id: 1086,
									},
									{
										id: 6305,
										name: "RUGINGA",
										generalAssembly_id: 1086,
									},
									{
										id: 6306,
										name: "RWERU",
										generalAssembly_id: 1086,
									},
									{
										id: 10061,
										name: "Bunyenga  Court of Appeal ",
										generalAssembly_id: 1086,
									},
									{
										id: 11594,
										name: "Bunyenga  Gacaca court of sector ",
										generalAssembly_id: 1086,
									},
								],
							},
							{
								id: 1087,
								name: "Mukoma ",
								sector_id: 20712,
								courtOfCells: [
									{
										id: 6307,
										name: "GASAYO",
										generalAssembly_id: 1087,
									},
									{
										id: 6308,
										name: "GITABA",
										generalAssembly_id: 1087,
									},
									{
										id: 6309,
										name: "GITWA",
										generalAssembly_id: 1087,
									},
									{
										id: 6310,
										name: "KARANGO",
										generalAssembly_id: 1087,
									},
									{
										id: 6311,
										name: "KILINDA",
										generalAssembly_id: 1087,
									},
									{
										id: 6312,
										name: "MALIBA",
										generalAssembly_id: 1087,
									},
									{
										id: 6313,
										name: "MUKOMA",
										generalAssembly_id: 1087,
									},
									{
										id: 6314,
										name: "MURENGE",
										generalAssembly_id: 1087,
									},
									{
										id: 6315,
										name: "MUYANGE",
										generalAssembly_id: 1087,
									},
									{
										id: 6316,
										name: "NTABO",
										generalAssembly_id: 1087,
									},
									{
										id: 10062,
										name: "Mukoma  Court of Appeal ",
										generalAssembly_id: 1087,
									},
									{
										id: 11595,
										name: "Mukoma  Gacaca court of sector ",
										generalAssembly_id: 1087,
									},
								],
							},
							{
								id: 1088,
								name: "Nyabitekeri ",
								sector_id: 20712,
								courtOfCells: [
									{
										id: 6317,
										name: "BUHOKORO",
										generalAssembly_id: 1088,
									},
									{
										id: 6318,
										name: "KABARE",
										generalAssembly_id: 1088,
									},
									{
										id: 6319,
										name: "KAZIBO",
										generalAssembly_id: 1088,
									},
									{
										id: 6320,
										name: "MUNANIRA",
										generalAssembly_id: 1088,
									},
									{
										id: 6321,
										name: "MUYANGE",
										generalAssembly_id: 1088,
									},
									{
										id: 6322,
										name: "NYABITEKERI",
										generalAssembly_id: 1088,
									},
									{
										id: 6323,
										name: "NYANGE",
										generalAssembly_id: 1088,
									},
									{
										id: 6324,
										name: "RURENGE",
										generalAssembly_id: 1088,
									},
									{
										id: 6325,
										name: "TUNDWE",
										generalAssembly_id: 1088,
									},
									{
										id: 10063,
										name: "Nyabitekeri  Court of Appeal ",
										generalAssembly_id: 1088,
									},
									{
										id: 11596,
										name: "Nyabitekeri  Gacaca court of sector ",
										generalAssembly_id: 1088,
									},
								],
							},
						],
					},
					{
						id: 20713,
						name: "Rangiro",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071304,
								name: "Murambi",
								sector_id: 20713,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071303,
								name: "Jurwe",
								sector_id: 20713,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071302,
								name: "Gakenke",
								sector_id: 20713,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071301,
								name: "Banda",
								sector_id: 20713,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1089,
								name: "Gahisi ",
								sector_id: 20713,
								courtOfCells: [
									{
										id: 6326,
										name: "BANDA",
										generalAssembly_id: 1089,
									},
									{
										id: 6327,
										name: "GAHISI",
										generalAssembly_id: 1089,
									},
									{
										id: 6328,
										name: "GAKENKE",
										generalAssembly_id: 1089,
									},
									{
										id: 6329,
										name: "GASANANE",
										generalAssembly_id: 1089,
									},
									{
										id: 6330,
										name: "RUGANZA",
										generalAssembly_id: 1089,
									},
									{
										id: 10064,
										name: "Gahisi  Court of Appeal ",
										generalAssembly_id: 1089,
									},
									{
										id: 11597,
										name: "Gahisi  Gacaca court of sector ",
										generalAssembly_id: 1089,
									},
								],
							},
							{
								id: 1090,
								name: "Mpabe ",
								sector_id: 20713,
								courtOfCells: [
									{
										id: 6331,
										name: "BUNYENYEZI",
										generalAssembly_id: 1090,
									},
									{
										id: 6332,
										name: "GASEBEYA",
										generalAssembly_id: 1090,
									},
									{
										id: 6333,
										name: "GATAGARA",
										generalAssembly_id: 1090,
									},
									{
										id: 6334,
										name: "MUNINI",
										generalAssembly_id: 1090,
									},
									{
										id: 6335,
										name: "RUDEHERO",
										generalAssembly_id: 1090,
									},
									{
										id: 6336,
										name: "RUGOMERO",
										generalAssembly_id: 1090,
									},
									{
										id: 10065,
										name: "Mpabe  Court of Appeal ",
										generalAssembly_id: 1090,
									},
									{
										id: 11598,
										name: "Mpabe  Gacaca court of sector ",
										generalAssembly_id: 1090,
									},
								],
							},
							{
								id: 1091,
								name: "Rangiro ",
								sector_id: 20713,
								courtOfCells: [
									{
										id: 6337,
										name: "KANEKE",
										generalAssembly_id: 1091,
									},
									{
										id: 6338,
										name: "KIBAVU",
										generalAssembly_id: 1091,
									},
									{
										id: 6339,
										name: "MURAMBI",
										generalAssembly_id: 1091,
									},
									{
										id: 6340,
										name: "NYAKABINGO",
										generalAssembly_id: 1091,
									},
									{
										id: 6341,
										name: "NYARWUNGO",
										generalAssembly_id: 1091,
									},
									{
										id: 6342,
										name: "RYARUBASHA",
										generalAssembly_id: 1091,
									},
									{
										id: 10066,
										name: "Rangiro  Court of Appeal ",
										generalAssembly_id: 1091,
									},
									{
										id: 11599,
										name: "Rangiro  Gacaca court of sector ",
										generalAssembly_id: 1091,
									},
								],
							},
						],
					},
					{
						id: 20714,
						name: "Ruharambuga",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071404,
								name: "Wimana",
								sector_id: 20714,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071403,
								name: "Save",
								sector_id: 20714,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071402,
								name: "Ntendezi",
								sector_id: 20714,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071401,
								name: "Kanazi",
								sector_id: 20714,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1092,
								name: "Bumazi ",
								sector_id: 20714,
								courtOfCells: [
									{
										id: 6343,
										name: "GASHWATI",
										generalAssembly_id: 1092,
									},
									{
										id: 6344,
										name: "GITABA",
										generalAssembly_id: 1092,
									},
									{
										id: 6345,
										name: "KADASHYA",
										generalAssembly_id: 1092,
									},
									{
										id: 6346,
										name: "KIRABACUTA",
										generalAssembly_id: 1092,
									},
									{
										id: 10067,
										name: "Bumazi  Court of Appeal ",
										generalAssembly_id: 1092,
									},
									{
										id: 11600,
										name: "Bumazi  Gacaca court of sector ",
										generalAssembly_id: 1092,
									},
								],
							},
							{
								id: 1093,
								name: "Nyamuhunga ",
								sector_id: 20714,
								courtOfCells: [
									{
										id: 6347,
										name: "BASHANGA",
										generalAssembly_id: 1093,
									},
									{
										id: 6348,
										name: "CYIMPUNDU",
										generalAssembly_id: 1093,
									},
									{
										id: 6349,
										name: "KANYOVU",
										generalAssembly_id: 1093,
									},
									{
										id: 6350,
										name: "MANZI",
										generalAssembly_id: 1093,
									},
									{
										id: 6351,
										name: "RWIMPILI",
										generalAssembly_id: 1093,
									},
									{
										id: 6352,
										name: "SAVE",
										generalAssembly_id: 1093,
									},
									{
										id: 10068,
										name: "Nyamuhunga  Court of Appeal ",
										generalAssembly_id: 1093,
									},
									{
										id: 11601,
										name: "Nyamuhunga  Gacaca court of sector ",
										generalAssembly_id: 1093,
									},
								],
							},
							{
								id: 1094,
								name: "Ruharambuga ",
								sector_id: 20714,
								courtOfCells: [
									{
										id: 6353,
										name: "GIHINGA",
										generalAssembly_id: 1094,
									},
									{
										id: 6354,
										name: "GIKO",
										generalAssembly_id: 1094,
									},
									{
										id: 6355,
										name: "KIGABIRO",
										generalAssembly_id: 1094,
									},
									{
										id: 6356,
										name: "KIGENGE",
										generalAssembly_id: 1094,
									},
									{
										id: 6357,
										name: "MUKO",
										generalAssembly_id: 1094,
									},
									{
										id: 6358,
										name: "NGANZO",
										generalAssembly_id: 1094,
									},
									{
										id: 6359,
										name: "RUKOMA",
										generalAssembly_id: 1094,
									},
									{
										id: 10069,
										name: "Ruharambuga  Court of Appeal ",
										generalAssembly_id: 1094,
									},
									{
										id: 11602,
										name: "Ruharambuga  Gacaca court of sector ",
										generalAssembly_id: 1094,
									},
								],
							},
							{
								id: 1095,
								name: "Rusambu ",
								sector_id: 20714,
								courtOfCells: [
									{
										id: 6360,
										name: "KANAZI",
										generalAssembly_id: 1095,
									},
									{
										id: 6361,
										name: "KARAMBO",
										generalAssembly_id: 1095,
									},
									{
										id: 6362,
										name: "RUBIHA",
										generalAssembly_id: 1095,
									},
									{
										id: 6363,
										name: "RWAMAHWA",
										generalAssembly_id: 1095,
									},
									{
										id: 6364,
										name: "RYAKIGAJU",
										generalAssembly_id: 1095,
									},
									{
										id: 10070,
										name: "Rusambu  Court of Appeal ",
										generalAssembly_id: 1095,
									},
									{
										id: 11603,
										name: "Rusambu  Gacaca court of sector ",
										generalAssembly_id: 1095,
									},
								],
							},
							{
								id: 1096,
								name: "Rwabidege ",
								sector_id: 20714,
								courtOfCells: [
									{
										id: 6365,
										name: "BAGONDO",
										generalAssembly_id: 1096,
									},
									{
										id: 6366,
										name: "KAGABIRO",
										generalAssembly_id: 1096,
									},
									{
										id: 6367,
										name: "KIGOGO",
										generalAssembly_id: 1096,
									},
									{
										id: 6368,
										name: "NYAGATONGO",
										generalAssembly_id: 1096,
									},
									{
										id: 6369,
										name: "UWANYANGURA",
										generalAssembly_id: 1096,
									},
									{
										id: 6370,
										name: "WIMANA",
										generalAssembly_id: 1096,
									},
									{
										id: 10071,
										name: "Rwabidege  Court of Appeal ",
										generalAssembly_id: 1096,
									},
									{
										id: 11604,
										name: "Rwabidege  Gacaca court of sector ",
										generalAssembly_id: 1096,
									},
								],
							},
						],
					},
					{
						id: 20715,
						name: "Shangi",
						district_id: 207,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2071505,
								name: "Shangi",
								sector_id: 20715,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071504,
								name: "Nyamugari",
								sector_id: 20715,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071503,
								name: "Mugera",
								sector_id: 20715,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071502,
								name: "Mataba",
								sector_id: 20715,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2071501,
								name: "Burimba",
								sector_id: 20715,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1097,
								name: "Gabiro ",
								sector_id: 20715,
								courtOfCells: [
									{
										id: 6371,
										name: "GABIRO",
										generalAssembly_id: 1097,
									},
									{
										id: 6372,
										name: "GASUMO",
										generalAssembly_id: 1097,
									},
									{
										id: 6373,
										name: "GIKOMBE",
										generalAssembly_id: 1097,
									},
									{
										id: 6374,
										name: "KABAHANDE",
										generalAssembly_id: 1097,
									},
									{
										id: 6375,
										name: "MATABA",
										generalAssembly_id: 1097,
									},
									{
										id: 6376,
										name: "MPISHYI",
										generalAssembly_id: 1097,
									},
									{
										id: 6377,
										name: "RUZINGA",
										generalAssembly_id: 1097,
									},
									{
										id: 6378,
										name: "RWABAGOYI",
										generalAssembly_id: 1097,
									},
									{
										id: 10072,
										name: "Gabiro  Court of Appeal ",
										generalAssembly_id: 1097,
									},
									{
										id: 11605,
										name: "Gabiro  Gacaca court of sector ",
										generalAssembly_id: 1097,
									},
								],
							},
							{
								id: 1098,
								name: "Mugera ",
								sector_id: 20715,
								courtOfCells: [
									{
										id: 6379,
										name: "BIKUNDA",
										generalAssembly_id: 1098,
									},
									{
										id: 6380,
										name: "BWERANYANGE",
										generalAssembly_id: 1098,
									},
									{
										id: 6381,
										name: "GITWA",
										generalAssembly_id: 1098,
									},
									{
										id: 6382,
										name: "MUGERA",
										generalAssembly_id: 1098,
									},
									{
										id: 6383,
										name: "MURAMBI",
										generalAssembly_id: 1098,
									},
									{
										id: 6384,
										name: "NYAKAGANO",
										generalAssembly_id: 1098,
									},
									{
										id: 6385,
										name: "NYAKIBINGO",
										generalAssembly_id: 1098,
									},
									{
										id: 6386,
										name: "RAMBURA",
										generalAssembly_id: 1098,
									},
									{
										id: 6387,
										name: "RUBAYI",
										generalAssembly_id: 1098,
									},
									{
										id: 6388,
										name: "RUKOHWA",
										generalAssembly_id: 1098,
									},
									{
										id: 6389,
										name: "RUSEBEYA",
										generalAssembly_id: 1098,
									},
									{
										id: 10073,
										name: "Mugera  Court of Appeal ",
										generalAssembly_id: 1098,
									},
									{
										id: 11606,
										name: "Mugera  Gacaca court of sector ",
										generalAssembly_id: 1098,
									},
								],
							},
							{
								id: 1099,
								name: "Nyamugari ",
								sector_id: 20715,
								courtOfCells: [
									{
										id: 6390,
										name: "BUKEYE",
										generalAssembly_id: 1099,
									},
									{
										id: 6391,
										name: "GAHANGA",
										generalAssembly_id: 1099,
									},
									{
										id: 6392,
										name: "GASHIRU",
										generalAssembly_id: 1099,
									},
									{
										id: 6393,
										name: "GIKOMBE",
										generalAssembly_id: 1099,
									},
									{
										id: 6394,
										name: "KANINDA",
										generalAssembly_id: 1099,
									},
									{
										id: 6395,
										name: "KIGARAMA",
										generalAssembly_id: 1099,
									},
									{
										id: 6396,
										name: "MWAMBU",
										generalAssembly_id: 1099,
									},
									{
										id: 6397,
										name: "NYAMATEKE",
										generalAssembly_id: 1099,
									},
									{
										id: 6398,
										name: "NYAMUGARI",
										generalAssembly_id: 1099,
									},
									{
										id: 10074,
										name: "Nyamugari  Court of Appeal ",
										generalAssembly_id: 1099,
									},
									{
										id: 11607,
										name: "Nyamugari  Gacaca court of sector ",
										generalAssembly_id: 1099,
									},
								],
							},
							{
								id: 1100,
								name: "Shangi ",
								sector_id: 20715,
								courtOfCells: [
									{
										id: 6399,
										name: "BUGOMBA",
										generalAssembly_id: 1100,
									},
									{
										id: 6400,
										name: "GASHARU",
										generalAssembly_id: 1100,
									},
									{
										id: 6401,
										name: "KABAGA",
										generalAssembly_id: 1100,
									},
									{
										id: 6402,
										name: "KABERE",
										generalAssembly_id: 1100,
									},
									{
										id: 6403,
										name: "KARAMBO",
										generalAssembly_id: 1100,
									},
									{
										id: 6404,
										name: "KIVOGO",
										generalAssembly_id: 1100,
									},
									{
										id: 6405,
										name: "MUNINI",
										generalAssembly_id: 1100,
									},
									{
										id: 6406,
										name: "MWAMBU",
										generalAssembly_id: 1100,
									},
									{
										id: 6407,
										name: "NYAMATEKE",
										generalAssembly_id: 1100,
									},
									{
										id: 10075,
										name: "Shangi  Court of Appeal ",
										generalAssembly_id: 1100,
									},
									{
										id: 11608,
										name: "Shangi  Gacaca court of sector ",
										generalAssembly_id: 1100,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 203,
				name: "Rubavu",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20301,
						name: "Bugeshi",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030107,
								name: "Rusiza",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030106,
								name: "Nsherima",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030105,
								name: "Mutovu",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030104,
								name: "Kabumba",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030103,
								name: "Hehu",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030102,
								name: "Butaka",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030101,
								name: "Buringo",
								sector_id: 20301,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1244,
								name: "Butaka ",
								sector_id: 20301,
								courtOfCells: [
									{
										id: 7118,
										name: "AKIMITONI",
										generalAssembly_id: 1244,
									},
									{
										id: 7119,
										name: "BIPFURA",
										generalAssembly_id: 1244,
									},
									{
										id: 7120,
										name: "BUGESHI",
										generalAssembly_id: 1244,
									},
									{
										id: 7121,
										name: "BURINGO",
										generalAssembly_id: 1244,
									},
									{
										id: 7122,
										name: "GAHIRA",
										generalAssembly_id: 1244,
									},
									{
										id: 7123,
										name: "KINYAMUHANGA",
										generalAssembly_id: 1244,
									},
									{
										id: 7124,
										name: "MUREMURE",
										generalAssembly_id: 1244,
									},
									{
										id: 7125,
										name: "NSHELIMA",
										generalAssembly_id: 1244,
									},
									{
										id: 7126,
										name: "NYAMUTUKURA",
										generalAssembly_id: 1244,
									},
									{
										id: 10218,
										name: "Butaka  Court of Appeal ",
										generalAssembly_id: 1244,
									},
									{
										id: 11751,
										name: "Butaka  Gacaca court of sector ",
										generalAssembly_id: 1244,
									},
								],
							},
							{
								id: 1245,
								name: "Mutovu ",
								sector_id: 20301,
								courtOfCells: [
									{
										id: 7127,
										name: "BUGESHI",
										generalAssembly_id: 1245,
									},
									{
										id: 7128,
										name: "BWERAMANA",
										generalAssembly_id: 1245,
									},
									{
										id: 7129,
										name: "HEHU",
										generalAssembly_id: 1245,
									},
									{
										id: 7130,
										name: "HUMURE",
										generalAssembly_id: 1245,
									},
									{
										id: 7131,
										name: "KABUHANGA",
										generalAssembly_id: 1245,
									},
									{
										id: 7132,
										name: "MBURAMAZI",
										generalAssembly_id: 1245,
									},
									{
										id: 7133,
										name: "RINDIRO",
										generalAssembly_id: 1245,
									},
									{
										id: 7134,
										name: "RWANYA",
										generalAssembly_id: 1245,
									},
									{
										id: 7135,
										name: "VUNA",
										generalAssembly_id: 1245,
									},
									{
										id: 10219,
										name: "Mutovu  Court of Appeal ",
										generalAssembly_id: 1245,
									},
									{
										id: 11752,
										name: "Mutovu  Gacaca court of sector ",
										generalAssembly_id: 1245,
									},
								],
							},
							{
								id: 1246,
								name: "Rusiza ",
								sector_id: 20301,
								courtOfCells: [
									{
										id: 7136,
										name: "BIHE",
										generalAssembly_id: 1246,
									},
									{
										id: 7137,
										name: "BONDE",
										generalAssembly_id: 1246,
									},
									{
										id: 7138,
										name: "KABUMBA",
										generalAssembly_id: 1246,
									},
									{
										id: 7139,
										name: "RUSIZA",
										generalAssembly_id: 1246,
									},
									{
										id: 7140,
										name: "SHAKI",
										generalAssembly_id: 1246,
									},
									{
										id: 7141,
										name: "BUNJURI",
										generalAssembly_id: 1246,
									},
									{
										id: 10220,
										name: "Rusiza  Court of Appeal ",
										generalAssembly_id: 1246,
									},
									{
										id: 11753,
										name: "Rusiza  Gacaca court of sector ",
										generalAssembly_id: 1246,
									},
								],
							},
						],
					},
					{
						id: 20302,
						name: "Busasamana",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030207,
								name: "Rusura",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030206,
								name: "Nyacyonga",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030205,
								name: "Makoro",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030204,
								name: "Kageshi",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030203,
								name: "Gihonga",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030202,
								name: "Gasiza",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030201,
								name: "Gacurabwenge",
								sector_id: 20302,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1247,
								name: "Akantwari",
								sector_id: 20302,
								courtOfCells: [
									{
										id: 7142,
										name: "GASIZA",
										generalAssembly_id: 1247,
									},
									{
										id: 7143,
										name: "MASHINGA",
										generalAssembly_id: 1247,
									},
									{
										id: 7144,
										name: "MUNANIRA",
										generalAssembly_id: 1247,
									},
									{
										id: 7145,
										name: "RWAGARE",
										generalAssembly_id: 1247,
									},
									{
										id: 7146,
										name: "BUNYOGWE",
										generalAssembly_id: 1247,
									},
									{
										id: 10221,
										name: "Akantwari Court of Appeal ",
										generalAssembly_id: 1247,
									},
									{
										id: 11754,
										name: "Akantwari Gacaca court of sector ",
										generalAssembly_id: 1247,
									},
								],
							},
							{
								id: 1248,
								name: "Busumba ",
								sector_id: 20302,
								courtOfCells: [
									{
										id: 7147,
										name: "RUHENGERI",
										generalAssembly_id: 1248,
									},
									{
										id: 7148,
										name: "BWEZA",
										generalAssembly_id: 1248,
									},
									{
										id: 7149,
										name: "CYANIKA",
										generalAssembly_id: 1248,
									},
									{
										id: 7150,
										name: "KABAGOYI",
										generalAssembly_id: 1248,
									},
									{
										id: 7151,
										name: "KINGOGO",
										generalAssembly_id: 1248,
									},
									{
										id: 7152,
										name: "KINOGO",
										generalAssembly_id: 1248,
									},
									{
										id: 7153,
										name: "MUNEGE",
										generalAssembly_id: 1248,
									},
									{
										id: 10222,
										name: "Busumba  Court of Appeal ",
										generalAssembly_id: 1248,
									},
									{
										id: 11755,
										name: "Busumba  Gacaca court of sector ",
										generalAssembly_id: 1248,
									},
								],
							},
							{
								id: 1249,
								name: "Gabiro ",
								sector_id: 20302,
								courtOfCells: [
									{
										id: 7154,
										name: "BWINIHIRE",
										generalAssembly_id: 1249,
									},
									{
										id: 7155,
										name: "CYANIKA",
										generalAssembly_id: 1249,
									},
									{
										id: 7156,
										name: "KITANIHIRWA",
										generalAssembly_id: 1249,
									},
									{
										id: 7157,
										name: "NGORO",
										generalAssembly_id: 1249,
									},
									{
										id: 7158,
										name: "RWANKUBA",
										generalAssembly_id: 1249,
									},
									{
										id: 10223,
										name: "Gabiro  Court of Appeal ",
										generalAssembly_id: 1249,
									},
									{
										id: 11756,
										name: "Gabiro  Gacaca court of sector ",
										generalAssembly_id: 1249,
									},
								],
							},
							{
								id: 1250,
								name: "Gacurabwenge ",
								sector_id: 20302,
								courtOfCells: [
									{
										id: 7159,
										name: "BUGARAMA",
										generalAssembly_id: 1250,
									},
									{
										id: 7160,
										name: "BUSANGANYA",
										generalAssembly_id: 1250,
									},
									{
										id: 7161,
										name: "KAMUYENZI",
										generalAssembly_id: 1250,
									},
									{
										id: 7162,
										name: "KANYABIJUMBA",
										generalAssembly_id: 1250,
									},
									{
										id: 7163,
										name: "RUBIKIRO",
										generalAssembly_id: 1250,
									},
									{
										id: 10224,
										name: "Gacurabwenge  Court of Appeal ",
										generalAssembly_id: 1250,
									},
									{
										id: 11757,
										name: "Gacurabwenge  Gacaca court of sector ",
										generalAssembly_id: 1250,
									},
								],
							},
							{
								id: 1251,
								name: "Mubona ",
								sector_id: 20302,
								courtOfCells: [
									{
										id: 7164,
										name: "AKANYIRABUGOYI",
										generalAssembly_id: 1251,
									},
									{
										id: 7165,
										name: "GASENYI",
										generalAssembly_id: 1251,
									},
									{
										id: 7166,
										name: "HANIKA",
										generalAssembly_id: 1251,
									},
									{
										id: 7167,
										name: "AKAMUZAMUZI",
										generalAssembly_id: 1251,
									},
									{
										id: 7168,
										name: "AKANYIRABUKIMA",
										generalAssembly_id: 1251,
									},
									{
										id: 7169,
										name: "KARAMBI",
										generalAssembly_id: 1251,
									},
									{
										id: 10225,
										name: "Mubona  Court of Appeal ",
										generalAssembly_id: 1251,
									},
									{
										id: 11758,
										name: "Mubona  Gacaca court of sector ",
										generalAssembly_id: 1251,
									},
								],
							},
						],
					},
					{
						id: 20303,
						name: "Cyanzarwe",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030308,
								name: "Ryabizige",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030307,
								name: "Rwanzekuma",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030306,
								name: "Rwangara",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030305,
								name: "Makurizo",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030304,
								name: "Kinyanzovu",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030303,
								name: "Gora",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030302,
								name: "Cyanzarwe",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030301,
								name: "Busigari",
								sector_id: 20303,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1252,
								name: "Kinyanzovu ",
								sector_id: 20303,
								courtOfCells: [
									{
										id: 7170,
										name: "BISIZI",
										generalAssembly_id: 1252,
									},
									{
										id: 7171,
										name: "BUSHANGA",
										generalAssembly_id: 1252,
									},
									{
										id: 7172,
										name: "KANEMBWE",
										generalAssembly_id: 1252,
									},
									{
										id: 7173,
										name: "KANYENTAMBI",
										generalAssembly_id: 1252,
									},
									{
										id: 7174,
										name: "KIBAYA",
										generalAssembly_id: 1252,
									},
									{
										id: 7175,
										name: "RUTAGARA",
										generalAssembly_id: 1252,
									},
									{
										id: 7176,
										name: "RWASHUNGWE",
										generalAssembly_id: 1252,
									},
									{
										id: 7177,
										name: "KINYANZOVU",
										generalAssembly_id: 1252,
									},
									{
										id: 10226,
										name: "Kinyanzovu  Court of Appeal ",
										generalAssembly_id: 1252,
									},
									{
										id: 11759,
										name: "Kinyanzovu  Gacaca court of sector ",
										generalAssembly_id: 1252,
									},
								],
							},
							{
								id: 1253,
								name: "Muhanda ",
								sector_id: 20303,
								courtOfCells: [
									{
										id: 7178,
										name: "BURAMBO",
										generalAssembly_id: 1253,
									},
									{
										id: 7179,
										name: "KAVUMU",
										generalAssembly_id: 1253,
									},
									{
										id: 7180,
										name: "MUTI",
										generalAssembly_id: 1253,
									},
									{
										id: 7181,
										name: "NGANZO",
										generalAssembly_id: 1253,
									},
									{
										id: 7182,
										name: "RWANGARA",
										generalAssembly_id: 1253,
									},
									{
										id: 7183,
										name: "RYABIZIGE",
										generalAssembly_id: 1253,
									},
									{
										id: 7184,
										name: "RUSHURA",
										generalAssembly_id: 1253,
									},
									{
										id: 10227,
										name: "Muhanda  Court of Appeal ",
										generalAssembly_id: 1253,
									},
									{
										id: 11760,
										name: "Muhanda  Gacaca court of sector ",
										generalAssembly_id: 1253,
									},
								],
							},
							{
								id: 1254,
								name: "Mukingo ",
								sector_id: 20303,
								courtOfCells: [
									{
										id: 7185,
										name: "GAHUMIRO",
										generalAssembly_id: 1254,
									},
									{
										id: 7186,
										name: "GASHUHA",
										generalAssembly_id: 1254,
									},
									{
										id: 7187,
										name: "GORA",
										generalAssembly_id: 1254,
									},
									{
										id: 7188,
										name: "KABERE",
										generalAssembly_id: 1254,
									},
									{
										id: 7189,
										name: "KABUKOMA",
										generalAssembly_id: 1254,
									},
									{
										id: 7190,
										name: "MAKURIZO",
										generalAssembly_id: 1254,
									},
									{
										id: 7191,
										name: "MUKINGO",
										generalAssembly_id: 1254,
									},
									{
										id: 10228,
										name: "Mukingo  Court of Appeal ",
										generalAssembly_id: 1254,
									},
									{
										id: 11761,
										name: "Mukingo  Gacaca court of sector ",
										generalAssembly_id: 1254,
									},
								],
							},
							{
								id: 1255,
								name: "Rwanzekuma ",
								sector_id: 20303,
								courtOfCells: [
									{
										id: 7192,
										name: "CYANZARWE",
										generalAssembly_id: 1255,
									},
									{
										id: 7193,
										name: "KABIRIZI",
										generalAssembly_id: 1255,
									},
									{
										id: 7194,
										name: "KARANGARA",
										generalAssembly_id: 1255,
									},
									{
										id: 7195,
										name: "RUNABA",
										generalAssembly_id: 1255,
									},
									{
										id: 7196,
										name: "RUKORAKORE",
										generalAssembly_id: 1255,
									},
									{
										id: 7197,
										name: "RUSHURA",
										generalAssembly_id: 1255,
									},
									{
										id: 10229,
										name: "Rwanzekuma  Court of Appeal ",
										generalAssembly_id: 1255,
									},
									{
										id: 11762,
										name: "Rwanzekuma  Gacaca court of sector ",
										generalAssembly_id: 1255,
									},
								],
							},
						],
					},
					{
						id: 20304,
						name: "Gisenyi",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030407,
								name: "Umuganda",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030406,
								name: "Rubavu",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030405,
								name: "Nengo",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030404,
								name: "Mbugangari",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030403,
								name: "Kivumu",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030402,
								name: "Bugoyi",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030401,
								name: "Amahoro",
								sector_id: 20304,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1256,
								name: "Gisenyi ",
								sector_id: 20304,
								courtOfCells: [
									{
										id: 7198,
										name: "MAHORO",
										generalAssembly_id: 1256,
									},
									{
										id: 7199,
										name: "BONDE",
										generalAssembly_id: 1256,
									},
									{
										id: 7200,
										name: "BUGOYI",
										generalAssembly_id: 1256,
									},
									{
										id: 7201,
										name: "DUKORE",
										generalAssembly_id: 1256,
									},
									{
										id: 7202,
										name: "GACUBA",
										generalAssembly_id: 1256,
									},
									{
										id: 7203,
										name: "KABUGA",
										generalAssembly_id: 1256,
									},
									{
										id: 7204,
										name: "KITAGABWA",
										generalAssembly_id: 1256,
									},
									{
										id: 7205,
										name: "KIVUMU",
										generalAssembly_id: 1256,
									},
									{
										id: 7206,
										name: "MBUGANGALI",
										generalAssembly_id: 1256,
									},
									{
										id: 7207,
										name: "MUDUHA",
										generalAssembly_id: 1256,
									},
									{
										id: 7208,
										name: "MUHATO",
										generalAssembly_id: 1256,
									},
									{
										id: 7209,
										name: "MURAKAZANEZA",
										generalAssembly_id: 1256,
									},
									{
										id: 7210,
										name: "NYABAGOBE",
										generalAssembly_id: 1256,
									},
									{
										id: 7211,
										name: "NYAKABUNGO",
										generalAssembly_id: 1256,
									},
									{
										id: 7212,
										name: "RUBAVU",
										generalAssembly_id: 1256,
									},
									{
										id: 10230,
										name: "Gisenyi  Court of Appeal ",
										generalAssembly_id: 1256,
									},
									{
										id: 11763,
										name: "Gisenyi  Gacaca court of sector ",
										generalAssembly_id: 1256,
									},
								],
							},
						],
					},
					{
						id: 20305,
						name: "Kanama",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030507,
								name: "Yungwe",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030506,
								name: "Rusongati",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030505,
								name: "Nkomane",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030504,
								name: "Musabike",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030503,
								name: "Mahoko",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030502,
								name: "Karambo",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030501,
								name: "Kamuhoza",
								sector_id: 20305,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1257,
								name: "Kanama ",
								sector_id: 20305,
								courtOfCells: [
									{
										id: 7213,
										name: "MAHOKO",
										generalAssembly_id: 1257,
									},
									{
										id: 7214,
										name: "NYAGASOZI",
										generalAssembly_id: 1257,
									},
									{
										id: 7215,
										name: "NYAMUGARI",
										generalAssembly_id: 1257,
									},
									{
										id: 7216,
										name: "NYANSHUNDURA",
										generalAssembly_id: 1257,
									},
									{
										id: 7217,
										name: "RUKORO",
										generalAssembly_id: 1257,
									},
									{
										id: 7218,
										name: "RWANKOMO",
										generalAssembly_id: 1257,
									},
									{
										id: 10231,
										name: "Kanama  Court of Appeal ",
										generalAssembly_id: 1257,
									},
									{
										id: 11764,
										name: "Kanama  Gacaca court of sector ",
										generalAssembly_id: 1257,
									},
								],
							},
							{
								id: 1258,
								name: "Karambo ",
								sector_id: 20305,
								courtOfCells: [
									{
										id: 7219,
										name: "BWIKURURE",
										generalAssembly_id: 1258,
									},
									{
										id: 7220,
										name: "KABINGO",
										generalAssembly_id: 1258,
									},
									{
										id: 7221,
										name: "KAGANO",
										generalAssembly_id: 1258,
									},
									{
										id: 7222,
										name: "MARIBA",
										generalAssembly_id: 1258,
									},
									{
										id: 7223,
										name: "MASHYOZA",
										generalAssembly_id: 1258,
									},
									{
										id: 7224,
										name: "MURAMBI",
										generalAssembly_id: 1258,
									},
									{
										id: 7225,
										name: "MUVEMBWA",
										generalAssembly_id: 1258,
									},
									{
										id: 7226,
										name: "NYABITUNDA",
										generalAssembly_id: 1258,
									},
									{
										id: 7227,
										name: "NYAKIBANDE",
										generalAssembly_id: 1258,
									},
									{
										id: 7228,
										name: "RUGEGE",
										generalAssembly_id: 1258,
									},
									{
										id: 7229,
										name: "RUSEKERA",
										generalAssembly_id: 1258,
									},
									{
										id: 7230,
										name: "RUSONGATI",
										generalAssembly_id: 1258,
									},
									{
										id: 7231,
										name: "YUNGWE",
										generalAssembly_id: 1258,
									},
									{
										id: 10232,
										name: "Karambo  Court of Appeal ",
										generalAssembly_id: 1258,
									},
									{
										id: 11765,
										name: "Karambo  Gacaca court of sector ",
										generalAssembly_id: 1258,
									},
								],
							},
						],
					},
					{
						id: 20306,
						name: "Kanzenze",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030606,
								name: "Nyaruteme",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030605,
								name: "Nyamirango",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030604,
								name: "Nyamikongi",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030603,
								name: "Muramba",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030602,
								name: "Kirerema",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030601,
								name: "Kanyirabigogo",
								sector_id: 20306,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1259,
								name: "Kanzenze ",
								sector_id: 20306,
								courtOfCells: [
									{
										id: 7232,
										name: "CYIVUGIZA",
										generalAssembly_id: 1259,
									},
									{
										id: 7233,
										name: "KABERE",
										generalAssembly_id: 1259,
									},
									{
										id: 7234,
										name: "KANYIRABIGOGO",
										generalAssembly_id: 1259,
									},
									{
										id: 7235,
										name: "RUSHASHO",
										generalAssembly_id: 1259,
									},
									{
										id: 7236,
										name: "RWAMIKUNGU",
										generalAssembly_id: 1259,
									},
									{
										id: 7237,
										name: "KIREREMA",
										generalAssembly_id: 1259,
									},
									{
										id: 10233,
										name: "Kanzenze  Court of Appeal ",
										generalAssembly_id: 1259,
									},
									{
										id: 11766,
										name: "Kanzenze  Gacaca court of sector ",
										generalAssembly_id: 1259,
									},
								],
							},
							{
								id: 1260,
								name: "Nyamirango ",
								sector_id: 20306,
								courtOfCells: [
									{
										id: 7238,
										name: "TUBINDI",
										generalAssembly_id: 1260,
									},
									{
										id: 7239,
										name: "GASIZI",
										generalAssembly_id: 1260,
									},
									{
										id: 7240,
										name: "KANYA",
										generalAssembly_id: 1260,
									},
									{
										id: 7241,
										name: "MARERU",
										generalAssembly_id: 1260,
									},
									{
										id: 7242,
										name: "MURAMBA",
										generalAssembly_id: 1260,
									},
									{
										id: 7243,
										name: "RUBARA",
										generalAssembly_id: 1260,
									},
									{
										id: 7244,
										name: "BISESERO",
										generalAssembly_id: 1260,
									},
									{
										id: 10234,
										name: "Nyamirango  Court of Appeal ",
										generalAssembly_id: 1260,
									},
									{
										id: 11767,
										name: "Nyamirango  Gacaca court of sector ",
										generalAssembly_id: 1260,
									},
								],
							},
						],
					},
					{
						id: 20307,
						name: "Mudende",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030707,
								name: "Rwanyakayaga",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030706,
								name: "Rungu",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030705,
								name: "Ndoranyi",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030704,
								name: "Mirindi",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030703,
								name: "Micinyiro",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030702,
								name: "Kanyundo",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030701,
								name: "Bihungwe",
								sector_id: 20307,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1261,
								name: "Mudende ",
								sector_id: 20307,
								courtOfCells: [
									{
										id: 7245,
										name: "GAHANIKA",
										generalAssembly_id: 1261,
									},
									{
										id: 7246,
										name: "KARANDARYI",
										generalAssembly_id: 1261,
									},
									{
										id: 7247,
										name: "KINYANGWE",
										generalAssembly_id: 1261,
									},
									{
										id: 7248,
										name: "NYAMIRAMA",
										generalAssembly_id: 1261,
									},
									{
										id: 7249,
										name: "RUKELI",
										generalAssembly_id: 1261,
									},
									{
										id: 7250,
										name: "RWANGARA",
										generalAssembly_id: 1261,
									},
									{
										id: 10235,
										name: "Mudende  Court of Appeal ",
										generalAssembly_id: 1261,
									},
									{
										id: 11768,
										name: "Mudende  Gacaca court of sector ",
										generalAssembly_id: 1261,
									},
								],
							},
							{
								id: 1262,
								name: "Mugongo ",
								sector_id: 20307,
								courtOfCells: [
									{
										id: 7251,
										name: "BIHUNGWE",
										generalAssembly_id: 1262,
									},
									{
										id: 7252,
										name: "BUNYOVE",
										generalAssembly_id: 1262,
									},
									{
										id: 7253,
										name: "CYUNYU",
										generalAssembly_id: 1262,
									},
									{
										id: 7254,
										name: "GASIZA",
										generalAssembly_id: 1262,
									},
									{
										id: 7255,
										name: "KANYUNDO",
										generalAssembly_id: 1262,
									},
									{
										id: 7256,
										name: "MUGONGO",
										generalAssembly_id: 1262,
									},
									{
										id: 7257,
										name: "MICINYIRO",
										generalAssembly_id: 1262,
									},
									{
										id: 10236,
										name: "Mugongo  Court of Appeal ",
										generalAssembly_id: 1262,
									},
									{
										id: 11769,
										name: "Mugongo  Gacaca court of sector ",
										generalAssembly_id: 1262,
									},
								],
							},
							{
								id: 1263,
								name: "Nyaruteme ",
								sector_id: 20307,
								courtOfCells: [
									{
										id: 7258,
										name: "BIHE",
										generalAssembly_id: 1263,
									},
									{
										id: 7259,
										name: "GITEGA",
										generalAssembly_id: 1263,
									},
									{
										id: 7260,
										name: "NDORANYI",
										generalAssembly_id: 1263,
									},
									{
										id: 7261,
										name: "RUNGU",
										generalAssembly_id: 1263,
									},
									{
										id: 10237,
										name: "Nyaruteme  Court of Appeal ",
										generalAssembly_id: 1263,
									},
									{
										id: 11770,
										name: "Nyaruteme  Gacaca court of sector ",
										generalAssembly_id: 1263,
									},
								],
							},
							{
								id: 1264,
								name: "Tamira ",
								sector_id: 20307,
								courtOfCells: [
									{
										id: 7262,
										name: "KABUNONI",
										generalAssembly_id: 1264,
									},
									{
										id: 7263,
										name: "KANYAMIZINGA",
										generalAssembly_id: 1264,
									},
									{
										id: 7264,
										name: "KIRYOHA",
										generalAssembly_id: 1264,
									},
									{
										id: 7265,
										name: "MUYANGE",
										generalAssembly_id: 1264,
									},
									{
										id: 7266,
										name: "MIRINDI",
										generalAssembly_id: 1264,
									},
									{
										id: 7267,
										name: "RWANYAKAYAGA",
										generalAssembly_id: 1264,
									},
									{
										id: 7268,
										name: "TAMIRA",
										generalAssembly_id: 1264,
									},
									{
										id: 10238,
										name: "Tamira  Court of Appeal ",
										generalAssembly_id: 1264,
									},
									{
										id: 11771,
										name: "Tamira  Gacaca court of sector ",
										generalAssembly_id: 1264,
									},
								],
							},
						],
					},
					{
						id: 20308,
						name: "Nyakiriba",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030804,
								name: "Nyarushyamba",
								sector_id: 20308,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030803,
								name: "Kanyefurwe",
								sector_id: 20308,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030802,
								name: "Gikombe",
								sector_id: 20308,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030801,
								name: "Bisizi",
								sector_id: 20308,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1265,
								name: "Bisizi ",
								sector_id: 20308,
								courtOfCells: [
									{
										id: 7269,
										name: "KABUHINI",
										generalAssembly_id: 1265,
									},
									{
										id: 7270,
										name: "KAKIGORO",
										generalAssembly_id: 1265,
									},
									{
										id: 7271,
										name: "KIBUYE",
										generalAssembly_id: 1265,
									},
									{
										id: 7272,
										name: "KINGOMA",
										generalAssembly_id: 1265,
									},
									{
										id: 7273,
										name: "MWUMBA",
										generalAssembly_id: 1265,
									},
									{
										id: 7274,
										name: "RUNABA",
										generalAssembly_id: 1265,
									},
									{
										id: 10239,
										name: "Bisizi  Court of Appeal ",
										generalAssembly_id: 1265,
									},
									{
										id: 11772,
										name: "Bisizi  Gacaca court of sector ",
										generalAssembly_id: 1265,
									},
								],
							},
							{
								id: 1266,
								name: "Nyarushyamba ",
								sector_id: 20308,
								courtOfCells: [
									{
										id: 7275,
										name: "GASHUNGURU",
										generalAssembly_id: 1266,
									},
									{
										id: 7276,
										name: "KIVUMU",
										generalAssembly_id: 1266,
									},
									{
										id: 7277,
										name: "MUTURA",
										generalAssembly_id: 1266,
									},
									{
										id: 7278,
										name: "RUHANGIRO",
										generalAssembly_id: 1266,
									},
									{
										id: 7279,
										name: "RUVUZANANGA",
										generalAssembly_id: 1266,
									},
									{
										id: 10240,
										name: "Nyarushyamba  Court of Appeal ",
										generalAssembly_id: 1266,
									},
									{
										id: 11773,
										name: "Nyarushyamba  Gacaca court of sector ",
										generalAssembly_id: 1266,
									},
								],
							},
							{
								id: 1267,
								name: "Kayove ",
								sector_id: 20308,
								courtOfCells: [
									{
										id: 7280,
										name: "GAHIRIKIRO",
										generalAssembly_id: 1267,
									},
									{
										id: 7281,
										name: "GASAVE",
										generalAssembly_id: 1267,
									},
									{
										id: 7282,
										name: "GIKOMBE",
										generalAssembly_id: 1267,
									},
									{
										id: 7283,
										name: "KANYEFURWE",
										generalAssembly_id: 1267,
									},
									{
										id: 7284,
										name: "NYAKIRIBA",
										generalAssembly_id: 1267,
									},
									{
										id: 7285,
										name: "NYENYERI",
										generalAssembly_id: 1267,
									},
									{
										id: 10241,
										name: "Kayove  Court of Appeal ",
										generalAssembly_id: 1267,
									},
									{
										id: 11774,
										name: "Kayove  Gacaca court of sector ",
										generalAssembly_id: 1267,
									},
								],
							},
						],
					},
					{
						id: 20309,
						name: "Nyamyumba",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2030906,
								name: "Rubona",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030905,
								name: "Munanira",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030904,
								name: "Kiraga",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030903,
								name: "Kinigi",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030902,
								name: "Busoro",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2030901,
								name: "Burushya",
								sector_id: 20309,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1268,
								name: "Kinigi ",
								sector_id: 20309,
								courtOfCells: [
									{
										id: 7286,
										name: "BUREVU",
										generalAssembly_id: 1268,
									},
									{
										id: 7287,
										name: "GATYAZO",
										generalAssembly_id: 1268,
									},
									{
										id: 7288,
										name: "KARAMBI",
										generalAssembly_id: 1268,
									},
									{
										id: 7289,
										name: "NYABISUSA",
										generalAssembly_id: 1268,
									},
									{
										id: 7290,
										name: "PFUNDA",
										generalAssembly_id: 1268,
									},
									{
										id: 10242,
										name: "Kinigi  Court of Appeal ",
										generalAssembly_id: 1268,
									},
									{
										id: 11775,
										name: "Kinigi  Gacaca court of sector ",
										generalAssembly_id: 1268,
									},
								],
							},
							{
								id: 1269,
								name: "Kiraga ",
								sector_id: 20309,
								courtOfCells: [
									{
										id: 7291,
										name: "BUHOGO",
										generalAssembly_id: 1269,
									},
									{
										id: 7292,
										name: "KABERAMO",
										generalAssembly_id: 1269,
									},
									{
										id: 7293,
										name: "KIGUFI",
										generalAssembly_id: 1269,
									},
									{
										id: 7294,
										name: "MIHINGO",
										generalAssembly_id: 1269,
									},
									{
										id: 7295,
										name: "MUTEMBE",
										generalAssembly_id: 1269,
									},
									{
										id: 7296,
										name: "NTERETI",
										generalAssembly_id: 1269,
									},
									{
										id: 7297,
										name: "RAMBO",
										generalAssembly_id: 1269,
									},
									{
										id: 10243,
										name: "Kiraga  Court of Appeal ",
										generalAssembly_id: 1269,
									},
									{
										id: 11776,
										name: "Kiraga  Gacaca court of sector ",
										generalAssembly_id: 1269,
									},
								],
							},
							{
								id: 1270,
								name: "Munanira ",
								sector_id: 20309,
								courtOfCells: [
									{
										id: 7298,
										name: "BWITEREKE",
										generalAssembly_id: 1270,
									},
									{
										id: 7299,
										name: "CYEYA",
										generalAssembly_id: 1270,
									},
									{
										id: 7300,
										name: "KABAKORA",
										generalAssembly_id: 1270,
									},
									{
										id: 7301,
										name: "KAMUGONGO",
										generalAssembly_id: 1270,
									},
									{
										id: 7302,
										name: "RUCANTEGE",
										generalAssembly_id: 1270,
									},
									{
										id: 7303,
										name: "RUHONDO",
										generalAssembly_id: 1270,
									},
									{
										id: 10244,
										name: "Munanira  Court of Appeal ",
										generalAssembly_id: 1270,
									},
									{
										id: 11777,
										name: "Munanira  Gacaca court of sector ",
										generalAssembly_id: 1270,
									},
								],
							},
							{
								id: 1271,
								name: "Busoro ",
								sector_id: 20309,
								courtOfCells: [
									{
										id: 7304,
										name: "BUGOMA",
										generalAssembly_id: 1271,
									},
									{
										id: 7305,
										name: "BUHANGA",
										generalAssembly_id: 1271,
									},
									{
										id: 7306,
										name: "BUSHAGI",
										generalAssembly_id: 1271,
									},
									{
										id: 7307,
										name: "BUVANO",
										generalAssembly_id: 1271,
									},
									{
										id: 7308,
										name: "KABUSHONGO",
										generalAssembly_id: 1271,
									},
									{
										id: 7309,
										name: "KIGURI",
										generalAssembly_id: 1271,
									},
									{
										id: 10245,
										name: "Busoro  Court of Appeal ",
										generalAssembly_id: 1271,
									},
									{
										id: 11778,
										name: "Busoro  Gacaca court of sector ",
										generalAssembly_id: 1271,
									},
								],
							},
							{
								id: 1272,
								name: "Rubona ",
								sector_id: 20309,
								courtOfCells: [
									{
										id: 7310,
										name: "BUGASHA",
										generalAssembly_id: 1272,
									},
									{
										id: 7311,
										name: "BUHARARA",
										generalAssembly_id: 1272,
									},
									{
										id: 7312,
										name: "BUNYAGO",
										generalAssembly_id: 1272,
									},
									{
										id: 7313,
										name: "BURIMA",
										generalAssembly_id: 1272,
									},
									{
										id: 7314,
										name: "RUREMBO",
										generalAssembly_id: 1272,
									},
									{
										id: 7315,
										name: "BUSHAGARA",
										generalAssembly_id: 1272,
									},
									{
										id: 10246,
										name: "Rubona  Court of Appeal ",
										generalAssembly_id: 1272,
									},
									{
										id: 11779,
										name: "Rubona  Gacaca court of sector ",
										generalAssembly_id: 1272,
									},
								],
							},
						],
					},
					{
						id: 20310,
						name: "Nyundo",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2031007,
								name: "Terimbere",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031006,
								name: "Nyundo",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031005,
								name: "Mukondo",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031004,
								name: "Kigarama",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031003,
								name: "Kavomo",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031002,
								name: "Gatovu",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031001,
								name: "Bahimba",
								sector_id: 20310,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1273,
								name: "Mukondo ",
								sector_id: 20310,
								courtOfCells: [
									{
										id: 7316,
										name: "BUROHA",
										generalAssembly_id: 1273,
									},
									{
										id: 7317,
										name: "BYINIRO",
										generalAssembly_id: 1273,
									},
									{
										id: 7318,
										name: "CYUNGERI",
										generalAssembly_id: 1273,
									},
									{
										id: 7319,
										name: "KANYAMISUKU",
										generalAssembly_id: 1273,
									},
									{
										id: 7320,
										name: "NKORA",
										generalAssembly_id: 1273,
									},
									{
										id: 7321,
										name: "TANDA",
										generalAssembly_id: 1273,
									},
									{
										id: 10247,
										name: "Mukondo  Court of Appeal ",
										generalAssembly_id: 1273,
									},
									{
										id: 11780,
										name: "Mukondo  Gacaca court of sector ",
										generalAssembly_id: 1273,
									},
								],
							},
							{
								id: 1274,
								name: "Nyundo ",
								sector_id: 20310,
								courtOfCells: [
									{
										id: 7322,
										name: "KEYA",
										generalAssembly_id: 1274,
									},
									{
										id: 7323,
										name: "GASENYI",
										generalAssembly_id: 1274,
									},
									{
										id: 7324,
										name: "HANIKA",
										generalAssembly_id: 1274,
									},
									{
										id: 7325,
										name: "HUYE",
										generalAssembly_id: 1274,
									},
									{
										id: 7326,
										name: "KANYAMATEMBE",
										generalAssembly_id: 1274,
									},
									{
										id: 7327,
										name: "KILIBATA",
										generalAssembly_id: 1274,
									},
									{
										id: 7328,
										name: "NYAKAGEZI",
										generalAssembly_id: 1274,
									},
									{
										id: 7329,
										name: "RAMBURA",
										generalAssembly_id: 1274,
									},
									{
										id: 7330,
										name: "RUMBATI",
										generalAssembly_id: 1274,
									},
									{
										id: 7331,
										name: "RUNANDE",
										generalAssembly_id: 1274,
									},
									{
										id: 7332,
										name: "TERIMBERE",
										generalAssembly_id: 1274,
									},
									{
										id: 10248,
										name: "Nyundo  Court of Appeal ",
										generalAssembly_id: 1274,
									},
									{
										id: 11781,
										name: "Nyundo  Gacaca court of sector ",
										generalAssembly_id: 1274,
									},
								],
							},
							{
								id: 1275,
								name: "Rugomero ",
								sector_id: 20310,
								courtOfCells: [
									{
										id: 7333,
										name: "BUDAHA",
										generalAssembly_id: 1275,
									},
									{
										id: 7334,
										name: "BURAMBO",
										generalAssembly_id: 1275,
									},
									{
										id: 7335,
										name: "GATOVU",
										generalAssembly_id: 1275,
									},
									{
										id: 7336,
										name: "GITWA",
										generalAssembly_id: 1275,
									},
									{
										id: 7337,
										name: "KANYAHENE",
										generalAssembly_id: 1275,
									},
									{
										id: 7338,
										name: "KITARIMWA",
										generalAssembly_id: 1275,
									},
									{
										id: 7339,
										name: "KAVOMO",
										generalAssembly_id: 1275,
									},
									{
										id: 7340,
										name: "KAVUMU",
										generalAssembly_id: 1275,
									},
									{
										id: 7341,
										name: "KINIHIRA",
										generalAssembly_id: 1275,
									},
									{
										id: 10249,
										name: "Rugomero  Court of Appeal ",
										generalAssembly_id: 1275,
									},
									{
										id: 11782,
										name: "Rugomero  Gacaca court of sector ",
										generalAssembly_id: 1275,
									},
								],
							},
							{
								id: 1276,
								name: "Kigarama ",
								sector_id: 20310,
								courtOfCells: [
									{
										id: 7342,
										name: "BAHINDA",
										generalAssembly_id: 1276,
									},
									{
										id: 7343,
										name: "KAGERA",
										generalAssembly_id: 1276,
									},
									{
										id: 7344,
										name: "KANYAMAGASHU",
										generalAssembly_id: 1276,
									},
									{
										id: 7345,
										name: "MWARI",
										generalAssembly_id: 1276,
									},
									{
										id: 7346,
										name: "NGEGE",
										generalAssembly_id: 1276,
									},
									{
										id: 7347,
										name: "RUNEKA",
										generalAssembly_id: 1276,
									},
									{
										id: 7348,
										name: "RUREMBO",
										generalAssembly_id: 1276,
									},
									{
										id: 10250,
										name: "Kigarama  Court of Appeal ",
										generalAssembly_id: 1276,
									},
									{
										id: 11783,
										name: "Kigarama  Gacaca court of sector ",
										generalAssembly_id: 1276,
									},
								],
							},
						],
					},
					{
						id: 20311,
						name: "Rubavu",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2031107,
								name: "Rukoko",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031106,
								name: "Murara",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031105,
								name: "Murambi",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031104,
								name: "Gikombe",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031103,
								name: "Byahi",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031102,
								name: "Burinda",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031101,
								name: "Buhaza",
								sector_id: 20311,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1277,
								name: "Byahi ",
								sector_id: 20311,
								courtOfCells: [
									{
										id: 7349,
										name: "BISIZI_RUTAGARA",
										generalAssembly_id: 1277,
									},
									{
										id: 7350,
										name: "BUHAZA",
										generalAssembly_id: 1277,
									},
									{
										id: 7351,
										name: "BYAHI_MIKINGO",
										generalAssembly_id: 1277,
									},
									{
										id: 7352,
										name: "MURAMBI",
										generalAssembly_id: 1277,
									},
									{
										id: 7353,
										name: "NGUGO",
										generalAssembly_id: 1277,
									},
									{
										id: 7354,
										name: "RUKOKO",
										generalAssembly_id: 1277,
									},
									{
										id: 7355,
										name: "GATAGARA",
										generalAssembly_id: 1277,
									},
									{
										id: 10251,
										name: "Byahi  Court of Appeal ",
										generalAssembly_id: 1277,
									},
									{
										id: 11784,
										name: "Byahi  Gacaca court of sector ",
										generalAssembly_id: 1277,
									},
								],
							},
							{
								id: 1278,
								name: "Bulinda ",
								sector_id: 20311,
								courtOfCells: [
									{
										id: 7356,
										name: "KASENGORE",
										generalAssembly_id: 1278,
									},
									{
										id: 7357,
										name: "BUBAJI",
										generalAssembly_id: 1278,
									},
									{
										id: 7358,
										name: "GASENYI",
										generalAssembly_id: 1278,
									},
									{
										id: 7359,
										name: "NYABANTU",
										generalAssembly_id: 1278,
									},
									{
										id: 7360,
										name: "NYAMWINSHI",
										generalAssembly_id: 1278,
									},
									{
										id: 7361,
										name: "RWEZAMENYO",
										generalAssembly_id: 1278,
									},
									{
										id: 10252,
										name: "Bulinda  Court of Appeal ",
										generalAssembly_id: 1278,
									},
									{
										id: 11785,
										name: "Bulinda  Gacaca court of sector ",
										generalAssembly_id: 1278,
									},
								],
							},
							{
								id: 1279,
								name: "Murambi ",
								sector_id: 20311,
								courtOfCells: [
									{
										id: 7362,
										name: "BUSHENGO",
										generalAssembly_id: 1279,
									},
									{
										id: 7363,
										name: "BUZUTA",
										generalAssembly_id: 1279,
									},
									{
										id: 7364,
										name: "BWIRU",
										generalAssembly_id: 1279,
									},
									{
										id: 7365,
										name: "RUVUMBU",
										generalAssembly_id: 1279,
									},
									{
										id: 7366,
										name: "RWANGARA",
										generalAssembly_id: 1279,
									},
									{
										id: 7367,
										name: "KABERE",
										generalAssembly_id: 1279,
									},
									{
										id: 10253,
										name: "Murambi  Court of Appeal ",
										generalAssembly_id: 1279,
									},
									{
										id: 11786,
										name: "Murambi  Gacaca court of sector ",
										generalAssembly_id: 1279,
									},
								],
							},
							{
								id: 1280,
								name: "Murara ",
								sector_id: 20311,
								courtOfCells: [
									{
										id: 7368,
										name: "BAMBIRO",
										generalAssembly_id: 1280,
									},
									{
										id: 7369,
										name: "BUGESERA",
										generalAssembly_id: 1280,
									},
									{
										id: 7370,
										name: "BUSHENGO",
										generalAssembly_id: 1280,
									},
									{
										id: 7371,
										name: "GAFUKU",
										generalAssembly_id: 1280,
									},
									{
										id: 7372,
										name: "GASAYO",
										generalAssembly_id: 1280,
									},
									{
										id: 7373,
										name: "KABERE",
										generalAssembly_id: 1280,
									},
									{
										id: 7374,
										name: "KIROJI",
										generalAssembly_id: 1280,
									},
									{
										id: 7375,
										name: "NYABUTWA",
										generalAssembly_id: 1280,
									},
									{
										id: 10254,
										name: "Murara  Court of Appeal ",
										generalAssembly_id: 1280,
									},
									{
										id: 11787,
										name: "Murara  Gacaca court of sector ",
										generalAssembly_id: 1280,
									},
								],
							},
						],
					},
					{
						id: 20312,
						name: "Rugerero",
						district_id: 203,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2031207,
								name: "Rwaza",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031206,
								name: "Rushubi",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031205,
								name: "Rugerero",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031204,
								name: "Muhira",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031203,
								name: "Kabilizi",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031202,
								name: "Gisa",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2031201,
								name: "Basa",
								sector_id: 20312,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1281,
								name: "Gisa ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7376,
										name: "CYANIKA",
										generalAssembly_id: 1281,
									},
									{
										id: 7377,
										name: "GATEKO",
										generalAssembly_id: 1281,
									},
									{
										id: 7378,
										name: "GISA",
										generalAssembly_id: 1281,
									},
									{
										id: 7379,
										name: "KANIGA",
										generalAssembly_id: 1281,
									},
									{
										id: 7380,
										name: "KIROJI",
										generalAssembly_id: 1281,
									},
									{
										id: 7381,
										name: "NDOBOGO",
										generalAssembly_id: 1281,
									},
									{
										id: 7382,
										name: "RUCYAMO",
										generalAssembly_id: 1281,
									},
									{
										id: 7383,
										name: "RUSONGATI",
										generalAssembly_id: 1281,
									},
									{
										id: 7384,
										name: "RWAZA",
										generalAssembly_id: 1281,
									},
									{
										id: 10255,
										name: "Gisa  Court of Appeal ",
										generalAssembly_id: 1281,
									},
									{
										id: 11788,
										name: "Gisa  Gacaca court of sector ",
										generalAssembly_id: 1281,
									},
								],
							},
							{
								id: 1282,
								name: "Basa  ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7385,
										name: "BURANGA",
										generalAssembly_id: 1282,
									},
									{
										id: 7386,
										name: "GAHINGA",
										generalAssembly_id: 1282,
									},
									{
										id: 7387,
										name: "KABEZA_KANYUKIRO",
										generalAssembly_id: 1282,
									},
									{
										id: 7388,
										name: "NYARUHENGERI",
										generalAssembly_id: 1282,
									},
									{
										id: 7389,
										name: "TAGAZA",
										generalAssembly_id: 1282,
									},
									{
										id: 10256,
										name: "Basa   Court of Appeal ",
										generalAssembly_id: 1282,
									},
									{
										id: 11789,
										name: "Basa   Gacaca court of sector ",
										generalAssembly_id: 1282,
									},
								],
							},
							{
								id: 1283,
								name: "Muhira ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7390,
										name: "GATEBE",
										generalAssembly_id: 1283,
									},
									{
										id: 7391,
										name: "GITEBE",
										generalAssembly_id: 1283,
									},
									{
										id: 7392,
										name: "KASONGA",
										generalAssembly_id: 1283,
									},
									{
										id: 7393,
										name: "KIZI",
										generalAssembly_id: 1283,
									},
									{
										id: 7394,
										name: "RUSAMAZA",
										generalAssembly_id: 1283,
									},
									{
										id: 10257,
										name: "Muhira  Court of Appeal ",
										generalAssembly_id: 1283,
									},
									{
										id: 11790,
										name: "Muhira  Gacaca court of sector ",
										generalAssembly_id: 1283,
									},
								],
							},
							{
								id: 1284,
								name: "Rugerero ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7395,
										name: "GAKORO",
										generalAssembly_id: 1284,
									},
									{
										id: 7396,
										name: "KABARORA",
										generalAssembly_id: 1284,
									},
									{
										id: 7397,
										name: "KIBAYA",
										generalAssembly_id: 1284,
									},
									{
										id: 7398,
										name: "NYANTOMVU",
										generalAssembly_id: 1284,
									},
									{
										id: 7399,
										name: "RUGERERO",
										generalAssembly_id: 1284,
									},
									{
										id: 7400,
										name: "RUKINGO",
										generalAssembly_id: 1284,
									},
									{
										id: 10258,
										name: "Rugerero  Court of Appeal ",
										generalAssembly_id: 1284,
									},
									{
										id: 11791,
										name: "Rugerero  Gacaca court of sector ",
										generalAssembly_id: 1284,
									},
								],
							},
							{
								id: 1285,
								name: "Rushubi ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7401,
										name: "BUSHEKE",
										generalAssembly_id: 1285,
									},
									{
										id: 7402,
										name: "BUTANGI",
										generalAssembly_id: 1285,
									},
									{
										id: 7403,
										name: "KIMINA",
										generalAssembly_id: 1285,
									},
									{
										id: 10259,
										name: "Rushubi  Court of Appeal ",
										generalAssembly_id: 1285,
									},
									{
										id: 11792,
										name: "Rushubi  Gacaca court of sector ",
										generalAssembly_id: 1285,
									},
								],
							},
							{
								id: 1286,
								name: "Kabirizi ",
								sector_id: 20312,
								courtOfCells: [
									{
										id: 7404,
										name: "GAKORO",
										generalAssembly_id: 1286,
									},
									{
										id: 7405,
										name: "NKAMA",
										generalAssembly_id: 1286,
									},
									{
										id: 7406,
										name: "RUHANGIRO",
										generalAssembly_id: 1286,
									},
									{
										id: 7407,
										name: "RUKUKUMBO",
										generalAssembly_id: 1286,
									},
									{
										id: 10260,
										name: "Kabirizi  Court of Appeal ",
										generalAssembly_id: 1286,
									},
									{
										id: 11793,
										name: "Kabirizi  Gacaca court of sector ",
										generalAssembly_id: 1286,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 206,
				name: "Rusizi",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20601,
						name: "Bugarama",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060103,
								name: "Ryankana",
								sector_id: 20601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060102,
								name: "Pera",
								sector_id: 20601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060101,
								name: "Nyange",
								sector_id: 20601,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1392,
								name: "Bugarama  ",
								sector_id: 20601,
								courtOfCells: [
									{
										id: 8088,
										name: "KAMABUYE",
										generalAssembly_id: 1392,
									},
									{
										id: 8089,
										name: "MISUFI",
										generalAssembly_id: 1392,
									},
									{
										id: 8090,
										name: "MUKO",
										generalAssembly_id: 1392,
									},
									{
										id: 8091,
										name: "MWARO",
										generalAssembly_id: 1392,
									},
									{
										id: 8092,
										name: "NYANGE",
										generalAssembly_id: 1392,
									},
									{
										id: 8093,
										name: "PERA",
										generalAssembly_id: 1392,
									},
									{
										id: 8094,
										name: "RUSAYO",
										generalAssembly_id: 1392,
									},
									{
										id: 10366,
										name: "Bugarama   Court of Appeal ",
										generalAssembly_id: 1392,
									},
									{
										id: 11899,
										name: "Bugarama   Gacaca court of sector ",
										generalAssembly_id: 1392,
									},
								],
							},
							{
								id: 1393,
								name: "Kibangira ",
								sector_id: 20601,
								courtOfCells: [
									{
										id: 8095,
										name: "BUHANGA",
										generalAssembly_id: 1393,
									},
									{
										id: 8096,
										name: "GIHIGANO",
										generalAssembly_id: 1393,
									},
									{
										id: 8097,
										name: "GOMBANIRO",
										generalAssembly_id: 1393,
									},
									{
										id: 8098,
										name: "KIBANGIRA",
										generalAssembly_id: 1393,
									},
									{
										id: 8099,
										name: "MUBOMBO",
										generalAssembly_id: 1393,
									},
									{
										id: 8100,
										name: "RYANKANA",
										generalAssembly_id: 1393,
									},
									{
										id: 10367,
										name: "Kibangira  Court of Appeal ",
										generalAssembly_id: 1393,
									},
									{
										id: 11900,
										name: "Kibangira  Gacaca court of sector ",
										generalAssembly_id: 1393,
									},
								],
							},
						],
					},
					{
						id: 20602,
						name: "Butare",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060204,
								name: "Rwambogo",
								sector_id: 20602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060203,
								name: "Nyamihanda",
								sector_id: 20602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060202,
								name: "Gatereri",
								sector_id: 20602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060201,
								name: "Butanda",
								sector_id: 20602,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1394,
								name: "Butare ",
								sector_id: 20602,
								courtOfCells: [
									{
										id: 8101,
										name: "BUTANDA",
										generalAssembly_id: 1394,
									},
									{
										id: 8102,
										name: "GATERERI",
										generalAssembly_id: 1394,
									},
									{
										id: 8103,
										name: "GISOVU",
										generalAssembly_id: 1394,
									},
									{
										id: 8104,
										name: "KAREBA",
										generalAssembly_id: 1394,
									},
									{
										id: 8105,
										name: "MURAMBA",
										generalAssembly_id: 1394,
									},
									{
										id: 8106,
										name: "NYABITIMBO",
										generalAssembly_id: 1394,
									},
									{
										id: 8107,
										name: "RUGERA",
										generalAssembly_id: 1394,
									},
									{
										id: 10368,
										name: "Butare  Court of Appeal ",
										generalAssembly_id: 1394,
									},
									{
										id: 11901,
										name: "Butare  Gacaca court of sector ",
										generalAssembly_id: 1394,
									},
								],
							},
							{
								id: 1395,
								name: "Gasumo  ",
								sector_id: 20602,
								courtOfCells: [
									{
										id: 8108,
										name: "BAYUMBA",
										generalAssembly_id: 1395,
									},
									{
										id: 8109,
										name: "BUYE",
										generalAssembly_id: 1395,
									},
									{
										id: 8110,
										name: "KIBENGA",
										generalAssembly_id: 1395,
									},
									{
										id: 8111,
										name: "NYAMIHANDA",
										generalAssembly_id: 1395,
									},
									{
										id: 8112,
										name: "RWAMBOGO",
										generalAssembly_id: 1395,
									},
									{
										id: 10369,
										name: "Gasumo   Court of Appeal ",
										generalAssembly_id: 1395,
									},
									{
										id: 11902,
										name: "Gasumo   Gacaca court of sector ",
										generalAssembly_id: 1395,
									},
								],
							},
						],
					},
					{
						id: 20603,
						name: "Bweyeye",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060305,
								name: "Rasano",
								sector_id: 20603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060304,
								name: "Nyamuzi",
								sector_id: 20603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060303,
								name: "Murwa",
								sector_id: 20603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060302,
								name: "Kiyabo",
								sector_id: 20603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060301,
								name: "Gikungu",
								sector_id: 20603,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1396,
								name: "Bweyeye  ",
								sector_id: 20603,
								courtOfCells: [
									{
										id: 8113,
										name: "GIKUNGU",
										generalAssembly_id: 1396,
									},
									{
										id: 8114,
										name: "KIYABO",
										generalAssembly_id: 1396,
									},
									{
										id: 8115,
										name: "MURWA",
										generalAssembly_id: 1396,
									},
									{
										id: 8116,
										name: "NYAMUZI",
										generalAssembly_id: 1396,
									},
									{
										id: 8117,
										name: "RASANO",
										generalAssembly_id: 1396,
									},
									{
										id: 10370,
										name: "Bweyeye   Court of Appeal ",
										generalAssembly_id: 1396,
									},
									{
										id: 11903,
										name: "Bweyeye   Gacaca court of sector ",
										generalAssembly_id: 1396,
									},
								],
							},
						],
					},
					{
						id: 20604,
						name: "Gashonga",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060408,
								name: "Rusayo",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060407,
								name: "Muti",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060406,
								name: "Karemereye",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060405,
								name: "Kamurehe",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060404,
								name: "Kacyuma",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060403,
								name: "Kabakobwa",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060402,
								name: "Buhokoro",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060401,
								name: "Birembo",
								sector_id: 20604,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1397,
								name: "Gashonga ",
								sector_id: 20604,
								courtOfCells: [
									{
										id: 8118,
										name: "KABAKOBWA",
										generalAssembly_id: 1397,
									},
									{
										id: 8119,
										name: "KABUYE",
										generalAssembly_id: 1397,
									},
									{
										id: 8120,
										name: "KANYIRAMUKINA",
										generalAssembly_id: 1397,
									},
									{
										id: 8121,
										name: "KIREMEREYE",
										generalAssembly_id: 1397,
									},
									{
										id: 8122,
										name: "NYAGATERO",
										generalAssembly_id: 1397,
									},
									{
										id: 8123,
										name: "NYAMUTARAMA",
										generalAssembly_id: 1397,
									},
									{
										id: 8124,
										name: "RUSAYO",
										generalAssembly_id: 1397,
									},
									{
										id: 10371,
										name: "Gashonga  Court of Appeal ",
										generalAssembly_id: 1397,
									},
									{
										id: 11904,
										name: "Gashonga  Gacaca court of sector ",
										generalAssembly_id: 1397,
									},
								],
							},
							{
								id: 1398,
								name: "Gisagara  ",
								sector_id: 20604,
								courtOfCells: [
									{
										id: 8125,
										name: "BUHOKORO",
										generalAssembly_id: 1398,
									},
									{
										id: 8126,
										name: "BUSEKERA",
										generalAssembly_id: 1398,
									},
									{
										id: 8127,
										name: "GAKOMBE",
										generalAssembly_id: 1398,
									},
									{
										id: 8128,
										name: "MAREBE",
										generalAssembly_id: 1398,
									},
									{
										id: 8129,
										name: "MUTI",
										generalAssembly_id: 1398,
									},
									{
										id: 8130,
										name: "RYAGACECE",
										generalAssembly_id: 1398,
									},
									{
										id: 10372,
										name: "Gisagara   Court of Appeal ",
										generalAssembly_id: 1398,
									},
									{
										id: 11905,
										name: "Gisagara   Gacaca court of sector ",
										generalAssembly_id: 1398,
									},
								],
							},
							{
								id: 1399,
								name: "Mibirizi  ",
								sector_id: 20604,
								courtOfCells: [
									{
										id: 8131,
										name: "KABAHA",
										generalAssembly_id: 1399,
									},
									{
										id: 8132,
										name: "KABAHINDA",
										generalAssembly_id: 1399,
									},
									{
										id: 8133,
										name: "KAGIKONGORO",
										generalAssembly_id: 1399,
									},
									{
										id: 8134,
										name: "KAREMEREYE",
										generalAssembly_id: 1399,
									},
									{
										id: 10373,
										name: "Mibirizi   Court of Appeal ",
										generalAssembly_id: 1399,
									},
									{
										id: 11906,
										name: "Mibirizi   Gacaca court of sector ",
										generalAssembly_id: 1399,
									},
								],
							},
							{
								id: 1400,
								name: "Murehe  ",
								sector_id: 20604,
								courtOfCells: [
									{
										id: 8135,
										name: "GACYAMO",
										generalAssembly_id: 1400,
									},
									{
										id: 8136,
										name: "KAMONYI",
										generalAssembly_id: 1400,
									},
									{
										id: 8137,
										name: "KAMUREHE",
										generalAssembly_id: 1400,
									},
									{
										id: 8138,
										name: "NYABIHANGA",
										generalAssembly_id: 1400,
									},
									{
										id: 8139,
										name: "NYAGITABIRE",
										generalAssembly_id: 1400,
									},
									{
										id: 8140,
										name: "SHARA",
										generalAssembly_id: 1400,
									},
									{
										id: 10374,
										name: "Murehe   Court of Appeal ",
										generalAssembly_id: 1400,
									},
									{
										id: 11907,
										name: "Murehe   Gacaca court of sector ",
										generalAssembly_id: 1400,
									},
								],
							},
							{
								id: 1401,
								name: "Rukunguri  ",
								sector_id: 20604,
								courtOfCells: [
									{
										id: 8141,
										name: "BIREMBO",
										generalAssembly_id: 1401,
									},
									{
										id: 8142,
										name: "GASHARU",
										generalAssembly_id: 1401,
									},
									{
										id: 8143,
										name: "KACYIYAMWA",
										generalAssembly_id: 1401,
									},
									{
										id: 8144,
										name: "KACYUMA",
										generalAssembly_id: 1401,
									},
									{
										id: 8145,
										name: "KAGOMERO",
										generalAssembly_id: 1401,
									},
									{
										id: 8146,
										name: "MUSUMBA",
										generalAssembly_id: 1401,
									},
									{
										id: 8147,
										name: "RYAGAHINDIRO",
										generalAssembly_id: 1401,
									},
									{
										id: 10375,
										name: "Rukunguri   Court of Appeal ",
										generalAssembly_id: 1401,
									},
									{
										id: 11908,
										name: "Rukunguri   Gacaca court of sector ",
										generalAssembly_id: 1401,
									},
								],
							},
						],
					},
					{
						id: 20605,
						name: "Giheke",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060508,
								name: "Turambi",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060507,
								name: "Rwega",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060506,
								name: "Ntura",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060505,
								name: "Kigenge",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060504,
								name: "Kamashangi",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060503,
								name: "Giheke",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060502,
								name: "Gakomeye",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060501,
								name: "Cyendajuru",
								sector_id: 20605,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1402,
								name: "Giheke  ",
								sector_id: 20605,
								courtOfCells: [
									{
										id: 8148,
										name: "CYENDAJURU",
										generalAssembly_id: 1402,
									},
									{
										id: 8149,
										name: "GIHEKE",
										generalAssembly_id: 1402,
									},
									{
										id: 8150,
										name: "KARORA",
										generalAssembly_id: 1402,
									},
									{
										id: 8151,
										name: "MURAMA",
										generalAssembly_id: 1402,
									},
									{
										id: 8152,
										name: "MURAMBI",
										generalAssembly_id: 1402,
									},
									{
										id: 8153,
										name: "MUSORO",
										generalAssembly_id: 1402,
									},
									{
										id: 8154,
										name: "RWUMVANGOMA",
										generalAssembly_id: 1402,
									},
									{
										id: 10376,
										name: "Giheke   Court of Appeal ",
										generalAssembly_id: 1402,
									},
									{
										id: 11909,
										name: "Giheke   Gacaca court of sector ",
										generalAssembly_id: 1402,
									},
								],
							},
							{
								id: 1403,
								name: "Isha  ",
								sector_id: 20605,
								courtOfCells: [
									{
										id: 8155,
										name: "GAHURUBUKA",
										generalAssembly_id: 1403,
									},
									{
										id: 8156,
										name: "GITWA",
										generalAssembly_id: 1403,
									},
									{
										id: 8157,
										name: "KAMASHANGI",
										generalAssembly_id: 1403,
									},
									{
										id: 8158,
										name: "KIGENGE",
										generalAssembly_id: 1403,
									},
									{
										id: 10377,
										name: "Isha   Court of Appeal ",
										generalAssembly_id: 1403,
									},
									{
										id: 11910,
										name: "Isha   Gacaca court of sector ",
										generalAssembly_id: 1403,
									},
								],
							},
							{
								id: 1404,
								name: "Munyove  ",
								sector_id: 20605,
								courtOfCells: [
									{
										id: 8159,
										name: "IMPALA",
										generalAssembly_id: 1404,
									},
									{
										id: 8160,
										name: "KAMUHOZA",
										generalAssembly_id: 1404,
									},
									{
										id: 8161,
										name: "KANOGA",
										generalAssembly_id: 1404,
									},
									{
										id: 8162,
										name: "MUNYOVE",
										generalAssembly_id: 1404,
									},
									{
										id: 8163,
										name: "RWEGA",
										generalAssembly_id: 1404,
									},
									{
										id: 8164,
										name: "RWINKWAVU",
										generalAssembly_id: 1404,
									},
									{
										id: 8165,
										name: "TURAMBI",
										generalAssembly_id: 1404,
									},
									{
										id: 10378,
										name: "Munyove   Court of Appeal ",
										generalAssembly_id: 1404,
									},
									{
										id: 11911,
										name: "Munyove   Gacaca court of sector ",
										generalAssembly_id: 1404,
									},
								],
							},
							{
								id: 1405,
								name: "Ntura  ",
								sector_id: 20605,
								courtOfCells: [
									{
										id: 8166,
										name: "BUBANGA",
										generalAssembly_id: 1405,
									},
									{
										id: 8167,
										name: "BUZI",
										generalAssembly_id: 1405,
									},
									{
										id: 8168,
										name: "GAKOMEYE",
										generalAssembly_id: 1405,
									},
									{
										id: 8169,
										name: "KABUGA",
										generalAssembly_id: 1405,
									},
									{
										id: 8170,
										name: "KAGARAMA",
										generalAssembly_id: 1405,
									},
									{
										id: 8171,
										name: "KARAMBI",
										generalAssembly_id: 1405,
									},
									{
										id: 8172,
										name: "KAVUYE",
										generalAssembly_id: 1405,
									},
									{
										id: 8173,
										name: "KIGENGE",
										generalAssembly_id: 1405,
									},
									{
										id: 8174,
										name: "NTURA",
										generalAssembly_id: 1405,
									},
									{
										id: 8175,
										name: "RUVUMBU",
										generalAssembly_id: 1405,
									},
									{
										id: 10379,
										name: "Ntura   Court of Appeal ",
										generalAssembly_id: 1405,
									},
									{
										id: 11912,
										name: "Ntura   Gacaca court of sector ",
										generalAssembly_id: 1405,
									},
								],
							},
						],
					},
					{
						id: 20606,
						name: "Gihundwe",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060606,
								name: "Shagasha",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060605,
								name: "Kamatita",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060604,
								name: "Kagara",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060603,
								name: "Gihaya",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060602,
								name: "Gatsiro",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060601,
								name: "Burunga",
								sector_id: 20606,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1406,
								name: "Gihundwe_A ",
								sector_id: 20606,
								courtOfCells: [
									{
										id: 8176,
										name: "BURUNGA",
										generalAssembly_id: 1406,
									},
									{
										id: 8177,
										name: "GIHUNDWE",
										generalAssembly_id: 1406,
									},
									{
										id: 8178,
										name: "KANOMBE",
										generalAssembly_id: 1406,
									},
									{
										id: 8179,
										name: "KARORABOSE",
										generalAssembly_id: 1406,
									},
									{
										id: 8180,
										name: "KAVUMU",
										generalAssembly_id: 1406,
									},
									{
										id: 8181,
										name: "RUBENGA",
										generalAssembly_id: 1406,
									},
									{
										id: 8182,
										name: "RUKOHWA",
										generalAssembly_id: 1406,
									},
									{
										id: 10380,
										name: "Gihundwe_A  Court of Appeal ",
										generalAssembly_id: 1406,
									},
									{
										id: 11913,
										name: "Gihundwe_A  Gacaca court of sector ",
										generalAssembly_id: 1406,
									},
								],
							},
							{
								id: 1407,
								name: "Muhari  ",
								sector_id: 20606,
								courtOfCells: [
									{
										id: 8183,
										name: "CYINZOVU",
										generalAssembly_id: 1407,
									},
									{
										id: 8184,
										name: "GAHWAZI",
										generalAssembly_id: 1407,
									},
									{
										id: 8185,
										name: "GIHAYA",
										generalAssembly_id: 1407,
									},
									{
										id: 8186,
										name: "KAGARA",
										generalAssembly_id: 1407,
									},
									{
										id: 8187,
										name: "KAMANYENGA",
										generalAssembly_id: 1407,
									},
									{
										id: 8188,
										name: "KAMATITA",
										generalAssembly_id: 1407,
									},
									{
										id: 8189,
										name: "MUNYANA",
										generalAssembly_id: 1407,
									},
									{
										id: 8190,
										name: "NGOMA",
										generalAssembly_id: 1407,
									},
									{
										id: 8191,
										name: "BAHEMBA",
										generalAssembly_id: 1407,
									},
									{
										id: 10381,
										name: "Muhari   Court of Appeal ",
										generalAssembly_id: 1407,
									},
									{
										id: 11914,
										name: "Muhari   Gacaca court of sector ",
										generalAssembly_id: 1407,
									},
								],
							},
							{
								id: 1408,
								name: "Rwahi  ",
								sector_id: 20606,
								courtOfCells: [
									{
										id: 8192,
										name: "GATSIRO",
										generalAssembly_id: 1408,
									},
									{
										id: 8193,
										name: "GIKOMBE",
										generalAssembly_id: 1408,
									},
									{
										id: 8194,
										name: "RWAHI",
										generalAssembly_id: 1408,
									},
									{
										id: 10382,
										name: "Rwahi   Court of Appeal ",
										generalAssembly_id: 1408,
									},
									{
										id: 11915,
										name: "Rwahi   Gacaca court of sector ",
										generalAssembly_id: 1408,
									},
								],
							},
							{
								id: 1409,
								name: "Shagasha ",
								sector_id: 20606,
								courtOfCells: [
									{
										id: 8195,
										name: "BISANGANIRA",
										generalAssembly_id: 1409,
									},
									{
										id: 8196,
										name: "GASHARU",
										generalAssembly_id: 1409,
									},
									{
										id: 8197,
										name: "KANOGA",
										generalAssembly_id: 1409,
									},
									{
										id: 8198,
										name: "KARAMBO",
										generalAssembly_id: 1409,
									},
									{
										id: 8199,
										name: "MPONGORA",
										generalAssembly_id: 1409,
									},
									{
										id: 8200,
										name: "NYAGATARE",
										generalAssembly_id: 1409,
									},
									{
										id: 8201,
										name: "SHAGASHA",
										generalAssembly_id: 1409,
									},
									{
										id: 10383,
										name: "Shagasha  Court of Appeal ",
										generalAssembly_id: 1409,
									},
									{
										id: 11916,
										name: "Shagasha  Gacaca court of sector ",
										generalAssembly_id: 1409,
									},
								],
							},
						],
					},
					{
						id: 20607,
						name: "Gikundamvura",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060703,
								name: "Nyamigina",
								sector_id: 20607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060702,
								name: "Mpinga",
								sector_id: 20607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060701,
								name: "Kizura",
								sector_id: 20607,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1410,
								name: "Gikundamvura  ",
								sector_id: 20607,
								courtOfCells: [
									{
										id: 8202,
										name: "KIZURA",
										generalAssembly_id: 1410,
									},
									{
										id: 8203,
										name: "MPINGA",
										generalAssembly_id: 1410,
									},
									{
										id: 8204,
										name: "MUBERA",
										generalAssembly_id: 1410,
									},
									{
										id: 8205,
										name: "MWEGERA",
										generalAssembly_id: 1410,
									},
									{
										id: 8206,
										name: "NYABIHANGA",
										generalAssembly_id: 1410,
									},
									{
										id: 8207,
										name: "NYAMIGINA",
										generalAssembly_id: 1410,
									},
									{
										id: 10384,
										name: "Gikundamvura   Court of Appeal ",
										generalAssembly_id: 1410,
									},
									{
										id: 11917,
										name: "Gikundamvura   Gacaca court of sector ",
										generalAssembly_id: 1410,
									},
								],
							},
						],
					},
					{
						id: 20608,
						name: "Gitambi",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060804,
								name: "Mashesha",
								sector_id: 20608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060803,
								name: "Hangabashi",
								sector_id: 20608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060802,
								name: "Gahungeri",
								sector_id: 20608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060801,
								name: "Cyingwa",
								sector_id: 20608,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1411,
								name: "Gitambi  ",
								sector_id: 20608,
								courtOfCells: [
									{
										id: 8208,
										name: "BUSASAMANA",
										generalAssembly_id: 1411,
									},
									{
										id: 8209,
										name: "KARAMA",
										generalAssembly_id: 1411,
									},
									{
										id: 8210,
										name: "MASHESHA",
										generalAssembly_id: 1411,
									},
									{
										id: 8211,
										name: "RUVURUGA",
										generalAssembly_id: 1411,
									},
									{
										id: 8212,
										name: "RWIHENE",
										generalAssembly_id: 1411,
									},
									{
										id: 10385,
										name: "Gitambi   Court of Appeal ",
										generalAssembly_id: 1411,
									},
									{
										id: 11918,
										name: "Gitambi   Gacaca court of sector ",
										generalAssembly_id: 1411,
									},
								],
							},
							{
								id: 1412,
								name: "Kaboza  ",
								sector_id: 20608,
								courtOfCells: [
									{
										id: 8213,
										name: "CYINGWA",
										generalAssembly_id: 1412,
									},
									{
										id: 8214,
										name: "KABUCUKU",
										generalAssembly_id: 1412,
									},
									{
										id: 8215,
										name: "KABUGARAMA",
										generalAssembly_id: 1412,
									},
									{
										id: 8216,
										name: "MUGENGE",
										generalAssembly_id: 1412,
									},
									{
										id: 10386,
										name: "Kaboza   Court of Appeal ",
										generalAssembly_id: 1412,
									},
									{
										id: 11919,
										name: "Kaboza   Gacaca court of sector ",
										generalAssembly_id: 1412,
									},
								],
							},
							{
								id: 1413,
								name: "Kimbagiro  ",
								sector_id: 20608,
								courtOfCells: [
									{
										id: 8217,
										name: "GAHUNGERI",
										generalAssembly_id: 1413,
									},
									{
										id: 8218,
										name: "KANINDA",
										generalAssembly_id: 1413,
									},
									{
										id: 8219,
										name: "KIGARAMA",
										generalAssembly_id: 1413,
									},
									{
										id: 8220,
										name: "NJAMBWE",
										generalAssembly_id: 1413,
									},
									{
										id: 8221,
										name: "NYAKIBINGO",
										generalAssembly_id: 1413,
									},
									{
										id: 8222,
										name: "NYAMAGANDA",
										generalAssembly_id: 1413,
									},
									{
										id: 8223,
										name: "NYANTABA",
										generalAssembly_id: 1413,
									},
									{
										id: 10387,
										name: "Kimbagiro   Court of Appeal ",
										generalAssembly_id: 1413,
									},
									{
										id: 11920,
										name: "Kimbagiro   Gacaca court of sector ",
										generalAssembly_id: 1413,
									},
								],
							},
							{
								id: 1414,
								name: "Nyabintare  ",
								sector_id: 20608,
								courtOfCells: [
									{
										id: 8224,
										name: "HANGABASHI",
										generalAssembly_id: 1414,
									},
									{
										id: 8225,
										name: "KAZINDA",
										generalAssembly_id: 1414,
									},
									{
										id: 8226,
										name: "NYAMPANGA",
										generalAssembly_id: 1414,
									},
									{
										id: 8227,
										name: "SHOTA",
										generalAssembly_id: 1414,
									},
									{
										id: 8228,
										name: "TAMBI",
										generalAssembly_id: 1414,
									},
									{
										id: 10388,
										name: "Nyabintare   Court of Appeal ",
										generalAssembly_id: 1414,
									},
									{
										id: 11921,
										name: "Nyabintare   Gacaca court of sector ",
										generalAssembly_id: 1414,
									},
								],
							},
						],
					},
					{
						id: 20609,
						name: "Kamembe",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2060905,
								name: "Ruganda",
								sector_id: 20609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060904,
								name: "Kamurera",
								sector_id: 20609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060903,
								name: "Kamashangi",
								sector_id: 20609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060902,
								name: "Gihundwe",
								sector_id: 20609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2060901,
								name: "Cyangugu",
								sector_id: 20609,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1415,
								name: "Gihundwe_B ",
								sector_id: 20609,
								courtOfCells: [
									{
										id: 8229,
										name: "KABUGI",
										generalAssembly_id: 1415,
									},
									{
										id: 8230,
										name: "KAMURERA",
										generalAssembly_id: 1415,
									},
									{
										id: 8231,
										name: "MURANGI",
										generalAssembly_id: 1415,
									},
									{
										id: 8232,
										name: "MURINDI",
										generalAssembly_id: 1415,
									},
									{
										id: 8233,
										name: "RUGANDA",
										generalAssembly_id: 1415,
									},
									{
										id: 10389,
										name: "Gihundwe_B  Court of Appeal ",
										generalAssembly_id: 1415,
									},
									{
										id: 11922,
										name: "Gihundwe_B  Gacaca court of sector ",
										generalAssembly_id: 1415,
									},
								],
							},
							{
								id: 1416,
								name: "Kamembe  ",
								sector_id: 20609,
								courtOfCells: [
									{
										id: 8234,
										name: "AMAHORO",
										generalAssembly_id: 1416,
									},
									{
										id: 8235,
										name: "BATERO",
										generalAssembly_id: 1416,
									},
									{
										id: 8236,
										name: "GATOVU",
										generalAssembly_id: 1416,
									},
									{
										id: 8237,
										name: "GIHUNDWE",
										generalAssembly_id: 1416,
									},
									{
										id: 8238,
										name: "KANNYOGO",
										generalAssembly_id: 1416,
									},
									{
										id: 8239,
										name: "KARANGIRO",
										generalAssembly_id: 1416,
									},
									{
										id: 8240,
										name: "UMUGANDA",
										generalAssembly_id: 1416,
									},
									{
										id: 10390,
										name: "Kamembe   Court of Appeal ",
										generalAssembly_id: 1416,
									},
									{
										id: 11923,
										name: "Kamembe   Gacaca court of sector ",
										generalAssembly_id: 1416,
									},
								],
							},
						],
					},
					{
						id: 20610,
						name: "Muganza",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061003,
								name: "Shara",
								sector_id: 20610,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061002,
								name: "Gakoni",
								sector_id: 20610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061001,
								name: "Cyarukara",
								sector_id: 20610,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1417,
								name: "Muganza  ",
								sector_id: 20610,
								courtOfCells: [
									{
										id: 8241,
										name: "BANNYAHE",
										generalAssembly_id: 1417,
									},
									{
										id: 8242,
										name: "CYARUKARA",
										generalAssembly_id: 1417,
									},
									{
										id: 8243,
										name: "GAKONI",
										generalAssembly_id: 1417,
									},
									{
										id: 8244,
										name: "MUGANZA",
										generalAssembly_id: 1417,
									},
									{
										id: 8245,
										name: "MURIRA",
										generalAssembly_id: 1417,
									},
									{
										id: 8246,
										name: "NYABISHUNJU",
										generalAssembly_id: 1417,
									},
									{
										id: 8247,
										name: "RUGARAGARA",
										generalAssembly_id: 1417,
									},
									{
										id: 8248,
										name: "SHARA",
										generalAssembly_id: 1417,
									},
									{
										id: 10391,
										name: "Muganza   Court of Appeal ",
										generalAssembly_id: 1417,
									},
									{
										id: 11924,
										name: "Muganza   Gacaca court of sector ",
										generalAssembly_id: 1417,
									},
								],
							},
						],
					},
					{
						id: 20611,
						name: "Mururu",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061107,
								name: "Tara",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061106,
								name: "Miko",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061105,
								name: "Karambi",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061104,
								name: "Kagarama",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061103,
								name: "Kabasigirira",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061102,
								name: "Kabahinda",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061101,
								name: "Gahinga",
								sector_id: 20611,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1418,
								name: "Cyete  ",
								sector_id: 20611,
								courtOfCells: [
									{
										id: 8249,
										name: "GIKUNGWE",
										generalAssembly_id: 1418,
									},
									{
										id: 8250,
										name: "GITWA",
										generalAssembly_id: 1418,
									},
									{
										id: 8251,
										name: "KAGARAMA",
										generalAssembly_id: 1418,
									},
									{
										id: 8252,
										name: "KAMATEME",
										generalAssembly_id: 1418,
									},
									{
										id: 10392,
										name: "Cyete   Court of Appeal ",
										generalAssembly_id: 1418,
									},
									{
										id: 11925,
										name: "Cyete   Gacaca court of sector ",
										generalAssembly_id: 1418,
									},
								],
							},
							{
								id: 1419,
								name: "Mururu  ",
								sector_id: 20611,
								courtOfCells: [
									{
										id: 8253,
										name: "BUREMERA",
										generalAssembly_id: 1419,
									},
									{
										id: 8254,
										name: "CYIRABYO",
										generalAssembly_id: 1419,
									},
									{
										id: 8255,
										name: "GAHINGA",
										generalAssembly_id: 1419,
									},
									{
										id: 8256,
										name: "KARANJWA",
										generalAssembly_id: 1419,
									},
									{
										id: 8257,
										name: "MUTARA",
										generalAssembly_id: 1419,
									},
									{
										id: 10393,
										name: "Mururu   Court of Appeal ",
										generalAssembly_id: 1419,
									},
									{
										id: 11926,
										name: "Mururu   Gacaca court of sector ",
										generalAssembly_id: 1419,
									},
								],
							},
							{
								id: 1420,
								name: "Mutongo  ",
								sector_id: 20611,
								courtOfCells: [
									{
										id: 8258,
										name: "GATAMBAMO",
										generalAssembly_id: 1420,
									},
									{
										id: 8259,
										name: "KAMAREBE",
										generalAssembly_id: 1420,
									},
									{
										id: 8260,
										name: "KAMUTONGO",
										generalAssembly_id: 1420,
									},
									{
										id: 8261,
										name: "RWIMBOGO",
										generalAssembly_id: 1420,
									},
									{
										id: 8262,
										name: "TARA",
										generalAssembly_id: 1420,
									},
									{
										id: 10394,
										name: "Mutongo   Court of Appeal ",
										generalAssembly_id: 1420,
									},
									{
										id: 11927,
										name: "Mutongo   Gacaca court of sector ",
										generalAssembly_id: 1420,
									},
								],
							},
							{
								id: 1421,
								name: "Nyakanyinya  ",
								sector_id: 20611,
								courtOfCells: [
									{
										id: 8263,
										name: "BITONGO",
										generalAssembly_id: 1421,
									},
									{
										id: 8264,
										name: "BUTAZIGURWA",
										generalAssembly_id: 1421,
									},
									{
										id: 8265,
										name: "KABAHESHI",
										generalAssembly_id: 1421,
									},
									{
										id: 8266,
										name: "KABASIGIRIRA",
										generalAssembly_id: 1421,
									},
									{
										id: 8267,
										name: "MIKO",
										generalAssembly_id: 1421,
									},
									{
										id: 8268,
										name: "RUHIMBI",
										generalAssembly_id: 1421,
									},
									{
										id: 10395,
										name: "Nyakanyinya   Court of Appeal ",
										generalAssembly_id: 1421,
									},
									{
										id: 11928,
										name: "Nyakanyinya   Gacaca court of sector ",
										generalAssembly_id: 1421,
									},
								],
							},
							{
								id: 1422,
								name: "Winteko  ",
								sector_id: 20611,
								courtOfCells: [
									{
										id: 8269,
										name: "BUGAYI",
										generalAssembly_id: 1422,
									},
									{
										id: 8270,
										name: "GAHINGA",
										generalAssembly_id: 1422,
									},
									{
										id: 8271,
										name: "KABAHINDA",
										generalAssembly_id: 1422,
									},
									{
										id: 8272,
										name: "KAGARAMA",
										generalAssembly_id: 1422,
									},
									{
										id: 8273,
										name: "KARAMBI",
										generalAssembly_id: 1422,
									},
									{
										id: 8274,
										name: "KARAMBO",
										generalAssembly_id: 1422,
									},
									{
										id: 10396,
										name: "Winteko   Court of Appeal ",
										generalAssembly_id: 1422,
									},
									{
										id: 11929,
										name: "Winteko   Gacaca court of sector ",
										generalAssembly_id: 1422,
									},
								],
							},
						],
					},
					{
						id: 20612,
						name: "Nkanka",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061205,
								name: "Rugabano",
								sector_id: 20612,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061204,
								name: "Kinyaga",
								sector_id: 20612,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061203,
								name: "Kangazi",
								sector_id: 20612,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061202,
								name: "Kamanyenga",
								sector_id: 20612,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061201,
								name: "Gitwa",
								sector_id: 20612,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1423,
								name: "Cyibumba  ",
								sector_id: 20612,
								courtOfCells: [
									{
										id: 8275,
										name: "GASHARU",
										generalAssembly_id: 1423,
									},
									{
										id: 8276,
										name: "GITWA",
										generalAssembly_id: 1423,
									},
									{
										id: 8277,
										name: "KAZUNGU",
										generalAssembly_id: 1423,
									},
									{
										id: 8278,
										name: "KINYAGA",
										generalAssembly_id: 1423,
									},
									{
										id: 8279,
										name: "MARIBA",
										generalAssembly_id: 1423,
									},
									{
										id: 8280,
										name: "RUGABANO",
										generalAssembly_id: 1423,
									},
									{
										id: 8281,
										name: "RUGARAGARA",
										generalAssembly_id: 1423,
									},
									{
										id: 8282,
										name: "RUSEBEYA",
										generalAssembly_id: 1423,
									},
									{
										id: 10397,
										name: "Cyibumba   Court of Appeal ",
										generalAssembly_id: 1423,
									},
									{
										id: 11930,
										name: "Cyibumba   Gacaca court of sector ",
										generalAssembly_id: 1423,
									},
								],
							},
							{
								id: 1424,
								name: "Nkanka  ",
								sector_id: 20612,
								courtOfCells: [
									{
										id: 8283,
										name: "GITWA",
										generalAssembly_id: 1424,
									},
									{
										id: 8284,
										name: "KAMANYENGA",
										generalAssembly_id: 1424,
									},
									{
										id: 8285,
										name: "KARAMA",
										generalAssembly_id: 1424,
									},
									{
										id: 8286,
										name: "MURAMBA",
										generalAssembly_id: 1424,
									},
									{
										id: 8287,
										name: "MURAMBI",
										generalAssembly_id: 1424,
									},
									{
										id: 8288,
										name: "NYABIRANGA",
										generalAssembly_id: 1424,
									},
									{
										id: 8289,
										name: "RWEYA",
										generalAssembly_id: 1424,
									},
									{
										id: 10398,
										name: "Nkanka   Court of Appeal ",
										generalAssembly_id: 1424,
									},
									{
										id: 11931,
										name: "Nkanka   Gacaca court of sector ",
										generalAssembly_id: 1424,
									},
								],
							},
							{
								id: 1425,
								name: "Rusunyu  ",
								sector_id: 20612,
								courtOfCells: [
									{
										id: 8290,
										name: "KANGAZI",
										generalAssembly_id: 1425,
									},
									{
										id: 8291,
										name: "MURAMA",
										generalAssembly_id: 1425,
									},
									{
										id: 8292,
										name: "MUYANGE",
										generalAssembly_id: 1425,
									},
									{
										id: 10399,
										name: "Rusunyu   Court of Appeal ",
										generalAssembly_id: 1425,
									},
									{
										id: 11932,
										name: "Rusunyu   Gacaca court of sector ",
										generalAssembly_id: 1425,
									},
								],
							},
						],
					},
					{
						id: 20613,
						name: "Nkombo",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061305,
								name: "Rwenje",
								sector_id: 20613,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061304,
								name: "Kamagimbo",
								sector_id: 20613,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061303,
								name: "Ishywa",
								sector_id: 20613,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061302,
								name: "Bugarura",
								sector_id: 20613,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061301,
								name: "Bigoga",
								sector_id: 20613,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1426,
								name: "Bugumira ",
								sector_id: 20613,
								courtOfCells: [
									{
										id: 8293,
										name: "BUNYESHYA",
										generalAssembly_id: 1426,
									},
									{
										id: 8294,
										name: "ISHYWA",
										generalAssembly_id: 1426,
									},
									{
										id: 8295,
										name: "KAMAGIMBO",
										generalAssembly_id: 1426,
									},
									{
										id: 8296,
										name: "MUVA",
										generalAssembly_id: 1426,
									},
									{
										id: 8297,
										name: "RWENJE",
										generalAssembly_id: 1426,
									},
									{
										id: 8298,
										name: "SURO",
										generalAssembly_id: 1426,
									},
									{
										id: 10400,
										name: "Bugumira  Court of Appeal ",
										generalAssembly_id: 1426,
									},
									{
										id: 11933,
										name: "Bugumira  Gacaca court of sector ",
										generalAssembly_id: 1426,
									},
								],
							},
							{
								id: 1427,
								name: "Mparwe  ",
								sector_id: 20613,
								courtOfCells: [
									{
										id: 8299,
										name: "BIGOGA",
										generalAssembly_id: 1427,
									},
									{
										id: 8300,
										name: "BUGARURA",
										generalAssembly_id: 1427,
									},
									{
										id: 8301,
										name: "GASHENYI",
										generalAssembly_id: 1427,
									},
									{
										id: 8302,
										name: "GASHYA",
										generalAssembly_id: 1427,
									},
									{
										id: 8303,
										name: "KARORO",
										generalAssembly_id: 1427,
									},
									{
										id: 10401,
										name: "Mparwe   Court of Appeal ",
										generalAssembly_id: 1427,
									},
									{
										id: 11934,
										name: "Mparwe   Gacaca court of sector ",
										generalAssembly_id: 1427,
									},
								],
							},
						],
					},
					{
						id: 20614,
						name: "Nkungu",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061404,
								name: "Ryamuhirwa",
								sector_id: 20614,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061403,
								name: "Mataba",
								sector_id: 20614,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061402,
								name: "Kiziguro",
								sector_id: 20614,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061401,
								name: "Gatare",
								sector_id: 20614,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1428,
								name: "Kigurwe ",
								sector_id: 20614,
								courtOfCells: [
									{
										id: 8304,
										name: "GAKO",
										generalAssembly_id: 1428,
									},
									{
										id: 8305,
										name: "GATARANGE",
										generalAssembly_id: 1428,
									},
									{
										id: 8306,
										name: "KINANIRA",
										generalAssembly_id: 1428,
									},
									{
										id: 8307,
										name: "NYARUSHISHI",
										generalAssembly_id: 1428,
									},
									{
										id: 8308,
										name: "RYAMARAZA",
										generalAssembly_id: 1428,
									},
									{
										id: 8309,
										name: "RYAMUHIRWA",
										generalAssembly_id: 1428,
									},
									{
										id: 10402,
										name: "Kigurwe  Court of Appeal ",
										generalAssembly_id: 1428,
									},
									{
										id: 11935,
										name: "Kigurwe  Gacaca court of sector ",
										generalAssembly_id: 1428,
									},
								],
							},
							{
								id: 1429,
								name: "Matare ",
								sector_id: 20614,
								courtOfCells: [
									{
										id: 8310,
										name: "GAHATI",
										generalAssembly_id: 1429,
									},
									{
										id: 8311,
										name: "GITWA",
										generalAssembly_id: 1429,
									},
									{
										id: 8312,
										name: "KABACUZI",
										generalAssembly_id: 1429,
									},
									{
										id: 8313,
										name: "KIZIGURO",
										generalAssembly_id: 1429,
									},
									{
										id: 8314,
										name: "MARORA",
										generalAssembly_id: 1429,
									},
									{
										id: 8315,
										name: "RUKUKUMBO",
										generalAssembly_id: 1429,
									},
									{
										id: 10403,
										name: "Matare  Court of Appeal ",
										generalAssembly_id: 1429,
									},
									{
										id: 11936,
										name: "Matare  Gacaca court of sector ",
										generalAssembly_id: 1429,
									},
								],
							},
							{
								id: 1430,
								name: "Muhanga  ",
								sector_id: 20614,
								courtOfCells: [
									{
										id: 8316,
										name: "GATARE",
										generalAssembly_id: 1430,
									},
									{
										id: 8317,
										name: "GIKOMBE",
										generalAssembly_id: 1430,
									},
									{
										id: 8318,
										name: "KANZUKI_GITWA",
										generalAssembly_id: 1430,
									},
									{
										id: 8319,
										name: "KIBIJUMBA",
										generalAssembly_id: 1430,
									},
									{
										id: 8320,
										name: "KIGABIRO",
										generalAssembly_id: 1430,
									},
									{
										id: 10404,
										name: "Muhanga   Court of Appeal ",
										generalAssembly_id: 1430,
									},
									{
										id: 11937,
										name: "Muhanga   Gacaca court of sector ",
										generalAssembly_id: 1430,
									},
								],
							},
							{
								id: 1431,
								name: "Nkungu  ",
								sector_id: 20614,
								courtOfCells: [
									{
										id: 8321,
										name: "BAFATAMPUNDU",
										generalAssembly_id: 1431,
									},
									{
										id: 8322,
										name: "BINYUGWE",
										generalAssembly_id: 1431,
									},
									{
										id: 8323,
										name: "KANELI",
										generalAssembly_id: 1431,
									},
									{
										id: 8324,
										name: "MATABA",
										generalAssembly_id: 1431,
									},
									{
										id: 8325,
										name: "MWANGO",
										generalAssembly_id: 1431,
									},
									{
										id: 8326,
										name: "NKUNGU",
										generalAssembly_id: 1431,
									},
									{
										id: 10405,
										name: "Nkungu   Court of Appeal ",
										generalAssembly_id: 1431,
									},
									{
										id: 11938,
										name: "Nkungu   Gacaca court of sector ",
										generalAssembly_id: 1431,
									},
								],
							},
							{
								id: 1432,
								name: "Nyamubembe",
								sector_id: 20614,
								courtOfCells: [
									{
										id: 8327,
										name: "GASEBEYA",
										generalAssembly_id: 1432,
									},
									{
										id: 8328,
										name: "KANOGA",
										generalAssembly_id: 1432,
									},
									{
										id: 8329,
										name: "KARAMBI",
										generalAssembly_id: 1432,
									},
									{
										id: 8330,
										name: "NYAKAGOMA",
										generalAssembly_id: 1432,
									},
									{
										id: 8331,
										name: "RUSAYO",
										generalAssembly_id: 1432,
									},
									{
										id: 10406,
										name: "Nyamubembe Court of Appeal ",
										generalAssembly_id: 1432,
									},
									{
										id: 11939,
										name: "Nyamubembe Gacaca court of sector ",
										generalAssembly_id: 1432,
									},
								],
							},
						],
					},
					{
						id: 20615,
						name: "Nyakabuye",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061506,
								name: "Nyabintare",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061505,
								name: "Mashyuza",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061504,
								name: "Kiziho",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061503,
								name: "Kamanu",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061502,
								name: "Gaseke",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061501,
								name: "Gasebeya",
								sector_id: 20615,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1433,
								name: "Bunyereri   ",
								sector_id: 20615,
								courtOfCells: [
									{
										id: 8332,
										name: "KIZIHO",
										generalAssembly_id: 1433,
									},
									{
										id: 8333,
										name: "MAKOKO",
										generalAssembly_id: 1433,
									},
									{
										id: 8334,
										name: "MASANGO",
										generalAssembly_id: 1433,
									},
									{
										id: 8335,
										name: "NYABINTARE",
										generalAssembly_id: 1433,
									},
									{
										id: 8336,
										name: "NYAGATO",
										generalAssembly_id: 1433,
									},
									{
										id: 8337,
										name: "NYANTOMVU",
										generalAssembly_id: 1433,
									},
									{
										id: 10407,
										name: "Bunyereri    Court of Appeal ",
										generalAssembly_id: 1433,
									},
									{
										id: 11940,
										name: "Bunyereri    Gacaca court of sector ",
										generalAssembly_id: 1433,
									},
								],
							},
							{
								id: 1434,
								name: "Nyakabuye  ",
								sector_id: 20615,
								courtOfCells: [
									{
										id: 8338,
										name: "BITENDEZI",
										generalAssembly_id: 1434,
									},
									{
										id: 8339,
										name: "GATAMBAMO",
										generalAssembly_id: 1434,
									},
									{
										id: 8340,
										name: "GIKOMBE",
										generalAssembly_id: 1434,
									},
									{
										id: 8341,
										name: "RUBONA",
										generalAssembly_id: 1434,
									},
									{
										id: 8342,
										name: "KINUNGA",
										generalAssembly_id: 1434,
									},
									{
										id: 8343,
										name: "MURAMBI",
										generalAssembly_id: 1434,
									},
									{
										id: 10408,
										name: "Nyakabuye   Court of Appeal ",
										generalAssembly_id: 1434,
									},
									{
										id: 11941,
										name: "Nyakabuye   Gacaca court of sector ",
										generalAssembly_id: 1434,
									},
								],
							},
							{
								id: 1435,
								name: "Nyamaronko  ",
								sector_id: 20615,
								courtOfCells: [
									{
										id: 8344,
										name: "CYAMURA",
										generalAssembly_id: 1435,
									},
									{
										id: 8345,
										name: "GISHAGARA",
										generalAssembly_id: 1435,
									},
									{
										id: 8346,
										name: "KAMANU",
										generalAssembly_id: 1435,
									},
									{
										id: 8347,
										name: "MASHYUZA",
										generalAssembly_id: 1435,
									},
									{
										id: 8348,
										name: "RUGUTI",
										generalAssembly_id: 1435,
									},
									{
										id: 10409,
										name: "Nyamaronko   Court of Appeal ",
										generalAssembly_id: 1435,
									},
									{
										id: 11942,
										name: "Nyamaronko   Gacaca court of sector ",
										generalAssembly_id: 1435,
									},
								],
							},
						],
					},
					{
						id: 20616,
						name: "Nyakarenzo",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061607,
								name: "Rusambu",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061606,
								name: "Murambi",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061605,
								name: "Karangiro",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061604,
								name: "Kanoga",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061603,
								name: "Kabuye",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061602,
								name: "Kabagina",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061601,
								name: "Gatare",
								sector_id: 20616,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1436,
								name: "Cyato  ",
								sector_id: 20616,
								courtOfCells: [
									{
										id: 8349,
										name: "BITABA",
										generalAssembly_id: 1436,
									},
									{
										id: 8350,
										name: "GATARE",
										generalAssembly_id: 1436,
									},
									{
										id: 8351,
										name: "KABAGINA",
										generalAssembly_id: 1436,
									},
									{
										id: 8352,
										name: "KABUMBWE",
										generalAssembly_id: 1436,
									},
									{
										id: 8353,
										name: "KARAMBI",
										generalAssembly_id: 1436,
									},
									{
										id: 10410,
										name: "Cyato   Court of Appeal ",
										generalAssembly_id: 1436,
									},
									{
										id: 11943,
										name: "Cyato   Gacaca court of sector ",
										generalAssembly_id: 1436,
									},
								],
							},
							{
								id: 1437,
								name: "Nyakarenzo  ",
								sector_id: 20616,
								courtOfCells: [
									{
										id: 8354,
										name: "GATARAMO",
										generalAssembly_id: 1437,
									},
									{
										id: 8355,
										name: "GITUZA",
										generalAssembly_id: 1437,
									},
									{
										id: 8356,
										name: "KABAYEGO",
										generalAssembly_id: 1437,
									},
									{
										id: 8357,
										name: "KARAMBI",
										generalAssembly_id: 1437,
									},
									{
										id: 8358,
										name: "KARANGIRO",
										generalAssembly_id: 1437,
									},
									{
										id: 8359,
										name: "MUGONGO",
										generalAssembly_id: 1437,
									},
									{
										id: 8360,
										name: "RUSAMBU",
										generalAssembly_id: 1437,
									},
									{
										id: 10411,
										name: "Nyakarenzo   Court of Appeal ",
										generalAssembly_id: 1437,
									},
									{
										id: 11944,
										name: "Nyakarenzo   Gacaca court of sector ",
										generalAssembly_id: 1437,
									},
								],
							},
							{
								id: 1438,
								name: "Nyamagana  ",
								sector_id: 20616,
								courtOfCells: [
									{
										id: 8361,
										name: "KABACUZI",
										generalAssembly_id: 1438,
									},
									{
										id: 8362,
										name: "KABUYE",
										generalAssembly_id: 1438,
									},
									{
										id: 8363,
										name: "MUGERERO",
										generalAssembly_id: 1438,
									},
									{
										id: 8364,
										name: "NYUNGU",
										generalAssembly_id: 1438,
									},
									{
										id: 10412,
										name: "Nyamagana   Court of Appeal ",
										generalAssembly_id: 1438,
									},
									{
										id: 11945,
										name: "Nyamagana   Gacaca court of sector ",
										generalAssembly_id: 1438,
									},
								],
							},
							{
								id: 1439,
								name: "Runyanzovu ",
								sector_id: 20616,
								courtOfCells: [
									{
										id: 8365,
										name: "GISOVU",
										generalAssembly_id: 1439,
									},
									{
										id: 8366,
										name: "KANOGA",
										generalAssembly_id: 1439,
									},
									{
										id: 8367,
										name: "KANYOVU",
										generalAssembly_id: 1439,
									},
									{
										id: 8368,
										name: "KUMANA",
										generalAssembly_id: 1439,
									},
									{
										id: 8369,
										name: "MURAMBI",
										generalAssembly_id: 1439,
									},
									{
										id: 10413,
										name: "Runyanzovu  Court of Appeal ",
										generalAssembly_id: 1439,
									},
									{
										id: 11946,
										name: "Runyanzovu  Gacaca court of sector ",
										generalAssembly_id: 1439,
									},
								],
							},
						],
					},
					{
						id: 20617,
						name: "Nzahaha",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061706,
								name: "Rwinzuki",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061705,
								name: "Rebero",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061704,
								name: "Nyenji",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061703,
								name: "Murya",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061702,
								name: "Kigenge",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061701,
								name: "Butambamo",
								sector_id: 20617,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1440,
								name: "Butambamo  ",
								sector_id: 20617,
								courtOfCells: [
									{
										id: 8370,
										name: "KARUNYERERA",
										generalAssembly_id: 1440,
									},
									{
										id: 8371,
										name: "MUGURI",
										generalAssembly_id: 1440,
									},
									{
										id: 8372,
										name: "NGOMA",
										generalAssembly_id: 1440,
									},
									{
										id: 8373,
										name: "RYARUSARO",
										generalAssembly_id: 1440,
									},
									{
										id: 10414,
										name: "Butambamo   Court of Appeal ",
										generalAssembly_id: 1440,
									},
									{
										id: 11947,
										name: "Butambamo   Gacaca court of sector ",
										generalAssembly_id: 1440,
									},
								],
							},
							{
								id: 1441,
								name: "Kiranga  ",
								sector_id: 20617,
								courtOfCells: [
									{
										id: 8374,
										name: "GATARE",
										generalAssembly_id: 1441,
									},
									{
										id: 8375,
										name: "KIBEREZI",
										generalAssembly_id: 1441,
									},
									{
										id: 8376,
										name: "NYAGAHINGA",
										generalAssembly_id: 1441,
									},
									{
										id: 8377,
										name: "PERU",
										generalAssembly_id: 1441,
									},
									{
										id: 8378,
										name: "RWINZUKI",
										generalAssembly_id: 1441,
									},
									{
										id: 10415,
										name: "Kiranga   Court of Appeal ",
										generalAssembly_id: 1441,
									},
									{
										id: 11948,
										name: "Kiranga   Gacaca court of sector ",
										generalAssembly_id: 1441,
									},
								],
							},
							{
								id: 1442,
								name: "Nyenji  ",
								sector_id: 20617,
								courtOfCells: [
									{
										id: 8379,
										name: "GAKOMBE",
										generalAssembly_id: 1442,
									},
									{
										id: 8380,
										name: "GASHARU",
										generalAssembly_id: 1442,
									},
									{
										id: 8381,
										name: "RUGUNGA",
										generalAssembly_id: 1442,
									},
									{
										id: 10416,
										name: "Nyenji   Court of Appeal ",
										generalAssembly_id: 1442,
									},
									{
										id: 11949,
										name: "Nyenji   Gacaca court of sector ",
										generalAssembly_id: 1442,
									},
								],
							},
							{
								id: 1443,
								name: "Ruhoko ",
								sector_id: 20617,
								courtOfCells: [
									{
										id: 8382,
										name: "BUGANZA",
										generalAssembly_id: 1443,
									},
									{
										id: 8383,
										name: "GACURIRO",
										generalAssembly_id: 1443,
									},
									{
										id: 8384,
										name: "GISHEKE",
										generalAssembly_id: 1443,
									},
									{
										id: 8385,
										name: "MURYA",
										generalAssembly_id: 1443,
									},
									{
										id: 8386,
										name: "NYAGASOZI",
										generalAssembly_id: 1443,
									},
									{
										id: 8387,
										name: "RYAGASHYITSI",
										generalAssembly_id: 1443,
									},
									{
										id: 10417,
										name: "Ruhoko  Court of Appeal ",
										generalAssembly_id: 1443,
									},
									{
										id: 11950,
										name: "Ruhoko  Gacaca court of sector ",
										generalAssembly_id: 1443,
									},
								],
							},
							{
								id: 1444,
								name: "Nzahaha  ",
								sector_id: 20617,
								courtOfCells: [
									{
										id: 8388,
										name: "GIHUNGWE",
										generalAssembly_id: 1444,
									},
									{
										id: 8389,
										name: "GITI",
										generalAssembly_id: 1444,
									},
									{
										id: 8390,
										name: "KABUYANGE",
										generalAssembly_id: 1444,
									},
									{
										id: 8391,
										name: "KIGENGE",
										generalAssembly_id: 1444,
									},
									{
										id: 8392,
										name: "MPUSHI",
										generalAssembly_id: 1444,
									},
									{
										id: 8393,
										name: "REBERO",
										generalAssembly_id: 1444,
									},
									{
										id: 10418,
										name: "Nzahaha   Court of Appeal ",
										generalAssembly_id: 1444,
									},
									{
										id: 11951,
										name: "Nzahaha   Gacaca court of sector ",
										generalAssembly_id: 1444,
									},
								],
							},
						],
					},
					{
						id: 20618,
						name: "Rwimbogo",
						district_id: 206,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2061805,
								name: "Ruganda",
								sector_id: 20618,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061804,
								name: "Rubugu",
								sector_id: 20618,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061803,
								name: "Mushaka",
								sector_id: 20618,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061802,
								name: "Muhehwe",
								sector_id: 20618,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
							{
								id: 2061801,
								name: "Karenge",
								sector_id: 20618,
								createdAt: "2021-05-03T07:57:21.346Z",
								updatedAt: "2021-05-03T07:57:21.346Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1445,
								name: "Muhehwe ",
								sector_id: 20618,
								courtOfCells: [
									{
										id: 8394,
										name: "CYUNGURIRO",
										generalAssembly_id: 1445,
									},
									{
										id: 8395,
										name: "MUHEHWE",
										generalAssembly_id: 1445,
									},
									{
										id: 8396,
										name: "MURAMA",
										generalAssembly_id: 1445,
									},
									{
										id: 8397,
										name: "MUSUMBA",
										generalAssembly_id: 1445,
									},
									{
										id: 8398,
										name: "RUGANDA",
										generalAssembly_id: 1445,
									},
									{
										id: 8399,
										name: "RUNGUNGA",
										generalAssembly_id: 1445,
									},
									{
										id: 10419,
										name: "Muhehwe  Court of Appeal ",
										generalAssembly_id: 1445,
									},
									{
										id: 11952,
										name: "Muhehwe  Gacaca court of sector ",
										generalAssembly_id: 1445,
									},
								],
							},
							{
								id: 1446,
								name: "Ntenyi  ",
								sector_id: 20618,
								courtOfCells: [
									{
										id: 8400,
										name: "BATURA",
										generalAssembly_id: 1446,
									},
									{
										id: 8401,
										name: "GAKOMBE",
										generalAssembly_id: 1446,
									},
									{
										id: 8402,
										name: "NTENYI",
										generalAssembly_id: 1446,
									},
									{
										id: 8403,
										name: "NYANGE",
										generalAssembly_id: 1446,
									},
									{
										id: 8404,
										name: "RUBUGU",
										generalAssembly_id: 1446,
									},
									{
										id: 10420,
										name: "Ntenyi   Court of Appeal ",
										generalAssembly_id: 1446,
									},
									{
										id: 11953,
										name: "Ntenyi   Gacaca court of sector ",
										generalAssembly_id: 1446,
									},
								],
							},
							{
								id: 1447,
								name: "Rwimbogo ",
								sector_id: 20618,
								courtOfCells: [
									{
										id: 8405,
										name: "KABAJOBA",
										generalAssembly_id: 1447,
									},
									{
										id: 8406,
										name: "KAMABUYE",
										generalAssembly_id: 1447,
									},
									{
										id: 8407,
										name: "KARENGE",
										generalAssembly_id: 1447,
									},
									{
										id: 8408,
										name: "NYABIHANGA",
										generalAssembly_id: 1447,
									},
									{
										id: 10421,
										name: "Rwimbogo  Court of Appeal ",
										generalAssembly_id: 1447,
									},
									{
										id: 11954,
										name: "Rwimbogo  Gacaca court of sector ",
										generalAssembly_id: 1447,
									},
								],
							},
						],
					},
				],
			},
			{
				id: 202,
				name: "Rutsiro",
				province_id: 2,
				createdAt: "2021-05-03T07:57:21.263Z",
				updatedAt: "2021-05-03T07:57:21.263Z",
				sectors: [
					{
						id: 20201,
						name: "Boneza",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020104,
								name: "Remera",
								sector_id: 20201,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020103,
								name: "Nkira",
								sector_id: 20201,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020102,
								name: "Kabihogo",
								sector_id: 20201,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020101,
								name: "Bushaka",
								sector_id: 20201,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1448,
								name: "Boneza ",
								sector_id: 20201,
								courtOfCells: [
									{
										id: 8409,
										name: "BONEZA",
										generalAssembly_id: 1448,
									},
									{
										id: 8410,
										name: "KABUGA",
										generalAssembly_id: 1448,
									},
									{
										id: 8411,
										name: "KARUKAMBA",
										generalAssembly_id: 1448,
									},
									{
										id: 8412,
										name: "MURAMBI",
										generalAssembly_id: 1448,
									},
									{
										id: 8413,
										name: "NKIRA",
										generalAssembly_id: 1448,
									},
									{
										id: 10422,
										name: "Boneza  Court of Appeal ",
										generalAssembly_id: 1448,
									},
									{
										id: 11955,
										name: "Boneza  Gacaca court of sector ",
										generalAssembly_id: 1448,
									},
								],
							},
							{
								id: 1449,
								name: "Busanza  ",
								sector_id: 20201,
								courtOfCells: [
									{
										id: 8414,
										name: "KAGANZA",
										generalAssembly_id: 1449,
									},
									{
										id: 8415,
										name: "KINUNGA",
										generalAssembly_id: 1449,
									},
									{
										id: 8416,
										name: "MUYANGE",
										generalAssembly_id: 1449,
									},
									{
										id: 8417,
										name: "REMERA",
										generalAssembly_id: 1449,
									},
									{
										id: 8418,
										name: "RUSORORO",
										generalAssembly_id: 1449,
									},
									{
										id: 8419,
										name: "RUTU",
										generalAssembly_id: 1449,
									},
									{
										id: 10423,
										name: "Busanza   Court of Appeal ",
										generalAssembly_id: 1449,
									},
									{
										id: 11956,
										name: "Busanza   Gacaca court of sector ",
										generalAssembly_id: 1449,
									},
								],
							},
							{
								id: 1450,
								name: "Rugamba  ",
								sector_id: 20201,
								courtOfCells: [
									{
										id: 8420,
										name: "BUHONONGO",
										generalAssembly_id: 1450,
									},
									{
										id: 8421,
										name: "GASHOKO",
										generalAssembly_id: 1450,
									},
									{
										id: 8422,
										name: "KABIHOGO",
										generalAssembly_id: 1450,
									},
									{
										id: 8423,
										name: "RUGAMBA",
										generalAssembly_id: 1450,
									},
									{
										id: 8424,
										name: "RWIMBOGO",
										generalAssembly_id: 1450,
									},
									{
										id: 10424,
										name: "Rugamba   Court of Appeal ",
										generalAssembly_id: 1450,
									},
									{
										id: 11957,
										name: "Rugamba   Gacaca court of sector ",
										generalAssembly_id: 1450,
									},
								],
							},
							{
								id: 1451,
								name: "Kinunu  ",
								sector_id: 20201,
								courtOfCells: [
									{
										id: 8425,
										name: "BIKONO",
										generalAssembly_id: 1451,
									},
									{
										id: 8426,
										name: "BUGARURA",
										generalAssembly_id: 1451,
									},
									{
										id: 8427,
										name: "BUSHAKA",
										generalAssembly_id: 1451,
									},
									{
										id: 8428,
										name: "GASEKE",
										generalAssembly_id: 1451,
									},
									{
										id: 8429,
										name: "MURAMBA",
										generalAssembly_id: 1451,
									},
									{
										id: 8430,
										name: "RWIMBOGO",
										generalAssembly_id: 1451,
									},
									{
										id: 8431,
										name: "NYAKARERA",
										generalAssembly_id: 1451,
									},
									{
										id: 10425,
										name: "Kinunu   Court of Appeal ",
										generalAssembly_id: 1451,
									},
									{
										id: 11958,
										name: "Kinunu   Gacaca court of sector ",
										generalAssembly_id: 1451,
									},
								],
							},
						],
					},
					{
						id: 20202,
						name: "Gihango",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020207,
								name: "Teba",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020206,
								name: "Shyembe",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020205,
								name: "Ruhingo",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020204,
								name: "Murambi",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020203,
								name: "Mataba",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020202,
								name: "Congo-nil",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020201,
								name: "Bugina",
								sector_id: 20202,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1452,
								name: "Gihango  ",
								sector_id: 20202,
								courtOfCells: [
									{
										id: 8432,
										name: "CONGO-NIL",
										generalAssembly_id: 1452,
									},
									{
										id: 8433,
										name: "GAKOMEYE",
										generalAssembly_id: 1452,
									},
									{
										id: 8434,
										name: "KIVUMU",
										generalAssembly_id: 1452,
									},
									{
										id: 8435,
										name: "MUKEBERA",
										generalAssembly_id: 1452,
									},
									{
										id: 8436,
										name: "MURAMBI",
										generalAssembly_id: 1452,
									},
									{
										id: 8437,
										name: "NKWIRO",
										generalAssembly_id: 1452,
									},
									{
										id: 10426,
										name: "Gihango   Court of Appeal ",
										generalAssembly_id: 1452,
									},
									{
										id: 11959,
										name: "Gihango   Gacaca court of sector ",
										generalAssembly_id: 1452,
									},
								],
							},
							{
								id: 1453,
								name: "Rugarambiro ",
								sector_id: 20202,
								courtOfCells: [
									{
										id: 8438,
										name: "GASHYUHA",
										generalAssembly_id: 1453,
									},
									{
										id: 8439,
										name: "RUGARAMBIRO",
										generalAssembly_id: 1453,
									},
									{
										id: 8440,
										name: "TEBA",
										generalAssembly_id: 1453,
									},
									{
										id: 10427,
										name: "Rugarambiro  Court of Appeal ",
										generalAssembly_id: 1453,
									},
									{
										id: 11960,
										name: "Rugarambiro  Gacaca court of sector ",
										generalAssembly_id: 1453,
									},
								],
							},
							{
								id: 1454,
								name: "Rugote ",
								sector_id: 20202,
								courtOfCells: [
									{
										id: 8441,
										name: "BUTARE",
										generalAssembly_id: 1454,
									},
									{
										id: 8442,
										name: "KARAMBO",
										generalAssembly_id: 1454,
									},
									{
										id: 8443,
										name: "MATABA",
										generalAssembly_id: 1454,
									},
									{
										id: 8444,
										name: "NGANZO",
										generalAssembly_id: 1454,
									},
									{
										id: 8445,
										name: "RUGOTE",
										generalAssembly_id: 1454,
									},
									{
										id: 8446,
										name: "RWAMIYAGA",
										generalAssembly_id: 1454,
									},
									{
										id: 8447,
										name: "SHYEMBE",
										generalAssembly_id: 1454,
									},
									{
										id: 10428,
										name: "Rugote  Court of Appeal ",
										generalAssembly_id: 1454,
									},
									{
										id: 11961,
										name: "Rugote  Gacaca court of sector ",
										generalAssembly_id: 1454,
									},
								],
							},
							{
								id: 1455,
								name: "Kibingo ",
								sector_id: 20202,
								courtOfCells: [
									{
										id: 8448,
										name: "BUGINA",
										generalAssembly_id: 1455,
									},
									{
										id: 8449,
										name: "BUHORO",
										generalAssembly_id: 1455,
									},
									{
										id: 8450,
										name: "GITARAMA",
										generalAssembly_id: 1455,
									},
									{
										id: 8451,
										name: "NYAMABUYE",
										generalAssembly_id: 1455,
									},
									{
										id: 8452,
										name: "RUHINGO",
										generalAssembly_id: 1455,
									},
									{
										id: 8453,
										name: "RWINYANA",
										generalAssembly_id: 1455,
									},
									{
										id: 10429,
										name: "Kibingo  Court of Appeal ",
										generalAssembly_id: 1455,
									},
									{
										id: 11962,
										name: "Kibingo  Gacaca court of sector ",
										generalAssembly_id: 1455,
									},
								],
							},
						],
					},
					{
						id: 20203,
						name: "Kigeyo",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020304,
								name: "Rukaragata",
								sector_id: 20203,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020303,
								name: "Nyagahinika",
								sector_id: 20203,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020302,
								name: "Nkora",
								sector_id: 20203,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020301,
								name: "Buhindure",
								sector_id: 20203,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1456,
								name: "Kigeyo ",
								sector_id: 20203,
								courtOfCells: [
									{
										id: 8454,
										name: "BUHINDURE",
										generalAssembly_id: 1456,
									},
									{
										id: 8455,
										name: "BURERA",
										generalAssembly_id: 1456,
									},
									{
										id: 8456,
										name: "BUKUNGU",
										generalAssembly_id: 1456,
									},
									{
										id: 8457,
										name: "CYIMBIRI",
										generalAssembly_id: 1456,
									},
									{
										id: 8458,
										name: "GASHINGAMUTWE",
										generalAssembly_id: 1456,
									},
									{
										id: 8459,
										name: "GISUNZU",
										generalAssembly_id: 1456,
									},
									{
										id: 8460,
										name: "RWUYA",
										generalAssembly_id: 1456,
									},
									{
										id: 8461,
										name: "NTONDE",
										generalAssembly_id: 1456,
									},
									{
										id: 8462,
										name: "BANDAMIKO",
										generalAssembly_id: 1456,
									},
									{
										id: 8463,
										name: "KAGEYO",
										generalAssembly_id: 1456,
									},
									{
										id: 8464,
										name: "KARAMBO",
										generalAssembly_id: 1456,
									},
									{
										id: 8465,
										name: "RUKERI",
										generalAssembly_id: 1456,
									},
									{
										id: 8466,
										name: "DEHERO",
										generalAssembly_id: 1456,
									},
									{
										id: 10430,
										name: "Kigeyo  Court of Appeal ",
										generalAssembly_id: 1456,
									},
									{
										id: 11963,
										name: "Kigeyo  Gacaca court of sector ",
										generalAssembly_id: 1456,
									},
								],
							},
							{
								id: 1457,
								name: "Ngabo ",
								sector_id: 20203,
								courtOfCells: [
									{
										id: 8467,
										name: "BUGINA",
										generalAssembly_id: 1457,
									},
									{
										id: 8468,
										name: "BUZURU",
										generalAssembly_id: 1457,
									},
									{
										id: 8469,
										name: "GASURA",
										generalAssembly_id: 1457,
									},
									{
										id: 8470,
										name: "MURAMBI",
										generalAssembly_id: 1457,
									},
									{
										id: 8471,
										name: "RUKARAGATA",
										generalAssembly_id: 1457,
									},
									{
										id: 8472,
										name: "RUSEBEYA",
										generalAssembly_id: 1457,
									},
									{
										id: 8473,
										name: "RYAMUGABO",
										generalAssembly_id: 1457,
									},
									{
										id: 10431,
										name: "Ngabo  Court of Appeal ",
										generalAssembly_id: 1457,
									},
									{
										id: 11964,
										name: "Ngabo  Gacaca court of sector ",
										generalAssembly_id: 1457,
									},
								],
							},
						],
					},
					{
						id: 20204,
						name: "Kivumu",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020406,
								name: "Nganzo",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020405,
								name: "Karambi",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020404,
								name: "Kabujenje",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020403,
								name: "Kabere",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020402,
								name: "Bunyunju",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020401,
								name: "Bunyoni",
								sector_id: 20204,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1458,
								name: "Budaha ",
								sector_id: 20204,
								courtOfCells: [
									{
										id: 8474,
										name: "BUCIRA",
										generalAssembly_id: 1458,
									},
									{
										id: 8475,
										name: "BUNYONI",
										generalAssembly_id: 1458,
									},
									{
										id: 8476,
										name: "GIHARI",
										generalAssembly_id: 1458,
									},
									{
										id: 8477,
										name: "GITWA",
										generalAssembly_id: 1458,
									},
									{
										id: 8478,
										name: "KABUJENJE",
										generalAssembly_id: 1458,
									},
									{
										id: 10432,
										name: "Budaha  Court of Appeal ",
										generalAssembly_id: 1458,
									},
									{
										id: 11965,
										name: "Budaha  Gacaca court of sector ",
										generalAssembly_id: 1458,
									},
								],
							},
							{
								id: 1459,
								name: "Buhoko ",
								sector_id: 20204,
								courtOfCells: [
									{
										id: 8479,
										name: "BUSHAMBA",
										generalAssembly_id: 1459,
									},
									{
										id: 8480,
										name: "KARAMBI",
										generalAssembly_id: 1459,
									},
									{
										id: 8481,
										name: "MUKONDO",
										generalAssembly_id: 1459,
									},
									{
										id: 8482,
										name: "RUKURAZO",
										generalAssembly_id: 1459,
									},
									{
										id: 8483,
										name: "RUSUMO",
										generalAssembly_id: 1459,
									},
									{
										id: 10433,
										name: "Buhoko  Court of Appeal ",
										generalAssembly_id: 1459,
									},
									{
										id: 11966,
										name: "Buhoko  Gacaca court of sector ",
										generalAssembly_id: 1459,
									},
								],
							},
							{
								id: 1460,
								name: "Gashashi ",
								sector_id: 20204,
								courtOfCells: [
									{
										id: 8484,
										name: "KAVUMU",
										generalAssembly_id: 1460,
									},
									{
										id: 8485,
										name: "NGANZO",
										generalAssembly_id: 1460,
									},
									{
										id: 8486,
										name: "NKWIRO",
										generalAssembly_id: 1460,
									},
									{
										id: 8487,
										name: "RWINYONI",
										generalAssembly_id: 1460,
									},
									{
										id: 10434,
										name: "Gashashi  Court of Appeal ",
										generalAssembly_id: 1460,
									},
									{
										id: 11967,
										name: "Gashashi  Gacaca court of sector ",
										generalAssembly_id: 1460,
									},
								],
							},
							{
								id: 1461,
								name: "Kivumu  ",
								sector_id: 20204,
								courtOfCells: [
									{
										id: 8488,
										name: "BUHABANZI",
										generalAssembly_id: 1461,
									},
									{
										id: 8489,
										name: "BUJOKA",
										generalAssembly_id: 1461,
									},
									{
										id: 8490,
										name: "BUNYUNJU",
										generalAssembly_id: 1461,
									},
									{
										id: 8491,
										name: "BUREMO",
										generalAssembly_id: 1461,
									},
									{
										id: 8492,
										name: "KIVUGIZA",
										generalAssembly_id: 1461,
									},
									{
										id: 8493,
										name: "TARASI",
										generalAssembly_id: 1461,
									},
									{
										id: 10435,
										name: "Kivumu   Court of Appeal ",
										generalAssembly_id: 1461,
									},
									{
										id: 11968,
										name: "Kivumu   Gacaca court of sector ",
										generalAssembly_id: 1461,
									},
								],
							},
							{
								id: 1462,
								name: "Mwufe ",
								sector_id: 20204,
								courtOfCells: [
									{
										id: 8494,
										name: "BURAMBO",
										generalAssembly_id: 1462,
									},
									{
										id: 8495,
										name: "BURANGO",
										generalAssembly_id: 1462,
									},
									{
										id: 8496,
										name: "BUREKE",
										generalAssembly_id: 1462,
									},
									{
										id: 8497,
										name: "KABERE",
										generalAssembly_id: 1462,
									},
									{
										id: 8498,
										name: "KAGERA",
										generalAssembly_id: 1462,
									},
									{
										id: 8499,
										name: "MUSHUBATI",
										generalAssembly_id: 1462,
									},
									{
										id: 10436,
										name: "Mwufe  Court of Appeal ",
										generalAssembly_id: 1462,
									},
									{
										id: 11969,
										name: "Mwufe  Gacaca court of sector ",
										generalAssembly_id: 1462,
									},
								],
							},
						],
					},
					{
						id: 20205,
						name: "Manihira",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020503,
								name: "Tangabo",
								sector_id: 20205,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020502,
								name: "Muyira",
								sector_id: 20205,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020501,
								name: "Haniro",
								sector_id: 20205,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1463,
								name: "Manihira  ",
								sector_id: 20205,
								courtOfCells: [
									{
										id: 8500,
										name: "GISEKE",
										generalAssembly_id: 1463,
									},
									{
										id: 8501,
										name: "HANIRO",
										generalAssembly_id: 1463,
									},
									{
										id: 8502,
										name: "RWAMIKO",
										generalAssembly_id: 1463,
									},
									{
										id: 10437,
										name: "Manihira   Court of Appeal ",
										generalAssembly_id: 1463,
									},
									{
										id: 11970,
										name: "Manihira   Gacaca court of sector ",
										generalAssembly_id: 1463,
									},
								],
							},
							{
								id: 1464,
								name: "Kagano  ",
								sector_id: 20205,
								courtOfCells: [
									{
										id: 8503,
										name: "KAGANO",
										generalAssembly_id: 1464,
									},
									{
										id: 8504,
										name: "MANIHIRA",
										generalAssembly_id: 1464,
									},
									{
										id: 8505,
										name: "TANGABO",
										generalAssembly_id: 1464,
									},
									{
										id: 10438,
										name: "Kagano   Court of Appeal ",
										generalAssembly_id: 1464,
									},
									{
										id: 11971,
										name: "Kagano   Gacaca court of sector ",
										generalAssembly_id: 1464,
									},
								],
							},
							{
								id: 1465,
								name: "Birambo  ",
								sector_id: 20205,
								courtOfCells: [
									{
										id: 8506,
										name: "BIRAMBO",
										generalAssembly_id: 1465,
									},
									{
										id: 8507,
										name: "MUYIRA",
										generalAssembly_id: 1465,
									},
									{
										id: 8508,
										name: "NYAKARAMBI",
										generalAssembly_id: 1465,
									},
									{
										id: 8509,
										name: "RUFUNGO",
										generalAssembly_id: 1465,
									},
									{
										id: 10439,
										name: "Birambo   Court of Appeal ",
										generalAssembly_id: 1465,
									},
									{
										id: 11972,
										name: "Birambo   Gacaca court of sector ",
										generalAssembly_id: 1465,
									},
								],
							},
						],
					},
					{
						id: 20206,
						name: "Mukura",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020606,
								name: "Mwendo",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020605,
								name: "Karambo",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020604,
								name: "Kagusa",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020603,
								name: "Kageyo",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020602,
								name: "Kagano",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020601,
								name: "Kabuga",
								sector_id: 20206,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1466,
								name: "Mukura  ",
								sector_id: 20206,
								courtOfCells: [
									{
										id: 8510,
										name: "GAKERI",
										generalAssembly_id: 1466,
									},
									{
										id: 8511,
										name: "KAGANO",
										generalAssembly_id: 1466,
									},
									{
										id: 8512,
										name: "KAZIZI",
										generalAssembly_id: 1466,
									},
									{
										id: 8513,
										name: "KIBAVU",
										generalAssembly_id: 1466,
									},
									{
										id: 10440,
										name: "Mukura   Court of Appeal ",
										generalAssembly_id: 1466,
									},
									{
										id: 11973,
										name: "Mukura   Gacaca court of sector ",
										generalAssembly_id: 1466,
									},
								],
							},
							{
								id: 1467,
								name: "Gihara  ",
								sector_id: 20206,
								courtOfCells: [
									{
										id: 8514,
										name: "BUCYEYE",
										generalAssembly_id: 1467,
									},
									{
										id: 8515,
										name: "GAKO",
										generalAssembly_id: 1467,
									},
									{
										id: 8516,
										name: "GASHARU",
										generalAssembly_id: 1467,
									},
									{
										id: 8517,
										name: "KABAHIGI",
										generalAssembly_id: 1467,
									},
									{
										id: 8518,
										name: "MUHINDO",
										generalAssembly_id: 1467,
									},
									{
										id: 8519,
										name: "MIRARAMO",
										generalAssembly_id: 1467,
									},
									{
										id: 8520,
										name: "RUTUNGURA",
										generalAssembly_id: 1467,
									},
									{
										id: 8521,
										name: "SANZARE",
										generalAssembly_id: 1467,
									},
									{
										id: 10441,
										name: "Gihara   Court of Appeal ",
										generalAssembly_id: 1467,
									},
									{
										id: 11974,
										name: "Gihara   Gacaca court of sector ",
										generalAssembly_id: 1467,
									},
								],
							},
							{
								id: 1468,
								name: "Kigeyo ",
								sector_id: 20206,
								courtOfCells: [
									{
										id: 8522,
										name: "KAGANO",
										generalAssembly_id: 1468,
									},
									{
										id: 8523,
										name: "MUCACA",
										generalAssembly_id: 1468,
									},
									{
										id: 8524,
										name: "SITE MUKURA",
										generalAssembly_id: 1468,
									},
									{
										id: 10442,
										name: "Kigeyo  Court of Appeal ",
										generalAssembly_id: 1468,
									},
									{
										id: 11975,
										name: "Kigeyo  Gacaca court of sector ",
										generalAssembly_id: 1468,
									},
								],
							},
							{
								id: 1469,
								name: "Mwendo  ",
								sector_id: 20206,
								courtOfCells: [
									{
										id: 8525,
										name: "GAFU",
										generalAssembly_id: 1469,
									},
									{
										id: 8526,
										name: "KAGOMBWA",
										generalAssembly_id: 1469,
									},
									{
										id: 8527,
										name: "MATABA",
										generalAssembly_id: 1469,
									},
									{
										id: 8528,
										name: "NYARUBANDE",
										generalAssembly_id: 1469,
									},
									{
										id: 8529,
										name: "NYARUSONGATI",
										generalAssembly_id: 1469,
									},
									{
										id: 8530,
										name: "NYOVE",
										generalAssembly_id: 1469,
									},
									{
										id: 8531,
										name: "RUGARI",
										generalAssembly_id: 1469,
									},
									{
										id: 10443,
										name: "Mwendo   Court of Appeal ",
										generalAssembly_id: 1469,
									},
									{
										id: 11976,
										name: "Mwendo   Gacaca court of sector ",
										generalAssembly_id: 1469,
									},
								],
							},
						],
					},
					{
						id: 20207,
						name: "Murunda",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020704,
								name: "Twabugezi",
								sector_id: 20207,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020703,
								name: "Rugeyo",
								sector_id: 20207,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020702,
								name: "Mburamazi",
								sector_id: 20207,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020701,
								name: "Kirwa",
								sector_id: 20207,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1470,
								name: "Nyarucundura ",
								sector_id: 20207,
								courtOfCells: [
									{
										id: 8532,
										name: "GATARE",
										generalAssembly_id: 1470,
									},
									{
										id: 8533,
										name: "MBURAMAZI",
										generalAssembly_id: 1470,
									},
									{
										id: 8534,
										name: "MURUNDA",
										generalAssembly_id: 1470,
									},
									{
										id: 8535,
										name: "NYARUCUNDURA",
										generalAssembly_id: 1470,
									},
									{
										id: 8536,
										name: "TWABUGEZI",
										generalAssembly_id: 1470,
									},
									{
										id: 10444,
										name: "Nyarucundura  Court of Appeal ",
										generalAssembly_id: 1470,
									},
									{
										id: 11977,
										name: "Nyarucundura  Gacaca court of sector ",
										generalAssembly_id: 1470,
									},
								],
							},
							{
								id: 1471,
								name: "Gatoki  ",
								sector_id: 20207,
								courtOfCells: [
									{
										id: 8537,
										name: "GATOKI",
										generalAssembly_id: 1471,
									},
									{
										id: 8538,
										name: "KAMABUYE",
										generalAssembly_id: 1471,
									},
									{
										id: 8539,
										name: "KAMUSAMBI",
										generalAssembly_id: 1471,
									},
									{
										id: 8540,
										name: "KARIBA",
										generalAssembly_id: 1471,
									},
									{
										id: 8541,
										name: "MUSONGATI",
										generalAssembly_id: 1471,
									},
									{
										id: 8542,
										name: "RUGEYO",
										generalAssembly_id: 1471,
									},
									{
										id: 8543,
										name: "RURIMBA",
										generalAssembly_id: 1471,
									},
									{
										id: 10445,
										name: "Gatoki   Court of Appeal ",
										generalAssembly_id: 1471,
									},
									{
										id: 11978,
										name: "Gatoki   Gacaca court of sector ",
										generalAssembly_id: 1471,
									},
								],
							},
							{
								id: 1472,
								name: "Bwiza  ",
								sector_id: 20207,
								courtOfCells: [
									{
										id: 8544,
										name: "KARUMBI",
										generalAssembly_id: 1472,
									},
									{
										id: 8545,
										name: "KARURUMA",
										generalAssembly_id: 1472,
									},
									{
										id: 8546,
										name: "KIRWA",
										generalAssembly_id: 1472,
									},
									{
										id: 8547,
										name: "RUHANGA",
										generalAssembly_id: 1472,
									},
									{
										id: 10446,
										name: "Bwiza   Court of Appeal ",
										generalAssembly_id: 1472,
									},
									{
										id: 11979,
										name: "Bwiza   Gacaca court of sector ",
										generalAssembly_id: 1472,
									},
								],
							},
						],
					},
					{
						id: 20208,
						name: "Musasa",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020804,
								name: "Nyarubuye",
								sector_id: 20208,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020803,
								name: "Murambi",
								sector_id: 20208,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020802,
								name: "Gisiza",
								sector_id: 20208,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020801,
								name: "Gabiro",
								sector_id: 20208,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1473,
								name: "Gihinga  ",
								sector_id: 20208,
								courtOfCells: [
									{
										id: 8548,
										name: "GASHAKI",
										generalAssembly_id: 1473,
									},
									{
										id: 8549,
										name: "GISIZA",
										generalAssembly_id: 1473,
									},
									{
										id: 8550,
										name: "GITOVU",
										generalAssembly_id: 1473,
									},
									{
										id: 8551,
										name: "KANOMBE",
										generalAssembly_id: 1473,
									},
									{
										id: 8552,
										name: "KINOMBE",
										generalAssembly_id: 1473,
									},
									{
										id: 8553,
										name: "NYAGAFURWE",
										generalAssembly_id: 1473,
									},
									{
										id: 10447,
										name: "Gihinga   Court of Appeal ",
										generalAssembly_id: 1473,
									},
									{
										id: 11980,
										name: "Gihinga   Gacaca court of sector ",
										generalAssembly_id: 1473,
									},
								],
							},
							{
								id: 1474,
								name: "Murama  ",
								sector_id: 20208,
								courtOfCells: [
									{
										id: 8554,
										name: "GABIRO",
										generalAssembly_id: 1474,
									},
									{
										id: 8555,
										name: "KITAZIGURWA",
										generalAssembly_id: 1474,
									},
									{
										id: 8556,
										name: "KOKO",
										generalAssembly_id: 1474,
									},
									{
										id: 8557,
										name: "MATEREZA",
										generalAssembly_id: 1474,
									},
									{
										id: 8558,
										name: "NYANTANGE",
										generalAssembly_id: 1474,
									},
									{
										id: 8559,
										name: "RUKARAGATA",
										generalAssembly_id: 1474,
									},
									{
										id: 10448,
										name: "Murama   Court of Appeal ",
										generalAssembly_id: 1474,
									},
									{
										id: 11981,
										name: "Murama   Gacaca court of sector ",
										generalAssembly_id: 1474,
									},
								],
							},
							{
								id: 1475,
								name: "Musasa  ",
								sector_id: 20208,
								courtOfCells: [
									{
										id: 8560,
										name: "KANYAMENDE",
										generalAssembly_id: 1475,
									},
									{
										id: 8561,
										name: "MUHORORO",
										generalAssembly_id: 1475,
									},
									{
										id: 8562,
										name: "NYARUBUYE",
										generalAssembly_id: 1475,
									},
									{
										id: 8563,
										name: "NYARUTOVU",
										generalAssembly_id: 1475,
									},
									{
										id: 8564,
										name: "REBERO",
										generalAssembly_id: 1475,
									},
									{
										id: 10449,
										name: "Musasa   Court of Appeal ",
										generalAssembly_id: 1475,
									},
									{
										id: 11982,
										name: "Musasa   Gacaca court of sector ",
										generalAssembly_id: 1475,
									},
								],
							},
							{
								id: 1476,
								name: "Syiki  ",
								sector_id: 20208,
								courtOfCells: [
									{
										id: 8565,
										name: "KABINGO",
										generalAssembly_id: 1476,
									},
									{
										id: 8566,
										name: "KAMABUYE",
										generalAssembly_id: 1476,
									},
									{
										id: 8567,
										name: "MURAMBI",
										generalAssembly_id: 1476,
									},
									{
										id: 8568,
										name: "RUKORE",
										generalAssembly_id: 1476,
									},
									{
										id: 8569,
										name: "SOZI",
										generalAssembly_id: 1476,
									},
									{
										id: 8570,
										name: "SYIKI",
										generalAssembly_id: 1476,
									},
									{
										id: 10450,
										name: "Syiki   Court of Appeal ",
										generalAssembly_id: 1476,
									},
									{
										id: 11983,
										name: "Syiki   Gacaca court of sector ",
										generalAssembly_id: 1476,
									},
								],
							},
						],
					},
					{
						id: 20209,
						name: "Mushonyi",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2020904,
								name: "Rurara",
								sector_id: 20209,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020903,
								name: "Magaba",
								sector_id: 20209,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020902,
								name: "Kaguriro",
								sector_id: 20209,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2020901,
								name: "Biruyi",
								sector_id: 20209,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1477,
								name: "Gihumba ",
								sector_id: 20209,
								courtOfCells: [
									{
										id: 8571,
										name: "BURUHUKIRO",
										generalAssembly_id: 1477,
									},
									{
										id: 8572,
										name: "KANYAMASIGA",
										generalAssembly_id: 1477,
									},
									{
										id: 8573,
										name: "KARUGARIKA",
										generalAssembly_id: 1477,
									},
									{
										id: 8574,
										name: "MPANZI",
										generalAssembly_id: 1477,
									},
									{
										id: 10451,
										name: "Gihumba  Court of Appeal ",
										generalAssembly_id: 1477,
									},
									{
										id: 11984,
										name: "Gihumba  Gacaca court of sector ",
										generalAssembly_id: 1477,
									},
								],
							},
							{
								id: 1478,
								name: "Gishwati  ",
								sector_id: 20209,
								courtOfCells: [
									{
										id: 8575,
										name: "BUHUNDE",
										generalAssembly_id: 1478,
									},
									{
										id: 8576,
										name: "KAMARANZARA",
										generalAssembly_id: 1478,
									},
									{
										id: 8577,
										name: "MUGARA",
										generalAssembly_id: 1478,
									},
									{
										id: 8578,
										name: "RURIMBA",
										generalAssembly_id: 1478,
									},
									{
										id: 10452,
										name: "Gishwati   Court of Appeal ",
										generalAssembly_id: 1478,
									},
									{
										id: 11985,
										name: "Gishwati   Gacaca court of sector ",
										generalAssembly_id: 1478,
									},
								],
							},
							{
								id: 1479,
								name: "Mushonyi  ",
								sector_id: 20209,
								courtOfCells: [
									{
										id: 8579,
										name: "BUSHUNGA",
										generalAssembly_id: 1479,
									},
									{
										id: 8580,
										name: "GITARAMO",
										generalAssembly_id: 1479,
									},
									{
										id: 8581,
										name: "KIZINGA",
										generalAssembly_id: 1479,
									},
									{
										id: 8582,
										name: "MIKINGO",
										generalAssembly_id: 1479,
									},
									{
										id: 8583,
										name: "RUGARAGARA",
										generalAssembly_id: 1479,
									},
									{
										id: 8584,
										name: "RURARA",
										generalAssembly_id: 1479,
									},
									{
										id: 10453,
										name: "Mushonyi   Court of Appeal ",
										generalAssembly_id: 1479,
									},
									{
										id: 11986,
										name: "Mushonyi   Gacaca court of sector ",
										generalAssembly_id: 1479,
									},
								],
							},
							{
								id: 1480,
								name: "Vumbi  ",
								sector_id: 20209,
								courtOfCells: [
									{
										id: 8585,
										name: "GASARENDA",
										generalAssembly_id: 1480,
									},
									{
										id: 8586,
										name: "GITOVU",
										generalAssembly_id: 1480,
									},
									{
										id: 8587,
										name: "KAGURIRO",
										generalAssembly_id: 1480,
									},
									{
										id: 8588,
										name: "KARONGI",
										generalAssembly_id: 1480,
									},
									{
										id: 8589,
										name: "KIRAVUMBA",
										generalAssembly_id: 1480,
									},
									{
										id: 8590,
										name: "MUTABI",
										generalAssembly_id: 1480,
									},
									{
										id: 8591,
										name: "RUGARAGARA",
										generalAssembly_id: 1480,
									},
									{
										id: 10454,
										name: "Vumbi   Court of Appeal ",
										generalAssembly_id: 1480,
									},
									{
										id: 11987,
										name: "Vumbi   Gacaca court of sector ",
										generalAssembly_id: 1480,
									},
								],
							},
						],
					},
					{
						id: 20210,
						name: "Mushubati",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2021005,
								name: "Sure",
								sector_id: 20210,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021004,
								name: "Mageragere",
								sector_id: 20210,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021003,
								name: "Gitwa",
								sector_id: 20210,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021002,
								name: "Cyarusera",
								sector_id: 20210,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021001,
								name: "Bumba",
								sector_id: 20210,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1481,
								name: "Mushubati  ",
								sector_id: 20210,
								courtOfCells: [
									{
										id: 8592,
										name: "GAFUMBA",
										generalAssembly_id: 1481,
									},
									{
										id: 8593,
										name: "KUNINI",
										generalAssembly_id: 1481,
									},
									{
										id: 8594,
										name: "MUGERA",
										generalAssembly_id: 1481,
									},
									{
										id: 8595,
										name: "NYAKABANDE",
										generalAssembly_id: 1481,
									},
									{
										id: 8596,
										name: "NYAKABUYE",
										generalAssembly_id: 1481,
									},
									{
										id: 8597,
										name: "RUSHIKIRI",
										generalAssembly_id: 1481,
									},
									{
										id: 10455,
										name: "Mushubati   Court of Appeal ",
										generalAssembly_id: 1481,
									},
									{
										id: 11988,
										name: "Mushubati   Gacaca court of sector ",
										generalAssembly_id: 1481,
									},
								],
							},
							{
								id: 1482,
								name: "Nyagatovu  ",
								sector_id: 20210,
								courtOfCells: [
									{
										id: 8598,
										name: "BUTONDE",
										generalAssembly_id: 1482,
									},
									{
										id: 8599,
										name: "CYANYA",
										generalAssembly_id: 1482,
									},
									{
										id: 8600,
										name: "NYARUGENGE",
										generalAssembly_id: 1482,
									},
									{
										id: 8601,
										name: "RUSHOKA",
										generalAssembly_id: 1482,
									},
									{
										id: 8602,
										name: "RUSORORO",
										generalAssembly_id: 1482,
									},
									{
										id: 8603,
										name: "RWINTARE",
										generalAssembly_id: 1482,
									},
									{
										id: 10456,
										name: "Nyagatovu   Court of Appeal ",
										generalAssembly_id: 1482,
									},
									{
										id: 11989,
										name: "Nyagatovu   Gacaca court of sector ",
										generalAssembly_id: 1482,
									},
								],
							},
							{
								id: 1483,
								name: "Buhinga  ",
								sector_id: 20210,
								courtOfCells: [
									{
										id: 8604,
										name: "GAKOMA",
										generalAssembly_id: 1483,
									},
									{
										id: 8605,
										name: "KARAMBIRA",
										generalAssembly_id: 1483,
									},
									{
										id: 8606,
										name: "KIBARI",
										generalAssembly_id: 1483,
									},
									{
										id: 8607,
										name: "MUBUGA",
										generalAssembly_id: 1483,
									},
									{
										id: 8608,
										name: "MUGERI",
										generalAssembly_id: 1483,
									},
									{
										id: 8609,
										name: "MURAMBI",
										generalAssembly_id: 1483,
									},
									{
										id: 8610,
										name: "RUBUBA",
										generalAssembly_id: 1483,
									},
									{
										id: 8611,
										name: "RUHINGA",
										generalAssembly_id: 1483,
									},
									{
										id: 10457,
										name: "Buhinga   Court of Appeal ",
										generalAssembly_id: 1483,
									},
									{
										id: 11990,
										name: "Buhinga   Gacaca court of sector ",
										generalAssembly_id: 1483,
									},
								],
							},
							{
								id: 1484,
								name: "Rukaragata  ",
								sector_id: 20210,
								courtOfCells: [
									{
										id: 8612,
										name: "BISYO",
										generalAssembly_id: 1484,
									},
									{
										id: 8613,
										name: "BUMBA",
										generalAssembly_id: 1484,
									},
									{
										id: 8614,
										name: "KABIRAHO",
										generalAssembly_id: 1484,
									},
									{
										id: 8615,
										name: "MATABA",
										generalAssembly_id: 1484,
									},
									{
										id: 8616,
										name: "RUKANKA",
										generalAssembly_id: 1484,
									},
									{
										id: 10458,
										name: "Rukaragata   Court of Appeal ",
										generalAssembly_id: 1484,
									},
									{
										id: 11991,
										name: "Rukaragata   Gacaca court of sector ",
										generalAssembly_id: 1484,
									},
								],
							},
						],
					},
					{
						id: 20211,
						name: "Nyabirasi",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2021105,
								name: "Terimbere",
								sector_id: 20211,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021104,
								name: "Ngoma",
								sector_id: 20211,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021103,
								name: "Mubuga",
								sector_id: 20211,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021102,
								name: "Cyivugiza",
								sector_id: 20211,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021101,
								name: "Busuku",
								sector_id: 20211,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1485,
								name: "Kanombe  ",
								sector_id: 20211,
								courtOfCells: [
									{
										id: 8617,
										name: "BUKANDA",
										generalAssembly_id: 1485,
									},
									{
										id: 8618,
										name: "GASHASHO",
										generalAssembly_id: 1485,
									},
									{
										id: 8619,
										name: "GISAYO",
										generalAssembly_id: 1485,
									},
									{
										id: 8620,
										name: "KAGESHI",
										generalAssembly_id: 1485,
									},
									{
										id: 8621,
										name: "KASONGA",
										generalAssembly_id: 1485,
									},
									{
										id: 8622,
										name: "KAZO",
										generalAssembly_id: 1485,
									},
									{
										id: 8623,
										name: "MUBUGA",
										generalAssembly_id: 1485,
									},
									{
										id: 8624,
										name: "NGOMA",
										generalAssembly_id: 1485,
									},
									{
										id: 10459,
										name: "Kanombe   Court of Appeal ",
										generalAssembly_id: 1485,
									},
									{
										id: 11992,
										name: "Kanombe   Gacaca court of sector ",
										generalAssembly_id: 1485,
									},
								],
							},
							{
								id: 1486,
								name: "Nkuli ",
								sector_id: 20211,
								courtOfCells: [
									{
										id: 8625,
										name: "BUSUKU",
										generalAssembly_id: 1486,
									},
									{
										id: 8626,
										name: "BUSUTI",
										generalAssembly_id: 1486,
									},
									{
										id: 8627,
										name: "GASHIHE",
										generalAssembly_id: 1486,
									},
									{
										id: 8628,
										name: "GATARE",
										generalAssembly_id: 1486,
									},
									{
										id: 8629,
										name: "NGUGO",
										generalAssembly_id: 1486,
									},
									{
										id: 8630,
										name: "NKUNA",
										generalAssembly_id: 1486,
									},
									{
										id: 8631,
										name: "TSINDIRO",
										generalAssembly_id: 1486,
									},
									{
										id: 10460,
										name: "Nkuli  Court of Appeal ",
										generalAssembly_id: 1486,
									},
									{
										id: 11993,
										name: "Nkuli  Gacaca court of sector ",
										generalAssembly_id: 1486,
									},
								],
							},
							{
								id: 1487,
								name: "Nyabirasi ",
								sector_id: 20211,
								courtOfCells: [
									{
										id: 8632,
										name: "GATSIRO",
										generalAssembly_id: 1487,
									},
									{
										id: 8633,
										name: "GIHINGA",
										generalAssembly_id: 1487,
									},
									{
										id: 8634,
										name: "KAGEYO",
										generalAssembly_id: 1487,
									},
									{
										id: 8635,
										name: "KAMANANGA",
										generalAssembly_id: 1487,
									},
									{
										id: 8636,
										name: "KINYAMAVUTA",
										generalAssembly_id: 1487,
									},
									{
										id: 8637,
										name: "NYABISHONGO",
										generalAssembly_id: 1487,
									},
									{
										id: 8638,
										name: "RUKOMERO",
										generalAssembly_id: 1487,
									},
									{
										id: 8639,
										name: "RURAJI",
										generalAssembly_id: 1487,
									},
									{
										id: 8640,
										name: "RUTOVU",
										generalAssembly_id: 1487,
									},
									{
										id: 8641,
										name: "RWANGAMBUTO",
										generalAssembly_id: 1487,
									},
									{
										id: 10461,
										name: "Nyabirasi  Court of Appeal ",
										generalAssembly_id: 1487,
									},
									{
										id: 11994,
										name: "Nyabirasi  Gacaca court of sector ",
										generalAssembly_id: 1487,
									},
								],
							},
						],
					},
					{
						id: 20212,
						name: "Ruhango",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2021206,
								name: "Rundoyi",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021205,
								name: "Rugasa",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021204,
								name: "Nyakarera",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021203,
								name: "Kavumu",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021202,
								name: "Gihira",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021201,
								name: "Gatare",
								sector_id: 20212,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1488,
								name: "Kayove  ",
								sector_id: 20212,
								courtOfCells: [
									{
										id: 8642,
										name: "BUGABO",
										generalAssembly_id: 1488,
									},
									{
										id: 8643,
										name: "GASASA",
										generalAssembly_id: 1488,
									},
									{
										id: 8644,
										name: "GIHIRA",
										generalAssembly_id: 1488,
									},
									{
										id: 8645,
										name: "KAGOGO",
										generalAssembly_id: 1488,
									},
									{
										id: 8646,
										name: "KAVUMU",
										generalAssembly_id: 1488,
									},
									{
										id: 8647,
										name: "MARABUYE",
										generalAssembly_id: 1488,
									},
									{
										id: 8648,
										name: "MUBIRIZI",
										generalAssembly_id: 1488,
									},
									{
										id: 8649,
										name: "NYAKARERA",
										generalAssembly_id: 1488,
									},
									{
										id: 8650,
										name: "RUNDOYI",
										generalAssembly_id: 1488,
									},
									{
										id: 10462,
										name: "Kayove   Court of Appeal ",
										generalAssembly_id: 1488,
									},
									{
										id: 11995,
										name: "Kayove   Gacaca court of sector ",
										generalAssembly_id: 1488,
									},
								],
							},
							{
								id: 1489,
								name: "Gasovu  ",
								sector_id: 20212,
								courtOfCells: [
									{
										id: 8651,
										name: "CYASHENGE",
										generalAssembly_id: 1489,
									},
									{
										id: 8652,
										name: "GASOVU",
										generalAssembly_id: 1489,
									},
									{
										id: 8653,
										name: "KABITOVU",
										generalAssembly_id: 1489,
									},
									{
										id: 8654,
										name: "KAMURAMIRA",
										generalAssembly_id: 1489,
									},
									{
										id: 8655,
										name: "KIRINJA",
										generalAssembly_id: 1489,
									},
									{
										id: 8656,
										name: "RUGASA",
										generalAssembly_id: 1489,
									},
									{
										id: 8657,
										name: "RUKENESHA",
										generalAssembly_id: 1489,
									},
									{
										id: 10463,
										name: "Gasovu   Court of Appeal ",
										generalAssembly_id: 1489,
									},
									{
										id: 11996,
										name: "Gasovu   Gacaca court of sector ",
										generalAssembly_id: 1489,
									},
								],
							},
						],
					},
					{
						id: 20213,
						name: "Rusebeya",
						district_id: 202,
						createdAt: "2021-05-03T07:57:21.276Z",
						updatedAt: "2021-05-03T07:57:21.276Z",
						cells: [
							{
								id: 2021304,
								name: "Ruronde",
								sector_id: 20213,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021303,
								name: "Remera",
								sector_id: 20213,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021302,
								name: "Mberi",
								sector_id: 20213,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
							{
								id: 2021301,
								name: "Kabona",
								sector_id: 20213,
								createdAt: "2021-05-03T07:57:21.345Z",
								updatedAt: "2021-05-03T07:57:21.345Z",
								villages: [],
							},
						],
						courts: [
							{
								id: 1490,
								name: "Rusebeya  ",
								sector_id: 20213,
								courtOfCells: [
									{
										id: 8658,
										name: "GAKERI",
										generalAssembly_id: 1490,
									},
									{
										id: 8659,
										name: "GIHINGA",
										generalAssembly_id: 1490,
									},
									{
										id: 8660,
										name: "MUKORE",
										generalAssembly_id: 1490,
									},
									{
										id: 8661,
										name: "RURONDE",
										generalAssembly_id: 1490,
									},
									{
										id: 8662,
										name: "RUSEBEYA",
										generalAssembly_id: 1490,
									},
									{
										id: 10464,
										name: "Rusebeya   Court of Appeal ",
										generalAssembly_id: 1490,
									},
									{
										id: 11997,
										name: "Rusebeya   Gacaca court of sector ",
										generalAssembly_id: 1490,
									},
								],
							},
							{
								id: 1491,
								name: "Muhira  ",
								sector_id: 20213,
								courtOfCells: [
									{
										id: 8663,
										name: "KABONA",
										generalAssembly_id: 1491,
									},
									{
										id: 8664,
										name: "KIGAMBA",
										generalAssembly_id: 1491,
									},
									{
										id: 8665,
										name: "MBERI",
										generalAssembly_id: 1491,
									},
									{
										id: 10465,
										name: "Muhira   Court of Appeal ",
										generalAssembly_id: 1491,
									},
									{
										id: 11998,
										name: "Muhira   Gacaca court of sector ",
										generalAssembly_id: 1491,
									},
								],
							},
							{
								id: 1492,
								name: "Gitebe  ",
								sector_id: 20213,
								courtOfCells: [
									{
										id: 8666,
										name: "BUNGWE",
										generalAssembly_id: 1492,
									},
									{
										id: 8667,
										name: "MURENGERI",
										generalAssembly_id: 1492,
									},
									{
										id: 8668,
										name: "MUTURAGAZA",
										generalAssembly_id: 1492,
									},
									{
										id: 8669,
										name: "REMERA",
										generalAssembly_id: 1492,
									},
									{
										id: 10466,
										name: "Gitebe   Court of Appeal ",
										generalAssembly_id: 1492,
									},
									{
										id: 11999,
										name: "Gitebe   Gacaca court of sector ",
										generalAssembly_id: 1492,
									},
								],
							},
						],
					},
				],
			},
		],
	},
];
