import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { IoDocumentTextSharp } from "react-icons/io5";
import { AiOutlineLeft } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import moment from "moment";
import { BounceLoader } from "react-spinners";
import { getSpecificSuspect, getDocumentTypes, uploadLinkDocuments } from "../redux/actions/documents";
import pdfIcon from "../assets/images/001-pdf.svg";
import PDFRenderer from "../components/PDFRenderer";
import LinkDocumentModal from '../components/LinkDocumentModal'

export class SuspectDetails extends Component {
	state = {
		file: "",
		showLinkModal: false,
		loadingLink: false,
		linkResults: null,
	};
	componentDidMount = async () => {
		const { id } = this.props.match.params;
		this.setState({ loading: true });
		const { getSpecificSuspect, getDocumentTypes } = this.props;
		await getSpecificSuspect(id);
		await getDocumentTypes();
		this.setState({ loading: false });

	};

	// componentDidUpdate = async (prevProps, prevState) => {
	// 	const { id } = this.props.match.params;
	// 	const { linkResults } = this.state;
	// 	if (prevState.linkResults !== linkResults) {
	// 		this.setState({ loading: true });
	// 		const { getSpecificSuspect } = this.props;
	// 		await getSpecificSuspect(id);
	// 		this.setState({ loading: false });
	// 	}
	// };
	handleDisplayFile = async (fileName) => {
		this.setState(() => ({
			file: fileName,
		}));
	};
	toggleLinkModal = () => {
		this.setState(prevState => ({ showLinkModal: !prevState.showLinkModal }))
	};
	handleLinkDocument = async (documentType, file) => {
		const { specificSuspectData, uploadLinkDocuments } = this.props;
		if (specificSuspectData?.data) {
			this.setState({ loadingLink: true });
		    const {
		    	id,
		    	province,
		    	district,
		    	sector,
		    	generalAssembly,
		    	courtOfCell,
		    } = specificSuspectData.data;
		    const data = {
		    	suspectId: id,
		    	province,
		    	district,
		    	sector,
		    	generalAssembly,
		    	courtOfCell,
		    	documentType,
		    };
		    const formData = new FormData();
		    for (const key in data) {
		    	if (data.hasOwnProperty(key)) {
		    		formData.append(key, data[key]);
		    	}
		    }
		    Object.values(file).forEach((item) => {
		    	formData.append("file", item);
		    });
		    const results = await uploadLinkDocuments(formData);
			if (results?.data) {
				this.setState({
					linkResults: results,
					showLinkModal: false,
					loadingLink: false,
					loading: true,
				});
			    const { getSpecificSuspect } = this.props;
			    await getSpecificSuspect(id);

			}
		    this.setState({ loadingLink: false, loading: false });
		}
	};

	render() {
		const { showLinkModal, loadingLink } = this.state;
		const { specificSuspectData, documentTypesData, uploadProgress } = this.props;
		if (specificSuspectData) {
			const {
				Province,
				District,
				Sector,
                GeneralAssembly,
                CourtOfCell,
				names,
				fatherNames,
				motherNames,
				DOB,
				gender,
				nationality,
                profession,
                description,
                trialDate,
                category,
				state,
				documents,
      crimes,
			} = specificSuspectData.data;
			return (
				<div>
					<div className="p-10 bg-gray-100">
						<Link
							to="/dashboard/suspects"
							className="row items-center text-gdrBlue text-xl"
						>
							<AiOutlineLeft /> &nbsp;
							<p>Back to search results</p>
						</Link>
						<div>
							<div className="pt-8 text-3xl row items-center">
								<FaUser className="text-gdrBlue text-2xl" /> &nbsp; {names}
							</div>
							<div className="p-4 my-4 border rounded-xl row bg-white">
								<div className="flex-1 row items-center">
									Province: {Province.name}
								</div>
								<div className="flex-1 row items-center">
									District: {District.name}
								</div>
								<div className="flex-1 row items-center">
									Sector: {Sector.name}
								</div>
								<div className="flex-1">
									<span className="bg-green-100 px-2 text-green-600 rounded-sm">
										{state} documents out of 8
									</span>
								</div>
							</div>
							<div className="w-full row gap-8 mb-4 rounded-xl bg-white border">
								<div className="flex-1 col p-10 border-r">
									<div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Accused name</div>
											<div className="font-semibold px-4 text-lg">
												{names}
											</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Date of birth</div>
											<div className="font-semibold px-4 text-lg">{DOB}</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Gender</div>
											<div className="font-semibold px-4 text-lg">{gender}</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Father name</div>
											<div className="font-semibold px-4 text-lg">{fatherNames}</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Mother name</div>
											<div className="font-semibold px-4 text-lg">{motherNames}</div>
										</div>
										<div className="col py-2 growing">
											<div className="text-gray-500">Nationality</div>
											<div className="font-semibold px-4 text-lg">
												{nationality}
											</div>
										</div>
									</div>
								</div>
								<div className="flex-1 col p-10 border-l">
									<div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Profession</div>
											<div className="font-semibold px-4 text-lg">
												{profession}
											</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">General assembly</div>
											<div className="font-semibold px-4 text-lg">
												{GeneralAssembly.name}
											</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Court of cell</div>
											<div className="font-semibold px-4 text-lg">
												{CourtOfCell.name}
											</div>
										</div>
										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Sentence</div>
											<div className="font-semibold px-4 text-lg">
												{description}
											</div>
										</div>

							<div className="row items-start my-4">
                            <div className="">Crime(s): </div>
                               <div className="col">
															 {crimes
                                	? crimes.map(({ id, name }) => (
																		<div key={id} className="px-2">
																			<li>{name}</li>
			                                  </div>
		                                     	))
	                                  	: ""}
																			</div>
													 </div>


										<div className="border-b col py-2 growing">
											<div className="text-gray-500">Trial date</div>
											<div className="font-semibold px-4 text-lg">
												{trialDate}
											</div>
										</div>
										<div className="col py-2 growing">
											<div className="text-gray-500">Trial category</div>
											<div className="font-semibold px-4 text-lg">
												{category}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="grid md:grid-cols-2 border rounded-xl bg-white">
								<div className="border-r col p-10">
									<p className="text-3xl">{`Documents (${state})`}</p>
									<div className="grid md:grid-cols-2 gap-6 w-full">
										{documents.length ?
											documents.map(({ id, file, documentName, updatedAt }) => (
												<div
													key={id}
													className="flex-1 border-2 rounded-lg cursor-pointer"
													onClick={() =>
														this.handleDisplayFile(
															//`${process.env.REACT_APP_BASE_URL}auth/${file}`,
															`${process.env.REACT_APP_BASE}${file}`,

														)
													}>

													<div className="col py-4 p-2 justify-center items-center text-center">
														<img src={pdfIcon} alt="" className="w-3/4" />
														<p className="py-1">{documentName}</p>
													</div>
													<div className="flex-1 row items center border-t p-2">
														{moment(updatedAt).format("MMMM DD, YYYY")}
													</div>
												</div>
											)): null}
									</div>
								    <div className="my-8">
								         <button
								            className="p-2 px-6 rounded-md bg-gdrBlue text-white outline-none"
								            onClick={this.toggleLinkModal}
							             >
							                Link a new document to accused
							             </button>
								    </div>
								</div>
								<div className="col">
									<div className="border-b row items-center p-4 py-6 font-semibold">
										<IoDocumentTextSharp /> &nbsp; {names}
									</div>
									<div className="bg-gray-100 p-4 h-screen w-full overflow-y-scroll">
										{/* <iframe
											title="title"
											src={this.state.file}
											className="h-full w-full"
										></iframe> */}
										{/* <img
											src={this.state.file}
											alt=""
											className="w-full h-full"
										/> */}
										<PDFRenderer
											pdf={`${this.state.file}`}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					{showLinkModal
					? <LinkDocumentModal
					    visible={showLinkModal}
						toggle={this.toggleLinkModal}
						documentTypes={documentTypesData?.data}
						onLinkDocument={this.handleLinkDocument}
						loading={loadingLink}
						uploadProgress={uploadProgress} />
				    : null}
				</div>
			);
		} else {
			return (
				<div className="col h-screen w-full justify-center items-center">
					<BounceLoader color="rgba(40, 100, 254, 1)" />
				</div>
			);
		}
	}
}

const mapStateToProps = (state) => {
	const {
		specificSuspectData,
		specificSuspectError,
		documentTypesData,
		documentTypesError,
		uploadLinkDocumentsData,
		uploadProgress
	} = state.documents;
	return {
		specificSuspectData,
		specificSuspectError,
		documentTypesData,
		documentTypesError,
		uploadLinkDocumentsData,
		uploadProgress
	};
};

export default connect(mapStateToProps, {
	getSpecificSuspect,
	getDocumentTypes,
	uploadLinkDocuments
})(SuspectDetails);
