import React from "react";

const StatsCard = ({ title, value }) => {
  return (
    <div className="w-full md:w-1/3 p-2">
      <div className="w-full h-full bg-blue-50 p-6 shadow-lg">
        <p>{title}</p>
        <p className="text-3xl text-right text-gdrBlue">{value}</p>
      </div>
    </div>
  );
};

export default StatsCard;
