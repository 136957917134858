import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BeatLoader } from "react-spinners";
import { MdRemoveCircle } from "react-icons/md";
import {
	getCrimesCategories,
	getCrimes,
	createSuspect,
} from "../redux/actions/documents";
import { Required } from "../components/Required";
import {
	formatAssemblies,
	formatCourts,
	formatDistricts,
	formatProvinces,
	formatSectors,
} from "../helpers/formatAdministration";

export class AddSuspect extends Component {
	state = {
		accusedName: "",
		dob: "",
		fatherNames: "",
		motherNames: "",
		profession: "",
		gender: "",
		nationality: "",
        province: "",
        district: "",
        sector: "",
        generalAssembly: "",
        courtOfCell: "",
		crimeCategory: "",
		crime: "",
		crimes: [],
		crimesNames: [],
        trialDate: "",
		description: "",
		loading: false,
	};
	componentDidMount = async () => {
		const { getCrimesCategories } = this.props;
        const provinces = formatProvinces();
		this.setState(() => ({
			provinces,
		}));
		await getCrimesCategories();
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleChangeProvince = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			district: "",
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
		}));
	};
	handleChangeDistrict = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			sector: "",
			generalAssembly: "",
			courtOfCell: "",
		}));
	};
	handleChangeSector = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			generalAssembly: "",
			courtOfCell: "",
		}));
	};
	handleChangeAssembly = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
			courtOfCell: "",
		}));
	};
	handleChangeCourt = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleChangeCategory = async (e) => {
		e.preventDefault();
		const { getCrimes } = this.props;
		const { name, value } = e.target;
		const newValue = value.split(":");
		this.setState(() => ({
			[name]: newValue[1],
			crime: "",
		}));
		await getCrimes(newValue[0]);
	};
	handleChangeCrimes = (e) => {
		e.preventDefault();
		const { value } = e.target;
		const { crimes } = this.state;
		const newValue = value.split(":");
		const foundCrime = crimes.find((item) => item === newValue[0]);
		if (!foundCrime) {
			this.setState(() => ({
				crime: newValue[0],
				crimes: crimes.concat(newValue[0]),
			}));
			this.saveCrimeName({ id: newValue[0], name: newValue[1] });
		} else {
			this.setState(() => ({
				found: true,
			}));
		}
	};
	handleRemoveCrime = (crimeId) => {
		const { crimes, crimesNames } = this.state;
		const RemainingCrimeIds = crimes.filter((item) => item !== crimeId);
		const RemainingCrimes = crimesNames.filter((item) => item.id !== crimeId);
		if (RemainingCrimes) {
			this.setState(() => ({
				crimes: RemainingCrimeIds,
				crimesNames: RemainingCrimes,
			}));
		}
	};
	saveCrimeName = async (details) => {
		const { crimesNames } = this.state;
		const foundCrimeName = await crimesNames.find(
			(item) => item === details.name,
		);
		if (!foundCrimeName) {
			await this.setState(() => ({
				crimesNames: crimesNames.concat(details),
			}));
		} else {
			this.setState(() => ({
				found: true,
			}));
		}
	};
	clearFields = (e) => {
		e.preventDefault();
		this.setState(() => ({
            accusedName: "",
            dob: "",
            fatherNames: "",
            motherNames: "",
            profession: "",
            gender: "",
            nationality: "",
            province: "",
            district: "",
            sector: "",
            generalAssembly: "",
            courtOfCell: "",
            crimeCategory: "",
            crime: "",
            trialDate: "",
            description: "",
		}));
	};
	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		const { createSuspect } = this.props;
		const {
            accusedName,
            dob,
            fatherNames,
            motherNames,
            gender,
            profession,
            nationality,
            province,
            district,
            sector,
            generalAssembly,
            courtOfCell,
            crimeCategory,
            crimes,
            trialDate,
            description,
		} = this.state;
		const data = {
            names: accusedName,
            DOB: dob,
            fatherNames,
            motherNames,
            gender,
            profession,
            nationality,
            province,
            district,
            sector,
            generalAssembly,
            courtOfCell,
            category: crimeCategory,
            crimes,
            trialDate,
            description,
		};
        
		await createSuspect(data);
		this.setState({ loading: false });
	};

  render() {
    const {
        accusedName,
        dob,
        fatherNames,
        motherNames,
        gender,
        profession,
        nationality,
        provinces,
        province,
        district,
        sector,
        generalAssembly,
        courtOfCell,
        crimeCategory,
        crime,
        crimes,
        crimesNames,
        trialDate,
        description,
        loading,
    } = this.state;
    const { crimeCategoriesData, crimesData, suspectError } = this.props;
    const districts = formatDistricts(Number(province));
	const sectors = formatSectors(Number(province), Number(district));
	const generalAssemblies = formatAssemblies(
		Number(province),
		Number(district),
		Number(sector),
	);
	const courts = formatCourts(
		Number(province),
		Number(district),
		Number(sector),
		Number(generalAssembly),
	);

    return (
      <div>
          <div className="p-10 bg-gray-100">
          <div className="col p-8">
					<p className="text-2xl">Personal data</p>
					<form onSubmit={this.handleSubmit}>
						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Accused full names
									<Required />
								</label>
								<input
									type="text"
									name="accusedName"
									value={accusedName}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
									required
								/>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
                                    Accused birth date
									<Required />
                                </label>
								<input
									type="date"
									name="dob"
									value={dob}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
                                    required
								/>
							</div>
						</div>

						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
                                    Accused father names
									<Required />
                                </label>
								<input
									type="text"
									name="fatherNames"
									value={fatherNames}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
                                    required
								/>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
                                    Accused mother names
									<Required />
                                </label>
								<input
									type="text"
									name="motherNames"
									value={motherNames}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
                                    required
								/>
							</div>
						</div>

						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Gender
									<Required />
								</label>
								<select
									name="gender"
									value={gender}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									required
								>
									<option value="" disabled={gender}>
										Select
									</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									Accused profession
								</label>
								<select
									name="profession"
									value={profession}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
								>
									<option value="" disabled={profession}>
										Select
									</option>
									<option value="None">None</option>
									<option value="Farmer">Farmer</option>
									<option value="Teacher">Teacher</option>
									<option value="Nurse">Nurse</option>
									<option value="Doctor">Doctor</option>
									<option value="Builder">Builder</option>
									<option value="Student">Student</option>
									<option value="Public officer">Public officer</option>
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
                                    Accused nationality
									<Required />
                                </label>
								<input
									type="text"
									name="nationality"
									value={nationality}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
                                    required
								/>
							</div>
						</div>

						<p className="text-2xl">Location data</p>
						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Province
									<Required />
								</label>
								<select
									name="province"
									value={province}
									onChange={this.handleChangeProvince}
									className="border rounded-lg p-3"
									disabled={!provinces}
									required
								>
									<option value="" disabled={province}>
										Select
									</option>
									{provinces &&
										provinces.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									District
									<Required />
								</label>
								<select
									name="district"
									value={district}
									onChange={this.handleChangeDistrict}
									className="border rounded-lg p-3"
									disabled={!districts}
									required
								>
									<option value="" disabled={district}>
										Select
									</option>
									{districts &&
										districts.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									Sector
									<Required />
								</label>
								<select
									name="sector"
									value={sector}
									onChange={this.handleChangeSector}
									className="border rounded-lg p-3"
									disabled={!sectors}
									required
								>
									<option value="" disabled={sector}>
										Select
									</option>
									{sectors &&
										sectors.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
						</div>
						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
                                    General Assembly
									<Required />
								</label>
								<select
									name="generalAssembly"
									value={generalAssembly}
									onChange={this.handleChangeAssembly}
									className="border rounded-lg p-3"
									disabled={!generalAssemblies}
									required
								>
									<option value="" disabled={generalAssembly}>
										Select
									</option>
									{generalAssemblies &&
										generalAssemblies.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
                                    Court
									<Required />
								</label>
								<select
									name="courtOfCell"
									value={courtOfCell}
									onChange={this.handleChangeCourt}
									className="border rounded-lg p-3"
									disabled={!courts}
									required
								>
									<option value="" disabled={courtOfCell}>
										Select
									</option>
									{courts &&
										courts.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
						</div>

						<p className="text-2xl">Crime data</p>
						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Accused crime category
									<Required />
								</label>
								<select
									name="crimeCategory"
									value={crimeCategory}
									onChange={this.handleChangeCategory}
									className="border rounded-lg p-3"
									disabled={!crimeCategoriesData}
									required
								>
									<option value="" disabled={crimeCategory}>
										Select
									</option>
									{crimeCategoriesData &&
										crimeCategoriesData.data.map(({ id, name }) => (
											<option key={id} value={`${id}:${name}`}>
												{name}
											</option>
										))}
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									Accused crimes
									<Required />
								</label>
								<select
									name="crime"
									value={crime}
									onChange={this.handleChangeCrimes}
									className="border rounded-lg p-3"
									disabled={!crimesData}
									required
								>
									<option value="" disabled={crime}>
										Select
									</option>
									{crimesData &&
										crimesData.data.map(({ id, name }) => (
											<option key={id} value={`${id}:${name}`}>
												{name}
											</option>
										))}
								</select>
							</div>
						</div>

						{crimesNames.length ? (
							<div>
								<p className="my-4">Selected crimes:</p>
								<div className="flex-1 row flex-wrap space-x-4">
									{crimesNames.map(({ id, name }) => (
										<div
											key={id}
											className="col relative p-1 px-2 pr-8 mx-1 bg-gray-200"
										>
											<button
                                                type="button"
												className="absolute -top-2 -right-2 text-red-600 text-2xl outline-none"
												onClick={() => this.handleRemoveCrime(id)}
											>
												<MdRemoveCircle />
											</button>
											<p>{name}</p>
										</div>
									))}
								</div>
							</div>
						) : (
							""
						)}

						<div className="row space-x-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
                                    Trial date
									<Required />
                                </label>
								<input
									type="date"
									name="trialDate"
									value={trialDate}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
                                    required
								/>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">Describe the sentence</label>
								<textarea
									name="description"
									value={description}
									onChange={this.handleChange}
									className="border rounded-lg p-3 h-40"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						{suspectError?.data && (
							<div className="text-red-500 my-4">
								{suspectError.data.error}
							</div>
						)}

						<div className="row space-x-4">
							<button
								className="p-2 px-6 rounded-md bg-gray-200 outline-none"
								type="reset"
								onClick={this.clearFields}
							>
								Cancel
							</button>
							<button
								className="p-2 px-6 rounded-md bg-gdrBlue text-white outline-none"
								type="submit"
								disabled={
									!accusedName ||
									!profession ||
									!gender ||
									!crimeCategory ||
									!crimes.length
								}
							>
								{loading ? (
									<BeatLoader color="#FFF" />
								) : (
									"Submit"
								)}
							</button>
						</div>
					</form>
				</div>
          </div>
       </div>
    )
  }
}

const mapStateToProps = (state) => {
    const {
		crimeCategoriesData,
		crimeCategoriesError,
		crimesData,
		crimesError,
		suspectData,
		suspectError,
	} = state.documents;
	return {
		crimeCategoriesData,
		crimeCategoriesError,
		crimesData,
		crimesError,
		suspectData,
		suspectError,
	};
};

const mapDispatchToProps = {
    getCrimesCategories,
	getCrimes,
	createSuspect,
}

export default connect(mapStateToProps, mapDispatchToProps)(AddSuspect)