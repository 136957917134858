import React, { useState } from 'react'
import { AiOutlineClose } from "react-icons/ai";
import { BeatLoader } from 'react-spinners';
import { LinearProgress } from '@material-ui/core'
import { CustomDropzone } from './CustomDropzone';

const LinkDocumentModal = ({
  visible,
  toggle,
  documentTypes,
  onLinkDocument,
  loading,
  uploadProgress,
}) => {
  const [documentType, setDocumentType] = useState('');
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
		e.preventDefault();
		const { value } = e.target;
		setDocumentType(value);
	};

	const handleFileChange = (e) => {
		e.preventDefault();
		const { files } = e.target;
		setFile(files)
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (documentType && file) {
      onLinkDocument(documentType, file);
    }
	};

  return (
    <div
      tabIndex="-1"
      aria-hidden={visible ? "false" : "true" }
      aria-modal="true"
      role="dialog"
      className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full outline-none md:inset-0 h-full bg-gray-500 bg-opacity-20 transition-opacity ease-out duration-500">
      {/* Modal Dialog */}
      <div className="relative p-4 mx-auto my-8 w-full max-w-lg h-full pointer-events-none md:h-auto">
        {/* Modal Content */}
        <div className="relative bg-white rounded-lg shadow-lg pointer-events-auto">
          {/* Modal Header */}
          <div
            className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-lg">
            <h5 className="text-xl leading-normal">Link document(s) to accussed</h5>
            <button
              type="button"
              className="p-1 ml-auto inline-flex items-center"
              aria-label="Close"
              onClick={toggle}>
                <AiOutlineClose  />
            </button>
          </div>
          {/* Modal Body */}
          <div className="relative p-4">
            <div>
              <div className="row items-center m-2">
						  	<label htmlFor="documentType" className="w-1/3">Document type</label>
						  	<select
                  id="documentType"
						  		className="w-2/3 border rounded-md bg-white p-2"
						  		name="documentType"
						  		value={documentType}
						  		onChange={handleChange}
						  		required
						  	>
						  		<option value="" disabled={documentType}>
						  			Select
						  		</option>
						  		{documentTypes &&
						  			documentTypes.map(({ id, name }) => (
						  				<option key={id} value={id}>
						  					{name}
						  				</option>
						  			))}
						  	</select>
						  </div>
              <div className="m-2 py-2">
								<CustomDropzone onFileChange={handleFileChange} />
							</div>
            </div>
          </div>
          {/* Modal Footer */}
          <div
            className="flex flex-shrink-0 flex-wrap items-center justify-end p-4 space-y-4 md:space-x-4 md:space-y-0 border-t border-gray-200 rounded-b-lg">
            <button
				    	type="button"
				    	className="inline-block p-2 px-6 rounded-md bg-gray-200 outline-none"
				    	onClick={toggle}
				    	>
				    	Cancel
				    </button>
            <button
              type="button"
              className="inline-block p-2 px-6 rounded-md bg-gdrBlue text-white outline-none"
              disabled={
                !documentType ||
                !file
              }
              onClick={handleSubmit}>
              {loading ? (
				    		<BeatLoader color="#FFF" />
				    	) : (
				    		"Link to accused"
				    	)}
            </button>
            {loading ? <div className="w-full row items-center">
              <LinearProgress className="w-11/12" variant="determinate" value={uploadProgress} />
              <p className="text-md flex ml-auto">{uploadProgress} %</p>
            </div> : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LinkDocumentModal
