import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CREATE_USER,
  CREATE_USER_ERROR,
  GET_ROLES,
  GET_ROLES_ERROR,
  SET_PASSWORD,
  RESET_PASSWORD,
  SET_PASSWORD_ERROR,
  GET_USERS,
  GET_USER,
  GET_USERS_ERROR,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_ERROR,
} from "../actions/types";
import { getToken } from "../../helpers/tokenHelper";

const token = getToken();
const initialState = {
  authData: null,
  authError: null,
  token: token,
  isLoggedIn: token !== null ? true : false,
  signupData: null,
  signupError: null,
  userData: null,
  userError: null,
  rolesData: null,
  rolesError: null,
  setPasswordData: null,
  resetPasswordData:null,
  resetPasswordError:null,
  setPasswordError: null,
  usersData: null,
  usersError: null,
};

const users = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authData: payload,
        authError: null,
        token: payload.data.tokens.access.token,
        isLoggedIn: true,
        signupData: null,
        signupError: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        authError: payload,
        authData: null,
        token: null,
        isLoggedIn: false,
        signupData: null,
        signupError: null,
      };
    case LOGOUT:
      return {
        ...state,
        authData: null,
        authError: null,
        token: null,
        isLoggedIn: false,
        signupData: null,
        signupError: null,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        authData: null,
        authError: null,
        token: null,
        isLoggedIn: false,
        signupData: null,
        signupError: null,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        authError: payload,
      };
    case CREATE_USER:
      return {
        ...state,
        userData: payload,
        userError: null,
      };
    case CREATE_USER_ERROR:
      return {
        ...state,
        userData: null,
        userError: payload,
      };
    case GET_ROLES:
      return {
        ...state,
        rolesData: payload,
        rolesError: null,
      };
    case GET_ROLES_ERROR:
      return {
        ...state,
        rolesData: null,
        rolesError: payload,
      };
    case SET_PASSWORD:
      return {
        ...state,
        setPasswordData: payload,
        setPasswordError: null,
      };
    case RESET_PASSWORD:
      return{
        ...state,
        resetPasswordData:payload,
        resetPasswordError:null,
      }
    case SET_PASSWORD_ERROR:
      return {
        ...state,
        setPasswordData: null,
        setPasswordError: payload,
      };
    case GET_USERS:
      return {
        ...state,
        usersData: payload,
        usersError: null,
		userData: null,
		userError: null,
      };
    case GET_USER:
      return{
      ...state,
      userData: payload,
      userError: null,
    };
    case GET_USERS_ERROR:
      return {
        ...state,
        usersData: null,
        usersError: payload,
      };
    case CHANGE_USER_STATUS:
      return {
        ...state,
        userData: payload,
        userError: null,
      };
    case CHANGE_USER_STATUS_ERROR:
      return {
        ...state,
        userData: null,
        userError: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default users;
