import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { BeatLoader } from "react-spinners";
import { MdRemoveCircle } from "react-icons/md";
import {
	getCrimesCategories,
	getCrimes,
	linkDocument,
	getSpecificDocument,
} from "../redux/actions/documents";
import { Required } from "./Required";

export class NotIndexed extends Component {
	state = {
		accusedName: "",
		dob: "",
		fatherNames: "",
		motherNames: "",
		profession: "",
		gender: "",
		crimeCategory: "",
		crime: "",
		crimes: [],
		crimesNames: [],
		description: "",
		loading: false,
	};
	componentDidMount = async () => {
		const { getCrimesCategories } = this.props;
		await getCrimesCategories();
	};
	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleChangeCategory = async (e) => {
		e.preventDefault();
		const { getCrimes } = this.props;
		const { name, value } = e.target;
		await this.setState(() => ({
			[name]: value,
			crime: "",
		}));
		await getCrimes(value);
	};
	handleChangeCrimes = (e) => {
		e.preventDefault();
		const { value } = e.target;
		const { crimes } = this.state;
		const newValue = value.split(":");
		const foundCrime = crimes.find((item) => item === newValue[0]);
		if (!foundCrime) {
			this.setState(() => ({
				crime: newValue[0],
				crimes: crimes.concat(newValue[0]),
			}));
			this.saveCrimeName({ id: newValue[0], name: newValue[1] });
		} else {
			this.setState(() => ({
				found: true,
			}));
		}
	};
	handleRemoveCrime = (crimeId) => {
		const { crimes, crimesNames } = this.state;
		const RemainingCrimeIds = crimes.filter((item) => item !== crimeId);
		const RemainingCrimes = crimesNames.filter((item) => item.id !== crimeId);
		if (RemainingCrimes) {
			this.setState(() => ({
				crimes: RemainingCrimeIds,
				crimesNames: RemainingCrimes,
			}));
		}
	};
	saveCrimeName = async (details) => {
		const { crimesNames } = this.state;
		const foundCrimeName = await crimesNames.find(
			(item) => item === details.name,
		);
		if (!foundCrimeName) {
			await this.setState(() => ({
				crimesNames: crimesNames.concat(details),
			}));
		} else {
			this.setState(() => ({
				found: true,
			}));
		}
	};
	clearFields = (e) => {
		e.preventDefault();
		this.setState(() => ({
			accusedName: "",
			dob: "",
			fatherNames: "",
			motherNames: "",
			profession: "",
			gender: "",
			crimeCategory: "",
			crimes: "",
			description: "",
		}));
	};
	handleSubmit = async (e) => {
		e.preventDefault();
		this.setState({ loading: true });
		const { id, linkDocument } = this.props;
		const {
			accusedName,
			dob,
			fatherNames,
			motherNames,
			profession,
			gender,
			crimes,
			description,
		} = this.state;
		const data = {
			names: accusedName,
			dob,
			fatherNames,
			motherNames,
			profession,
			gender,
			crimes,
			description,
		};
		await linkDocument(id, data);
		this.setState({ loading: false });
	};
	static getDerivedStateFromProps = (state, props) => {
		const { linkDocumentData, getSpecificDocument, id } = props;
		if (linkDocumentData) {
			getSpecificDocument(id);
		}
		return state;
	};
	render() {
		const {
			accusedName,
			dob,
			fatherNames,
			motherNames,
			profession,
			gender,
			crimeCategory,
			crime,
			crimes,
			crimesNames,
			description,
			loading,
		} = this.state;
		const { crimeCategoriesData, crimesData, linkDocumentError } = this.props;
		return (
			<div>
				<div className="col p-8">
					<p className="text-2xl">Personal data</p>
					<form onSubmit={this.handleSubmit}>
						<div className="row gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Accused full names
									<Required />
								</label>
								<input
									type="text"
									name="accusedName"
									value={accusedName}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
									required
								/>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">Accused birth date</label>
								<input
									type="date"
									name="dob"
									value={dob}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
								/>
							</div>
						</div>

						<div className="row gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">Accused father names</label>
								<input
									type="text"
									name="fatherNames"
									value={fatherNames}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
								/>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">Accused mother names</label>
								<input
									type="text"
									name="motherNames"
									value={motherNames}
									onChange={this.handleChange}
									className="border rounded-lg p-3"
									placeholder="Fill in this field"
								/>
							</div>
						</div>

						<div className="row gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Accused profession
									<Required />
								</label>
								<select
									name="profession"
									value={profession}
									onChange={this.handleChange}
									className="border bg-transparent rounded-lg p-3"
									required
								>
									<option value="" disabled={profession}>
										Select
									</option>
									<option value="None">None</option>
									<option value="Farmer">Farmer</option>
									<option value="Teacher">Teacher</option>
									<option value="Nurse">Nurse</option>
									<option value="Doctor">Doctor</option>
									<option value="Builder">Builder</option>
									<option value="Student">Student</option>
									<option value="Public officer">Public officer</option>
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									Gender
									<Required />
								</label>
								<select
									name="gender"
									value={gender}
									onChange={this.handleChange}
									className="border bg-transparent rounded-lg p-3"
									required
								>
									<option value="" disabled={gender}>
										Select
									</option>
									<option value="Male">Male</option>
									<option value="Female">Female</option>
								</select>
							</div>
						</div>

						<p className="text-2xl">Crime data</p>
						<div className="row gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">
									Accused crime category
									<Required />
								</label>
								<select
									name="crimeCategory"
									value={crimeCategory}
									onChange={this.handleChangeCategory}
									className="border bg-transparent rounded-lg p-3"
									disabled={!crimeCategoriesData}
									required
								>
									<option value="" disabled={crimeCategory}>
										Select
									</option>
									{crimeCategoriesData &&
										crimeCategoriesData.data.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</select>
							</div>
							<div className="flex-1 col">
								<label htmlFor="">
									Accused crimes
									<Required />
								</label>
								<select
									name="crime"
									value={crime}
									onChange={this.handleChangeCrimes}
									className="border bg-transparent rounded-lg p-3"
									disabled={!crimesData}
									required
								>
									<option value="" disabled={crime}>
										Select
									</option>
									{crimesData &&
										crimesData.data.map(({ id, name }) => (
											<option key={id} value={`${id}:${name}`}>
												{name}
											</option>
										))}
								</select>
							</div>
						</div>

						{crimesNames.length ? (
							<div>
								<p className="my-4">Selected crimes:</p>
								<div className="flex-1 row flex-wrap gap-4">
									{crimesNames.map(({ id, name }) => (
										<div
											key={id}
											className="col relative p-1 px-2 pr-8 mx-1 bg-gray-200"
										>
											<div
												className="absolute -top-2 -right-2 text-red-600 text-2xl cursor-pointer"
												onClick={() => this.handleRemoveCrime(id)}
											>
												<MdRemoveCircle />
											</div>
											<p>{name}</p>
										</div>
									))}
								</div>
							</div>
						) : (
							""
						)}

						<div className="row gap-6 my-6">
							<div className="flex-1 col">
								<label htmlFor="">Describe the sentence</label>
								<textarea
									name="description"
									value={description}
									onChange={this.handleChange}
									className="border rounded-lg p-3 h-40"
									placeholder="Fill in this field"
								/>
							</div>
						</div>
						{linkDocumentError && (
							<div className="text-red-500 my-4">
								{linkDocumentError.data.error}
							</div>
						)}

						<div className="row gap-4">
							<button
								className="p-2 px-6 rounded-md bg-gray-200"
								type="reset"
								onClick={this.clearFields}
							>
								Cancel
							</button>
							<button
								className="p-2 px-6 rounded-md bg-gdrBlue text-white"
								type="submit"
								disabled={
									!accusedName ||
									!profession ||
									!gender ||
									!crimeCategory ||
									!crimes.length
								}
							>
								{loading ? (
									<BeatLoader color="#FFF" />
								) : (
									"Link document to accused"
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		crimeCategoriesData,
		crimeCategoriesError,
		crimesData,
		crimesError,
		linkDocumentData,
		linkDocumentError,
	} = state.documents;
	return {
		crimeCategoriesData,
		crimeCategoriesError,
		crimesData,
		crimesError,
		linkDocumentData,
		linkDocumentError,
	};
};

export default connect(mapStateToProps, {
	getCrimesCategories,
	getCrimes,
	linkDocument,
	getSpecificDocument,
})(withRouter(NotIndexed));
