import { toast } from "react-toastify";
import {
  GET_DOCUMENT_TYPES,
  GET_DOCUMENT_TYPES_FAILURE,
  DOCUMENTS_BY_TYPE,
  DOCUMENTS_BY_TYPE_ERROR,
  SPECIFIC_DOCUMENT,
  SPECIFIC_DOCUMENT_ERROR,
  GET_CRIMES_CATEGORIES,
  GET_CRIMES_CATEGORIES_ERROR,
  GET_CRIMES,
  GET_CRIMES_ERROR,
  LINK_DOCUMENT,
  LINK_DOCUMENT_ERROR,
  GET_SUSPECTS,
  GET_SUSPECTS_ERROR,
  SUSPECTS_BY_NAMES,
  SUSPECTS_BY_NAMES_ERROR,
  GET_SPECIFIC_SUSPECT_ERROR,
  GET_SPECIFIC_SUSPECT,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_ERROR,
  UPLOAD_LINK_DOCUMENTS,
  UPLOAD_LINK_DOCUMENTS_ERROR,
  CREATE_SUSPECT,
  CREATE_SUSPECT_ERROR,
  UPLOAD_PROGRESS,
  GET_TOTAL_CASES,
  GET_COMPLETED_CASES,
  GET_PENDING_CASES,
  GET_TOTAL_DOCUMENTS_BY_PROVINCE,
  GET_TOTAL_DOCUMENTS_BY_DISTRICT,
  GET_DOCUMENTS_BY_PROVINCE,
  GET_LINKED_DOCUMENTS_BY_PROVINCE,
  GET_DOCUMENTS_BY_DISTRICT,
  GET_LINKED_DOCUMENTS_BY_DISTRICT,
  GET_DOCUMENTS_BY_SECTOR,
  GET_LINKED_DOCUMENTS_BY_SECTOR,
  GET_DOCUMENTS_BY_GENERAL_ASSEMBLY,
  GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY,
  GET_DOCUMENTS_BY_COURT,
  GET_LINKED_DOCUMENTS_BY_COURT,
  GET_STATS_ERROR,
	COUNT_TOTAL_UPLOADED_DOCUMENTS,
	COUNT_TOTAL_LINKED_DOCUMENTS,
  COUNT_TOTAL_PAGES_BY_STATUS,
  COUNT_TOTAL_PAGES,
  COUNT_TOTAL_PAGES_ERROR,
  COUNT_TOTAL_PAGE_LINKED,
  COUNT_TOTAL_PAGE_UNLINKED,
} from "./types";
import apiCall from "../../Utils/APICall";
import { getToken } from "../../helpers/tokenHelper";

const authType = (type, payload) => ({
  type,
  payload,
});

export const getDocumentTypes = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get("/document-types", { headers });
    dispatch(authType(GET_DOCUMENT_TYPES, res.data));
  } catch (error) {
    dispatch(authType(GET_DOCUMENT_TYPES_FAILURE, error.response));
  }
};

export const getTotalPagesByStatus=(documentStatus)=>async(dispatch)=>{
 const token=getToken();
 const headers = {
  "Content-Type": "application/json",
  token: "bear ".concat(token),
};
try {
  const res=await apiCall.get(`/documents/pages?${documentStatus}`,{headers});
  dispatch(authType(COUNT_TOTAL_PAGES,res.data));
} catch (error) {
  dispatch(authType(COUNT_TOTAL_PAGES_ERROR,error.response));
}
}
export const getTotalLinked=()=>async(dispatch)=>{
  const token=getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  const documentStatus="linked";
  try {
    const res=await apiCall.get(`/documents/pages?documentStatus=${documentStatus}`,{headers});
    dispatch(authType(COUNT_TOTAL_PAGE_LINKED,res.data));
  } catch (error) {
    dispatch(authType(COUNT_TOTAL_PAGES_ERROR,error.response));
  }
}
export const getTotalUnlinked=()=>async(dispatch)=>{
  const token=getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  const documentStatus="not-assigned";
  try {
    const res=await apiCall.get(`/documents/pages?documentStatus=${documentStatus}`,{headers});
    dispatch(authType(COUNT_TOTAL_PAGE_UNLINKED,res.data));
  } catch (error) {
    dispatch(authType(COUNT_TOTAL_PAGES_ERROR,error.response));
  }
}
export const getTotalLinkedOrUnlinked=(documentStatus)=>async(dispatch)=>{
  const token=getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res=await apiCall.get(`/documents/pages?documentStatus=${documentStatus}`,{headers});
    dispatch(authType(COUNT_TOTAL_PAGES_BY_STATUS,res.data));
  } catch (error) {
    dispatch(authType(COUNT_TOTAL_PAGES_ERROR,error.response));
  }
}
export const getDocumentsByType = (searchParams) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/documents?${searchParams}`, { headers });
    dispatch(authType(DOCUMENTS_BY_TYPE, res.data));
  } catch (error) {
    dispatch(authType(DOCUMENTS_BY_TYPE_ERROR, error.response));
  }
};

export const getSpecificDocument = (id) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/documents/${id}`, { headers });
    dispatch(authType(SPECIFIC_DOCUMENT, res.data));
  } catch (error) {
    dispatch(authType(SPECIFIC_DOCUMENT_ERROR, error.response));
  }
};

export const getCrimesCategories = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/crimeCategory/`, { headers });
    dispatch(authType(GET_CRIMES_CATEGORIES, res.data));
  } catch (error) {
    dispatch(authType(GET_CRIMES_CATEGORIES_ERROR, error.response));
  }
};

export const getCrimes = (id) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/crime/${id}`, { headers });
    dispatch(authType(GET_CRIMES, res.data));
  } catch (error) {
    dispatch(authType(GET_CRIMES_ERROR, error.response));
  }
};

export const linkDocument = (id, data) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.patch(`/documents/${id}/link`, data, { headers });
    dispatch(authType(LINK_DOCUMENT, res.data));
    toast.success("Document(s) linked successfuly");
  } catch (error) {
    dispatch(authType(LINK_DOCUMENT_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getSuspectsByName = (name) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/suspects/search?query=${name}`, {
      headers,
    });
    dispatch(authType(SUSPECTS_BY_NAMES, res.data));
  } catch (error) {
    dispatch(authType(SUSPECTS_BY_NAMES_ERROR, error.response));
  }
};

export const getSuspectsByNames = (searchParams) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/suspects?${searchParams}`, {
      headers,
    });
    dispatch(authType(SUSPECTS_BY_NAMES, res.data));
  } catch (error) {
    dispatch(authType(SUSPECTS_BY_NAMES_ERROR, error.response));
  }
};

export const getSuspects = (searchParams) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/suspects?${searchParams}`, {
      headers,
    });
    dispatch(authType(GET_SUSPECTS, res.data));
  } catch (error) {
    dispatch(authType(GET_SUSPECTS_ERROR, error.response));
  }
};

export const getSpecificSuspect = (id) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.get(`/suspects/${id}`, { headers });
    dispatch(authType(GET_SPECIFIC_SUSPECT, res.data));
  } catch (error) {
    dispatch(authType(GET_SPECIFIC_SUSPECT_ERROR, error.response));
  }
};

export const uploadDocuments = (data) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "multipart/form-data",
    token: "bear ".concat(token),
  };
  const config = {
    onUploadProgress: (progressEvent) => {
      console.log(
        "progressEvent",
        Math.round((progressEvent.loaded * 100) / progressEvent.total)
      );
      dispatch(
        authType(
          UPLOAD_PROGRESS,
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
      );
    },
  };
  try {
    const res = await apiCall.post(`/documents`, data, { headers, ...config });
    dispatch(authType(UPLOAD_DOCUMENTS, res.data));
    toast.success("Document(s) uploaded successfuly");
  } catch (error) {
    dispatch(authType(UPLOAD_DOCUMENTS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const uploadLinkDocuments = (data) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "multipart/form-data",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.post("/suspect-document", data, { headers });
    dispatch(authType(UPLOAD_LINK_DOCUMENTS, res.data));
    toast.success("Document(s) linked successfuly");
    return res.data;
  } catch (error) {
    dispatch(authType(UPLOAD_LINK_DOCUMENTS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const createSuspect = (data) => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "bear ".concat(token),
  };
  try {
    const res = await apiCall.post("/suspects/create", data, { headers });
    dispatch(authType(CREATE_SUSPECT, res.data));
    toast.success("Suspect created successfuly");
    return res.data;
  } catch (error) {
    dispatch(authType(CREATE_SUSPECT_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getDashboardStats = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
  try {
    const [
      totalCases,
      completedCases,
      pendingCases,
      documentsyProvince,
      documentsyDistrict,
			totalUploadedDocuments,
			totalLinkedDocuments,
    ] = await Promise.all([
      apiCall.get("documents/bylocation", { headers }),
      apiCall.get("/suspects/completedCases", { headers }),
      apiCall.get("documents/bylocation?documentStatus=linked", { headers }),
      apiCall.get("/documents/byProvince", { headers }),
      apiCall.get("/documents/byDistrict", { headers }),
			apiCall.get('documents/bylocation', { headers }),
			apiCall.get('documents/bylocation?documentStatus=linked',{headers}),
    ]);

    dispatch(authType(GET_TOTAL_CASES, totalCases.data));
    dispatch(authType(GET_COMPLETED_CASES, completedCases.data));
    dispatch(authType(GET_PENDING_CASES, pendingCases.data));
    dispatch(
      authType(GET_TOTAL_DOCUMENTS_BY_PROVINCE, documentsyProvince.data)
    );
    dispatch(
      authType(GET_TOTAL_DOCUMENTS_BY_DISTRICT, documentsyDistrict.data)
    );
		dispatch(authType(COUNT_TOTAL_UPLOADED_DOCUMENTS,totalUploadedDocuments.data));
		dispatch(authType(COUNT_TOTAL_LINKED_DOCUMENTS,totalLinkedDocuments.data));
  } catch (error) {
    console.log(error);
    dispatch(authType(GET_STATS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};
export const getTotalUploadedStats = () => async (dispatch) =>{
	const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
	try {
		  const [totalUploadedDocuments, totalLinkedDocuments] = await Promise.all([
				apiCall.get('documents/bylocation', { headers }),
				apiCall.get('documents/bylocation?documentStatus=linked',{headers}),
			]);
			dispatch(authType(COUNT_TOTAL_UPLOADED_DOCUMENTS,totalUploadedDocuments.data));
			dispatch(authType(COUNT_TOTAL_LINKED_DOCUMENTS,totalLinkedDocuments.data));
	} catch (error) {
		console.log(error);
		dispatch(authType(GET_STATS_ERROR, error.response));
		toast.error("Something went wrong, Please try again later"+error);
	}
}
export const getDashboardStatsByProvince = (province) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
  try {
    const [documents, linkedDocuments] = await Promise.all([
      apiCall.get(`documents/bylocation?province=${province}`, { headers }),
      apiCall.get(
        `documents/bylocation?documentStatus=linked&province=${province}`,
        { headers }
      ),
    ]);

    dispatch(authType(GET_DOCUMENTS_BY_PROVINCE, documents.data));
    dispatch(authType(GET_LINKED_DOCUMENTS_BY_PROVINCE, linkedDocuments.data));
  } catch (error) {
    console.log(error);
    dispatch(authType(GET_STATS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getDashboardStatsByDistrict = (district) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
  try {
    const [documents, linkedDocuments] = await Promise.all([
      apiCall.get(`documents/bylocation?district=${district}`, { headers }),
      apiCall.get(
        `documents/bylocation?documentStatus=linked&district=${district}`,
        { headers }
      ),
    ]);

    dispatch(authType(GET_DOCUMENTS_BY_DISTRICT, documents.data));
    dispatch(authType(GET_LINKED_DOCUMENTS_BY_DISTRICT, linkedDocuments.data));
  } catch (error) {
    console.log(error);
    dispatch(authType(GET_STATS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getDashboardStatsBySector = (sector) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
  try {
    const [documents, linkedDocuments] = await Promise.all([
      apiCall.get(`documents/bylocation?sector=${sector}`, { headers }),
      apiCall.get(
        `documents/bylocation?documentStatus=linked&sector=${sector}`,
        { headers }
      ),
    ]);

    dispatch(authType(GET_DOCUMENTS_BY_SECTOR, documents.data));
    dispatch(authType(GET_LINKED_DOCUMENTS_BY_SECTOR, linkedDocuments.data));
  } catch (error) {
    console.log(error);
    dispatch(authType(GET_STATS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getDashboardStatsByGeneralAssembly =
  (generalAssembly) => async (dispatch) => {
    const token = getToken();
    const headers = {
      token: "Bearer ".concat(token),
    };
    try {
      const [documents, linkedDocuments] = await Promise.all([
        apiCall.get(`documents/bylocation?generalAssembly=${generalAssembly}`, {
          headers,
        }),
        apiCall.get(
          `documents/bylocation?documentStatus=linked&generalAssembly=${generalAssembly}`,
          { headers }
        ),
      ]);

      dispatch(authType(GET_DOCUMENTS_BY_GENERAL_ASSEMBLY, documents.data));
      dispatch(
        authType(GET_LINKED_DOCUMENTS_BY_GENERAL_ASSEMBLY, linkedDocuments.data)
      );
    } catch (error) {
      console.log(error);
      dispatch(authType(GET_STATS_ERROR, error.response));
      toast.error("Something went wrong, Please try again later");
    }
  };

export const getDashboardStatsByCourt = (courtOfCell) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "Bearer ".concat(token),
  };
  try {
    const [documents, linkedDocuments] = await Promise.all([
      apiCall.get(`documents/bylocation?courtOfCell=${courtOfCell}`, {
        headers,
      }),
      apiCall.get(
        `documents/bylocation?documentStatus=linked&courtOfCell=${courtOfCell}`,
        { headers }
      ),
    ]);

    dispatch(authType(GET_DOCUMENTS_BY_COURT, documents.data));
    dispatch(authType(GET_LINKED_DOCUMENTS_BY_COURT, linkedDocuments.data));
  } catch (error) {
    console.log(error);
    dispatch(authType(GET_STATS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};
