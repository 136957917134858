import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { ToastContainer } from 'react-toastify';
import "./index.css";
import "./styles/index.scss";
import "./styles/global.scss";
  import 'react-toastify/dist/ReactToastify.css';
import App from "./App.jsx";
import Reducers from "./redux/reducers";
import Middlewares from "./redux/middlewares";

const store = createStore(Reducers, Middlewares);

ReactDOM.render(
	<Provider store={store}>
		<App />
		<ToastContainer
		    position="top-right"
		    autoClose={5000}
		    hideProgressBar={true}
		    newestOnTop={true}
		    closeOnClick
		    rtl={false}
		    pauseOnFocusLoss
		    draggable
		    pauseOnHover
		/>
	</Provider>
	,
	document.getElementById("root"),
);
