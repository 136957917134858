import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { AiFillAppstore } from "react-icons/ai";
import { HiCloudUpload, HiFolderRemove, HiUserGroup, HiUsers, HiLogout, } from "react-icons/hi";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { logout } from "../redux/actions/users";

export class SideNav extends Component {
	state = {
		loading: false,
		error: false,
	};
    //implementing logout
	handleLogout = async () => {
		this.setState({ error: false, loading: true });
		const { logout, history, location } = this.props;
		const { from } = location.state || { from: { pathname: '/dashboard' } };
		// caches.keys().then(cacheNames => {
		// 	cacheNames.forEach(cacheName => {
		// 	  caches.delete(cacheName);
		// 	});
		//   });
		await logout();
		window.location.reload();
		this.setState({ loading: false });
		history.replace(from);
	}

	render() {
		const { loading } = this.state;

		return (
			<div>
				<div className="h-screen overflow-y-auto bg-kindaBlue py-8 px-4">
					<div className="col h-full">
						<div className="bg-gdrBlue text-white p-4 rounded-md text-center text-2xl font-semibold">
						GACACA CASE FILING
						{/* <span className="font-semibold">Filing</span> */}
						</div>
						<div className="my-4 flex-1">
							<ul className="list-none">
								<NavLink
									to="/dashboard/stats"
									className="row items-center text-white p-4 my-2 w-full hover:bg-gdrBlue rounded-md"
									activeClassName="bg-gdrBlue rounded-md"
								>
									<AiFillAppstore className="text-3xl" /> &nbsp; &nbsp;
									Dashboard
								</NavLink>
								<NavLink
									to="/dashboard/files"
									className="row items-center text-white p-4 my-2 w-full hover:bg-gdrBlue rounded-md"
									activeClassName="bg-gdrBlue rounded-md"
								>
									<HiFolderRemove className="text-3xl" /> &nbsp; &nbsp; Files
								</NavLink>
								<NavLink
									to="/dashboard/suspects"
									className="row items-center text-white p-4 my-2 w-full hover:bg-gdrBlue rounded-md"
									activeClassName="bg-gdrBlue rounded-md"
								>
									<HiUserGroup className="text-3xl" /> &nbsp; &nbsp; Suspects
								</NavLink>
								<NavLink
									to="/dashboard/upload"
									className="row items-center text-white p-4 my-2 w-full hover:bg-gdrBlue rounded-md"
									activeClassName="bg-gdrBlue rounded-md"
								>
									<HiCloudUpload className="text-3xl" /> &nbsp; &nbsp; Upload
								</NavLink>
								<NavLink
									to="/dashboard/users"
									className="row items-center text-white p-4 my-2 w-full hover:bg-gdrBlue rounded-md"
									activeClassName="bg-gdrBlue rounded-md"
								>
									<HiUsers className="text-3xl" /> &nbsp; &nbsp; Users
								</NavLink>
							</ul>
						</div>
						<div className="justify-self-end">
							<button
								className="row items-center text-white p-4 w-full hover:bg-gdrBlue rounded-md outline-none"
								onClick={this.handleLogout}
							>
								{loading
								    ? <ClipLoader color="white" size={30} />
								    : <HiLogout className="text-3xl" />
								} &nbsp; &nbsp;
								Logout
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { authData, authError } = state.users;
	return { authData, authError };
};

export default connect(mapStateToProps, { logout })(SideNav);
