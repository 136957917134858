import React, { Component, Fragment } from "react";
import { Route, Switch } from "react-router";
import NotFound from "../components/NotFound";
import SideNav from "../components/SideNav";
import AddSuspect from "./AddSuspect";
import AddUser from "./AddUser";
import BulkUpload from "./BulkUpload";
import DashboardIndexes from "./DashboardIndexes";
import DashboardStats from "./DashboardStats";
import IndexDetails from "./IndexDetails";
import SuspectDetails from "./SuspectDetails";
import SuspectsList from "./SuspectsList";
import UsersList from "./UsersList";
import  SetSecret  from "./SetSecret";
import ChangePassword from "./ChangePassword";




export class Dashboard extends Component {
	render() {
		const { match } = this.props;
		return (
			<Fragment>
				<div className="row h-screen">
					<div className="w-3/12 xl:w-2/12">
						<SideNav { ...this.props } />
					</div>
					<div className="w-9/12 xl:w-10/12 overflow-y-auto">
						<Switch>
							<Route exact path={`${match.path}`} component={DashboardStats} />
							<Route
								exact
								path={`${match.path}/stats`}
								component={DashboardStats}
							/>
							<Route
								exact
								path={`${match.path}/files`}
								component={DashboardIndexes}
							/>
							<Route
								path={`${match.path}/files/:id`}
								component={IndexDetails}
							/>
							<Route
								exact
								path={`${match.path}/suspects`}
								component={SuspectsList}
							/>
							<Route
								path={`${match.path}/suspects/add`}
								component={AddSuspect}
							/>
							<Route
								path={`${match.path}/suspects/:id`}
								component={SuspectDetails}
							/>
							<Route
								path={`${match.path}/upload`}
								component={BulkUpload}
							/>
							<Route
								path={`${match.path}/users/add`}
								component={AddUser}
							/>
							<Route
								path={`${match.path}/users`}
								component={UsersList}
							/>
							<Route
								path={`${match.path}/skidamarinkadikadu`}
								component={SetSecret}
							/>
							<Route 
							path={`${match.path}/change-password/:id`}
							component={ChangePassword}/>
							
							<Route component={NotFound} />
						</Switch>
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Dashboard;
