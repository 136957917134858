import { administrationStructure } from "../Utils/administration";

/**
 *
 * @returns [provinces]
 */

export const formatProvinces = () => {
	const provinces = administrationStructure.map(({ id, name, districts }) => ({
		id,
		name,
		districts,
	}));
	return provinces;
};

/**
 *
 * @param {provinceId}
 * @returns [districts]
 */
export const formatDistricts = (provinceId) => {
	const provinces = formatProvinces();
	if (provinceId) {
		const allDistricts = provinces.filter(
			(province) => province.id === provinceId,
		);
		return allDistricts[0].districts;
	} else {
		return null;
	}
};

/**
 *
 * @param {provinceId} provinceId
 * @param {districtId} districtId
 * @returns  [sectors]
 */
export const formatSectors = (provinceId, districtId) => {
	if (provinceId && districtId) {
		const districts = formatDistricts(provinceId);
		const district = districts.find((dist) => dist.id === districtId);
		return district.sectors;
	} else {
		return null;
	}
};

export const formatAssemblies = (provinceId, districtId, sectorId) => {
	if (provinceId && districtId && sectorId) {
		const sectors = formatSectors(provinceId, districtId);
		const sector = sectors.find((sect) => sect.id === sectorId);
		return sector.courts;
	} else {
		return null;
	}
};

export const formatCourts = (provinceId, districtId, sectorId, assemblyId) => {
	if (provinceId && districtId && sectorId && assemblyId) {
		const assemblies = formatAssemblies(provinceId, districtId, sectorId);
		const assembly = assemblies.find((assemb) => assemb.id === assemblyId);
		return assembly.courtOfCells;
	} else {
		return null;
	}
};
