import React, { Component } from "react";
import { FaUser } from "react-icons/fa";
import { IoCalendarOutline } from "react-icons/io5";
import { BiFirstPage, BiLastPage } from "react-icons/bi";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { IconButton } from "@material-ui/core";
import { BounceLoader } from "react-spinners";

export class IndexedResults extends Component {
	state = {
	    page: this.props.page || 1,
	    limit: this.props.rowsPerPage || 10,
    };

	handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState(() => ({
			[name]: value,
		}));
	};
	handleChangePage = (page) => {
		const { limit } = this.state;
		const { onChangePage } = this.props;
		this.setState({
			page,
		});
		onChangePage(page, limit);
	}

	render() {
		// const { page, limit } = this.state;
		const { indexedData, count, page, rowsPerPage: limit, loading } = this.props;
		return (
			<div>
				{loading
				    ? (
					<div className="col w-full items-center justify-center">
						<BounceLoader color="rgba(40, 100, 254, 1)" />
					</div>
					) : indexedData ? indexedData.map(
							({
								id,
								DOB,
								names,
								fatherNames,
								motherNames,
								crimes,
								description,
								gender,
								state
							}) => (
								<div key={id}>
									<div className="border my-6 p-4 rounded-md text-lg">
										<div className="row items-center">
											<input
												type="radio"
												name="accused"
												id="accused"
												value={id}
												className="w-6 h-6 cursor-pointer"
												onChange={this.props.onChange}
											/>
											&nbsp; &nbsp; <FaUser /> &nbsp; &nbsp;
											<p>{names}</p>
										</div>
										<div className="my-4 text-gray-600">
											Father: {fatherNames}
										</div>
										<div className="my-4 text-gray-600">
											Mother: {motherNames}
										</div>
										<div className="row items-start my-4 text-gray-600">
											<div className="">Crime(s):</div>
											<div className="col">
												{crimes.map(({ id, name }) => (
													<div key={id} className="px-2">
														<li>{name}</li>
													</div>
												))}
											</div>
										</div>
										<div className="my-4 text-gray-600">
											Sentence description: {description}
										</div>
										<div className="my-4 text-gray-600">
											Gender: {gender}
										</div>
										<div className="my-4 text-gdrBlue row items-center">
											<IoCalendarOutline /> &nbsp; &nbsp; Birth Date:{" "}
											{DOB}
										</div>
										<div className="my-4">
											Status: &nbsp;
											<span className="bg-green-100 px-2 text-green-600 rounded-sm">
												{state} documents out of 8
											</span>
										</div>
									</div>
								</div>
							),
					  ): null}
    <div className="mb-6 row items-center justify-end">
	    <div className="mr4">
	    	<span className="">
	    		{(limit * page + 1) - limit} - {limit * page} of {count}
	    	</span>
	    </div>
        <div className="flex-shrink-0">
			<IconButton
				onClick={() => this.handleChangePage(1)}
				disabled={page === 1}
				aria-label="first page"
			>
				<BiFirstPage />
			</IconButton>
			<IconButton
				onClick={() => this.handleChangePage(page - 1)}
				disabled={page === 1}
				aria-label="previous page"
			>
				<MdKeyboardArrowLeft />
			</IconButton>
			<IconButton
				onClick={() => this.handleChangePage(page + 1)}
				disabled={page >= Math.ceil(count / limit)}
				aria-label="next page"
			>
				<MdKeyboardArrowRight />
			</IconButton>
			<IconButton
				onClick={() => this.handleChangePage(Math.ceil(count / limit))}
				disabled={page >= Math.ceil(count / limit)}
				aria-label="last page"
			>
				<BiLastPage />
			</IconButton>
		</div>
	</div>
</div>
		);
	}
}

export default IndexedResults;
