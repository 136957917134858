import { toast } from "react-toastify";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CREATE_USER,
  CREATE_USER_ERROR,
  GET_ROLES,
  GET_ROLES_ERROR,
  SET_PASSWORD,
  RESET_PASSWORD,
  SET_PASSWORD_ERROR,
  GET_USERS,
  GET_USER,
  GET_USERS_ERROR,
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_ERROR,
} from "./types";
import apiCall from "../../Utils/APICall";
import { getToken, removeToken, setToken } from "../../helpers/tokenHelper";

const authType = (type, payload) => ({
  type,
  payload,
});

export const login = (data) => async (dispatch) => {
  try {
    const res = await apiCall.post("/auth/login", data);
    setToken(res.data.data.tokens.access.token);
    dispatch(authType(LOGIN_SUCCESS, res.data));
  } catch (error) {
    dispatch(authType(LOGIN_FAILURE, error.response));
  }
};

export const logout = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    "Content-Type": "application/json",
    token: "Bearer ".concat(token),
  };

  try {
    const res = await apiCall.post("/auth/logout", {}, { headers });
    removeToken();
    dispatch(authType(LOGOUT_SUCCESS, res.data));
  } catch (error) {
    dispatch(authType(LOGOUT_FAILURE, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const createUser = (data) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "bear ".concat(token),
  };

  try {
    const res = await apiCall.post("/auth/signup", data, { headers });
    dispatch(authType(CREATE_USER, res.data));
    toast.success("User created successfully");
  } catch (error) {
    dispatch(authType(CREATE_USER_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};

export const getRoles = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "bear ".concat(token),
  };

  try {
    const res = await apiCall.get("/roles", { headers });
    dispatch(authType(GET_ROLES, res.data));
  } catch (error) {
    dispatch(authType(GET_ROLES_ERROR, error.response));
  }
};

export const setPassword = (password, token) => async (dispatch) => {
  const headers = {
    token: "bear ".concat(token),
  };

  try {
    const res = await apiCall.post(
      "/auth/set-password",
      { password },
      { headers }
    );
    dispatch(authType(SET_PASSWORD, res.data));
    toast.success(
      "Password set successfully, login to your account to continue"
    );
  } catch (error) {
    dispatch(authType(SET_PASSWORD_ERROR, error.response));
    toast.error("Password must include a number,6 character and a Capital letter at least");
  }
};

export const resetPassword=(userId,password) => async (dispatch)=>{
  const token=getToken();
  const headers={
    token:"bear ".concat(token),
  };
  try {
    const data={
      userId,
      password
    }
    const res= await apiCall.post(
      "/auth/reset-passwords",
      data,
      {headers}
    );
    dispatch(authType(RESET_PASSWORD,res.data));
    toast.success(
      "Password changed succesfully!"
    )
  } catch (error) {
    dispatch(authType(SET_PASSWORD_ERROR,error.response));
  }
}
export const getUsers = () => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "bear ".concat(token),
  };

  try {
    const res = await apiCall.get("/users", { headers });
    dispatch(authType(GET_USERS, res.data));
  } catch (error) {
    dispatch(authType(GET_USERS_ERROR, error.response));
  }
};

export const getOneUser =(id) => async(dispatch)=>{
  const token=getToken();
  const  headers={
    token:"bear ".concat(token),
  };
  try {
    const res=await apiCall.get(`/users/${id}`,{headers});
    dispatch(authType(GET_USER,res.data));
  } catch (error) {
    dispatch(authType(GET_USERS_ERROR, error.response));
  }
};

export const changeStatus = (id, status) => async (dispatch) => {
  const token = getToken();
  const headers = {
    token: "bear ".concat(token),
  };

  try {
    const res = await apiCall.patch(
      `/users/${id}/change-status`,
      { status },
      { headers }
    );
    dispatch(authType(CHANGE_USER_STATUS, res.data));
    toast.success("Status changed successfully");
  } catch (error) {
    dispatch(authType(CHANGE_USER_STATUS_ERROR, error.response));
    toast.error("Something went wrong, Please try again later");
  }
};
